<default-popup-wrapper-component [displayMode]="'DISPLAY_MODE_XLARGE'" [globalPopup]="globalPopup" [title]="'candelarrules.edit' | translate" (onClose)="closePopup($event)">
    <div class="control-exception-container">
        <div class="row">
            <div class="pr-2 program-list-container" [ngClass]="{'open':(showList || activeControlException === null), 'is-hidden':(_laptopMode || _tabletMode || _mobileMode)}">
                <div class="header mt-1 mb-2">
                    <div class="search-container">
                        <div class="input-container">
                            <input type="search" class="search-field" (keyup.enter)="doSearch()" [(ngModel)]="currentActiveFilterQuery" placeholder="{{'treesearch.placeholder' | translate}}" />
                            <div class="search-buttons">
                                <i class="material-icons search-clear-btn" (click)="clearSearchQuery()" [ngClass]="{'active':hasSearchQuery()}">close</i>
                                <i class="material-icons" (click)="doSearch()">search</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="program-list pr-2">
                    <div *ngIf="isLoadingControlExceptionList && !isAddingNewException" class="py-3 d-flex justify-content-center">
                        <div class="loader"></div>
                    </div>
                    <ng-container *ngIf="isAddingNewException">
                        <div class="program-item is-new is-selected">
                            <div class="name w-100 d-flex align-items-center">{{'candelarrules.new-exception-name' | translate}}</div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isLoadingControlExceptionList">
                        <span *ngIf="controlExceptionList.length == 0 && !isAddingNewException">{{'candelarrules.no-rules-found' | translate}}</span>
                    </ng-container>
                    <ng-container *ngFor="let controlException of controlExceptionList">
                        <div class="program-item" *ngIf="!controlException.isHidden || controlException.isSelected" (click)="getControlException(controlException.id)" [ngClass]="{'is-selected':controlException.isSelected}">
                            <div class="name w-100 d-flex align-items-center">{{controlException.name}}</div>
                            <div class="info w-100">
                                <ul>
                                    <li *ngIf="controlException.controlProgramName"><span>{{controlException.controlProgramName}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="ml-3">
                    <lumi-button (onClick)="addException()"
                                 [disabled]="disableAddExceptionButton"
                                 [size]="'small'"
                                 [rank]="'primary'"
                                 [label]="'candelarrules.add-btn' | translate"
                                 [icon]="'add_circle'">
                    </lumi-button>
                    <lumi-button (onClick)="addDefaultExceptions()"
                                 [disabled]="disableAddExceptionButton"
                                 [size]="'small'"
                                 [rank]="'primary'"
                                 [label]="'candelarrules.add-default-rules-btn' | translate"
                                 [icon]="'add_circle'">
                    </lumi-button>
                </div>
                <div *ngIf="(_laptopMode || _tabletMode || _mobileMode) && activeControlException !== null" class="mobile-expand-icon" (click)="toggleArticleNavigation()"><i class="material-icons">menu_open</i></div>
            </div>
            <div class="pl-5 pr-3 program-details-container" *ngIf="isAddingNewException || activeControlException || isLoadingControlException" (click)="toggleArticleNavigation(true)">
                <div class="py-3 d-flex justify-content-center" *ngIf="isLoadingControlException">
                    <div class="loader"></div>
                </div>
                <div *ngIf="!isLoadingControlException">
                    <div class="program-details-header">
                        <div class="row">
                            <div class="col-12">
                                <input type="text" [(ngModel)]="activeControlException.name" [disabled]="!canEditExceptionProgram()" [attr.placeholder]="isAddingNewException ? ('candelarrules.new-exception-name' | translate) : ('control-program.program-name-placeholder' | translate)" (ngModelChange)="checkForError()"  />
                            </div>
                        </div>
                    </div>
                    <div class="program-details-inner mt-3 mb-2">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-4">{{'candelarrules.control-program-label' | translate}}</div>
                                    <div class="col-8">
                                        <div class="loader" *ngIf="isLoadingControlProgramList"></div>
                                        <lumi-select class="lumi-select-field" *ngIf="!isLoadingControlProgramList"
                                                     [options]="controlProgramsOptions"
                                                     [showOptionFilter]="true"
                                                     [style.width]="'80%'"
                                                     [selectedOptions]="getSelect('control-program')"
                                                     (onOptionsSelect)="changeSelect('control-program',$event)">
                                        </lumi-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">{{'candelarrules.priority-label' | translate}}</div>
                                    <div class="col-8">
                                        <lumi-select class="lumi-select-field"
                                                     [options]="priorityOptions"
                                                     [readonly]="false"
                                                     [disabled]="false"
                                                     [showOptionFilter]="false"
                                                     [style.width]="'20%'"
                                                     [selectedOptions]="getSelect('priority')"
                                                     (onOptionsSelect)="changeSelect('priority',$event)">
                                        </lumi-select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-4">{{'candelarrules.type-label' | translate}}</div>
                                    <div class="col-8">
                                        <div class="d-flex flex-wrap">
                                            <div>
                                                <input type="radio" id="group1-1" name="group1" [checked]="activeControlException.type === ESelectedType.one_time" (click)="setSelectedType(ESelectedType.one_time)">
                                                <label for="group1-1" class="mb-0">{{'candelarrules.single-label' | translate}}</label>
                                            </div>
                                            <div>
                                                <input type="radio" id="group1-2" name="group1"
                                                    [checked]="activeControlException.type === ESelectedType.weekly ||
                                                                activeControlException.type === ESelectedType.monthly_date ||
                                                                activeControlException.type === ESelectedType.monthly_weekday ||
                                                                activeControlException.type === ESelectedType.yearly_date ||
                                                                activeControlException.type === ESelectedType.yearly_weekday"
                                                    (click)="setSelectedType(ESelectedType.weekly)">
                                                <label for="group1-2" class="mb-0">{{'candelarrules.recurrence-label' | translate}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12"><hr /></div>
                                </div>
                                <ng-container *ngIf="activeControlException.type !== null">
                                    <div class="row">
                                        <div class="col-4">{{'candelarrules.start-date-label' | translate}}</div>
                                        <div class="col-8">
                                            <div class="d-flex flex-wrap">
                                                <date-input [inputType]="'date'"
                                                            [allowClear]="false"
                                                            [initialValue]="activeControlException.startDate"
                                                            (onDateChanged)="changeDate('start',$event)"
                                                ></date-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-4">{{'candelarrules.end-date-label' | translate}}</div>
                                        <div class="col-8">
                                            <div class="d-flex flex-wrap">
                                                <date-input [inputType]="'date'"
                                                            [allowClear]="false"
                                                            [initialValue]="activeControlException.endDate"
                                                            (onDateChanged)="changeDate('end',$event)"
                                                ></date-input>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <!--Recurring use-->
                                <div *ngIf="activeControlException.type === ESelectedType.weekly ||
                                            activeControlException.type === ESelectedType.monthly_date ||
                                            activeControlException.type === ESelectedType.monthly_weekday ||
                                            activeControlException.type === ESelectedType.yearly_date ||
                                            activeControlException.type === ESelectedType.yearly_weekday">
                                    <div class="row">
                                        <div class="col-4">{{'candelarrules.frequency-label' | translate}}</div>
                                        <div class="col-8">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="d-flex flex-wrap">
                                                        <div><input type="radio" id="group2-1" name="group2" [checked]="activeControlException.type === ESelectedType.weekly" (click)="setSelectedType(ESelectedType.weekly)"> <label for="group2-1">{{'candelarrules.weekly-label' | translate}}</label></div>
                                                        <div><input type="radio" id="group2-2" name="group2" [checked]="activeControlException.type === ESelectedType.monthly_date || activeControlException.type === ESelectedType.monthly_weekday" (click)="setSelectedType(ESelectedType.monthly_date)"> <label for="group2-2">{{'candelarrules.monthly-label' | translate}}</label></div>
                                                        <div><input type="radio" id="group2-3" name="group2" [checked]="activeControlException.type === ESelectedType.yearly_date || activeControlException.type === ESelectedType.yearly_weekday" (click)="setSelectedType(ESelectedType.yearly_date)"> <label for="group2-3">{{'candelarrules.yearly-label' | translate}}</label></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Week-->
                                            <div *ngIf="activeControlException.type === ESelectedType.weekly">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="d-flex flex-wrap align-items-center">
                                                            <span>{{'candelarrules.every' | translate}}</span>
                                                            <input type="number" value="" style="width:40px;" class="ml-2 mr-2" (change)="populateSumup()" [(ngModel)]="activeControlException.recurrenceNr">
                                                            <span>{{'candelarrules.repeat-week' | translate}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="d-flex flex-wrap">
                                                            <ng-container *ngFor="let day of daysOfWeekShort">
                                                                <input type="radio" value="{{day.id}}" id="w-{{day.id}}" name="day" [checked]="activeControlException.weekday === day.id" (click)="setWeekday(day.id)"> <label for="w-{{day.id}}">{{day.name}}</label>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Month-->
                                            <div *ngIf="activeControlException.type === ESelectedType.monthly_date || activeControlException.type === ESelectedType.monthly_weekday">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="d-flex flex-wrap align-items-center">
                                                            <span>{{'candelarrules.every' | translate}}</span>
                                                            <input type="number" value="" style="width:40px;" class="ml-2 mr-2" (change)="populateSumup()" [(ngModel)]="activeControlException.recurrenceNr">
                                                            <span>{{'candelarrules.repeat-month' | translate}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="d-flex flex-wrap align-items-center">
                                                            <input type="radio" id="group3-1" name="group3" [checked]="activeControlException.type === ESelectedType.monthly_date" (click)="setSelectedType(ESelectedType.monthly_date)">
                                                            <label for="group3-1">{{'candelarrules.day' | translate}}</label>
                                                            <input type="number" value="" style="width:40px;" class="ml-2 mb-2" (focus)="setSelectedType(ESelectedType.monthly_date, false)" (change)="populateSumup()" [(ngModel)]="activeControlException.day">
                                                        </div>
                                                        <div class="d-flex flex-wrap align-items-center">
                                                            <input type="radio" id="group3-2" name="group3" [checked]="activeControlException.type === ESelectedType.monthly_weekday" (click)="setSelectedType(ESelectedType.monthly_weekday)">
                                                            <label for="group3-2">{{'candelarrules.every' | translate}}</label>
                                                            <lumi-select class="lumi-select-field ml-2 mr-2"
                                                                         [options]="everyX"
                                                                         [readonly]="false"
                                                                         [disabled]="false"
                                                                         [showOptionFilter]="false"
                                                                         [forceDropUp]="true"
                                                                         [style.width]="'55px'"
                                                                         [selectedOptions]="getSelect('relative')"
                                                                         (click)="setSelectedType(ESelectedType.monthly_weekday, false)"
                                                                         (onOptionsSelect)="changeSelect('relative',$event)">
                                                            </lumi-select>
                                                            <lumi-select class="lumi-select-field"
                                                                         [options]="daysOfWeek"
                                                                         [readonly]="false"
                                                                         [disabled]="false"
                                                                         [showOptionFilter]="false"
                                                                         [forceDropUp]="true"
                                                                         [style.width]="'85px'"
                                                                         [selectedOptions]="getSelect('weekday')"
                                                                         (click)="setSelectedType(ESelectedType.monthly_weekday, false)"
                                                                         (onOptionsSelect)="changeSelect('weekday',$event)">
                                                            </lumi-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--Year-->
                                            <div *ngIf="activeControlException.type === ESelectedType.yearly_date || activeControlException.type === ESelectedType.yearly_weekday">
                                                <div class="row">
                                                    <div class="col-1">{{'candelarrules.every' | translate}}</div>
                                                    <div class="col-11">
                                                        <div class="d-flex flex-wrap align-items-center">
                                                            <input type="radio" id="group4-1" name="group4" [checked]="activeControlException.type === ESelectedType.yearly_date" (click)="setSelectedType(ESelectedType.yearly_date)">
                                                            <label for="group4-1"></label>
                                                            <lumi-select class="lumi-select-field"
                                                                         [options]="daysOfMonth"
                                                                         [readonly]="false"
                                                                         [disabled]="false"
                                                                         [showOptionFilter]="false"
                                                                         [forceDropUp]="true"
                                                                         [style.width]="'55px'"
                                                                         [selectedOptions]="getSelect('day')"
                                                                         (click)="setSelectedType(ESelectedType.yearly_date, false)"
                                                                         (onOptionsSelect)="changeSelect('day',$event)">
                                                            </lumi-select>
                                                            <lumi-select class="lumi-select-field ml-2"
                                                                         [options]="monthsOfYear"
                                                                         [readonly]="false"
                                                                         [disabled]="false"
                                                                         [showOptionFilter]="false"
                                                                         [forceDropUp]="true"
                                                                         [style.width]="'85px'"
                                                                         [selectedOptions]="getSelect('month')"
                                                                         (click)="setSelectedType(ESelectedType.yearly_date, false)"
                                                                         (onOptionsSelect)="changeSelect('month',$event)">
                                                            </lumi-select>
                                                        </div>
                                                        <div class="d-flex flex-wrap align-items-center mt-1">
                                                            <input type="radio" id="group4-2" name="group4" [checked]="activeControlException.type === ESelectedType.yearly_weekday" (click)="setSelectedType(ESelectedType.yearly_weekday)">
                                                            <label for="group4-2"></label>
                                                            <lumi-select class="lumi-select-field"
                                                                         [options]="everyX"
                                                                         [readonly]="false"
                                                                         [disabled]="false"
                                                                         [showOptionFilter]="false"
                                                                         [forceDropUp]="true"
                                                                         [style.width]="'55px'"
                                                                         [selectedOptions]="getSelect('relative')"
                                                                         (click)="setSelectedType(ESelectedType.yearly_weekday, false)"
                                                                         (onOptionsSelect)="changeSelect('relative',$event)">
                                                            </lumi-select>
                                                            <lumi-select class="lumi-select-field ml-2"
                                                                         [options]="daysOfWeek"
                                                                         [readonly]="false"
                                                                         [disabled]="false"
                                                                         [showOptionFilter]="false"
                                                                         [forceDropUp]="true"
                                                                         [style.width]="'85px'"
                                                                         [selectedOptions]="getSelect('weekday')"
                                                                         (click)="setSelectedType(ESelectedType.yearly_weekday, false)"
                                                                         (onOptionsSelect)="changeSelect('weekday',$event)">
                                                            </lumi-select>
                                                            <span class="ml-2 mr-2">{{'candelarrules.of' | translate}}</span>
                                                            <lumi-select class="lumi-select-field"
                                                                         [options]="monthsOfYear"
                                                                         [readonly]="false"
                                                                         [disabled]="false"
                                                                         [showOptionFilter]="false"
                                                                         [forceDropUp]="true"
                                                                         [style.width]="'85px'"
                                                                         [selectedOptions]="getSelect('month')"
                                                                         (click)="setSelectedType(ESelectedType.yearly_weekday, false)"
                                                                         (onOptionsSelect)="changeSelect('month',$event)">
                                                            </lumi-select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="program-details-footer">
                        <div class="row">
                            <div class="col-9 dates align-self-end">
                                <span class="sumup" [innerHTML]="textualSumup"></span> <span *ngIf="!isAddingNewException"> - <span class="link-text" (click)="deleteActiveControlException()">{{'candelarrules.remove-item' | translate}}</span></span>
                            </div>
                            <div class="col-3 text-right" *ngIf="canEditExceptionProgram()">
                                <lumi-button (onClick)="saveException()"
                                             [disabled]="hasFormError"
                                             [size]="'small'"
                                             [rank]="'primary'"
                                             [label]="'candelarrules.save-btn' | translate">
                                </lumi-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</default-popup-wrapper-component>
