<div class="d-block mt-1 w-100">
    <div class="d-flex">
        <div class="form-label-part"></div>
        <div class="md-form input-group p-0 m-0">
            <lumi-button
                    (onClick)="handleClickCreateDeviceMapping()"
                    [rank]="'tertiary'"
                    [fullWidth]="true"
                    [size]="'medium'"
                    [icon]="'my_location'"
                    [label]="config.label">
            </lumi-button>
        </div>
    </div>
</div>
