/**
 * Created by Christiaan on 07/04/2017.
 */

import {
    Directive, ElementRef, NgZone, Output, EventEmitter
} from '@angular/core';

@Directive({
    selector: '[scrollOutsideAngular]'
})

export class ScrollOutsideAngularDirective
{
    private static readonly EVENT_SCROLL:string = "scroll";

    @Output('scrollOutsideAngular') scrollHandler: EventEmitter<any> = new EventEmitter();

    constructor(private elementRef: ElementRef, private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            this.elementRef.nativeElement.addEventListener(ScrollOutsideAngularDirective.EVENT_SCROLL, (event:any)=> {
                this.scrollHandler.emit(event);
            })
        });
    }
}
