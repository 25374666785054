<default-popup-wrapper-component [displayMode]="'DISPLAY_MODE_XLARGE'" [globalPopup]="globalPopup" [title]="'controlprogram.edit' | translate" (onClose)="closePopup($event)">
    <div class="control-program-container">
        <div class="row">
            <div class="pr-2 program-list-container" [ngClass]="{'open':(showList || activeControlProgram === null), 'is-hidden':(_laptopMode || _tabletMode || _mobileMode)}">
                <div class="header mt-1 mb-2">
                    <div class="search-container">
                        <div class="input-container">
                            <input type="search" class="search-field" (keyup.enter)="doSearch()" [(ngModel)]="currentActiveFilterQuery" placeholder="{{'treesearch.placeholder' | translate}}" />
                            <div class="search-buttons">
                                <i class="material-icons search-clear-btn" (click)="clearSearchQuery()" [ngClass]="{'active':hasSearchQuery()}">close</i>
                                <i class="material-icons" (click)="doSearch()">search</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="program-list pr-2">
                    <div *ngIf="controlProgramsList.length == 0 && !isAddingNewProgram" class="py-3 d-flex justify-content-center">
                        <div class="loader"></div>
                    </div>
                    <ng-container *ngIf="isAddingNewProgram">
                        <div class="program-item is-new is-selected">
                            <div class="name w-100 d-flex align-items-center">{{'control-program.new-program-name' | translate}}</div>
                            <div class="info w-100">
                                <ul>
                                    <li>{{'control-program.avg-dim-level-label' | translate}}: <span>100%</span></li>
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngFor="let controlProgram of controlProgramsList">
                        <div class="program-item" *ngIf="!controlProgram.isHidden || controlProgram.isSelected" (click)="getControlProgram(controlProgram.id)" [ngClass]="{'is-selected':controlProgram.isSelected}">
                            <div class="name w-100 d-flex align-items-center"><span class="color" [ngStyle]="{ 'background-color': controlProgram.color}" *ngIf="controlProgram.color"></span>{{controlProgram.name}}</div>
                            <div class="info w-100">
                                <ng-container *ngIf="controlProgram.description"><p class="description mb-1">{{controlProgram.description}}</p></ng-container>
                                <ul>
                                    <li *ngIf="controlProgram.avgDimLevel">{{'control-program.avg-dim-level-label' | translate}}: <span>{{controlProgram.avgDimLevel}}%</span></li>
                                    <li *ngIf="controlProgram.usedBy">{{'control-program.used-by-label' | translate}}: <span>{{controlProgram.usedBy}}</span></li>
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="ml-3">
                    <lumi-button (onClick)="addProgram()"
                                 [disabled]="disableAddProgramButton"
                                 [size]="'small'"
                                 [rank]="'primary'"
                                 [label]="'control-program.add-program-btn' | translate"
                                 [icon]="'add_circle'">
                    </lumi-button>
                </div>
                <div *ngIf="(_laptopMode || _tabletMode || _mobileMode) && activeControlProgram !== null" class="mobile-expand-icon" (click)="toggleArticleNavigation()"><i class="material-icons">menu_open</i></div>
            </div>
            <div class="pl-5 pr-3 program-details-container" *ngIf="isAddingNewProgram || activeControlProgram || isLoadingControlProgram" (click)="toggleArticleNavigation(true)">
                <div class="py-3 d-flex justify-content-center" *ngIf="isLoadingControlProgram">
                    <div class="loader"></div>
                </div>
                <div *ngIf="!isLoadingControlProgram">
                    <div class="program-details-header">
                        <div class="row">
                            <div class="col-8">
                                <div><label for="field-name" class="field-label">Naam</label> <input id="field-name" type="text" [(ngModel)]="activeControlProgram.name" [disabled]="!canEditControlProgram()" [attr.placeholder]="isAddingNewProgram ? ('control-program.new-program-name' | translate) : ('control-program.program-name-placeholder' | translate)" (ngModelChange)="checkForError()"  /></div>
                                <div><label for="field-description" class="field-label">Omschrijving</label> <input id="field-description" type="text" [(ngModel)]="activeControlProgram.description" [disabled]="!canEditControlProgram()" placeholder="{{'control-program.description-placeholder' | translate}}" /></div>
                            </div>
                            <div class="col-4">
                                <div class="d-flex justify-content-end align-items-center">
                                    <div class="mr-3"><input type="number" style="width: 80px; min-width: 80px;" min="0" max="100" [readonly]="!canEditControlProgram()" [(ngModel)]="this.activeControlProgram.defaultLightLevel" (ngModelChange)="changeDefaultLightLevel()" />%</div>
                                    <ngx-colors ngx-colors-trigger
                                                [(ngModel)]="activeControlProgram.color"
                                                (ngModelChange)="selectColor('default')"
                                                [hideTextInput]="true"
                                                [disableControl]="!canEditControlProgram()"
                                                [hideColorPicker]="false"
                                                [colorPickerControls]="'no-alpha'"
                                                [palette]="availableColorPallet"
                                                [disabled]="false">
                                    </ngx-colors>
                                </div>
                                <div class="active-period mt-3">
                                    <lumi-checkbox
                                                   [isDisabled]="!canEditControlProgram()"
                                                   [small]="true"
                                                   [right]="true"
                                                   (change)="changeAstroOffset($event)"
                                                   [value]="this.activeControlProgram.activePeriodAstroEnabled"
                                                   [label]="'control-program.use-offset-label' | translate">
                                    </lumi-checkbox>

                                    <div class="d-flex">
                                        <div class="d-flex align-items-center justify-content-start disabled-{{!this.activeControlProgram.activePeriodAstroEnabled}}">
                                            <i class="material-icons icon-half pr-0 pl-0">wb_sunny</i><i class="material-icons pl-0">keyboard_arrow_up</i>
                                            <input type="number" [max]="240" [min]="-240" [(ngModel)]="activeControlProgram.activePeriodAstroSunriseOffset" [disabled]="!this.activeControlProgram.activePeriodAstroEnabled" />
                                            <div class="ml-2">{{'control-program.minute-indicator' | translate}}</div>
                                        </div>
                                        <div class="d-flex ml-5 align-items-center justify-content-start disabled-{{!this.activeControlProgram.activePeriodAstroEnabled}}">
                                            <i class="material-icons icon-half pr-0 pl-0">wb_sunny</i><i class="material-icons pl-0">keyboard_arrow_down</i>
                                            <input type="number" [max]="240" [min]="-240" [(ngModel)]="activeControlProgram.activePeriodAstroSunsetOffset" [disabled]="!this.activeControlProgram.activePeriodAstroEnabled" />
                                            <div class="ml-2">{{'control-program.minute-indicator' | translate}}</div>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="program-details-inner mt-3 mb-2">
                        <div class="row">
                            <div class="col-12 col-lg-8 graph-parent">
                                <div id="graph"></div>
                            </div>
                            <div class="col-12 col-lg-4">
                                <div class="time-controls">
                                    <div class="d-flex align-items-center mb-1 mt-3">
                                        <h5 class="mb-0">{{'control-program.time-controls-title' | translate}}</h5>
                                        <div class="ml-auto">
                                            <lumi-button (onClick)="addTimeControl()"
                                                         [disabled]="!canEditControlProgram()"
                                                         [size]="'small'"
                                                         [rank]="'primary'"
                                                         [icon]="'add_circle'">
                                            </lumi-button>
                                        </div>
                                    </div>
                                    <div class="time-controls-inner">
                                        <div class="time-item d-flex align-items-center" *ngFor="let timeControl of activeControlProgram.timeControls">
                                                <date-input style="width: 110px; min-width: 110px;"
                                                            [style.min-width]="'110px'"
                                                            [inputType]="'time'"
                                                            [disabled]="!canEditControlProgram()"
                                                            [allowClear]="false"
                                                            [initialValue]="convertStringDateToFormat('1970-01-01 '+timeControl.time,'HH:mm')"
                                                            (onDateChanged)="changeTimeControlTime($event,timeControl.controlId)">
                                                </date-input>
                                            <input type="number" class="ml-3" style="min-width: 40px;" min="0" max="100" [readonly]="!canEditControlProgram()" [(ngModel)]="timeControl.dimLevel" (ngModelChange)="changeTimeControl()" /> <span>%</span>
                                            <div class="action ml-auto">
                                                <i class="material-icons" (click)="removeTimeControl(timeControl.controlId)">delete</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="program-details-footer">
                        <div class="row">
                            <div class="col-8 dates align-self-end">
                                <ng-container *ngIf="!isAddingNewProgram">
                                    {{'control-program.created-label' | translate}}: {{activeControlProgram.createdAt  | momentDate : 'll'}} <span *ngIf="activeControlProgram.updatedAt">- {{'control-program.updated-label' | translate}}: {{activeControlProgram.updatedAt  | momentDate : 'lll'}}</span> - <span class="link-text" (click)="deleteActiveControlProgram()">{{'control-program.remove-control-program' | translate}}</span>
                                </ng-container>
                            </div>
                            <div class="col-4 text-right" *ngIf="canEditControlProgram()">
                                <lumi-button (onClick)="copyProgram()"
                                             [disabled]="hasFormError"
                                             [size]="'small'"
                                             [rank]="'primary'"
                                             [icon]="'content_copy'"
                                             [title]="'Copy program'">
                                </lumi-button>
                                <lumi-button (onClick)="saveProgram()"
                                             [disabled]="hasFormError"
                                             [size]="'small'"
                                             [rank]="'primary'"
                                             [label]="'control-program.save-program-btn' | translate">
                                </lumi-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</default-popup-wrapper-component>
