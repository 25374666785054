import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpService2} from "../../../../shared/services/http-service-2.0/http2.0.service";
import {FormPostResult} from '../../../../shared/services/http-service-2.0/http.interface';
import {map} from "rxjs/operators";
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import {IControlProgram} from "./global-popup-control-program-edit.interface";


@Injectable()
export class GlobalPopupControlProgramEditService {
    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {}

    public getControlProgramsList(): Observable<FormPostResult>{
        this.logger.log('[GlobalPopupControlProgramEditService] ' + 'Get control program list')
        let postValues: any = {};
        return this.httpService2.doFormPostRequest(`control-form/control-program/list`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public getControlProgram(controlProgramId:number): Observable<IControlProgram>{
        this.logger.log('[GlobalPopupControlProgramEditService] ' + 'Get control program by id')
        return <Observable<IControlProgram>>this.httpService2.doGetRequest(`control-form/control-program/get/${controlProgramId}`, true);
    }

    public saveControlProgram(controlProgram:IControlProgram): Observable<FormPostResult>{
        this.logger.log('[GlobalPopupControlProgramEditService] ' + 'Save control program')
        let postValues: any = controlProgram;
        return this.httpService2.doFormPostRequest(`control-form/control-program/edit`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public deleteControlProgram(controlProgramId:number): Observable<FormPostResult>{
        this.logger.log('[GlobalPopupControlProgramEditService] ' + 'Delete control program')
        return <Observable<FormPostResult>>this.httpService2.doGetRequest(`control-form/control-program/delete/${controlProgramId}`, true);
    }
}
