import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    Cell,
    CellData,
    DataType,
    ExportType,
    Header,
    HeaderData,
    Row,
    RowClasses,
    RowData,
    TableData,
    TableOptions,
    TableSortDirection,
    TableSorting
} from './baseTable.interface';
import {SortService} from './sort.service';
import {BehaviorSubject} from 'rxjs';
import {BaseTableExportService} from './baseTableExport.service';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'base-table-component',
    templateUrl: `./baseTable.component.html`,
    providers: [SortService]
})
export class BaseTableComponent implements OnInit, OnDestroy {
    @ViewChild('scrollContainer', {static: false}) private scrollContainer: ElementRef<HTMLDivElement>;
    @ViewChild('topPlaceHolder', {static: false}) private topPlaceHolder: ElementRef<HTMLTableRowElement>;
    @ViewChild('bottomPlaceHolder', {static: false}) private bottomPlaceHolder: ElementRef<HTMLTableRowElement>;
    @Input() set isLoading(loading: boolean) {
        if (this.isInitialized) {
            this.isLoading$.next(loading);
            this.detectChanges();
        }
    }
    @Input() set tableOptions(tableOptions: TableOptions) {
        for (const [key, value] of Object.entries(tableOptions)) {
            this._tableOptions[key] = value;
        }
        if (this.isInitialized) {
            this.detectChanges();
        }
    }
    @Input() tableData: TableData;
    @Output() onRowAction: EventEmitter<RowData[]> = new EventEmitter();
    @Output() onRowDelete: EventEmitter<RowData> = new EventEmitter();
    @Output() onFilterColumn: EventEmitter<string | number> = new EventEmitter();
    @Output() onRowMouseOver: EventEmitter<RowData> = new EventEmitter();
    public readonly TABLE_SORT_DIRECTION = TableSortDirection;
    public allowFiltering: boolean = false;
    public headers: Header[] = [];
    public rows: Row[] = [];
    public viewModelRows: Row[] = [];
    public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public maxCellCharacters: number = 5;
    public maxCellLines: number = 3;
    public rowHeightInPX: number = 30;
    protected previousTopRowIndex: number = 0;
    protected previousScrollTop: number = 0;
    private isInitialized: boolean = false;
    private isDestroyed: boolean = false;
    
    public _tableOptions: TableOptions = {
        allowDeleteRow: false,
        maxRows: 200,
        allowMultiLineInRow: false,
        mobileMode: false,
        parentWidthPercentage: 100,
        clearHeader: false,
        canActivateRows: false,
        boldAllowed: true
    };

    constructor(
        protected cd: ChangeDetectorRef,
        protected tableSortService: SortService,
        protected exportService: BaseTableExportService,
        protected logger: LoggerService
    ) {
        cd.detach();
    }
    
    ngOnInit(): void {
        this.isInitialized = true;
        if (this.tableData && this.scrollContainer) {
            this.createTable();
        }
    }
    
    ngOnDestroy() {
        this.isDestroyed = true;
        this.headers = [];
        this.rows = [];
    }
    
    protected detectChanges(): void {
        if (!this.isDestroyed) {
            this.cd.detectChanges();
        }
    }
    
    public getActiveSorting(): TableSorting {
        return this.tableSortService.getActiveSorting();
    }
    
    public onScroll() {
        if (this.isScrollDirectionVertical()) {
            this.virtualScroll();
        }
        this.previousScrollTop = this.scrollContainer.nativeElement.scrollTop;
    }
    
    public updateTable(selectedItems?: string[] | number[], newSorting?: TableSorting, keepScrollPosition: boolean = false): void {
        this.isLoading$.next(true);
        this.detectChanges();
        setTimeout(() => {
            this.isLoading$.next(false);
            this.createTable(selectedItems);
            this.setNewSorting(newSorting);
            if (!keepScrollPosition) {
                this.setView(0);
                this.scrollToScrollTop(0);
            } else {
                this.reapplyView();
            }
        });
    }
    
    public checkActiveColumnFilter(columnId: string | number): any {
        // does nothing in base component
    }
    
    public filterColumn(event: MouseEvent, columnId: string | number): any {
        // does nothing in base component
    }
    
    public onRowClick(event: MouseEvent, clickedRow: Row): void {
        if (!this._tableOptions.canActivateRows) {
            return;
        }
        this.onRowAction.emit(
            [this.tableData.rows.find(row => row.uniqueId === clickedRow.uniqueId)]
        );
    }
    
    public deleteRow(event: MouseEvent, clickedRow: Row): void {
        // overlapping click events
        event.stopPropagation();
        
        this.onRowDelete.emit(
            this.tableData.rows.find(row => row.uniqueId === clickedRow.uniqueId)
        );
    }
    
    public toggleTruncation(cell: Cell): void {
        cell.truncate = !cell.truncate;
        this.rows.forEach(row =>
            row.cells.find(_cell => cell.columnId === _cell.columnId).truncate = false
        );
        this.detectChanges();
    }
    
    public sortByColumn(columnId: string | number, sortDirection?: TableSortDirection) {
        this.isLoading$.next(true);
        this.detectChanges();
        setTimeout(() => {
            this.isLoading$.next(false);
            this.tableSortService.sortByColumn(this.rows, columnId, this.headers, sortDirection);
            this.reapplyView();
        });
    }
    
    public handleMouseDownRow(event: MouseEvent): void {
        // Prevent default when ctrl clicking, so that the row won't be 'rectangled' by some browsers
        if (event.ctrlKey || event.metaKey || event.shiftKey) {
            event.preventDefault();
        }
    }
    
    public handleMouseOverRow(event: MouseEvent, mouseOveredRow: Row): void {
        this.highlightRowByUniqueId(mouseOveredRow.uniqueId);
        this.onRowMouseOver.emit(
            this.tableData.rows.find(row => row.uniqueId === mouseOveredRow.uniqueId)
        );
    }
    
    public highlightRowByUniqueId(uniqueId: string | number): void {
        // can only highlight one row at a time
        this.getVisibleRows().filter(row => row.isHighlighted).forEach(row => row.isHighlighted = false);
        if (this.getVisibleRows().some(row => String(row.uniqueId) === String(uniqueId))) {
            this.getVisibleRows().find(row => String(row.uniqueId) === String(uniqueId)).isHighlighted = true;
        }
        this.detectChanges();
    }
    
    public scrollToRow(uniqueId: string | number): void {
        const scrollTop: number = Math.max(0, (this.getVisibleRows().findIndex(
                row => String(row.uniqueId) === String(uniqueId)) - 3
        )) * this.rowHeightInPX;
        this.scrollToScrollTop(scrollTop);
    }
    
    public getRowClass(row: Row): string {
        return row.isHighlighted ?
            (this._tableOptions.canActivateRows ?
                (row.isSelected ? RowClasses.ACTIVE_SELECTED : RowClasses.ACTIVE_SELECTABLE) : RowClasses.ACTIVE) :
            (this._tableOptions.canActivateRows ?
                (row.isSelected ? RowClasses.INACTIVE_SELECTED : RowClasses.INACTIVE_SELECTABLE) : RowClasses.INACTIVE);
    }
    
    public getVisibleRows(): Row[] {
        return this.rows.filter(row => row.isVisible);
    }
    
    public getHiddenRows(): Row[] {
        return this.rows.filter(row => !row.isVisible);
    }
    
    public exportTable(exportType: ExportType, selectionOnly:boolean = false) {
        let rows: Row[] = this.getVisibleRows();
        if(selectionOnly){
            rows = rows.filter(_x => {
                return _x.isSelected
            })
        }
        rows.forEach(row => row.cells.sort((cellA: Cell, cellB: Cell) => {
            return cellA.columnRank - cellB.columnRank;
        }));
        switch (exportType) {
            case ExportType.CSV:
                this.exportService.exportToCsv(this.headers, rows);
                break;
            case ExportType.XLSX:
                this.exportService.exportToExcel(this.headers, rows);
                break;
            case ExportType.CLIPBOARD:
                this.exportService.copyToClipboard(this.headers, rows);
                break;
        }
    }
    
    public isRowActuallyVisibleInWindow(uniqueId: string | number) {
        const rowIndex: number = this.viewModelRows.findIndex(row => String(row.uniqueId) === String(uniqueId));
        
        if (rowIndex < 0 || !this.scrollContainer) {
            return false;
        }
        
        const element: Element = this.scrollContainer.nativeElement.children[0].children[1].children[rowIndex + 1];
        const elementRect = element.getBoundingClientRect();
        const scrollContainer: Element = this.scrollContainer.nativeElement;
        const scrollContainerRect = scrollContainer.getBoundingClientRect();
        
        return elementRect.top > scrollContainerRect.top
            && elementRect.bottom < scrollContainerRect.bottom;
    }
    
    public reapplyTruncation() {
        this.detectChanges();
        setTimeout(() => {
            this.setMaxCellCharacters();
            if (this.rows && this.rows.length > 0) {
                this.resetTruncation();
                this.detectChanges();
            }
        });
    }
    
    protected reapplyView(): void {
        this.resetTruncation();
        this.setView(this.previousTopRowIndex);
        this.scrollToScrollTop(this.previousScrollTop);
    }
    
    protected scrollToScrollTop(scrollTop: number): void {
        if (!this.scrollContainer) {
            return;
        }
        try {
            this.scrollContainer.nativeElement.scrollTo({
                top: scrollTop
            });
        } catch (e) {
            this.logger.error(e);
        }
    }
    
    protected setNewSorting(newSorting?: TableSorting): void {
        if (this.rows.length < 1) {
            return;
        }
        const activeSorting = this.tableSortService.getActiveSorting();
        if (newSorting && JSON.stringify(newSorting) !== JSON.stringify(activeSorting)) {
            this.tableSortService.sortByColumn(this.rows, newSorting.columnId, this.headers, newSorting.sortDirection);
            return;
        }
        
        if (activeSorting) {
            this.tableSortService.sortByColumn(this.rows, activeSorting.columnId, this.headers, activeSorting.sortDirection);
            return;
        }

        const hasSortingColumn = this.headers.findIndex(header => header.sorting);
        if (this.headers.some(header => header.isVisible)) {
            if(hasSortingColumn >= 0){
                const sortDirection = this.headers.find(header => header.sorting).sortDirection == 0 ? TableSortDirection.SORT_DIRECTION_DESC : TableSortDirection.SORT_DIRECTION_ASC;
                this.tableSortService.sortByColumn(this.rows, this.headers.find(header => header.sorting).columnId, this.headers, sortDirection);
            } else {
                this.tableSortService.sortByColumn(this.rows, this.headers.find(header => header.isVisible).columnId, this.headers, TableSortDirection.SORT_DIRECTION_ASC, true);
            }
        }
    }
    
    protected createTable(selectedItems?: (string | number)[]): void {
        if (this.tableData && this.tableData.headers && this.tableData.rows) {
            this.setMaxCellCharacters();
            this.setRowHeight();
            this.headers = this.createHeaders();
            this.rows = this.createRows(selectedItems);
        } else {
            this.rows = [];
            this.headers = [];
        }
    }
    
    protected setView(topRowIndex: number): void {
        this.previousTopRowIndex = topRowIndex;
        this.viewModelRows = [];
        
        if (this.getVisibleRows().length < this._tableOptions.maxRows) {
            this.viewModelRows = [...this.getVisibleRows()];
        } else {
            const bottomIndex: number = Math.max(
                topRowIndex - Math.round(this._tableOptions.maxRows * (1 / 3)),
                0
            );
            const topIndex: number = Math.max(
                topRowIndex + Math.round(this._tableOptions.maxRows * (2 / 3)),
                this._tableOptions.maxRows
            );
            this.viewModelRows = [...this.getVisibleRows().slice(bottomIndex, topIndex)];
        }
        
        this.sizePlaceholders();
        this.sortColumnsByRank();
        this.detectChanges();
    }
    
    private resetTruncation(): void {
        this.rows.forEach(row =>
            row.cells.forEach((cell: Cell) =>
                cell.truncate = (cell.label ? cell.label.length > this.maxCellCharacters : false)
                    && this.headers.find(header => header.isVisible).columnId !== cell.columnId
            )
        );
    }
    
    private sortColumnsByRank(): void {
        this.headers.sort((headerA: Header, headerB: Header) => {
            return headerA.columnRank - headerB.columnRank;
        });
        this.viewModelRows.forEach(row => row.cells.sort((cellA: Cell, cellB: Cell) => {
            return cellA.columnRank - cellB.columnRank;
        }));
    }
    
    private createHeaders() {
        const firstVisibleHeaderIndex = this.tableData.headers.findIndex(header => header.isVisible) || 0;
        return this.tableData.headers.map(
            (header: HeaderData, index: number) => this.createHeader(header, index, firstVisibleHeaderIndex)
        );
    }
    
    private createRows(selectedItems?: (string | number)[]): Row[] {
        return this.tableData.rows.map((rowData: RowData) => {
            return this.createRow(rowData, selectedItems);
        });
    }
    
    private createRow(rowData: RowData, selectedItems?: (string | number)[]): Row {
        return {
            uniqueId: rowData.uniqueId,
            cells: rowData.cells.map((cellData: CellData, cellIndex: number) => {
                return this.createCell(cellData, cellIndex);
            }),
            isVisible: true,
            isSelected: selectedItems ? selectedItems.some(item => String(item) === String(rowData.uniqueId)) : false,
            isHighlighted: false
        };
    }
    
    private createCell(cellData: CellData, index: number): Cell {
        return {
            label: cellData.label,
            dataType: cellData.dataType,
            columnId: this.headers[index].columnId,
            bold: this.headers[index].bold,
            truncate: cellData.label ? cellData.label.length > this.maxCellCharacters : false,
            isVisible: this.headers[index].isVisible,
            columnRank: this.headers[index].columnRank,
            children: cellData.children ? cellData.children : null
        };
    }
    
    private createHeader(headerData: HeaderData, index: number, firstVisibleHeaderIndex: number): Header {
        return {
            columnId: headerData.columnId,
            label: headerData.label,
            code: headerData.code,
            type: headerData.type,
            bold: this._tableOptions.boldAllowed ? (headerData.bold || index === firstVisibleHeaderIndex) : false,
            isVisible: headerData.isVisible,
            sorting: headerData.sorting,
            sortDirection: headerData.sorting
                ? (headerData.sortDirection == 0 ? TableSortDirection.SORT_DIRECTION_DESC : TableSortDirection.SORT_DIRECTION_ASC)
                : TableSortDirection.SORT_DIRECTION_UNSET,
                //: (index === firstVisibleHeaderIndex ? TableSortDirection.SORT_DIRECTION_DESC : TableSortDirection.SORT_DIRECTION_UNSET),
            columnRank: headerData.columnRank
        };
    }
    
    private sizePlaceholders(): void {
        if (!this.topPlaceHolder || !this.bottomPlaceHolder) {
            return;
        }
        const indexOfCurrentTop: number = this.getVisibleRows().findIndex(
            row => String(row.uniqueId) === String(this.viewModelRows[0]?.uniqueId)
        );
        const indexOfCurrentBottom: number = this.getVisibleRows().findIndex(
            row => String(row.uniqueId) === String(this.viewModelRows[this.viewModelRows.length - 1]?.uniqueId)
        );
        
        const numberOfRowsBelowCurrentBottom: number = this.getVisibleRows().length - indexOfCurrentBottom - 1;
        const topHeight: number = indexOfCurrentTop * this.rowHeightInPX;
        const bottomHeight: number = numberOfRowsBelowCurrentBottom * this.rowHeightInPX;
        this.topPlaceHolder.nativeElement.setAttribute('height', `${topHeight}px`);
        this.bottomPlaceHolder.nativeElement.setAttribute('height', `${bottomHeight}px`);
    }
    
    private setMaxCellCharacters() {
        if (this._tableOptions.allowMultiLineInRow || !this.scrollContainer) {
            this.maxCellCharacters = 200;
            return;
        }
        const scrollContainer: Element = this.scrollContainer.nativeElement;
        const totalWidthInPX = scrollContainer.getBoundingClientRect().width;
        const fontSizeInPX = parseFloat(window.getComputedStyle(scrollContainer, null).getPropertyValue('font-size'));
        const meanFontWidth = fontSizeInPX * 0.4;

        this.maxCellCharacters = Math.floor((
            ((totalWidthInPX / meanFontWidth) * (this._tableOptions.parentWidthPercentage / 100)) /
            this.tableData.headers.filter(header => header.isVisible).length
        ));
        this.maxCellCharacters = Math.max(this.maxCellCharacters, 20);
    }
    
    // ================== custom virtual scroll ===============================
    private virtualScroll() {
        if (this.isBottomPlaceholderInViewport() || this.isTopPlaceholderInViewport()) {
            const newTopRowIndex: number = Math.round(
                this.scrollContainer.nativeElement.scrollTop /
                this.rowHeightInPX
            );
            this.setView(newTopRowIndex);
        }
    }
    
    private isScrollDirectionVertical(): boolean {
        if (!this.scrollContainer) {
            return false;
        }
        return this.scrollContainer.nativeElement.scrollTop !== this.previousScrollTop;
    }
    
    private isBottomPlaceholderInViewport(): boolean {
        if (!this.scrollContainer || !this.bottomPlaceHolder) {
            return false;
        }
        return this.bottomPlaceHolder.nativeElement.getBoundingClientRect().top <=
            this.scrollContainer.nativeElement.getBoundingClientRect().bottom;
    }
    
    private isTopPlaceholderInViewport(): boolean {
        if (!this.topPlaceHolder) {
            return false;
        }
        return this.topPlaceHolder.nativeElement.getBoundingClientRect().bottom > 0;
    }
    
    private setRowHeight(): void {
        if (!this.scrollContainer) {
            return;
        }
        let numberOfLines: number = (this._tableOptions.allowMultiLineInRow || this._tableOptions.mobileMode) ? 2 : 1;
        if (this.tableData.headers.some(header => header.type === DataType.COMPLEX && header.isVisible)) {
            numberOfLines = this.tableData.rows.reduce((_numberOfLines, row) => {
                const complexCells = row.cells.filter(
                    (cell, index) => cell.dataType === DataType.COMPLEX && this.tableData.headers[index].isVisible
                );
                complexCells.forEach(cell => {
                    const linesNeededForCell = cell.children.length + (cell.label ? 1 : 0);
                    _numberOfLines = (linesNeededForCell > _numberOfLines) ? linesNeededForCell : _numberOfLines;
                });

                return _numberOfLines;
            }, 1);
            numberOfLines = Math.min(numberOfLines, this.maxCellLines + 1);
        }
        const scrollContainer: Element = this.scrollContainer.nativeElement;
        const fontSizeInPX = parseFloat(window.getComputedStyle(scrollContainer, null).getPropertyValue('font-size'));
        this.rowHeightInPX = numberOfLines * (1.6 * fontSizeInPX);
    }
}
