import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService2} from '../../../../services/http-service-2.0/http2.0.service';
import {IDeviceStateHistory, IDeviceStateOverview} from './device-state.interface';
import {map} from 'rxjs/operators';
import {LoggerService} from "../../../../services/logger/logger.service";

@Injectable({
    providedIn: 'root'
})
export class DeviceStateService {

    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {}

    public getDeviceStateOverview(urlPrefix: string): Observable<IDeviceStateOverview> {
        return <Observable<IDeviceStateOverview>>this.httpService2.doGetRequest(`${urlPrefix}get-state`);
    }

    public getDeviceStateHistoryOverview(urlPrefix: string, stateTypeId: number | null): Observable<IDeviceStateHistory[]> {
        let historyUrl = urlPrefix+'get-history';
        if(stateTypeId !== null){
            historyUrl = urlPrefix+'get-history/'+stateTypeId;
        }
            

        return <Observable<IDeviceStateHistory[]>>this.httpService2.doGetRequest(historyUrl);
    }

    public toggleSuppress(urlPrefix: string, stateTypeId: number, shouldSuppress: boolean): Observable<void> {
        const postValues = {state_type_id: stateTypeId, should_suppress: shouldSuppress};
        return this.httpService2.doFormPostRequest(`${urlPrefix}toggle-state-suppress`, JSON.parse(JSON.stringify(postValues))).pipe(
            map((result) => {
                this.logger.log('[DeviceStateService] toggleSuppress result', result);
            })
        );
    }
}
