import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {SimpleTreeNode} from './simple-tree-node.interface';

@Component({
    selector: 'simple-tree-node',
    templateUrl: './simple-tree-node.component.html',
})
export class SimpleTreeNodeComponent implements OnInit, OnChanges {
    @Input() node: SimpleTreeNode;
    @Input() nodeIcon: string;
    @Input() selectedNodeId: number;
    @Output() onNodeClick: EventEmitter<number> = new EventEmitter();
    
    public expanded: boolean = false;
    
    constructor() {
    }
    
    ngOnInit() {
    }
    
    ngOnChanges() {
        if (!this.expanded) {
            this.expanded = this.node
                && this.node.children
                && this.node.children.length > 0
                && this.node.children.some(childNode => childNode.nodeId === this.selectedNodeId);
        }
    }
    
    public handleClickNode($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        
        if (this.node.children) {
            this.handleExpandNode();
        } else {
            this.onNodeClick.emit(this.node.nodeId);
        }
    }
    
    public handleChildNodeClick(nodeId: number): void {
        this.onNodeClick.emit(nodeId);
    }
    
    private handleExpandNode(): void {
        this.expanded = !this.expanded;
    }
}
