import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from '@angular/core';
import {DOCUMENT} from "@angular/common";
import {ScrollSpyService} from "../../services/scroll-spy.service";
import {GlobalModel} from "../../../../services/state/global.model";
import { Subscription } from 'rxjs';
import {TranslateService} from "../../../../services/translate/translate.service";
import {GlobalEvent} from "../../../../interfaces/global-event";
import {ChangeableComponent} from '../../../changeable/changeable.component';
import {PageScrollInstance, PageScrollService} from 'ngx-page-scroll-core';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-section-component',
    template : `
        <div class="{{getContainerClass()}}" (globalResizeOutsideAngular)="onWindowResize($event)">
            <div class="list-group" id="listGroup" (click)="handleClickSectionIcon($event)">
                <!-- TODO: configurable or randomly generated list group id, otherwise no two listGroups can exist ever on the same page. ID must be unique -->
                
                <a href="#formTop" title="{{title}}" (click)="scrollToElement($event, '#formTop')" class="{{getSectionClass(true)}}">{{ getSectionLabel({label:title})}}</a>
                <ng-container *ngFor="let section of sections;">
                    <div class="d-flex">
                        <a #viewSection href="#{{section.hash}}" title="{{section.label + (section.count && section.count >= 0?' - ' + ts.translate('section.itemcount', [section.count]):'')}}"
                           (click)="scrollToElement($event, '#' + section.hash)" class="{{getSectionClass(false, section)}} {{section.active?'active':''}}">
                            <i *ngIf="getSectionIcon(section)" class="material-icons">{{section.icon}}</i>
                            <div *ngIf="section.count && section.count >= 0" class="form-section-counter">
                                <div class="d-flex justify-content-center align-items-center">{{section.count}}</div>
                            </div>
                            {{getSectionLabel(section)}}
                        </a>
                    </div>
                </ng-container>

                <div class="py-3 {{document.querySelector('.form-group .default-button-container button')?'':'d-none'}}">
                    <ng-content></ng-content>
                </div>
                
            </div>
            <div *ngIf="!mobileMode && (sectionLayout == SECTION_LAYOUT_ICON_BAR)" (click)="handleClickCollapse($event)" title="{{'In- en uitklappen' | translate}}" class="{{getSectionClass(false)}} form-section-collapse-button">
                <i class="material-icons">{{collapsed?'keyboard_arrow_left':'keyboard_arrow_right'}}</i>
            </div>
        </div>
    `
})

export class FormSectionComponent extends ChangeableComponent implements OnInit, OnDestroy{

    //Section types, add when nu layout type is needed
    public static readonly SECTION_LAYOUT_TEXT:number = 1;
    public static readonly SECTION_LAYOUT_ICON_BAR:number = 2;

    //Scrolloffset for auto-scrolling to elements
    public static readonly DEFAULT_SCROLL_DURATION:number = 500;
    public static readonly DEFAULT_SCROLL_OFFSET:number = 25;
    public static readonly BUTTONBAR_SCROLL_OFFSET:number = 65;
    //private readonly RECALCULATE_BUFFER_TIME: number = 250; //Milliseconds

    private _formData:any;
    private expanded:boolean = true;

    @Input() title:string = "";

    @Input()
    set formData(data:any)
    {
        this._formData = data;
        this.rebuildSections(this._formData);
    }

    get formData():any
    {
        return this._formData;
    }

    @Input()
    private item:any;

    @Input()
    public collapsed:boolean;

    @Output() onToggleCollapse: EventEmitter<any> = new EventEmitter();

    @ViewChildren('viewSections') viewSections:QueryList<any>;
    
    public sectionLayout:number = FormSectionComponent.SECTION_LAYOUT_TEXT;
    private formComponentNativeElement:any;
    public sections:any;
    private formGroups:any;
    private formGroupsSelector:string = '.form-group:not([style*="display:none"])';
    private listGroup:any;
    private listGroupSelector:string = '.list-group-item';
    public mobileMode:boolean = false;
    private hasFormTopButtonBar:boolean = false;

    //private recalculateScrollTimeout:any;
    private subMobileMode:Subscription = null;

    public constructor(private scrollSpyService:ScrollSpyService, private pageScrollService:PageScrollService, @Inject(DOCUMENT) public document: any, private elRef: ElementRef, private ngZone:NgZone, private model:GlobalModel, private cd:ChangeDetectorRef, public ts:TranslateService, protected elementRef:ElementRef, protected logger:LoggerService)
    {
    super(elementRef);
        /*
        this.ngZone.runOutsideAngular(() => {
            //Set scroll settings
            PageScrollConfig.defaultEasingLogic = {
                ease: (t: number, b: number, c: number, d: number): number => {
                    // easeInOutExpo easing
                    if (t === 0) return b;
                    if (t === d) return b + c;
                    if ((t /= d / 2) < 1) return c / 2 * Math.pow(2, 10 * (t - 1)) + b;
                    return c / 2 * (-Math.pow(2, -10 * --t) + 2) + b;
                }
            };

            PageScrollConfig.defaultDuration = 500;
        });
*/
        
        this.subMobileMode = this.model.mobileMode.subscribe((value:boolean) => {
            this.mobileMode = value;
            this.cd.markForCheck();
        })

    }

    ngOnInit(){
        this.isCollapsible();
    }

    ngOnDestroy(){
        if (this.subMobileMode){
            this.subMobileMode.unsubscribe();
        }
    }

    private isCollapsible()
    {
        if (this.sections){
            for (let j=0;j<this.sections.length;j++){
                if (this.sections[j].hasChildSection){
                    for (let i=0;i<this.sections[j].children.length;i++){
                        this.sections[j].expanded = this.sections[j].children[i].collapsible;
                    }
                }
            }
        }
    }
    
    public handleClickExpand(event:any,section:any):void
    {
        section.expanded = !section.expanded;

        if(section.level == 1 && section.hasChildSection){
            for (let i=0;i<section.children.length;i++){
                section.children[i].expanded = !section.children[i].expanded;
            }
        }
        this.cd.detectChanges();
    }

    //Expand the entire form when clicking on a section icon, when it was hidden to the right of the screen
    public handleClickSectionIcon(event:any){
        this.onToggleCollapse.emit({collapsed:false});
    }

    //Collapse the entire form to the right of the screen
    public handleClickCollapse(event:any):void{
        this.onToggleCollapse.emit({collapsed:!this.collapsed});
    }
    
    public getSectionLabel(section:any):string
    {
        if (this.sectionLayout == FormSectionComponent.SECTION_LAYOUT_ICON_BAR){
            return "";
        }

        return section.label;
    }
    
    public getSectionIcon(section:any):string
    {
        if (this.sectionLayout == FormSectionComponent.SECTION_LAYOUT_TEXT){
            return null;
        }

        return section.icon;
    }
    
    public getContainerClass():string{

        let result:string = '';

        if (this.sectionLayout == FormSectionComponent.SECTION_LAYOUT_ICON_BAR){
            result += " module-section-container"; // TODO: make this class more generic
        }

        return result;
    }

    // TODO: isHeader is ook gewoon gelijk aan een section.level van 0
    public getSectionClass(isHeader:boolean, section:any = null):string
    {
        let result:string = "list-group-item list-group-item-action";

        if (this.sectionLayout == FormSectionComponent.SECTION_LAYOUT_ICON_BAR){
            result += " module-section-item"; // TODO: make this class more generic
        }

        if(section && section.level > 1){
            if(section.expanded){
                result += ' d-block';
            } else {
                result += ' d-none';
            }
        }

        if (this.sectionLayout == FormSectionComponent.SECTION_LAYOUT_TEXT){
            if (!isHeader){
                //TODO: ooit een completere implementatie die ook werkt voor meer niveaus of de icon versie. Voor nu is dit voldoende
                if (section && section.level > 1){
                    // Deep items (beyond second level)
                    result += " module-text-section-item-child";
                }else{
                    // Second level items
                    result += " module-text-section-item";
                }
            }
        }
        return result;
    }

    // Creates a flat array of sections from nested children
    private getSectionsRecursive(children:any[], level:number):any[]
    {
        let sections:any = [];
        let grandChildren: any = [];

        children.forEach((child:any) => {

            // Build children first so you can push them on the parent
            if (child.children) {
                grandChildren = this.getSectionsRecursive(child.children, level + 1);
            }

            // Create parent section
            if (child.attr && child.attr.section && (child.attr.visible == true || child.attr.visible == undefined)){
                sections.push({children:grandChildren, hash:child.name, icon:child.attr.section.icon, label:child.label || child.attr.section.label, count: child.attr.section.count, level: level, hasChildSection:(child.children && child.children.length > 0 && child.children[0].type == 'object'), collapsible:child.attr.collapsible, active:false})

                // Set the parent on the children
                grandChildren.forEach((grandChild:any) => {
                    grandChild.parentSection = sections[sections.length -1];
                });
            }

            // Add child-sections after the parent
            if (grandChildren && grandChildren.length > 0){
                sections = sections.concat(grandChildren);
            }
        });

        return sections;
    }

    public rebuildSections(formData:any):void
    {
        if (!formData){
            this.sections = null;
            return
        }

        //Check if first item has attr isFixedTopButtonBar
        if (formData.schema.children.length > 0 && formData.schema.children[0].attr && formData.schema.children[0].attr.isFixedTopButtonBar){
            this.hasFormTopButtonBar = true;
        }

        this.sections = this.getSectionsRecursive([formData.schema], 0);

        //TODO: de sections moeten op het scherm staan. Daarop wordt gewacht. Maar kan dit mooier?
        setTimeout(()=>{
            this.updateScrollSpy();
        }, 100);
    }

    public connectForm(formComponent:any, layout:number):void
    {
        this.sectionLayout = layout;
        this.formComponentNativeElement = formComponent.nativeElement;

        //TODO: de sections moeten op het scherm staan. Daarop wordt gewacht. Maar kan dit mooier?
        setTimeout(()=>{
            this.updateScrollSpy();
        }, 100)
    }

    //Setting scrollSmooth to false will enable direct scrolling, no transition
    public scrollToElement(event:any, hash:string, scrollSmooth:boolean = true, autoExpandSection:boolean = true):void
    {
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_SCROLL_TO_SECTION, {hash:hash, autoExpandSection:autoExpandSection}));

        this.ngZone.runOutsideAngular(() => {
            // Prevent executing default link on click
            if (event) {
                event.preventDefault();
            }

            //Set the scroll duration setting
            let duration:number = 0;
            let buttonbarHeight:number = 0;
            if (scrollSmooth){
                duration = FormSectionComponent.DEFAULT_SCROLL_DURATION;
            }

            if (this.formComponentNativeElement)
            {

                buttonbarHeight = $('.form-group.form-group--mutationButtons.button-bar-fixed').outerHeight() - 8 || 0;

                this.logger.log("[FormSectionComponent] " + "buttonbarHeight: " + buttonbarHeight + " duration: " + duration);

                //TODO: moet de service ook weer een keer gestopped worden of is dat niet nodig? Oppassen: je moet wel meerdere forms tegelijk op het scherm kunnen blijven scrollen
                let pageScrollInstance: PageScrollInstance = new PageScrollInstance({
                    document: this.document,
                    scrollTarget: hash,
                    scrollViews: [this.formComponentNativeElement],
                    scrollOffset: FormSectionComponent.DEFAULT_SCROLL_OFFSET + buttonbarHeight,
                    duration: duration
                });

                //pageScrollOffset: FormSectionComponent.DEFAULT_SCROLL_OFFSET + (this.hasFormTopButtonBar?buttonbarHeight:0),

                this.pageScrollService.start(pageScrollInstance);
            }
        });
    }

    private updateScrollSpy():void
    {
        if (this.formComponentNativeElement) {


            //The form might have changed, update the elements
            this.formGroups = this.formComponentNativeElement.querySelectorAll(this.formGroupsSelector);
            this.listGroup = this.elRef.nativeElement.querySelectorAll(this.listGroupSelector);


            this.logger.log("[FormSectionComponent] " + "updateScrollSpy, formgroups: ", this.formGroups);

            //Iedere list zou ook als group moeten voorkomen. Zo niet, dan kan er niet gehighlight worden
            /*this.logger.log("[FormSectionComponent] " + "Listgroups: ", this.listGroup);
            this.logger.log("[FormSectionComponent] " + "this.formGroups: ", this.formGroups);*/

            //TODO: hier ook kijken wat handig is. Als je een nieuw formulier opent zou het voor de gebruiker het handigste zijn als de oude sectie nog actief is, indien die nog bestaat, en de scroll moet daar dan ook nog staan (gaat automatisch?)
            //TODO: Bij een bestaand form zou de sectie ook gehighlight moeten blijven (bv na een submit)
            //TODO: dus zoiets als: is er een laatst geselecteerde sectie? ja> check of die nog bestaat en highlight hem. nee > scroll naar top en highlight de root
            this.scrollSpyService.handleScroll(this.formComponentNativeElement, this.formGroups, this.hasFormTopButtonBar, this.sections);

            try{
                this.cd.detectChanges();
            }catch(e){
                this.logger.log("[FormSectionComponent] " + "Warning: trying to trigger change detection on a destroyed view");
            }
        }
    }

    //Buffer the resize events and only trigger the recalculate function once
    public onWindowResize(event:Event):void
    {
        /*clearTimeout(this.recalculateScrollTimeout);
        this.recalculateScrollTimeout = setTimeout(
            () => {
                this.logger.log("[FormSectionComponent] " + "Buffered Resize: redrawCalculateScroll");
                this.scrollSpyService.handleScroll(this.formComponentNativeElement, this.formGroups, this.listGroup);
            }, this.RECALCULATE_BUFFER_TIME
        );*/

        this.logger.log("[FormSectionComponent] " + "Buffered Resize: redrawCalculateScroll");
        this.scrollSpyService.handleScroll(this.formComponentNativeElement, this.formGroups, this.hasFormTopButtonBar, this.sections);
        this.cd.detectChanges();
    }

    public handleFormScroll(event:any):void
    {
        if (this.formGroups){
            this.scrollSpyService.handleScroll(event.target, this.formGroups, this.hasFormTopButtonBar, this.sections);
            this.cd.detectChanges();
        }else{
            // This can be the case when the event triggers before the html-elements are build
            this.logger.log("[FormSectionComponent] " + "No formgroups found");
        }
    }

    get SECTION_LAYOUT_ICON_BAR():number{
        return FormSectionComponent.SECTION_LAYOUT_ICON_BAR;
    }

/*    ngOnDestroy(){
        clearTimeout(this.recalculateScrollTimeout);
    }*/
}
