import {Component, HostBinding, Renderer2} from "@angular/core";
import 'moment/locale/nl';
import {AbstractFormFieldComponent} from "../abstract/abstract-form-field.component";
import {ValidationConstraintService} from "../../services/validation-constraint.service";
import {TooltipService} from "../../../../services/tooltip/tooltip.service";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TranslateService} from "../../../../services/translate/translate.service";
import Utils from "../../../../utils/utils";
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-input',
    template: `
    <div class="multi-input d-flex w-100">
        <ng-container [formGroup]="group">
            
            <form-component-label [batchUpdateMode]="batchUpdateMode" [config]="config" [included]="included" (onClickInclude)="handleClickInclude($event)"></form-component-label>
            
            <div class="input-container md-form input-group p-0 m-0">

                <div class="w-50 d-inline-block mr-2">
                    <div class="form-read-only-label">{{config.attr.present_value}}</div>
                </div>

                <ng-container *ngIf="hasInputGroupAddonBefore()">
                    <span class="input-group-addon">{{ config.attr.inputGroup.addOn.text }}</span>
                </ng-container>
                <div class="w-50 d-inline-block">
                    <input  #currentFormField
                            name="{{config.name}}"
                            type="text"
                            [attr.placeholder]="config.placeholder"
                            [formControlName]="config.name"
                            [(ngModel)]="initialValue"
                    >
                </div>
                <ng-container *ngIf="hasInputGroupAddonAfter()">
                    <span class="input-group-addon">{{ config.attr.inputGroup.addOn.text }}</span>
                </ng-container>
    
                <ng-container *ngIf="hasSyncStatus()">
                    <span><i class="material-icons unselectable-content" [title]="getStatusText(config.attr.syncStatus)">{{getStatusIcon(config.attr.syncStatus)}}</i></span>
                </ng-container>
    
                <ng-container *ngIf="allowScannerInput()">
                    <div class="pl-2">
                        <div class="card {{!isControlDisabled()?'cursor-pointer':'d-none'}}" (click)="handleClickScanner($event)" [title]="'scanner.tooltip' | translate">
                            <i class="p-2 material-icons " style="font-size: 20px">videocam</i>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
    `
})

export class FormMultiInputComponent extends AbstractFormFieldComponent {

    // [disableControl]="!isControlDisabled()"

    @HostBinding('class') hostClasses = 'd-flex mt-1';

    private static readonly SYNC_STATUS_DEFAULT:string = 'factory_default';
    private static readonly SYNC_STATUS_IN_SYNC:string = 'in_sync';
    private static readonly SYNC_STATUS_SYNC_PENDING:string = 'sync_pending';

    constructor(public renderer:Renderer2, public validationConstraintService:ValidationConstraintService, public tooltipService:TooltipService, public globalAlertService:GlobalAlertService, public ts:TranslateService, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }
    
    public hasSyncStatus()
    {
        return (this.config && this.config.attr && this.config.attr.syncStatus);
    }
    
    public allowScannerInput()
    {
        return (this.config && this.config.attr && this.config.attr.allowScanner);
    }
    
    public getStatusIcon(syncStatus:string):string{

        let result:string = "";

        switch (syncStatus){
            case FormMultiInputComponent.SYNC_STATUS_DEFAULT:
            case FormMultiInputComponent.SYNC_STATUS_IN_SYNC:
                result = "check";
                break;
            case FormMultiInputComponent.SYNC_STATUS_SYNC_PENDING:
                result = "sync";
                break;
        }

        return result;
    }
    
    public handleClickScanner(e:MouseEvent)
    {
        if (Utils.getInternetExplorerVersion() > 0){
            this.globalAlertService.addAlertNoIESupport(this.ts.translate("alert.noiesupportsubtitle"), this.ts.translate("alert.noiesupportlabel"));
            return;
        }

        this.globalAlertService.addPopupBarCodeScanner( this.ts.translate('scanner.scan'),(buttonCode:any, response:any) => {
            if (response && response.scanResult && response.scanResult != ''){
                this.group.get(this.config.name).patchValue(response.scanResult);
                //this.config.initialValue = response.scanResult;
            }
        }, () => {});
    }

    //TODO: vertalingen
    //TODO: wat voor teksten moeten hier komen?
    public getStatusText(syncStatus:string):string{

        let result:string = "Onbekend";

        switch (syncStatus){
            case FormMultiInputComponent.SYNC_STATUS_DEFAULT:
            case FormMultiInputComponent.SYNC_STATUS_IN_SYNC:
                result = "Gesynchroniseerd";
                break;
            case FormMultiInputComponent.SYNC_STATUS_SYNC_PENDING:
                result = "Aan het synchroniseren...";
                break;
        }

        return result;
    }

    protected hasInputGroupAddon() {
        if (
            this.config && this.config.attr && this.config.attr.inputGroup &&
            this.config.attr.inputGroup.addOn && this.config.attr.inputGroup.addOn.text &&
            this.config.attr.inputGroup.addOn.position
        ) {
            return true;
        }
        return false;
    }

    public hasInputGroupAddonBefore() {
        if (this.hasInputGroupAddon() === true) {
            if (this.config.attr.inputGroup.addOn.position == 'before') {
                return true;
            }
        }
        return false;
    }

    public hasInputGroupAddonAfter() {
        if (this.hasInputGroupAddon() === true) {
            if (this.config.attr.inputGroup.addOn.position == 'after') {
                return true;
            }
        }
        return false;
    }
}
