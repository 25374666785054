import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {
    ActivityInput,
    ActivityInputType,
    ActivitySelectInput,
    ActivityTextInput, ActivityUploadInput, ActivityCheckboxInput,
    CheckActivity
} from './check-activity.interface';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ActivityStatusIconComponent} from './activity-status-icon/activity-status-icon.component';
import {TooltipService} from '../../../services/tooltip/tooltip.service';
import {TranslateService} from '../../../services/translate/translate.service';
import {GlobalAlertService} from '../../../../wrapper/global-alert/global-alert.service';
import Utils from '../../../utils/utils';
import {LumiSelectOption} from '../../commonUI/select/lumi-select/lumi-select.interface';
import * as exif from 'exif-js';
import {FormDataService} from '../../form/services/form-data.service';
import {FileSelectorComponent} from '../../file/file-selector/file-selector.component';
import {LoggerService} from "../../../services/logger/logger.service";

@Component({
    selector: 'check-activity',
    templateUrl: './check-activity.component.html'
})
export class CheckActivityComponent implements OnChanges, OnDestroy {
    private static readonly ATTACHMENT_MAX_SIZE: number = 10; // in MB (bytes / 1000000)
    private static readonly ATTACHMENT_MAX_WIDTH: number = 2560; // px
    private static readonly ATTACHMENT_COMPRESSION_QUALITY: number = 0.7; // Ranging from 0 to 1
    private static readonly ATTACHMENT_CREATE_PATH: string = 'components/attachments/create-for-activity';

    @Input() public activity: CheckActivity;
    @Input() public canEdit: boolean;
    @Input() public canDelete: boolean;
    @Input() public isLoading: boolean;
    @Input() public readonly: boolean;
    @Input() public baseObjectId: number;
    @Output() onDelete: EventEmitter<CheckActivity> = new EventEmitter<CheckActivity>();
    @Output() onEdit: EventEmitter<CheckActivity> = new EventEmitter<CheckActivity>();
    @Output() onSave: EventEmitter<CheckActivity> = new EventEmitter<CheckActivity>();
    @ViewChild('checkMark', {static: false}) activityStatusIconComponent: ActivityStatusIconComponent;
    @ViewChild('header', {static: false}) header: ElementRef<HTMLDivElement>;
    @ViewChildren('inputField') inputElements: QueryList<ElementRef<HTMLInputElement>>;
    @ViewChild('fileSelector', {static: false}) fileSelector: FileSelectorComponent;
    
    public NOTE_MAX_LENGTH: number = 1500;
    public ACTIVITY_INPUT_TYPE = ActivityInputType;
    public showAdditionalInfo: boolean = false;
    public form: UntypedFormGroup = new UntypedFormGroup({});
    private tooltipElements: ElementRef[] = [];
    public fileTypesString: string = '';
    private uploadCount: number;

    private static estimateFileSize(fileData: any): number {
        let imgFileSize = Math.round((fileData.length) * 3 / 4);
        // Get size in MB
        return imgFileSize / 1000000;
    }

    // Block files containing strange characters
    private static isSafeFileName(fileName: string): boolean {
        let i: number = fileName.length;

        while (i--) {
            if (fileName.charCodeAt(i) > 125) {
                return false;
            }
        }

        return true;
    }

    constructor(
        private tooltipService: TooltipService,
        private renderer: Renderer2,
        private translateService: TranslateService,
        private globalAlertService: GlobalAlertService,
        private cd: ChangeDetectorRef,
        private formDataService: FormDataService,
        protected logger: LoggerService
    ) {
    }

    ngOnChanges(): void {
        if (this.activity && this.activity.inputs) {
            this.addFormControls();
        }
    }

    ngOnDestroy(): void {
        this.hideTooltipsAndMenus();
    }

    ngAfterViewInit() {
        this.activity.inputs.forEach((activity, index) => {
            if (activity.activityInputType === ActivityInputType.UPLOAD || activity.activityInputType === ActivityInputType.CHECKBOX) {
                let checkboxElement: any = this.inputElements.get(index);
                checkboxElement.checkbox.nativeElement.setAttribute('name', activity.fieldName);
            }
        });
    }

    public checkboxChanged(input:ActivityCheckboxInput,e:any):void {
        if (input.value === '') {
            input.value = input.fieldName;
        } else {
            input.value = '';
        }
    }

    public onFilesSelected(files: any, input: ActivityUploadInput) {
        if (input.value === '') {
            this.onFilesUpload(files, input);
        }
    }

    public clearFileSelect(input: ActivityUploadInput) {
        input.value = '';
        this.onSave.emit(this.activity);
    }

    public delete(): void {
        this.logger.log('[CheckActivityComponent]' + 'deleting activity: ', this.activity);
        this.onDelete.emit(this.activity);
    }

    public edit(): void {
        this.logger.log('[CheckActivityComponent]' + 'editing activity: ', this.activity);
        this.onEdit.emit(this.activity);
    }

    public onToggleStatusDone(event: MouseEvent|KeyboardEvent): void {
        event.stopImmediatePropagation();

        // form is invalid when disabled, so first set to enabled again before checking again
        this.form.enable();
        this.form.updateValueAndValidity();

        if (this.form.valid) {
            this.toggleDone();
            this.save();
        } else {
            this.showInputErrors();
            this.fadeInAndOutCheckmarkClass();
            this.activity.done = false;
        }
    }

    public toggleDone(): void {
        this.activity.done = !this.activity.done;

        if (this.activity.done) {
            this.disableForm();
        } else {
            this.form.enable();
        }
    }

    public onToggleAttention(): void {
        this.toggleAttention();
        this.save();
    }

    public toggleAttention(): void {
        this.activity.attention = !this.activity.attention;
        if (this.activity.attention) {
            this.form.addControl('note', new UntypedFormControl(this.activity.note));
            this.form.get('note').enable();
        } else {
            this.form.removeControl('note');
        }
    }

    public setValue(input: ActivityInput): void {
        this.activity.inputs.find(_input => _input === input).value = this.form.get(input.fieldName).value;
    }

    public setNote(): void {
        this.activity.note = this.form.get('note').value;
    }

    public toggleAdditionalInfo(): void {
        this.showAdditionalInfo = !this.showAdditionalInfo;
    }

    public save(): void {
        this.logger.log('[CheckActivityComponent]' + 'saving activity: ', this.activity);
        this.onSave.emit(this.activity);
    }

    public hideTooltipsAndMenus(): void {
        this.tooltipElements.forEach(element => this.tooltipService.destroyToolTip(element));
    }

    public isControlEnabled(input: ActivityInput): boolean {
        return this.form.get(input.fieldName).enabled && !this.readonly;
    }

    public isUploadedOrDisabled(input: ActivityInput): boolean {
        return !this.isControlEnabled(input) || input.value !== '';
    }

    public handleClickScanner(event: MouseEvent, input: ActivityInput): void {
        event.preventDefault();
        if (Utils.getInternetExplorerVersion() > 0) {
            this.globalAlertService.addAlertNoIESupport(
                this.translateService.translate('alert.noiesupportsubtitle'),
                this.translateService.translate('alert.noiesupportlabel')
            );
            return;
        }

        this.globalAlertService.addPopupBarCodeScanner(
            this.translateService.translate('scanner.scan'),
            (buttonCode: any, response: any) => {
                if (response && response.scanResult && response.scanResult !== '') {
                    this.form.get(input.fieldName).patchValue(response.scanResult);
                    this.setValue(input);
                }
            }, () => {
            }
        );
    }

    public showRequestError(message: string): void {
        this.tooltipElements.push(this.header);
        this.tooltipService.createAndShowTooltip(
            this.renderer,
            this.header,
            message
        );
    }

    public showMenuButton() {
        return this.showEditButton() || this.showDeleteButton();
    }

    private addFormControls(): void {
        this.form = new UntypedFormGroup({});
        this.activity.inputs.forEach(input => {
            this.form.addControl(input.fieldName, new UntypedFormControl(input.value));
            const validators: ValidatorFn[] = [];
            if (input.required) {
                validators.push(Validators.required);
            }
            if (input.activityInputType === ActivityInputType.STRING) {
                if (input.regex) {
                    validators.push(this.createRegexValidator(input));
                }
            }
            if (this.form.get(input.fieldName)) {
                this.form.get(input.fieldName).setValidators(validators);
            }
        });
        if (this.activity.attention) {
            this.form.addControl('note', new UntypedFormControl(this.activity.note));
        }
        if (this.activity.done) {
            this.disableForm();
        }
    }

    private disableForm(): void {
        this.form.disable();
        if (this.form.get('note') && !this.readonly) {
            this.form.get('note').enable();
        }
        this.logger.log('[CheckActivityComponent] ', this.form);
    }

    private createRegexValidator(input): ValidatorFn {
        const regex = new RegExp(input.regex);
        return (control: AbstractControl): { [key: string]: any } | null => {
            const allow = regex.test(control.value);
            return allow ? null : {regex: true};
        };
    }

    private fadeInAndOutCheckmarkClass(): void {
        if (!this.form.valid) {
            this.activityStatusIconComponent.fadeInAndOutCheckmarkClass();
        }
    }

    private showInputErrors(): void {
        Object.values(this.form.controls).forEach(formControl => {
            // mark all controls as touched and dirty so that the invalid visuals are triggered
            formControl.markAllAsTouched();
            formControl.markAsDirty();

            // show tooltip error
            if (!formControl.valid) {
                const formControlElement: ElementRef<HTMLInputElement>|any = this.inputElements.find((item: any) => {
                    if (item.checkbox) {
                        return item.checkbox.nativeElement.getAttribute('name') === this.getControlName(formControl);
                    } else {
                        return item.nativeElement.getAttribute('name') === this.getControlName(formControl);
                    }
                });
                const input: ActivityTextInput = <ActivityTextInput>this.activity.inputs.find(
                    _input => _input.fieldName === this.getControlName(formControl)
                );

                const firstError: string = Object.keys(formControl.errors)[0];
                let message: string;
                if (firstError === 'regex' && input?.invalidRegexMessage) {
                    message = input.invalidRegexMessage;
                } else {
                    message = this.translateService.translate(`check-activity.error.${firstError}`);
                }

                const tooltipElement = formControlElement.checkbox ? formControlElement.checkbox : formControlElement;

                this.tooltipElements.push(tooltipElement);
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    tooltipElement,
                    message
                );
            }
        });
    }

    private getControlName(control: AbstractControl): string | null {
        const formGroup = control.parent.controls;
        return Object.keys(formGroup).find(name => control === formGroup[name]) || null;
    }

    public getFlagButtonClass(): string {
        if (this.activity.attention) {
            return 'ca-icon-button-flag-active';
        }
        return 'ca-icon-button';
    }

    public showDeleteButton(): boolean {
        return this.canDelete && !this.readonly && !this.activity.done;
    }

    public showEditButton(): boolean {
        return this.canEdit && !this.readonly && !this.activity.done;
    }

    public setInputValue(selectedOption: LumiSelectOption[], input: ActivitySelectInput) {
        if (input?.options?.length > 0 && input.options.some(option => option.id === selectedOption[0].id)) {
            input.value = selectedOption[0].id;
            this.form.get(input.fieldName).setValue(selectedOption[0].id);
        }
    }

    public getSelectedOptions(input: ActivitySelectInput): LumiSelectOption[] {
        if (input.options.some(option => option.id === input.value)) {
            return [input.options.find(option => option.id === input.value)];
        } else {
            return [];
        }
    }

    public onCheckKeyDown($event: KeyboardEvent) {
        if (!Utils.hasFocus(this.activityStatusIconComponent.checkButton.nativeElement)) {
            return;
        }
        if ($event.key === 'Enter') {
            this.onToggleStatusDone($event);
        }
        if ($event.key === 'Escape') {
            Utils.preventDefault($event);
            Utils.removeAllFocus();
        }
    }

    // Copy pasta van Form Attachment Component
    public onFilesUpload(files: FileList, input: ActivityUploadInput): void {
        this.uploadCount = 0;
        if (files && files.length > 0) {
            Array.from(files).forEach(async (file: File) => {
                let exifData: string;
                let fileData: string;
                let fileSize: number;
                let fileName = file.name;

                // Read all EXIF data from selected image
                exif.getData(String(file), function () {
                    exifData = exif.getAllTags(this);
                    this.logger.log('[FormAttachmentComponent] EXIF DATA -> ', exifData);
                });

                // De maxwidth en quality geldt alleen voor images die exif hebben. Dus JPG, en dan een eentje met de exifdata.
                // iOS en Android foto-apps voldoen hieraan
                // NOTE: als de filedata leeg is, was geen exif orientation data gevonden. Dus het was geen JPG of de JPG bevatte geen exif.
                // Doe niks met de afbeelding en haal de filedata via de normale reader op.
                await Utils.getRotatedImageUrl(
                    file,
                    1,
                    CheckActivityComponent.ATTACHMENT_MAX_WIDTH,
                    CheckActivityComponent.ATTACHMENT_COMPRESSION_QUALITY
                ).then(rotatedFileData => {
                    if (!rotatedFileData) {
                        const fileReader: FileReader = new FileReader();
                        this.logger.log('[FormAttachmentComponent] ' + 'No orientation found, load the file like any other');

                        fileReader.onload = (event: any) => {
                            fileData = event.target.result;
                            fileSize = CheckActivityComponent.estimateFileSize(fileData);
                            this.logger.log('[FormAttachmentComponent] ' + 'Selected file size (estimate): ' + fileSize);
                            this.upload(fileData, fileSize, fileName, exifData, input);
                        };

                        // TODO: testen in de praktijk
                        fileReader.onerror = () => {
                            this.logger.log('[FormAttachmentComponent] ' + 'Error while reading file');
                            this.clearInput();
                        };

                        fileReader.readAsDataURL(file);
                    } else {
                        // foto gevonden met orientatie. orientatie rechtgezet en waarde naar 1
                        fileData = rotatedFileData;
                        fileSize = CheckActivityComponent.estimateFileSize(fileData);
                        this.logger.log('[FormAttachmentComponent] ' + 'Selected file size (estimate): ' + fileSize);

                        this.upload(fileData, fileSize, fileName, exifData, input);
                    }
                });
            });
        }
    }

    private upload(fileData: string, fileSize: number, fileName: string, exifData: string, input: ActivityUploadInput): void {
        this.logger.log('[FormAttachmentComponent] ' + 'trying to upload file ' + fileName + ', with fileSize ' + fileSize);
        if (fileSize > CheckActivityComponent.ATTACHMENT_MAX_SIZE) {
            this.logger.log('[CheckActivityComponent] filesize is not OK', );
            return;
        }
        if (!CheckActivityComponent.isSafeFileName(fileName)) {
            this.logger.log('[CheckActivityComponent] filename is not OK');
            return;
        }
        this.isLoading = true;

        let encodedFileData: string = encodeURIComponent(fileData);

        // keep track of pending uploads
        this.uploadCount++;

        let url = `${CheckActivityComponent.ATTACHMENT_CREATE_PATH}/${this.baseObjectId}`;

        return this.formDataService.addAttachment(
            url,
            fileName,
            exifData,
            encodedFileData,
            (data: any) => {
                this.uploadCount--;
                this.logger.log('[FormAttachmentComponent] ' + fileName + ' uploaded successfully');
                this.clearInput();

                // Trigger animation + cleanup
                data.isNew = true;
                setTimeout(() => {
                    data.isNew = false;
                    this.cd.detectChanges();
                }, 1000);

                // Add the new attachment at the beginning of the array
                // data.listItem = this.getListItem(data);
                // this.attachments.unshift(data);

                if (this.uploadCount < 1) {
                    // Enable the input again
                    this.isLoading = false;

                    // Update the view
                    this.cd.detectChanges();

                    this.logger.log('[CheckActivityComponent] upload success', data);
                    // this.onComponentEvent.emit({event: FormEvent.ATTACHMENT_ADD_SUCCESS, data: {}});
                }
                if (input.activityInputType === ActivityInputType.UPLOAD && input.value === '') {
                    input.value = input.fieldName;
                }
            },
            () => {
                this.uploadCount--;
                this.logger.log('[FormAttachmentComponent] ' + fileName + ' upload failed');
                if (input.activityInputType === ActivityInputType.UPLOAD) {
                    input.value = '';
                }
                this.isLoading = false;
                this.cd.detectChanges();
            },
            () => {
                this.uploadCount--;
                this.logger.log('[FormAttachmentComponent] ' + fileName + ' upload failed');
                if (input.activityInputType === ActivityInputType.UPLOAD) {
                    input.value = '';
                }
                this.isLoading = false;
                this.cd.detectChanges();
            }, {
                activityId: this.activity.id,
                attachmentPropertyName: input.attachmentPropertyName
            });
    }

    private clearInput(): void {
        // Clear the input
        this.fileSelector.fileInput.nativeElement.value = '';
    }
}
