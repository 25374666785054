import {Component, Input, OnInit} from '@angular/core';
import {GuiChildConfig} from '../../gui.interface';
import {DeviceStateService} from '../../components/device-state/device-state.service';
import {
    IDeviceState,
    IDeviceStateHistory,
    IDeviceStateOverview
} from '../../components/device-state/device-state.interface';
import {take} from 'rxjs/operators';
import MapIconGenerator from '../../../../utils/map-icon-generator';
import {TranslateService} from '../../../../services/translate/translate.service';
import {TooltipComponent} from '@angular/material/tooltip';
import {GlobalEvent} from "../../../../interfaces/global-event";
import {GlobalModel} from "../../../../services/state/global.model";
import * as moment from 'moment/moment';
import {StorageService} from "../../../../services/storage/storage.service";

@Component({
    selector: 'device-state',
    templateUrl: './device-state.component.html',
    styleUrls: ['./device-state.component.scss']
})

export class DeviceStateComponent implements OnInit {
    @Input('config') config: GuiChildConfig;

    public deviceStateOverview: IDeviceStateOverview = null;
    public deviceStateHistory: IDeviceStateHistory[] = null;
    public showAllMessages: boolean = true;
    public showLoading: boolean = false;
    public activeStateMessages: IDeviceState[] = null;
    public inactiveStateMessages: IDeviceState[] = null;
    public activeHistoryStateCode: string = null;
    public activeDeviceTab: 'messages' | 'history' = 'messages';

    constructor(private deviceStateService: DeviceStateService, public ts: TranslateService, public model: GlobalModel, protected storageService: StorageService) {
        //Hack to enable html in angular material tooltip
        Object.defineProperty(TooltipComponent.prototype, 'message', {
            set(v: any) {
                const el = document.querySelectorAll('.mdc-tooltip');
                if (el) {
                    el[el.length - 1].innerHTML = v;
                }
            },
            configurable: true
        });
    }

    ngOnInit(): void {
        this.getDeviceStateMessages(true);
    }

    public generateHtmlForHistoryTooltip(deviceStateHistory:IDeviceStateHistory):string {
        let html = `<div class="d-flex flex-column">`;
        html += `
            <div>
                ${this.ts.translate('devicestate.start')}: <em>${moment(deviceStateHistory.date_reported).format("L LT")}</em><br />
                ${this.ts.translate('devicestate.end')}: <em>${deviceStateHistory.date_resolve ? moment(deviceStateHistory.date_resolve).format("L LT") : this.ts.translate('devicestate.current')}</em>
            </div>
        `;
        if(deviceStateHistory.specific_data && deviceStateHistory.specific_data.length !== 0){
            Object.entries(deviceStateHistory.specific_data).forEach(infoField => {
                if(infoField[0] !== 'timestamp' && infoField[0] !== this.ts.translate('devicestate.timestamp')){
                    html += `<div><span style="text-transform: capitalize;">${infoField[0]}:</span> <em>${infoField[1]}</em></div>`;
                }
            });
        }
        html += `</div>`;
        return html;
    }

    public toggleAllMessages(): void {
        this.showAllMessages = this.showAllMessages ? false : true;
    }

    public formatDeviceStateCode(code:string): string {
        return code.replace(/ /g,'-').toLowerCase();
    }

    public getImage(icon: string, includeSize: boolean = true): string {
        return MapIconGenerator.ICON_BASE_PATH + (includeSize ? MapIconGenerator.ICON_PATH_SIZE_16 : '') + icon + '.png';
    }

    private getDeviceStateMessages(showInitialLoading: boolean): void{
        if(showInitialLoading){
            this.toggleLoading();
        }


        this.deviceStateService.getDeviceStateOverview(this.config.url_prefix).pipe(
            take(1)
        ).subscribe((deviceStateOverview) => {
            this.deviceStateOverview = deviceStateOverview;
            this.activeStateMessages = deviceStateOverview.alarms.filter(_x => _x.active === true && _x.suppressed === false);
            this.inactiveStateMessages = deviceStateOverview.alarms.filter(_x => _x.active === false && _x.suppressed === true);
        }).add(() => {
            this.getActiveDeviceTab();

            if(showInitialLoading && this.activeDeviceTab === 'history'){
                this.getDeviceStateHistory(null);
            }
            this.toggleLoading();
        });
    }

    private getDeviceStateHistory(deviceState: IDeviceState | null): void{
        this.toggleLoading();
        this.deviceStateService.getDeviceStateHistoryOverview(this.config.url_prefix, (deviceState === null ? null : deviceState.state_type_id))
            .subscribe((deviceStateHistory) => {
                this.deviceStateHistory = deviceStateHistory;
            }).add(() => {
            this.toggleLoading();
        });
    }

    private getActiveDeviceTab():void{
        if (this.storageService.keyExists(StorageService.KEY_ACTIVE_DEVICE_TAB)) {
            this.storageService.getStringValue(StorageService.KEY_ACTIVE_DEVICE_TAB, value => {
                switch(value){
                    case 'messages':
                    case 'history':
                        this.activeDeviceTab = value;
                        break;
                }
            });
        }
    }

    public suppressAlarm(deviceState: IDeviceState): void {
        this.toggleLoading();
        let suppress: boolean = false;
        if (!deviceState.suppressed){
            suppress = true;
        }


        this.deviceStateService.toggleSuppress(this.config.url_prefix, deviceState.state_type_id, suppress).pipe(
            take(1)
        ).subscribe(() => {
            this.getDeviceStateMessages(false);
            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MODULE_REFRESH, {invalidateCache: true}));
        });
    }

    public getMonthName(month:number):string{
        return [this.ts.translate('jan'),
            this.ts.translate('feb'),
            this.ts.translate('mar'),
            this.ts.translate('apr'),
            this.ts.translate('may'),
            this.ts.translate('jun'),
            this.ts.translate('jul'),
            this.ts.translate('aug'),
            this.ts.translate('sep'),
            this.ts.translate('oct'),
            this.ts.translate('nov'),
            this.ts.translate('dec')][month];
    }

    public getStateHistoryDays(year: number, month: number): number[]{
        return this.activeHistoryStateCode !== null
            ? [...new Set(this.deviceStateHistory
                .filter(_x => _x.state_type_code === this.activeHistoryStateCode && moment(_x.date_reported).year() === year && moment(_x.date_reported).month() === month)
                .map(_x => moment(_x.date_reported).date())
                .sort((a,b) => b - a))]
            : [...new Set(this.deviceStateHistory
                .filter(_x => moment(_x.date_reported).year() === year && moment(_x.date_reported).month() === month)
                .map(_x => moment(_x.date_reported).date())
                .sort((a,b) => b - a))];
    }

    public getStateHistoryMonths(year: number): number[]{
        return this.activeHistoryStateCode !== null
            ? [...new Set(this.deviceStateHistory
                .filter(_x => _x.state_type_code === this.activeHistoryStateCode && moment(_x.date_reported).year() === year)
                .map(_x => moment(_x.date_reported).month())
                .sort((a,b) => b - a))]
            : [...new Set(this.deviceStateHistory
                .filter(_x => moment(_x.date_reported).year() === year)
                .map(_x => moment(_x.date_reported).month())
                .sort((a,b) => b - a))];
    }

    public getStateHistoryYears(): number[]{
        return this.activeHistoryStateCode !== null
            ? [...new Set(this.deviceStateHistory
                .filter(_x => _x.state_type_code === this.activeHistoryStateCode)
                .map(_x => moment(_x.date_reported).year())
                .sort((a,b) => b - a))]
            : [...new Set(this.deviceStateHistory
                .map(_x => moment(_x.date_reported).year())
                .sort((a,b) => b - a))];
    }

    public getStateHistoryData(day:number, month: number, year: number): IDeviceStateHistory[]{
        return this.activeHistoryStateCode !== null
            ? this.deviceStateHistory
                .filter(_x =>
                    _x.state_type_code === this.activeHistoryStateCode
                    && moment(_x.date_reported).date() === day
                    && moment(_x.date_reported).month() === month
                    && moment(_x.date_reported).year() === year)
                .sort((a, b) => (moment(a.date_reported) > moment(b.date_reported) ? -1 : 1))
            : this.deviceStateHistory
                .filter(_x =>
                    moment(_x.date_reported).date() === day
                    && moment(_x.date_reported).month() === month
                    && moment(_x.date_reported).year() === year)
                .sort((a, b) => (moment(a.date_reported) > moment(b.date_reported) ? -1 : 1));
    }

    public calculateActivityTime(dateReported:Date, dateResolve:Date): string{
        if(dateResolve){
            const diffTime = Math.abs(<any>moment(dateResolve) - <any>moment(dateReported));
            const diffSeconds = Math.ceil(diffTime / 1000);
            const diffMinutes = Math.ceil(diffTime / (1000 * 60));
            const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
            const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

            if(diffSeconds <= 60){
                return diffSeconds+' '+this.ts.translate('devicestate.seconds');
            } else if(diffMinutes <= 60){
                return diffMinutes+' '+(diffMinutes === 1 ? this.ts.translate('devicestate.minute') : this.ts.translate('devicestate.minutes'));
            } else if(diffHours <= 96){
                return diffHours+' '+(diffHours === 1 ? this.ts.translate('devicestate.hour') : this.ts.translate('devicestate.hours'));
            } else{
                return diffDays+' '+this.ts.translate('devicestate.days');
            }
        } else {
            return this.ts.translate('devicestate.current');
        }
    }

    public hasStateHistoryData():number{
        return this.activeHistoryStateCode !== null
            ? this.deviceStateHistory.filter(_x => _x.state_type_code === this.activeHistoryStateCode).length
            : this.deviceStateHistory.length;
    }

    public handleStateTabChange(tab:'messages'|'history'): void{
        this.activeDeviceTab = tab;
        this.storageService.setValue(StorageService.KEY_ACTIVE_DEVICE_TAB, tab);
        if(this.activeHistoryStateCode === null){
            this.getDeviceStateHistory(null);
        }
    }

    public handleHistoryStateChange(deviceState: IDeviceState | null): void{
        if(this.activeHistoryStateCode !== (deviceState === null ? null : deviceState.state_type_code)){
            this.activeHistoryStateCode = deviceState === null ? null : deviceState.state_type_code;
            this.getDeviceStateHistory((deviceState === null ? null : deviceState));
        }
    }

    public handleStateHistoryShortcut(deviceState: IDeviceState): void{
        this.handleStateTabChange('history');
        this.handleHistoryStateChange(deviceState);
    }

    public toggleLoading(): void{
        this.showLoading = !this.showLoading;
    }

    public getSpecificData(alarm: IDeviceState | IDeviceStateHistory) {
        return Object.entries(alarm.specific_data);
    }
}
