/**
 * Created by Jasper on 01/04/2020.
 */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output, ViewChild, ViewChildren
} from '@angular/core';

@Component({
    selector: 'video-player',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template:`
        <video class="{{width == '100%'?'w-100':''}}{{width == '50%'?'w-50':''}} {{height == '100%'?'h-100':''}}{{height == '50%'?'h-50':''}}" width="{{width}}" height="{{height}}" [controls]="videoConfig.controls" [autoplay]="videoConfig.autoplay" style="outline: none">
            <source src="{{this.source}}" type="video/mp4">
            <source src="{{this.source}}" type="video/webm">
            Your browser does not support the video tag.
        </video>
    `
})

export class VideoPlayerComponent {

    @Input() videoConfig:any = null;

    public width:any = 360;
    public height:any = 240;
    public source:string = null;

    constructor() {}

    ngOnInit(){
        // Er moet per video een config worden meegestuurd.
        if(this.videoConfig){
            this.width = this.videoConfig.width;
            this.height = this.videoConfig.height;
            this.source = this.videoConfig.source;
        }
    }

}
