import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import {GlobalModel} from '../../services/state/global.model';
import {MapTableComponent} from '../map-table/map-table.component';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {MapItem} from '../map/map-item/map-item';
import {MapTableService} from '../map-table/map-table.service';
import {TreeLMX} from '../commonUI/tree/tree-lmx';
import {TreeComponent} from '../commonUI/tree/tree.component';
import {RequestFailure} from '../../services/http/request-failure';
import {StorageService} from '../../services/storage/storage.service';
import {TableOptions, TableOptionsField, TableOptionsSet} from '../table/tableColumnSelector/table-options';
import {AppSettings} from '../../../app.settings';
import {TableOptionsService} from '../table/table-options.service';
import {GlobalEvent} from '../../interfaces/global-event';
import {AuthorizationService} from '../../services/authorization/authorization.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormDataService} from '../form/services/form-data.service';
import {HTTPService} from '../../services/http/http.service';
import Utils from '../../utils/utils';
import {BoundsChangedDuringAutoLoadEvent, LongPressEvent} from '../map/map-core.interface';
import {TreeNodeLMX} from '../commonUI/tree/tree-node-lmx';
import {take} from 'rxjs/operators';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {GlobalStateService} from "../../services/state/global-state.service";
import {MapTableSettings, TreeMapFormEvent, TreeSettings} from './tree-map-form.interface';
import {CreateNodeObject} from '../commonUI/tree/tree-node-interface';
import {Filter} from '../table/filterableTable/filter.interface';
import {AreaalService} from '../../services/areaal/areaal.service';
import {LuminizerRoutes} from '../../interfaces/routes';
import {TranslateService} from "../../services/translate/translate.service";
import {IMapItemStyles} from "../map/managers/map-manager.interface";
import {LoggerService} from "../../services/logger/logger.service";
import {isString} from 'util';

@Component({
    selector: 'tree-map-form',
    templateUrl: './tree-map-form.component.html'
})
export class TreeMapFormComponent implements OnDestroy {
    @ViewChild('mapTableComponent', {static: false}) mapTableComponent: MapTableComponent;
    @ViewChild('dragContainer', {static: false}) dragContainer: ElementRef<HTMLDivElement>;
    @ViewChild('treeComponent', {static: false}) treeComponent: TreeComponent;
    private isPreferredSelectionAutoSet: boolean = false;

    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter<any>();
    
    
    @Input() set mapTableSettings(mapTableSettings: MapTableSettings) {
        this.setMapTableSettings(mapTableSettings);
    }
    public _mapTableSettings: MapTableSettings = {
        allowSingleSelect: true,
        allowMultiSelect: null,
        allowCreateMarker: false,
        allowMixedView: null,
        allowAutoLoadMarkers: true,
        allowMarkerDrag: null,
        useCustomIconColors: false,
        iconSet: 'default',
        isBasicMode: false,
        hideMap: false,
        showReportInfoText: false,
        showSearchBar: false,
        allowMultiLineInRow: false
    };
    
    @Input() set treeSettings(treeSettings: TreeSettings) {
        this.setTreeSettings(treeSettings);
    }
    public _treeSettings: TreeSettings = {
        hideActionIcon: false,
        unfoldFolderClick: false,
        childNodeIcon: 'place',
        nodeActionIcon: 'settings',
        nodeActionTitleKey: 'dimgroep.edit',
    };
    
    public leftSliderId: string = null;
    public rightSliderId: string = null;

    public initialized: boolean = false;

    get STATE_TREE(): number {
        return TreeMapFormComponent.STATE_TREE;
    }

    get STATE_MAP(): number {
        return TreeMapFormComponent.STATE_MAP;
    }

    get STATE_FORM(): number {
        return TreeMapFormComponent.STATE_FORM;
    }
    protected static readonly MAX_URL_ITEMS: number = 100;
    protected static readonly BATCHUPDATE_FORM_NAME: string = 'batch_update';

    public static readonly AUTO_REFRESH_INTERVAL: number = 45000;
    public static readonly STATE_TREE: number = 0;
    public static readonly STATE_MAP: number = 1;
    public static readonly STATE_FORM: number = 2;
    
    public readonly MAP_TABLE_MIN_WIDTH: number = 300;
    public readonly FORM_MIN_WIDTH: number = 325;
    public readonly FORM_COLLAPSED_WIDTH: number = 40;
    public readonly TREE_COLLAPSED_WIDTH: number = 40;

    MODULE_OBJECT_TYPES: string[] = [];
    MODULE_PATH: string = '';
    MODULE_COUNTS: string[] = [];
    MODULE_FORM_URL: string = '';
    MODULE_FORM_DATA_URL: string = '';
    MODULE_BATCHUPDATE_URL: string = '';

    protected activeState: number = -1;
    protected startingState: number = this.STATE_TREE;
    mobileMode: boolean = false;
    hideTree: boolean = false;
    hideForm: boolean = false;
    protected currentAutoRefreshId: number = -1;
    showMapTableLoaders: boolean = false;
    isBasicMode: boolean = false;

    hiddenTreeTableFieldsChange: boolean = false;


    autoRefreshOnModuleEnter: boolean = false;
    protected expectTreeNodeActions: boolean = false; // When the module needs to expect treenode action

    leftPanelWidth: number = 0;
    leftPanelDefaultWidth: number = 0;
    rightPanelWidth: number = 0;
    rightPanelDefaultWidth: number = 0;

    formData: FormDataInterface = null;
    formCollapsed: boolean = false;
    treeCollapsed: boolean = false;
    autoloadTreeRootOnHiddenTree: boolean = true;

    protected dataRequested: boolean = false;
    protected timesParamsReceived: number = 0; // To help differentiate between new data by request and new data as initial value from model
    protected numLastSelectedItems: number = 0;
    tableOptions: TableOptions = new TableOptions();

    public treeCollapsedObservable: BehaviorSubject<boolean>;
    public selectedItemsObservable: BehaviorSubject<{baseObjectId: number|string}[]>;
    public formDataObservable: BehaviorSubject<null>;
    public formCollapsedObservable: BehaviorSubject<boolean>;
    public autoLoadMapItemsObservable: Subject<MapItem[]>;
    public autoLoadTableItemsObservable: Subject<MapItem[]>;
    public refreshMapItemsObservable: Subject<MapItem[]> = new Subject<MapItem[]>();
    public refreshTableItemsObservable: Subject<MapItem[]> = new Subject<MapItem[]>();
    public mapItemsObservable: BehaviorSubject<{mapItems: MapItem[], zoomOnMarkers?: boolean}>;

    public tableItemsObservable: BehaviorSubject<MapItem[]>;
    public filterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    protected subscriptions: Subscription[] = [];
    public isGridModeActive:boolean = false;

    // TODO: Dit is nu ff noodzakelijk omdat de url niet gelijk is aan name in de DB
    // Golden function for assets to asset-management convertion
    public static moduleNameToPath(moduleName: string): string {
        // Case sensitive compare
        if (moduleName == 'assets') {
            return 'asset-management';
        } else {
            return moduleName;
        }
    }

    constructor(
        protected cd: ChangeDetectorRef,
        protected _activatedRoute: ActivatedRoute,
        protected httpService: HTTPService,
        protected _router: Router,
        public model: GlobalModel,
        protected mapTableService: MapTableService,
        protected storage: StorageService,
        protected tableOptionsService: TableOptionsService,
        public auth: AuthorizationService,
        protected formDataService: FormDataService,
        protected ngZone: NgZone,
        protected activatedRoute: ActivatedRoute,
        protected globalAlertService: GlobalAlertService,
        protected areaalService: AreaalService,
        protected globalStateService: GlobalStateService,
        public ts: TranslateService,
        protected logger:LoggerService
    ) {
        // Set public portal mode as soon as possible
        this.isBasicMode = this.model.publicPortalMode.value;
    }
    
    public setTreeMapFormSettings(
        leftSliderId: string,
        rightSliderId: string,
        moduleObjectTypes: string[],
        modulePath: string,
        moduleCounts: string[],
        moduleFormUrl: string,
        moduleBatchUpdateUrl: string,
        moduleFormDataUrl: string,
        selectedItemsObservable: BehaviorSubject<{baseObjectId: number|string}[]>,
        formDataObservable: BehaviorSubject<null>,
        formCollapsedObservable: BehaviorSubject<boolean>,
        treeCollapsedObservable: BehaviorSubject<boolean>,
        mapItemsObservable: BehaviorSubject<{mapItems: MapItem[], zoomOnMarkers?: boolean}>,
        autoLoadMapItemsObservable: Subject<MapItem[]>,
        autoLoadTableItemsObservable: Subject<MapItem[]>,
        tableItemsObservable: BehaviorSubject<MapItem[]>,
        filterString: BehaviorSubject<string>,
        currentTree: BehaviorSubject<TreeLMX>,
        mapTableSettings?: MapTableSettings,
        treeSettings?: TreeSettings
    ): void {
        this.leftSliderId = leftSliderId;
        this.rightSliderId = rightSliderId;
        
        if (treeSettings) {
            this.setTreeSettings(treeSettings);
        }
        if (mapTableSettings) {
            this.setMapTableSettings(mapTableSettings);
        }
        
        this.MODULE_OBJECT_TYPES = moduleObjectTypes;
        this.model.availableModuleObjectTypes = moduleObjectTypes;
        this.MODULE_PATH = modulePath;
        this.MODULE_COUNTS = moduleCounts;
        this.MODULE_FORM_URL = moduleFormUrl;
        this.MODULE_BATCHUPDATE_URL = moduleBatchUpdateUrl;
        this.MODULE_FORM_DATA_URL = moduleFormDataUrl;
    
        this.selectedItemsObservable = selectedItemsObservable;
        this.formDataObservable = formDataObservable;
        this.formCollapsedObservable = formCollapsedObservable;
        this.treeCollapsedObservable = treeCollapsedObservable;
        this.mapItemsObservable = mapItemsObservable;
        this.autoLoadMapItemsObservable = autoLoadMapItemsObservable;
        this.autoLoadTableItemsObservable = autoLoadTableItemsObservable;
        this.tableItemsObservable = tableItemsObservable;
        this.filterString = filterString;
        this.model.currentTree = currentTree;
    
        this.model.currentSelectedItems = this.selectedItemsObservable;
        
        this.logger.log('[TreeMapForm]' + 'settings set');
    };

    viewInit() {
        this.initialized = true;
        // Hide tree when enabled in module actions
        // NOTE: hideTree can also be true when no tree is given for the module (so, not only when disabled by rights)
        if (this.auth.allowHideTree()) {
            this.hideTree = true;
        }
        if (this.auth.allowHideForm()) {
            this.hideForm = true;
        }

        this.getTableOptionsSets();
        this.handleHiddenTree();
        this.setPanelSize();

        //Subscribe or load state
        this.subscribeTableItemsObservable();
        this.subscribeMapItemsObservable();

        // Local handling, not for subclasses
        this.subscriptions.push(this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            switch (event.type) {
                case GlobalEvent.EVENT_TABLE_OPTIONS_SETS_CHANGED:
                    break;
                case GlobalEvent.EVENT_MOVE_MAPITEM_FROM_MAP_SUCCESS:
                    // When a mapitem location gets updated from the map (not form) refresh all associated baseobjects
                    let baseObjectIds: number[] = [];

                    if (event.data && event.data.marker && event.data.marker.dataRef && event.data.marker.dataRef.baseObjects) {
                        const baseObjects = event.data.marker.dataRef.baseObjects;

                        baseObjects.forEach((baseObject) => {
                            baseObjectIds.push(baseObject.id);
                        });

                        if(baseObjectIds.length > 0){
                            if(baseObjectIds.length === 1){
                                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REFRESH_FORM, {referenceId: baseObjectIds[0]}));
                            } else {
                                this.refreshMapTableItems(baseObjectIds);
                            }
                        }
                    }
                    break;
                case GlobalEvent.EVENT_MODULE_REFRESH:
                    let invalidateCache: boolean = false;
                    if (event.data && event.data.invalidateCache) {
                        invalidateCache = event.data.invalidateCache;
                    }
                    // Reload the tree and reapply the selected nodes > loading all items again
                    this.reloadTree(true, true, invalidateCache);
                    // NOTE: to refresh every item on screen (side-loaded items too) use: refreshAllMapTableItems
                    break;
                case GlobalEvent.EVENT_MODULE_START_AUTO_REFRESH:
                    // Create a new id for each refresh chain
                    this.currentAutoRefreshId = Utils.getRandomNumber(0, 9999999999999);
                    this.autoRefreshMapData(this.currentAutoRefreshId);
                    break;
                case GlobalEvent.EVENT_MODULE_STOP_AUTO_REFRESH:
                    this.currentAutoRefreshId = -1;
                    break;
                case GlobalEvent.EVENT_REMOVE_UNDERGROUND_BASEOBJECT_SUCCESS:
                    if(this.isGridModeActive){
                        this.clearSelectedItemsForGrid()
                    } else {
                        this.clearSelectedItems([],true)
                    }

                    break;
                case GlobalEvent.EVENT_ADD_MAP_ITEM_TO_TABLE:
                    this.refreshTableItemsObservable.next(<any>event.data.tableItems);
                    break;
            }

            this.handleGlobalEvent(event);
        }));

        this.subscriptions.push(this._activatedRoute.queryParams.subscribe(param => {
            // read the state or default to starting state
            let state: number = param && param.state ? param.state : this.startingState;

            // Fix for map not showing correctly. Needs some fake resize event
            if (state == this.STATE_MAP) {
                this.mapTableComponent.initMap();
            }

            this.activeState = state;

            // Show the backbutton when the page is differrent from the starting page
            this.model.showMobileStateBackButton.next(state > this.startingState);

            this.cd.detectChanges();
        }));
    
        this.subscriptions.push(this.formCollapsedObservable.subscribe((value: boolean) => {
            this.formCollapsed = value;
            this.mapTableComponent.initMap();
            this.cd.detectChanges();
        }));
    
        this.subscriptions.push(this.treeCollapsedObservable.subscribe((value: boolean) => {
            this.treeCollapsed = value;
            this.mapTableComponent.initMap();
            this.cd.detectChanges();
        }));
    
        this.subscriptions.push(this.model.mobileMode.subscribe((value: boolean) => {
            this.mobileMode = value;
            this.cd.detectChanges(); // Mark for change was not enough here
        }));
    
        this.subscriptions.push(this.model.mobileStateBack.subscribe((value: number) => {
            if(value != null){
                this.applyActiveState(value, true);
                this.cd.detectChanges();
            }
        }));

        this.subscriptions.push(this.refreshMapItemsObservable.subscribe((mapItems: MapItem[]) => {
            if (mapItems) {
                // when in mobile mode, and map is not visible, pass it, so the map reapplies zoom when turned visible
                this.mapTableComponent.appendMapItems(mapItems);
            }
        }));
    
        this.subscriptions.push(this.refreshTableItemsObservable.subscribe((tableData) => {
            if (tableData) {
                this.mapTableComponent.appendTableItems(<any>tableData);
            }
        }));
    
        this.subscriptions.push(this.autoLoadMapItemsObservable.subscribe((mapItems: MapItem[]) => {
            if (mapItems) {
                // when in mobile mode, and map is not visible, pass it, so the map reapplies zoom when turned visible
                this.mapTableComponent.appendMapItems(mapItems);
            }
        }));
    
        this.subscriptions.push(this.autoLoadTableItemsObservable.subscribe((tableData) => {
            if (tableData) {
                this.mapTableComponent.appendTableItems(<any>tableData);
            }
        }));



        this.subscriptions.push(this.model.currentTree.subscribe((value: TreeLMX) => {
            if (value) {
                this.getTableFields(value.id);

                // if the module is not starting for the first time (or specifically set in settings)
                // and there is was a tree before this event, get cookie values
                if ((this.model.currentTree.value && this.model.currentTree.value.treeNodes) || AppSettings.REAPPLY_SMART_SELECTION_ON_AREA_SWITCH) {
                    this.storage.getObjectValue(this.MODULE_PATH + '.tableOptions', (_value: TableOptions) => {
                        // Match the storage with the table fields from the model/tree
                        this.tableOptionsService.mergeTableOptions(this.tableOptions, _value);
                    });
                } else {
                    // user switched area or entered the module for the first time: reset the last selected tableoptions
                    this.storage.removeItem(this.MODULE_PATH + '.tableOptions');
                    this.model.tableOptionsSets.pipe(
                        take(2) // first result is always []
                    ).subscribe((tableOptionSets: TableOptionsSet[]) => {
                        if (tableOptionSets.length > 0 && !this.isPreferredSelectionAutoSet) {
                            this.setPreferredSmartSelection(tableOptionSets);
                        }
                    });
                }

                // Apply previous smart selection, if wanted
                if (AppSettings.REAPPLY_SMART_SELECTION_ON_AREA_SWITCH) {
                    this.storage.getObjectValue(this.MODULE_PATH + '.tableOptions', (_value: TableOptions) => {
                        // Match the storage with the table fields from the model/tree
                        this.tableOptionsService.mergeTableOptions(this.tableOptions, _value);
                    });
                }
            } else {
                // The trees are empty, trigger hide-tree modus for this module
                if (AppSettings.AUTO_HIDE_EMPTY_TREE) {
                    this.logger.log('[TreeMapFormComponent] ' + 'NO TREES FOR THIS AREA/MODULE: HIDDEN-TREE-MODE ACTIVATED');
                    this.hideTree = true;
                    this.handleHiddenTree();
                }
            }
        }));

        this.subscriptions.push(this.formDataObservable.subscribe((json) => {
            this.ngZone.run(() => {
                this.handleFormDataChanged(json);
            });
        }));

        this.subscriptions.push(this.selectedItemsObservable.subscribe((selectedItems) => {
            this.handleSelectedItemsChanged(selectedItems);
        }));

        this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
            this.handleRouteChanged(params);
        }));

        // this.subscriptions.push(this.model.gridDrawModeActive.subscribe((value: boolean) => {
        //     this.isGridModeActive = value
        // }));

        setTimeout(() => {

            // When items are loaded in the models for the first time they are made invisible.
            // Apply filters to the data, to make the items visible
            this.mapTableComponent.filterItems(this.filterString.value, true);

            // Wait for the other subscriptions to have triggered (mobile back state), then set the activity to map if necessary
            // if (this.auth.enableAutoSelectTreeRoot()){

            // Logic is changed to: when a tree node has been selected, show the map directly.
            // In the case of autoselectroot, no item is selected yet, but it will be in the future.
            // So include it in the if-statement here to switch to map and don't wait for the tree to load.
            let selectedTreeNodes;
            if (this.treeComponent) {
                selectedTreeNodes = this.treeComponent.getSelectedNodes();
            }
            // Refresh the tree and mapitems on module enter
            if (this.autoRefreshOnModuleEnter) {
                this.logger.log('[TreeMapFormComponent] ' + 'currentree: ', this.model.currentTree.value);
                if (this.model.currentTree && this.model.currentTree.value && !!this.model.currentTree.value.code) {
                    // Dont re-load when the module is entered for the first time, the tree will be loaded anyway
                    this.reloadTree(true);
                }
            }
            if (this.auth.enableAutoSelectTreeRoot()
                || selectedTreeNodes
                && selectedTreeNodes.nodes
                && selectedTreeNodes.nodes.length > 0
            ) {
                // NOTE: this does also set the state to map when the user chose a tree node different from the root and
                // re-enters this module. Seems to be fine for now. The tree has less priority
                this.setActiveState(this.STATE_MAP);
            }

            // Enable line below to get items from state but load the new items in the background and apply them once loaded.
            // this.refreshAllMapTableItems();
        }, 100);
    }


    private subscribeTableItemsObservable(){
        const getTreeFormState = this.globalStateService.getSetTreeFormState('get', this.model.currentTree.value.code,null);
        if(getTreeFormState){ // In state
            const tableData = getTreeFormState;
            if (tableData && tableData.tableItems) {
                this.mapTableComponent.updateTableItems((<any>tableData).tableItems, (<any>tableData).sorting);
            } else {
                this.mapTableComponent.updateTableItems([], null);
            }
        } else { // Not in state
            this.subscriptions.push(this.tableItemsObservable.subscribe((data) => {
                const tableData = <any>data || {};
                if (tableData && tableData.tableItems) {
                    this.mapTableComponent.updateTableItems((<any>tableData).tableItems, (<any>tableData).sorting);
                } else {
                    this.mapTableComponent.updateTableItems([], null);
                }
                this.globalStateService.getSetTreeFormState('set', this.model.currentTree.value.code, tableData);
            }));
        }
    }

    private subscribeMapItemsObservable(){
        const getMapFormState = this.globalStateService.getSetMapFormState('get', this.model.currentTree.value.code,null);
        if(getMapFormState){ // In state
            const mapData = getMapFormState;
            if (mapData && mapData.mapItems) {
                this.mapTableComponent.updateMapItems(mapData.mapItems,this.dataRequested,this.mobileMode && this.activeState == TreeMapFormComponent.STATE_MAP,mapData.zoomOnMarkers);
            } else {
                this.mapTableComponent.updateMapItems([],null,null,null);
            }
        } else { // Not in state
            this.subscriptions.push(this.mapItemsObservable.subscribe((data) => {
                const mapData = <any>data || {};
                if (mapData && mapData.mapItems) {
                    this.mapTableComponent.updateMapItems(mapData.mapItems,this.dataRequested,this.mobileMode && this.activeState == TreeMapFormComponent.STATE_MAP,mapData.zoomOnMarkers);
                } else {
                    this.mapTableComponent.updateMapItems([],null,null,null);
                }
                this.globalStateService.getSetMapFormState('set', this.model.currentTree.value.code, mapData);
            }));
        }
    }


    protected loadMapTableItems(selectionData: {references: TreeNodeLMX[], selectedInTree: boolean, treeCode: string, treeNodes: object[]}, appendItems: boolean = false, successCallBack?: () => void): void {
        this.dataRequested = true;
        this.showMapTableLoaders = true;
        let mapItemsDone: boolean = false;
        let tableItemsDone: boolean = false;

        this.cd.detectChanges();
        if (this._mapTableSettings.hideMap ||
            (this.model.currentTree.value && this.model.currentTree.value.code === 'PERFECT_VIEW' || this.model.isKeycodeActive('x'))
          //  || (this.model.currentTree.value && this.model.currentTree.value.code === 'MSB_MELDING')
        ) {
            mapItemsDone = true;
        } else {
            let getMapFormState: boolean | object = false;
            if (this.isTreeHidden() && !this.hiddenTreeTableFieldsChange) {
                getMapFormState = this.globalStateService.getSetMapFormState('get', this.model.currentTree.value.code, null);
            }
            if (!getMapFormState) { // Not in state or not hidden
                this.mapTableService.getMapItems(
                    this.model.isKeycodeActive('c'),
                    this.model.isKeycodeActive('q'),
                    selectionData.treeCode,
                    selectionData.references,
                    this.tableOptions,
                    this.MODULE_OBJECT_TYPES,
                    this.MODULE_PATH,
                    this.mapTableComponent.mapComponent.map.getZoom(),
                    (mapItems: MapItem[]) => {
                        this.mapItemsObservable.next({mapItems: mapItems, zoomOnMarkers: !appendItems});
                        this.mapTableComponent.updateMapItems(mapItems, this.dataRequested, this.mobileMode && this.activeState == TreeMapFormComponent.STATE_MAP, !appendItems);
                        this.globalStateService.getSetMapFormState('set', this.model.currentTree.value.code, {
                            treeCode: this.model.currentTree.value.code,
                            mapItems: <any>mapItems,
                            zoomOnMarkers: !appendItems
                        });

                        mapItemsDone = true;
                        if(tableItemsDone){
                            setTimeout(() => {
                                this.mapTableComponent.filterItems(this.filterString.value, !appendItems);
                                if (successCallBack) {
                                    this.showMapTableLoaders = false;
                                    successCallBack();
                                    this.cd.detectChanges();
                                }
                            });
                        }
                    },
                    (failure: RequestFailure) => {
                        // Can't really happen, there is nog user-input needed for this call
                        this.logger.log('[TreeMapFormComponent] ' + 'Get mapitems failed' + failure);
                    },
                );
            } else { // In state
                this.mapItemsObservable.next({mapItems: <any>getMapFormState, zoomOnMarkers: !appendItems});
                mapItemsDone = true;
                if(tableItemsDone){
                    setTimeout(() => {
                        this.mapTableComponent.filterItems(this.filterString.value, !appendItems);
                        if (successCallBack) {
                            this.showMapTableLoaders = false;
                            successCallBack();
                            this.cd.detectChanges();
                        }
                    });
                }
            }
        }

        let getTreeFormState: boolean | object = false;
        if (this.isTreeHidden() && !this.hiddenTreeTableFieldsChange) {
            getTreeFormState = this.globalStateService.getSetTreeFormState('get', this.model.currentTree.value.code, null);
        }
        if (!getTreeFormState) { // Not in state or not hidden
            let preventClusters:boolean = false;
            if(this.model.isKeycodeActive('c') || this.model.isKeycodeActive('x')){
                preventClusters = true;
            };
            this.mapTableService.getTableItems(
                preventClusters,
                this.model.isKeycodeActive('q'),
                selectionData.treeCode,
                this.tableOptions,
                selectionData.references,
                this.MODULE_OBJECT_TYPES,
                this.MODULE_PATH,
                (tableData) => {
                    this.tableItemsObservable.next(<any>tableData);
                    this.mapTableComponent.updateTableItems((<any>tableData).tableItems, (<any>tableData).sorting);
                    this.globalStateService.getSetTreeFormState('set', this.model.currentTree.value.code, <any>tableData);
                    // this.setHeatmapData(this.model.currentTree.value.code, tableData);

                    tableItemsDone = true;
                    if(mapItemsDone){
                        setTimeout(() => {
                            this.mapTableComponent.filterItems(this.filterString.value, !appendItems);
                            if (successCallBack) {
                                this.showMapTableLoaders = false;
                                successCallBack();
                                this.cd.detectChanges();
                            }
                        });
                    }

                },
                (failure: RequestFailure) => {
                    // Can't really happen, there is nog user-input needed for this call
                    this.logger.log('[TreeMapFormComponent] ' + 'Get tableitems failed' + failure);
                },
            );
        } else { // In state
            this.tableItemsObservable.next(<any>getTreeFormState);
            tableItemsDone = true;
            if(mapItemsDone){
                setTimeout(() => {
                    this.mapTableComponent.filterItems(this.filterString.value, !appendItems);
                    if (successCallBack) {
                        this.showMapTableLoaders = false;
                        successCallBack();
                        this.cd.detectChanges();
                    }
                });
            }
        }

        if(this.hiddenTreeTableFieldsChange){
            this.hiddenTreeTableFieldsChange = false;
        }
    }

    private setHeatmapData(treeCode:string, tableData:any){
        let hasUsableHeatmapFields:boolean = false;
        this.model.heatmapTreeCode = "";

        //Empty array and put default value at start
        this.model.heatmapValueOptions = [{
            id: '',
            name: this.ts.translate('layers.heatmap.disable'),
            type: 'default',
            isPreferred: true
        }];
        tableData.tableItems[0].forEach((_x, index) => {
            if(_x.type == 'datetime' || _x.type == 'integer'){
                hasUsableHeatmapFields = true;
                this.model.heatmapValueOptions.push({
                    id: _x.code,
                    name: _x.label,
                    type: _x.type,
                    isPreferred: false
                })
            }
        })

        if(hasUsableHeatmapFields){
            //Heatmap relies heavily on state data. If table data is not in state then it is probably disabled for this module.
            //To still enable heatmap, set custom state that will only be used for heatmap so heatmap still works.
            if(!this.globalStateService.getSetTreeFormState('get', treeCode, null)) {
                treeCode = treeCode+'_HEATMAP_ONLY';
                this.globalStateService.getSetTreeFormState('set', treeCode, tableData);
            }
            this.model.heatmapTreeCode = treeCode;
        }
    }

    private setPreferredSmartSelection(tableOptionSets: TableOptionsSet[]): void {
        if (tableOptionSets.some(set => set.isPreferred)) {
            this.isPreferredSelectionAutoSet = true;
            const preferredSet = tableOptionSets.find(set => set.isPreferred);
            this.mapTableComponent.handleClickTableOptionsSet(null, preferredSet, (name) => {
                this.globalAlertService.addAlert(
                    '',
                    'Automatisch slimme selectie toegepast',
                    `de slimme selectie \'${name}\' is automatisch toegepast`,
                    GlobalAlertService.ALERT_ICON_SETTINGS,
                    null,
                    GlobalAlertService.DEFAULT_SUCCESS_ALERT_AUTO_FADE_TIME);
            });
        }
    }

    private autoRefreshMapData(autoRefreshId: number): void {
        // Compare the current with the running refresh chain, if not the same, abort this chain
        if (this.currentAutoRefreshId == autoRefreshId) {
            // Start reloading the tree
            if (this.model.currentTree.value && this.model.currentTree.value.treeNodes) {
                this.reloadTree(true, true, false, () => {

                    // All items are reloaded, wait for the next reload
                    this.waitForAutoRefresh(autoRefreshId);

                    // NOTE: use this if you want to refresh ALL items in the table (side-loaded e.g. via b-box loading items too)
                    // Check if baseobjects visible, if not, start the timeout, else, load the items then start timeout
                    /*let baseObjectIds: number[] = this.mapTableComponent.getAllBaseObjectIds();
                    if (this.currentAutoRefreshId == autoRefreshId && baseObjectIds && baseObjectIds.length > 0) {
                        this.refreshMapTableItems(baseObjectIds, () => {
                            this.waitForAutoRefresh(autoRefreshId);
                        });
                    } else {
                        this.waitForAutoRefresh(autoRefreshId);
                    }*/
                });
            } else {
                this.waitForAutoRefresh(autoRefreshId);
            }
        }
    }

    private waitForAutoRefresh(autoRefreshId: number): void {
        setTimeout(() => {
            if (this.currentAutoRefreshId == autoRefreshId) {
                this.autoRefreshMapData(autoRefreshId);
            }
        }, TreeMapFormComponent.AUTO_REFRESH_INTERVAL);
    }

    // Reload tree and set selected + expanded tree nodes. Optionally reload the selected tree nodes
    public reloadTree(refreshMapTableByTreeSelection: boolean = false, appendItems: boolean = false, invalidateCache: boolean = false, successCallBack?: () => void): void {
        if (!this.treeComponent) {
            return;
        }

        // Clear state item to force get from server
        this.globalStateService.clearSpecificFormState(this.model.currentTree.value.code);

        this.treeComponent.reload(invalidateCache, () => {
            // NOTE: use this option when it's important to reload the map items matching the selection in the tree
            //  e.g when you've created a new tree node
            //  (there are other methods to refresh all visible map/table items)
            if (refreshMapTableByTreeSelection) {
                this.treeComponent.reapplyTreeSelection(appendItems);
            }

            // TODO: this callback triggers when the tree is reloaded. reapplyTreeSelection might be done AFTER that
            if (successCallBack) {
                successCallBack();
            }
        });
    }

    // Do a call to get the smart selection of the current user + module
    private getTableOptionsSets(): void {
        // Get the current table options
        this.tableOptionsService.listTableOptionSets(this.MODULE_PATH, (response) => {
            if (response) {
                this.model.tableOptionsSets.next(response);
            }
        }, () => {
        }, () => {
        });
    }

    protected handleHiddenTree(): void {
        // When tree is hidden by access management, or no tree is given
        if (this.hideTree && this.autoloadTreeRootOnHiddenTree) {

            // Set state to map view, skipping the tree (and preventing the user from ever going to the tree)
            this.startingState = this.STATE_MAP;

            // Wait for the tree to have emit, and the cookie with tableoptions to be read. Else the list call wont work as planned
            // This problem occurs only with hidden trees, since they load map/table items directly on startup
            setTimeout(() => {
                // Load the root items, goto map view
                this.loadMapTableItems({references: [], selectedInTree: false, treeCode: '', treeNodes: []});
            });
        }

        this.setActiveState(this.startingState);
    }

    // Tablefields are send with trees, get the table field for a tree id here
    private getTableFields(treeId: number): void {
        // Find a match with between trees and the current tree
        const trees = this.model.currentTrees.value;
        for (let i = 0; i < trees.length; i++) {
            if (trees[i].id == treeId) {
                this.tableOptions.tableFields = (<any>trees[i]).tableFields;
            }
        }
    }

    // When the table options changed
    handleTableFilterChange(e: {tableOptionsChanged?: boolean, reset?: boolean}): void {
        // Store the options in the local storage
        this.storage.setObjectValue(this.MODULE_PATH + '.tableOptions', this.tableOptions);

        // TODO: when the tree gets an option to load as archived, enable this again
        /*if (e.showArchivedChanged){
            this.reloadTree();
        }*/

        if (e.tableOptionsChanged) {
            // New columns selected, reload the data to get the new columns from the backend

            // TODO: reapplyTreeSelection fixes the header of the table, refreshAllMapTableItems does not.
            //  make it so in the future you can use refreshAllMapTableItems
            this.treeComponent.reapplyTreeSelection(false);
            // this.refreshAllMapTableItems();
        } else {
            // The column selection didn't change, only apply the filters to the current existing data
            this.mapTableComponent.filterItems(this.filterString.value, true);
        }
        
        if (e.reset) {
            this.onComponentEvent.emit({
                event: TreeMapFormEvent.FILTER_RESET,
                data: {}
            });
        }
    }

    protected refreshAllMapTableItems(): void {
        let refreshIds: (number|string)[] = this.mapTableComponent.getAllBaseObjectIds();
        if (refreshIds && refreshIds.length > 0) {
            this.refreshMapTableItems(refreshIds, () => {
            });
        }
    }

    refreshMapTableItems(baseObjects: (number|string)[], successCallBack?: () => void): void {
        // Skip calls for undefined trees
        if (!this.model.currentTree.value || this.treeComponent == null) {
            return;
        }

        let mapItemsDone: boolean = false;
        let tableItemsDone: boolean = false;
        let treeCode: string = this.model.currentTree.value.code;

        if (this._mapTableSettings.hideMap || this.model.currentTree.value.code === 'PERFECT_VIEW' ) { //|| this.model.currentTree.value.code === 'MSB_MELDING'
            mapItemsDone = true;
        } else {
            this.setModuleObjectType();
            this.mapTableService.getMapItemsById(
                this.mapTableComponent.mapComponent.map.getBounds(),
                baseObjects,
                this.treeComponent.getSelectedNodes().references,
                treeCode,
                this.tableOptions,
                this.MODULE_OBJECT_TYPES,
                this.MODULE_PATH,
                (mapItems: MapItem[]) => {
                    this.refreshMapItemsObservable.next(mapItems);
                    mapItemsDone = true;
                    if (tableItemsDone) {
                        this.mapTableComponent.filterItems(this.filterString.value, false);
                        if (successCallBack) {
                            successCallBack();
                        }
                    }
                    this.cd.detectChanges();
                }, () => {

                });
        }

        this.mapTableService.getTableItemsById(
            this.mapTableComponent.mapComponent.map.getBounds(),
            baseObjects,
            this.treeComponent.getSelectedNodes().references,
            treeCode,
            this.tableOptions,
            this.MODULE_OBJECT_TYPES,
            this.MODULE_PATH,
            (tableItems: any) => {
                // Find baseobjectsids that were send but not received. These items are deleted or moved
                let removedBaseObjects: (number | string)[] = [];
                const baseObjectIdIndex = tableItems[0].findIndex(item => item.code === 'baseObjectId');
                const tableItemsCopy = [...tableItems];
                tableItemsCopy.splice(0, 1);

                this.logger.log(Utils.measureFunctionTime('[TreeMapFormComponent] ' + 'baseobjects to remove filtered in: ', () => {
                    removedBaseObjects = (baseObjects || []).filter(baseObject => {
                        if (tableItemsCopy.some(item => String(item[baseObjectIdIndex].label) === String(baseObject))) {
                            const index = tableItemsCopy
                                .findIndex(item => String(item[baseObjectIdIndex].label) === String(baseObject));
                            tableItemsCopy.splice(index, 1);
                            return false;
                        }
                        return true;
                    });
                }));

                if (removedBaseObjects.length > 0) {
                    this.logger.log('[TreeMapFormComponent] ' + 'going to remove baseobjects: ', removedBaseObjects);
                    this.mapTableComponent.removeBaseObjects(removedBaseObjects);
                }

                this.refreshTableItemsObservable.next(tableItems);
                tableItemsDone = true;
                if (mapItemsDone) {
                    this.mapTableComponent.filterItems(this.filterString.value, false);
                    if (successCallBack) {
                        successCallBack();
                    }
                }
                this.cd.detectChanges();
            }, () => {

            });
    }

    handleAutoLoadRequest(e: BoundsChangedDuringAutoLoadEvent) {
        // The tree determines the objecttypes in this module. So get the types and then execute the default handler
        this.setModuleObjectType();
        this.setModuleFormDataUrl();

        let mapItemsDone: boolean = false;
        let tableItemsDone: boolean = false;

        this.mapTableService.getMapItemsInBoundingBox(e.bounds, this.tableOptions, this.treeComponent.getSelectedNodes().references, this.MODULE_OBJECT_TYPES, this.MODULE_PATH, this.mapTableComponent.mapComponent.map.getZoom(),
            (mapItems: MapItem[]) => {
                this.autoLoadMapItemsObservable.next(mapItems);
                mapItemsDone = true;
                if (tableItemsDone) {
                    this.mapTableComponent.filterItems(this.filterString.value, false);
                }
                this.cd.detectChanges();
            });
        this.mapTableService.getTableItemsInBoundingBox(e.bounds, this.tableOptions, this.treeComponent.getSelectedNodes().references, this.MODULE_OBJECT_TYPES, this.MODULE_PATH,
            (tableItems) => {
                this.autoLoadTableItemsObservable.next(tableItems);
                tableItemsDone = true;
                if (mapItemsDone) {
                    this.mapTableComponent.filterItems(this.filterString.value, false);
                }
                this.cd.detectChanges();
            });
    }




    // Gets the currently selected items as id-array
    getSelectedItemIds(): number[] {
        const selectedItems = this.mapTableComponent.getSelectedItems();
        let selectedItemIds: number[] = [];
        selectedItems.forEach((selectedItem: {baseObjectId: number}) => {
            if (selectedItem && selectedItem.baseObjectId) {
                selectedItemIds.push(selectedItem.baseObjectId);
            }
        });


        return selectedItemIds;
    }

    // Use to override in a specific module
    // e.g. back button behaviour
    protected applyActiveState(value: number, ignoreOriginBlock:boolean = false): void {

        // Als je vanuit de treenodeaction gelijk bent doorgelinked naar een dimgroep,
        // ga dan helemaal terug naar de tree, niet naar de kaart
        if (this.httpService.getQueryParam('origin') == FormDataService.ORIGIN_TREE_NODE_ACTION) {
            value = 10000; // Triljoen miljoen miljard vijf
        }
        this.setActiveState(this.activeState - value, ignoreOriginBlock);
    }

    handleResize(): void {
        this.setPanelSize();
    }

    handleToggleCollapseForm(event: {collapsed: boolean}) {
        this.formCollapsedObservable.next(event.collapsed);
    }

    handleToggleCollapseTree(event: {collapsed: boolean}) {
        this.treeCollapsedObservable.next(event.collapsed);
    }

    private setPanelSize(): void {

        let newWidth: number = this.dragContainer.nativeElement.clientWidth;

        this.leftPanelDefaultWidth = newWidth * ((newWidth < 1600 ? 3 : 2) / 12);
        this.rightPanelDefaultWidth = newWidth * ((newWidth < 1600 ? 4 : 3) / 12);

        this.leftPanelWidth = this.leftPanelDefaultWidth;
        this.rightPanelWidth = this.rightPanelDefaultWidth;

        this.cd.detectChanges();
    }

    handleTreeSliderSlide(event: {x: number, isMaxLeft: boolean, isMaxRight: boolean}): void {
        if (event.isMaxLeft) {
            this.treeCollapsedObservable.next(true);
        }

        this.leftPanelWidth = this.leftPanelDefaultWidth + event.x;
        this.cd.detectChanges();
    }

    handleFormSliderSlide(event: {x: number, isMaxLeft: boolean, isMaxRight: boolean}): void {
        if (event.isMaxRight) {
            this.formCollapsedObservable.next(true);
        }

        this.rightPanelWidth = this.rightPanelDefaultWidth - event.x;
        this.cd.detectChanges();
    }

    isActiveState(state: number): boolean {
        if (!this.mobileMode) {
            return true;
        }

        return state == this.activeState;
    }

    isTreeHidden(): boolean {
        return !this.isActiveState(this.STATE_TREE) || this.hideTree;
    }

    isTreeSliderHidden(): boolean {
        return this.treeCollapsed || this.hideTree;
    }

    isFormSliderHidden(): boolean {
        return this.formCollapsed || this.hideForm;
    }

    // Set the state to map or form (ect) in the url, so navigation with the backbutton works on mobile devices.
    protected setActiveState(state: number, ignoreOriginBlock:boolean = false) {
        if((this.httpService.getQueryParam('origin') != FormDataService.ORIGIN_HISTORY &&  this.httpService.getQueryParam('origin') != FormDataService.ORIGIN_SEARCH) || ignoreOriginBlock){ // Disable for history and search calls
            // Protection against invalid queryparams
            if (isNaN(state)) {
                state = 0;
            }

            if (state < this.startingState) {
                state = this.startingState;
            }

            this.logger.log('[TreeMapFormComponent] ' + 'SET ACTIVE STATE: ', state);

            // TODO: knipt dit niet bestaande queryparams af?
            setTimeout(() => {
                this._router.navigate([], {queryParams: {state: state, origin: 'null'}, queryParamsHandling: 'merge'});
            });
        }
    }

    protected handleFormDataChanged(json: FormDataInterface, containerForms?: string[]): void {
        // Preventing 'wild' selection of items to show the wrong form. Check if new data is still relevant
        let numSelectedItems: number = this.selectedItemsObservable ? this.selectedItemsObservable.value.length : 0;

        if (json) {

            let containerMatch: boolean = false;
            if (containerForms && containerForms.length > 0) {
                for (let i = 0; i < containerForms.length; i++) {
                    if (json.schema.name.indexOf(containerForms[i]) != -1) {
                        containerMatch = true;
                        break;
                    }
                }
            }

            // When the new form is a batchupdate form
            if (json.schema.name.indexOf(TreeMapFormComponent.BATCHUPDATE_FORM_NAME) != -1) {
                //Removed if statement below because since we have clusters, a batch form is also called with 1 item (a cluster) selected.
                // When the selected items is > 1 AND (the form is empty or not batchupdateform)
                // if (numSelectedItems > 1) { // && (!this.formData || this.formData.schema.name.indexOf("batch_update") == -1)) {
                    this.formData = null;
                    setTimeout(() => {
                        this.formData = json;
                        this.cd.markForCheck();
                    });
                // }
            } else if (containerMatch) {
                this.formData = null;
                setTimeout(() => {
                    this.formData = json;
                    this.cd.markForCheck();
                });

                if (AppSettings.AUTO_EXPAND_FORM) {
                    this.formCollapsedObservable.next(false);
                }
            } else {
                // TODO: je kan hier ook nog checken of het id van het form wel het geselecteerde item is
                if (numSelectedItems == 1) {
                    this.formData = null;
                    setTimeout(() => {
                        this.formData = json;
                        this.cd.markForCheck();
                    });
                }
            }
        } else {
            this.formData = json;
        }

        this.cd.markForCheck();
    }

    protected handleRouteChanged(params: Params): void {
        this.logger.log('[TreeMapFormComponent] ' + 'HANDLE ROUTE CHANGED');
        this.timesParamsReceived++;
        this.handleRouteDefaultIds(params);
    }

    protected handleRouteDefaultIds(params: Params): void {
        // When there is an id given with the url, load that form
        if (params && params.ids) {

            // Convert string to array of objects
            const ids = params.ids.split(',');
            const idsAsObject: {baseObjectId: number}[] = [];
            ids.forEach((id) => {
                idsAsObject.push({baseObjectId: id});
            });

            if (idsAsObject.length == 1 && idsAsObject[0].baseObjectId == 0) {
                // Skip loading id 0
                return;
            } else {
                // Store selected item for later use of this module

                this.selectedItemsObservable.next(idsAsObject);
                // Request the form for the given id
                if (idsAsObject.length == 1) {
                    this.getForm(idsAsObject[0].baseObjectId);

                    // Expand tree for search results, widgets or directlinks
                    let origin: string = this.httpService.getQueryParam('origin');
                    if (this.timesParamsReceived == 1
                        || origin == FormDataService.ORIGIN_RELOAD
                        || origin == FormDataService.ORIGIN_NEW_SCHAKELKAST
                        || origin == FormDataService.ORIGIN_SEARCH
                        || origin == FormDataService.ORIGIN_WIDGET
                    ) {

                        if (origin == FormDataService.ORIGIN_NEW_SCHAKELKAST) {
                            this.reloadTree(false, false, false, () => {
                                this.treeComponent.expandTreeForBaseObject(idsAsObject[0].baseObjectId);
                            });
                        } else {
                            this.treeComponent.expandTreeForBaseObject(idsAsObject[0].baseObjectId);
                        }

                        if (origin == FormDataService.ORIGIN_RELOAD
                            || origin == FormDataService.ORIGIN_NEW_SCHAKELKAST
                            || origin == FormDataService.ORIGIN_SEARCH
                            || origin == FormDataService.ORIGIN_WIDGET
                        ) {
                            // Only for mobile, on desktop mode you also see the form,
                            // so keep the map/table on the tab the user handpicked last
                            if (this.mobileMode) {
                                // Switch to the map, instead of table, to prevent a selected item being invisible
                                // (on another page of the table or at the bottom of the table)
                                this.mapTableComponent.switchToMap();
                            }

                            // Link to the map instead of directly to the form
                            this.setActiveState(this.STATE_MAP);
                        } else {
                            // In case of a direct url link to an object, still go to the form

                            // TODO: dit kan nog heroverwogen worden. Alleen als je nu een url met id gebruikt gaat hij nog naar de form
                            this.setActiveState(this.STATE_FORM);
                        }
                    }
                } else if (idsAsObject.length > 1) {
                    if (this.model.currentModule.value === LuminizerRoutes.WORK_ORDERS_PAGE) {
                        this.tryGetBatchUpdateForm(ids);
                    } else if (this.model.currentModule.value === LuminizerRoutes.DEVICE_MANAGEMENT_PAGE) {
                        this.tryGetBatchUpdateForm(ids);
                    } else {
                        this.tryGetBatchUpdateForm();
                    }
                }
            }
        }
    }

    protected handleSelectedItemsChanged(selectedItems: {baseObjectId: number|string}[], collapseWhenNotSelected: boolean = true): void {
        // This will be triggered upon entering this module (thus, setting its selected state)
        // Also triggered by new url+param
        this.mapTableComponent.setSelectedItems(selectedItems);

        if (AppSettings.AUTO_EXPAND_FORM) {
            if (selectedItems.length <= 0) {
                // At control: there still can be a dimming group selected, so don't collapse
                if (collapseWhenNotSelected) {
                    this.formCollapsedObservable.next(true);
                }
            } else {
                // Don't open the form if there was more then 0 items selected. The user has closed the form on purpose
                if (this.numLastSelectedItems <= 0) {
                    this.formCollapsedObservable.next(false);
                }
            }
        }

        this.numLastSelectedItems = selectedItems.length;
    }

    handleTableMapSelectionChange(selectedItems: {baseObjectId: number|string}[], navigateCallBack?: (params: string) => void) {
        this.setActiveState(this.STATE_FORM);

        // Generate the url for the clicked item
        if (selectedItems && selectedItems.length > 0) {

            // TODO: waar helpt dit tegen? dat je cleared als je batchupdate ziet, maar vervolgens 1 item selecteerd,
            //  zodat je nooit 1 item geselecteerd voor BU ziet staan?
            if (
                selectedItems.length == 1
                && (!this.formData || this.formData.schema.name.indexOf(TreeMapFormComponent.BATCHUPDATE_FORM_NAME) != -1)
            ) {
                this.formDataObservable.next(null);
            }

            if (selectedItems.length < TreeMapFormComponent.MAX_URL_ITEMS) {
                // Convert objects to param string for url
                let params: string = '';

                selectedItems.forEach((item) => {
                    if (item.baseObjectId.toString() != '') {
                        params += item.baseObjectId + ',';
                    }
                });

                // If baseobjects found, remove last comma
                if (params != '') {
                    params = params.substr(0, params.length - 1);
                }

                if (navigateCallBack) {
                    navigateCallBack(params);
                } else {
                    // default handling
                    this._router.navigate([this.MODULE_FORM_URL, params], {queryParamsHandling: 'merge'});
                }
            } else {

                this.logger.log('[TreeMapFormComponent] ' + 'Too many selected items for url, apply directly: ' + selectedItems.length);
                // To many items to put in url. Skip url logic and store selected items on client directly

                this.selectedItemsObservable.next(selectedItems); // [{baseObjectId:params.id}]);
                this.tryGetBatchUpdateForm();
            }
        } else {
            // No items selected, reset al stored values
            this.clearSelectedItems();
        }
    }

    // When the selected tree nodes in the tree change
    handleTreeSelectionChanged(selectionData: {
        references: TreeNodeLMX[],
        treeNodes: {action: string}[],
        selectedInTree: boolean,
        treeCode: string,
        appendItems: boolean
    }) {
        // When no tree items are selected (or selected items are deselected), clear all visible items
        if ((selectionData.treeNodes && selectionData.treeNodes.length == 0)
            && (!selectionData.references || selectionData.references.length == 0)
        ) {
            this.clearVisibleItems();
            return;
        }

        if (selectionData.selectedInTree) {
            this.setActiveState(this.STATE_MAP);
        }

        this.setModuleObjectType();
        this.setModuleFormDataUrl();
    
        this.loadMapTableItems(selectionData, selectionData.appendItems, () => {
            if (!selectionData.selectedInTree && this.selectedItemsObservable.value && this.selectedItemsObservable.value.length == 1) {
                let baseObjectId: number|string = this.selectedItemsObservable.value[0].baseObjectId;
                // Give the map some time to focus on the markers, then highlight the marker
                setTimeout(() => {
                    this.mapTableComponent.mapComponent.highlightMarkerByBaseObjectId(baseObjectId);
                }, 500);
            }
        });

        if (this.expectTreeNodeActions && selectionData.selectedInTree && !this.mobileMode) {
            // When selecting 1 treenode
            if (selectionData.treeNodes && selectionData.treeNodes.length == 1) {

                // Execute action url on treenode
                if (selectionData.treeNodes[0].action && selectionData.treeNodes[0].action != '') {
                    this.gotoActionUrl(selectionData.treeNodes[0].action);
                } else {
                    this.clearSelectedItems();
                }
            }
        }
        
        this.onComponentEvent.emit({
            event: TreeMapFormEvent.TREE_NODE_SELECTED,
            data: {
                selectionData: selectionData
            }
        });
    }

    // TODO: voor nu is dit alleen voor het knopje dat verschijnt in mobile mode.
    //  Misschien moet dit anders genoemd worden of ook getriggert worden wanneer je niet in mobile zit. Alleen dan verlies je flexibileit.
    public handleTreeNodeAction(data: {action: string}): void {
        this.gotoActionUrl(data.action);
    }

    protected gotoActionUrl(url: string): void {
        // Overwrite this method in subclass for module specific implementation
    }

    protected setModuleFormDataUrl(): void {
        // Overwrite this method in subclass for module specific implementation
    }

    protected setModuleObjectType(): void {
        // Overwrite this method in subclass for module specific implementation
    }

    protected handleGlobalEvent(event: GlobalEvent): void {
        // Overwrite this method in subclass for module specific implementation
    }

    protected clearSelectedItemsForGrid():void{
        this.setActiveState(this.STATE_MAP);
        this.formDataObservable.next(null);
        this.selectedItemsObservable.next([]);
        this._router.navigate([this.MODULE_FORM_URL, 0], { queryParams: { grid : 1 } });
    }

    clearSelectedItems(navigationCommands: (string|number)[] = [this.MODULE_FORM_URL, 0], collapseForm: boolean = false): void {
        this.setActiveState(this.STATE_MAP);
        this.formDataObservable.next(null);

        this.selectedItemsObservable.next([]);
        this._router.navigate(navigationCommands);

        if (collapseForm) {
            // Setting selected items to null wont trigger a form collapse in this module, so do it manually here
            this.formCollapsedObservable.next(true);
        }
    }

    protected clearVisibleItems(navigationCommands: (string|number)[] = [this.MODULE_FORM_URL, 0]): void {
        // Clear items, clear selected state, clear form
        this.mapItemsObservable.next({mapItems: []});
        this.tableItemsObservable.next([]);
        this.formDataObservable.next(null);
        this.selectedItemsObservable.next([]);
        this._router.navigate(navigationCommands);

        // Tell mobile to go to tree page
        this.setActiveState(this.startingState);
    }

    protected tryGetBatchUpdateForm(appendURL: string = null): void {
        // Only reload the form if the last form wasn't an batchupdate form
        // If you do reload the form, you will clear all typed input content
        // (that's not wanted when selecting extra items to add to the batch)

        if (this.auth.allowBatchUpdate() &&
            (this.model.currentTree.value &&
                this.model.currentTree.value.code !== 'PERFECT_VIEW'
            //    &&
            //    this.model.currentTree.value.code !== 'MSB_MELDING'
            )) {
                // TODO: dit is niet heel veilig om alleen op de url te checken
                if (!this.httpService.isPendingCallPath(['/batch-update/get'])) {
                    // alleen voor interarealen een refresh
                    if (this.model.currentAreaal.value.interArea) {
                        this.getBatchUpdateForm(this.MODULE_BATCHUPDATE_URL + (appendURL ? '/' + appendURL : ''));
                    } else if (!this.formData || this.formData.schema.name.indexOf(TreeMapFormComponent.BATCHUPDATE_FORM_NAME) == -1) {
                        this.getBatchUpdateForm(this.MODULE_BATCHUPDATE_URL + (appendURL ? '/' + appendURL : ''));
                    }
                    // In some cases (e.g. the control module) a baseobject id is appended to determine
                    // the type of batch update form that needs to be returned
                }
        }
    }

    getBatchUpdateForm(url: string): void {
        this.formDataService.getBatchUpdateFormData(this.MODULE_FORM_DATA_URL, this.formDataObservable, url,
            () => {
            },
            () => {
            },
            () => {
            },
        );
    }

    getFormForMasterData(id: string, errorCallBack?: () => void, previousObjectId?: number): void {
        this.formDataService.getFormDataForIdString(this.MODULE_FORM_DATA_URL, this.formDataObservable, id,
            () => {},
            (value: any) => {
                this.logger.log('[TreeMapFormComponent] could not find baseobject in area, trigger area switch: ', value);
                this.logger.log('[TreeMapFormComponent] called url: ', this._router.url);
                const calledUrl = this._router.url;
                if (value[0]?.areaId) {
                    this.areaalService.loadAreaal(value[0].areaId, true,
                        (json: any) => {
                            this.logger.log('[TreeMapFormComponent] success area switch (deep link)', json);
                            setTimeout(() => {
                                this._router.navigateByUrl(calledUrl);
                            }, 100)
                        },
                        (failure: RequestFailure) => {
                            this.logger.log('[TreeMapFormComponent] failed area switch (deep link)', failure);
                            this.globalAlertService.addAlertFailure(failure);
                        },
                        LuminizerRoutes.INITIAL_PAGE,
                    );
                }
            },
            () => {
                if (errorCallBack) {
                    errorCallBack();
                }
            }, previousObjectId,
        );
    }

    getForm(id: number, errorCallBack?: () => void, previousObjectId?: number): void {
        this.formDataService.getFormDataForId(this.MODULE_FORM_DATA_URL, this.formDataObservable, id,
            () => {},
            (value:any) => {
                this.logger.log('[TreeMapFormComponent] could not find baseobject in area, trigger area switch: ', value);
                this.logger.log('[TreeMapFormComponent] called url: ', this._router.url);
                const calledUrl = this._router.url;
                if (value[0]?.areaId) {
                    this.areaalService.loadAreaal(value[0].areaId, true,
                        (json: any) => {
                            this.logger.log('[TreeMapFormComponent] success area switch (deep link)', json);
                            setTimeout(() => {
                                this._router.navigateByUrl(calledUrl);
                            }, 100)
                        },
                        (failure: RequestFailure) => {
                            this.logger.log('[TreeMapFormComponent] failed area switch (deep link)', failure);
                            this.globalAlertService.addAlertFailure(failure);
                        },
                        LuminizerRoutes.INITIAL_PAGE,
                    );
                }
            },
            () => {
                if (errorCallBack) {
                    errorCallBack();
                }
            }, previousObjectId, this.MODULE_BATCHUPDATE_URL
        );
    }

    // Very important! Unsubscribe to the observable, or it will trigger multiple times when the view is recreated
    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());

        // Turn off auto refresh timers when closing the module
        this.currentAutoRefreshId = -1;
    }
    
    public handleComponentEvent($event: any) {
        this.onComponentEvent.emit($event);
    }
    
    public handleMapLongPress($event: LongPressEvent) {
        // overwrite in extending class
    }
    
    public handleTreeNodeCreate($event: CreateNodeObject) {
        // overwrite in extending class
    }
    
    public handleSwitchTree() {
        // overwrite in extending class
    }
    
    private setMapTableSettings(mapTableSettings: MapTableSettings) {
        for (const [key, value] of Object.entries(mapTableSettings)) {
            this._mapTableSettings[key] = value;
        }
    }
    
    private setTreeSettings(treeSettings: TreeSettings) {
        for (const [key, value] of Object.entries(treeSettings)) {
            this._treeSettings[key] = value;
        }
    }
    
    public clearMapTableFilters(): void {
        this.tableOptionsService.resetTableOptions(this.tableOptions);
        this.mapTableComponent.filterItems(this.filterString.value, true);
    }
    
    public applyMapTableFilters(filters: Filter[]): void {
        const tableFields: TableOptionsField[] = [];
        filters.forEach(filter => {
            const tableField = JSON.parse(JSON.stringify(
                this.tableOptions.tableFields.find(field => field.code === filter.tableColumnCode)
            ));
            if (!tableField) {
                this.logger.log('[TreeMapForm]' + 'no tablefield found for columncode ', filter.tableColumnCode);
                return;
            }
            tableField.filter = {
                command: filter.filterCommand,
                values: filter.filterValues
            };
            tableFields.push(tableField);
        });
        const newTableOptions: TableOptions = {
            showArchived: this.tableOptions.showArchived,
            tableFields: tableFields
        };
        this.tableOptionsService.mergeTableOptions(this.tableOptions, newTableOptions);
        this.mapTableComponent.filterItems(this.filterString.value, true);
    }
}

export interface FormDataInterface {
    schema: {
        name: string,
        base_object_id: number
    }
}
