/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable, OnInit} from '@angular/core';
import {GlobalModel} from '../../shared/services/state/global.model';
import {RequestFailure} from '../../shared/services/http/request-failure';
import {GlobalAlert} from './global-alert';
import {ButtonCode, ButtonInterface, GlobalPopup, GlobalPopupType, PopupResult} from './global-popup';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {TableOptions} from '../../shared/components/table/tableColumnSelector/table-options';
import {PopupMergeOptionInterface} from './alerts/global-popup-merge-dropdown.component';
import {
    Attachment, IAttachmentCarousel,
    IImageCarousel
} from "../../shared/components/form/components/form-attachment/form-attachment.interface";
import {LoggerService} from "../../shared/services/logger/logger.service";
import {
    IControlCalendar
} from "../../shared/components/gui-form/components/control-calendar/control-calendar.interface";

@Injectable({
    providedIn: 'root'
})
export class GlobalAlertService implements OnInit { // extends HTTPService{

    public static ALERT_TITLE_ERROR: string = '';
    public static ALERT_TITLE_HTTP_ERROR: string = '';
    public static ALERT_TITLE_HTTP_FORBIDDEN = ''; // 403
    public static ALERT_TITLE_EMPTY_RESPONSE: string = '';
    public static ALERT_TITLE_TODO: string = '';
    public static ALERT_TITLE_WARNING: string = '';
    public static ALERT_TITLE_NO_CONFIG: string = '';
    public static ALERT_TITLE_NO_IE_SUPPORT: string = '';

    public static readonly ALERT_ICON_BUG: any = {type: 'bug_report', color: '#8f8f8f'};
    public static readonly ALERT_ICON_ERROR: any = {type: 'error_outline', color: '#d9534f'};
    public static readonly ALERT_ICON_TIME: any = {type: 'access_time', color: '#8f8f8f'};
    public static readonly ALERT_ICON_SUCCESS: any = {type: 'done', color: '#5AAB9C'};
    public static readonly ALERT_ICON_OVERRIDE_ON: any = {type: 'toggle_on', color: '#90EE90'};
    public static readonly ALERT_ICON_OVERRIDE_OFF: any = {type: 'toggle_off', color: '#8f8f8f'};
    public static readonly ALERT_ICON_WORK_ORDER: any = {type: 'assignment', color: '#8f8f8f'};
    public static readonly ALERT_ICON_FIRST_LOGIN: any = {type: 'grade', color: '#ffc463'};
    public static readonly ALERT_ICON_NO_SUPPORT: any = {type: 'warning', color: '#ffc463'};
    public static readonly ALERT_ICON_SETTINGS: any = {type: 'settings', color: '#8f8f8f'};

    public static readonly ALERT_TYPE_DEFAULT: number = 0;
    public static readonly ALERT_TYPE_FIRST_LOGIN: number = 1;
    public static readonly ALERT_TYPE_HTTP_ERROR: number = 3;
    public static readonly ALERT_TYPE_DEBUG: number = 4;

    public static readonly DEFAULT_SUCCESS_ALERT_AUTO_FADE_TIME: number = 3000; // in milliseconds
    
    public static readonly DEFAULT_BLUE_BUTTON_CLASSES: string = 'btn btn-primary px-2 px-md-4 global-alert-button-min-width';
    public static readonly DEFAULT_WHITE_BUTTON_CLASSES: string = 'btn btn-white text-gray-dark px-2 px-md-4 global-alert-button-min-width';

    public static readonly BUTTON_ROLE_SUBMIT: string = 'submit';

    private _globalAlerts: GlobalAlert[] = [];
    private _globalPopups: GlobalPopup[] = [];

    private subChangeLanguage: Subscription;

    constructor(private model: GlobalModel, private ts: TranslateService, protected logger:LoggerService) {}
    
    ngOnInit() {
        this.subChangeLanguage = this.model.changeLanguage.subscribe(() => {
            GlobalAlertService.ALERT_TITLE_ERROR = this.ts.translate('Error');
            GlobalAlertService.ALERT_TITLE_HTTP_ERROR = this.ts.translate('HTTP Error');
            GlobalAlertService.ALERT_TITLE_HTTP_FORBIDDEN = this.ts.translate('Verboden toegang');
            GlobalAlertService.ALERT_TITLE_EMPTY_RESPONSE = this.ts.translate('Empty response');
            GlobalAlertService.ALERT_TITLE_TODO = this.ts.translate('TODO');
            GlobalAlertService.ALERT_TITLE_WARNING = this.ts.translate('Waarschuwing');
            GlobalAlertService.ALERT_TITLE_NO_CONFIG = this.ts.translate('Niet geconfigureerd');
            GlobalAlertService.ALERT_TITLE_NO_IE_SUPPORT = this.ts.translate('alert.noiesupporttitle');
        });
    }
    
    //Return true when a popup is currently active
    public isPopupActive(): boolean {
        return this._globalPopups.length > 0;
    }
    
    isPopupActiveByType(type: GlobalPopupType) {
        return this._globalPopups.filter((popup) => popup.type === type).length > 0 ;
    }

    private publishNewPopup(newPopup: GlobalPopup): void {
        this._globalPopups.push(newPopup);
        this.model.globalPopups.next(this._globalPopups);
    }

    public addPopup(title: string, label: string, buttonArray: any[], callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.BASIC);
        newPopup.title = title;
        newPopup.buttonArray = buttonArray;
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateAreaal(title: string, label: string, newUrl: string, createUrl: string, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_AREAAL);
        newPopup.title = title;
        newPopup.label = label;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Opslaan'),
            code: ButtonCode.SAVE,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {requestUrl: newUrl, submitUrl: createUrl};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateEditMapServer(title: string, label: string, newUrl: string, createUrl: string, callbackSuccess: any, callbackCheckStatus: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_MAP_SERVER);
        newPopup.title = title;
        newPopup.label = label;
        newPopup.buttonArray = [{
            label: this.ts.translate('mapserver.checkstatus'),
            code: ButtonCode.OK,
            callback: callbackCheckStatus,
            isPrimary: true,
        }, {
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Opslaan'),
            code: ButtonCode.SAVE,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {requestUrl: newUrl, submitUrl: createUrl};

        this.publishNewPopup(newPopup);
    }

    public addPopupInput(title: string, label: string, preFill: string, buttonArray: ButtonInterface[], callbackClose: () => void) {
        let newPopup = new GlobalPopup(GlobalPopupType.BASIC_INPUT);
        newPopup.title = title;
        newPopup.buttonArray = buttonArray;
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {preFill: preFill};

        this.publishNewPopup(newPopup);
    }

    public addPopupTimer(title: string, label: string, timer: number, buttonArray: any[], callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.TIMER);
        newPopup.title = title;
        newPopup.buttonArray = buttonArray;
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {idleTimer:true, idlePopupTime:timer};

        this.publishNewPopup(newPopup);
    }

    public addPopupSetOverride(callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.SET_OVERRIDE);
        newPopup.buttonArray = []; //[{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, callback:callbackFail, classes:GlobalAlertService.DEFAULT_WHITE_BUTTON_CLASSES}, {label:this.ts.translate("Instellen"), code:ButtonCode.OK, role:"submit", callback:callbackSuccess, classes:GlobalAlertService.DEFAULT_BLUE_BUTTON_CLASSES}];
        newPopup.callbackClose = callbackFail;
        newPopup.callbackSuccess = callbackSuccess;

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateException(dimGroupId:number, controlExceptionId:number, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_EXCEPTION);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {controlExceptionId: controlExceptionId, dimGroupId:dimGroupId};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreatePublicReport(newUrl: string, createUrl: string, callbackSuccess: any, callbackFail: any, newLat?: any, newLng?: any, baseObjectId?: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_PUBLIC_REPORT);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {
            requestUrl: newUrl,
            submitUrl: createUrl,
            lat: newLat,
            lng: newLng,
            baseObjectId: baseObjectId,
        };

        this.publishNewPopup(newPopup);
    }
    public addPopupViewStedinOrder(newUrl: string, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.VIEW_STEDIN_ORDER);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {requestUrl: newUrl};

        this.publishNewPopup(newPopup);
    }

    public addPopupViewFormPreview(newUrl: string,  mapItemBushDropDownData: any[], callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.FORM_PREVIEW);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {
            requestUrl: newUrl,
            mapItemBushDropDownData: mapItemBushDropDownData
        };


        this.publishNewPopup(newPopup);
    }


    // tslint:disable-next-line:max-line-length
    public addPopupCreateExternalMalfunctionReport(newUrl: string, createUrl: string, callbackSuccess: any, callbackFail: any,
                                       newLat?: any, newLng?: any, externalMalfunctionReportId?: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_GENERIC_REPORT);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;

        newPopup.data = {requestUrl: newUrl, submitUrl: createUrl, lat: newLat, lng: newLng, externalMalfunctionReportId: externalMalfunctionReportId};

        this.publishNewPopup(newPopup);
    }

    // tslint:disable-next-line:max-line-length
    public addPopupCreateGenericReport(newUrl: string, createUrl: string, callbackSuccess: any, callbackFail: any,
                                       newLat?: any, newLng?: any, msbMeldingAssetId?: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_GENERIC_REPORT);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;

        newPopup.data = {requestUrl: newUrl, submitUrl: createUrl, lat: newLat, lng: newLng, msbMeldingAssetId: msbMeldingAssetId};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateUser(newUrl: string, createUrl: string, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_USER);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {requestUrl: newUrl, submitUrl: createUrl};

        this.publishNewPopup(newPopup);
    }

    public addPopupInviteUser(newUrl: string, createUrl: string, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.INVITE_USER);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Uitnodigen'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {requestUrl: newUrl, submitUrl: createUrl};

        this.publishNewPopup(newPopup);
    }

    public addPopupAddAttachmentToGenericReport(newUrl: string, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.ADD_ATTACHMENT_TO_GENERIC_REPORT);
        newPopup.buttonArray = [{
            label: this.ts.translate('genericreport.buttondone'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {requestUrl: newUrl};

        this.publishNewPopup(newPopup);
    }

    public addPopupEditScheme(selectedScheme: number, selectedChannel: number, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.EDIT_SCHEME);
        newPopup.callbackClose = callbackClose;
        newPopup.data = {selectedScheme: selectedScheme, selectedChannel: selectedChannel};

        this.publishNewPopup(newPopup);
    }

    public addPopupDeleteBaseObject(title: string, cascadeEffects: any, buttonArray: any[], callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.DELETE_BASEOBJECT);
        newPopup.title = title;
        newPopup.buttonArray = buttonArray;
        newPopup.label = '';
        newPopup.callbackClose = callbackClose;
        newPopup.data = {cascadeEffects: cascadeEffects};

        this.publishNewPopup(newPopup);
    }

    public addPopupAboutUs(callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.ABOUT_US);
        newPopup.buttonArray = [{
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackClose;

        this.publishNewPopup(newPopup);
    }


    public addPopupMoveItem(mapItemId: number, callbackSuccess: any, callbackFail: any, newLat?: any, newLng?: any) {
        //"Item verplaatsen", "Wilt u item \'" + itemLabel + "\' verplaatsen naar de volgende locatie:",

        let newPopup = new GlobalPopup(GlobalPopupType.MOVE_ITEM);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {mapItemId: mapItemId, lat: newLat, lng: newLng};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreatePVStoring(mapItemId: number, callbackSuccess: any, callbackFail: any, newLat?: any, newLng?: any) {

        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_PV_STORING);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {mapItemId: mapItemId, lat: newLat, lng: newLng};

        this.publishNewPopup(newPopup);
    }
    public addPopupCreateMsbMelding(mapItemId: number, callbackSuccess: any, callbackFail: any, newLat?: any, newLng?: any) {

        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_MSB_MELDING);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {mapItemId: mapItemId, lat: newLat, lng: newLng};

        this.publishNewPopup(newPopup);
    }
    public addPopupCreateWorkorderFromExternalMalfunctionReport(mapItemId: number, callbackSuccess: any, callbackFail: any,
                                                                newLat?: any, newLng?: any) {

        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_WORKORDER_FROM_EXTERNAL_MALFUNCTION_REPORT);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {mapItemId: mapItemId, lat: newLat, lng: newLng};

        this.publishNewPopup(newPopup);
    }

    public addPopupExternalMalfunctionReportChangeState(statusCode: string, externalMalfunctionReportId: number, callbackSuccess: any, callbackFail: any, newLat?: any, newLng?: any) {

        let newPopup = new GlobalPopup(GlobalPopupType.EXTERNAL_MALFUNCTION_REPORT_CHANGE_STATE);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {statusCode: statusCode, externalMalfunctionReportId: externalMalfunctionReportId};

        this.publishNewPopup(newPopup);
    }
    public addPopupAddExternalMalfunctionReportToWorkorder(mapItemId: number, callbackSuccess: any, callbackFail: any) {

        let newPopup = new GlobalPopup(GlobalPopupType.ADD_EXTERNAL_MALFUNCTION_REPORT_TO_WORKORDER);
        newPopup.buttonArray = [{
            label: 'Koppel aan bestaande',
            code: ButtonCode.EXTERNAL_MALFUNCTION_REPORT_PAIR_EXISTING,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        },
            {
                label: 'Annuleren',
                code: ButtonCode.ANNULEREN,
                callback: callbackFail,
                isPrimary: false,
            }, {
                label: 'Maak nieuwe aan',
                code: ButtonCode.EXTERNAL_MALFUNCTION_REPORT_PAIR_NEW,
                callback: callbackSuccess,
                role: 'submit',
                isPrimary: true,
            }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {mapItemId: mapItemId};

        this.publishNewPopup(newPopup);
    }
    public addPopupAddMsbMeldingToWorkorder(mapItemId: number, callbackSuccess: any, callbackFail: any) {

        let newPopup = new GlobalPopup(GlobalPopupType.ADD_MSB_MELDING_TO_WORKORDER);
        newPopup.buttonArray = [{
            label: 'Koppel aan bestaande',
            code: ButtonCode.MSB_PAIR_EXISTING,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        },
            {
            label: 'Annuleren',
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: 'Maak nieuwe aan',
            code: ButtonCode.MSB_CREATE_NEW,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {mapItemId: mapItemId};

        this.publishNewPopup(newPopup);
    }
    public addPopupCreateAdditionalMSBMelding(msbMeldingAssetId: number, callbackSuccess: any, callbackFail: any) {

        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_ADDITIONAL_MSB_MELDING);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: 'Maak nieuwe aan',
            code: ButtonCode.MSB_CREATE_NEW,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {msbMeldingAssetId: msbMeldingAssetId};
        this.publishNewPopup(newPopup);
    }

    public addPopupWerkbonSuggestionCreateNewWorkorder(werkbonSuggestionId: number, callbackSuccess: any, callbackFail: any, newLat?: any, newLng?: any) {

        let newPopup = new GlobalPopup(GlobalPopupType.WERKBON_SUGGESTION_CREATE_NEW_WORKORDER);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {werkbonSuggestionId: werkbonSuggestionId};

        this.publishNewPopup(newPopup);
    }

    public addPopupMeldingApiChangeState(statusCode: string, msbMeldingAssetId: number, callbackSuccess: any, callbackFail: any, newLat?: any, newLng?: any) {

        let newPopup = new GlobalPopup(GlobalPopupType.MSB_MELDING_CHANGE_STATE);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {statusCode: statusCode, msbMeldingAssetId: msbMeldingAssetId};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateWorkPreparation(baseObjectId: number, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_WORK_PREPARATION);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {baseObjectId: baseObjectId};

        this.publishNewPopup(newPopup);
    }

    // tslint:disable-next-line:max-line-length
    public addPopupEditWorkPreparation(preparationId: number, newUrl: string, createUrl: string, preparationDropDownData: any[],
                                         callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.EDIT_WORK_PREPARATION);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {
            preparationId: preparationId,
            requestUrl: newUrl,
            submitUrl: createUrl,
            preparationDropDownData: preparationDropDownData,
        };

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateWorkActivity(baseObjectId: number, newUrl: string, createUrl: string, activityDropDownData: any[], callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_WORK_ACTIVITY);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {
            baseObjectId: baseObjectId,
            requestUrl: newUrl,
            submitUrl: createUrl,
            activityDropDownData: activityDropDownData,
        };

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateCheckActivity(baseObjectId: number, newUrl: string, createUrl: string,
                                       activityDropDownData: any[], batchUpdate  = false,
                                       callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_CHECK_ACTIVITY);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {
            baseObjectId: baseObjectId,
            requestUrl: newUrl,
            submitUrl: createUrl,
            activityDropDownData: activityDropDownData,
            batchUpdate: batchUpdate
        };

        this.publishNewPopup(newPopup);
    }
    
    public addPopupCreateMapItems(callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_MAPITEMS);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('mapItemCreate.button.ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
    
        this.publishNewPopup(newPopup);
    }

    public addPopupCreateWorkSpecification(baseObjectId:number, newUrl:string, createUrl:string, specificationDropDownData:any[], callbackSuccess:any, callbackFail:any)
    {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_WORK_SPECIFICATION);
        newPopup.buttonArray = [{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, callback:callbackFail, isPrimary:false}, {label:this.ts.translate("Ok"), code:ButtonCode.OK, callback:callbackSuccess, role:'submit', isPrimary:true}];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {baseObjectId:baseObjectId, requestUrl:newUrl, submitUrl:createUrl, specificationDropDownData:specificationDropDownData};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateWeekprijs(termijnstaatId: number, newUrl: string, createUrl: string, weekprijsDropDownData: any[],
                                   callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_WEEKPRIJS);
        newPopup.buttonArray = [{label: this.ts.translate('Annuleren'), code: ButtonCode.ANNULEREN, callback: callbackFail,
            isPrimary: false}, {label: this.ts.translate('Ok'), code: ButtonCode.OK, callback: callbackSuccess, role: 'submit',
            isPrimary: true}];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {termijnstaatId: termijnstaatId, requestUrl: newUrl, submitUrl: createUrl,
            weekprijsDropDownData: weekprijsDropDownData};

        this.publishNewPopup(newPopup);
    }

    public addPopupCopyBaseObject(mapItemId: number, callbackSuccess: any, callbackFail: any, newLat?: any, newLng?: any) {
        //"Item verplaatsen", "Wilt u item \'" + itemLabel + "\' verplaatsen naar de volgende locatie:",

        let newPopup = new GlobalPopup(GlobalPopupType.COPY_BASEOBJECT);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {mapItemId: mapItemId, lat: newLat, lng: newLng};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateDropdown(title: string, label: string, path: string, callbackSuccess: any, callbackClose: any, autoFill?: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_DROPDOWN);
        newPopup.title = title;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('Toevoegen'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {path: path, autoFill: autoFill};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateMasterData(title: string, label: string, path: string, callbackSuccess: any, callbackClose: any, autoFill?: any, masterDataType?: string) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_MASTERDATA);
        newPopup.title = title;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('Toevoegen'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {path: path, autoFill: autoFill, masterDataType: masterDataType};

        this.publishNewPopup(newPopup);
    }

    public addPopupEditDropdown(title: string, label: string, path: string, oldValue: string, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.EDIT_DROPDOWN);
        newPopup.title = title;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('alert.change'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {path: path, oldValue: oldValue};

        this.publishNewPopup(newPopup);
    }

    public addPopupMergeDropdown(title: string, label: string, path: string, options: PopupMergeOptionInterface[], valueToReplace: PopupMergeOptionInterface, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.MERGE_DROPDOWN);
        newPopup.title = title;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('alert.change'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {path: path, options: options, valueToReplace: valueToReplace};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateBaseObject(title: string, label: string, newUrl: string, createUrl: string, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_BASEOBJECT);
        newPopup.title = title;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {requestUrl: newUrl, submitUrl: createUrl};

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateMapItem(title: string, label: string, newUrl: string, createUrl: string, entityDropdownData: any[], callbackSuccess: any, callbackClose: any, newLat?: any, newLng?: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_MAPITEM);
        newPopup.title = title;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {
            requestUrl: newUrl,
            submitUrl: createUrl,
            lat: newLat,
            lng: newLng,
            entityDropdownData: entityDropdownData,
        };

        this.publishNewPopup(newPopup);
    }

    public addPopupCreateDrawingItem(title: string, label: string, newUrl: string, createUrl: string, entityDropdownData: any[], geometry: any, newLat: any, newLng: any, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CREATE_DRAWINGITEM);
        newPopup.title = title;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanmaken'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {
            requestUrl: newUrl,
            submitUrl: createUrl,
            lat: newLat,
            lng: newLng,
            entityDropdownData: entityDropdownData,
            geometry:geometry
        };

        this.publishNewPopup(newPopup);
    }

    public addPopupWarning(callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.WARNING);
        // {label:this.ts.translate("ie-warning.okbutton"), code:ButtonCode.OK, callback:callbackClose, isPrimary:false},
        newPopup.buttonArray = [{
            label: this.ts.translate('popup-warning.cancel'),
            code: ButtonCode.ANNULEREN,
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackClose;

        this.publishNewPopup(newPopup);
    }

    public addPopupAreYouSure(callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.WARNING);
        // {label:this.ts.translate("ie-warning.okbutton"), code:ButtonCode.OK, callback:callbackClose, isPrimary:false},
        newPopup.buttonArray = [{
            label: this.ts.translate('popup-warning.cancel'),
            code: ButtonCode.ANNULEREN,
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackClose;

        this.publishNewPopup(newPopup);
    }

    public addPopupViewAttachment(attachment: any, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.VIEW_ATTACHMENT);
        newPopup.buttonArray = [{
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];

        newPopup.callbackClose = callbackClose;
        newPopup.data = {attachment: attachment};

        this.publishNewPopup(newPopup);
    }

    public addPopupViewImagesCarousel(attachmentImageList:IAttachmentCarousel, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.VIEW_IMAGES_CAROUSEL);
        newPopup.buttonArray = [{
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];

        newPopup.callbackClose = callbackClose;
        newPopup.data = attachmentImageList;

        this.publishNewPopup(newPopup);
    }

    public addPopupBarCodeScanner(title: string, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.BARCODE_SCANNER);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('scanner.swap'),
            code: ButtonCode.SWAP_CAMERA,
            isPrimary: false,
        }, {
            label: this.ts.translate('scanner.use'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.title = title;
        newPopup.callbackClose = callbackClose;

        this.publishNewPopup(newPopup);
    }

    public addPopupMapLegend(allowSingleSelect: boolean, allowMultiSelect: boolean, allowMarkerDrag: boolean, allowEasyMarkerDrag: boolean, allowCreateMarker: boolean, useCustomIconColors: boolean, iconsSet: 'default'|'mechanic'|'smartdevices', callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.MAP_LEGEND);
        newPopup.data = {
            allowSingleSelect: allowSingleSelect,
            allowMultiSelect: allowMultiSelect,
            allowMarkerDrag: allowMarkerDrag,
            allowEasyMarkerDrag: allowEasyMarkerDrag,
            allowCreateMarker: allowCreateMarker,
            useCustomIconColors: useCustomIconColors,
            iconSet: iconsSet
        };
        newPopup.callbackClose = callbackClose;

        this.publishNewPopup(newPopup);
    }

    public addPopupMapOptions(mapTypeId: string, POIVisible: boolean, heatmapOptionCode:string, mapPopupVisible: boolean, wmsLayerVisible: boolean, markerLabelsVisible: boolean, mapClustererVisible: boolean, isBasicMode:boolean, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.MAP_OPTIONS);
        newPopup.data = {
            mapTypeId: mapTypeId,
            POIVisible: POIVisible,
            heatmapOptionCode: heatmapOptionCode,
            mapPopupVisible: mapPopupVisible,
            wmsLayerVisible: wmsLayerVisible,
            markerLabelsVisible: markerLabelsVisible,
            mapClustererVisible: mapClustererVisible,
            isBasicMode: isBasicMode
        };
        newPopup.callbackClose = callbackClose;

        this.publishNewPopup(newPopup);
    }

    public addPopupTableOptions(tableOptions: TableOptions, hideMap, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.TABLE_OPTIONS);
        newPopup.buttonArray = [
            {label: this.ts.translate('Annuleren'), code: ButtonCode.ANNULEREN, isPrimary: false},
            {
                label: this.ts.translate('tableoptions.resetitem'),
                code: ButtonCode.RESET,
                callback: callbackSuccess,
                isPrimary: false,
            },
            /* {label:this.ts.translate("filter.new"), code:ButtonCode.ADD_FILTER, callback:callbackSuccess, classes:GlobalAlertService.DEFAULT_WHITE_BUTTON_CLASSES},*/
            {
                label: this.ts.translate('optionset.apply'),
                code: ButtonCode.OK,
                role: 'submit',
                callback: callbackSuccess,
                isPrimary: true,
            }];
        newPopup.callbackClose = callbackClose;
        newPopup.data = {tableOptions: tableOptions, hideMap: hideMap};

        this.publishNewPopup(newPopup);
    }

    public addPopupAddTableFilter(tableFields: any, selectedField: any, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.ADD_TABLE_FILTER);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];

        //Only add delete button when the popup is for an specific columns
        if (selectedField) {
            newPopup.buttonArray.splice(1, 0, {
                label: this.ts.translate('Verwijderen'),
                code: ButtonCode.DELETE,
                isPrimary: false,
                callback: callbackSuccess,
            });
        }

        newPopup.callbackClose = callbackClose;
        newPopup.data = {tableFields: tableFields, selectedField: selectedField};

        this.publishNewPopup(newPopup);
    }

    public addPopupEditTimeRegistration(allowDelete: boolean, newUrl: string, createUrl: string, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.EDIT_TIME_REGISTRATION);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('timeregistration.save'),
            code: ButtonCode.OK,
            role: GlobalAlertService.BUTTON_ROLE_SUBMIT,
            callback: callbackSuccess,
            isPrimary: true,
        }];

        if (allowDelete) {
            newPopup.buttonArray.splice(1, 0, {
                label: this.ts.translate('Verwijderen'),
                code: ButtonCode.DELETE,
                isPrimary: false,
                callback: callbackSuccess,
            });
        }

        newPopup.callbackClose = callbackFail;
        newPopup.data = {requestUrl: newUrl, submitUrl: createUrl};

        this.publishNewPopup(newPopup);
    }
    
    public addPopupRequestRefresh(onClose: any, refresh: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.REFRESH_REQUEST);
        newPopup.buttonArray = [{
            label: this.ts.translate('popup-refresh-request.okbutton'),
            code: ButtonCode.OK,
            callback: onClose
        }, {
            label: this.ts.translate('popup-refresh-request.refreshbutton'),
            code: ButtonCode.OK,
            callback: refresh,
            isPrimary: true
        }];
        newPopup.data = null;
        newPopup.title = this.ts.translate('popup-refresh-request.title');

        this.publishNewPopup(newPopup);
    }

    public addPopupFirstLogin(status:string, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.FIRST_LOGIN);
        newPopup.buttonArray = [{
            label: this.ts.translate('notifications.closemodal'),
            code: ButtonCode.CLOSE,
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.label = status
        newPopup.callbackClose = callbackClose;
        this.publishNewPopup(newPopup);
    }

    public addPopupLocalization(localizationKey: string, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.TRANSLATE);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            callback: callbackSuccess,
            role: 'submit',
            isPrimary: true,
        }];
        newPopup.callbackClose = callbackFail;
        newPopup.data = {localizationKey: localizationKey};

        this.publishNewPopup(newPopup);
    }

    public addPopupDeviceMapping(baseObjectId:number, icon:string, lat: number, lng:number, showUnMapButton:boolean, callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.DEVICE_MAPPING);
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            callback: callbackFail,
            isPrimary: false,
        }]
        if(showUnMapButton){
            newPopup.buttonArray.push({
                label: this.ts.translate('button.unlink-asset'),
                code: ButtonCode.UNMAP_DEVICE,
                callback: callbackSuccess,
                isPrimary: true
            })
        }
        newPopup.buttonArray.push({
            label: this.ts.translate('button.submit-asset-mapping'),
            code: ButtonCode.OK,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true
        })

        newPopup.callbackClose = callbackFail;
        newPopup.data = {baseObjectId: baseObjectId, icon: icon, lat: lat, lng: lng};

        this.publishNewPopup(newPopup);
    }

    public addPopupControlCalendar(calendar:IControlCalendar[], callbackSuccess: any, callbackFail: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CONTROL_CALENDAR);
        newPopup.buttonArray = [{
            label: this.ts.translate('close'),
            code: ButtonCode.CLOSE,
            callback: callbackSuccess,
            isPrimary: false,
        }]


        newPopup.callbackClose = callbackFail;
        newPopup.data = {calendar: calendar};

        this.publishNewPopup(newPopup);
    }


    public addPopupControlProgramEdit(selectedScheme: number, selectedChannel: number, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CONTROL_PROGRAM_EDIT);
        newPopup.callbackClose = callbackClose;
        newPopup.data = {selectedScheme: selectedScheme, selectedChannel: selectedChannel};

        this.publishNewPopup(newPopup);
    }

    public addPopupControlExceptionEdit(data:any, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.CONTROL_PROGRAM_EXCEPTION_EDIT);
        newPopup.callbackClose = callbackClose;
        newPopup.data = data;

        this.publishNewPopup(newPopup);
    }

    public editPopupUserDefinedWidget(title: string, label: string, newUrl: string, createUrl: string, callbackSuccess: any, callbackClose: any) {
        let newPopup = new GlobalPopup(GlobalPopupType.EDIT_USER_WIDGET);
        newPopup.title = title;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('Aanpassen'),
            code: ButtonCode.SAVE,
            role: 'submit',
            callback: callbackSuccess,
            isPrimary: true,
        }];
        newPopup.label = label;
        newPopup.callbackClose = callbackClose;
        newPopup.data = {
            requestUrl: newUrl,
            submitUrl: createUrl
        };

        this.publishNewPopup(newPopup);
    }

    public addAlert(title: string, subTitle: string, label: string, icon?: string, type?: number, autoFade?: number) {
        let newGlobalAlert: GlobalAlert = new GlobalAlert();
        newGlobalAlert.title = title;
        newGlobalAlert.subTitle = subTitle;
        newGlobalAlert.label = label;
        newGlobalAlert.icon = icon ? icon : GlobalAlertService.ALERT_ICON_ERROR;
        newGlobalAlert.type = type ? type : GlobalAlertService.ALERT_TYPE_DEFAULT;
        newGlobalAlert.autoFade = autoFade ? autoFade : -1; // use autofade time, or disable autofade

        this._globalAlerts.push(newGlobalAlert);
        this.model.globalAlerts.next(this._globalAlerts);

        this.logger.log('[GlobalAlertService] ' + 'alert succesvol');
    }

    public addAlertHTTPError(title: string, subTitle: string, label: string, error: any) {
        let newGlobalAlert: GlobalAlert = new GlobalAlert();
        newGlobalAlert.title = title;
        newGlobalAlert.subTitle = subTitle;
        newGlobalAlert.label = label;
        newGlobalAlert.icon = GlobalAlertService.ALERT_ICON_ERROR;
        newGlobalAlert.type = GlobalAlertService.ALERT_TYPE_HTTP_ERROR;
        newGlobalAlert.error = error;

        this._globalAlerts.push(newGlobalAlert);
        this.model.globalAlerts.next(this._globalAlerts);
    }

    // Default alert for simple debug message. Only display it for service engineers (use allowDebug)
    public addAlertDebugMessage(title: string, subTitle: string, label: string) {
        // NOTE: LET OP deze melding wordt zonder conditie ook weergeven voor niet service engineers
        //  Je moet zelf deze methode wrappen in een if (auth.allowDebug()){

        // TODO: een authservice meegeven als parameter aan deze functie en deze popup vanuit hier checken op rol == SE, niet meer van buitenaf
        this.addAlert(title, subTitle, label, GlobalAlertService.ALERT_ICON_BUG, GlobalAlertService.ALERT_TYPE_DEBUG);
    }

    // Default alert for simple success message. Has autofade
    public addAlertSuccess(title: string, subTitle: string, label: string, autoFadeTime?: number) {
        this.addAlert(title, subTitle, label, GlobalAlertService.ALERT_ICON_SUCCESS, GlobalAlertService.ALERT_TYPE_DEFAULT, autoFadeTime?autoFadeTime:GlobalAlertService.DEFAULT_SUCCESS_ALERT_AUTO_FADE_TIME);
    }
    
    // Default alert for simple success message. Has autofade
    public addAlertOverrideOn(title: string, subTitle: string, label: string) {
        this.addAlert(title, subTitle, label, GlobalAlertService.ALERT_ICON_OVERRIDE_ON, GlobalAlertService.ALERT_TYPE_DEFAULT, GlobalAlertService.DEFAULT_SUCCESS_ALERT_AUTO_FADE_TIME);
    }
    
    // Default alert for simple success message. Has autofade
    public addAlertOverrideOff(title: string, subTitle: string, label: string) {
        this.addAlert(title, subTitle, label, GlobalAlertService.ALERT_ICON_OVERRIDE_OFF, GlobalAlertService.ALERT_TYPE_DEFAULT, GlobalAlertService.DEFAULT_SUCCESS_ALERT_AUTO_FADE_TIME);
    }
    
    // Default alert for simple success message. Has autofade
    public addAlertStatusUpdate(text: string) {
        this.addAlert('', text, '', GlobalAlertService.ALERT_ICON_WORK_ORDER, GlobalAlertService.ALERT_TYPE_DEFAULT, GlobalAlertService.DEFAULT_SUCCESS_ALERT_AUTO_FADE_TIME);
    }

    // Default alert for when the 'data'-part of a json reponse was empty
    public addAlertEmptyResponse(path: string) {
        this.addAlert(GlobalAlertService.ALERT_TITLE_EMPTY_RESPONSE, 'No data received', 'URL: ' + path + '<br>' + 'The call finished successfully, but data was expected and none was returned');
    }

    public addAlertNoIESupport(title: string, label: string) {
        this.addAlert(GlobalAlertService.ALERT_TITLE_NO_IE_SUPPORT, title, label, GlobalAlertService.ALERT_ICON_NO_SUPPORT);
    }

    // Default alert for call failure
    public addAlertFailure(failure: RequestFailure) {
        this.addAlert(failure.title, '', failure.message);
    }

    //Close all alerts of a type
    public closeAllAlertsOfType(type: any) {
        //Splice in reverse
        for (let i = this._globalAlerts.length - 1; i >= 0; i--) {
            if (this._globalAlerts[i].type == type) {
                this._globalAlerts.splice(i, 1);
            }
        }

        this.model.globalAlerts.next(this._globalAlerts);
    }

    public removeAlert(alert: GlobalAlert): void {
        let alertIndex: number = this._globalAlerts.indexOf(alert);

        if (alertIndex != -1) {
            this._globalAlerts.splice(alertIndex, 1);
            this.model.globalAlerts.next(this._globalAlerts);
        }
    }

    public removePopup(popup: GlobalPopup): void {
        let popupIndex: number = this._globalPopups.indexOf(popup);

        if (popupIndex != -1) {
            this._globalPopups.splice(popupIndex, 1);
            this.model.globalPopups.next(this._globalPopups);
        }
    }
    
    public openPopupBasicForm(form, saveUrl, title = '', label = ''): Observable<any> {
        const popupResult: BehaviorSubject<PopupResult> = new BehaviorSubject<PopupResult>(null);
        let newPopup = new GlobalPopup(GlobalPopupType.BASIC_FORM);
        newPopup.title = title;
        newPopup.label = label;
        newPopup.form = form;
        newPopup.saveUrl = saveUrl;
        newPopup.result = popupResult;
        newPopup.buttonArray = [{
            label: this.ts.translate('Annuleren'),
            code: ButtonCode.ANNULEREN,
            isPrimary: false,
        }, {
            label: this.ts.translate('Ok'),
            code: ButtonCode.OK,
            role: 'submit',
            isPrimary: true,
        }];
    
        this.publishNewPopup(newPopup);
        return popupResult;
    }
}
