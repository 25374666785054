<default-popup-wrapper-component [componentRef]="this" [globalPopup]="globalPopup"
                                 [title]="globalPopup.title"
                                 (onClose)="closePopup($event)">
    <div *ngIf="isLoading" class="py-3 d-flex justify-content-center">
        <div class="loader"></div>
    </div>
    <div class="form-group-label-container mt-2 d-flex justify-content-between align-items-center">
        <h1>{{'control-program.yearview-title'|translate}} {{activeYearToShow}}</h1>
        <div class="year-select" *ngIf="getYears().length > 1">
            <h5 class=" d-flex align-items-center">
                <i class="material-icons" [ngClass]="{'visible':getYears()[0] < activeYearToShow}" (click)="changeYear(activeYearToShow-1)">keyboard_arrow_left</i>
                {{activeYearToShow}}
                <i class="material-icons" [ngClass]="{'visible':getYears()[getYears().length-1] > activeYearToShow}" (click)="changeYear(activeYearToShow+1)">keyboard_arrow_right</i>
            </h5>
        </div>
    </div>
    <div class="year-calendar" *ngIf="!isLoading">
        <div class="status-card month m-1" *ngFor="let month of getMonths()">
            <div class="inner">
                <div class="month-header mb-1">{{convertStringDateToFormat(month+'-01-1970','MMMM')}}</div>
                <div class="days-container d-flex flex-wrap">
                    <div class="day day-header">{{'control-program.yearview-header-mon'|translate}}</div>
                    <div class="day day-header">{{'control-program.yearview-header-tue'|translate}}</div>
                    <div class="day day-header">{{'control-program.yearview-header-wed'|translate}}</div>
                    <div class="day day-header">{{'control-program.yearview-header-thu'|translate}}</div>
                    <div class="day day-header">{{'control-program.yearview-header-fri'|translate}}</div>
                    <div class="day day-header">{{'control-program.yearview-header-sat'|translate}}</div>
                    <div class="day day-header">{{'control-program.yearview-header-sun'|translate}}</div>
                    <div class="break"></div>
                    <ng-container  *ngFor="let day of getDays(month);let i = index">
                        <div class="day" [ngClass]="{'empty-day':day.isEmptyPlaceholder}" [ngStyle]="{'border-color': day.control_program[0].color}" data-toggle="tooltip" data-placement="left" mat-raised-button matTooltip="{{day.control_program[0].name}}">
                            <span *ngIf="!day.isEmptyPlaceholder">{{convertStringDateToFormat(day.date,'DD')}}</span>
                            <span *ngIf="day.isEmptyPlaceholder">&nbsp;&nbsp;</span>
                        </div>
                        <div class="break" *ngIf="(i+1) % 7 == 0"></div>
                    </ng-container>
                    <ng-container *ngIf="getDays(month).length / 7 <= 5">
                        <div class="day empty-day" *ngFor="let e of [].constructor(7)"><span>&nbsp;&nbsp;</span></div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</default-popup-wrapper-component>
