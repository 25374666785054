<div class="container-fluid text-center dashboard-container mx-1 mt-3 no-mobile-side-margin">
    <div *ngIf="isLoading" class="py-3 d-flex justify-content-center">
        <div class="loader"></div>
    </div>
    <div *ngIf="!isLoading">
        <div class="widget-outer-container">
            <div class="widget-container d-flex align-items-start flex-wrap" *ngFor="let row of getRows()">
                <div class="widget-item text-left" *ngFor="let widget of getColumns(row); let i = index" [title]="widget.getDescription()">
                    <div class="header d-flex justify-content-between align-items-start">
                        <div class="title">{{widget.getTitle()}}</div>
                        <div class="actions pl-4 d-flex justify-content-end">
                            <div class="dropdown">
                                <i class="material-icons main-item p-0" (click)="widget.changeWidgetPosition(widget.getRow(), widget.getColumn()-1)" *ngIf="i > 0">keyboard_arrow_left</i>
                                <i class="material-icons main-item p-0" (click)="widget.changeWidgetPosition(widget.getRow(), widget.getColumn()+1)" *ngIf="i < getColumns(row).length-1">keyboard_arrow_right</i>
                                <i class="material-icons main-item pl-2 p-0" [attr.id]="'widgetSettings-'+widget.getId()" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">settings</i>
                                <div class="dropdown-menu" [attr.aria-labelledby]="'widgetSettings-'+widget.getId()">
                                    <div class="item d-flex align-items-center" (click)="widget.openChartEditor()"><i class="material-icons p-0 pr-1">bar_chart</i> {{'userdefined-dashboard.chart-edit' | translate}}</div>
                                    <div class="item d-flex align-items-center" (click)="widget.editWidget()"><i class="material-icons p-0 pr-1">edit</i> {{'userdefined-dashboard.widget-edit' | translate}}</div>
                                    <div class="dropdown-divider"></div>
                                    <div class="item d-flex align-items-center" (click)="widget.removeWidget()"><i class="material-icons p-0 pr-1">delete</i> {{'Verwijderen' | translate}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="widget-content">
                        <div class="widget-data" [attr.id]="'widgetChartContainer-'+widget.getId()"></div>
                        <div *ngIf="widget.isLoadingChartData" class="py-3 d-flex justify-content-center"><div class="loader"></div></div>
                        <div class="no-content" *ngIf="!widget.isLoadingChartData && widget.isEmptyWidget()"><p>{{'userdefined-dashboard.no-data' | translate}}</p><p><a (click)="widget.editWidget()">{{'userdefined-dashboard.configure-widget' | translate}}</a></p></div>
                    </div>
                </div>
                <div class="widget-item add-widget d-flex align-items-center justify-content-center">
                    <lumi-select *ngIf="availableWidgetTypesDropdown.length > 0"
                                 #availableWidgetTypesInput
                                 class="widget-types"
                                 [options]="availableWidgetTypesDropdown"
                                 [showOptionFilter]="availableWidgetTypesDropdown?.length > 10"
                                 (onOptionsSelect)="createWidget($event,row,getColumns(row)[getColumns(row).length-1].getColumn()+1)">
                    </lumi-select>
                    <i class="material-icons">add_circle</i>
                </div>
            </div>
            <!--Add new row -->
            <div class="widget-container d-flex align-items-start flex-wrap">
                <div class="widget-item add-widget d-flex align-items-center justify-content-center">
                    <lumi-select *ngIf="availableWidgetTypesDropdown.length > 0"
                                 #availableWidgetTypesInput
                                 class="widget-types"
                                 [options]="availableWidgetTypesDropdown"
                                 [showOptionFilter]="availableWidgetTypesDropdown?.length > 10"
                                 (onOptionsSelect)="createWidget($event,getRows()[getRows().length-1]+1,0)">
                    </lumi-select>
                    <i class="material-icons">add_circle</i>
                </div>
            </div>
        </div>
    </div>
</div>

