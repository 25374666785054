import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SimpleTree} from './simple-tree.interface';

@Component({
    selector: 'simple-tree',
    templateUrl: './simple-tree.component.html',
})
export class SimpleTreeComponent implements OnInit {
    @Input() tree: SimpleTree;
    @Input() selectedNodeId: number;
    @Output() onNodeClick: EventEmitter<number> = new EventEmitter();
    @Input() treeIcon: string;
    @Input() nodeIcon: string;
    @Input() expanded: boolean = true;
    
    constructor() {
    }
    
    ngOnInit() {
    }
    
    public handleNodeClick(nodeId: number): void {
        this.onNodeClick.emit(nodeId);
    }
    
    public toggleExpand($event: MouseEvent): void {
        this.expanded = !this.expanded;
    }
}
