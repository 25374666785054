<tree-map-form #treeMapFormComponent
               (onComponentEvent)="handleComponentEvent($event)">
    <div mapTableButtons class="d-flex align-items-center">
        <div *ngIf="masterDataType !== null">
            <lumi-button (onClick)="handleClickAdd()"
                         [icon]="'add'"
                         [title]="'Toevoegen'"
                         [label]="'Toevoegen'">
            </lumi-button>
        </div>
    </div>
</tree-map-form>
