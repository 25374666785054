<div class="card map-table-view-container default-dropdown {{isBasicMode?'pb-3':''}}"
     (globalKeydownOutsideAngular)="handleGlobalKeyDown($event)">

    <div class="py-3 {{!isBasicMode?'d-none':''}}">
        <h1 class="m-0" *ngIf="this.model.currentAreaal !== null && this.model.currentAreaal.value">{{'publicportal.title' | translate:[this.model.currentAreaal.value.label]}}</h1>
    </div>
    <div class="card public-portal-info py-2 {{!isBasicMode || !showBasicModeInfoText?'d-none':''}}">
        <i class="material-icons global-alert-close-icon ml-auto" style="margin-right: -0.5rem;" role="button"
           (click)="closePublicPortalPopup()">close</i>
        <ul class="pl-3 pb-1 m-0">
            <li [innerHTML]="'publicportal.info0' | translate"></li>
            <li [innerHTML]="'publicportal.info1' | translate"></li>
            <li>{{'publicportal.info2a' | translate}}
                <div class="d-inline-block"><img alt="" src="{{getImage('ond-green')}}"/></div>
                {{'publicportal.info2b' | translate}}
                <div class="d-inline-block"><img alt="" src="{{getImage('ond-orange')}}"/></div>
                {{'publicportal.info2c' | translate}}</li>
        </ul>
    </div>

    <div class="card report-generic-info py-2 pr-2 {{!showReportInfoText?'d-none':''}}">
        <i class="material-icons global-alert-close-icon ml-auto" style="margin-right: -0.25rem;" role="button"
           (click)="closeReportGenericPopup()">close</i>
        <ul class="pb-1 m-0 mr-4 pl-2">
            <li class="font-weight-bold text-center"
                style="list-style-type: none; margin-top: -1.4rem; margin-bottom: 0.5rem"
                [innerHTML]="'reportgeneric.infotitle' | translate"></li>
            <li [innerHTML]="'reportgeneric.info0' | translate"></li>
            <li [innerHTML]="'reportgeneric.info1' | translate"></li>
        </ul>
    </div>

    <div class="{{this.breakTopBar()? '':'d-flex'}}{{isBasicMode?'d-none':''}}">
        <div #topBar class="top-bar-container w-100">
            <div class="map-table-filter-container">
                <menu-dropdown #menu [menuTitle]="'tableoptions.selectionsection' | translate | uppercase"
                               [leftDropdown]="true"
                               [buttonColor]="hasSmartSelectionActive()? AppSettings.getColor('$selection-blue'):AppSettings.getColor('$color_text_darkgray')">
                    <ng-container *ngIf="tableOptionSets && tableOptionSets.length > 0">
                        <div style="overflow-y: auto; max-height: 250px; padding: 0 !important; background-color: white!important;">
                            <ng-container *ngFor="let optionsSet of tableOptionSets">
                                <div menuItem class="d-flex md-focusable {{optionsSet.isPreferred? 'dropdown-item-with-icon':''}}"
                                     [style.color]="(optionsSet.name === activeSmartSelection && !changedSelection)? AppSettings.getColor('$selection-blue'):''"
                                     (click)="handleClickTableOptionsSet($event,optionsSet)"
                                     title="{{'tableoptions.loadpreset' | translate}}">
                                    {{optionsSet.name}}
                                    <div class="mr-0 ml-auto"
                                         style="padding: 0 !important;"
                                         *ngIf="optionsSet.isPreferred">
                                        <i class="material-icons md-dark md-18 p-0"
                                           [style.color]="(optionsSet.name === activeSmartSelection && !changedSelection)? AppSettings.getColor('$selection-blue'):''">
                                            star
                                        </i>
                                    </div>
                                </div>
                            </ng-container>
                            <div menuItem (click)="handleClickTableOptionsReset()"
                                 class="md-focusable"
                                 title="{{'tableoptions.resettitle' | translate}}">
                                <span style="padding: 0"><i>{{'tableoptions.resetitem' | translate}}</i></span>
                            </div>
                        </div>
                        <p class="dropdown-divider"></p>
                    </ng-container>
                    <div *ngIf="!isMasterData" menuItem class="md-focusable" (click)="handleClickShowTableOptions()"
                         title="{{'tableoptions.optionstitle' | translate}}">{{'tableoptions.options' | translate}}</div>
                    <p *ngIf="!isMasterData" class="dropdown-divider"></p>
                    <div #subMenuExport (globalClickOutsideAngular)="handleClickOutside($event)"
                         (click)="handleSubMenuClick($event, false)"
                         menuItem class="md-focusable w-100 dropdown btn-group nav-item p-0 pr-2">
                        <a class="px-0 py-1 nav-link waves-effect waves-light menu_icon"
                           id="navbarDropdownMenuLink2" title="{{'tableoptions.exporttitle' | translate}}"
                           data-toggle="dropdown">
                            {{'tableoptions.export' | translate}}
                        </a>
                        <div class="dropdown-toggle-right"></div>
                        <div *ngIf="showSubMenuExport" class="sub-dropdown dropdown-menu dropdown-secondary dropdown-menu-left {{showSubMenuExport?'show-dropdown':''}}"
                             aria-labelledby="navbarDropdownMenuLink2" data-dropdown-in="fadeIn"
                             data-dropdown-out="fadeOut">
                            <div menuItem class="md-focusable" (click)="handleExportToExcel()"
                                 title="{{'tableoptions.exportexceltitle' | translate}}">
                                {{'tableoptions.exportexcel' | translate}}
                            </div>
                            <div menuItem class="md-focusable" (click)="handleExportToCsv()"
                                 title="{{'tableoptions.exportcsvtitle' | translate}}">
                                {{'tableoptions.exportcsv' | translate}}
                            </div>
                            <div menuItem class="md-focusable" (click)="handleCopyToClipboard()"
                                 title="{{'tableoptions.copytitle' | translate}}">
                                {{'tableoptions.copy' | translate}}
                            </div>
                        </div>
                    </div>
                    <div #subMenuExportSelection (globalClickOutsideAngular)="handleClickOutside($event)"
                         (click)="handleSubMenuClick($event, true)"
                         menuItem class="md-focusable w-100 dropdown btn-group nav-item p-0 pr-2">
                        <a class="px-0 py-1 nav-link waves-effect waves-light menu_icon"
                           id="navbarDropdownMenuLink3" title="{{'tableoptions.export-selection-title' | translate}}"
                           data-toggle="dropdown">
                            {{'tableoptions.export-selection' | translate}}
                        </a>
                        <div class="dropdown-toggle-right"></div>
                        <div *ngIf="showSubMenuExportSelection" class="sub-dropdown dropdown-menu dropdown-secondary dropdown-menu-left {{showSubMenuExportSelection?'show-dropdown':''}}"
                             aria-labelledby="navbarDropdownMenuLink3" data-dropdown-in="fadeIn"
                             data-dropdown-out="fadeOut">
                            <div menuItem class="md-focusable" (click)="handleExportToExcel(true)"
                                 title="{{'tableoptions.exportexceltitle' | translate}}">
                                {{'tableoptions.exportexcel' | translate}}
                            </div>
                            <div menuItem class="md-focusable" (click)="handleExportToCsv(true)"
                                 title="{{'tableoptions.exportcsvtitle' | translate}}">
                                {{'tableoptions.exportcsv' | translate}}
                            </div>
                            <div menuItem class="md-focusable" (click)="handleCopyToClipboard(true)"
                                 title="{{'tableoptions.copytitle' | translate}}">
                                {{'tableoptions.copy' | translate}}
                            </div>
                        </div>
                    </div>
                    <p class="dropdown-divider"></p>
                    <div menuItem class="md-focusable" (click)="handleClickRefresh()"
                         title="{{'tableoptions.refreshtitle' | translate}}">{{'tableoptions.refresh' | translate}}</div>
                    <div menuItem class="w-100 md-focusable" *ngIf="auth.allowAutoRefresh()" (click)="handleClickAutoRefresh()"
                         title="{{ts.translate('tableoptions.autorefreshtitle', [AUTO_REFRESH_INTERVAL])}}">
                        {{'tableoptions.autorefresh' | translate}}
                        <div *ngIf="autoRefresh" class="p-0 auto-refresh-checkmark"></div>
                    </div>
                </menu-dropdown>
                <input #filterInput initialFocus class="quick-filter {{hasQuickFilterActive()?'active':''}}"
                       placeholder="{{'Filteren...' | translate}}" title="{{'filter.tooltip' | translate}}"
                       type="text" (change)="handleChangeFilterInput($event)" [value]="_filterString"
                       [disabled]="httpService.isPendingCallPath([mapTableService.MAP_ITEM_PATH, mapTableService.TABLE_ITEMS_PATH])">
            </div>
            <ng-content select="[unbroken-top-bar]"></ng-content>
            <ul class="navbar-nav ml-auto nav-flex-icons map-table-view-toggle tab-bar">
                <li *ngFor="let tabItem of tabBarConfig;" class="nav-item" (click)="handleClickTab(tabItem)">
                    <a *ngIf="(!hideMap && (tabItem.code !== MAP_TABLE_TAB.MIXED || allowMixedView))" class="nav-link"><i
                            class="material-icons map-table-view-toggle-icon waves-effect waves-light {{tabItem.active?'active':''}} {{isIconMirrored(tabItem.code)?'mirror':''}}">{{tabItem.icon}}</i></a>
                </li>
            </ul>
        </div>
        <ng-content select="[broken-top-bar]"></ng-content>
    </div>

    <div class="map-table-container" style="position: relative">
        <geo-data-management-table-component
            #geoDataManagementTableComponent
            [tableOptions]="{
                            allowMultiLineInRow: allowMultiLineInRow,
                            mobileMode: mobileMode,
                            parentWidthPercentage: tabBarConfig[2].active?50:100,
                            canActivateRows: allowSingleSelect,
                            boldAllowed: false
                        }"
            [tableData]="geoDataTableData"
            [isLoading]="httpService.isPendingCallPath([mapTableService.TABLE_ITEMS_PATH])"
            [ngStyle]="{width:tabBarConfig[2].active?'50%':'100%', 'margin-right':tabBarConfig[2].active?'1rem':0}"
            (onRowMouseOver)="handleMouseOverTableRow($event)"
            (onRowAction)="handleClickTableRow($event)"
            (onFilterColumn)="openFilterPopup($event)"
            [hidden]="tabBarConfig[1].active"
            [multiSelect]="allowMultiSelect">
        </geo-data-management-table-component>
        <map-core-component #mapComponent [showSearchBar]="showSearchBar"
                            [showNoItemsWarning]="!showNoContentPopup"
                            (onInfoWindowDataRequest)="handleInfoWindowDataRequest($event)"
                            [isBasicMode]="isBasicMode"
                            [useCustomIconColors]="useCustomIconColors"
                            [iconSet]="iconSet"
                            [allowLocation]="true"
                            [allowAutoLoadMarkers]="allowAutoLoadMarkers"
                            [allowCreateMarker]="allowCreateMarker"
                            [allowMarkerDrag]="allowMarkerDrag"
                            [allowSingleSelect]="allowSingleSelect"
                            [allowMultiSelect]="allowMultiSelect"
                            [isLoading]="httpService.isPendingCallPath([mapTableService.MAP_ITEM_PATH])"
                            (onDragMarker)="handleDragMarker($event)" (onLongPress)="handleMapLongPress($event)"
                            (onClickMarker)="handleClickMarker($event)"
                            (onBoundsChangedDuringAutoLoad)="handleBoundsChanged($event)"
                            (onSelectMarkers)="handleSelectMarkers($event)"
                            (onMouseOverMarker)="handleMouseOverMarker($event)"
                            [hidden]="tabBarConfig[0].active || hideMap"
                            [ngStyle]="{width:tabBarConfig[2].active?'50%':'100%'}" class="map-table-component"
                            [showLoading]="showMapTableLoaders"></map-core-component>

        <div *ngIf="showNoContentPopup && !mobileMode" class="no-content-popup">
            <div class="loading-form">
                <h1 class=" text-truncate mb-2 pb-0">Selecteer een locatie</h1>
                <div>Kies een groep/locatie in de tree om aan de slag te gaan</div>
                <img alt="" class="img-fluid px-2 pt-3" src="../../../../assets/img/select_tree.jpg">
            </div>
        </div>
    </div>

    <div class="justify-content-between {{isBasicMode?'d-none':'d-flex'}}">
        <div class="d-flex" (click)="handleClickRefresh()"
             title="{{'tableoptions.refreshtitle' | translate}}">
            <i class="material-icons p-0 {{isRefreshing()?'auto-refreshing disabled':'cursor-pointer'}}">refresh</i>
            <div class="map-table-item-count pl-1">{{lastRefreshTime}}</div>
        </div>
        <div class="d-flex map-table-active-selection" style="flex:auto" title="active selection">
            <div *ngIf="activeSmartSelection">{{'smartselection.active' | translate}} {{activeSmartSelection}}{{changedSelection ? '*' : ''}}</div>
        </div>
        <div class="map-table-item-count"
             title="{{getMapItemCountTitle(getTotalItemCount(),getVisibleItemCount(),totalSelectedItems)}}">
            <i title="{{'filter.activewarningtitle' | translate}}" (click)="handleClickFilterWarning()"
               *ngIf="isFilterActive()" class="form-link-anchor pr-2">{{'filter.activewarning' | translate}}</i>
            <span>{{getVisibleItemCount()}}/{{getTotalItemCount()}} {{'Items' | translate }}</span>
            <a (click)="focusOnSelectedItems()"
               title="{{'tableoptions.scrollToSelectiontitle' | translate}}">{{totalSelectedItems > 0 ? ', ' + totalSelectedItems + ' ' + ('geselecteerd' | translate) : ''}}</a>
        </div>
    </div>
</div>
