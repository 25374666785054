<ng-container>
    <div [ngSwitch]="config.attr.type">
        <gateway-reset *ngSwitchCase="guiType.GATEWAY_RESET" [config]="config.attr"></gateway-reset>
        <device-state *ngSwitchCase="guiType.DEVICE_STATE" [config]="config.attr"></device-state>
        <create-stedin-order *ngSwitchCase="guiType.CREATE_STEDIN_ORDER" [config]="config.attr"></create-stedin-order>
        <interactive-table *ngSwitchCase="guiType.INTERACTIVE_TABLE" [config]="config"></interactive-table>
        <interactive-table-rules *ngSwitchCase="guiType.INTERACTIVE_TABLE_RULES" [config]="config"></interactive-table-rules>
        <create-device-mapping *ngSwitchCase="guiType.DEVICE_CREATE_MAPPING" [config]="config"></create-device-mapping>
        <control-calendar *ngSwitchCase="guiType.CONTROL_CALENDAR" [config]="config"></control-calendar>
    </div>
</ng-container>
