<div class="device-state">
    <div *ngIf="deviceStateOverview">
        <div class="status-card">
            <div class="statusbar {{deviceStateOverview.icon_name}}"></div>
            <div class="inner">
                <div class="image highlight">
                    <img [attr.src]="getImage(deviceStateOverview.icon_name + '-sel')" [attr.alt]="deviceStateOverview.base_state" [attr.title]="deviceStateOverview.base_state" />
                </div>
                <div class="info">
                    <div class="d-flex justify-content-between align-items-end">
                        <div class="state-info">
                            {{'devicestate.deviceStatus' | translate}}<br />
                            <span>{{deviceStateOverview.base_state}}</span>
                        </div>
                        <div class="state-time">
                            <ng-container *ngIf="deviceStateOverview.last_communication">
                                {{deviceStateOverview.last_communication | humanDate}}
                                <i class="material-icons md-dark cursor-pointer align-bottom align-right p-0" data-toggle="tooltip" data-placement="top" mat-raised-button matTooltip="{{'lastcommunication' | translate}}: {{deviceStateOverview.last_communication | momentDate: 'LLLL'}}<br />{{'alarms.amount' | translate}}: {{deviceStateOverview.alarms.length}}">info</i>
                            </ng-container>
                            <ng-container *ngIf="!deviceStateOverview.last_communication">
                                {{'lastcommunication.none' | translate}}
                                <i class="material-icons md-dark cursor-pointer align-bottom align-right p-0" data-toggle="tooltip" data-placement="top" mat-raised-button matTooltip="{{'lastcommunication.none' | translate}}<br />{{'alarms.amount' | translate}}: {{deviceStateOverview.alarms.length}}">info</i>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="tabs-container">
                    <ul class="nav" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="activeDeviceTab === 'messages' ? 'active': ''" (click)="handleStateTabChange('messages')" id="messages-tab" data-toggle="tab" href="#messages" role="tab" aria-controls="Meldingen" aria-selected="true">{{'devicestate.meldingen' | translate}}</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [ngClass]="activeDeviceTab === 'history' ? 'active': ''" (click)="handleStateTabChange('history')" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="Geschiedenis" aria-selected="false">{{'devicestate.geschiedenis' | translate}}</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div class="loading-block d-flex justify-content-center align-items-center" *ngIf="showLoading">
                            <div><img src="/assets/img/loading2022.gif" alt="{{'loading' | translate}}" title="{{'loading' | translate}}" /></div>
                        </div>
                        <div class="tab-pane fade" [ngClass]="activeDeviceTab === 'messages' ? 'show active': ''" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                            <div class="state-messages">
                                <div class="active-messages" *ngIf="activeStateMessages.length > 0">
                                    <div class="line-item" *ngFor="let activeStateMessage of activeStateMessages;">
                                        <div class="line-info">
                                            <div class="d-flex justify-content-between">
                                                <div class="line-title" data-toggle="collapse" [attr.data-target]="'#'+formatDeviceStateCode(activeStateMessage.state_type_code)" [attr.aria-controls]="'#'+formatDeviceStateCode(activeStateMessage.state_type_code)" aria-expanded="false">{{activeStateMessage.state_type_code}}</div>
                                                <div class="line-icons d-flex justify-content-end">
                                                    <div class="action-btn action-toggle"><i class="material-icons" title="{{'devicestate.hideMessage' | translate}}" (click)="suppressAlarm(activeStateMessage)">visibility_off</i></div>
                                                    <div class="action-btn action-status"><i class="material-icons state-warning" title="{{'devicestate.stateActive' | translate}}">circle</i></div>
                                                    <div class="action-btn action-dropdown" data-toggle="collapse" [attr.data-target]="'#'+formatDeviceStateCode(activeStateMessage.state_type_code)" [attr.aria-controls]="'#'+formatDeviceStateCode(activeStateMessage.state_type_code)" aria-expanded="false"><i class="material-icons">keyboard_arrow_down</i></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="line-description collapse" [attr.id]="formatDeviceStateCode(activeStateMessage.state_type_code)">
                                            {{activeStateMessage.alarm_description}}
                                            <div class="line-extra-data">
                                                <table>
                                                    <tr *ngFor="let infoField of getSpecificData(activeStateMessage)">
                                                        <td class="label">{{infoField[0]}}:</td>
                                                        <td class="value">
                                                            <span *ngIf="infoField[0] === 'timestamp' || infoField[0] === ('devicestate.timestamp' | translate)">{{infoField[1] | momentDate:'l LT'}}</span>
                                                            <span *ngIf="infoField[0] !== 'timestamp' &&  infoField[0] !== ('devicestate.timestamp' | translate)">{{infoField[1]}}</span>
                                                        </td>
                                                    </tr>
                                                    <tr *ngIf="activeStateMessage.date_reported">
                                                        <td colspan="2" class="value text-right font-italic">
                                                            <span>{{activeStateMessage.date_reported | momentDate:'l LT'}}</span>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="inactive-messages" *ngIf="showAllMessages && inactiveStateMessages.length > 0">
                                    <hr *ngIf="activeStateMessages.length > 0" />
                                    <ng-container *ngFor="let inactiveStateMessage of inactiveStateMessages;">
                                        <div class="line-item" [ngClass]="{'line-suppressed':inactiveStateMessage.suppressed}" *ngIf="(!inactiveStateMessage.suppressed && inactiveStateMessage.specific_data.length !== 0) || inactiveStateMessage.suppressed">
                                            <div class="line-info">
                                                <div class="d-flex justify-content-between">
                                                    <div class="line-title" data-toggle="collapse" [attr.data-target]="'#'+formatDeviceStateCode(inactiveStateMessage.state_type_code)" [attr.aria-controls]="'#'+formatDeviceStateCode(inactiveStateMessage.state_type_code)" aria-expanded="false">{{inactiveStateMessage.state_type_code}}</div>
                                                    <div class="line-icons d-flex justify-content-end">
                                                        <div class="action-btn action-toggle"><i class="material-icons" *ngIf="inactiveStateMessage.suppressed" (click)="suppressAlarm(inactiveStateMessage)" title="{{'devicestate.showMessage' | translate}}">visibility</i></div>
                                                        <div class="action-btn action-status"><i class="material-icons" [ngClass]="inactiveStateMessage.suppressed ? 'state-suppressed' : 'state-success'" title="{{inactiveStateMessage.suppressed ? ('devicestate.stateHidden' | translate) : ('devicestate.stateInactive' | translate)}}">circle</i></div>
                                                        <div class="action-btn action-dropdown" data-toggle="collapse" [attr.data-target]="'#'+formatDeviceStateCode(inactiveStateMessage.state_type_code)" [attr.aria-controls]="'#'+formatDeviceStateCode(inactiveStateMessage.state_type_code)" aria-expanded="false"><i class="material-icons">keyboard_arrow_down</i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="line-description collapse" [attr.id]="formatDeviceStateCode(inactiveStateMessage.state_type_code)">
                                                {{inactiveStateMessage.alarm_description}}
                                                <div class="line-extra-data">
                                                    <table>
                                                        <tr *ngFor="let infoField of getSpecificData(inactiveStateMessage)">
                                                            <td class="label">{{infoField[0]}}:</td>
                                                            <td class="value">
                                                                <span *ngIf="infoField[0] === 'timestamp' || infoField[0] === ('devicestate.timestamp' | translate)">{{infoField[1] | momentDate:'l LT'}}</span>
                                                                <span *ngIf="infoField[0] !== 'timestamp' &&  infoField[0] !== ('devicestate.timestamp' | translate)">{{infoField[1]}}</span>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="inactiveStateMessage.date_reported">
                                                            <td colspan="2" class="value text-right font-italic">
                                                                <span>{{inactiveStateMessage.date_reported | momentDate:'l LT'}}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="activeStateMessages.length === 0 && inactiveStateMessages.length === 0">
                                    {{'devicestate.noActiveMessages' | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" [ngClass]="activeDeviceTab === 'history' ? 'show active': ''" id="history" role="tabpanel" aria-labelledby="history-tab">
                            <div class="history-state-content-container">
                                <div class="history-state-content-inner" *ngIf="deviceStateHistory && hasStateHistoryData() > 0">
                                    <div>
                                        <section class="year" *ngFor="let stateHistoryYear of getStateHistoryYears()">
                                            <h3>{{stateHistoryYear}}</h3>
                                            <ng-container *ngFor="let stateHistoryMonth of getStateHistoryMonths(stateHistoryYear)">
                                                <section *ngFor="let stateHistoryDay of getStateHistoryDays(stateHistoryYear, stateHistoryMonth)">
                                                    <h4>{{(stateHistoryDay)}} {{getMonthName(stateHistoryMonth)}}</h4>
                                                    <ul>
                                                        <li class="" *ngFor="let stateHistoryData of getStateHistoryData(stateHistoryDay,stateHistoryMonth,stateHistoryYear)"
                                                            mat-raised-button [matTooltip]="generateHtmlForHistoryTooltip(stateHistoryData)">
                                                            {{stateHistoryData.state_type_code}} <span class="date-info">{{stateHistoryData.date_reported | momentDate: 'LT'}} - {{calculateActivityTime(stateHistoryData.date_reported, stateHistoryData.date_resolve)}}</span>
                                                        </li>
                                                    </ul>
                                                </section>
                                            </ng-container>
                                        </section>
                                    </div>
                                </div>
                                <div *ngIf="!deviceStateHistory || hasStateHistoryData() === 0" [ngClass]="!deviceStateHistory ? 'px14' : ''">
                                    {{'devicestate.noHistory' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!deviceStateOverview">
        <div class="form-attachment-loader">
            <div class="progress mb-0">
                <div class="indeterminate"></div>
            </div>
        </div>
    </ng-container>
</div>
