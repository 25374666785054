import {
    BaseFormConfig,
    ButtonBarConfig,
    ConfigType,
    FormRowConfig
} from '../../../shared/components/form/components/field/fieldDirective.interface';
import {ActivityConfig} from '../../../shared/components/check-activities/check-activities.interface';
import {AttachmentConfig} from '../../../shared/components/form/components/form-attachment/form-attachment.interface';
import {CommentConfig} from '../../../shared/components/form/components/form-comment/form-comment.interface';
import {LogConfig} from '../../../shared/components/form/components/form-log/form-log.interface';
import {FormGroupWithStatusConfig} from '../../../shared/components/form/components/form-group/form-group-with-status/form-group-with-status.interface';

export interface ProjectsManageFormConfig extends BaseFormConfig {
    base_object_id: number;
    children: (
        ButtonBarConfig|
        ProjectsManageFormWorkOrderRowConfig|
        ProjectsManageFormActivityRowConfig|
        ProjectsManageFormAttachmentRowConfig|
        ProjectsManageFormCommentRowConfig|
        ProjectsManageFormLogRowConfig
    )[];
    clientReadOnly: boolean;
    name: 'projects_manage_order'
    referenceId: string;
    type: ConfigType.object
}

export interface ProjectsManageFormWorkOrderRowConfig extends FormGroupWithStatusConfig {
}

export interface ProjectsManageFormActivityRowConfig extends FormRowConfig {
    name: ProjectsManageFormRowName.Werkbon
    children: ActivityConfig[];
}

export interface ProjectsManageFormAttachmentRowConfig extends FormRowConfig {
    children: AttachmentConfig[];
}

export interface ProjectsManageFormCommentRowConfig extends FormRowConfig {
    children: CommentConfig[];
}

export interface ProjectsManageFormLogRowConfig extends FormRowConfig {
    children: LogConfig[];
}

export enum ProjectsManageButtonAction {
    'delete-bon' = 'delete-bon',
    'create-check-activity' = 'create-check-activity',
}

export enum ProjectsManageFormRowName {
    'Werkbon' = 'Werkbon',
    'Werkzaamheden' = 'Werkzaamheden',
    'Bijlagen' = 'Bijlagen',
    'Opmerkingen' = 'Opmerkingen',
    'Logs'  = 'Logs'
}
