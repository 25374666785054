import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, Renderer2,
} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {AbstractFormFieldComponent} from "../abstract/abstract-form-field.component";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {FormDataService} from '../../services/form-data.service';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import * as moment from 'moment/moment';
import {GlobalEvent} from '../../../../interfaces/global-event';
import {GlobalModel} from '../../../../services/state/global.model';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-time-tracking',
    template: `
        <div class="d-block text-center time-tracking">
            <div *ngFor="let button of buttons" class="btn-group col-4 p-0">
                <button type="button"
                        class="btn w-100 px-0 py-3"
                        [disabled]="isControlDisabled() || button.state == STATE_DISABLED || button.state == 'active'"
                        (click)="handleButtonClick(button, $event)">
                    {{button.state == STATE_ACTIVE?button.datetime:button.label}}
                </button>
            </div>
        </div>
    `
})

export class FormTimeTrackingComponent extends AbstractFormFieldComponent implements AfterViewInit {

    private static readonly STATE_NORMAL:string = "normal";
    private static readonly STATE_DISABLED:string = "disabled";
    private static readonly STATE_ACTIVE:string = "active";

    config: any;
    group: UntypedFormGroup;
    readOnly: boolean;

    public buttons:any = null;

    constructor(private globalAlertService: GlobalAlertService, public cd:ChangeDetectorRef, public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, private formDataService:FormDataService, private model:GlobalModel, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }

    ngOnInit()
    {
        if (this.config && this.config.attr && this.config.attr.buttons){
            this.buttons = this.config.attr.buttons;
            for (let i = 0; i < this.config.attr.buttons.length; i++){
                if (this.config.attr.buttons[i].datetime){
                    this.config.attr.buttons[i].datetime = moment(this.config.attr.buttons[i].datetime).format('LTS');
                }
            }
        }
    }
    
    public handleButtonClick(button:any, event:any):void
    {
        if (button.state == FormTimeTrackingComponent.STATE_NORMAL) {
            button.state = FormTimeTrackingComponent.STATE_DISABLED;

            let now = new Date();
            let url = button.url + '/' + now.toISOString();
            this.formDataService.submitTimeTracking(url, (json: any) => {
                this.buttons = json.schema.attr.buttons;
                for (let i = 0; i < this.buttons.length; i++) {
                    this.buttons[i].datetime = moment(this.buttons[i].datetime).format('LTS');
                }
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_TIME_REGISTRATION_UPDATE, json));
                this.cd.detectChanges();
            }, () => {
            });
        }
    }

    get STATE_ACTIVE():string{
        return FormTimeTrackingComponent.STATE_ACTIVE;
    }

    get STATE_DISABLED():string{
        return FormTimeTrackingComponent.STATE_DISABLED;
    }
}
