import {Injectable} from '@angular/core';
import {RequestFailure} from '../../../services/http/request-failure';
import {HTTPService} from '../../../services/http/http.service';
import {GlobalAlertService} from '../../../../wrapper/global-alert/global-alert.service';
import {HTTPError} from '../../../services/http/http-error';
import {BehaviorSubject, Observable} from 'rxjs';
import {GlobalModel} from '../../../services/state/global.model';
import {ProjectActivitiesConfigActivity} from '../../check-activities/check-activities.interface';
import {HttpService2} from '../../../services/http-service-2.0/http2.0.service';
import {BasicHttpGetResult, FormPostResult} from '../../../services/http-service-2.0/http.interface';
import {LoggerService} from "../../../services/logger/logger.service";

@Injectable({
    providedIn: 'root'
})
export class FormDataService {
    //TODO: Uitfaseren
    public static readonly ORIGIN_RELOAD = 'reload';
    public static readonly ORIGIN_SEARCH = 'search';
    public static readonly ORIGIN_WIDGET = 'widget';
    public static readonly ORIGIN_HISTORY = 'history';
    public static readonly ORIGIN_NEW_SCHAKELKAST = 'new-schakelkast';
    public static readonly ORIGIN_TREE = 'tree';
    public static readonly ORIGIN_TREE_NODE_ACTION = 'tree-node-action';
    public static readonly ORIGIN_ROUTER_REFRESH = 'router-refresh';

    protected updateUrl = '/update';
    protected updateDimGroupUrl = '/dimgroup/update';
    protected updateProjectUrl = '/project/update';
    protected updateSystemGroupUrl = '/systemgroup/update';
    protected updateOverrideUrl = '/override/update';

    protected getUrl = '/get';
    protected getProjectUrl = '/project/get';
    protected getDimGroupUrl = '/dimgroup/get';
    protected getSystemGroupUrl = '/systemgroup/get';
    protected getOverrideUrl = '/override/get';
    protected getBatchUpdateUrl = '/batch-update/get/mast';

    public static readonly FORM_URL_ASSETS: string = 'asset-management-form';
    public static readonly FORM_URL_USER_MANAGEMENT: string = 'user-management-form';
    public static readonly FORM_URL_DEVICES: string = 'device-management-form';
    public static readonly FORM_URL_CONTROL: string = 'control-form';
    public static readonly FORM_URL_REPORT_MALFUNCTION: string = 'report-malfunction';
    public static readonly FORM_URL_DEVICE_MALFUNCTION: string = 'device-malfunction';
    public static readonly FORM_URL_PROJECTS_CREATE: string = 'projects-create';
    public static readonly FORM_URL_PROJECTS_MANAGE: string = 'projects-manage';
    public static readonly FORM_URL_PUBLIC_PORTAL: string = 'public-portal';
    public static readonly FORM_URL_WORK_ORDERS: string = 'work-orders';
    public static readonly FORM_URL_MECHANIC: string = 'mechanic';
    public static readonly FORM_URL_STEDIN_ORDERS: string = 'stedin-orders';
    public static readonly FORM_URL_REPORT_GENERIC: string = 'report-generic';
    public static readonly FORM_URL_MASTER_DATA: string = 'master-data';


    constructor(
        protected httpService: HTTPService,
        protected httpService2: HttpService2,
        protected globalAlertService: GlobalAlertService,
        protected model: GlobalModel,
        protected logger:LoggerService
    ) {
    }

    //If the id is not a number then it is a cluster.
    private isCluster(id:any):boolean{
        return isNaN(id);
    }

    //TODO: als er meer graphs komen moet dit misschien anders
    public getGraphData(url: string, successCallBack?: (json: any) => any, failCallBack: (failure: RequestFailure) => any = null) {
        this.httpService.doGetRequest(url,
            (json: any, url: string) => {
                if (json) {
                    successCallBack(json);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                }
            }, (failure: RequestFailure) => {
                if (failCallBack) {
                    failCallBack(failure);
                }
            }, () => {
            }, false, true);
    }

    public submitTimeTracking(url: string, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any) {
        this.httpService.doGetRequest(url,
            (json: any, url: string) => {
                if (json) {
                    successCallBack(json);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                }
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, () => {
            }, true);
    }

    public getTinyGraphData(url: string, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any) {
        this.httpService.doGetRequest(url,
            (json: any, url: string) => {
                if (json) {
                    successCallBack(json);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                }
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, () => {
            }, true);
    }

    //TODO: deze lijkt te veel op de bovenstaande
    public getTableData(url: string, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any) {
        this.httpService.doGetRequest(url,
            (json: any, url: string) => {
                if (json) {
                    successCallBack(json);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                }
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, () => {
            }, false, true);
    }

    // Get formdata for views with no model (i.e. don't store in model from this services)
    public getFormData(
        url: string,
        successCallBack?: (formData: any) => any,
        failCallBack?: (failure: RequestFailure) => any,
        errorCallBack?: (error: HTTPError) => any,
        usePathAsFullUrl = false,
        sendArealId:boolean = true
    ): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            sendArealId,
            usePathAsFullUrl
        );
    }

    // Get formdata for views with no model (i.e. don't store in model from this services)
    public getFormData2(
        url: string
    ): Observable<BasicHttpGetResult> {
        return this.httpService2.doGetRequest(url);
    }

    public setFormData(form: any, formName: string, url: string, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any, sendArealId:boolean = true) {
        let fullForm = {[formName]: form};

        this.httpService.doFormPostRequest(url, fullForm,
            (json: any) => {
                //Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            sendArealId,
        );
    }

    public setFormData2(form: any, formName: string, url: string): Observable<FormPostResult> {
        let fullForm = {[formName]: form} as JSON;

        return this.httpService2.doFormPostRequest(url, fullForm, true);
    }

    //For creating new stamdata entries
    public createNewDropDown(path: string, value: string, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        //Build the post values
        let postValues: any = {name: value};

        this.httpService.doPostRequest(path, postValues,
            (json: any) => {
                //Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true);
    }

    //For deleting old stamdata entries
    public deleteDropDownItem(path: string, value: number|string, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        //Build the post values
        let postValues: any = {name: value};

        this.httpService.doPostRequest(path, postValues,
            (json: any) => {
                //Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true);
    }

    //For editing new stamdata entries
    public editDropDown(path: string, value: number, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        //Build the post values
        let postValues: any = {name: value};

        this.httpService.doPostRequest(path, postValues,
            (json: any) => {
                //Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true);
    }

    //For merge new stamdata entries
    public mergeDropDown(path: string, valueToReplace: string | number, valueToKeep: number, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        //Build the post values
        let postValues: any = {valueToReplace: valueToReplace, valueToKeep: valueToKeep};

        this.httpService.doPostRequest(path, postValues,
            (json: any) => {
                //Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true);
    }

    public getFormDataForId(formURL: string, formData: BehaviorSubject<any>, objectId: number, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any, previousObjectId: number = -1, batchUpdateUrl = ''): void {
        //When a cluster, do a batch call even though it is a single item

        if(this.isCluster(objectId) && formURL != 'master-data'){
            this.getBatchUpdateFormData(formURL, formData, batchUpdateUrl, successCallBack, failCallBack, errorCallBack);
        } else {
            let url: string = formURL + this.getUrl + '/' + objectId;
            // -1: don't send ID in url
            //  0: send 0 as id
            // >0: send full id
            url += previousObjectId >= 0 ? '/' + previousObjectId : '';

            this.httpService.doGetRequest(
                url,
                (json: any) => {

                    // When the form name is different from the last loaded form name, clear the form data.
                    // This will result in the total rebuilding of the form.
                    // Needed for the form component to handle different form types
                    // Rebuilding also causes the scroll position to revert to 0, so use it only when necessary
                    // if (json && json.schema) {
                    /*if (this.lastFormName == "" || this.lastFormName != json.schema.name) {
                     this.lastFormName = json.schema.name;*/
                    //this.model.assetsFormData.next(null);
                    /*}*/
                    //}

                    //Set the data the next frame so the form view can be cleared in the frame before this one
                    //setTimeout(() => {


                    json.schema.referenceId = objectId;

                    formData.next(json);
                    successCallBack();
                    //})
                }, (failure: RequestFailure) => {
                    failCallBack(failure);
                }, (error: HTTPError) => {
                    errorCallBack(error);
                },
                true,
            );
        }

    }
    public getFormDataForIdString(formURL: string, formData: BehaviorSubject<any>,
                                  objectId: string, successCallBack?: () => any,
                                  failCallBack?: (failure: RequestFailure) => any,
                                  errorCallBack?: (error: HTTPError) => any,
                                  previousObjectId: number = -1): void {
        let url: string = formURL + this.getUrl + '/' + objectId;
        url += previousObjectId >= 0 ? '/' + previousObjectId : '';
        this.httpService.doGetRequest(
            url,
            (json: any) => {
                json.schema.referenceId = objectId;
                formData.next(json);
                successCallBack();
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }
    public setFormDataWithUrlForId(formURL: string, formData: BehaviorSubject<any>, form: any, formName: string,
                                   objectId: number|string, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any,
                                   errorCallBack?: (error: HTTPError) => any) {
        let fullForm = {[formName]: form};

        this.httpService.doFormPostRequest(formURL, fullForm,
            (json: any) => {

                json.schema.referenceId = objectId;

                formData.next(json);
                successCallBack();
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public setFormDataForId(formURL: string, formData: BehaviorSubject<any>, form: any, formName: string, objectId: number|string, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let fullForm = {[formName]: form};

        this.httpService.doFormPostRequest(formURL + this.updateUrl + '/' + objectId, fullForm,
            (json: any) => {

                json.schema.referenceId = objectId;

                formData.next(json);
                //Let component know request is sent
                successCallBack();
            }, (failure: RequestFailure) => {
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public batchDelete(url: string, items: any[], archive: boolean = true, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let itemIdString: string = '';

        for (let i: number = 0; i < items.length; i++) {
            itemIdString += items[i].baseObjectId + (i < items.length - 1 ? ',' : '');
        }

        let fullForm = {baseObjectIds: itemIdString, action: (archive ? 'archive' : 'delete')};

        this.httpService.doFormPostRequest(url, fullForm,
            () => {
                successCallBack();
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public setBatchUpdateFormDataPopUp(formData: BehaviorSubject<any>, url: string, form: any, formName: string, items: any[],
                                       successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any,
                                       errorCallBack?: (error: HTTPError) => any) {
        let itemIdString: string = '';

        for (let i: number = 0; i < items.length; i++) {
            itemIdString += items[i].baseObjectId + (i < items.length - 1 ? ',' : '');
        }

        let fullForm = {[formName]: form, baseObjectIds: itemIdString};

        this.httpService.doFormPostRequest(url, fullForm,
            (json: any) => {
                successCallBack();

            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public setBatchUpdateFormData(formData: BehaviorSubject<any>, url: string, form: any, formName: string, items: any[], successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let itemIdString: string = '';
        let isCluster: boolean = false;

        for (let i: number = 0; i < items.length; i++) {
            itemIdString += items[i].baseObjectId + (i < items.length - 1 ? ',' : '');
        }

        if(items.length > 0){
            isCluster = (this.isCluster(items[0].baseObjectId) && url != 'master-data');
        }

        this.httpService.doFormPostRequest(url, isCluster ? {[formName]: form, clusterIds: itemIdString} : {[formName]: form, baseObjectIds: itemIdString},
            (json: any) => {

                //if (json && json.schema) {
                //this.model.assetsFormData.next(null);
                //}

                //setTimeout(() => {
                formData.next(json);
                //Let component know request is sent
                successCallBack();
                //});

            }, (failure: RequestFailure) => {
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public getBatchUpdateFormData(formURL: string, formData: BehaviorSubject<any>, url: string, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let batchUpdateUrl: string = (url != '' ? url : formURL + this.getBatchUpdateUrl);

        this.httpService.doGetRequest(batchUpdateUrl,
            (json: any) => {

                // When the form name is different from the last loaded form name, clear the form data.
                // This will result in the total rebuilding of the form.
                // Needed for the form component to handle different form types
                // Rebuilding also causes the scroll position to revert to 0, so use it only when necessary

                //if (json && json.schema) {
                /*if (this.lastFormName == "" || this.lastFormName != json.schema.name) {
                 this.lastFormName = json.schema.name;*/
                //this.model.assetsFormData.next(null);
                /*}*/
                // }

                //Set the data the next frame so the form view can be cleared in the frame before this one
                //setTimeout(() => {
                formData.next(json);
                successCallBack();
                //})


            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                this.logger.log('[FormDataService] ERROR');
                errorCallBack(error);
            },
            true,
        );
    }

    public setSystemGroupFormDataForId(formURL: string, formData: BehaviorSubject<any>, form: any, formName: string, objectId: number, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let fullForm = {[formName]: form};

        this.httpService.doFormPostRequest(formURL + this.updateSystemGroupUrl + '/' + objectId, fullForm,
            (json: any) => {

                json.schema.referenceId = objectId;

                formData.next(json);
                //Let component know request is sent
                successCallBack();
            }, (failure: RequestFailure) => {
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }
    public setOverrideFormDataForId(formURL: string, formData: BehaviorSubject<any>, form: any, formName: string, objectId: number, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let fullForm = {[formName]: form};

        this.httpService.doFormPostRequest(formURL + this.updateOverrideUrl + '/' + objectId, fullForm,
            (json: any) => {

                json.schema.referenceId = objectId;

                formData.next(json);
                //Let component know request is sent
                successCallBack();
            }, (failure: RequestFailure) => {
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public setDimGroupFormDataForId(formURL: string, formData: BehaviorSubject<any>, form: any, formName: string, objectId: number, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let fullForm = {[formName]: form};

        this.httpService.doFormPostRequest(formURL + this.updateDimGroupUrl + '/' + objectId, fullForm,
            (json: any) => {

                json.schema.referenceId = objectId;

                formData.next(json);
                //Let component know request is sent
                successCallBack();
            }, (failure: RequestFailure) => {
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public setProjectFormDataForId(formURL: string, formData: BehaviorSubject<any>, form: any, formName: string, objectId: number, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let fullForm = {[formName]: form};

        this.httpService.doFormPostRequest(formURL + this.updateProjectUrl + '/' + objectId, fullForm,
            (json: any) => {

                json.schema.referenceId = objectId;

                formData.next(json);
                //Let component know request is sent
                successCallBack();
            }, (failure: RequestFailure) => {
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public getOverrideFormDataForId(formURL: string, formData: BehaviorSubject<any>, objectId: number, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(
            formURL + this.getOverrideUrl + '/' + objectId,
            (json: any) => {

                // When the form name is different from the last loaded form name, clear the form data.
                // This will result in the total rebuilding of the form.
                // Needed for the form component to handle different form types
                // Rebuilding also causes the scroll position to revert to 0, so use it only when necessary
                // if (json && json.schema) {
                /*if (this.lastFormName == "" || this.lastFormName != json.schema.name) {
                 this.lastFormName = json.schema.name;*/
                //this.model.assetsFormData.next(null);
                /*}*/
                //}

                //Set the data the next frame so the form view can be cleared in the frame before this one
                //setTimeout(() => {

                json.schema.referenceId = objectId;

                formData.next(json);
                successCallBack();
                //})
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                this.logger.log('[FormDataService] ERROR');
                errorCallBack(error);
            },
            true,
        );
    }

    public getSystemGroupFormDataForId(formURL: string, formData: BehaviorSubject<any>, objectId: number, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(
            formURL + this.getSystemGroupUrl + '/' + objectId,
            (json: any) => {

                // When the form name is different from the last loaded form name, clear the form data.
                // This will result in the total rebuilding of the form.
                // Needed for the form component to handle different form types
                // Rebuilding also causes the scroll position to revert to 0, so use it only when necessary
                // if (json && json.schema) {
                /*if (this.lastFormName == "" || this.lastFormName != json.schema.name) {
                 this.lastFormName = json.schema.name;*/
                //this.model.assetsFormData.next(null);
                /*}*/
                //}

                //Set the data the next frame so the form view can be cleared in the frame before this one
                //setTimeout(() => {

                json.schema.referenceId = objectId;

                formData.next(json);
                successCallBack();
                //})
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                this.logger.log('[FormDataService] ERROR');
                errorCallBack(error);
            },
            true,
        );
    }

    public getDimGroupFormDataForId(formURL: string, formData: BehaviorSubject<any>, objectId: number, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(
            formURL + this.getDimGroupUrl + '/' + objectId,
            (json: any) => {

                // When the form name is different from the last loaded form name, clear the form data.
                // This will result in the total rebuilding of the form.
                // Needed for the form component to handle different form types
                // Rebuilding also causes the scroll position to revert to 0, so use it only when necessary
                // if (json && json.schema) {
                /*if (this.lastFormName == "" || this.lastFormName != json.schema.name) {
                 this.lastFormName = json.schema.name;*/
                //this.model.assetsFormData.next(null);
                /*}*/
                //}

                //Set the data the next frame so the form view can be cleared in the frame before this one
                //setTimeout(() => {

                json.schema.referenceId = objectId;

                formData.next(json);
                successCallBack();
                //})
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                this.logger.log('[FormDataService] ERROR');
                errorCallBack(error);
            },
            true,
        );
    }

    public getProjectFormDataForId(formURL: string, formData: BehaviorSubject<any>, objectId: number, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(
            formURL + this.getProjectUrl + '/' + objectId,
            (json: any) => {

                // When the form name is different from the last loaded form name, clear the form data.
                // This will result in the total rebuilding of the form.
                // Needed for the form component to handle different form types
                // Rebuilding also causes the scroll position to revert to 0, so use it only when necessary
                // if (json && json.schema) {
                /*if (this.lastFormName == "" || this.lastFormName != json.schema.name) {
                 this.lastFormName = json.schema.name;*/
                //this.model.assetsFormData.next(null);
                /*}*/
                //}

                //Set the data the next frame so the form view can be cleared in the frame before this one
                //setTimeout(() => {

                json.schema.referenceId = objectId;

                formData.next(json);
                successCallBack();
                //})
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                this.logger.log('[FormDataService] ERROR');
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteDimGroup(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteProject(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteSystemgroup(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }
    public deleteOverride(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteWorkPreparation(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteWorkActivity(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteCheckActivity(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public saveCheckActivity(url: string, data: ProjectActivitiesConfigActivity): Observable<FormPostResult> {
        return this.httpService2.doFormPostRequest(url, JSON.parse(JSON.stringify(data)), true);
    }

    public deleteWorkSpecification(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }
    public deleteWeekprijs(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public updateWorkPreparation(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteComment(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public addComment(url: string, message: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        //Build the post values
        let postValues: any = {message: message};

        this.httpService.doPostRequest(url, postValues,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                if (errorCallBack) {
                    errorCallBack(error);
                }
            },
            true,
        );
    }

    public deleteWorkOrder(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteUser(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteAttachment(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public getAttachment(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public addAttachment(url: string, fileName: string, exifData: any, fileData: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any, extraPostData:object = {}): void {
        //Build the post values
        let postValues: any = {fileName: fileName, exifData: exifData, fileData: fileData};

        Object.assign(postValues, extraPostData);

        this.httpService.doPostRequest(url, postValues,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                if (errorCallBack) {
                    errorCallBack(error);
                }
            },
            true,
        );
    }

    public deleteTimeRegistration(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public deleteMapServer(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true,
        );
    }

    public disableMapServer(url: string): Observable<BasicHttpGetResult | boolean> {
        return this.httpService2.doGetRequest(url)
    }

    public saveMapServerLayers(url: string, layers: any): Observable<FormPostResult> {
        //Build the post values
        let postValues: any = {layerIds: layers};

        return this.httpService2.doFormPostRequest(url, postValues, true);
    }

    public getSelectedMapServerLayers(url: string): Observable<BasicHttpGetResult> {
        return this.httpService2.doGetRequest(url)
    }
}
