import {ChangeDetectorRef, Component, ElementRef, NgZone, OnChanges, SimpleChanges} from '@angular/core';
import {RequestFailure} from '../../shared/services/http/request-failure';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {FormComponent} from '../../shared/components/form/containers/form/form.component';
import {GlobalModel} from '../../shared/services/state/global.model';
import {FormDataService} from '../../shared/components/form/services/form-data.service';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {AbstractObjectFormComponent} from '../../shared/components/form/containers/form/abstract-object-form.component';
import {GlobalEvent} from '../../shared/interfaces/global-event';
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {ButtonCode} from '../../wrapper/global-alert/global-popup';
import {HTTPService} from '../../shared/services/http/http.service';
import {HTTPError} from '../../shared/services/http/http-error';
import {LoggerService} from "../../shared/services/logger/logger.service";


@Component({
    selector: 'assets-form-component',
    template: `
        <ng-container *ngIf="formData && config">
            <form-component
                    #baseFormGroup
                    [config]="config"
                    [name]="getFormName()"
                    [validationConstraints]="validationConstraints"
                    [invalidControlsErrors]="invalidControlsErrors"
                    [readOnly]="readOnly"
                    [formIsSubmitted]="formIsSubmitted"
                    (onComponentEvent)="handleComponentEvent($event)">
            </form-component>
            <!--<validation-warning [showWarning]="hasFailedConstraints()"></validation-warning>-->
        </ng-container>
    `,
})

export class AssetsFormComponent extends AbstractObjectFormComponent implements OnChanges {

    public constructor(protected cd: ChangeDetectorRef,
                       protected globalAlertService: GlobalAlertService,
                       protected formDataService: FormDataService,
                       protected zone: NgZone,
                       protected elementRef: ElementRef,
                       public model: GlobalModel,
                       protected ts: TranslateService,
                       protected httpService: HTTPService,
                       protected logger: LoggerService) {
        super(cd, globalAlertService, elementRef, ts, model, logger);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const resolvePath = (obj: any, path: string[], def: any) => {
            if (path.length) {
                const key = path[0]
                return (obj[key])
                    ? resolvePath(obj[key], path.slice(1), def)
                    : def;
            }
            else {
                return obj;
            }
        }
    }

    handleComponentEvent(eventData: any): void {

        switch (eventData.event) {
            case FormEvent.GENERATE_MAPS_WITH_GROUPS:
                // this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                //     window.open(eventData.data.url, '_blank');
                //     this.onComponentEvent.emit({event: FormEvent.SAVE_SUCCESS, data: {baseObjectId: eventData.data.referenceId}});
                // });
                this.httpService.doGetRequest(eventData.data.url,
                    (json: any) => {
                        // this.onComponentEvent.emit({event: FormEvent.SAVE_SUCCESS, data: {baseObjectId: eventData.data.referenceId}});
                        this.globalAlertService.addAlert('Taak is aangemaakt',
                            'De kaart met groepen wordt aangemaakt',
                            'Dit kan tot een minuut duren, u kunt deze terugvinden bij de bijlagen op dit formulier',
                            GlobalAlertService.ALERT_ICON_SUCCESS, GlobalAlertService.ALERT_TYPE_DEFAULT, 3000);
                    }, (failure: RequestFailure) => {
                }, (error: HTTPError) => {
                },
                false, true);
                break;
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                    this.onComponentEvent.emit({
                        event: FormEvent.SAVE_SUCCESS,
                        data: {baseObjectId: eventData.data.referenceId},
                    });
                });
                break;
            case FormEvent.DELETE_BASEOBJECT:
                //Click from delete button on baseobject-form (not the tree)
                this.logger.log('[AssetsFormComponent] ' + 'Delete baseobject via form');
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_BASEOBJECT, {}));
                break;
            case FormEvent.DELETE_UNDERGROUND_BASEOBJECT:
                //Click from delete button on underground baseobject-form (not the tree)
                this.logger.log('[AssetsFormComponent] ' + 'Delete undergroundbaseobject via form',eventData.data);
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_UNDERGROUND_BASEOBJECT, {baseObjectData: eventData.data}));
                break;
            case FormEvent.BATCH_DELETE:
                this.globalAlertService.addPopup(this.ts.translate('Items verwijderen'), this.ts.translate('Wilt u de geselecteerde items verwijderen of archiveren?'),
                    [
                        {
                            label: this.ts.translate('Annuleren'),
                            code: ButtonCode.ANNULEREN,
                            isPrimary: true,
                        },
                        {
                            label: this.ts.translate('Archiveren'), code: ButtonCode.ARCHIVE,
                            callback: () => {
                                const selectedValues = this.model.assetsSelectedItems.value
                                this.submitBatchDelete(this.model.assetsSelectedItems.value, eventData.data.url, true, () => {
                                    this.handleBatchDeleteSuccess(selectedValues);
                                });
                            },
                            isPrimary: false,
                        },
                        {
                            label: this.ts.translate('Verwijderen'), code: ButtonCode.DELETE,
                            callback: () => {
                                const selectedValues = this.model.assetsSelectedItems.value
                                this.submitBatchDelete(this.model.assetsSelectedItems.value, eventData.data.url, false, () => {
                                    this.handleBatchDeleteSuccess(selectedValues);
                                });
                            },
                            isPrimary: false,
                        },
                    ], () => {
                    });
                break;

            case FormEvent.BATCH_SAVE:
                this.submitBatchUpdate(this.model.assetsSelectedItems.value, eventData.data.formData, eventData.data.url, () => {
                    this.handleBatchUpdateSuccess();
                });
                break;
            case FormEvent.MOVE_MAPITEM:
                this.globalAlertService.addPopupMoveItem(eventData.data.mapItem,
                    () => {
                        //Object is successfully moved
                        this.onComponentEvent.emit({
                            event: FormEvent.MOVE_MAPITEM_SUCCESS,
                            data: {mapItemId: eventData.data.mapItem},
                        });
                    }, () => {
                        //Move is canceled, or failed. Do nothing
                    });
                break;
            case FormEvent.COPY_BASEOBJECT:
                this.globalAlertService.addPopupCopyBaseObject(eventData.data.mapItem,
                    (button: any, data: any) => {
                        //Object is successfully copied
                        this.onComponentEvent.emit({
                            event: FormEvent.COPY_BASEOBJECT_SUCCESS,
                            data: {baseObjectId: data.baseObjectId},
                        });
                    }, () => {
                        //Copy is canceled, or failed. Do nothing
                    });
                break;
        }

        this.onComponentEvent.emit(eventData);
    }

    private submitBatchUpdate(items: any[], form: any, url: string, successCallBack?: () => any): void {
        if (!items || items.length == 0) {
            this.logger.log('[AssetsFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setBatchUpdateFormData(this.model.assetsFormData, url, form, this.getFormName(), items,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            () => {
                this.handleSubmitResponse(null, true);
            },
        );
    }

    private submitBatchDelete(items: any[], url: string, archive: boolean = true, successCallBack?: () => any): void {
        if (!items || items.length == 0) {
            this.logger.log('[AssetsFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.batchDelete(url, items, archive,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            () => {
                this.handleSubmitResponse(null, true);
            },
        );
    }

    private submitFormData(form: any, itemId: number, successCallBack?: () => any): void {
        if (itemId == 0) {
            this.logger.log('[AssetsFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setFormDataForId(FormDataService.FORM_URL_ASSETS, this.model.assetsFormData, form, this.getFormName(), itemId,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            () => {
                this.handleSubmitResponse(null, true);
            },
        );
    }
}
