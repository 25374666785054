<div class="timeline-bar" *ngIf="bar"></div>
<div class="timeline">
    <div class="d-flex align-items-center">
        <div class="timeline-date">
            <span>{{config.date_reported | momentDate:'lll'}}</span>
            <span>{{config.date_resolve | momentDate:'lll'}}</span>
        </div>
        <div class="timeline-icon-container">
            <div class="timeline-icon"></div>
        </div>
        <div class="timeline-status">
         <span>{{config.state_type_code}}</span>
        </div>
    </div>
<div class="timeline-timediff">{{timeDifference | duration}}</div>
</div>
