import {AfterViewInit, Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output, Renderer2} from "@angular/core";
import {GlobalModel} from "../../../services/state/global.model";
import Utils from "../../../utils/utils";

@Component ({
    selector: 'slider-component',
    template: `        
        <div (globalResizeOutsideAngular)="handleResize($event)" class="d-flex position-relative full-height align-items-center cursor-pointer justify-content-center" [style.width]="'0.5rem'">
            <div class="d-flex justify-content-center text-color-darkgrey" style="z-index:2; width:20px; position:absolute; left:-6px;">|</div>
        </div>
    `
})

export class SliderComponent implements OnDestroy, AfterViewInit{

    @Output() onSlide: EventEmitter<any> = new EventEmitter();

    @Input() refId:string = ""; //Reference for temporary storage of slide values in the model
    @Input() minX:number; //= 0;
    @Input() maxX:number; //= 400;

    private delta = {x: 0, y: 0};
    private offset = {x: 0, y: 0};
    private startX:number = 0;
    private startY:number = 0;
    private isDragging = false;

    // Reference for unlistening
    private mouseDownEvent:any;
    private mouseMoveEvent:any;
    private mouseUpEvent:any;
    private touchStartEvent:any;
    private touchMoveEvent:any;
    private touchEndEvent:any;

    constructor(private elementRef: ElementRef, private zone: NgZone, private renderer:Renderer2, private model:GlobalModel) {
        this.setupEvents();
    }

    ngAfterViewInit():void{

        //When a ref is given, try to get the old value
        if (this.refId != ""){
            setTimeout( () => {
                let oldDelta:any = this.model.sliderPositions[this.refId];
                if (oldDelta != undefined){
                    this.delta = oldDelta;
                    this.delta.x = Utils.getWithinMinMax(this.delta.x, this.minX, this.maxX);
                    this.offset = this.delta;
                    this.emitSlide();
                }
            }, 100);

            //TODO: de 100 komt doordat het parent component ook wacht tot afterviewinit, dan de min-max uitrekend, die doorgeeft naar dit component. Als je een min/max moet hebben moet je dus even wachten
            //TODO: ooit mooier maken
        }
    }

    private emitSlide():void
    {
        // Check if the end of the slider is reached
        let isMaxLeft:boolean = (this.delta.x == this.minX);
        let isMaxRight:boolean = (this.delta.x == this.maxX);

        //TODO: Je wilt weten of het de rechter of linker slider is. Nu wordt dat uit de naam afgeleid. Hier moet ooit iets mooiers voor komen. Nadeel van een attribuut is dat je hem overal weer zou moeten toevoegen, het is mooier als het automatisch goed gaat

        // If the end is reached, add a margin to the movement and stop the drag
        if ((isMaxLeft && this.refId.indexOf("_1") != -1) || (isMaxRight && this.refId.indexOf("_2") != -1)){
            // The margin will prevent the user from directly reaching the max of the slider again (and thus closing a for for example)
            this.delta.x += (this.delta.x < 0)?50:-50;
            this.endDragging()
        }

        this.onSlide.emit({x: this.delta.x, isMaxLeft: isMaxLeft, isMaxRight: isMaxRight});
    }

    private setupEvents():void{
        this.zone.runOutsideAngular(() => {

            // Touch events need a specific implementation here
            this.touchStartEvent = this.renderer.listen(this.elementRef.nativeElement, 'touchstart', (e:any) =>
            {
                this.clickStart(e, true)
            });
            this.mouseDownEvent = this.renderer.listen(this.elementRef.nativeElement, 'mousedown', (e:any) =>
            {
                this.clickStart(e, false);
            });

            this.touchMoveEvent = this.renderer.listen('document', 'touchmove', (e:any) =>
            {
                this.clickMove(e, true);
            });
            this.mouseMoveEvent = this.renderer.listen('document', 'mousemove', (e:any) =>
            {
                this.clickMove(e, false);
            });

            this.touchEndEvent = this.renderer.listen('document', 'touchend', (e:any) =>
            {
                this.clickEnd(e);
            });
            this.mouseUpEvent = this.renderer.listen('document', 'mouseup', (e:any) =>
            {
                this.clickEnd(e);
            });
        });
    }

    private clickStart(e:any, mobile:boolean):void
    {
        if (mobile){
            // Mobile support, fixes a Chrome warning
            if (e.cancelable) {
                e.preventDefault();
            }

            // Get coordinate from first touch
            this.startX = e.touches[0].clientX;
            this.startY = e.touches[0].clientX;
        }else{
            e.preventDefault();

            this.startX = e.clientX;
            this.startY = e.clientY;
        }

        this.isDragging = true;
    }

    private clickMove(e:any, mobile:boolean):void
    {
        if (this.isDragging) {
            // Use mouse or touch coordinates
            let clientX: number = mobile ? e.touches[0].clientX : e.clientX;
            let clientY: number = mobile ? e.touches[0].clientY : e.clientY;

            this.delta = {x: this.offset.x + (clientX - this.startX), y: this.offset.y + (clientY - this.startY)};
            this.delta.x = Utils.getWithinMinMax(this.delta.x, this.minX, this.maxX);

            //Emit new slider position
            this.emitSlide();
        }
    }

    private clickEnd(e:any):void
    {
        this.endDragging();
    }

    private endDragging():void
    {
        if (this.isDragging) {

            //Done dragging slider, update the position
            this.offset.x = this.delta.x ;
            this.offset.y = this.delta.y;
            //this.delta = {x: 0, y: 0};

            //Store current slider position in the model
            if (this.refId != "") {
                this.model.sliderPositions[this.refId] = this.offset;
            }

            this.isDragging = false;
            this.model.onFormResize.next('');
        }
    }

    public handleResize(event:any):void
    {
        this.resetSlider();
    }

    private resetSlider():void
    {
        this.offset = {x:0, y:0};
        this.delta = {x: 0, y: 0};
    }

    ngOnDestroy(){

        //The listener returns a functon to unlisten. Execute that funtion
        if (this.touchStartEvent){
            this.touchStartEvent();
        }
        if (this.touchMoveEvent){
            this.touchMoveEvent();
        }
        if (this.touchEndEvent){
            this.touchEndEvent();
        }
        if (this.mouseDownEvent){
            this.mouseDownEvent();
        }
        if (this.mouseMoveEvent){
            this.mouseMoveEvent();
        }
        if (this.mouseUpEvent){
            this.mouseUpEvent();
        }
    }
}
