/**
 * Created by Christiaan on 13/03/2017.
 */
import {Component, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, ElementRef, OnInit} from '@angular/core';
import {DashboardService} from './dashboard.service';
import {RequestFailure} from '../../shared/services/http/request-failure';
import {GlobalModel} from '../../shared/services/state/global.model';
import {Areaal} from '../../shared/interfaces/areaal';
import {Subscription} from 'rxjs';
import {HTTPService} from '../../shared/services/http/http.service';
import {Dashboard} from './dashboard';
import {StorageService} from '../../shared/services/storage/storage.service';
import {take} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component ({
    selector: 'dashboard-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
   
    <div class="container-fluid text-center dashboard-container mx-1 mt-3 no-mobile-side-margin">
        <ng-container *ngIf="!isDashboardLoading() && currentDashboard" >
            <div class="row widget-row" *ngFor="let row of currentDashboard.rows;">
                <div class="col-12 col-lg-{{12 / row.widgets.length}} widget-component-container px-2 mb-3" *ngFor="let widget of row.widgets;">
                    <small-widget-component *ngIf="widget.type == WIDGET_TYPE_TEXT" [widget]="widget"></small-widget-component>
                    <widget-component *ngIf="widget.type == WIDGET_TYPE_CHART" [widget]="widget" [canEditChart]="true"></widget-component>
                    <map-widget-component *ngIf="widget.type == WIDGET_TYPE_MAP" [widget]="widget"></map-widget-component>
                    <table-widget-component *ngIf="widget.type == WIDGET_TYPE_TABLE" [widget]="widget"></table-widget-component>
                    <feed-widget-component *ngIf="widget.type == WIDGET_TYPE_FEED" [widget]="widget"></feed-widget-component>
                    <static-widget-component *ngIf="widget.type == WIDGET_TYPE_STATIC" [widget]="widget"></static-widget-component>
                </div>
            </div>
        </ng-container>
    </div>
`
})

export class DashboardComponent implements OnDestroy, OnInit {

    public static readonly WIDGET_TYPE_TEXT: string = 'TEXT';
    public static readonly WIDGET_TYPE_CHART: string = 'GRAPH';
    public static readonly WIDGET_TYPE_MAP: string = 'MAP';
    public static readonly WIDGET_TYPE_TABLE: string = 'TABLE';
    public static readonly WIDGET_TYPE_FEED: string = 'FEED';
    public static readonly WIDGET_TYPE_STATIC: string = 'STATIC';

    private subDashboard: Subscription;
    private subPendingCallPaths: Subscription;

    public currentDashboard: Dashboard;

    constructor(
        private dashboardService: DashboardService,
        private model: GlobalModel,
        private httpService: HTTPService,
        private changeDetectorRef: ChangeDetectorRef,
        protected elementRef: ElementRef,
        private storageService: StorageService,
        private activatedRoute: ActivatedRoute,
        protected logger:LoggerService
    ) {
        this.subDashboard = model.currentDashboard.subscribe((currentDashboard: Dashboard) => {
            this.currentDashboard = currentDashboard;

            if (this.currentDashboard && this.currentDashboard.id) {
                this.storageService.setValue(StorageService.KEY_LAST_USED_DASHBOARD, this.currentDashboard.id);
            }

            this.changeDetectorRef.markForCheck();
        });

        this.subPendingCallPaths = this.httpService.pendingCallPaths.subscribe((paths: string[]) => {
            // Don't forget to unsubscribe

            // Refresh the loading state by checking the pending calls
            this.changeDetectorRef.markForCheck();
        });
    }
    
    ngOnInit(): void {
        this.loadDashboard();
    }

    // Very important! Unsubscribe to the observable, or it will trigger multiple times when the view is recreated
    ngOnDestroy(): any {
        this.subDashboard.unsubscribe();
        this.subPendingCallPaths.unsubscribe();
    }

    private loadDashboard() {
        this.activatedRoute.params.pipe(take(1)).subscribe((params: any) => {
            if (this.dashboardService.allowedToLoadDefaultDashboard()) {
                // get a specific dashboard
                if (params && params.dashboard && params.dashboard !== 0) {
                    this.storageService.setValue(StorageService.KEY_LAST_USED_DASHBOARD, params.dashboard);
                }
                this.dashboardService.getDashboard(this.getLastUsedDashboard()).pipe(
                    take(1)
                ).subscribe();
            }
        });
    }

    // Get last used dashboard from local storage
    private getLastUsedDashboard(): string {

        // Set default dashboard
        let resultUri: string = this.model.dashboardItems[0].uri;
        let matchFound: boolean = false;

        // Check if present in storage
        this.storageService.getStringValue(StorageService.KEY_LAST_USED_DASHBOARD, (value: string) => {
            this.logger.log('[DashboardComponent] ' + 'Last used dashboard found. ID: ' + value);

            // Check for match with dashboard items
            this.model.dashboardItems.forEach((dashboard: any) => {

                // TODO: er is geen lekkere match tussen menuitem en dashboard id (werkt niet via id en ook niet via code) dus haal het laatste getal uit de url voor nu...
                let lastNumber: any = /(\d+)(?!.*\d)/.exec(dashboard.uri);

                if (lastNumber && lastNumber[0] == value) {
                    // Found a match with a existing dashboard, you can use this value now
                    this.logger.log('[DashboardComponent] ' + 'Match found, return uri: ' + dashboard.uri);
                    resultUri = dashboard.uri;
                    matchFound = true;
                }
            });

            if (!matchFound) {
                this.logger.log('[DashboardComponent] ' + 'No match found, return default dashboard');
            }
        });

        this.logger.log('[DashboardComponent] ' + 'Load dashboard uri: ' + resultUri);

        return resultUri;
    }

    // You can't reach static/global vars from template, only local vars, so use this workaround...
    get WIDGET_TYPE_TEXT() {
        return DashboardComponent.WIDGET_TYPE_TEXT;
    }

    get WIDGET_TYPE_CHART() {
        return DashboardComponent.WIDGET_TYPE_CHART;
    }

    get WIDGET_TYPE_MAP() {
        return DashboardComponent.WIDGET_TYPE_MAP;
    }

    get WIDGET_TYPE_TABLE() {
        return DashboardComponent.WIDGET_TYPE_TABLE;
    }

    get WIDGET_TYPE_FEED() {
        return DashboardComponent.WIDGET_TYPE_FEED;
    }

    get WIDGET_TYPE_STATIC() {
        return DashboardComponent.WIDGET_TYPE_STATIC;
    }

    public isDashboardLoading(): boolean {
        return this.httpService.isPendingCallPath([DashboardService.GET_DASHBOARD_PATH]);
    }
}
