import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from "../../../services/translate/translate.service";
import {GlobalModel} from "../../../services/state/global.model";
import {Subscription} from "rxjs";
import {ITreeShortcut} from "./tree-shortcuts.interface";

@Component ({
    selector: 'tree-shortcuts-component',
    templateUrl: 'tree-shortcuts.component.html',
    styleUrls: ['tree-shortcuts.component.scss']
})

export class TreeShortcutsComponent implements OnInit, OnDestroy{
    protected subscriptions: Subscription[] = [];
    public shortcutList:ITreeShortcut[] = [];

    constructor(public ts:TranslateService, public model:GlobalModel, private cd:ChangeDetectorRef) {}

    ngOnInit() {
        this.setKey('x', this.ts.translate('treeshortcut.skipmapitems'));
        this.setKey('q', this.ts.translate('treeshortcut.limitload'));
        this.setKey('c', this.ts.translate('treeshortcut.skipclusters'));

        
        document.onkeydown = (e => {if(document.activeElement.tagName != 'INPUT'){this.setKeyState(e.key,true);}})
        document.onkeyup = (e => {if(document.activeElement.tagName != 'INPUT'){this.setKeyState(e.key,false);}})
        this.subscriptions.push(this.model.treeShortcuts.subscribe((value:ITreeShortcut[] ) => {
            this.shortcutList = value;
            this.cd.detectChanges();
        }));
    }

    public setKey(keycode:string,title:string):void{
        const shortcutList = this.model.treeShortcuts.value;
        if(shortcutList.filter(_x => {
           return _x.keycode == keycode
        }).length == 0){
            shortcutList.push({keycode:keycode,title:title,isActive:false});
            this.model.treeShortcuts.next(shortcutList);
        }
    }

    public setKeyState(keycode:string,active:boolean):void{
        this.shortcutList.map(_x => {
            if(_x.keycode == keycode){
                _x.isActive = active;
            } else {
                _x.isActive = false;
            }
        })
        this.model.treeShortcuts.next(this.shortcutList);
    }

    public forceKeyPress(keycode:string,event:any):void{
        event.stopPropagation();
        this.setKeyState(keycode,!this.model.isKeycodeActive(keycode));
    }

    public hasActiveKeyPress():boolean{
        return this.shortcutList.filter(_x => {
            return _x.isActive
        }).length > 0
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        document.onkeydown = null;
        document.onkeyup = null;
    }
}
