<div class="interactive-table">
    <div class="d-flex justify-content-between align-items-center">
        <div class="form-group-label mb-0 text-truncate">{{config.label}}</div>
        <div class="d-flex justify-content-end align-items-center">
            <div class="add-table-row text-right" *ngIf="enableActionButton(tableData.table.settings.createUrl)"></div>
            <div *ngIf="showExportOptions"
                 class="widget-title d-inline-block form-settings align-middle float-right">
                <menu-dropdown [menuTitle]="'menu.exportto' | translate">
                    <ng-container *ngFor="let option of exportOptions">
                        <div *ngIf="option.show" menuItem class="md-focusable form-setting-option"
                             (click)="handleExportClick(option.name)"
                             title="{{'menu.exportexceltitle' | translate}}">{{option.label}}</div>
                    </ng-container>
                </menu-dropdown>
            </div>
        </div>
    </div>
    <div class="loading-block d-flex justify-content-center align-items-center" *ngIf="showLoading">
        <div><img src="/assets/img/loading2022.gif" class="w-75 align-content-center" alt="{{'loading' | translate}}" title="{{'loading' | translate}}" /></div>
    </div>
    <div class="table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <ng-container *ngFor="let tableHeader of tableData.table.headers; let headerIndex = index;">
                        <th scope="col" *ngIf="tableHeader.visible" (click)="sortTable(headerIndex, tableHeader.sort)">
                            {{tableHeader.label}}
                            <i *ngIf="tableHeader.sort == 'desc'" class="material-icons align-middle">keyboard_arrow_up</i>
                            <i *ngIf="tableHeader.sort == 'asc'" class="material-icons align-middle">keyboard_arrow_down</i>
                        </th>
                    </ng-container>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let tableRow of tableData.table.rows;">
                    <ng-container *ngFor="let tableColumn of tableRow;let i = index;">
                        <td *ngIf="getDataVisible(i)"  title="{{'interactivetable.editrow' | translate}}">
                            <span *ngIf="getDataType(i) == 'date'">{{tableColumn.label  | momentDate : 'L'}}</span>
                            <span *ngIf="getDataType(i) == 'number'">{{tableColumn.label | number : '1.2-2'}}</span>
                            <span *ngIf="getDataType(i) != 'date' && getDataType(i) != 'number'">{{tableColumn.label}}</span>
                        </td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </div>
</div>
