import {ChangeDetectorRef, Component, ElementRef, Input, OnDestroy,} from '@angular/core';

import {GlobalAlertService} from "../../global-alert.service";
import {ButtonCode, GlobalPopup} from '../../global-popup';
import {TranslateService} from "../../../../shared/services/translate/translate.service";
import {Subscription} from "rxjs";
import {GlobalModel} from "../../../../shared/services/state/global.model";
import {AuthorizationService} from "../../../../shared/services/authorization/authorization.service";
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {TooltipService} from "../../../../shared/services/tooltip/tooltip.service";
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import {GlobalPopupControlExceptionEditService} from "./global-popup-control-exception-edit.service";
import {ESelectedType, IControlException, IControlExceptionList} from "./global-popup-control-exception-edit.interface";
import * as dayjs from "dayjs";
import {take} from "rxjs/operators";
import {IControlProgramList} from "../global-popup-control-program-edit/global-popup-control-program-edit.interface";
import {LumiSelectOption} from "../../../../shared/components/commonUI/select/lumi-select/lumi-select.interface";
import * as moment from "moment";


@Component ({
    selector: 'global-popup-control-exception-edit-component',
    templateUrl: 'global-popup-control-exception-edit.component.html',
    styleUrls: ['global-popup-control-exception-edit.component.scss']
})

export class GlobalPopupControlExceptionEditComponent extends AbstractBasePopupComponent implements OnDestroy {
    protected readonly ESelectedType = ESelectedType;
    private subscriptions: Subscription[] = [];
    @Input() currentActiveFilterQuery:string = '';

    public _mobileMode:boolean = false;
    public _tabletMode:boolean = false;
    public _laptopMode:boolean = false;
    public showList:boolean = false;
    public disableAddExceptionButton:boolean = false;
    public isAddingNewException:boolean = false;
    public hasFormError:boolean = false;
    public activeControlException:IControlException = null;
    public hasChanged:boolean = false;
    public controlExceptionList:IControlExceptionList[] = [];
    public controlProgramsList:IControlProgramList[] = [];
    public isLoadingControlException:boolean = false;
    public isLoadingControlProgramList:boolean = false;
    public isLoadingControlExceptionList:boolean = true;
    public textualSumup:string = '';

    public daysOfMonth:LumiSelectOption[] = [];
    public daysOfWeek:LumiSelectOption[] = [];
    public daysOfWeekShort:LumiSelectOption[] = [];
    public monthsOfYear:LumiSelectOption[] = [];
    public everyX:LumiSelectOption[] = [];
    public priorityOptions:LumiSelectOption[] = [];
    public controlProgramsOptions:LumiSelectOption[] = [];

    public constructor(protected cd:ChangeDetectorRef, protected elementRef:ElementRef, private controlExceptionService:GlobalPopupControlExceptionEditService, protected globalAlertService:GlobalAlertService, protected ts:TranslateService, private model:GlobalModel, public auth:AuthorizationService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);

    }

    ngOnInit(){
        this.populateDropdownData();
        this.getControlExceptionLists();
        this.getControlProgramLists();

        if(this.globalPopup.data.controlExceptionId !== null && typeof this.globalPopup.data.controlExceptionId !== 'undefined'){
            this.getControlException(this.globalPopup.data.controlExceptionId)
        }
        this.subscriptions.push(this.model.mobileMode.subscribe((value: boolean) => {
            // Don't forget to unsubscribe
            this._mobileMode = value;
            this.checkToDisableNavigation()
        }));
        this.subscriptions.push(this.model.tabletMode.subscribe((value: boolean) => {
            // Don't forget to unsubscribe
            this._tabletMode = value;
            this.checkToDisableNavigation()
        }));
        this.subscriptions.push(this.model.laptopMode.subscribe((value: boolean) => {
            // Don't forget to unsubscribe
            this._laptopMode = value;
            this.checkToDisableNavigation()
        }));
    }
    private checkToDisableNavigation():void{
        if(!this._mobileMode && !this._tabletMode && !this._tabletMode){
            this.showList = false;
            this.cd.detectChanges()
        }
    }
    public toggleArticleNavigation(forceClose:boolean = false, preventDefault:boolean = false){
        if((this._mobileMode || this._tabletMode || this._laptopMode) && (!preventDefault)){
            if(!forceClose){
                this.showList = !this.showList;
            } else {
                this.showList = false;
            }
        }
    }
    public addDefaultExceptions(){
        this.isLoadingControlExceptionList = true;
        this.subscriptions.push(this.controlExceptionService.addDefaultExceptions(this.activeControlException, this.globalPopup.data.referenceId).subscribe((formPostResult) => {
            this.getControlExceptionLists();
        }));
    }
    public addException(){
        this.isAddingNewException = true;
        this.hasFormError = true;
        this.activeControlException = {
            id: null,
            name:'',
            controlProgramId:null,
            priority:null,
            type: null,
            startDate:null,
            endDate:null,
            recurrenceNr:null,
            weekday:null,
            weekdayRelative:null,
            month:null,
            day:null
        }
        this.setSelected(null);
        this.cd.detectChanges();
    }

    public setSelectedType(type:ESelectedType, resetFields:boolean = true):void{
        this.activeControlException.type = type;
        if(resetFields){
            this.resetFormField();
        }
    }

    public changeSelect(field:string, $event: LumiSelectOption[]){
        const selectedId = Number($event[0].id);
        switch(field){
            case 'control-program':
                this.activeControlException.controlProgramId = selectedId
                break;
            case 'priority':
                this.activeControlException.priority = selectedId
                break;
            case 'relative':
                this.activeControlException.weekdayRelative = selectedId
                break;
            case 'weekday':
                this.setWeekday(selectedId);
                break;
            case 'day':
                this.activeControlException.day = selectedId
                break;
            case 'month':
                this.activeControlException.month = selectedId
                break;
        }
        this.populateSumup();
    }

    public getSelect(field:string):LumiSelectOption[]{
        switch(field){
            case 'control-program':
                return [this.controlProgramsOptions.find(_x => {return _x.id === this.activeControlException.controlProgramId})]
            case 'priority':
                return [this.priorityOptions.find(_x => {return _x.id === this.activeControlException.priority})]
            case 'relative':
                return [this.everyX.find(_x => {return _x.id === this.activeControlException.weekdayRelative})]
            case 'weekday':
                return [this.daysOfWeek.find(_x => {return _x.id === this.activeControlException.weekday})]
            case 'day':
                return [this.daysOfMonth.find(_x => {return _x.id === this.activeControlException.day})]
            case 'month':
                return [this.monthsOfYear.find(_x => {return _x.id === this.activeControlException.month})]
            default:
                return null;
        }
    }

    public changeDate(field:string, isoString: any):void{
        const date = new Date(isoString);
        const isEmpty:boolean = date.getFullYear() == 1970;

        switch(field){
            case 'start':
                this.activeControlException.startDate = isEmpty ? null : date.toUTCString()
                break;
            case 'end':
                this.activeControlException.endDate = isEmpty ? null : date.toUTCString()
                break;
        }
        this.populateSumup();
    }

    public setWeekday(day:number):void{
        this.activeControlException.weekday = day;
        this.populateSumup();
    }

    private resetFormField():void{
        this.activeControlException.recurrenceNr = null;
        this.activeControlException.weekday = null;
        this.activeControlException.weekdayRelative = null;
        this.activeControlException.month = null;
        this.activeControlException.day = null;

        this.populateSumup();
    }

    public saveException(){
        if(this.activeControlException.name != ''){
            this.subscriptions.push(this.controlExceptionService.saveControlException(this.activeControlException, this.globalPopup.data.referenceId).subscribe((formPostResult) => {
                let controlException:IControlException = (formPostResult as any);
                if(this.activeControlException.id === null){
                    this.controlExceptionList.unshift({
                        id: controlException.id,
                        name: controlException.name,
                        controlProgramName: controlException.controlProgramName,
                        isHidden:false,
                        isSelected:true
                    })
                    this.setSelected(controlException.id)
                    this.activeControlException = controlException;
                } else {
                    const currentListControlException = this.controlExceptionList.find(_x => {return _x.id == this.activeControlException.id;})
                    currentListControlException.name = controlException.name;
                    currentListControlException.controlProgramName = controlException.controlProgramName;
                }
                this.cd.detectChanges()
            }));
            this.hasChanged = true;
        }
    }

    public populateSumup():void{
        const a = this.activeControlException;
        const startDate:string = a.startDate !== null ? moment(a.startDate).format('DD-MM-YYYY') : 'X';
        const endDate:string = a.endDate !== null ? moment(a.endDate).format('DD-MM-YYYY') : this.ts.translate('candelarrules.sumup-no-enddate');
        const recurrenceNr = a.recurrenceNr !== null ? a.recurrenceNr : 'X';
        const day = a.day !== null ? a.day : 'X';

        let weekday = 'X';
        if(a.weekday !== null){
            weekday = this.daysOfWeek.find(_x => { return _x.id == a.weekday}).name;
        }
        let month = 'X';
        if(a.month !== null){
            month = this.monthsOfYear.find(_x => { return _x.id == a.month}).name;
        }
        let weekdayRelative = 'X';
        if(a.weekdayRelative !== null){
            weekdayRelative = this.everyX.find(_x => { return _x.id == a.weekdayRelative}).name;
        }

        switch(this.activeControlException.type){
            case ESelectedType.one_time:
                this.textualSumup = this.ts.translate('candelarrules.sumup-one-time', [startDate, endDate]);
                break;
            case ESelectedType.weekly:
                this.textualSumup = this.ts.translate('candelarrules.sumup-weekly', [recurrenceNr, weekday, startDate, endDate]);
                break;
            case ESelectedType.monthly_date:
                this.textualSumup = this.ts.translate('candelarrules.sumup-monthly-date', [recurrenceNr, day, startDate, endDate]);
                break;
            case ESelectedType.monthly_weekday:
                this.textualSumup = this.ts.translate('candelarrules.sumup-monthly-weekday', [recurrenceNr, weekdayRelative, weekday, startDate, endDate]);
                break;
            case ESelectedType.yearly_date:
                this.textualSumup = this.ts.translate('candelarrules.sumup-yearly-date', [day, month, startDate, endDate]);
                break;
            case ESelectedType.yearly_weekday:
                this.textualSumup = this.ts.translate('candelarrules.sumup-yearly-weekday', [weekdayRelative, weekday, month, startDate, endDate]);
                break;
        }
        this.cd.detectChanges();
    }


    public deleteActiveControlException():void{
        this.globalAlertService.addPopup(this.ts.translate("control-program.remove-program-title"), this.ts.translate("control-program.remove-program-label"),
            [{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                {label:this.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                    callback:() => {
                        this.subscriptions.push(this.controlExceptionService.deleteControlException(this.activeControlException.id,this.globalPopup.data.referenceId).pipe(take(1)).subscribe((data) => {}));
                        this.controlExceptionList.splice(this.controlExceptionList.map(function(e) { return e.id; }).indexOf(this.activeControlException.id),1);
                        this.activeControlException = null;
                        this.cd.detectChanges();
                    },
                    isPrimary:false}
            ], () => {})
    }


    private getControlProgramLists():void{
        this.isLoadingControlProgramList = true;
        this.subscriptions.push(this.controlExceptionService.getControlProgramsList().subscribe((formPostResult) => {
            this.controlProgramsList = (formPostResult as any).controlProgramList;
            this.controlProgramsList.map(_x => {
                this.controlProgramsOptions.push({id:_x.id,name:_x.name});
            })
            this.isLoadingControlProgramList = false;
            this.cd.detectChanges()
        }));
    }



    public checkForError(){
        this.hasFormError = this.activeControlException.name == '';
        this.cd.detectChanges()
    }

    private getControlExceptionLists():void{
        this.isLoadingControlExceptionList = true;
        this.subscriptions.push(this.controlExceptionService.getControlExceptionList(this.globalPopup.data.referenceId).subscribe((formPostResult) => {
            this.controlExceptionList = formPostResult as any;
            this.isLoadingControlExceptionList = false;
            this.cd.detectChanges()
        }));
    }

    public getControlException(controlExceptionId:number):void{
        if(this.activeControlException == null || this.activeControlException.id != controlExceptionId){
            this.setSelected(controlExceptionId);
            this.isLoadingControlException = true;
            this.subscriptions.push(this.controlExceptionService.getControlException(controlExceptionId,this.globalPopup.data.referenceId).subscribe((formGetResult) => {
                this.activeControlException = formGetResult;
                this.isLoadingControlException = false;
                this.cd.detectChanges();
                this.populateSumup();
                this.checkForError();
            }));
        }
    }




    public doSearch():void {
        this.controlExceptionList.map(_x => {
            if(this.currentActiveFilterQuery == ''){
                _x.isHidden = false;
            } else {
                _x.isHidden = !(_x.name.toLowerCase().includes(this.currentActiveFilterQuery.toLowerCase()) || _x.isSelected);
            }
        })
        this.cd.detectChanges();
    }

    public hasSearchQuery():boolean {
        return !(this.currentActiveFilterQuery == '' || this.currentActiveFilterQuery == null || typeof this.currentActiveFilterQuery == 'undefined');
    }

    public clearSearchQuery():void {
        this.currentActiveFilterQuery = ''
        this.doSearch()
    }

    public canEditExceptionProgram():boolean{
        return true;
    }


    private setSelected(controlExceptionId:number):void{
        if(controlExceptionId !== null){
            this.isAddingNewException = false;
        }
        //Set the selected item to false
        this.controlExceptionList.filter(_x => {return _x.isSelected}).map(_x => {_x.isSelected = false});
        //Set current item selected
        this.controlExceptionList.filter(_x => {return _x.id == controlExceptionId}).map(_x => {_x.isSelected = true});
    }

    public convertStringDateToFormat(date:string|number,format:string):string{
        return dayjs(date).locale(this.ts.getLanguage()).format(format);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //Hide alert and perform callback of button
        this.onPopupAction.emit({event:event, alert:alert, button:button, data:{dataChanged:this.hasChanged}});
    }

    public closePopup(event:any):void
    {
        //When the moments have changed, show a are-your-sure-popup
        this.doDefaultCloseHandling(event,this.isAddingNewException);
    }

    private populateDropdownData():void{
        this.daysOfWeek.push(
            {id:1,name:moment("01-05-1970").format('dddd')},
            {id:2,name:moment("01-06-1970").format('dddd')},
            {id:3,name:moment("01-07-1970").format('dddd')},
            {id:4,name:moment("01-08-1970").format('dddd')},
            {id:5,name:moment("01-09-1970").format('dddd')},
            {id:6,name:moment("01-10-1970").format('dddd')},
            {id:0,name:moment("01-11-1970").format('dddd')},
        )
        this.daysOfWeekShort.push(
            {id:1,name:moment("01-05-1970").format('dd')},
            {id:2,name:moment("01-06-1970").format('dd')},
            {id:3,name:moment("01-07-1970").format('dd')},
            {id:4,name:moment("01-08-1970").format('dd')},
            {id:5,name:moment("01-09-1970").format('dd')},
            {id:6,name:moment("01-10-1970").format('dd')},
            {id:0,name:moment("01-11-1970").format('dd')},
        )
        this.monthsOfYear.push(
            {id:1,name:moment("01-01-1970").format('MMMM')},
            {id:2,name:moment("02-01-1970").format('MMMM')},
            {id:3,name:moment("03-01-1970").format('MMMM')},
            {id:4,name:moment("04-01-1970").format('MMMM')},
            {id:5,name:moment("05-01-1970").format('MMMM')},
            {id:6,name:moment("06-01-1970").format('MMMM')},
            {id:7,name:moment("07-01-1970").format('MMMM')},
            {id:8,name:moment("08-01-1970").format('MMMM')},
            {id:9,name:moment("09-01-1970").format('MMMM')},
            {id:10,name:moment("10-01-1970").format('MMMM')},
            {id:11,name:moment("11-01-1970").format('MMMM')},
            {id:12,name:moment("12-01-1970").format('MMMM')},
        )
        this.everyX.push(
            {id:1,name:'eerste'},
            {id:2,name:'tweede'},
            {id:3,name:'derde'},
            {id:4,name:'vierde'},
            {id:-1,name:'laatste'},
        )

        for (let i = 1; i <= 10; i++) {
            this.priorityOptions.push({id:i,name:i.toString()});
        }
        for (let i = 1; i <= 31; i++) {
            this.daysOfMonth.push({id:i,name:i.toString()});
        }
    }

    ngOnDestroy(){
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
    }

}
