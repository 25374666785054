import {MapCoreV2Component} from "../map-core-V2.component";
import {IParallelHeading, ISnappablePoint} from "./map-manager.interface";
import {MapItem} from "./map-item";

export class MapLayer {
    public layerId: number
    public layerName: string
    public isSnappable: boolean
    public isParallel: boolean
    public isVisible: boolean = true
    private mapItems:{ [key: number] : MapItem } = {};

    constructor(private mapCoreV2: MapCoreV2Component, layerId: number, layerName: string) {
        this.layerId = layerId;
        this.layerName = layerName
        this.isParallel = true;
        this.isSnappable = true;
    }

    public appendMapItem(mapItem: MapItem): void {
        if(mapItem.mapItemData.isCluster && this.getMapItem(mapItem) !== null){ //Skip clusters. They are not individually removed and are being removed at a higher level
            return;
        }
        if(this.getMapItem(mapItem) !== null){ //Remove mapItem before adding.
            this.removeMapItem(mapItem.mapItemData.baseObjects[0].id)
        }
        if (this.isVisible) {
            mapItem.initGoogleObject();
        }
        this.mapItems[mapItem.mapItemData.id]= mapItem;
    }

    public removeMapItem(baseObjectId:number):void{
        Object.values(this.mapItems).map(_mapFilter => {
            if(_mapFilter.getBaseObjectId() === baseObjectId){
                _mapFilter.removeFromMap()
            }
        })
    }

    public clear(): void {
        Object.values(this.mapItems).map(_mapItem => {
            _mapItem.removeFromMap();
        });
        this.mapItems = {};
    }

    public hide(): void {
        this.isVisible = false;
        Object.values(this.mapItems).map(_mapItem => {
            _mapItem.removeFromMap();
        });
    }

    public show(): void {
        this.isVisible = true;
        Object.values(this.mapItems).map(_mapItem => {
            _mapItem.initGoogleObject();
        });
    }

    public getMapItems(): MapItem[] {
        return Object.values(this.mapItems);
    }

    public getMapItem(mapItemToFind:MapItem): MapItem {
        if (mapItemToFind.mapItemData.id in this.mapItems) {
            return this.mapItems[mapItemToFind.mapItemData.id];
        } else {
            return null;
        }
    }

    public setClickable(clickableBool: boolean): void {
        Object.values(this.mapItems).map(_mapItem => {
            _mapItem.setClickable(clickableBool);
        })
    }

    public getSnappablePoints(): ISnappablePoint[] {
        let snappableList: ISnappablePoint[] = []
        let bound = this.mapCoreV2.map.getBounds();
        Object.values(this.mapItems).map(_mapItem => {
            snappableList.push(..._mapItem.getSnappablePoints(bound))
        })
        return snappableList;
    }

    public getParallelHeadings(): IParallelHeading[] {
        let headings: IParallelHeading[] = []
        let bound = this.mapCoreV2.map.getBounds();
        Object.values(this.mapItems).map(_mapItem => {
            headings.push(..._mapItem.getParallelHeadings(bound))
        })
        return headings;
    }
}
