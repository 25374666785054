import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpService2} from "../../../../shared/services/http-service-2.0/http2.0.service";
import {FormPostResult} from '../../../../shared/services/http-service-2.0/http.interface';
import {map} from "rxjs/operators";
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import {IControlException} from "./global-popup-control-exception-edit.interface";


@Injectable()
export class GlobalPopupControlExceptionEditService {
    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {}

    public getControlExceptionList(dimGroupId:number): Observable<FormPostResult>{
        this.logger.log('[GlobalPopupControlExceptionEditService] ' + 'Get control exception list')
        let postValues: any = {};
        return this.httpService2.doFormPostRequest(`control-form/calendar-rules-date/${dimGroupId}/list`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public getControlException(controlExceptionId:number,dimGroupId:number): Observable<IControlException>{
        this.logger.log('[GlobalPopupControlExceptionEditService] ' + 'Get control exception by id')
        return <Observable<IControlException>>this.httpService2.doGetRequest(`control-form/calendar-rules-date/${dimGroupId}/get/${controlExceptionId}`, true);
    }

    public saveControlException(controlProgram:IControlException, dimGroupId:number): Observable<FormPostResult>{
        this.logger.log('[GlobalPopupControlExceptionEditService] ' + 'Save control exception')
        let postValues: any = controlProgram;
        return this.httpService2.doFormPostRequest(`control-form/calendar-rules-date/${dimGroupId}/edit`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public addDefaultExceptions(controlProgram:IControlException, dimGroupId:number): Observable<FormPostResult>{
        let postValues: any = controlProgram;
        return this.httpService2.doFormPostRequest(`control-form/calendar-rules-date/add-default-exceptions/${dimGroupId}`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public deleteControlException(controlExceptionId:number,dimGroupId:number): Observable<FormPostResult>{
        this.logger.log('[GlobalPopupControlExceptionEditService] ' + 'Delete control exception')
        return <Observable<FormPostResult>>this.httpService2.doGetRequest(`control-form/calendar-rules-date/${dimGroupId}/delete/${controlExceptionId}`, true);
    }

    public getControlProgramsList(): Observable<FormPostResult>{
        this.logger.log('[GlobalPopupControlProgramEditService] ' + 'Get control program list')
        let postValues: any = {};
        return this.httpService2.doFormPostRequest(`control-form/control-program/list`, postValues, true);
    }
}
