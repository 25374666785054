import {Component, OnInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {GoogleChartComponent} from '../../../shared/components/commonUI/googleChart/google-chart.component';
import {WidgetService} from "./widget.service";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {StorageService} from "../../../shared/services/storage/storage.service";
import {BaseWidgetComponent} from "./base-widget.component";
import {AppSettings} from '../../../app.settings';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'widget-component',
    template: `
        <div class="card widget-panel" (globalResizeOutsideAngular)="onResize($event)">
            <div class="widget-title d-flex justify-content-between">{{widget.title}}
                <menu-dropdown *ngIf="widgetOptions?.length > 0"
                               [menuTitle]="'history.url.export-wizard' | translate">
                    <ul>
                        <ng-container *ngFor="let option of widgetOptions">
                            <li menuItem *ngIf="option.paramKey != ''" (click)="handleClickWidgetOption(option)" class="md-focusable dropdown btn-group  waves-effect waves-light luminizer-main-menu-drop-down-item-container"><a class="{{option.active?'selected-widget-option':''}} nav-item nav-link waves-effect waves-light luminizer-main-menu-drop-down-item">{{option.label}}</a></li>
                            <div *ngIf="option.paramKey == ''" class="dropdown-divider"></div>
                        </ng-container>
                        <ng-container *ngIf="canEditChart">
                            <div class="dropdown-divider"></div>
                            <li (click)="this.openChartEditor()" class="md-focusable dropdown btn-group  waves-effect waves-light luminizer-main-menu-drop-down-item-container"><a class="nav-item nav-link waves-effect waves-light luminizer-main-menu-drop-down-item">{{'chart-edit' | translate}}</a></li>
                        </ng-container>
                    </ul>
                </menu-dropdown>
            </div>
            <google-chart *ngIf="!hideChart  || !chartData || !chartData.cols || chartData.cols.length < 1" class="widget-chart" #googleChart="GoogleChartDirective" [chartId]="widget.code" [chartOptions]="chartOptions" [chartType]="chartType"></google-chart>
            <div class="chart-widget-no-data-container" *ngIf="hideChart">
                <div class="chart-widget-no-data">{{'Geen informatie beschikbaar' | translate}}</div>
            </div>
            <div *ngIf="widget.description" class="widget-subtext-container">
                <i class="material-icons md-18 widget-subtext-icon">info_outline</i>
                <div class="widget-subtext">{{widget.description}}</div>
            </div>
        </div>
    `
})
export class WidgetComponent extends BaseWidgetComponent implements OnInit
{
    @ViewChild('googleChart', {static: false}) private chartElement: any;

    //private readonly WIDGET_RESIZE_REDRAW_TIME: number = 250; //Milliseconds
    private readonly DEFAULT_HEIGHT: number = 300;
    private readonly DEFAULT_LABEL_COLOR: string = AppSettings.getColor('$color_grey-default-label');

    public chartType: string;
    public chartData: any;
    private isLoading:boolean = true;
    public hideChart:boolean = false;
    private showFullNumbers:boolean = false;

    public chartOptions: any = {
        height: this.DEFAULT_HEIGHT,
        backgroundColor: {fill: 'transparent'},
        fontName: 'Roboto',
        hAxis: {
            textStyle: {
                color: this.DEFAULT_LABEL_COLOR
            },
            titleTextStyle: {
                color: this.DEFAULT_LABEL_COLOR
            }
        },
        vAxis: {
            textStyle: {
                color: this.DEFAULT_LABEL_COLOR
            },
            titleTextStyle: {
                color: this.DEFAULT_LABEL_COLOR
            }
        },
        legend: {
            textStyle: {
                color: this.DEFAULT_LABEL_COLOR
            },
            position: 'bottom',
            alignment: 'start'
        },
        animation: {
            duration: 1000,
            easing: 'out',
            startup: true
        }
    };

    constructor(private widgetService: WidgetService, protected globalAlertService: GlobalAlertService, protected cd:ChangeDetectorRef, protected storage:StorageService, protected logger:LoggerService) {
        super(globalAlertService, cd, storage, logger);
    }

    public onResize(event: MouseEvent)
    {
        //Delay redraw for performance
        //clearTimeout(this.redrawTimeout);
        //this.redrawTimeout = setTimeout(() => this.redrawChart(), this.WIDGET_RESIZE_REDRAW_TIME);

        this.logger.log("[WidgetComponent] " + "Resize triggered, redraw..");
        this.redrawChart()
    }

    private redrawChart()
    {
        if (this.chartElement){
            this.chartElement.drawGraph(this.chartData);
        }
    }

    private loadWidget()
    {
        this.isLoading = true;
        let widgetParams = this.getWidgetParams();

        this.widgetService.getWidgetData(this.widget.id, widgetParams,
            (json: any) => {
                this.isLoading = false;
                this.chartData = json.datatable;
                this.widget.title = json.title?json.title:this.widget.title;

                if (!this.chartData) {
                    this.hideChart = true;
                }

                if (this.chartData && this.chartData.cols && this.chartData.cols.length < 2){
                    this.globalAlertService.addAlert("Widget warning", "Onvoldoende columns", "Widget " + this.widget.code + " heeft niet genoeg columns om een geldige chart mee op te bouwen")
                }

                this.widget.description = json.description;


                //When the backend sends chart options, apply them over the default values. But only override if a value is given for a key
                this.applyChartSettings(json.chartSettings);


                this.applyWidgetOptions(json.dropdowndata);

                if (widgetParams == "") {
                    //When the call is executed without params, activate the first of every option-key
                    this.activateFirstOptions();
                }else{
                    if (json.dropdowndata.paramKey !== 'toExcel') {
                        //When params are present, highlight the options by the params
                        this.activateOptionsByParams(widgetParams);
                    }
                }


                if(json.dropdowndata.paramKey !== 'toExcel'){
                    json.dropdowndata.push({label: 'Naar excel', paramKey: 'toExcel', paramValue: 'toExcel'})
                }

                //Store current selected options in local storage
                if(json.dropdowndata.paramKey !== 'toExcel') {
                    this.storeWidgetParams(this.widget.id, widgetParams);
                }


                //Update the view
                this.redrawChart();
                this.cd.markForCheck();
            }
        );

        //Apply chart specific options
        this.setChartOptions();
    }

    public openChartEditor(){
        this.chartElement.openChartEditor()
    }

    //Replaces the keys of the chart options for every key in the giving settings object
    private applyChartSettings(chartSettings:any)
    {
        if (chartSettings){
            for (let key in chartSettings) {
                this.chartOptions[key] = chartSettings[key];
            }
        }
    }

    private setChartOptions():void
    {
        //Set graph type or handle the error
        if (this.widget.graphType && this.widget.graphType != "") {
            this.chartType = this.widget.graphType;
        }else{
            //No chart information: revert to default
            this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_ERROR, "Unknown widget graph type", "Voor widget-type [" + this.widget.code + "] is geen graph-type informatie gedefinieerd");
            this.chartType = GoogleChartComponent.TYPE_BARCHART;
        }

        //Apply google chart defaults
        GoogleChartComponent.setDefaultChartOptions(this.chartType, this.chartOptions, this.globalAlertService);

        //Apply component specific options
        if (this.chartType == GoogleChartComponent.TYPE_PIECHART){
            this.chartOptions["pieSliceText"] = this.showFullNumbers?'value':'percentage';
        }

        this.cd.markForCheck();
    }

    ngOnInit()
    {
        this.loadWidget();
    }

    //When an option is activated. Triggered from super class
    protected handleActivateOption(key:string, value:string)
    {
        //Handle clientside widget options
        switch (key){
             case "showFullNumbers":
                //Redraw chart with new setting
                this.showFullNumbers = (value == "true");
                this.setChartOptions();

                setTimeout(() => {
                    this.redrawChart();
                });
                break;
            case 'toExcel':
                if (this.chartData && this.chartData.cols && this.chartData.cols.length > 1) {
                    this.widgetService.exportToExcel(this.chartData.cols, this.chartData.rows);
                }
        }
    }

    //When an option is clicked. Triggered from super class
    protected handleClickOption(key:string, value:string)
    {
        //Reload the widget. Activated options will be applied when reloading
        this.loadWidget();
    }
}
