<div class="simple-tree" (click)="toggleExpand($event)"
     [style.width]="expanded?'10rem':'2rem'"
     [style.transition-delay]="expanded?'0.1s':'0s'">
    <div class="simple-tree-icon">
        <i>{{treeIcon}}</i>
    </div>
    <div class="simple-tree-items-container {{expanded? 'show-tree':'hide-tree'}}" *ngIf="tree?.nodes.length > 0">
        <ng-container *ngFor="let node of tree.nodes">
            <simple-tree-node [node]="node"
                              [nodeIcon]="nodeIcon"
                              [selectedNodeId]="selectedNodeId"
                              (onNodeClick)="handleNodeClick($event)">
            </simple-tree-node>
        </ng-container>
    </div>
    <div class="simple-tree-expand-icon">
        <i>{{expanded ? 'chevron_left' : 'chevron_right'}}</i>
    </div>
</div>
