import {ChangeDetectorRef, Component, ElementRef} from "@angular/core";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TranslateService} from "../../../../services/translate/translate.service";
import {AbstractBaseFormComponent} from "./abstract-base-form.component";
import { FormEvent } from './form.interface';
import {GlobalEvent} from "../../../../interfaces/global-event";
import {GlobalModel} from "../../../../services/state/global.model";
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'abstract-object-form',
    template: ''
})
export abstract class AbstractObjectFormComponent extends AbstractBaseFormComponent
{
    protected constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected elementRef:ElementRef, protected ts:TranslateService, public model: GlobalModel, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, logger);
    }

    protected handleBatchUpdateSuccess():void{
        this.onComponentEvent.emit({event:FormEvent.BATCH_SAVE_SUCCESS, data:{}});
        this.globalAlertService.addAlertSuccess(this.ts.translate('batchupdate.savetitle'), this.ts.translate('batchupdate.savetext' ), '');
    }

    protected handleBatchDeleteSuccess(selectedBaseObjectIds:(string[]|number[]) = null):void{
        this.onComponentEvent.emit({event:FormEvent.BATCH_DELETE_SUCCESS, data:{}});
        if(selectedBaseObjectIds !== null){
            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_BASEOBJECT_BATCH_SUCCESS, {baseObjectIds: selectedBaseObjectIds}));
        }
        this.globalAlertService.addAlertSuccess(this.ts.translate('batchupdate.deletetitle'), this.ts.translate('batchupdate.deletetext' ), '');
    }
}
