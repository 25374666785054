import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {SortService} from '../shared/baseTable/sort.service';
import {FilterableTableComponent} from '../filterableTable/filterableTable.component';
import {FilterService} from '../filterableTable/filter.service';
import {Row} from '../shared/baseTable/baseTable.interface';
import {BaseTableExportService} from '../shared/baseTable/baseTableExport.service';
import {GeoDataManagementTableData} from './geoDataManagementTable.interface';
import {LoggerService} from "../../../services/logger/logger.service";

@Component({
    selector: 'geo-data-management-table-component',
    templateUrl: `../shared/baseTable/baseTable.component.html`,
    providers: [SortService, FilterService]
})
export class GeoDataManagementTableComponent extends FilterableTableComponent {
    @Input() multiSelect = true;
    @Input() tableData: GeoDataManagementTableData;
    
    private lastClickedRowIndex = 0;
    
    constructor(
        protected cd: ChangeDetectorRef,
        public filterService: FilterService,
        protected tableSortService: SortService,
        protected exportService: BaseTableExportService,
        protected logger:LoggerService
    ) {
        super(cd, filterService, tableSortService, exportService, logger);
    }
    
    public onRowClick(event: MouseEvent, clickedRow: Row): void {
        if (!this._tableOptions.canActivateRows) {
            return;
        }
        
        const actualRowIndex: number = this.getVisibleRows().findIndex(row => clickedRow.uniqueId === row.uniqueId);
        let rows: Row[] = [];
        
        if (event.shiftKey && this.multiSelect) {
            rows = this.getVisibleRows().slice(
                Math.min(this.lastClickedRowIndex, actualRowIndex), Math.max(this.lastClickedRowIndex, actualRowIndex) + 1
            );
            rows.filter(row => !row.isSelected).forEach(row => this.setRowIsSelectedByUniqueId(row.uniqueId, true, false));
            this.detectChanges();
        } else if (event.ctrlKey || event.metaKey) {
            if (!this.multiSelect) {
                this.resetSelectedRows();
            }
            this.setRowIsSelectedByUniqueId(clickedRow.uniqueId, !clickedRow.isSelected);
        } else {
            this.resetSelectedRows();
            this.setRowIsSelectedByUniqueId(clickedRow.uniqueId);
        }
        
        this.onRowAction.emit(
            this.getSelectedRows().map(row => this.tableData.rows.find(rowData => rowData.uniqueId === row.uniqueId))
        );
        this.lastClickedRowIndex = actualRowIndex;
    }
    
    public getSelectedRows(): Row[] {
        return this.rows.filter(row => row.isSelected);
    }
    
    public resetSelectedRows(): void {
        this.rows.filter(row => row.isSelected).forEach(row => row.isSelected = false);
        this.detectChanges();
    }
    
    public setRowIsSelectedByUniqueId(uniqueId: string | number, isSelected: boolean = true, detectChanges: boolean = true): void {
        this.getVisibleRows().find(row => String(row.uniqueId) === String(uniqueId)).isSelected = isSelected;
        if (detectChanges) {
            this.detectChanges();
        }
    }
    
    public setAllVisibleRowsAsSelected(): void {
        this.getVisibleRows().forEach(row => row.isSelected = true);
        this.detectChanges();
    }
}
