export class PolygonSelect {
    private startPos: google.maps.LatLng = null;
    private rect: google.maps.Rectangle = null;
    private poly: google.maps.Polygon = null;

    public changeable: boolean = true;

    public polyIsActive(): boolean {
        return (this.poly !== null)
    }

    public dragIsActive(): boolean {
        return (this.rect !== null);
    }

    startDragSelect(map: google.maps.Map, pos: google.maps.LatLng): void {
        if (this.rect) {
            this.rect.setMap(null)
        }
        else if (this.poly) {
            this.closePolygonSelect()
        }

        map.setOptions({
            draggable: false,
        });

        this.rect = new google.maps.Rectangle({
            map: map,
            bounds: null,
            fillOpacity: 0.15,
            strokeWeight: 0.9,
            clickable: false,
            zIndex:20
        });

        this.startPos = pos;
    }

    moveDragSelect(newPos: google.maps.LatLng): void {
        if (this.rect) {
            const newBounds = new google.maps.LatLngBounds(this.startPos, null);
            newBounds.extend(newPos);
            this.rect.setBounds(newBounds);
        }
    }

    stopDragSelect(makeSelection: (rectBounds?: google.maps.LatLngBounds) => void): void {
        this.rect.getMap().setOptions({
            draggable: true
        });

        const G = google.maps;
        const rectBounds = this.rect.getBounds();

        if (this.changeable && rectBounds) {
            const ne = rectBounds.getNorthEast();
            const sw = rectBounds.getSouthWest();
            const polyPath = new G.MVCArray([
                ne,
                new G.LatLng(sw.lat(), ne.lng()),
                sw,
                new G.LatLng(ne.lat(), sw.lng()),
            ]);

            const callback = () => { makeSelection() }
            polyPath.addListener('set_at', callback);
            polyPath.addListener('insert_at', callback)
            polyPath.addListener('remove_at', callback)

            this.poly = new G.Polygon({
                map: this.rect.getMap(),
                paths: polyPath,
                fillOpacity: 0.15,
                strokeWeight: 0.9,
                clickable: false,
                editable: true,
                zIndex:20
            });
            this.setDeleteButton()
        }

        this.rect.setMap(null);
        this.rect = null;
        makeSelection(rectBounds)
    }

    containsLocation(pos: google.maps.LatLng): boolean {
        return (this.poly === null)
            ? false
            : google.maps.geometry.poly.containsLocation(pos, this.poly);
    }

    closePolygonSelect(): void {
        this.poly.setMap(null);
        this.poly = null;
    }

    private setDeleteButton(): void {
        function pointUpdated(index): void {
            const path = this;
            let btnDelete: HTMLImageElement = document.querySelector(`img[src$="${path.btnDeleteImageUrl}"]`);

            if (!btnDelete) {
                const undoimg = document.querySelector('img[src$="https://maps.gstatic.com/mapfiles/undo_poly.png"]');
                if (undoimg === null) {
                    return;
                }

                const div = document.createElement('div')
                div.style.cssText = "overflow-x: hidden; overflow-y: hidden; position: absolute; width: 30px; height: 27px; top:21px;"

                const img = document.createElement('img')
                img.src = path.btnDeleteImageUrl
                img.className = 'deletePoly'
                img.style.cssText = 'height:auto; width:auto; position: absolute; left:0; max-width: none;'
                img.addEventListener('mouseover', () => { img.style.left = '-30px' })
                img.addEventListener('mouseout', () => { img.style.left = '0' })
                img.addEventListener('mousedown', () => { img.style.left = '-60px' })
                div.appendChild(img)

                undoimg.parentElement.style.height = '21px !important';
                undoimg.parentElement.parentElement.appendChild(div);

                // now get that button back again!
                btnDelete = img;
            }

            // now add a handler for removing the passed in index
            path.btnDeleteClickHandler = function () {
                path.removeAt(index);
                return false;
            };
            btnDelete.onclick = path.btnDeleteClickHandler;
        }


        const imageUrl = '/assets/img/remove.png';
        const polyPath = this.poly.getPath();
        polyPath["btnDeleteClickHandler"] = {};
        polyPath["btnDeleteImageUrl"] = imageUrl;

        google.maps.event.addListener(this.poly.getPath(), 'set_at', pointUpdated);
        google.maps.event.addListener(this.poly.getPath(), 'insert_at', pointUpdated);
    }
}
