import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService2} from '../../../shared/services/http-service-2.0/http2.0.service';
import {LoggerService} from "../../../shared/services/logger/logger.service";
import {FormPostResult} from "../../../shared/services/http-service-2.0/http.interface";
import {IWidget, IWidgetData, IWidgetType} from "./user-defined-dashboard.interface";


@Injectable()
export class UserDefinedDashboardService {

    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {}

    public getWidgetList(): Observable<IWidget[]> {
        this.logger.log('[UserDefinedDashboardService] ' + 'Get user defined dashboard widgets')
        return <Observable<IWidget[]>>this.httpService2.doGetRequest(`dashboard/user-defined/get`, true);
    }

    public getWidgetTypes(): Observable<IWidgetType[]> {
        this.logger.log('[UserDefinedDashboardService] ' + 'Get available widget types')
        return <Observable<IWidgetType[]>>this.httpService2.doGetRequest(`dashboard/user-defined/widget-type/get`, true);
    }

    public getWidgetData(url:string): Observable<IWidgetData> {
        this.logger.log('[UserDefinedDashboardService] ' + 'Get widget data')
        return <Observable<IWidgetData>>this.httpService2.doGetRequest(url, true);
    }

    public createWidget(widgetTypeCode:string, row:number, column:number): Observable<FormPostResult>{
        this.logger.log('[UserDefinedDashboardService] ' + 'Create widget with type '+widgetTypeCode)
        return <Observable<FormPostResult>>this.httpService2.doFormPostRequest(`dashboard/user-defined/widget/new`, JSON.parse(JSON.stringify({widgetType: widgetTypeCode, row: row, col: column})), true);
    }

    public updateWidgetPosition(widgetId:number, row:number, column:number): Observable<FormPostResult>{
        this.logger.log('[UserDefinedDashboardService] ' + 'Update widget position with id '+widgetId)
        return <Observable<FormPostResult>>this.httpService2.doFormPostRequest(`dashboard/user-defined/widget/update`, JSON.parse(JSON.stringify({widgetId: widgetId, row: row, col: column})), true);
    }

    public updateWidgetChartOptions(url:string, chartOptions:any): Observable<FormPostResult>{
        this.logger.log('[UserDefinedDashboardService] ' + 'Update widget chart options')
        return <Observable<FormPostResult>>this.httpService2.doFormPostRequest(url, JSON.parse(JSON.stringify({chartOptions: chartOptions})), true);
    }

    public removeWidget(url:string): Observable<any> {
        this.logger.log('[UserDefinedDashboardService] ' + 'Remove widget')
        return <Observable<any>>this.httpService2.doGetRequest(url, true);
    }

}
