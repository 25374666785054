/**
 * Created by Christiaan on 13/03/2017.
 */
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard.component';
import {UserDefinedDashboardComponent} from "./userDefined/user-defined-dashboard.component";

const routes: Routes = [
    {path: 'view', pathMatch: 'full', component: DashboardComponent},
    {path: 'view/:dashboard', component: DashboardComponent},
    {path: 'user', component: UserDefinedDashboardComponent},
    {path: '', pathMatch: 'full', redirectTo: 'view'},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class DashboardRoutingModule {}
