import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, isDevMode, OnDestroy, OnInit, ViewChild,
} from '@angular/core';
import {GlobalModel} from './shared/services/state/global.model';
import {LoginService} from './wrapper/login/login.service';
import {Router} from '@angular/router';
import {AppSettings} from './app.settings';
import { Subscription } from 'rxjs';
import {User} from './wrapper/login/user';
import {GlobalAlertService} from './wrapper/global-alert/global-alert.service';
import {StorageService} from './shared/services/storage/storage.service';
import Utils from './shared/utils/utils';
import {AuthorizationService} from './shared/services/authorization/authorization.service';
import {TranslateService} from './shared/services/translate/translate.service';
import {Areaal} from './shared/interfaces/areaal';
import {Title} from '@angular/platform-browser';
import {LuminizerRoutes} from './shared/interfaces/routes';
import {LoggerService} from "./shared/services/logger/logger.service";
import {LumiSelectOption} from "./shared/components/commonUI/select/lumi-select/lumi-select.interface";
import {IMarkerLabelOverride} from "./shared/services/state/global-state.interface";

@Component({
    selector: 'my-app',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './app.component.html',
})

export class AppComponent implements OnInit, OnDestroy {

    private static readonly STEDIN_PREFIX: string = 'stedin';

    @ViewChild('menu', {static: false}) menu: any;

    _user: User;
    private _mobileMode = true;
    _menuTooLarge = false;
    _publicPortalMode = false;

    private subMobileMode: Subscription;
    private subUser: Subscription;
    private subPublicPortalMode: Subscription;
    private subCurrentAreaal: Subscription;

    private static getLayoutType(): string {
        // this.logger.log("[AppComponent] " + "Checking layout for host location: " + window.location.host);

        // Search for stedin in url. Return true for host "stedin.luminizer.nl"
        if (window.location.host.toLowerCase().lastIndexOf(AppComponent.STEDIN_PREFIX.toLowerCase()) > -1) {
            return AppSettings.LAYOUT_TYPE_STEDIN;
        }

        // this.logger.log("[AppComponent] " + "Default Luminizer layout selected");
        return AppSettings.LAYOUT_TYPE_LUMINIZER;
    }

    constructor(
        private titleService: Title,
        public loginService: LoginService,
        private model: GlobalModel,
        private ts: TranslateService,
        private router: Router,
        private cd: ChangeDetectorRef,
        private globalAlertService: GlobalAlertService,
        private storageService: StorageService,
        private auth: AuthorizationService,
        protected logger:LoggerService
    ) {}

    ngOnInit(): any {

        AppSettings.LAYOUT_TYPE = AppComponent.getLayoutType();
        AppSettings.setModel(this.model);
        AppSettings.setTranslateService(this.ts);

        this.logger.log('[Luminizer] ' + 'Luminizer version: ' + AppSettings.getVersionNumber() + ', Angular version: ' + Utils.getAngularVersion() + ', language: ' + this.ts.getLanguage() + ', production mode: ' + (isDevMode() ? 'NO' : 'YES'));

        this.subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {
            // Don't forget to unsubscribe
            this._mobileMode = value;
        });

        this.subUser = this.model.user.subscribe((value: User) => {
            this._user = value;
            this.cd.markForCheck();
        });

        this.subPublicPortalMode = this.model.publicPortalMode.subscribe((value: boolean) => {
            // Don't forget to unsubscribe
            this._publicPortalMode = value;
        });

        if (this.storageService.keyExists(StorageService.KEY_MARKER_LABEL_OVERRIDE)) {
            this.storageService.getObjectValue(StorageService.KEY_MARKER_LABEL_OVERRIDE, (_value: IMarkerLabelOverride) => {
                this.model.mapMarkerLabelOverride = {
                    id: _value.id,
                    name: _value.name
                }
            });
        }



        if (AppSettings.ENABLE_VISIBLE_CHANGE_DETECTION) {
            // DEBUG PURPOSE ONLY!! (no translation)
            this.globalAlertService.addAlert('Visual change detection enabled', 'Debug option', 'Een teveel aan change detection wordt zichtbaar gemaakt door het element zwart te kleuren. Hoe meer change detection hoe zwarter. Vergeet deze debug option niet uit te zetten bij de app-settings voor een echte release-build.')
        }

        this.subCurrentAreaal = this.model.currentAreaal.subscribe((value: Areaal) => {
            if (!value) {return}
            this.titleService.setTitle('Luminizer - ' + value.label)
        });

        // Add class to body when not production so styles can be applied
        if(/\blocalhost\b/i.test(window.location.host) || window.location.host == 'development.luminizer.nl' || window.location.host == 'testing.luminizer.nl' || window.location.host == 'staging.luminizer.nl'){
            document.body.classList.add('env-warn');
            document.body.classList.add('lang-'+this.ts.getLanguage());
            if(/\blocalhost\b/i.test(window.location.host)){
                document.body.classList.add('env-local');
            } else if(window.location.host == 'development.luminizer.nl'){
                document.body.classList.add('env-dev');
            } else if(window.location.host == 'testing.luminizer.nl'){
                document.body.classList.add('env-test');
            } else if(window.location.host == 'staging.luminizer.nl'){
                document.body.classList.add('env-staging');
            }
        }
    }

    ngOnDestroy() {
        this.subUser.unsubscribe();
        this.subMobileMode.unsubscribe();
        this.subPublicPortalMode.unsubscribe();
        this.subCurrentAreaal.unsubscribe();
    }

    notOnForbiddenPage(): boolean {
        return !this.router.isActive(LuminizerRoutes.FORBIDDEN_PAGE, false);
    }

    handleClickContent(): void {
        if (this.menu) {
            this.menu.closeMenu();
        }
    }
}
