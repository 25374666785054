import {ChangeDetectorRef, Component} from '@angular/core';
import {BaseTableComponent} from '../shared/baseTable/baseTable.component';
import {SortService} from '../shared/baseTable/sort.service';
import {FilterService} from './filter.service';
import {Filter} from './filter.interface';
import {BaseTableExportService} from '../shared/baseTable/baseTableExport.service';
import {TableSorting} from '../shared/baseTable/baseTable.interface';
import {LoggerService} from "../../../services/logger/logger.service";

@Component({
    selector: 'filterable-table-component',
    templateUrl: `../shared/baseTable/baseTable.component.html`,
    providers: [SortService, FilterService]
})
export class FilterableTableComponent extends BaseTableComponent {
    public allowFiltering: boolean = true;
    
    constructor(
        protected cd: ChangeDetectorRef,
        protected filterService: FilterService,
        protected tableSortService: SortService,
        protected exportService: BaseTableExportService,
        protected logger:LoggerService
    ) {
        super(cd, tableSortService, exportService,logger);
    }
    
    public updateTable(selectedItems?: (string | number)[], newSorting?: TableSorting, keepScrollPosition: boolean = false) {
        this.isLoading$.next(true);
        this.detectChanges();
        setTimeout(() => {
            this.isLoading$.next(false);
            super.createTable(selectedItems);
            this.filterService.filterOnColumn(this.rows, this.headers);
            super.setNewSorting(newSorting);
            if (!keepScrollPosition) {
                super.setView(0);
                super.scrollToScrollTop(0);
            } else {
                super.reapplyView();
            }
        });
    }
    
    public checkActiveColumnFilter(columnId: string | number): boolean {
        return this.filterService.getActiveFilters().some((filter: Filter) => {
            return filter.tableColumnId === columnId;
        });
    }
    
    public filterColumn(event: MouseEvent, columnId: string | number): void {
        // overlapping click events
        event.stopPropagation();
        
        this.onFilterColumn.emit(columnId);
    }
    
    public applyFilters(): void {
        this.isLoading$.next(true);
        this.detectChanges();
        setTimeout(() => {
            this.isLoading$.next(false);
            this.filterService.filterOnColumn(this.rows, this.headers);
            this.reapplyView();
        });
    }
    
    public addFilter(filter: Filter): void {
        this.filterService.addActiveFilter(filter);
    }
}
