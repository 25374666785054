import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {TreeMapFormComponent} from '../../shared/components/tree-map-form/tree-map-form.component';
import {Router} from '@angular/router';
import {GlobalModel} from '../../shared/services/state/global.model';
import {AuthorizationService} from '../../shared/services/authorization/authorization.service';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {FormEvent} from '../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'master-data',
    templateUrl: './master-data.component.html'
})
export class MasterDataComponent implements AfterViewInit {
    public static readonly MODULE_PATH_MASTER_DATA: string = 'master-data';
    public static readonly FORM_URL: string = '/master-data/view';

    public masterDataType: string = null;
    public masterDataTypeLabel: string = null;
    
    @ViewChild('treeMapFormComponent', {static: false}) treeMapForm: TreeMapFormComponent;

    constructor(
        public _router: Router,
        public model: GlobalModel,
        public auth: AuthorizationService,
        public ts: TranslateService,
        public globalAlertService: GlobalAlertService,
        protected logger:LoggerService
    ) {
    }

    ngAfterViewInit(): void {
        this.treeMapForm.setTreeMapFormSettings(
            'master_1',
            'master_2',
            [],
            MasterDataComponent.MODULE_PATH_MASTER_DATA,
            [],
            MasterDataComponent.FORM_URL,
            '',
            'master-data',
            this.model.masterDataSelectedItems,
            this.model.masterDataFormData,
            this.model.masterDataFormCollapsed,
            this.model.masterDataTreeCollapsed,
            this.model.masterDataMapItems,
            this.model.masterDataAutoLoadedMapItems,
            this.model.masterDataAutoLoadedTableItems,
            this.model.masterDataTableItems,
            this.model.masterDataFilterString,
            this.model.masterDataTree,
            {
                hideMap: true,
                allowCreateMarker: this.auth.allowCreateMapItem(),
                allowMarkerDrag: this.auth.allowMoveMapItem()
            },
            {
                unfoldFolderClick: true
            }
        );
        
        this.treeMapForm.autoRefreshOnModuleEnter = true;
        
        this.treeMapForm.viewInit();
        this.treeMapForm.treeComponent.onSelectionChange.subscribe(treeSelection => {
            this.masterDataType = (treeSelection.references && treeSelection.references.length > 0 && treeSelection.references[0].data)
                ? treeSelection.references[0].data
                : this.masterDataType = null;

            this.masterDataTypeLabel = (treeSelection.treeNodes && treeSelection.treeNodes.length > 0 && treeSelection.treeNodes[0].label)
                ? treeSelection.treeNodes[0].label
                : this.masterDataTypeLabel = this.masterDataType;
            this.logger.log('[MasterDataComponent] ' + JSON.stringify(this.masterDataType));
        });

    }

    public handleComponentEvent(eventData: { event: string, data: { termijnstaatId?: number, baseObjectId?: number } }): void {
        switch (eventData.event) {
            case FormEvent.CANCEL:
                const polygonSelect = this.treeMapForm.mapTableComponent.mapComponent.polygonSelect;
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect();
                }
                this.treeMapForm.clearSelectedItems();
                break;
            case FormEvent.WEEKPRIJS_CREATE_SUCCESS:
                this.treeMapForm.getFormForMasterData('termijnstaat-' + eventData.data.termijnstaatId);
                break;
            case FormEvent.SAVE_SUCCESS:
            case FormEvent.MERGE_SUCCESS:
                this.treeMapForm.refreshMapTableItems(this.treeMapForm.getSelectedItemIds());
                break;

            case FormEvent.DELETE_MASTERDATA_SUCCESS:
                this.treeMapForm.refreshMapTableItems(this.treeMapForm.getSelectedItemIds());
                this.treeMapForm.clearSelectedItems();
                break;
        }
    }

    public handleClickAdd(): void {
        const itemText = this.masterDataTypeLabel?this.masterDataTypeLabel:this.ts.translate('onbekend');
        const postPath = 'master-data/' + this.masterDataType + '/create';


        this.globalAlertService.addPopupCreateMasterData(this.ts.translate('Item toevoegen aan: ') + itemText, '', postPath, (buttonCode: string, response: { newId: number }) => {
            //User pressed OK and call succeeded
            this.logger.log('[MasterDataComponent] Gebruiker heeft op OK gedrukt, response:', response);
            if (response) {
                this.treeMapForm.refreshMapTableItems([response.newId]);
                this._router.navigate([this.treeMapForm.MODULE_FORM_URL, `${this.masterDataType}-${response.newId}`], {queryParamsHandling: 'merge'})
                    .catch(this.logger.error)
            }
        }, () => {
            this.logger.log('[MasterDataComponent] Gebruiker heeft op ANNULEREN of kruisje gedrukt');
        }, '', this.masterDataType);
    }
}
