/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable} from '@angular/core';
import {GlobalModel} from '../../services/state/global.model';
import {HTTPService} from '../../services/http/http.service';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {RequestFailure} from '../../services/http/request-failure';
import {ICluster, MapItem} from '../map/map-item/map-item';
import {TableOptions, TableOptionsField} from '../table/tableColumnSelector/table-options';
import {AuthorizationService} from '../../services/authorization/authorization.service';
import {TreeNodeLMX} from '../commonUI/tree/tree-node-lmx';
import {MapSettings} from "../map/managers/map.settings";
import {Observable} from "rxjs";
import {HttpService2} from "../../services/http-service-2.0/http2.0.service";
import {IClusterInfo} from "./map-table.interface";
import {
    IControlProgram
} from "../../../wrapper/global-alert/alerts/global-popup-control-program-edit/global-popup-control-program-edit.interface";
import {FormPostResult} from "../../services/http-service-2.0/http.interface";
import {map} from "rxjs/operators";
import {GlobalStateService} from "../../services/state/global-state.service";
import {HttpParams} from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class MapTableService {
    public static readonly MAP_ITEM_PATH = 'mapitem/list/';
    public static readonly TABLE_ITEMS_PATH = 'tableitem/list/';
    // public static readonly MOVE_PATH = 'mapitem/move/';
    public static readonly BBOX: string = 'BOUNDING_BOX';

    constructor(private httpService: HTTPService, private httpService2: HttpService2, private model: GlobalModel, private globalAlertService: GlobalAlertService, private auth: AuthorizationService, private globalStateService: GlobalStateService) {
    }

    public getMapItemsById(bounds: google.maps.LatLngBounds, baseObjects: (number|string)[], references: TreeNodeLMX[], treeCode: String, tableOptions: TableOptions, objectTypes: string[], modulePath: string, successCallBack?: (mapItems: MapItem[]) => void, failCallBack?: (failure: RequestFailure) => void): void {
        let postValues: any = {
            tree: {
                'code': treeCode,
                'references': references,
                'objectTypes': objectTypes,
                bounds: bounds ? bounds.toJSON() : null,
                baseObjectIds: baseObjects.toString(),
            }, showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
        };

        this.httpService.doPostRequest(MapTableService.MAP_ITEM_PATH + modulePath, postValues,
            (json: any, url: string) => {
                if(json){
                    let convertedJson:any;
                    if(!json.mapItems){
                        convertedJson = {
                            mapItems: json
                        }
                    } else {
                        convertedJson = json;
                    }
                    successCallBack(convertedJson);
                } else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
                this.model.currentCallIsTableBBox = false;
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            },
        );
    }

    public getTableItemsById(bounds: google.maps.LatLngBounds, baseObjects: (number|string)[], references: TreeNodeLMX[], treeCode: String, tableOptions: TableOptions, objectTypes: string[], modulePath: string, successCallBack?: (tableItems: MapItem[]) => void, failCallBack?: (failure: RequestFailure) => void): void {
        const postValues = {
            tree: {
                'code': treeCode,
                bounds: bounds ? bounds.toJSON() : null,
                'references': references,
                baseObjectIds: baseObjects.toString(),
                'extraFields': this.getTableFieldCodes(tableOptions.tableFields),
                'objectTypes': objectTypes,
            }, showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
        };

        this.httpService.doPostRequest(MapTableService.TABLE_ITEMS_PATH + modulePath, postValues,
            (json: any, url: string) => {
                if (json && json.tableItems) {
                    successCallBack(json.tableItems);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
                this.model.currentCallIsTableBBox = false;
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            },
        );
    }

    private addParamsToUrl(url:string, preventLoadClusters:boolean, preventLoadFull:boolean):string{
        if(preventLoadClusters || preventLoadFull){
            url += '?';
        }
        if(preventLoadClusters){
            url += 'clustering=false';
        }
        if(preventLoadFull){
            if(preventLoadClusters){
                url += '&';
            }
            url += 'partial=true';
        }
        return url;
    }

    public getMapItems(preventLoadClusters:boolean = false, preventLoadFull:boolean = false, treeCode: String, references: TreeNodeLMX[], tableOptions: TableOptions, objectTypes: string[], modulePath: string, zoomLevel:number, successCallBack?: (mapItems: MapItem[]) => void, failCallBack?: (failure: RequestFailure) => void): void {
        let postValues: any = {
            tree: {
                code: treeCode,
                references: references,
                objectTypes: objectTypes,
                showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
                zoomLevel:zoomLevel
            },
        };

        let completeUrl:string = this.addParamsToUrl(MapTableService.MAP_ITEM_PATH + modulePath,preventLoadClusters,preventLoadFull);

        this.httpService.doPostRequest(
            completeUrl,
            postValues,
            (json: any, url: string) => {
                if (json && json.mapItems) {
                    //this.model.currentMapItems.next(plainToClass(MapItem, json.mapItems) as any);
                    successCallBack(json.mapItems); //plainToClass(MapItem, json.mapItems) as any);
                } else if(json && json.length > 0 && json[0].geometry.type == 'Polygon'){ //Check if first item is Polygon, then clusters are returned.
                    successCallBack(this.convertClustersToMapItems(json));
                } else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
                this.model.currentCallIsTableBBox = false;
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            },
        );
    }

    public getTableItems(preventLoadClusters:boolean = false, preventLoadFull:boolean = false, treeCode: String, tableOptions: TableOptions, references: TreeNodeLMX[], objectTypes: string[], modulePath: string, successCallBack?: (tableItems: { tableItems?: MapItem[], sorting?: number }) => void, failCallBack?: (failure: RequestFailure) => void): void {
        let postValues: any = {
            tree: {
                'code': treeCode,
                'extraFields': this.getTableFieldCodes(tableOptions.tableFields),
                'references': references,
                'objectTypes': objectTypes,
            }, showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
        };

        let completeUrl:string = this.addParamsToUrl(MapTableService.TABLE_ITEMS_PATH + modulePath,preventLoadClusters,preventLoadFull);

        this.httpService.doPostRequest(
            completeUrl,
            postValues,
            (json: any, url: string) => {
                if (json && json.tableItems) {
                    successCallBack({tableItems: json.tableItems, sorting: json.sorting});
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack({});
                }
                this.model.currentCallIsTableBBox = false;
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            },
        );
    }

    public getTableItemsInBoundingBox(bounds: google.maps.LatLngBounds, tableOptions: TableOptions, references: TreeNodeLMX[], objectTypes: string[], modulePath: string, successCallBack?: (tableItems: MapItem[]) => void): void {
        let postValues: any = {
            tree: {
                code: MapTableService.BBOX,
                'extraFields': this.getTableFieldCodes(tableOptions.tableFields),
                bounds: bounds ? bounds.toJSON() : null,
                objectTypes: objectTypes,
                references: references,
            }, showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
        };

        this.httpService.doPostRequest(
            MapTableService.TABLE_ITEMS_PATH + modulePath,
            postValues,
            (json: any, url: string) => {
                if (json && json.tableItems) {
                    successCallBack(json.tableItems);
                    this.globalStateService.getSetTreeFormState('set', MapTableService.BBOX, <any>json);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
                this.model.currentCallIsTableBBox = true;
            }, () => {
                //failCallBack(failure)
            },
        );
    }

    public getMapItemsInBoundingBox(bounds: google.maps.LatLngBounds, tableOptions: TableOptions, references: TreeNodeLMX[], objectTypes: string[], modulePath: string, zoomLevel: number, successCallBack?: (mapItems: MapItem[]) => void): void {
        let postValues: any = {
            tree: {
                code: MapTableService.BBOX,
                bounds: bounds ? bounds.toJSON() : null,
                objectTypes: objectTypes,
                references: references,
                showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
                zoomLevel:zoomLevel
            },
        };

        this.httpService.doPostRequest(
            MapTableService.MAP_ITEM_PATH + modulePath,
            postValues,
            (json: any, url: string) => {
                if (json && json.mapItems) {
                    successCallBack(json.mapItems);
                    this.globalStateService.getSetTreeFormState('set', MapTableService.BBOX, <any>json);
                } else if(json && json.length > 0 && json[0].geometry.type == 'Polygon'){ //Check if first item is Polygon, then clusters are returned.
                    successCallBack(this.convertClustersToMapItems(json));
                    this.globalStateService.getSetTreeFormState('set', MapTableService.BBOX, <any>json);
                } else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
                this.model.currentCallIsTableBBox = false;
            }, () => {
                //failCallBack(failure)
            },
        );
    }

    private convertClustersToMapItems(json:ICluster[]):MapItem[]{
        const newMapItemList:MapItem[] = [];
        json.sort(function(a, b) {
            if (a.total < b.total) {return 1}
            if (a.total > b.total) {return -1}
            return 0;
        });
        json.map(_cluster => {
            const convertedCoordinates: {lat:number,lng:number}[] = []
            _cluster.geometry.coordinates.map(_coordinate => {
                convertedCoordinates.push({lat:_coordinate[1],lng:_coordinate[0]}) //Get a GeoJson from server. In GeoJson LngLat is used so switch the 2.
            })
            newMapItemList.push({
                lat:_cluster.h3.center.lat,
                lng:_cluster.h3.center.lng,
                label:String(_cluster.total),
                icon:'dot-empty',
                id:_cluster.h3.cell_id,
                baseObjects:[{
                    id:_cluster.h3.cell_id,
                    label: String(_cluster.total)
                }],
                objectType: 'cluster',
                layerId: MapSettings.CLUSTER_LAYER_HARDCODED_ID,
                data:_cluster.data,
                geometry:{
                    type:_cluster.geometry.type,
                    coordinates:convertedCoordinates
                },
                total:_cluster.total,
                offline:_cluster.offline,
                fault:_cluster.fault,
                attention:_cluster.attention,
                maxClusterSize:json[0].total, //Sorting is done above so the first item always has the highest total
                isCluster: true
            })

        })
        return newMapItemList;
    }

    public getClusterInfo(treeCode:string,clusterIds:string[]): Observable<FormPostResult>{
        let postValues: any = {
            treeCode: treeCode,
            clusterIds:clusterIds
        };
        return this.httpService2.doFormPostRequest(`mapitem/batch-update/cluster/count`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    private getTableFieldCodes(tableFields: TableOptionsField[]): string[] {
        const fieldArray: string[] = [];

        tableFields.forEach((field) => {

            //If no explicit visibility, use default yes/no.
            if ((field.visible == undefined && field.default) || field.visible) {
                fieldArray.push(field.code);
            }
        });

        return fieldArray;
    }

    get MAP_ITEM_PATH(): string {
        return MapTableService.MAP_ITEM_PATH;
    }

    get TABLE_ITEMS_PATH(): string {
        return MapTableService.TABLE_ITEMS_PATH;
    }
}
