import {ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, FormControl} from "@angular/forms";
import {Directive, ElementRef, forwardRef, Renderer2} from "@angular/core";
import {LoggerService} from "../../../../services/logger/logger.service";

// taken from https://stackoverflow.com/questions/40019842/angular2-checkbox-formcontrol
// code based on work by Austin Shoecraft, updated to Angular 4 by Erik Vroon and edited

export const CHECKBOX_VALUE_OVERRIDE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckBoxControlValueOverrideAccessor),
    multi: true,
};

@Directive({
    selector: 'input[type=checkbox][formControlName],input[type=checkbox][formControl],input[type=checkbox][ngModel]',
    host: {'(change)': 'onChange(($event.target.checked === true) ? $event.target.value : "")', '(blur)': 'onTouched()'},
    providers: [CHECKBOX_VALUE_OVERRIDE_ACCESSOR]
})
export class CheckBoxControlValueOverrideAccessor implements ControlValueAccessor {
    constructor(private _renderer: Renderer2, private _elementRef: ElementRef, protected logger:LoggerService) {}
    writeValue(value: any): void {
        this.logger.log('[CheckBoxControlValueOverrideAccessor] writeValue called with value', value);
        if (value === '') {
            // see host property of this directive, this is the value a checkbox will have if it is to be not checked
            this._renderer.setProperty(this._elementRef.nativeElement, 'checked', false);
        }
        if (value && value !== '') {
            // see host property of this directive, this is the value a checkbox will have if it is to be checked
            this._renderer.setProperty(this._elementRef.nativeElement, 'checked', true);
        }
    }
    onChange = (_: any) => {};
    onTouched = () => {};
    registerOnChange(fn: (_: any) => {}): void { this.onChange = fn; }
    registerOnTouched(fn: () => {}): void { this.onTouched = fn; }
    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    }
}
