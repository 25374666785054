import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app.routing';
import {AppComponent} from './app.component';

import {WrapperModule} from "./wrapper/wrapper.module";
import {GlobalModel} from "./shared/services/state/global.model";
import {RouteGuard} from "./shared/guards/route.guard";
import {LoginService} from "./wrapper/login/login.service";
import {HTTPService} from "./shared/services/http/http.service";
import {UserService} from "./wrapper/login/user.service";
import {AuthorizationService} from "./shared/services/authorization/authorization.service";
import {WidgetService} from "./modules/dashboard/widgets/widget.service";
import {AreaalService} from "./shared/services/areaal/areaal.service";
import {TimerService} from './shared/services/timer/timer.service';

import * as moment from 'moment/moment';

import {EmptyComponent} from "./shared/components/empty/empty.component";

import {DynamicMenuModule} from "./dynamic-menu/dynamic-menu.module";
import {DashboardModule} from "./modules/dashboard/dashboard.module";
import {MenuService} from "./dynamic-menu/menu/menu.service";
import {TreeService} from "./shared/components/commonUI/tree/tree.service";
import {MapTableService} from "./shared/components/map-table/map-table.service";
import {HistoryService} from "./dynamic-menu/menu/menu-history/history.service";
import {StorageService} from "./shared/services/storage/storage.service";
import {LoggerService} from "./shared/services/logger/logger.service";
import {TooltipService} from "./shared/services/tooltip/tooltip.service";
import {MapItemService} from "./shared/components/map/map-item/map-item.service";
import {MenuSearchService} from "./dynamic-menu/menu/menu-search/menu-search.service";
import {CommonPipesModule} from "./shared/pipes/common-pipes.module";
import {DimmingService} from "./modules/control/dimming/dimming.service";
import {ValidationConstraintService} from "./shared/components/form/services/validation-constraint.service";
import {TableOptionsService} from "./shared/components/table/table-options.service";
import {SettingsService} from './shared/components/form/components/settings-service';
import * as Sentry from '@sentry/angular-ivy'
import {ExportService} from './modules/export/export.service';
import {VersionCheckInterceptor} from "./shared/interceptors/version-check-interceptor";
import {GlobalAlertService} from './wrapper/global-alert/global-alert.service';
import {TranslateService} from './shared/services/translate/translate.service';
import {registerLocaleData} from '@angular/common';
import {NgxPageScrollCoreModule} from 'ngx-page-scroll-core';
import {SassHelperComponent} from './shared/utils/sass-helper-component';
import {GuiFormModule} from './shared/components/gui-form/gui-form.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router} from '@angular/router'
import {GlobalStateService} from './shared/services/state/global-state.service';
import {LocalizationModule} from "./shared/components/localization/localization.module";

import localeNl from '@angular/common/locales/nl';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeSl from '@angular/common/locales/sl';

registerLocaleData(localeNl);
registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeSl);


@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        WrapperModule,
        DashboardModule,
        DynamicMenuModule,
        CommonPipesModule,
        GuiFormModule,
        NgxPageScrollCoreModule.forRoot({ duration: 1600 }),
        BrowserAnimationsModule,
        LocalizationModule
    ],
    declarations: [
        AppComponent,
        EmptyComponent,
        SassHelperComponent
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        GlobalModel,
        AuthorizationService,
        HTTPService,
        RouteGuard,
        LoginService,
        AreaalService,
        TimerService,
        MenuService,
        HistoryService,
        WidgetService,
        UserService,
        TreeService,
        MapTableService,
        StorageService,
        LoggerService,
        TooltipService,
        MapItemService,
        MenuSearchService,
        DimmingService,
        ValidationConstraintService,
        TableOptionsService,
        SettingsService,
        ExportService,
        HTTPService,
        GlobalStateService,
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({showDialog: false})
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: VersionCheckInterceptor,
            multi: true,
            deps: [GlobalAlertService, TranslateService]
        },
    ]
})
export class AppModule {
    // Add services to 'providers' when they need to be shared across the application.
    // They will be seen as one instance (but in no way specifically forced to be one).
    constructor(trace: Sentry.TraceService) {
        moment.locale('nl-NL');
    }
}
