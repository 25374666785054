import {Component, Input} from "@angular/core";

@Component({
    selector: 'validation-warning',
    template: `
        <ng-container *ngIf="showWarning">
            <div class="failed-validation form-note mx-0 px-2">
                <p><strong>{{"invalidform" | translate}}</strong></p>
            </div>
        </ng-container>
    `
})

export class ValidationWarningComponent {

    @Input() showWarning:boolean = false;

}
