import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    ViewChild,
} from '@angular/core';
import {GlobalModel} from '../../shared/services/state/global.model';
import {MapTableComponent} from '../../shared/components/map-table/map-table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {MapTableService} from '../../shared/components/map-table/map-table.service';
import {HTTPService} from '../../shared/services/http/http.service';
import {FormDataService} from '../../shared/components/form/services/form-data.service';
import {AuthorizationService} from '../../shared/services/authorization/authorization.service';
import {TreeMapFormComponent} from '../../shared/components/tree-map-form/tree-map-form.component';
import {SectionFormComponent} from '../../shared/components/form/components/section-form.component';
import {StorageService} from '../../shared/services/storage/storage.service';
import {GlobalEvent} from '../../shared/interfaces/global-event';
import {TableOptionsService} from '../../shared/components/table/table-options.service';
import {TreeComponent} from '../../shared/components/commonUI/tree/tree.component';
import {FormEvent} from '../../shared/components/form/containers/form/form.interface';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {stringify} from 'querystring';
import {GlobalStateService} from "../../shared/services/state/global-state.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'device-malfunction-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../../shared/components/tree-map-form/tree-map-form.component.html'
})
export class DeviceMalfunctionComponent extends TreeMapFormComponent implements AfterViewInit {

    public static readonly FORM_URL: string = '/device-malfunction/view';
    public static readonly MODULE_PATH_DEVICE_MALFUNCTION: string = 'device-malfunction';
    public static readonly BATCHUPDATE_URL: string = 'device-malfunction/batch-update/get/mast';

    @ViewChild('mapTableComponent', {static: false}) mapTableComponent: MapTableComponent;
    @ViewChild('formComponent', {static: false}) formComponent: SectionFormComponent;
    @ViewChild('dragContainer', {static: false}) dragContainer: any;
    
    constructor(
        public model: GlobalModel,
        protected mapTableService: MapTableService,
        protected elementRef: ElementRef,
        protected cd: ChangeDetectorRef,
        protected ngZone: NgZone,
        protected activatedRoute: ActivatedRoute,
        private router: Router,
        protected httpService: HTTPService,
        protected formDataService: FormDataService,
        public auth: AuthorizationService,
        protected storage: StorageService,
        protected tableOptionsService: TableOptionsService,
        protected globalAlertService: GlobalAlertService,
        protected areaalService: AreaalService,
        protected globalStateService: GlobalStateService,
        public ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(cd, activatedRoute, httpService, router, model, mapTableService, storage, tableOptionsService, auth, formDataService, ngZone, activatedRoute, globalAlertService, areaalService, globalStateService, ts, logger);
        
        this.setTreeMapFormSettings(
            'deviceMalfunction_1',
            'deviceMalfunction_2',
            [],
            DeviceMalfunctionComponent.MODULE_PATH_DEVICE_MALFUNCTION,
            ['werkorders', 'defect'],
            DeviceMalfunctionComponent.FORM_URL,
            DeviceMalfunctionComponent.BATCHUPDATE_URL,
            FormDataService.FORM_URL_DEVICE_MALFUNCTION,
            this.model.deviceMalfunctionSelectedItems,
            this.model.deviceMalfunctionFormData,
            this.model.deviceMalfunctionFormCollapsed,
            this.model.deviceMalfunctionTreeCollapsed,
            this.model.deviceMalfunctionMapItems,
            this.model.deviceMalfunctionAutoLoadedMapItems,
            this.model.deviceMalfunctionAutoLoadedTableItems,
            this.model.deviceMalfunctionTableItems,
            this.model.deviceMalfunctionFilterString,
            this.model.deviceMalfunctionTree,
            {
                allowMarkerDrag: false,
                allowMultiLineInRow: true
            }
        );
    
        this.storage.getNumberValue(StorageService.KEY_SELECTED_TREE, (value: number) => {
            if (value && (value === 48)) {
                this.MODULE_FORM_DATA_URL = FormDataService.FORM_URL_DEVICE_MALFUNCTION + '/external-malfunction-report';
                this.MODULE_BATCHUPDATE_URL = 'device-malfunction/batch-update/external-malfunction-report/get';
            }
        });
        this.storage.getObjectValue(StorageService.KEY_SELECTED_MODULE_TREE, (value: any) => {
            if (value && value.module) {
                if (value.module === 'device-malfunction') {
                    if (value.treeCode === TreeComponent.DEVICE_MALFUNCTION) {
                        this.MODULE_FORM_DATA_URL = FormDataService.FORM_URL_DEVICE_MALFUNCTION + '/external-malfunction-report';
                        this.MODULE_BATCHUPDATE_URL = 'device-malfunction/batch-update/external-malfunction-report/get';
                    }
                }
            }
        });
    }

    ngAfterViewInit(): void {
        this.viewInit();
    }

    protected handleGlobalEvent(event: GlobalEvent): void {
        if (event.type === GlobalEvent.EVENT_LOAD_FORM_REQUEST) {
            if (event.data.url) {
                // When switching entities on the batchupdate form
                this.getBatchUpdateForm(event.data.url);
            }
        }
    }

    // Overwrite super class
    protected setModuleFormDataUrl(): void {
        this.MODULE_FORM_DATA_URL = FormDataService.FORM_URL_DEVICE_MALFUNCTION;
        this.MODULE_BATCHUPDATE_URL = DeviceMalfunctionComponent.BATCHUPDATE_URL;
    }

    // Overwrite super class
    protected setModuleObjectType(): void {
        this.MODULE_OBJECT_TYPES = ['werkbonSuggestion'];
    }

    handleComponentEvent(eventData: any): void {
        switch (eventData.event) {
            case FormEvent.SAVE_SUCCESS:
            case FormEvent.BATCH_SAVE_SUCCESS:
                this.reloadTree();
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;
            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                const polygonSelect = this.mapTableComponent.mapComponent.polygonSelect;
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect();
                }
                this.clearSelectedItems();
                break;
        }
    }
}
