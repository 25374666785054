import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService2} from '../../../../services/http-service-2.0/http2.0.service';
import {IControlCalendar} from './control-calendar.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Injectable()
export class ControlCalendarService {

    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {}

    public getControlCalendar(urlToUse: string): Observable<IControlCalendar[]> {
        return <Observable<IControlCalendar[]>>this.httpService2.doGetRequest(`${urlToUse}`);
    }

}
