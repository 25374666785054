/**
 * Created by Christiaan on 13/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    ViewChild,
} from '@angular/core';
import {GlobalModel} from '../../shared/services/state/global.model';
import {MapTableComponent} from '../../shared/components/map-table/map-table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {MapTableService} from '../../shared/components/map-table/map-table.service';
import {HTTPService} from '../../shared/services/http/http.service';
import {FormDataService} from '../../shared/components/form/services/form-data.service';
import {AuthorizationService} from '../../shared/services/authorization/authorization.service';
import {TreeMapFormComponent} from '../../shared/components/tree-map-form/tree-map-form.component';
import {SectionFormComponent} from '../../shared/components/form/components/section-form.component';
import {StorageService} from '../../shared/services/storage/storage.service';
import {GlobalEvent} from '../../shared/interfaces/global-event';
import {TableOptionsService} from '../../shared/components/table/table-options.service';
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {GlobalStateService} from "../../shared/services/state/global-state.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'work-orders-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../../shared/components/tree-map-form/tree-map-form.component.html'
})
export class WorkOrdersComponent extends TreeMapFormComponent implements AfterViewInit {

    public static readonly FORM_URL: string = '/work-orders/view';
    public static readonly MODULE_PATH_WORK_ORDERS: string = 'work-orders';
    public static readonly BATCHUPDATE_URL: string = 'work-orders/batch-update/get';
    
    @ViewChild('mapTableComponent', {static: false}) mapTableComponent: MapTableComponent;
    @ViewChild('formComponent', {static: false}) formComponent: SectionFormComponent;
    @ViewChild('dragContainer', {static: false}) dragContainer: any;

    constructor(
        public model: GlobalModel,
        protected mapTableService: MapTableService,
        protected elementRef: ElementRef,
        protected cd: ChangeDetectorRef,
        protected ngZone: NgZone,
        protected activatedRoute: ActivatedRoute,
        private router: Router,
        protected httpService: HTTPService,
        protected formDataService: FormDataService,
        public auth: AuthorizationService,
        protected storage: StorageService,
        protected tableOptionsService: TableOptionsService,
        protected globalAlertService: GlobalAlertService,
        protected areaalService: AreaalService,
        protected globalStateService: GlobalStateService,
        public ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(cd, activatedRoute, httpService, router, model, mapTableService, storage, tableOptionsService, auth, formDataService, ngZone, activatedRoute, globalAlertService, areaalService, globalStateService, ts, logger);

        this.setTreeMapFormSettings(
            'WorkOrders_1',
            'WorkOrders_2',
            ['werkbon'],
            WorkOrdersComponent.MODULE_PATH_WORK_ORDERS,
            ['werkorders'],
            WorkOrdersComponent.FORM_URL,
            WorkOrdersComponent.BATCHUPDATE_URL,
            FormDataService.FORM_URL_WORK_ORDERS,
            this.model.workOrdersSelectedItems,
            this.model.workOrdersFormData,
            this.model.workOrdersFormCollapsed,
            this.model.workOrdersTreeCollapsed,
            this.model.workOrdersMapItems,
            this.model.workOrdersAutoLoadedMapItems,
            this.model.workOrdersAutoLoadedTableItems,
            this.model.workOrdersTableItems,
            this.model.workOrdersFilterString,
            this.model.workOrdersTree,
            {
                allowMarkerDrag: false,
                allowMultiLineInRow: true,
                iconSet: 'mechanic'
            }
        );
    }

    ngAfterViewInit(): void {
        this.viewInit();
    }

    protected handleGlobalEvent(event: GlobalEvent): void {
        if (event.type == GlobalEvent.EVENT_LOAD_FORM_REQUEST) {
            if (event.data.url) {
                //When switching entities on the batchupdate form
                this.getBatchUpdateForm(event.data.url);
            }
        }
        if (event.type === GlobalEvent.EVENT_REFRESH_FORM) {
            this.refreshMapTableItems(this.getSelectedItemIds());
            this.getForm(event.data.referenceId);
        }
    }

    handleComponentEvent(eventData: any): void {
        switch (eventData.event) {
            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                const polygonSelect = this.mapTableComponent.mapComponent.polygonSelect;
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect();
                }
                this.clearSelectedItems();
                break;
            case FormEvent.WORKORDER_DELETE_SUCCESS:
            case FormEvent.BATCH_DELETE_SUCCESS:
                this.reloadTree();
                this.refreshMapTableItems(this.getSelectedItemIds());
                this.clearSelectedItems();
                break;
            case FormEvent.WORK_PREPARATION_CREATE_SUCCESS:
            case FormEvent.WORK_PREPARATION_DELETE_SUCCESS:
            case FormEvent.WORK_PREPARATION_UPDATE_SUCCESS:
            case FormEvent.WORK_ACTIVITY_CREATE_SUCCESS:
            case FormEvent.WORK_ACTIVITY_DELETE_SUCCESS:
            case FormEvent.WORK_SPECIFICATION_CREATE_SUCCESS:
            case FormEvent.WORK_SPECIFICATION_DELETE_SUCCESS:
            case FormEvent.ACTIVITY_CREATE_SUCCESS:
            case FormEvent.ACTIVITY_DELETE_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                this.getForm(eventData.data.referenceId);
                break;
            case FormEvent.COMMENT_ADD_SUCCESS:
            case FormEvent.COMMENT_DELETE_SUCCESS:
            case FormEvent.ATTACHMENT_ADD_SUCCESS:
            case FormEvent.ATTACHMENT_DELETE_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;
            case FormEvent.SAVE_SUCCESS:
            case FormEvent.BATCH_SAVE_SUCCESS:
                if (eventData?.data?.referenceId) {
                    this.getForm(eventData.data.referenceId);
                }
                this.reloadTree();
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;
        }
    }
}
