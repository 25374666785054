import {Injectable, OnDestroy} from '@angular/core'
import {Cell, DataType, Header, Row} from '../shared/baseTable/baseTable.interface'
import * as moment from 'moment/moment'
import {ActivityFilterString, DateInfo, Filter, FilterCommand} from './filter.interface'
import Utils from '../../../utils/utils'
import {
    CheckActivityCellChild
} from '../shared/baseTable/table-cell/check-activity-cell-child/check-activity-cell-child.interface'
import {LoggerService} from "../../../services/logger/logger.service";

@Injectable()
export class FilterService implements OnDestroy {
    private activeFilters: Filter[] = [];
    private previousFiltersAsJSON: string;
    
    constructor(protected logger:LoggerService) {}
    
    ngOnDestroy() {
    }
    
    public getActiveFilters(): Filter[] {
        return this.activeFilters;
    }
    
    public addActiveFilter(filter: Filter): void {
        this.removeFilter(filter);
        this.activeFilters.push(filter);
    }
    
    public removeFilter(filterToBeRemoved: Filter): void {
        this.previousFiltersAsJSON = JSON.stringify([...this.activeFilters]);
        if (this.activeFilters.some(filter => filter.tableColumnId === filterToBeRemoved.tableColumnId)) {
            const indexOfFilter = this.activeFilters.findIndex(filter => filter.tableColumnId === filterToBeRemoved.tableColumnId);
            this.activeFilters.splice(indexOfFilter, 1);
        }
    }
    
    public clearAllFilters(): void {
        this.previousFiltersAsJSON = JSON.stringify([...this.activeFilters]);
        this.activeFilters = [];
    }
    
    public filterOnColumn(rows: Row[], headers: Header[]): void {
        this.logger.log(Utils.measureFunctionTime('[FilterService] Table items filtered in: ', () => {
            if (!this.getHasNewFilters()) {
                return;
            }
            this.previousFiltersAsJSON = JSON.stringify([...this.activeFilters]);
            const dateInfo: DateInfo = {
                today: this.isoStringToCompareString(moment().toISOString()),
                tomorrow: this.isoStringToCompareString(moment().add(1, 'days').toISOString()),
                yesterday: this.isoStringToCompareString(moment().add(-1, 'days').toISOString())
            };
            
            rows.filter(row => row.isVisible).forEach(row => row.isVisible = false);
            
            rows.filter((row: Row) => {
                return this.passFiltersOnRow(row, headers, dateInfo);
            }).forEach((row: Row) => {
                row.isVisible = true;
            });
        }));
    }
    
    private getHasNewFilters(): boolean {
        return this.previousFiltersAsJSON !== JSON.stringify(this.activeFilters);
    }
    
    private passFiltersOnRow(row: Row, headers: Header[], dateInfo: DateInfo): boolean {
        if (this.activeFilters.length > 0 && row && row.cells && row.cells.length > 0 && headers && headers.length > 0) {
            return this.activeFilters.every((filter: Filter) => {
                if (filter.tableColumnId && row.cells.some(cell => cell.columnId === filter.tableColumnId)) {
                    const tableCell: Cell = row.cells.find(cell => cell.columnId === filter.tableColumnId);
                    const dataType: DataType = tableCell.dataType;
                    
                    return this.passFilter(dataType, this.getCellString(tableCell), filter, dateInfo);
                }
                return row.cells.some(cell => {
                    const dataType: DataType = headers.find(header => header.columnId === cell.columnId).type;
                    return this.checkString(this.getCellString(cell), filter, dataType);
                });
            });
        } else {
            return true;
        }
    }
    
    private getCellString(cell: Cell): string {
        let string = String(cell.label ? cell.label : '');
        if (cell.dataType === DataType.COMPLEX && cell.children) {
            cell.children.forEach(cellChild => {
                string += cellChild.label;
                if (cellChild.dataType === DataType.CHECK_ACTIVITY) {
                    const activityCell = cellChild as CheckActivityCellChild;
                    string += activityCell.isDone ? ActivityFilterString.DONE : '';
                    string += activityCell.hasAttention ? ActivityFilterString.ATTENTION : '';
                    string += (!activityCell.hasAttention && !activityCell.isDone) ? ActivityFilterString.NOT_STARTED : '';
                }
            });
        }
        
        return string;
    }
    
    private passFilter(type: DataType, value: string | number, filter: Filter, dateInfo: DateInfo): boolean {
        // When the type of column is a date
        if (type === DataType.DATETIME || type === DataType.DATE) {
            return this.checkDateTime(value, filter, dateInfo);
        } else {
            return this.checkString(String(value), filter, type);
        }
    }
    
    private checkDateTime(value: any, filter: Filter, dateInfo: DateInfo): boolean {
        const command: FilterCommand = filter.filterCommand;
        const filterValues: string[] = filter.filterValues.map(_value => this.isoStringToCompareString(String(_value), true));
        
        const valueDay: string = value.split(' ')[0].split('-')[0];
        const valueMonth: string = value.split(' ')[0].split('-')[1];
        const valueYear: string = value.split(' ')[0].split('-')[2];
        
        if (!valueYear || !valueMonth || !valueDay) {
            return this.checkString(
                value,
                {filterCommand: command, filterValues: filterValues},
                DataType.DATETIME
            );
        }
        const dateValue = valueYear.trim() + valueMonth.trim() + valueDay.trim();
        
        switch (command) {
            case FilterCommand.FUTURE_X_DAYS:
                let futureDate: string = this.isoStringToCompareString(moment().add(Number(filterValues[0]), 'days').toISOString());
                return this.checkString(
                    dateValue,
                    {filterCommand: FilterCommand.BETWEEN, filterValues: [dateInfo.tomorrow as string, futureDate]},
                    DataType.DATETIME
                );
            case FilterCommand.TODAY_OR_FUTURE_X_DAYS:
                let futureDate2: string = this.isoStringToCompareString(moment().add(Number(filterValues[0]), 'days').toISOString());
                return this.checkString(
                    dateValue,
                    {filterCommand: FilterCommand.BETWEEN, filterValues: [dateInfo.today as string, futureDate2]},
                    DataType.DATETIME
                );
            case FilterCommand.TODAY:
                return this.checkString(
                    dateValue,
                    {filterCommand: FilterCommand.EQUALS, filterValues: [dateInfo.today as string]},
                    DataType.DATETIME
                );
            case FilterCommand.PAST_DAYS:
                return this.checkString(
                    dateValue,
                    {filterCommand: FilterCommand.SMALLER, filterValues: [dateInfo.today as string]},
                    DataType.DATETIME
                );
            case FilterCommand.TODAY_OR_PAST_DAYS:
                return this.checkString(
                    dateValue,
                    {filterCommand: FilterCommand.SMALLER_OR_EQUAL, filterValues: [dateInfo.today as string]},
                    DataType.DATETIME
                );
            case FilterCommand.TODAY_OR_FUTURE_DAYS:
                return this.checkString(
                    dateValue,
                    {filterCommand: FilterCommand.BIGGER_OR_EQUAL, filterValues: [dateInfo.today as string]},
                    DataType.DATETIME
                );
            case FilterCommand.FUTURE_DAYS:
                return this.checkString(
                    dateValue,
                    {filterCommand: FilterCommand.BIGGER, filterValues: [dateInfo.today as string]},
                    DataType.DATETIME
                );
            case FilterCommand.TODAY_OR_PAST_X_DAYS:
                let pastDate2: string = this.isoStringToCompareString(moment().add(-Number(filterValues[0]), 'days').toISOString());
                return this.checkString(
                    dateValue,
                    {filterCommand: FilterCommand.BETWEEN, filterValues: [pastDate2, dateInfo.today as string]},
                    DataType.DATETIME
                );
            case FilterCommand.PAST_X_DAYS:
                let pastDate: string = this.isoStringToCompareString(moment().add(-Number(filterValues[0]), 'days').toISOString());
                return this.checkString(
                    dateValue,
                    {filterCommand: FilterCommand.BETWEEN, filterValues: [pastDate, dateInfo.yesterday as string]},
                    DataType.DATETIME
                );
            default:
                return this.checkString(dateValue, {
                    filterCommand: command,
                    filterValues: filterValues
                }, DataType.DATETIME);
        }
    }
    
    private checkString(value: string, filter: Filter, type: DataType): boolean {
        const command: FilterCommand = filter.filterCommand;
        let filterValues: string[] = filter.filterValues;
        
        // Only convert values for strings
        if (type === DataType.STRING || type === DataType.COMPLEX) {
            value = value.toLowerCase();
            filterValues = filter.filterValues.map(filterValue => filterValue.toLowerCase());
        }
        
        switch (command) {
            case FilterCommand.EQUALS:
                if (value === filterValues[0]) {
                    return true;
                }
                break;
            case FilterCommand.NOT_EQUALS:
                if (value !== filterValues[0]) {
                    return true;
                }
                break;
            case FilterCommand.EMPTY:
                if (value === '') {
                    return true;
                }
                break;
            case FilterCommand.NOT_EMPTY:
                if (value !== '') {
                    return true;
                }
                break;
            case FilterCommand.CONTAINS:
                if (value.indexOf(filterValues[0]) > -1) {
                    return true;
                }
                break;
            case FilterCommand.NOT_CONTAINS:
                if (value.indexOf(filterValues[0]) === -1) {
                    return true;
                }
                break;
            case FilterCommand.SMALLER:
                if (value < filterValues[0]) {
                    return true;
                }
                break;
            case FilterCommand.SMALLER_OR_EQUAL:
                if (value <= filterValues[0]) {
                    return true;
                }
                break;
            case FilterCommand.BIGGER:
                if (value > filterValues[0]) {
                    return true;
                }
                break;
            case FilterCommand.BIGGER_OR_EQUAL:
                if (value >= filterValues[0]) {
                    return true;
                }
                break;
            case FilterCommand.BETWEEN:
                if (value >= filterValues[0] && value <= filterValues[1]) {
                    return true;
                }
                break;
            case FilterCommand.NOT_BETWEEN:
                if (value < filterValues[0] || value > filterValues[1]) {
                    return true;
                }
                break;
            case FilterCommand.IN_SET:
                for (let i = 0; i < filterValues.length; i++) {
                    if (value === filterValues[i]) {
                        return true;
                    }
                }
                break;
            case FilterCommand.NOT_IN_SET:
                for (let i = 0; i < filterValues.length; i++) {
                    if (value === filterValues[i]) {
                        return false;
                    }
                }
                return true;
            default:
                return false;
        }
        return false;
    }
    
    private isoStringToCompareString(isoString: string, adjustDay: boolean = false): string {
        const day: number = Number(isoString.split('T')[0].split('-')[2]);
        const month: number = Number(isoString.split('T')[0].split('-')[1]);
        const year: number = Number(isoString.split('T')[0].split('-')[0]);
        if (!day || !month || !year) {
            return isoString;
        }
        if (!adjustDay) {
            return `${year}${month < 10 ? '0' : ''}${month}${day < 10 ? '0' : ''}${day}`;
        }
        
        if (day >= 28) {
            if (this.isDecember(month) && day === 31) {
                return `${year + 1}0101`;
            }
            if ((this.is30DayMonth(month) && day === 30) || (this.is31DayMonth(month) && day === 31)) {
                return `${year}${(month + 1) < 10 ? '0' : ''}${month + 1}01`;
            }
            if (this.isFebruary(month)) {
                if ((this.isLeapYear(year) && day === 29) || (!this.isLeapYear(year) && day === 28)) {
                    return `${year}0301`;
                }
            }
        }
        return `${year}${month < 10 ? '0' : ''}${month}${(day + 1) < 10 ? '0' : ''}${day + 1}`;
    }
    
    private isLeapYear(year: number): boolean {
        return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }
    
    private isFebruary(month: number): boolean {
        return month === 2;
    }
    
    private isDecember(month: number): boolean {
        return month === 12;
    }
    
    private is31DayMonth(month: number): boolean {
        return (month === 1 || month === 3 || month === 5 || month === 7 || month === 8 || month === 10);
    }
    
    private is30DayMonth(month: number): boolean {
        return (month === 4 || month === 6 || month === 9 || month === 11);
    }
}
