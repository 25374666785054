
import {ChangeDetectorRef, Component, ElementRef, NgZone} from "@angular/core";
import {RequestFailure} from "../../shared/services/http/request-failure";
import {HTTPError} from "../../shared/services/http/http-error";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";
import {FormComponent} from "../../shared/components/form/containers/form/form.component";
import {FormDataService} from "../../shared/components/form/services/form-data.service";
import {GlobalModel} from "../../shared/services/state/global.model";
import {TranslateService} from "../../shared/services/translate/translate.service";
import Utils from "../../shared/utils/utils";
import {MapItemService} from "../../shared/components/map/map-item/map-item.service";
import {AbstractObjectFormComponent} from "../../shared/components/form/containers/form/abstract-object-form.component";
import {GlobalEvent} from "../../shared/interfaces/global-event";
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {ButtonCode} from '../../wrapper/global-alert/global-popup';
import {HTTPService} from '../../shared/services/http/http.service'
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'device-management-form-component',
    template : `
    <ng-container *ngIf="formData && config">
        <form-component
                #baseFormGroup
                [config]="config"
                [name]="getFormName()"
                [validationConstraints]="validationConstraints"
                [invalidControlsErrors]="invalidControlsErrors"
                [readOnly]="readOnly"
                [formIsSubmitted]="formIsSubmitted"
                (onComponentEvent)="handleComponentEvent($event)">
        </form-component>
        <!--<validation-warning [showWarning]="hasFailedConstraints()"></validation-warning>-->
    </ng-container>
    `
})

export class DeviceManagementFormComponent extends AbstractObjectFormComponent {

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, private mapItemService:MapItemService, protected formDataService:FormDataService, protected zone:NgZone, protected elementRef:ElementRef, public model:GlobalModel, protected ts:TranslateService, protected httpService: HTTPService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, model, logger);
    }
    
    public handleComponentEvent(eventData:any):void{

        switch (eventData.event){
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                    this.onComponentEvent.emit({event:FormEvent.SAVE_SUCCESS, data:{baseObjectId:eventData.data.referenceId}});
                });
                break;
            case FormEvent.DELETE_BASEOBJECT:
                this.logger.log('[DeviceManagementFormComponent] ' + 'Delete baseobject via form');
                // data: {url: url, mapItem: mapItem, attr: attr}
                this.getBaseObjectDeleteEffects(eventData.data.url, this.config.referenceId,
                    (json) => {
                        // Effects successfully received, trigger popup
                        this.globalAlertService.addPopupDeleteBaseObject( Utils.capitalizeFirstLetter(this.ts.translate('baseobject.delete', ['olc', eventData.data.attr.label])), json,
                            [
                                { label: this.ts.translate("Annuleren"), code: ButtonCode.ANNULEREN, isPrimary: true },
                                { label: this.ts.translate('Archiveren'), code: ButtonCode.ARCHIVE, callback: () => {
                                        this.mapItemService.archiveBaseObject(eventData.data.url, eventData.data.referenceId,
                                            () => {
                                                this.logger.log('[DeviceManagementFormComponent] ' + 'Object gearchiveerd: ', eventData);
                                                this.globalAlertService.addAlertSuccess(this.ts.translate('treenode.archivetitle'), this.ts.translate('treenode.archivetext' ), '');
                                                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_BASEOBJECT_SUCCESS, {baseObjectId: eventData.data.referenceId}));
                                            },
                                            () => { return },
                                            () => { return },
                                        )
                                    }, isPrimary: false },
                                {label: this.ts.translate('Verwijderen'), code: 'DELETE', callback: () => {
                                        this.mapItemService.deleteBaseObject(eventData.data.url, eventData.data.referenceId,
                                            () => {
                                                this.logger.log('[DeviceManagementFormComponent] ' + 'Object verwijderd: ', eventData);
                                                this.globalAlertService.addAlertSuccess(this.ts.translate('treenode.deletetitle'), this.ts.translate('treenode.deletetext' ), '');
                                                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_BASEOBJECT_SUCCESS, {baseObjectId: eventData.data.referenceId}));
                                            },
                                            () => { return },
                                            () => { return },
                                        )
                                    }, isPrimary: false}], () => { return });
                    },
                    () => {},
                    () => {},
                )
                break;
            case FormEvent.BATCH_DELETE:
                this.globalAlertService.addPopup(this.ts.translate("Olcs verwijderen"), this.ts.translate("Wilt u de geselecteerde items verwijderen of archiveren?"),
                    [
                        {label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                        {label:this.ts.translate("Archiveren"), code:ButtonCode.ARCHIVE,
                            callback:() => {
                                this.submitBatchDelete(this.model.deviceManagementSelectedItems.value, eventData.data.url, true, () => {
                                    this.handleBatchDeleteSuccess();
                                });
                            },
                            isPrimary:false},
                        {label:this.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                            callback:() => {
                                this.submitBatchDelete(this.model.deviceManagementSelectedItems.value, eventData.data.url, false, () => {
                                    this.handleBatchDeleteSuccess();
                                });
                            },
                            isPrimary:false}
                        ], () => {})
                break;
            case FormEvent.BATCH_SAVE:
                this.submitBatchUpdate(this.model.deviceManagementSelectedItems.value, eventData.data.formData, eventData.data.url, () => {
                    this.handleBatchUpdateSuccess();
                });
                break;
            case FormEvent.MOVE_MAPITEM:
                this.globalAlertService.addPopupMoveItem(eventData.data.mapItem,
                    () => {
                        //Object is successfully moved
                        this.onComponentEvent.emit({event:FormEvent.MOVE_MAPITEM_SUCCESS, data:{mapItemId:eventData.data.mapItem}});
                    }, () => {
                        //Move is canceled, or failed. Do nothing
                    });
                break;
            case FormEvent.SEGMENT_DELETE:
                //Click from delete button on baseobject-form (not the tree)
                this.logger.log("[DeviceManagementFormComponent] " + "Delete baseobject from form");

                // TODO: als er een mapitembush komt voor devicemanagement moet dit ook geimplementeerd:
                // this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_BASEOBJECT, {}));

                let baseObjectId:number = eventData.data.referenceId;
                let deleteUrl:string = eventData.data.attr.deleteUrl;

                this.mapItemService.getBaseObjectDeleteEffects(deleteUrl, baseObjectId ,
                    (json:any) => {
                        //Effects successfully received, trigger popup
                        this.globalAlertService.addPopupDeleteBaseObject( Utils.capitalizeFirstLetter(this.ts.translate("segment.delete")), json, [{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:false},
                            {label:this.ts.translate('Verwijderen'), code:'DELETE', callback:() => {
                                    this.mapItemService.deleteBaseObject(deleteUrl, baseObjectId,
                                        (json:any) => {
                                            this.logger.log("[DeviceManagementFormComponent] " + "Object verwijderd, stuur success event: ");
                                            this.onComponentEvent.emit({event:FormEvent.SEGMENT_DELETE_SUCCESS});
                                        },
                                        (failure:RequestFailure) => {},
                                        (error:HTTPError) => {},
                                    )
                                }, isPrimary:true},], () => {
                            //Close popup, do nothing
                        });
                    },
                    (failure:RequestFailure) => {
                        //User can't help this failure, do nothing
                    },
                    (error:HTTPError) => {
                        //HTTP error is auto reported, do nothing
                    },
                );

                break;
        }

        this.onComponentEvent.emit(eventData);
    }

    private submitBatchUpdate(items:any[], form:any, url:string, successCallBack?:() => any):void
    {
        if (!items || items.length == 0){
            this.logger.log("[DeviceManagementFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setBatchUpdateFormData(this.model.deviceManagementFormData, url, form, this.getFormName(), items,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitBatchDelete(items:any[], url:string, archive:boolean = true, successCallBack?:() => any):void
    {
        if (!items || items.length == 0){
            this.logger.log("[DeviceManagementFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.batchDelete(url, items, archive,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitFormData(form: any, itemId:number, successCallBack?:() => any):void
    {
        if (itemId == 0){
            this.logger.log("[DeviceManagementFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setFormDataForId(FormDataService.FORM_URL_DEVICES, this.model.deviceManagementFormData, form, this.getFormName(), itemId,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    public getBaseObjectDeleteEffects(url: string, baseObjectId: number, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        const postValues = {baseObjectId: baseObjectId, action: 'check'};

        this.httpService.doFormPostRequest(url, postValues,
            (json: any) => {
                // Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {
                // The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true
        );
    }

}
