import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone} from '@angular/core';
import {RequestFailure} from '../../shared/services/http/request-failure';
import {HTTPError} from '../../shared/services/http/http-error';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {GlobalModel} from '../../shared/services/state/global.model';
import {FormDataService} from '../../shared/components/form/services/form-data.service';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {AbstractObjectFormComponent} from '../../shared/components/form/containers/form/abstract-object-form.component';
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'projects-create-form-component',
    template : `
    <ng-container *ngIf="formData && config">
        <form-component
                #baseFormGroup
                [config]="config"
                [name]="getFormName()"
                [validationConstraints]="validationConstraints"
                [invalidControlsErrors]="invalidControlsErrors"
                [readOnly]="readOnly"
                [formIsSubmitted]="formIsSubmitted"
                (onComponentEvent)="handleComponentEvent($event)">
        </form-component>
    </ng-container>
    `
})

export class ProjectsCreateFormComponent extends AbstractObjectFormComponent {
    
    public constructor(
        protected cd: ChangeDetectorRef,
        protected globalAlertService: GlobalAlertService,
        protected formDataService: FormDataService,
        protected zone: NgZone,
        protected elementRef: ElementRef,
        public model: GlobalModel,
        protected ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, model, logger);
    }
    
    public handleComponentEvent(eventData: any): void {

        switch (eventData.event) {
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                    this.onComponentEvent.emit({event: FormEvent.SAVE_SUCCESS, data: {baseObjectId: eventData.data.referenceId}});
                });
                break;
            case FormEvent.BATCH_SAVE:
                this.submitBatchUpdate(this.model.projectsCreateSelectedItems.value, eventData.data.formData, eventData.data.url, () => {
                    this.handleBatchUpdateSuccess();
                });
                break;
        }

        this.onComponentEvent.emit(eventData);
    }

    private submitBatchUpdate(items: any[], form: any, url: string, successCallBack?: () => any): void {
        if (!items || items.length == 0) {
            this.logger.log('[ProjectsCreateFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setBatchUpdateFormData(this.model.projectsCreateFormData, url, form, this.getFormName(), items,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitFormData(form: any, itemId: number, successCallBack?: () => any): void {
        if (itemId == 0) {
            this.logger.log('[ProjectsCreateFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setFormDataForId(FormDataService.FORM_URL_PROJECTS_CREATE, this.model.projectsCreateFormData, form, this.getFormName(), itemId,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }
}
