import {
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
} from '@angular/core';
import {GlobalAlertService} from '../../global-alert.service';
import {ButtonInterface, GlobalPopup} from '../../global-popup';
import {FormDataService} from '../../../../shared/components/form/services/form-data.service';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {TooltipService} from '../../../../shared/services/tooltip/tooltip.service';
import {GlobalModel} from '../../../../shared/services/state/global.model';
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import * as dayjs from 'dayjs'
import {
    IControlCalendar
} from "../../../../shared/components/gui-form/components/control-calendar/control-calendar.interface";


@Component({
    selector: 'global-popup-control-calendar-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './global-popup-control-calendar.component.html',
    styleUrls: ['./global-popup-control-calendar.component.scss']
})

export class GlobalPopupControlCalendarComponent extends AbstractBasePopupComponent implements OnInit {
    public calendarData:IControlCalendar[];
    public activeYearToShow:number = dayjs().year();

    public constructor(
        protected cd: ChangeDetectorRef,
        protected globalAlertService: GlobalAlertService,
        protected formDataService: FormDataService,
        protected elementRef: ElementRef,
        protected ts: TranslateService,
        protected tooltipService: TooltipService,
        protected model: GlobalModel,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
        require(`dayjs/locale/${this.ts.getLanguage()}`)
    }

    ngOnInit() {
        this.calendarData = this.globalPopup.data.calendar;
        this.isLoading = false;
    }

    public getYears():number[]{
        const currentYear:number = dayjs().year();
        let lastUsedYear:number = null;
        let uniqueYears:number[] = [];
        this.calendarData.map(_x => {
            const yearToCheck = dayjs(_x.date).get('year');
            if(yearToCheck >= currentYear && lastUsedYear != yearToCheck){
                lastUsedYear = yearToCheck;
                uniqueYears.push(yearToCheck);
            }
        })
        return uniqueYears;
    }

    public getMonths():number[]{
        let currentYearData = this.calendarData.filter(_x => {
            return dayjs(_x.date).get('year') == this.activeYearToShow;
        });
        return currentYearData.map(item => item.month).filter((value, index, self) => self.indexOf(value) === index);
    }

    public getDays(month:number):IControlCalendar[]{
        let monthData:IControlCalendar[] = [];
        let modifiedMonth: IControlCalendar[] = [];
        let currentYearData = this.calendarData.filter(_x => {
            return dayjs(_x.date).get('year') == this.activeYearToShow;
        });
        monthData = currentYearData.filter(_data => {
            return _data.month == month;
        })

        //Make sunday not the first day of the week
        monthData.map(_day => {
            if(_day.dow == 0){
                _day.dow = 7
            }
        })

        //Add empty days to match monday-sunday
        if(monthData.length > 0){
            if(monthData[0].dow != 1){ //First day of the month is not a monday
                for (let i = 1; i < monthData[0].dow; i++) {
                    modifiedMonth.push(this.addEmptyControlCalendar(month,monthData[0].dow,monthData[0].date,(monthData[0].dow-i),true))
                }
            }
            modifiedMonth.push(...monthData)
            if(monthData[monthData.length-1].dow != 7){ //Last day of the month is not a sunday
                for (let i = monthData[monthData.length-1].dow+1; i <= 7; i++) {
                    modifiedMonth.push(this.addEmptyControlCalendar(month,monthData[monthData.length-1].dow,monthData[monthData.length-1].date,(i-monthData[monthData.length-1].dow),false))
                }
            }
        }
        return modifiedMonth;
    }

    public changeYear(year:number){
        this.activeYearToShow = year;
        this.cd.detectChanges();
    }

    private addEmptyControlCalendar(month:number,dayOfWeek:number,date:string,daysToChange:number,subtract:boolean):IControlCalendar{
        return {
            date: subtract
                ? dayjs(date).subtract(daysToChange,'day').locale(this.ts.getLanguage()).format('YYYY-MM-DD')
                : dayjs(date).add(daysToChange,'day').locale(this.ts.getLanguage()).format('YYYY-MM-DD'),
            dow: subtract
                ? dayOfWeek-daysToChange
                : dayOfWeek+daysToChange,
            month:month,
            control_program: [{
                color:null,
                name:null,
                id:null
            }],
            isEmptyPlaceholder:true
        }
    }

    public convertStringDateToFormat(date:string|number,format:string):string{
        return dayjs(date).locale(this.ts.getLanguage()).format(format);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: ButtonInterface): void {
        this.onPopupAction.emit({event: event, alert: alert, button: button});
    }

    public closePopup(event: any): void {
        this.logger.log('[GlobalPopupControlCalendarComponent] ', event);
        this.doDefaultCloseHandling(event, false);
    }
}
