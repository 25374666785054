
import {ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, Output} from "@angular/core";
import {AbstractBaseFormComponent} from "../../shared/components/form/containers/form/abstract-base-form.component";
import {RequestFailure} from "../../shared/services/http/request-failure";
import {HTTPError} from "../../shared/services/http/http-error";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";
import {FormComponent} from "../../shared/components/form/containers/form/form.component";
import {GlobalModel} from "../../shared/services/state/global.model";
import {FormDataService} from "../../shared/components/form/services/form-data.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {AbstractObjectFormComponent} from "../../shared/components/form/containers/form/abstract-object-form.component";
import {FormEvent} from '../../shared/components/form/containers/form/form.interface';
import {GlobalEvent} from '../../shared/interfaces/global-event';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'device-malfunction-form-component',
    template : `
    <ng-container *ngIf="formData && config">
        <form-component
                #baseFormGroup
                [config]="config"
                [name]="getFormName()"
                [validationConstraints]="validationConstraints"
                [invalidControlsErrors]="invalidControlsErrors"
                [readOnly]="readOnly"
                [formIsSubmitted]="formIsSubmitted"
                (onComponentEvent)="handleComponentEvent($event)">
        </form-component>
        <!--<validation-warning [showWarning]="hasFailedConstraints()"></validation-warning>-->
    </ng-container>
    `
})

export class DeviceMalfunctionFormComponent extends AbstractObjectFormComponent {

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected zone:NgZone, protected elementRef:ElementRef, public model:GlobalModel, protected ts:TranslateService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, model, logger);
    }
    
    public handleComponentEvent(eventData:any):void{

        switch (eventData.event){
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                    this.onComponentEvent.emit({event:FormEvent.SAVE_SUCCESS, data:{baseObjectId:eventData.data.referenceId}});
                });
                break;
            case FormEvent.BATCH_SAVE:
                this.submitBatchUpdate(this.model.reportMalfunctionSelectedItems.value, eventData.data.formData, eventData.data.url, () => {
                    this.handleBatchUpdateSuccess();
                });
                break;
            case FormEvent.SAVE_ADDITIONAL_REPORT:
                this.submitBatchUpdate(this.model.reportMalfunctionSelectedItems.value, eventData.data.formData, eventData.data.url, () => {
                    this.handleAdditionalReportSuccess();
                });
                break;
            case FormEvent.DELETE_MSB_ASSET:
                this.logger.log('[ReportMalfunctionFormComponent] ' + 'Delete msb via form');
                this.formDataService.deleteWorkOrder(eventData.data.attr.deleteUrl,
                    () => {
                        this.onComponentEvent.emit({event: FormEvent.MSB_MELDING_DELETE_SUCCESS});
                        this.globalAlertService.addAlertSuccess(this.ts.translate('msb-melding.isdeletedtitle'), this.ts.translate('msb-melding.isdeletedtext' ), '');
                    }, () => {}, () => {})
                break;
        }

        this.onComponentEvent.emit(eventData);
    }

    protected handleAdditionalReportSuccess():void{
        this.onComponentEvent.emit({event:FormEvent.SAVE_ADDITIONAL_REPORT, data:{}});
        this.globalAlertService.addAlertSuccess(this.ts.translate('additionalreport.savetitle'), this.ts.translate('additionalreport.savetext' ), '');
    }

    private submitBatchUpdate(items:any[], form:any, url:string, successCallBack?:() => any):void
    {
        if (!items || items.length == 0){
            this.logger.log("[ReportMalfunctionFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setBatchUpdateFormData(this.model.reportMalfunctionFormData, url, form, this.getFormName(), items,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitFormData(form: any, itemId:number, successCallBack?:() => any):void
    {
        if (itemId == 0){
            this.logger.log("[ReportMalfunctionFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

      let url: string = FormDataService.FORM_URL_REPORT_MALFUNCTION;
      if (this.model.reportMalfunctionTree.value.code === 'PERFECT_VIEW') {
        url = FormDataService.FORM_URL_REPORT_MALFUNCTION + '/pv';
      } else if (this.model.reportMalfunctionTree.value.code === 'MSB_MELDING') {
          url = FormDataService.FORM_URL_REPORT_MALFUNCTION + '/msb-melding';
      } else if (this.model.reportMalfunctionTree.value.code === 'EXTERNAL_MALFUNCTION_REPORT') {
          url = FormDataService.FORM_URL_REPORT_MALFUNCTION + '/external-malfunction-report';
      }

      this.formDataService.setFormDataForId(url, this.model.reportMalfunctionFormData, form, this.getFormName(), itemId,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }
}
