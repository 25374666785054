/**
 * Created by Christiaan on 01/05/2017.
 */
import {BehaviorSubject} from 'rxjs';
import {LumiForm} from '../../shared/components/form/containers/form/form.interface';

export interface ButtonInterface {
    callback?: (code?: ButtonCode, data?: any) => void,
    code: ButtonCode
    label?: string
    role?: string
    isPrimary?: boolean
}

export interface PopupActionEvent {
    event: MouseEvent
    alert: GlobalPopup
    button: ButtonInterface
    data?: any
}

export enum ButtonCode {
    ANNULEREN = 'ANNULEREN',
    CLOSE = 'CLOSE',
    OK = 'OK',
    DELETE = 'DELETE',
    ARCHIVE = 'ARCHIVE',
    NAVIGATE_GOOGLE_MAPS = 'NAVIGATE_GOOGLE_MAPS',
    NAVIGATE_APPLE_MAPS = 'NAVIGATE_APPLE_MAPS',
    NAVIGATE_LUMINIZER = 'NAVIGATE_LUMINIZER',
    RESET = 'RESET',
    SAVE = 'SAVE',
    SAVE_AS = 'SAVE_AS',
    SWAP_CAMERA = 'SWAP_CAMERA',
    MSB_PAIR_EXISTING = 'MSB_PAIR_EXISTING',
    MSB_CREATE_NEW = 'MSB_CREATE_NEW',
    WERKBON_SUGGESTION_CREATE_NEW_WORKORDER = 'WERKBON_SUGGESTION_CREATE_NEW_WORKORDER',
    EXTERNAL_MALFUNCTION_REPORT_PAIR_EXISTING = 'EXTERNAL_MALFUNCTION_REPORT_PAIR_EXISTING',
    EXTERNAL_MALFUNCTION_REPORT_PAIR_NEW = 'EXTERNAL_MALFUNCTION_REPORT_PAIR_NEW',
    UNMAP_DEVICE = 'UNMAP_DEVICE'

}

export class GlobalPopup {
    type: string | GlobalPopupType;
    title: string;
    subtitle: string;
    label: string;
    icon: string;
    img: string;
    callbackClose: () => void;
    callbackSuccess: () => void;
    removed: boolean;
    buttonArray: ButtonInterface[];
    data: any;
    result?: BehaviorSubject<PopupResult>;
    form?: LumiForm;
    saveUrl?: string;
    
    constructor(type: string) {
        this.type = type;
    }
}

export enum GlobalPopupType {
    'REFRESH_REQUEST' = 'REFRESH_REQUEST',
    'TIMER' = 'TIMER',
    'BASIC' = 'BASIC',
    'BASIC_INPUT' = 'BASIC_INPUT',
    'BASIC_FORM' = 'BASIC_FORM',
    'MOVE_ITEM' = 'MOVE_ITEM',
    'ABOUT_US' = 'ABOUT_US',
    'CREATE_PV_STORING' = 'CREATE_PV_STORING',
    'CREATE_MSB_MELDING' = 'CREATE_MSB_MELDING',
    'ADD_MSB_MELDING_TO_WORKORDER' = 'ADD_MSB_MELDING_TO_WORKORDER',
    'CREATE_ADDITIONAL_MSB_MELDING' = 'CREATE_ADDITIONAL_MSB_MELDING',

    'CREATE_WORKORDER_FROM_EXTERNAL_MALFUNCTION_REPORT' = 'CREATE_WORKORDER_FROM_EXTERNAL_MALFUNCTION_REPORT',
    'EXTERNAL_MALFUNCTION_REPORT_CHANGE_STATE' = 'EXTERNAL_MALFUNCTION_REPORT_CHANGE_STATE',
    'ADD_EXTERNAL_MALFUNCTION_REPORT_TO_WORKORDER' = 'ADD_EXTERNAL_MALFUNCTION_REPORT_TO_WORKORDER',

    'WERKBON_SUGGESTION_CREATE_NEW_WORKORDER' = 'WERKBON_SUGGESTION_CREATE_NEW_WORKORDER',

    'MSB_MELDING_CHANGE_STATE' = 'MSB_MELDING_CHANGE_STATE',
    'CREATE_DROPDOWN' = 'CREATE_DROPDOWN',
    'EDIT_DROPDOWN' = 'EDIT_DROPDOWN',
    'MERGE_DROPDOWN' = 'MERGE_DROPDOWN',
    'CREATE_BASEOBJECT' = 'CREATE_BASEOBJECT',
    'DELETE_BASEOBJECT' = 'DELETE_BASEOBJECT',
    'COPY_BASEOBJECT' = 'COPY_BASEOBJECT',
    'CREATE_WORK_PREPARATION' = 'CREATE_WORK_PREPARATION',
    'EDIT_WORK_PREPARATION' = 'EDIT_WORK_PREPARATION',
    'CREATE_WORK_ACTIVITY' = 'CREATE_WORK_ACTIVITY',
    'CREATE_CHECK_ACTIVITY' = 'CREATE_CHECK_ACTIVITY',
    'CREATE_WORK_SPECIFICATION' = 'CREATE_WORK_SPECIFICATION',
    'CREATE_WEEKPRIJS' = 'CREATE_WEEKPRIJS',
    'CREATE_MASTERDATA' = 'CREATE_MASTERDATA',
    'CREATE_MAPITEM' = 'CREATE_MAPITEM',
    'CREATE_MAPITEMS' = 'CREATE_MAPITEMS',
    'CREATE_DRAWINGITEM' = 'CREATE_DRAWINGITEM',
    'WARNING' = 'WARNING',
    'MAP_LEGEND' = 'POPUP_MAP_LEGEND',
    'MAP_OPTIONS' = 'POPUP_MAP_OPTIONS',
    'BARCODE_SCANNER' = 'BARCODE_SCANNER',
    'CREATE_PUBLIC_REPORT' = 'CREATE_PUBLIC_REPORT',
    'CREATE_GENERIC_REPORT' = 'CREATE_GENERIC_REPORT',
    'VIEW_STEDIN_ORDER' = 'VIEW_STEDIN_ORDER',
    'CREATE_USER' = 'CREATE_USER',
    'INVITE_USER' = 'INVITE_USER',
    'ADD_ATTACHMENT_TO_GENERIC_REPORT' = 'ADD_ATTACHMENT_TO_GENERIC_REPORT',
    'TABLE_OPTIONS' = 'TABLE_OPTIONS',
    'CREATE_EXCEPTION' = 'CREATE_EXCEPTION',
    'SET_OVERRIDE' = 'SET_OVERRIDE',
    'EDIT_SCHEME' = 'EDIT_SCHEME',
    'ADD_TABLE_FILTER' = 'ADD_TABLE_FILTER',
    'VIEW_ATTACHMENT' = 'VIEW_ATTACHMENT',
    'VIEW_IMAGES_CAROUSEL' = 'VIEW_IMAGES_CAROUSEL',
    'EDIT_TIME_REGISTRATION' = 'EDIT_TIME_REGISTRATION',
    'FIRST_LOGIN' = 'FIRST_LOGIN',
    'CREATE_MAP_SERVER' = 'CREATE_MAP_SERVER',
    'CREATE_AREAAL' = 'CREATE_AREAAL',
    'FORM_PREVIEW' = 'FORM_PREVIEW',
    'TRANSLATE' = 'TRANSLATE',
    'DEVICE_MAPPING' = 'DEVICE_MAPPING',
    'CONTROL_CALENDAR' = 'CONTROL_CALENDAR',
    'CONTROL_PROGRAM_EDIT' = 'CONTROL_PROGRAM_EDIT',
    'EDIT_USER_WIDGET' = 'EDIT_USER_WIDGET',
    'CONTROL_PROGRAM_EXCEPTION_EDIT' = 'CONTROL_PROGRAM_EXCEPTION_EDIT',
}

export interface PopupResult {
    code: PopupResultCode
}

export enum PopupResultCode {
    'OK' = 'OK',
    'CANCEL' = 'CANCEL',
    'CLOSE' = 'CLOSE'
}
