<a class="notifications-bell d-none d-xl-block" [ngClass]="model.hasNewNotifications ? 'has-notifications' : ''" (click)="toggleSidebar()">
    <div class="notify-badge" *ngIf="model.hasNewNotifications"></div>
    <i class="material-icons md-light menu-icon">circle_notifications</i>
</a>
<div class="notifications-backdrop" *ngIf="showSidebar" (click)="toggleSidebar()"></div>
<div class="notifications-sidebar" [ngClass]="showSidebar ? 'visible' : ''">
    <div class="sidebar-header d-flex align-items-start justify-content-between">
        <div class="sidebar-title">
            <h3>{{'notifications.title' | translate}}</h3>
            <a routerLink="/notifications" (click)="toggleSidebar()">
                {{'notifications.showall' | translate}} <i class="material-icons">keyboard_arrow_right</i>
            </a>
        </div>
        <div class="sidebar-close" (click)="toggleSidebar()">
            <span>&times;</span>
        </div>
    </div>
    <div class="sidebar-content">
        <div *ngFor="let notification of model.notificationList.slice(0, 10);let i = index">
            <div class="status-card">
                <div class="notify-badge" *ngIf="notification.isNew"></div>
                <div class="statusbar state-{{notification.status}}"></div>
                <div class="inner">
                    <div class="d-flex justify-content-start align-items-top">
                        <div>
                            <div class="image text d-flex align-items-center justify-content-center state-{{notification.status}}">
                                {{notification.date | momentDate : 'DD'}}<br />{{getMonthNameShort(notification.date)}}
                            </div>
                        </div>
                        <div class="info">
                            <h6>
                                <a routerLink="/notifications/{{notification.notificationId}}" (click)="toggleSidebar(notification.notificationId)">
                                    {{notification.title}}
                                </a>
                            </h6>
                            <div class="info-datelink d-flex justify-content-between">
                                <div class="date">{{notification.date | momentDate : 'LLL'}}</div>
                                <div class="readmore">
                                    <a routerLink="/notifications/{{notification.notificationId}}" (click)="toggleSidebar(notification.notificationId)">
                                        {{'notifications.readmore' | translate}} &gt;
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="no-notifications" *ngIf="model.notificationList.length === 0">
        {{'notifications.nonotifications' | translate}}
    </div>
</div>
