import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {UserDefinedDashboardService} from "./user-defined-dashboard.service";
import {IWidget, IWidgetType} from "./user-defined-dashboard.interface";
import {LumiSelectOption} from "../../../shared/components/commonUI/select/lumi-select/lumi-select.interface";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {UserDefinedWidget} from "./user-defined-widget.component";
import {FormPostResult} from "../../../shared/services/http-service-2.0/http.interface";

@Component({
    selector: 'user-defined-dashboard',
    templateUrl: './user-defined-dashboard.component.html',
    styleUrls: ['user-defined-dashboard.component.scss']
})
export class UserDefinedDashboardComponent implements OnInit, OnDestroy {
    @ViewChild('availableWidgetTypesInput', {static: false}) availableWidgetTypesInput:any;
    public subscriptions: Subscription[] = [];
    public isLoading:boolean = true;
    public userWidgetList:UserDefinedWidget[] = [];
    public availableWidgetTypes:IWidgetType[] = [];
    public availableWidgetTypesDropdown:LumiSelectOption[] = [];

    constructor(public userDashboardService:UserDefinedDashboardService, public cd:ChangeDetectorRef, public ts:TranslateService, public globalAlertService:GlobalAlertService) {}

    ngOnInit() {
        this.getUserWidgets();
        this.getAvailableWidgetTypes();

        window.onresize = (e => {
            this.userWidgetList.map(_widget => {
                _widget.drawChart();
            })
        })
    }

    public getRows():number[]{
        return this.userWidgetList
            .map(item => item.getRow())
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a,b) => a - b);
    }

    public getColumns(row:number):UserDefinedWidget[]{
        return this.userWidgetList
            .filter(_x => {return _x.getRow() == row})
            .sort((a,b) => a.getColumn() - b.getColumn());
    }

    private getUserWidgets(){
        this.subscriptions.push(this.userDashboardService.getWidgetList().subscribe((userWidgets) => {
            userWidgets.map(_x => {
                this.initWidget(_x)
            })
            this.handleLoading()
        }));
    }

    private initWidget(widget:IWidget){
        const newWidget = new UserDefinedWidget(widget, this);
        newWidget.initWidget();
        this.userWidgetList.push(newWidget)
    }

    public createWidget(selectedWidgetType:any, row:number, column:number):void{
        this.subscriptions.push(this.userDashboardService.createWidget(selectedWidgetType[0].id,row,column).subscribe((formPostResult: FormPostResult) => {
            this.initWidget((formPostResult as any))
        }))
    }

    private getAvailableWidgetTypes(){
        this.subscriptions.push(this.userDashboardService.getWidgetTypes().subscribe((widgetTypes) => {
            this.availableWidgetTypes = widgetTypes;
            widgetTypes.map(_x => {
                this.availableWidgetTypesDropdown.push({
                    id: _x.code,
                    name: _x.name,
                })
            })
            this.handleLoading()
        }));
    }

    private handleLoading():void{
        if(this.userWidgetList.length >= 0 && this.availableWidgetTypes.length >= 0){
            this.isLoading = false;
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        window.onresize = null;
    }
}
