import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {LoggerService} from "../../../../services/logger/logger.service";
import {ControlCalendarService} from "./control-calendar.service";
import {IControlCalendar} from "./control-calendar.interface";
import * as dayjs from 'dayjs'

@Component({
    selector: 'control-calendar',
    templateUrl: './control-calendar.component.html',
    styleUrls: ['control-calendar.component.scss']
})
export class ControlCalendarComponent implements OnInit {
    @Input('config') config: any;
    public label: string = '';
    public isLoading: boolean = true;
    public controlCalendarData:IControlCalendar[] = null;
    public currentWeekData:IControlCalendar[] = null;
    public currentDay:string = '';
    public currentWeekNumber:number = null;

    constructor(
        public ts: TranslateService,
        protected globalAlertService: GlobalAlertService,
        protected logger:LoggerService,
        protected controlCalendarService:ControlCalendarService
    ) {
        let weekOfYear = require("dayjs/plugin/weekOfYear");
        dayjs.extend(weekOfYear);
        require(`dayjs/locale/${this.ts.getLanguage()}`)
    }

    ngOnInit() {
        this.currentDay = dayjs().locale(this.ts.getLanguage()).format('YYYY-MM-DD')
        // @ts-ignore
        this.currentWeekNumber = dayjs().week()
        this.getControlCalendar();
    }

    public handleClickOpenControlCalendar() {
        if(this.controlCalendarData){
            this.openControlCalendarPopup(this.controlCalendarData)
        }
    }

    public formatDateString(date:string, format:string):string{
        return dayjs(date).locale(this.ts.getLanguage()).format(format).toString();
    }

    public buildCurrentWeekData():void{
        let currentWeekStartDate = dayjs().day(1).locale(this.ts.getLanguage()).format('YYYY-MM-DD')
        let currentWeekEndDate = dayjs().day(7).locale(this.ts.getLanguage()).format('YYYY-MM-DD')
        this.currentWeekData = this.controlCalendarData.filter(_x => {
            return _x.date >= currentWeekStartDate && _x.date <= currentWeekEndDate
        })
    }

    private getControlCalendar(): void{
        this.controlCalendarService.getControlCalendar(this.config.attr.url_prefix)
            .subscribe((controlCalendar) => {
                this.controlCalendarData = controlCalendar;
                this.buildCurrentWeekData();
                this.isLoading = false;
            })
            .add(() => {});
    }

    private openControlCalendarPopup(calendar:IControlCalendar[]):void{
        this.globalAlertService.addPopupControlCalendar(
            calendar,
            (buttonCode: any, response: any) => {
            }, () => {}
        );
    }
}
