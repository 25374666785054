/**
 * Created by Christiaan on 13/03/2017.
 */
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild} from '@angular/core';
import {GlobalModel} from "../../shared/services/state/global.model";
import {MapTableComponent} from "../../shared/components/map-table/map-table.component";
import {ActivatedRoute, Router} from "@angular/router";
import {MapTableService} from "../../shared/components/map-table/map-table.service";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";
import {TreeComponent} from "../../shared/components/commonUI/tree/tree.component";
import {HTTPService} from "../../shared/services/http/http.service";
import {FormDataService} from "../../shared/components/form/services/form-data.service";
import {AuthorizationService} from "../../shared/services/authorization/authorization.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {TreeMapFormComponent} from "../../shared/components/tree-map-form/tree-map-form.component";
import {SectionFormComponent} from "../../shared/components/form/components/section-form.component";
import {DimmingService} from "./dimming/dimming.service";
import {StorageService} from "../../shared/services/storage/storage.service";
import {TableOptionsService} from "../../shared/components/table/table-options.service";
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {ButtonCode} from '../../wrapper/global-alert/global-popup';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {RequestFailure} from '../../shared/services/http/request-failure';
import {LuminizerRoutes} from '../../shared/interfaces/routes';
import {GlobalStateService} from "../../shared/services/state/global-state.service";
import {LoggerService} from "../../shared/services/logger/logger.service";
import {CreateNodeObject} from '../../shared/components/commonUI/tree/tree-node-interface';

@Component ({
    selector: 'control-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../../shared/components/tree-map-form/tree-map-form.component.html'
})
export class ControlComponent extends TreeMapFormComponent implements AfterViewInit {

    public static readonly FORM_URL: string = "/control/view";
    public static readonly MODULE_PATH_CONTROL: string = "control";
    public static readonly BATCHUPDATE_URL: string = 'control-form/batch-update/get';

    @ViewChild('mapTableComponent', {static: false}) mapTableComponent: MapTableComponent;
    @ViewChild('formComponent', {static: false}) formComponent: SectionFormComponent;
    @ViewChild('dragContainer', {static: false}) dragContainer: any;
    
    constructor(
        public model: GlobalModel,
        protected mapTableService: MapTableService,
        protected elementRef: ElementRef,
        protected cd: ChangeDetectorRef,
        protected ngZone: NgZone,
        protected activatedRoute: ActivatedRoute,
        private router: Router,
        protected globalAlertService:GlobalAlertService,
        protected httpService:HTTPService,
        protected formDataService:FormDataService,
        protected storage:StorageService,
        public auth:AuthorizationService,
        public ts:TranslateService,
        private dimmingService:DimmingService,
        protected tableOptionsService:TableOptionsService,
        protected areaalService: AreaalService,
        protected globalStateService: GlobalStateService,
        protected logger:LoggerService
    ) {
        super(cd, activatedRoute, httpService, router, model, mapTableService, storage, tableOptionsService, auth, formDataService, ngZone, activatedRoute, globalAlertService, areaalService, globalStateService, ts, logger);

        this.setTreeMapFormSettings(
            'control_1',
            'control_2',
            ['NIET_GEBRUIKT_IN_DEZE_MODULE'], // Meerdere types door elkaar afhankelijk van de tree
            ControlComponent.MODULE_PATH_CONTROL,
            [],
            ControlComponent.FORM_URL,
            ControlComponent.BATCHUPDATE_URL,
            FormDataService.FORM_URL_CONTROL,
            this.model.controlSelectedItems,
            this.model.controlFormData,
            this.model.controlFormCollapsed,
            this.model.controlTreeCollapsed,
            this.model.controlMapItems,
            this.model.controlAutoLoadedMapItems,
            this.model.controlAutoLoadedTableItems,
            this.model.controlTableItems,
            this.model.controlFilterString,
            this.model.controlTree,
            {
                useCustomIconColors: true,
                allowCreateMarker: this.auth.allowCreateMapItem(),
                allowMarkerDrag: this.auth.allowMoveMapItem(),
            }
        );
    
        this.expectTreeNodeActions = true;
        
        this.subscriptions.push(this.model.mobileMode.subscribe(mobileMode => {
            this._treeSettings.hideActionIcon = !mobileMode;
        }));
    }

    ngAfterViewInit():void
    {
        this.viewInit();
    }

    // Overwrite super class
    protected handleFormDataChanged(json: any):void
    {
        super.handleFormDataChanged(json, ["control_dimgroep", "control_systemgroup", "control_override"])
    }

    // Overwrite super class
    protected handleRouteChanged(params:any):void
    {
        this.timesParamsReceived++;

        //When no ID is used
        if (params && params.dimgroupid && params.dimgroupid == 0 &&
            params.ids && params.ids == 0 &&
            params.systemgroupid && params.systemgroupid == 0 &&
            params.overrideId && params.overrideId == 0
        ){

            //When this route-change is just to fool the router so you can execute the same link twice, do nothing now
            if (this.httpService.getQueryParam("origin") == FormDataService.ORIGIN_ROUTER_REFRESH){
                return;
            }

            //Klap het formulier in bij 0/0 url
            this.logger.log("[ControlComponent] " + "No id's, collapse form");
            if (this.timesParamsReceived > 1){
                this.formCollapsedObservable.next(true);
            }
        }

        if (params && params.overrideId) {

            //Convert string to array of objects
            let ids: any[] = params.overrideId.split(",");
            let idsAsObject: any[] = [];
            ids.forEach((id: any) => {
                idsAsObject.push({override: parseInt(id)});
            });

            if (idsAsObject.length == 1 && idsAsObject[0].override == 0) {
                //Skip loading id 0
                //return;
            } else {
                //One or more objects. Request the form for the first selected treenode and select all treenodes and do list calls

                //Form doesnt corresponds with selected items any more, so clear selected items
                this.selectedItemsObservable.next([]);
                this.getOverrideForm(idsAsObject[0].override);

                if (this.timesParamsReceived == 1 || this.httpService.getQueryParam("origin") != FormDataService.ORIGIN_TREE) {
                    //Expand the tree
                    this.logger.log("[ControlComponent] " + "EXPAND AND LOAD TREE for override");

                    //Only set the state to form when a link is clicked that links directly to the dimgroepform
                    this.setActiveState(this.STATE_FORM);

                    //When using the direct link in the tree, don't load the map/table items
                    if (this.httpService.getQueryParam("origin") != FormDataService.ORIGIN_TREE_NODE_ACTION){
                        this.treeComponent.switchToTree(TreeComponent.TREE_CODE_OVERRIDE_FOLDERS, this.model.controlTree.value.id, () => {
                            this.treeComponent.expandTreeForReferences(idsAsObject);
                        });
                    }
                } else {
                    this.logger.log("[ControlComponent] " + "DONT EXPAND AND LOAD TREE");
                }
            }
        }

        //When there is an id given with the url, load that form
        if (params && params.systemgroupid) {//} && params.id != 0) {

            //Convert string to array of objects
            let ids: any[] = params.systemgroupid.split(",");
            let idsAsObject: any[] = [];
            ids.forEach((id: any) => {
                idsAsObject.push({systemGroup: parseInt(id)});
            });

            if (idsAsObject.length == 1 && idsAsObject[0].systemGroup == 0) {
                //Skip loading id 0
                //return;
            } else {
                //One or more objects. Request the form for the first selected treenode and select all treenodes and do list calls

                //Form doesnt corresponds with selected items any more, so clear selected items
                this.selectedItemsObservable.next([]);
                this.getSystemGroupForm(idsAsObject[0].systemGroup);

                if (this.timesParamsReceived == 1 || this.httpService.getQueryParam("origin") != FormDataService.ORIGIN_TREE) {
                    //Expand the tree
                    this.logger.log("[ControlComponent] " + "EXPAND AND LOAD TREE for systemgroup");

                    //Only set the state to form when a link is clicked that links directly to the dimgroepform
                    this.setActiveState(this.STATE_FORM);

                    //When using the direct link in the tree, don't load the map/table items
                    if (this.httpService.getQueryParam("origin") != FormDataService.ORIGIN_TREE_NODE_ACTION){
                        this.treeComponent.switchToTree(TreeComponent.TREE_CODE_SYSTEM, this.model.controlTree.value.id, () => {
                            this.treeComponent.expandTreeForReferences(idsAsObject);
                        });
                    }
                } else {
                    this.logger.log("[ControlComponent] " + "DONT EXPAND AND LOAD TREE");
                }
            }
        }

        //When there is an id given with the url, load that form
        if (params && params.dimgroupid) {//} && params.id != 0) {

            //Convert string to array of objects
            let ids: any[] = params.dimgroupid.split(",");
            let idsAsObject: any[] = [];
            ids.forEach((id: any) => {
                idsAsObject.push({dimGroup: parseInt(id)});
            });

            if (idsAsObject.length == 1 && idsAsObject[0].dimGroup == 0) {
                //Skip loading id 0
                //return;
            } else {
                //One or more objects. Request the form for the first selected treenode and select all treenodes and do list calls

                //Form doesnt corresponds with selected items any more, so clear selected items
                this.selectedItemsObservable.next([]);
                this.getDimGroupForm(idsAsObject[0].dimGroup);

                if (this.timesParamsReceived == 1 || this.httpService.getQueryParam("origin") != FormDataService.ORIGIN_TREE) {
                    //Expand the tree
                    this.logger.log("[ControlComponent] " + "EXPAND AND LOAD TREE");

                    //Only set the state to form when a link is clicked that links directly to the dimgroepform
                    this.setActiveState(this.STATE_FORM);

                    //When using the direct link in the tree, don't load the map/table items
                    if (this.httpService.getQueryParam("origin") != FormDataService.ORIGIN_TREE_NODE_ACTION){
                        this.treeComponent.switchToTree(TreeComponent.TREE_CODE_CONTROL, this.model.controlTree.value.id, () => {
                            this.treeComponent.expandTreeForReferences(idsAsObject);
                        });
                    }
                } else {
                    this.logger.log("[ControlComponent] " + "DONT EXPAND AND LOAD TREE");
                }
            }
        }

        this.handleRouteDefaultIds(params);
    }

    private getDimGroupForm(id:number){
        this.formDataService.getDimGroupFormDataForId(this.MODULE_FORM_DATA_URL, this.formDataObservable, id,
            () => {},
            (value) => {
                this.logger.log('[TreeMapFormComponent] could not find baseobject in area, trigger area switch: ', value);
                this.logger.log('[TreeMapFormComponent] called url: ', this._router.url);
                const calledUrl = this._router.url;
                if (value[0].areaId) {
                    this.areaalService.loadAreaal(value[0].areaId, true,
                        (json: any) => {
                            this.logger.log('[TreeMapFormComponent] success area switch (deep link)', json);
                            setTimeout(() => {
                                this._router.navigateByUrl(calledUrl);
                            }, 100)
                        },
                        (failure: RequestFailure) => {
                            this.logger.log('[TreeMapFormComponent] failed area switch (deep link)', failure);
                            this.globalAlertService.addAlertFailure(failure);
                        },
                        LuminizerRoutes.INITIAL_PAGE,
                    );
                }
            },
            () => {}
        )
    }
    private getOverrideForm(id:number){
        this.formDataService.getOverrideFormDataForId(this.MODULE_FORM_DATA_URL, this.formDataObservable, id,
            () => {},
            (value) => {
                this.logger.log('[TreeMapFormComponent] could not find baseobject in area, trigger area switch: ', value);
                this.logger.log('[TreeMapFormComponent] called url: ', this._router.url);
                const calledUrl = this._router.url;
                if (value[0].areaId) {
                    this.areaalService.loadAreaal(value[0].areaId, true,
                        (json: any) => {
                            this.logger.log('[TreeMapFormComponent] success area switch (deep link)', json);
                            setTimeout(() => {
                                this._router.navigateByUrl(calledUrl);
                            }, 100)
                        },
                        (failure: RequestFailure) => {
                            this.logger.log('[TreeMapFormComponent] failed area switch (deep link)', failure);
                            this.globalAlertService.addAlertFailure(failure);
                        },
                        LuminizerRoutes.INITIAL_PAGE,
                    );
                }
            },
            () => {}
        )
    }
    private getSystemGroupForm(id:number){
        this.formDataService.getSystemGroupFormDataForId(this.MODULE_FORM_DATA_URL, this.formDataObservable, id,
            () => {},
            (value) => {
                this.logger.log('[TreeMapFormComponent] could not find baseobject in area, trigger area switch: ', value);
                this.logger.log('[TreeMapFormComponent] called url: ', this._router.url);
                const calledUrl = this._router.url;
                if (value[0].areaId) {
                    this.areaalService.loadAreaal(value[0].areaId, true,
                        (json: any) => {
                            this.logger.log('[TreeMapFormComponent] success area switch (deep link)', json);
                            setTimeout(() => {
                                this._router.navigateByUrl(calledUrl);
                            }, 100)
                        },
                        (failure: RequestFailure) => {
                            this.logger.log('[TreeMapFormComponent] failed area switch (deep link)', failure);
                            this.globalAlertService.addAlertFailure(failure);
                        },
                        LuminizerRoutes.INITIAL_PAGE,
                    );
                }
            },
            () => {}
        )
    }

    handleSwitchTree():void
    {
        this.clearVisibleItems();
    }

    // Overwrite super class
    protected gotoActionUrl(url:string):void
    {
        //Go to tree node action url
        this.router.navigate([this.MODULE_FORM_URL, '0', '0', '0','0'] , {queryParams: {origin:FormDataService.ORIGIN_ROUTER_REFRESH}});
        setTimeout(() => {
            this.router.navigateByUrl(url + "?origin=" + FormDataService.ORIGIN_TREE_NODE_ACTION);
        });
    }

    handleTableMapSelectionChange(selectedItems: any)
    {
        super.handleTableMapSelectionChange(selectedItems, (params: any) => {
            this._router.navigate([this.MODULE_FORM_URL, params, '0', '0', '0'], {queryParamsHandling: "merge"});
        });
    }

    protected tryGetBatchUpdateForm(appendURL: string = null)
    {
        if (this.selectedItemsObservable.value){
            appendURL = this.selectedItemsObservable.value[0].baseObjectId.toString();
        }

        super.tryGetBatchUpdateForm(appendURL);
    }

    // Overwrites super class
    public clearSelectedItems():void
    {
        super.clearSelectedItems([this.MODULE_FORM_URL, 0, 0, 0, 0], true);
    }

    // Overwrites super class
    protected clearVisibleItems():void
    {
        super.clearVisibleItems([this.MODULE_FORM_URL, 0, 0, 0, 0]);
    }

    // Overwrite super class
    protected setModuleObjectType():void
    {
        let objectTypes:string[] = [];
        if (this.model.controlTree.value.code == TreeComponent.TREE_CODE_SYSTEM ||
            this.model.controlTree.value.code == TreeComponent.TREE_CODE_OVERRIDE_FOLDERS) {
            objectTypes.push('olc');
        } else if (this.model.controlTree.value.code == TreeComponent.TREE_CODE_CONTROL ||
            this.model.controlTree.value.code == TreeComponent.TREE_CODE_CONTROL_GATEWAYS ||
            this.model.controlTree.value.code == TreeComponent.TREE_CODE_CONTROL_FOLDERS ||
            this.model.controlTree.value.code == TreeComponent.TREE_CODE_CONTROL_DIMGROEP_FOLDERS
        ){
            objectTypes.push('armatuur');
        }

        this.MODULE_OBJECT_TYPES = objectTypes;
    }

    handleComponentEvent(eventData: any):void
    {
        switch (eventData.event) {
            case FormEvent.SAVE_SUCCESS:
            case FormEvent.BATCH_SAVE_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;
            case FormEvent.EXCEPTION_CREATE_SUCCESS:
            case FormEvent.CLOSE_SCHEME_EDITOR:
            case FormEvent.CLOSE_CONTROL_PROGRAM_EDIT:
                this.getDimGroupForm(eventData.data.referenceId);
                break;
            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                const polygonSelect = this.mapTableComponent.mapComponent.polygonSelect
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect()
                }
                this.clearSelectedItems();
                break;
            case FormEvent.ADD_SUCCESS:
                this.reloadTree();
                break;
            case FormEvent.DIMGROUP_SAVE_SUCCESS:
            case FormEvent.SYSTEMGROUP_SAVE_SUCCESS:
                this.reloadTree(true);
                break;
            case FormEvent.SET_DIMGROUP_OVERRIDE_SUCCESS:
                this.reloadTree();
                this.getDimGroupForm(eventData.data.referenceId);
                break;
            case FormEvent.DIMGROUP_DELETE_SUCCESS:
            case FormEvent.SYSTEMGROUP_DELETE_SUCCESS:
                this.reloadTree(false, false, false,() => {
                    this.clearVisibleItems();
                });
                break;
            case FormEvent.SET_DIMGROUP_OVERRIDE:
                this.globalAlertService.addPopupSetOverride((buttonCode:string, restData:any) => {
                    if (buttonCode == ButtonCode.DELETE){
                        this.dimmingService.releaseDimGroupOverride(eventData.data.attr.dimGroupId, () => {
                            this.handleComponentEvent({event: FormEvent.SET_DIMGROUP_OVERRIDE_SUCCESS, data: {referenceId: eventData.data.attr.dimGroupId}});
                        }, () => {}, () => {});
                    }else{
                        this.dimmingService.setDimGroupOverride(eventData.data.attr.dimGroupId, restData.overrideLevel,
                            () => {
                            this.handleComponentEvent({event: FormEvent.SET_DIMGROUP_OVERRIDE_SUCCESS, data: {referenceId: eventData.data.attr.dimGroupId}});
                            }, () => {}, () => {});
                    }
                },() => {});
                break;
        }
    }

    public handleTreeNodeCreate(data: CreateNodeObject): void {
        this.globalAlertService.addPopupCreateDropdown(
            this.ts.translate('add.title', [data.parentNode]),
            '',
            data.creationHref,
            (buttonCode: any, response: any) => {
                this.reloadTree(false, false, false,() => {
                    if (response.type && response.type == 'dimgroup') {
                        this.router.navigate([`${this.MODULE_FORM_URL}/0/${response.id}/0/0`], {queryParams: {state: 2, origin: null}});
                    } else {
                        this.router.navigate([`${this.MODULE_FORM_URL}/0/0/0/${response.id}`], {queryParams: {state: 2, origin: null}});
                    }
                    });
            },
            () => {
            }
        );
    }
}
