/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild
} from '@angular/core';

import {GlobalPopup} from "../global-popup";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {GlobalAlertService} from '../global-alert.service';
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-edit-dropdown-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [disableButtons]="formDisabled" [globalPopup]="globalPopup" [title]="globalPopup.title" [description]="'Nieuwe waarde:' | translate" (onClose)="closePopup($event)">
            <input initialFocus #dropDownText [value]="globalPopup.data.oldValue" class="" title="{{globalPopup.label}}" type="text">
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupEditDropdownComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    @ViewChild("dropDownText", {static: false}) dropDownText:any = "";

    public formDisabled:boolean = false;

    constructor(private formDataService:FormDataService, protected cd:ChangeDetectorRef, protected tooltipService:TooltipService, protected elementRef:ElementRef, private renderer:Renderer2, protected ts:TranslateService, protected globalAlertService:GlobalAlertService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit(){
        this.submitButton = this.getSubmitButton();
    }

    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, false);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        this.tooltipService.destroyToolTip(this.dropDownText);

        //callback for submit button comes from this form, not from outside this component
        if (button == this.submitButton){
            this.submitValue();
        }else{
            //Hide alert and perform callback of button
            this.onPopupAction.emit({event:event, alert:alert, button:button});
        }

        //this.onPopupAction.emit({event:event, alert:alert, button:button, data:{input:this.dropDownText.nativeElement.value}});
    }

    private submitValue()
    {
        if (!this.formDisabled){

            let input:string = this.dropDownText.nativeElement.value;
            this.logger.log("[GlobalPopupCreateDropdownComponent] " + "Try to submit value |" + input + "|");

            if (input == ""){
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.dropDownText,
                    this.ts.translate("popupinput.validation"), //JSON.stringify(this.validationConstraints),
                    TooltipService.PLACEMENT_TOP, TooltipService.TOOLTIP_MODE_MANUAL, "", true
                );
            }else {
                this.formDisabled = true;

                //Execute call
                this.formDataService.editDropDown(this.globalPopup.data.path, this.dropDownText.nativeElement.value, (response: any) => {
                    this.formDisabled = false;

                    //Hide alert and perform success callback via submit button
                    this.onPopupAction.emit({
                        event: null,
                        alert: this.globalPopup,
                        button: this.submitButton,
                        data: response
                    });

                    this.cd.detectChanges()

                }, (failure: RequestFailure) => {

                    this.tooltipService.createAndShowTooltip(
                        this.renderer,
                        this.dropDownText,
                        this.ts.translate("Geef een geldige en unieke waarde op"), //JSON.stringify(this.validationConstraints),
                        TooltipService.PLACEMENT_TOP, TooltipService.TOOLTIP_MODE_MANUAL, "", true
                    );

                    this.formDisabled = false;
                    this.cd.detectChanges()
                }, () => {
                    this.tooltipService.createAndShowTooltip(
                        this.renderer,
                        this.dropDownText,
                        this.ts.translate("Er ging iets mis met het aanmaken. Item is niet aangemaakt."), //JSON.stringify(this.validationConstraints),
                        TooltipService.PLACEMENT_TOP, TooltipService.TOOLTIP_MODE_MANUAL, "", true
                    );

                    this.formDisabled = false;
                    this.cd.detectChanges()
                });
            }
        }
    }
}
