/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import {GlobalModel} from '../../services/state/global.model';
import {MapItem} from '../map/map-item/map-item';
import {
    BoundsChangedDuringAutoLoadEvent,
    ClickMarkerEvent,
    DragMarkerEvent,
    InfoWindowDataInterface,
    LongPressEvent,
    LuminizerMapMarker,
    MouseOverMarkerEvent,
    SelectMarkersEvent,
} from '../map/map-core.interface';
import {MapCoreComponent} from '../map/map-core.component';
import {HTTPService} from '../../services/http/http.service';
import {MapTableService} from './map-table.service';
import {ChangeableComponent} from '../changeable/changeable.component';
import {StorageService} from '../../services/storage/storage.service';
import Utils from '../../utils/utils';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TranslateService} from '../../services/translate/translate.service';
import {TableOptions, TableOptionsField, TableOptionsSet} from '../table/tableColumnSelector/table-options';
import {TableOptionsService} from '../table/table-options.service';
import {TreeMapFormComponent} from '../tree-map-form/tree-map-form.component';
import {GlobalEvent} from '../../interfaces/global-event';
import {AuthorizationService} from '../../services/authorization/authorization.service';
import {TreeService} from '../commonUI/tree/tree.service';
import {
    ExportType,
    HeaderData,
    RowData,
    TableSortDirection,
    TableSorting
} from '../table/shared/baseTable/baseTable.interface';
import {GeoDataManagementTableComponent} from '../table/geoDataManagementTable/geoDataManagementTable.component';
import {Filter, FilterCommand} from '../table/filterableTable/filter.interface';
import {
    GeoDataManagementTableData,
    GeoDataManagementTableRowData
} from '../table/geoDataManagementTable/geoDataManagementTable.interface';
import {
    FilterPopupData,
    MapTableTab,
    TabInterface,
    TableCell,
    TableColumn,
    TableRow,
    TmpMapDataInterface
} from './map-table.interface';
import {BaseTableService} from '../table/shared/baseTable/baseTableService';
import {AppSettings} from 'src/app/app.settings';
import {MenuDropdownComponent} from '../commonUI/dropdown/menu-dropdown/menu-dropdown.component';
import {
    IconLegendComponent
} from '../../../wrapper/global-alert/alerts/global-popup-map-legend/icon-legend/icon-legend.component';
import {LoggerService} from "../../services/logger/logger.service";

@Component({
    selector: 'map-table-component',
    templateUrl: './map-table.component.html'
})
export class MapTableComponent extends ChangeableComponent implements OnDestroy, AfterViewInit {
    @ViewChild('mapComponent', {static: false}) mapComponent: MapCoreComponent;
    @ViewChild('filterInput', {static: false}) filterInput: ElementRef<HTMLInputElement>;
    @ViewChild('menu', {static: false}) menu: MenuDropdownComponent;
    @ViewChild('subMenuExport', {static: false}) subMenuExport: ElementRef<HTMLDivElement>;
    @ViewChild('subMenuExportSelection', {static: false}) subMenuExportSelection: ElementRef<HTMLDivElement>;
    @ViewChild('geoDataManagementTableComponent', {static: false}) geoDataManagementTable: GeoDataManagementTableComponent;
    @ViewChild('topBar', {static: false}) topBar: ElementRef<HTMLDivElement>;
    public readonly TOP_BAR_BREAK_POINT = 600;
    
    @Output() onSelectionChange: EventEmitter<{ baseObjectId: number | string }[]> = new EventEmitter();
    @Output() onMapLongPress: EventEmitter<LongPressEvent> = new EventEmitter();
    @Output() onAutoLoadRequest: EventEmitter<BoundsChangedDuringAutoLoadEvent> = new EventEmitter();
    @Output() onTableFilterChange: EventEmitter<{ tableOptionsChanged?: boolean, reset?: boolean }> = new EventEmitter();
    
    @Input() public allowSingleSelect: boolean = true;
    @Input() public allowMultiSelect: boolean = false;
    @Input() public allowCreateMarker: boolean = false;
    @Input() public allowMixedView: boolean = true;
    @Input() public allowAutoLoadMarkers: boolean = true;
    @Input() public allowMarkerDrag: boolean = false;
    @Input() public useCustomIconColors: boolean = false;
    @Input() public iconSet: 'default'|'mechanic'|'smartdevices' = 'default';
    @Input() public isBasicMode: boolean = false;
    @Input() public tableOptions: TableOptions = new TableOptions();
    @Input() public showMapTableLoaders: boolean = false;
    @Input() public hideMap: boolean = false;
    @Input() public showReportInfoText: boolean = false;
    @Input() public showSearchBar: boolean = false;
    @Input() public allowMultiLineInRow: boolean = false;
    public MAP_TABLE_TAB = MapTableTab;
    public selectedItems: { baseObjectId: number | string }[] = [];
    public showNoContentPopup: boolean = false;
    public _filterString: string = '';
    public mobileMode: boolean = false;
    public showBasicModeInfoText: boolean = false;
    public tableOptionSets: TableOptionsSet[] = [];
    public autoRefresh: boolean = false;
    public showSubMenuExport: boolean = false;
    public showSubMenuExportSelection: boolean = false;
    public lastRefreshTime: string = '';
    public isMasterData: boolean = false;
    public activeSmartSelection: string = '';
    public changedSelection: boolean = false;
    public geoDataTableData: GeoDataManagementTableData = {
        headers: [],
        rows: []
    };
    public readonly tabBarConfig: TabInterface[] = [
        {
            code: MapTableTab.TABLE, icon: 'reorder', active: false,
        },
        {
            code: MapTableTab.MAP, icon: 'photo', active: false,
        },
        {
            code: MapTableTab.MIXED, icon: 'art_track', active: false,
        },
    ];
    @Input() public filterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private activeTab: TabInterface;
    private subscriptions: Subscription[] = [];
    private previousFilters: TableOptionsField[] = [];
    private previousSorting: string;
    private tmpMapData: TmpMapDataInterface = null;
    private previouslyScrolledItem: number | string;

    public totalSelectedItems:number = 0;
    private indexForClustersTotal:number = -1;
    private indexForClustersCode:number = -1;

    
    public AppSettings = AppSettings;
    
    constructor(
        public httpService: HTTPService,
        private storage: StorageService,
        public model: GlobalModel,
        private ngZone: NgZone,
        protected elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        public mapTableService: MapTableService,
        private globalAlertService: GlobalAlertService,
        public ts: TranslateService,
        private tableOptionsService: TableOptionsService,
        public auth: AuthorizationService,
        private baseTableService: BaseTableService,
        protected logger:LoggerService
    ) {
        super(elementRef);
        
        //  Set default starting values, enable the map
        this.activeTab = this.tabBarConfig[1];
        this.activeTab.active = true;
        
        if (!this.auth.forceMapAsDefault()) {
            // Set stored starting values, if there are any
            this.storage.getStringValue(StorageService.KEY_SELECTED_MAP_TABLE_VIEW, (value: string) => {
                this.tabBarConfig.forEach((tabItem) => {
                    if (tabItem.code === value) {
                        this.activeTab = tabItem;
                        this.activeTab.active = true;
                    } else {
                        tabItem.active = false;
                    }
                });
            });
        }
        
        this.subscriptions.push(this.httpService.pendingCallPaths.subscribe(() => {
            // Refresh the loading state by checking the pending calls
            this.cd.markForCheck();
        }));
        
        this.subscriptions.push(this.model.mobileMode.subscribe((value: boolean) => {
            this.mobileMode = value;
        }));
        
        this.subscriptions.push(this.model.tableOptionsSets.subscribe((value) => {
            if (value) {
                this.tableOptionSets = value;
            }
        }));
        
        this.subscriptions.push(this.model.currentModule.subscribe((value) => {
            if (value === 'master-data') {
                this.isMasterData = true;
            }
        }));
        
        this.subscriptions.push(this.model.changedSmartSelection.subscribe((value) => {
            this.changedSelection = value;
        }));

        this.subscriptions.push(this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            switch (event.type) {
                case GlobalEvent.EVENT_MAP_CATEGORIZE_BY_COLOR_ICONS:
                    this.randomColorIcons(event.data.columnCode);
                    break;
                case GlobalEvent.EVENT_MAP_CUSTOM_LABEL_ICONS:
                    this.customLabelIcons(event.data.columnCode);
                    break;
            }
        }));
    }
    
    get AUTO_REFRESH_INTERVAL(): number {
        return TreeMapFormComponent.AUTO_REFRESH_INTERVAL / 1000;
    }
    
    ngAfterViewInit(): void {
        if (this.auth.enableAutoRefreshByDefault()) {
            this.autoRefresh = true;
        } else {
            this.storage.getBooleanValue(this.model.currentModule.value + '.' + StorageService.KEY_MODULE_AUTOREFRESH, (value: boolean) => {
                this.autoRefresh = value;
                if (this.autoRefresh) {
                    this.setRefreshTime();
                }
            });
        }
        
        this.showSubMenuExport = false;
        this.showSubMenuExportSelection = false;
        
        this.triggerAutoRefresh();
        
        if (this.isBasicMode) {
            let publicPortalWelcomeText: string = null;
            if (this.model.publicPortalWelcomeText !== null) {
                publicPortalWelcomeText = this.model.publicPortalWelcomeText.replace(/(?:\r\n|\r|\n)/g, '<br>');
            }
            this.globalAlertService.addPopup(
                this.ts.translate('publicportal.welcometitle'),
                publicPortalWelcomeText != null ?
                    publicPortalWelcomeText + this.ts.translate('publicportal.welcomelabel')
                    : this.ts.translate('publicportal.welcomelabeldefault', [this.model.currentAreaal.value.label]), [{
                    label: this.ts.translate('Doorgaan'),
                    code: 'OK',
                    isPrimary: true,
                }], () => {
                    // Popup closed
                    
                    setTimeout(() => {
                        this.showBasicModeInfoText = true;
                        this.cd.detectChanges();
                    }, 1000);
                    
                });
            
            // Always use map
            this.tabBarConfig.forEach((tabItem) => {
                if (tabItem.code === MapTableTab.MAP) {
                    this.activeTab = tabItem;
                    this.activeTab.active = true;
                } else {
                    tabItem.active = false;
                }
            });
        }
        
        //  When the map is hiden, switch to table
        if (this.hideMap) {
            for (let i = 0; i < this.tabBarConfig.length; i++) {
                this.tabBarConfig[i].active = false;
                if (this.tabBarConfig[i].code === MapTableTab.TABLE) {
                    this.activeTab.active = true;
                }
            }
        }
        
        this.subscriptions.push(this.filterString.subscribe((filter: string) => {
            this._filterString = filter;
        }));
        
        this.cd.detectChanges();
    }


    private mMinorIndexTravelled:number = 0;
    

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    
    public isRefreshing(): boolean {
        let isRefreshing: boolean = this.httpService.isPendingCallPath(
            [TreeService.GET_TREE_PATH, this.mapTableService.MAP_ITEM_PATH, this.mapTableService.TABLE_ITEMS_PATH]
        );
        
        if (isRefreshing && this.lastRefreshTime !== '') {
            this.setRefreshTime();
        }
        
        return isRefreshing;
    }
    
    public handleClickOutside(e: MouseEvent): void {
        if (e.target !== this.subMenuExport?.nativeElement) {
            this.showSubMenuExport = false;
        }
        if (e.target !== this.subMenuExportSelection?.nativeElement) {
            this.showSubMenuExportSelection = false;
        }
    }
    
    public handleSubMenuClick(e: MouseEvent, selectionOnly:boolean): void {
        e.stopImmediatePropagation();
        if(selectionOnly){
            this.showSubMenuExport = false;
            this.showSubMenuExportSelection = !this.showSubMenuExportSelection;
        } else {
            this.showSubMenuExportSelection = false;
            this.showSubMenuExport = !this.showSubMenuExport;
        }
        setTimeout(() => {
            this.menu.setMenuItemElementsArray();
        }, 100);
    }
    
    public getMapItemCountTitle(total: number, visible: number, selected: number): string {
        return this.ts.translate('selecteditems.tooltip', [total, visible, selected]);
    }
    
    public handleGlobalKeyDown(event: KeyboardEvent): void {
        // Skip if a popup is currently showing
        if (this.globalAlertService.isPopupActive()) {
            return;
        }
        
        // return when ctrl+a or d is pressed on an input. The input will handle the shortcut itself
        if (event.target && (
            (<HTMLInputElement>event.target).tagName === 'INPUT'
            || (<HTMLInputElement>event.target).tagName === 'TEXTAREA'
            || (<HTMLInputElement>event.target).tagName === 'SELECT'
        )) {
            this.logger.log('[MapTableComponent] ' + 'No select all applied, target is a form input');
            return;
        }
        
        // CTRL/CMD+A > select all
        if ((event.ctrlKey || event.metaKey) && event.keyCode === 65) {
            if (this.allowMultiSelect) {
                this.logger.log('[MapTableComponent] ' + 'CTRL+A, select all items');
                this.selectedItems = this.geoDataManagementTable.getVisibleRows().map(rowData => {
                    return {baseObjectId: rowData.uniqueId};
                });
                this.updateSelectedItems(false);
            }
            event.preventDefault();
        }
        
        // CRTL+D
        if ((event.ctrlKey || event.metaKey) && event.keyCode === 68) {
            this.logger.log('[MapTableComponent] ' + 'CTRL+D, deselect all items');
            this.selectedItems = [];
            this.updateSelectedItems();
            event.preventDefault();
        }
    }

    public randomColorIcons(columnCode: string) {
        let colIdx = -1
        for (let i = 0; i < this.geoDataTableData.headers.length; i++) {
            if (this.geoDataTableData.headers[i].code == columnCode) {
                colIdx = i;
                break;
            }
        }
        if (colIdx < 0) {
            return;
        }
        const iconMapping = {};
        const mapItemMap = {};
        this.mapComponent.mapCoreV2Component.mapLayerManagerService.layers.map(_layer => {
            if(_layer.getMapItems().length){
                _layer.getMapItems().map(_mapItem => {
                    mapItemMap[_mapItem.getId()] = _mapItem;
                })
            }
        })
        for (let i = 0; i < this.geoDataTableData.rows.length; i++) {
            const row = this.geoDataTableData.rows[i];
            const rowColVal = row.cells[colIdx].label;

            if (!iconMapping[rowColVal]){
                const randomColor = '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
                iconMapping[rowColVal] = this.mapComponent.mapIconGenerator.loadNewColorImage(randomColor, '#FFFFFF');
            }
            const mapItem = mapItemMap[row.mapItemId];
            if (mapItem && mapItem.googleMarker) {
                mapItem.googleMarker.setIcon(iconMapping[rowColVal]);
            }
        }
    }
    

    public customLabelIcons(columnCode: string) {
        let colIdx = -1
        for (let i = 0; i < this.geoDataTableData.headers.length; i++) {
            if (this.geoDataTableData.headers[i].code == columnCode) {
                colIdx = i;
                break;
            }
        }
        if (colIdx < 0) {
            return;
        }
        const bounds = this.mapComponent.mapCoreV2Component.map.getBounds()
        const mapItemMap = {};
        this.mapComponent.mapCoreV2Component.mapLayerManagerService.layers.map(_layer => {
            if(_layer.getMapItems().length){
                _layer.getMapItems().map(_mapItem => {
                    mapItemMap[_mapItem.getId()] = _mapItem;
                })
            }
        })
        for (let i = 0; i < this.geoDataTableData.rows.length; i++) {
            const row = this.geoDataTableData.rows[i];
            const rowColVal = row.cells[colIdx].label;

            const mapItem = mapItemMap[row.mapItemId];
            if (mapItem && mapItem.googleMarker && mapItem.isInBounds(bounds)) {
                const newIcon = this.mapComponent.mapIconGenerator.getIconWithLabel(mapItem.mapItemData, rowColVal);
                mapItem.googleMarker.setIcon(newIcon);
            }
        }
    }

    public initMap(): void {
        // Only 'init' the map when it is visible
        if (this.activeTab.code !== MapTableTab.TABLE) {
            setTimeout(() => {
                this.mapComponent.showMap();
            }, 100);
        }
    }

    public handleExportToCsv(selectionOnly:boolean = false): void {
        this.geoDataManagementTable.exportTable(ExportType.CSV,selectionOnly);
    }

    public handleExportToExcel(selectionOnly:boolean = false): void {
        this.geoDataManagementTable.exportTable(ExportType.XLSX,selectionOnly);
    }
    
    public handleCopyToClipboard(selectionOnly:boolean = false): void {
        this.geoDataManagementTable.exportTable(ExportType.CLIPBOARD,selectionOnly);
    }
    
    public closePublicPortalPopup(): void {
        this.showBasicModeInfoText = false;
    }
    
    public closeReportGenericPopup(): void {
        this.showReportInfoText = false;
    }
    
    public handleClickRefresh(): void {
        if (!this.isRefreshing()) {
            this.setRefreshTime();
            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MODULE_REFRESH, {invalidateCache: true}));
        }
    }
    
    public handleClickAutoRefresh(): void {
        // event.stopPropagation();
        this.setRefreshTime();
        this.autoRefresh = !this.autoRefresh;
        this.storage.setValue(this.model.currentModule.value + '.' + StorageService.KEY_MODULE_AUTOREFRESH, this.autoRefresh);
        this.triggerAutoRefresh();
    }
    
    public getAllBaseObjectIds(): (number | string)[] {
        return this.geoDataTableData ? this.geoDataTableData.rows.map(row => row.uniqueId) : [];
    }
    
    public openFilterPopup(columnId: string | number) {
        // Find matching column
        const selectedField: TableOptionsField = this.tableOptions.tableFields.find(field =>
            field.code === this.geoDataTableData.headers.find(header => header.columnId === columnId).code
        );
        
        if (!selectedField) {
            this.globalAlertService.addAlert(
                GlobalAlertService.ALERT_TITLE_WARNING,
                'Implementation error',
                'Column code does not match any of the table field codes'
            );
            return;
        }
        
        // Create a new popup with the fields
        this.globalAlertService.addPopupAddTableFilter(
            this.tableOptions.tableFields,
            selectedField,
            (event: string, data: FilterPopupData) => {
                // // Apply the new filter
                this.tableOptions.tableFields.forEach((field) => {
                    if (field.code === data.filter.field) {
                        if (data.deleteFilter) {
                            field.filter = null;
                        } else {
                            field.filter = {command: data.filter.command, values: data.filter.values};
                        }
                    }
                });
                const correspondingHeader: HeaderData = this.geoDataTableData.headers.find(header => header.code === data.filter.field);
                if (correspondingHeader) {
                    const filter: Filter = {
                        tableColumnId: correspondingHeader.columnId,
                        filterCommand: FilterCommand[data.filter.command],
                        filterValues: data.filter.values
                    };
                    
                    if (!data.deleteFilter) {
                        this.geoDataManagementTable.filterService.addActiveFilter(filter);
                    } else {
                        this.geoDataManagementTable.filterService.removeFilter(filter);
                    }
                }
                this.handleTableFilterChange();
            },
            () => {
            },
        );
    }
    
    public handleClickShowTableOptions(): void {
        const activeSorting = this.geoDataManagementTable.getActiveSorting();
        if (activeSorting && this.geoDataTableData.headers.some(header => header.columnId === activeSorting.columnId)) {
            const columnCode = this.geoDataTableData.headers.find(header => header.columnId === activeSorting.columnId).code;
            this.tableOptions.tableFields.forEach(tableField => {
               if (tableField.code === columnCode) {
                   tableField.sorting = true;
                   tableField.sortDirection = activeSorting.sortDirection;
               } else {
                   tableField.sorting = false;
                   tableField.sortDirection = -1;
               }
            });
        }
        this.globalAlertService.addPopupTableOptions(this.tableOptions, this.hideMap, (e, data) => {
            if (data.activeSmartSelectionName !== null) {
                this.activeSmartSelection = data.activeSmartSelectionName;
            }
            // trigger reload table or filter
            this.onTableFilterChange.emit(data);
            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_TABLE_OPTIONS_SETS_CHANGED, {}));
        }, () => {
            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_TABLE_OPTIONS_SETS_CHANGED, {}));
        });
    }
    
    public handleClickFilterWarning(): void {
        // Reset all types of filters
        this.changedSelection = false;
        this.activeSmartSelection = '';
        this.geoDataManagementTable.filterService.clearAllFilters();
        this.filterString.next('');
        this.resetTableOptions();
    }
    
    public handleClickTableOptionsReset(): void {
        this.model.changedSmartSelection.next(false);
        this.resetTableOptions();
        this.geoDataManagementTable.filterService.clearAllFilters();
    }
    
    //  Select a smart selection and try to load + apply it
    public handleClickTableOptionsSet(e: MouseEvent, tableOptionsSet: TableOptionsSet, additionalCallback: Function = null): void {
        this.tableOptionsService.getTableOptionSet(
            TreeMapFormComponent.moduleNameToPath(this.model.currentModule.value),
            Number(tableOptionsSet.id),
            (response) => {
                if (response && response.data && response.data.tableOptions) {
                    this.geoDataManagementTable.filterService.clearAllFilters();
                    // Apply the options, and trigger refreshes of the table data
                    this.tableOptionsService.mergeTableOptions(this.tableOptions, response.data.tableOptions);
                    this.onTableFilterChange.emit({tableOptionsChanged: true});
                    this.activeSmartSelection = response.name;
                    this.changedSelection = false;
                    this.cd.detectChanges();
                    if (additionalCallback) {
                        additionalCallback(response.name);
                    }
                }
            },
            () => {
            },
            () => {
            }
        );
    }
    
    public handleClickTab(tabItem: TabInterface): void {
        this.activateTab(tabItem);
        this.geoDataManagementTable.reapplyTruncation();
    }
    
    //  Specific function for switching to map to prevent circular references
    public switchToMap(): void {
        this.switchToTab(MapTableTab.MAP);
    }
    
    public hasQuickFilterActive(): boolean {
        return this.filterString.value !== '';
    }
    
    public getImage(icon: string, includeSize: boolean = true): string {
        return IconLegendComponent.getImage(icon, includeSize);
    }
    
    public hasSmartSelectionActive(): boolean {
        let result: boolean = false;
        if (this.tableOptions && this.tableOptions.tableFields) {
            for (let i = 0; i < this.tableOptions.tableFields.length; i++) {
                if (this.tableOptions.tableFields[i].filter) {
                    result = true;
                    break;
                }
            }
        }
        
        if (this.tableOptions.showArchived) {
            result = true;
        }
        
        return result;
    }
    
    public handleInfoWindowDataRequest(event: InfoWindowDataInterface): void {
        let mouseOverData: (TableColumn | TableCell)[][] = this.getMouseOverDataForIds(event.baseObjects);
        this.mapComponent.setInfoWindowData(mouseOverData, event.content);
    }
    
    public handleChangeFilterInput(event: Event): void {
        if (event && event.target) {
            const eventTarget = <HTMLInputElement>event.target;
            
            // Deselect textinput on mobile devices to hide keyboard
            if (this.mobileMode) {
                this.filterInput.nativeElement.blur();
                
                // The screen is resized, because of the keyboard retracting on mobile devices, recalculate zoom
                // TODO: als het keyboard altijd het scherm omhoog blijft duwen,
                //  is resize-event misschien een mooiere manier om de markers te refitten.
                //  Als we het keyboard over de site heen kunnen laten vallen is dit overbodig.
                //  Voor nu een prima fix.
                setTimeout(() => {
                    this.mapComponent.zoomToFitAllMarkers();
                }, 500);
            }
            
            // Set model
            this.filterString.next(eventTarget.value);
            
            // Filter items. Prevent auto zooming when you changed the filter in autoload mode
            this.filterItems(eventTarget.value, !this.mapComponent.autoLoadMarkers);
        }
    }
    
    public filterItems(filterString: string, zoomOnMarkers: boolean): void {
        let hiddenAndVisibleItems: { hiddenItems: (number | string)[], visibleItems: (number | string)[] };
        // Filter items on table, user matches to filter on map (via id)
        // When replacing the markers, remember if the map is currently visible.
        // If not, the map zoomtofit function needs to be queued, and executed on opening of the map
        this.previousFilters.forEach(field => {
            this.geoDataManagementTable.filterService.removeFilter({
                tableColumnId: field.columnId,
                filterValues: [],
                filterCommand: null
            });
        });
        
        const tableOptionsFilterFields: TableOptionsField[] = this.tableOptions.tableFields.filter(field => field.filter)
        .filter(field => this.geoDataTableData.headers.some(header => header.code === field.code));
        
        this.previousFilters = tableOptionsFilterFields.map(field => {
            field.columnId = this.geoDataTableData.headers.find(header => header.code === field.code).columnId;
            return field;
        });
        if (tableOptionsFilterFields.length > 0) {
            tableOptionsFilterFields.forEach(field => {
                let filterValues: string[] = field.filter.values;
                if (
                    filterValues.length === 1 &&
                    (FilterCommand[field.filter.command] === FilterCommand.NOT_IN_SET
                        || FilterCommand[field.filter.command] === FilterCommand.IN_SET
                        || FilterCommand[field.filter.command] === FilterCommand.NOT_BETWEEN
                        || FilterCommand[field.filter.command] === FilterCommand.BETWEEN)
                ) {
                    filterValues = filterValues[0].split(',').map(value => value.trim());
                }
                this.geoDataManagementTable.filterService.addActiveFilter({
                    tableColumnId: this.geoDataTableData.headers.find(header => header.code === field.code).columnId,
                    filterValues: [...filterValues],
                    filterCommand: FilterCommand[field.filter.command]
                });
            });
        } else {
            this.geoDataManagementTable.filterService.clearAllFilters();
        }
        
        this.geoDataManagementTable.filterService.addActiveFilter({
            filterValues: [filterString],
            filterCommand: FilterCommand.CONTAINS
        });
        
        this.geoDataManagementTable.applyFilters();
        
        // Give the table items some time to refresh the view. Then start with the map items
        setTimeout(() => {
            hiddenAndVisibleItems = {
                hiddenItems: this.geoDataManagementTable.getHiddenRows().map(row => row.uniqueId),
                visibleItems: this.geoDataManagementTable.getVisibleRows().map(row => row.uniqueId)
            };
            this.cd.detectChanges();
            this.mapComponent.filterItemsLocal(
                hiddenAndVisibleItems.hiddenItems,
                hiddenAndVisibleItems.visibleItems,
                this.activeTab.code === MapTableTab.TABLE,
                zoomOnMarkers
            );
        }, 200);
    }
    
    public isFilterActive(): boolean {
        // Return true if a quick filter is applied or a smart selection is active
        return (this.hasQuickFilterActive() || this.hasSmartSelectionActive());
    }
    
    public removeBaseObjectsOnMap(baseObjectIds: (number | string)[]): void {
        this.mapComponent.removeMarkersByBaseObjectId(baseObjectIds);
    }
    
    public removeBaseObjects(baseObjectIds: (number | string)[]): void {
        this.mapComponent.removeMarkersByBaseObjectId(baseObjectIds);
        baseObjectIds.forEach(baseObjectId => {
            let rowIndex = this.geoDataTableData.rows.findIndex(row => String(row.uniqueId) === String(baseObjectId));
            this.geoDataTableData.rows.splice(rowIndex, 1);
        });
    }
    
    public appendMapItems(mapItems: MapItem[]): void {
        this.mapComponent.appendMarkers(mapItems);
        this.mapComponent.updateSelectedItems(this.selectedItems);
    }
    
    public updateMapItems(mapItems: MapItem[], newData: boolean, mapHidden: boolean = false, zoomOnMarkers: boolean = true): void {
        if (this.activeTab.code === MapTableTab.TABLE) {
            this.tmpMapData = {
                mapItems: mapItems,
                newData: newData,
                mapHidden: mapHidden,
                zoomOnMarkers: zoomOnMarkers
            };
        } else {
            this.mapComponent.replaceMarkers(
                mapItems,
                this.activeTab.code === MapTableTab.TABLE || mapHidden,
                false,
                zoomOnMarkers
            );
            this.mapComponent.updateSelectedItems(this.selectedItems);
        }
    }

    public updateTableItems(
        tableData: (TableRow | TableColumn[])[],
        sorting: { [key: string]: string }
    ): void {
        if (tableData) {
            this.updateGeoDataManagementTable(tableData, sorting);
        }
    }
    
    public appendTableItems(tableData: (TableRow | TableColumn[])[]): void {
        if (tableData) {
            this.updateGeoDataManagementTable(tableData, null, true);
        }
    }
    
    public handleBoundsChanged(event: BoundsChangedDuringAutoLoadEvent): void {
        this.onAutoLoadRequest.emit(event);
        this.cd.detectChanges();
    }
    
    public handleMouseOverTableRow(mouseOveredRow: GeoDataManagementTableRowData): void {
        if (this.activeTab.code === MapTableTab.MIXED && mouseOveredRow && mouseOveredRow.mapItemId) {
            const mapItemId: string = String(mouseOveredRow.mapItemId);
            this.mapComponent.highlightMarkerByMapItemId(mapItemId);
        }
    }
    
    public handleMouseOverMarker(clickData: MouseOverMarkerEvent): void {
        if (this.activeTab.code === MapTableTab.MIXED) {
            if (this.geoDataTableData.rows.some(row => row.mapItemId == clickData.marker.dataRef.id)) {
                const targetBaseObjectId: string | number = this.geoDataTableData.rows.find(
                    row => row.mapItemId == clickData.marker.dataRef.id
                ).uniqueId;
                this.geoDataManagementTable.highlightRowByUniqueId(targetBaseObjectId);
            }
        }
    }
    
    public handleClickTableRow(clickData: GeoDataManagementTableRowData[]): void {
        this.selectedItems = clickData.filter(row => row !== undefined).map(rowData => {
            return {baseObjectId: rowData.uniqueId};
        });
        this.updateSelectedItems(false);
    }
    
    // When dragging on map with shift
    public handleSelectMarkers(clickData: SelectMarkersEvent): void {
        if (!clickData.cmdKeyPressed) {
            // Replace selected items with all markers
            this.selectedItems = <any>clickData.markers;
        } else {
            // Add all markers to selected items
            // TODO: hoe is deze performance? het ligt heel erg aan welke van de twee sets de grootste is
            let markerFound: boolean;
            clickData.markers.map(_marker => {
                markerFound = false;
                this.selectedItems.map(_selectedMarker => {
                    if (!markerFound && (_marker as LuminizerMapMarker).baseObjectId === _selectedMarker.baseObjectId) {
                        markerFound = true;
                    }
                })
                if (!markerFound) {
                    this.selectedItems.push(_marker);
                }
            })
        }

        // Update view
        this.updateSelectedItems();
    }
    
    public handleClickMarker(clickData: ClickMarkerEvent): void {
        this.logger.log('[MapTableComponent] ' + 'clickData.markers: ', clickData.markers);
        
        const clickedMarkers: MapItem[] = [];
        
        clickData.markers.forEach((marker) => {
            clickedMarkers.push(marker.dataRef);
        });
        
        this.handleSelectMarker(clickedMarkers, clickData.cmdKeyPressed, clickData.byPopUp);
    }

    private getTotalItemsForClustersIndex():{totalIndex:number,clusterIndex:number}{
        if(this.indexForClustersTotal == -1 || this.indexForClustersCode == -1){
            this.geoDataTableData.headers.map((_x, index) => {
                if (_x.code == 'aantal') {
                    this.indexForClustersTotal = index;
                } else if(_x.code == 'cluster'){
                    this.indexForClustersCode = index
                }
            })
        }
        return {totalIndex:this.indexForClustersTotal,clusterIndex:this.indexForClustersCode}
    }

    public getVisibleItemCount(): number {
        if (!this.geoDataManagementTable || !this.geoDataManagementTable.getVisibleRows()) {
            return 0;
        } else if (!isNaN(Number(this.geoDataTableData.rows[0]?.uniqueId)) || this.isMasterData) {
            return this.geoDataManagementTable.getVisibleRows().length;
        }

        let totalCount = 0;
        if (this.getTotalItemsForClustersIndex().totalIndex > -1 && this.getTotalItemsForClustersIndex().clusterIndex > -1) {
            this.geoDataManagementTable.rows.filter(_x => {
                return isNaN(Number(_x.uniqueId));
            }).map(_y => {
                totalCount = totalCount + Number(_y.cells[this.getTotalItemsForClustersIndex().totalIndex]?.label);
            })
        }

        return totalCount;
    }
    
    public getTotalItemCount(): number {
        if (!this.geoDataManagementTable || !this.geoDataManagementTable.getVisibleRows()) {
            return 0;
        } else if (!isNaN(Number(this.geoDataTableData.rows[0]?.uniqueId)) || this.isMasterData) {
            return this.geoDataManagementTable.getVisibleRows().length + this.geoDataManagementTable.getHiddenRows().length;
        }

        let totalCount = 0;
        if (this.getTotalItemsForClustersIndex().totalIndex > -1 && this.getTotalItemsForClustersIndex().clusterIndex > -1) {
            this.geoDataManagementTable.rows.filter(_x => {
                return isNaN(Number(_x.uniqueId));
            }).map(_y => {
                totalCount = totalCount + Number(_y.cells[this.getTotalItemsForClustersIndex().totalIndex]?.label);
            })
        }

        return totalCount;
    }

    public getSelectedItemsInClusters(): void{
        this.model.currentSelectedClusterItems.next(0)
        this.totalSelectedItems = 0;
        if (this.selectedItems.length == 0) {
            return null;
        } else if (!isNaN(Number(this.selectedItems[0].baseObjectId))) {
            this.totalSelectedItems = this.selectedItems.length;
            return null;
        }

        let totalCount = 0;
        if (this.getTotalItemsForClustersIndex().totalIndex > -1 && this.getTotalItemsForClustersIndex().clusterIndex > -1) {
            this.selectedItems.map(_x => {
                totalCount = totalCount + Number(this.geoDataTableData.rows?.find(_y => {
                    return _y.uniqueId == _x.baseObjectId;
                })?.cells[this.getTotalItemsForClustersIndex().totalIndex]?.label);
            })
        }
        this.model.currentSelectedClusterItems.next(totalCount)
        if(totalCount > 0){
            this.totalSelectedItems = totalCount;
        } else {
            this.totalSelectedItems = this.selectedItems.length;
        }
    }

    // By-pass default selection logic and hard-set the selected items. (This doesn't trigger an event, so forms aren't updated for example)
    public setSelectedItems(selectedItems: object[], triggerSelectionEvent: boolean = false): void {
        this.logger.log(Utils.measureFunctionTime('[MapTableComponent] ' + 'Map/table items selected in: ', () => {
            this.selectedItems = <any>selectedItems;
            if (triggerSelectionEvent) {
                this.updateSelectedItems();
            } else {
                if (this.geoDataTableData.rows.length > 0) {
                    if (this.selectedItems.length !== this.geoDataManagementTable.getVisibleRows().length) {
                        const baseObjectDict = {};
                        this.selectedItems.map(_selectedItem => {
                            baseObjectDict[_selectedItem.baseObjectId] = _selectedItem
                        })
                        this.geoDataManagementTable.rows.map(_row => {
                            _row.isSelected = _row.uniqueId in baseObjectDict;
                        })
                        this.cd.detectChanges()
                    } else if (this.selectedItems.length > 0) {
                        this.geoDataManagementTable.setAllVisibleRowsAsSelected();
                    }
                }
                this.mapComponent.updateSelectedItems(this.selectedItems);
                this.getSelectedItemsInClusters()
            }


        }));
    }



    
    public getSelectedItems(): object[] {
        return this.selectedItems;
    }
    
    public handleMapLongPress(e: LongPressEvent): void {
        // Pass event to parent component
        this.onMapLongPress.emit(e);
    }
    
    public handleDragMarker(dragData: DragMarkerEvent): void {
        this.logger.log('[MapTableComponent] ' + 'dragData: ', dragData);
        
        // TODO: als er ooit per module andere acties moeten worden uitgevoerd moet dit een niveau hoger.
        //  Voor nu zorgt draggen altijd voor het verplaatsen van een mapitem
        
        //  let itemLabel = this.ts.translate('onbekend');
        //  if (dragData.draggedMarker.dataRef.baseObjects && dragData.draggedMarker.dataRef.baseObjects.length > 0) {
        //      itemLabel = dragData.draggedMarker.dataRef.baseObjects[0].label;
        //  }
        
        this.globalAlertService.addPopupMoveItem(parseInt(dragData.draggedMarker.dataRef.id),
            (buttonCode, data) => {
                // User picks ok and form is successfully submitted
                this.mapComponent.acceptDrag(dragData.draggedMarker, data.lat, data.lng);
                
                this.model.onGlobalEvent.next(new GlobalEvent(
                    GlobalEvent.EVENT_MOVE_MAPITEM_FROM_MAP_SUCCESS,
                    {marker: dragData.draggedMarker})
                );
                
                // this.model.onItemRefreshRequest.next(dragData.draggedMarker.dataRef.id);
                
            }, () => {
                // User picks close or cancel
                this.mapComponent.rejectDrag(dragData.draggedMarker, dragData.posOld.lat, dragData.posOld.lng);
            },
            dragData.posNew.lat(),
            dragData.posNew.lng(),
        );
    }
    
    public isIconMirrored(code: string): boolean {
        return code === MapTableTab.MIXED || code === MapTableTab.MAP;
    }
    
    public focusOnSelectedItems() {
        if (this.selectedItems.length <= 1) {
            this.mapComponent.handleClickFocus();
        }
        this.scrollToSelectedItems();
    }
    
    private handleTableFilterChange(): void {
        if (this.activeSmartSelection.length > 0) {
            this.changedSelection = true;
        }
        //  Trigger a table data refresh
        this.onTableFilterChange.emit({});
        this.cd.detectChanges();
    }
    
    private setRefreshTime(): void {
        let today = new Date();
        let minutes: number = today.getMinutes();
        let hours: number = today.getHours();
        this.lastRefreshTime = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes; //  + ":" + today.getSeconds();
    }
    
    private triggerAutoRefresh(): void {
        setTimeout(() => {
            this.autoRefresh ?
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MODULE_START_AUTO_REFRESH, {}))
                : this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MODULE_STOP_AUTO_REFRESH, {}));
        }, 0);
    }
    
    private resetTableOptions(): void {
        this.tableOptionsService.resetTableOptions(this.tableOptions);
        this.activeSmartSelection = '';
        this.onTableFilterChange.emit({tableOptionsChanged: true, reset: true});
        this.cd.detectChanges();
    }
    
    private switchToTab(tabCode: string): void {
        let match: TabInterface = null;
        //  Find a tab by code
        this.tabBarConfig.forEach((tabItem) => {
            if (tabItem.code === tabCode) {
                match = tabItem;
            }
        });
        
        //  Activate the tab
        if (match) {
            this.activateTab(match);
        }
    }
    
    private activateTab(tabItemToActivate: TabInterface): void {
        // Deselect all
        this.tabBarConfig.forEach((tabItem) => {
            tabItem.active = false;
        });
        
        // Select one
        tabItemToActivate.active = true;
        this.activeTab = tabItemToActivate;
        
        // Trigger manual resize event, so the maps center stays on the center of a resized map
        // setTimeout(() => this.mapComponent.onResize(null), 100);
        if (this.activeTab.code !== MapTableTab.TABLE) {
            if (this.tmpMapData) {
                const zoomOnMarkers = this.tmpMapData.zoomOnMarkers;
                this.updateMapItems(
                    this.tmpMapData.mapItems,
                    this.tmpMapData.newData,
                    this.tmpMapData.mapHidden,
                    this.tmpMapData.zoomOnMarkers
                );
                this.tmpMapData = null;
                this.filterItems(this._filterString, zoomOnMarkers);
            }
            this.initMap();
        }
        
        this.storage.setValue(StorageService.KEY_SELECTED_MAP_TABLE_VIEW, this.activeTab.code);
    }
    
    private updateGeoDataManagementTable(
        tableData: (TableRow | TableColumn[])[],
        sorting?: { [key: string]: string },
        append: boolean = false
    ) {
        if (tableData.length < 1) {
            this.geoDataTableData = {
                headers: [],
                rows: []
            };
            this.geoDataManagementTable.updateTable();
            return;
        }
        const previousRowLength: number = this.geoDataTableData.rows.length;
        let headers: HeaderData[] = this.createHeaderData(<TableColumn[]>tableData[0]);
        const baseObjectIdIndex: number = tableData[0].findIndex(header => header.code === 'baseObjectId');
        const mapItemIdIndex: number = tableData[0].findIndex(header => header.code === 'mapItemId');
        let rows: GeoDataManagementTableRowData[] = this.createRowData(
            <TableRow[]>tableData.slice(1, tableData.length),
            baseObjectIdIndex,
            mapItemIdIndex,
            <TableColumn[]>tableData[0]
        );
        if (!append) {
            this.geoDataTableData.headers = [...headers];
            this.geoDataTableData.rows = [...rows];
        } else {
            headers.forEach(newHeader => {
                if (this.geoDataTableData.headers.some(header => header.columnId === newHeader.columnId)) {
                    const index = this.geoDataTableData.headers.findIndex(header => header.columnId === newHeader.columnId);
                    this.geoDataTableData.headers.splice(index, 1);
                }
            });
            rows.forEach(newRow => {
                if (this.geoDataTableData.rows.some(row => row.uniqueId === newRow.uniqueId)) {
                    const index = this.geoDataTableData.rows.findIndex(row => row.uniqueId === newRow.uniqueId);
                    this.geoDataTableData.rows.splice(index, 1);
                }
            });
            this.geoDataTableData.headers.push(...headers);
            this.geoDataTableData.rows.push(...rows);
        }
        
        const tableOptionsSortField: TableOptionsField = this.tableOptions.tableFields.find(field => field.sorting);
        let newSorting: TableSorting;
        if (sorting) {
            // todo GUIDO: does this ever happen?
        } else if (JSON.stringify(tableOptionsSortField) !== this.previousSorting) {
            if (tableOptionsSortField && this.geoDataTableData.headers.some(header => header.code === tableOptionsSortField.code)) {
                newSorting = {
                    columnId: this.geoDataTableData.headers.find(header => header.code === tableOptionsSortField.code).columnId,
                    sortDirection: tableOptionsSortField.sortDirection,
                };
            } else {
                newSorting = {
                    columnId: this.geoDataTableData.headers.find(header => header.isVisible).columnId,
                    sortDirection: TableSortDirection.SORT_DIRECTION_ASC
                };
            }
        }
        this.previousSorting = JSON.stringify(tableOptionsSortField);
        
        const keepScrollPosition: boolean = this.geoDataTableData.rows.length === previousRowLength;
        this.geoDataManagementTable.updateTable(this.selectedItems.map(item => item.baseObjectId), newSorting, keepScrollPosition);

        this.model.tableColumnOptions = [];
        this.geoDataTableData.headers.map(_header =>{
            this.model.tableColumnOptions.push({
                id:_header.columnId,
                name:_header.code,
                title:_header.label,
            })
        })
    }
    
    private createHeaderData(tableColumns: TableColumn[]): HeaderData[] {
        return this.baseTableService.createHeaderData(tableColumns).map((headerData: HeaderData, index: number) => {
            headerData.columnRank = this.tableOptions.tableFields.some(field => field.columnRank && field.code === headerData.code) ?
                this.tableOptions.tableFields.find(field => field.code === headerData.code).columnRank
                : index + 1;
            return headerData;
        });
    }
    
    private createRowData(
        tableRows: TableRow[],
        baseObjectIdIndex: number,
        mapItemIdIndex: number,
        tableColumns: TableColumn[]
    ): GeoDataManagementTableRowData[] {
        return this.baseTableService.createRowData(tableRows, tableColumns).map((rowData: RowData, index: number) => {
            let geoDataManagementTableRowData: GeoDataManagementTableRowData = rowData;
            geoDataManagementTableRowData.uniqueId = tableRows[index][baseObjectIdIndex].label;
            geoDataManagementTableRowData.mapItemId = mapItemIdIndex >= 0 ? tableRows[index][mapItemIdIndex].label
                : tableRows[index][baseObjectIdIndex].label;
            return rowData;
        });
    }
    
    // Selecting a marker via click or popup-click
    private handleSelectMarker(markers: MapItem[], cmdKeyPressed: boolean, byPopUp: boolean): void {
        this.logger.log('[MapTableComponent] ' + 'select markers', markers);
        
        // If in mobile mode, highlight the mapitem on marker click, select on popup click
        // WARNING: cannot be tested without touch input (mouse will reset highlight state)
        //  TODO: dit werkt nu voor alle touch devices, ook als ze zo groot zijn dat mobilemode niet aangaat
        // if (this.mobileMode && !byPopUp) {
        if (!byPopUp) {
            this.mapComponent.highlightMarkerByMapItemId(markers[0].id);
        }
        
        if ((!this.mobileMode && this.allowSingleSelect) || byPopUp) {
            //  When cmd key pressed and multiselect, or selecting the same item in single select
            if (cmdKeyPressed && (this.allowMultiSelect || (markers.length === 1 && this.findSelectedItem(markers[0]) > -1))) {
                this.handleCmdClickItem(markers);
            } else {
                this.handleClickItem(markers);
            }
        }
    }
    
    private handleClickItem(items: object[]): void {
        // Clear all
        this.selectedItems = [];
        
        // Add last clicked item only
        this.selectedItems = <any>items;
        
        // Update view
        this.updateSelectedItems();
    }
    
    private handleCmdClickItem(items: object[]): void {
        items.forEach((item) => {
            
            // Search for match in selecteditems
            let foundSelectedItem: number = this.findSelectedItem(item);
            
            if (foundSelectedItem !== -1) {
                // Item is selected already > deselect
                this.selectedItems.splice(foundSelectedItem, 1);
            } else {
                // Item is not selected > select
                this.selectedItems.push(<any>item);
            }
            
        });
        
        // Update view
        this.updateSelectedItems();
    }
    
    private handleShiftClickItem(items: object[]): void {
        // If item not selected already, select it
        items.forEach((item) => {
            if (this.findSelectedItem(item) === -1) {
                this.selectedItems.push(<any>item);
            }
        });
        
        // Update view
        this.updateSelectedItems();
    }
    
    private updateSelectedItems(resetTable: boolean = true): void {
        resetTable = false;
        if (resetTable) {
            this.geoDataManagementTable.resetSelectedRows();
            this.selectedItems.forEach((item: { baseObjectId: number }) =>
                this.geoDataManagementTable.setRowIsSelectedByUniqueId(item.baseObjectId)
            );
            this.scrollToSelectedItems();
        }
        this.onSelectionChange.emit(this.selectedItems);
    }
    
    // Search for matching ID in selectedItems array
    private findSelectedItem(item: { baseObjectId?: number | string }): number {
        for (let i = 0; i < this.selectedItems.length; i++) {
            const selectedItem = this.selectedItems[i];
            if (selectedItem.baseObjectId === item.baseObjectId) {
                return i;
            }
        }
        
        return -1;
    }
    
    private getMouseOverDataForIds(baseObjects: number[]) {
        // Get all visible columns
        let visibleColumnIndexes: number[] = [];
        const visibleColumns: TableColumn[] = [...this.geoDataTableData.headers];
        
        // Get columns included for mouseover. That data is stored in the tablefieldsettings
        let mouseOverColumns: TableColumn[] = [];
        
        if (this.tableOptions && this.tableOptions.tableFields) {
            visibleColumns.forEach((column: TableColumn, columnIndex: number) => {
                this.tableOptions.tableFields.forEach((tableField: TableOptionsField) => {
                    if (
                        tableField.code === column.code
                        && ((tableField.inMouseOver === undefined && tableField.default) || tableField.inMouseOver)
                    ) {
                        mouseOverColumns.push(tableField);
                        visibleColumnIndexes.push(columnIndex);
                    }
                });
            });
        }
        
        // Find the matching rows
        let result: (TableColumn | TableCell)[][] = [mouseOverColumns];
        baseObjects.forEach(id => {
            if (this.geoDataTableData.rows.some(row => String(row.uniqueId) === String(id))) {
                let strippedRow: TableCell[] = [];
                visibleColumnIndexes.forEach((index) => {
                    strippedRow.push(this.geoDataTableData.rows.find(
                        row => String(row.uniqueId) === String(id)).cells[index] as TableCell
                    );
                });
                result.push(strippedRow);
            }
        });
        
        return result;
    }
    
    private scrollToSelectedItems() {
        let itemToScrollTo: number | string = this.selectedItems.map(item => item.baseObjectId)[0];
        if (this.previouslyScrolledItem) {
            const nextItemIndex: number = this.selectedItems.findIndex(
                item => item.baseObjectId === this.previouslyScrolledItem
            ) + 1;
            if (nextItemIndex < this.selectedItems.length) {
                itemToScrollTo = this.selectedItems.map(item => item.baseObjectId)[nextItemIndex];
            }
        }
        this.previouslyScrolledItem = itemToScrollTo;
        
        if (!this.geoDataManagementTable.getVisibleRows().some(row => String(row.uniqueId) === String(itemToScrollTo))) {
            return;
        }
        
        this.geoDataManagementTable.highlightRowByUniqueId(itemToScrollTo);
        if (!this.geoDataManagementTable.isRowActuallyVisibleInWindow(itemToScrollTo)) {
            this.geoDataManagementTable.scrollToRow(itemToScrollTo);
        }
    }
    
    public breakTopBar(): boolean {
        return this.mobileMode || this.topBar?.nativeElement?.getBoundingClientRect()?.width < this.TOP_BAR_BREAK_POINT;
    }
}
