import {Component} from "@angular/core";
/**
 * Created by Christiaan on 18/05/2017.
 */
@Component ({
    selector: 'empty-component',
    template: `

    <div></div>
    `})

    export class EmptyComponent{
        constructor(){}
    }
