/**
 * Created by Christiaan on 13/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    NgZone,
    ViewChild,
    OnDestroy
} from '@angular/core';
import {GlobalModel} from '../../shared/services/state/global.model';
import {MapTableComponent} from '../../shared/components/map-table/map-table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {MapTableService} from '../../shared/components/map-table/map-table.service';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {HTTPService} from '../../shared/services/http/http.service';
import {FormDataService} from '../../shared/components/form/services/form-data.service';
import {AuthorizationService} from '../../shared/services/authorization/authorization.service';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {TreeMapFormComponent} from '../../shared/components/tree-map-form/tree-map-form.component';
import {SectionFormComponent} from '../../shared/components/form/components/section-form.component';
import {StorageService} from '../../shared/services/storage/storage.service';
import {GlobalEvent} from '../../shared/interfaces/global-event';
import {TableOptionsService} from '../../shared/components/table/table-options.service';
import {LongPressEvent} from '../../shared/components/map/map-core.interface';
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {GlobalStateService} from "../../shared/services/state/global-state.service";
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'assets-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../../shared/components/tree-map-form/tree-map-form.component.html'
})
export class AssetsComponent extends TreeMapFormComponent implements AfterViewInit, OnDestroy {
    public static readonly FORM_URL: string = '/assets/view';
    // NOTE: this is the only module path that doesnt match the module name (assets vs asset-management)
    public static readonly MODULE_PATH_ASSETS: string = 'asset-management';
    public static readonly BATCHUPDATE_URL: string = 'asset-management-form/batch-update/get/mast';


    @ViewChild('mapTableComponent', {static: false}) mapTableComponent: MapTableComponent;
    @ViewChild('formComponent', {static: false}) formComponent: SectionFormComponent;
    @ViewChild('dragContainer', {static: false}) dragContainer: ElementRef<HTMLDivElement>;

    constructor(
        public model: GlobalModel,
        protected mapTableService: MapTableService,
        protected elementRef: ElementRef,
        protected cd: ChangeDetectorRef,
        protected ngZone: NgZone,
        protected activatedRoute: ActivatedRoute,
        private router: Router,
        protected globalAlertService: GlobalAlertService,
        protected httpService: HTTPService,
        protected formDataService: FormDataService,
        public auth: AuthorizationService,
        public ts: TranslateService,
        protected storage: StorageService,
        protected tableOptionsService: TableOptionsService,
        protected areaalService: AreaalService,
        protected globalStateService: GlobalStateService,
        protected logger: LoggerService
    ) {
        super(cd, activatedRoute, httpService, router, model, mapTableService, storage, tableOptionsService, auth, formDataService, ngZone, activatedRoute, globalAlertService, areaalService, globalStateService, ts, logger);

        //Check if grid
        this.subscriptions.push(this.activatedRoute.queryParams.subscribe(params => {
            let objectTypesToQuery = ['schakelkast', 'mast', 'genericasset']
            if(params['grid'] == '1' && this.auth.hasModuleAuthorization('grid')){
                this.model.gridDrawModeActive.next(true);
                this.isGridModeActive = true
                objectTypesToQuery = ['schakelkast', 'mast', 'cable', 'wire', 'conduit', 'joint','annotation', 'genericAsset']
            } else {
                this.model.gridDrawModeActive.next(false);
                this.isGridModeActive = false
            }

            this.setTreeMapFormSettings(
                'assets_1',
                'assets_2',
                objectTypesToQuery,
                AssetsComponent.MODULE_PATH_ASSETS,
                ['werkorders'],
                AssetsComponent.FORM_URL,
                AssetsComponent.BATCHUPDATE_URL,
                FormDataService.FORM_URL_ASSETS,
                this.model.assetsSelectedItems,
                this.model.assetsFormData,
                this.model.assetsFormCollapsed,
                this.model.assetsTreeCollapsed,
                this.model.assetsMapItems,
                this.model.assetsAutoLoadedMapItems,
                this.model.assetsAutoLoadedTableItems,
                this.model.assetsTableItems,
                this.model.assetsFilterString,
                this.model.assetsTree,
                {
                    allowCreateMarker: this.auth.allowCreateMapItem(),
                    allowMarkerDrag: this.auth.allowMoveMapItem()
                }
            );
        }));
    }

    ngAfterViewInit(): void {
        this.viewInit();
    }

    handleMapLongPress(e: LongPressEvent): void {
        //Only allow creation of marker with long press if user is not in grid.
        //To disable the long press when user has access to grid, use: if(!this.auth.hasModuleAuthorization('grid'))
        if(!this.isGridModeActive){
            let dropDownData:any = [{
                value: 'mast',
                label: this.ts.translate('Mast'),
            }, {
                value: 'schakelkast',
                label: this.ts.translate('Schakelkast')
            }];


            if (this.auth.allowCreateGenericAsset()) {
                dropDownData.push({
                    value: 'genericAsset',
                    label: this.ts.translate('Overig')
                });
            }

            this.globalAlertService.addPopupCreateMapItem(this.ts.translate('Item aanmaken'), '', 'mapitem/form/new/', 'mapitem/form/create/',
                dropDownData, (buttonCode, response) => {
                    // Changes in schakelkast might need a tree refresh, so do a big-update
                    this.router.navigateByUrl(this.MODULE_FORM_URL + '/' + response.baseObjectId + '?origin=' + (response.schakelkastId && response.schakelkastId != '' ? FormDataService.ORIGIN_NEW_SCHAKELKAST : FormDataService.ORIGIN_RELOAD))
                        .catch(this.logger.error);
                    //this.setActiveState(this.STATE_FORM);

                }, () => {
                }, e.position.lat(), e.position.lng());
        }
    }


    public getForm(id: number, errorCallBack?: () => void) {
        //Special support for loading sub-entities when clicking a root entity. This is specific to asset management, for now.
        let previousObjectId: number = this.formData && this.formData.schema && this.formData.schema.base_object_id ? this.formData.schema.base_object_id : 0;

        super.getForm(id, errorCallBack, previousObjectId);
    }

    protected handleGlobalEvent(event: GlobalEvent): void {
        switch (event.type) {
            case GlobalEvent.EVENT_LOAD_FORM_REQUEST:
                if (event.data.url) {
                    //When switching entities on the batchupdate form
                    this.getBatchUpdateForm(event.data.url);
                }
                break;
            case GlobalEvent.EVENT_REMOVE_BASEOBJECT_SUCCESS:
                // This is handling for both deleting from the form and from the mapitemtree

                // Refresh the map and table
                this.refreshMapTableItems(this.getSelectedItemIds());

                // Check if selected items need to be deselected
                if (event.data.baseObjectId) {
                    if (this.getSelectedItemIds().length > 0) {
                        if (this.getSelectedItemIds()[0] == event.data.baseObjectId) {
                            // When the currently selected item equals the deleted mapitem-treenode: deselect all items and don't reload the form
                            this.clearSelectedItems();
                        }
                        else {
                            // Mapitem tree item does not equal the currently selected item, reload the form to refresh the mapitemtree
                            this.getForm(this.getSelectedItemIds()[0], () => {
                                //TODO: hier hoeft nu niks te gebeuren tenzij je een lamp geselecteerd had en de bijbehorende mast aan het verwijderen bent, dan krijg je nu een melding dat je het formulier niet kan ophalen omdat deze gearchiveerd is, en selected items blijft op 1 staan. dus die wordne hier leeggehaald, maar dat moet eigenlijk mooier
                                this.logger.log('[AssetsComponent] ' + 'Error while getting form, deselect all items');
                                this.clearSelectedItems();
                            });

                        }
                    }
                }
                break;
            case(GlobalEvent.EVENT_REFRESH_FORM):
                    this.refreshMapTableItems(this.getSelectedItemIds());
                    this.getForm(event.data.referenceId);
                break;
        }
    }

    handleComponentEvent(eventData: {event: string, data: {baseObjectId?: number}}): void {
        switch (eventData.event) {
            case FormEvent.SAVE_SUCCESS:
            case FormEvent.BATCH_SAVE_SUCCESS:
            case FormEvent.MOVE_MAPITEM_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;

            case FormEvent.BATCH_DELETE_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                if(this.isGridModeActive){
                    this.clearSelectedItemsForGrid()
                } else {
                    this.clearSelectedItems()
                }
                break;

            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                const polygonSelect = this.mapTableComponent.mapComponent.polygonSelect
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect()
                }

                if(this.isGridModeActive){
                    this.clearSelectedItemsForGrid()
                } else {
                    this.clearSelectedItems()
                }
                break;

            case FormEvent.COPY_BASEOBJECT_SUCCESS:
                if (eventData.data && eventData.data.baseObjectId) {
                    this.refreshMapTableItems([eventData.data.baseObjectId]);
                }
                break;

        }
    }

    ngOnDestroy() {
        this.model.gridDrawModeActive.next(false);
        super.ngOnDestroy();
    }
}
