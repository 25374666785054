import {Component, OnDestroy} from '@angular/core';
import {INotifications} from './notifications.interface';
import {NotificationsService} from './notifications.service';
import {TranslateService} from '../../shared/services/translate/translate.service';
import * as moment from 'moment/moment';
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {GlobalModel} from "../../shared/services/state/global.model";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";

@Component ({
    selector: 'notifications-component',
    templateUrl: 'notifications.component.html'
})
export class NotificationsComponent implements OnDestroy {
    public activeNotificationId: number = null;
    protected subscriptions: Subscription[] = [];

    constructor(public notificationService:NotificationsService, public ts: TranslateService, protected activatedRoute: ActivatedRoute, public model:GlobalModel,public globalAlertService:GlobalAlertService) {
        this.subscriptions.push(this.notificationService.getNotificationList().subscribe(notificationList => {
            this.model.notificationList = notificationList.sort((a, b) => (a.notificationId > b.notificationId ? -1 : 1));
            this.checkForNewNotifications();
            this.checkForModalNotifications();
        }));

        //If notification id is given in url
        this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
            if(params && params.notificationId !== null && params.notificationId > 0){
                this.activeNotificationId = params.notificationId;
                this.updateNotification(this.getNotification(params.notificationId));

                //Scroll to notification only on desktop
                if(window.innerWidth > 1199){
                    document.getElementById('notification-'+params.notificationId)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
            }
        }));
    }

    private getNotification(notificationId:number):INotifications{
        return this.model.notificationList.filter(_x => _x.notificationId == notificationId)[0];
    }

    public updateNotification(notification: INotifications):void {
        if(notification && notification.isNew) {
            const tmpSub = this.notificationService.changeIsNewState(notification.notificationId).subscribe(()=>{});
            //Use timeout here because firefox would unsubscribe too fast and block the call
            setTimeout(()=>{
                tmpSub.unsubscribe()
            },2000)

            this.model.notificationList.find(_x => {
                return _x.notificationId === notification.notificationId
            }).isNew = false

            this.checkForNewNotifications();
        }
    }

    private checkForNewNotifications():void{
        this.model.hasNewNotifications = this.model.notificationList.filter(_x => _x.isNew).length > 0;
    }

    private checkForModalNotifications():void{
        if(!this.model.hasModalNotifications){
            this.model.notificationModalList = this.model.notificationList.filter(_x => _x.displayModal === true && _x.isNew === true);
            this.model.hasModalNotifications = this.model.notificationModalList.length > 0;
            if(this.model.hasModalNotifications){
                this.globalAlertService.addPopupFirstLogin(this.model.notificationModalList[0].status, () => {
                    this.updateModalMessages()
                }, () => {
                    this.updateModalMessages()
                })
            }
        }
    }

    private updateModalMessages():void{
        this.model.notificationModalList.forEach(_x => {
            //Use timeout here because firefox would block the call when too many other calls are running
            setTimeout(()=>{
                this.subscriptions.push(this.notificationService.changeIsNewState(_x.notificationId).subscribe(()=>{}));
            })
        })
        this.model.notificationModalList = [];
        // this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public getMonthNameShort(date:string):string{
        return [this.ts.translate('jan.short'),
            this.ts.translate('feb.short'),
            this.ts.translate('mar.short'),
            this.ts.translate('apr.short'),
            this.ts.translate('may.short'),
            this.ts.translate('jun.short'),
            this.ts.translate('jul.short'),
            this.ts.translate('aug.short'),
            this.ts.translate('sep.short'),
            this.ts.translate('oct.short'),
            this.ts.translate('nov.short'),
            this.ts.translate('dec.short')][moment(date).month()];
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
