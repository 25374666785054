import {MapCoreV2Component} from "../map-core-V2.component";
import {drawingStates, IRegisteredEvents, mapItemTypes} from "./map-manager.interface";
import {ButtonCode} from "../../../../wrapper/global-alert/global-popup";
import {LoggerService} from "../../../services/logger/logger.service";


export default class MapEventManagerService {
    callbackSuccess: () => void;
    private registeredEvents:google.maps.MapsEventListener[] = [];
    public isCtrlKeyActive:boolean = false;

    constructor(private mapCoreV2: MapCoreV2Component, protected logger:LoggerService) {}

    public initGridModeIndependentEvents():void{
        //Events that are initialized whether grid is active or not.
        this.logger.log('[Grid][MapEventManagerService] ' + 'Initialize grid independent events')
        //Control key for multiselect selected
        this.createGMEventDomListener('keydown', document, 'document', (event) => {
            this.isCtrlKeyActive = true
        }, this.mapCoreV2.mapSettings.KEYCODES_MULTI_SELECT, false);

        //Control key for multiselect deselected
        this.createGMEventDomListener('keyup', document, 'document',(event) => {
            this.isCtrlKeyActive = false
        }, this.mapCoreV2.mapSettings.KEYCODES_MULTI_SELECT, false);
    }

    public initCreateMapEvents() {
        //Events that are initialized only when grid is active
        this.logger.log('[Grid][MapEventManagerService] ' + 'Initialize map events')
        this.createGMListener('click', this.mapCoreV2.map, (event) => {
            if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode() || this.mapCoreV2.mapItemsAreClickable || this.mapCoreV2.mapHelperManagerService.isInEditMode()) {
                this.mapCoreV2.mapDrawingManagerService.handleDrawClick(event)
            }
        })
        this.createGMListener('mousemove', this.mapCoreV2.map, (event) => {
            this.mapCoreV2.mousePosition.currentMouseLatLng = event.latLng
            if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode() || this.mapCoreV2.mapItemsAreClickable || this.mapCoreV2.drawState === drawingStates.DRAW_EDIT_HEAD) {
                this.mapCoreV2.mapDrawingManagerService.handleDrawMousemove(event)
            }
        }) // Do not register this event since V1 also relies on mouse move so if destroyed, it will not initiate again like the V2 events.

        this.createGMListener('zoom_changed', this.mapCoreV2.map, (event) => {
            if (this.mapCoreV2.map.getZoom() > 14) {
                this.mapCoreV2.mapLayerManagerService.layers.map(_layer => {
                    if(_layer.layerId == this.mapCoreV2.mapSettings.CLUSTER_LAYER_HARDCODED_ID){
                        _layer.hide();
                    }
                })
                this.mapCoreV2.enableAutoLoadMarkers(true);
            } else {
                this.mapCoreV2.disableAutoLoadMarkers();
            }

            this.mapCoreV2.mapHelperManagerService.handleSuperZoom()
            this.mapCoreV2.mapHelperManagerService.handleAnnotations()
            if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode() || this.mapCoreV2.mapHelperManagerService.isInPolylineAlterMode()) {
                this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints();
                this.mapCoreV2.mapHelperManagerService.setSnapPointRadiusByZoom()
            }
        })

        this.createGMListener('maptypeid_changed', this.mapCoreV2.map, (event) => {
            this.mapCoreV2.saveOriginalMapTypeId();
        })

        this.createGMListener('dragend', this.mapCoreV2.map, (event) => {
            if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode() || this.mapCoreV2.mapHelperManagerService.isInPolylineAlterMode()) {
                this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints();
            }
        })

        this.createGMListener('dblclick', this.mapCoreV2.map, (event) => {
            if (this.mapCoreV2.drawState === drawingStates.DRAW_NEW_HEAD) {
                this.mapCoreV2.mapDrawingManagerService.closeDrawingMode(true);
            }
        })

        //Esc key
        this.createGMEventDomListener('keyup', document, 'dom document',(event) => {
            if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode()) {
                if(this.mapCoreV2.mapHelperManagerService.isInEditMode()){
                    this.mapCoreV2.mapDrawingManagerService.closeEditMode()
                } else {
                    this.mapCoreV2.mapDrawingManagerService.closeDrawingMode(false);
                }
            }
        }, this.mapCoreV2.mapSettings.KEYCODES_DRAW_CANCEL);

        //Backspace key
        this.createGMEventDomListener('keyup', document, 'dom document',(event) => {
            if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode() && (this.mapCoreV2.drawState === drawingStates.DRAW_NEW_HEAD || this.mapCoreV2.drawState === drawingStates.DRAW_EDIT_HEAD)) {
                this.mapCoreV2.mapDrawingManagerService.revertLastAction()
                this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints();
            }
        }, this.mapCoreV2.mapSettings.KEYCODES_DRAW_UNDO);

        //Zoom prefix
        this.createGMEventDomListener('keyup', document, 'dom document',(event) => {
            const zoomLevel:number = Number(this.mapCoreV2.mapSettings.ZOOM_PREFIX_BUTTONS[3][0]);
            const keyCode = (event.keyCode ? event.keyCode : event.which);
            if(keyCode === 49 || keyCode === 97){ //Number 1
                this.mapCoreV2.mapHelperManagerService.zoomToLevel(Number(this.mapCoreV2.mapSettings.ZOOM_PREFIX_BUTTONS[3][0]),true)
            } else if(keyCode === 50 || keyCode === 98){ //Number 2
                this.mapCoreV2.mapHelperManagerService.zoomToLevel(Number(this.mapCoreV2.mapSettings.ZOOM_PREFIX_BUTTONS[2][0]),true)
            } else if(keyCode === 51 || keyCode === 99){ //Number 3
                this.mapCoreV2.mapHelperManagerService.zoomToLevel(Number(this.mapCoreV2.mapSettings.ZOOM_PREFIX_BUTTONS[1][0]),true)
            } else if(keyCode === 52 || keyCode === 100) { //Number 4
                this.mapCoreV2.mapHelperManagerService.zoomToLevel(Number(this.mapCoreV2.mapSettings.ZOOM_PREFIX_BUTTONS[0][0]), true)
            }
        }, this.mapCoreV2.mapSettings.KEYCODES_ZOOM_LEVEL);


        //Trigger events for focus layer as if you are clicking on the map
        this.createGMListener('click', this.mapCoreV2.mapLayerManagerService.focusLayer, (event) => {
            if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode()) {
                google.maps.event.trigger(this.mapCoreV2.map, 'click', event);
            }
        })
        this.createGMListener('mousemove', this.mapCoreV2.mapLayerManagerService.focusLayer, (event) => {
            if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode()) {
                google.maps.event.trigger(this.mapCoreV2.map, 'mousemove', event);
            }
        })

        //Enter key
        this.createGMEventDomListener('keyup', document, 'dom document',(event) => {
            if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode()) {
                if(this.mapCoreV2.mapHelperManagerService.isInEditMode()){
                    this.mapCoreV2.mapDrawingManagerService.handleFinishEditMapItem(true);
                } else {
                    this.mapCoreV2.mapDrawingManagerService.closeDrawingMode(true)
                }
            }
        }, this.mapCoreV2.mapSettings.KEYCODES_SAVE_CHANGES);

        //Keep track if mouse is on or outside of canvas
        const mainMapItem = document.getElementById('mapContainer')
        this.createGMEventDomListener('mouseenter', mainMapItem, 'mouseenter on map', (event) => {
            this.mapCoreV2.mousePosition.isMouseOnCanvas = true;
        });
        this.createGMEventDomListener('mouseleave', mainMapItem, 'mouseenter on map', (event) => {
            this.mapCoreV2.mousePosition.isMouseOnCanvas = false;
        });
    }

    public setUIEvents(outerDiv: HTMLDivElement, controlPanel: 'main' | 'draw' | 'edit' | 'zoom'): void {
        this.logger.log('[Grid][MapEventManagerService] ' + 'Initialize ui events')
        //Prevent close dropdown
        const dropdownElement = outerDiv.getElementsByClassName('prevent-close');
        for (let i = 0; i < dropdownElement.length; i++) {
            this.createGMEventDomListener('click', dropdownElement[i], 'prevent-close',(event) => {
                event.stopPropagation();
            })
        }

        if (controlPanel === 'main') {
            //Focus function
            const focusFunction = outerDiv.getElementsByClassName('function_focus')[0];
            this.createGMEventDomListener('click', focusFunction, 'function_focus',(event) => {
                this.mapCoreV2.mapUIManagerService.toggleActiveClass(focusFunction);
                this.mapCoreV2.mapLayerManagerService.toggleFocusLayer(focusFunction.classList.contains('active'))
            })
            //InfoWindow function
            const infoWindowFunction = outerDiv.getElementsByClassName('function_infowindows')[0];
            this.createGMEventDomListener('click', infoWindowFunction, 'function_infowindows',(event) => {
                this.mapCoreV2.mapUIManagerService.toggleActiveClass(infoWindowFunction);
                this.mapCoreV2.showInfoWindows = infoWindowFunction.classList.contains('active')
            })
            //Map item edit function
            const mapItemEditFunction = outerDiv.getElementsByClassName('function_mapitemedit')[0];
            this.createGMEventDomListener('click', mapItemEditFunction, 'function_mapitemedit',(event) => {
                this.mapCoreV2.mapUIManagerService.toggleActiveClass(mapItemEditFunction);
                this.mapCoreV2.mapUIManagerService.toggleEditOptionsToolbar(true);

                //Enable/disable other buttons
                if (mapItemEditFunction.classList.contains('active')) {
                    this.mapCoreV2.mapUIManagerService.openMapItemEditSelectionMode()
                    this.mapCoreV2.mapHelperManagerService.openEditModeMapItem()
                } else {
                    this.mapCoreV2.mapUIManagerService.closeMapItemEditSelectionMode()
                    this.mapCoreV2.mapHelperManagerService.closeEditModeMapItem()
                }
            })
            //Open line draw dropdown
            const drawLineDropdownButton = outerDiv.getElementsByClassName('draw_polyline_dropdown')[0];
            this.createGMEventDomListener('click', drawLineDropdownButton, 'draw_polyline_dropdown',(event) => {
                this.mapCoreV2.mapUIManagerService.removeActiveClass('available-layers')
                this.mapCoreV2.mapUIManagerService.removeActiveClass('available-styles')
                this.mapCoreV2.mapUIManagerService.toggleDrawLineCloseIcon(false)
                this.mapCoreV2.mapUIManagerService.toggleSelectedLayerStyleContainer(false)
                this.mapCoreV2.mapUIManagerService.toggleSelectedLayerStyleButton(false)
                this.mapCoreV2.mapHelperManagerService.setSnapPointRadiusByZoom()
            })
            //Draw function
            const drawFunction = outerDiv.getElementsByClassName('function_draw');
            for (let i = 0; i < drawFunction.length; i++) {
                const type = drawFunction[i].getAttribute('attr-value');
                this.createGMEventDomListener('click', drawFunction[i], 'function_draw',(event) => {
                    this.mapCoreV2.mapUIManagerService.removeActiveClass('function_draw')
                    this.mapCoreV2.mapUIManagerService.toggleActiveClass(drawFunction[i])
                    this.mapCoreV2.mapDrawingManagerService.closeDrawingMode(false, false, 'changeDrawingType', (type as mapItemTypes))
                    if((type as mapItemTypes) === mapItemTypes.ANNOTATION){
                        this.mapCoreV2.mapUIManagerService.toggleLatLngPosition(false)
                        this.mapCoreV2.mapUIManagerService.repositionDrawingControlsToCenter()
                    }
                })
            }
            //Draw a line step 1
            const drawLineFunction = outerDiv.getElementsByClassName('function_drawline');
            for (let i = 0; i < drawLineFunction.length; i++) {
                const type = drawLineFunction[i].getAttribute('attr-value');
                this.createGMEventDomListener('click', drawLineFunction[i], 'function_drawline',(event) => {
                    this.mapCoreV2.mapDrawingManagerService.closeDrawingMode(false)
                    this.mapCoreV2.mapUIManagerService.addActiveClass('available-layers')
                    this.mapCoreV2.mapUIManagerService.toggleDrawLineCloseIcon(true)
                    this.mapCoreV2.mapUIManagerService.toggleSelectedLayerStyleContainer(true)
                    this.mapCoreV2.mapUIManagerService.enableQuicklinkLayerStyle()
                    this.mapCoreV2.mapUIManagerService.toggleSelectedLayerStyleButton(true)
                })
            }
            //Draw a line step 2
            const layerSelectFunction = outerDiv.getElementsByClassName('function_layer_select');
            for (let i = 0; i < layerSelectFunction.length; i++) {
                const layerId = layerSelectFunction[i].getAttribute('attr-value');
                this.createGMEventDomListener('click', layerSelectFunction[i], 'function_layer_select',(event) => {
                    this.mapCoreV2.mapUIManagerService.removeActiveClass('available-layers')
                    this.mapCoreV2.mapUIManagerService.addActiveClass('available-styles')
                    this.mapCoreV2.mapHelperManagerService.setSelectedLayerId(Number(layerId))
                })
            }
            //Draw a line step 3
            const styleSelectFunction = outerDiv.getElementsByClassName('function_style_select');
            for (let i = 0; i < styleSelectFunction.length; i++) {
                const styleId = styleSelectFunction[i].getAttribute('attr-value');
                this.createGMEventDomListener('click', styleSelectFunction[i], 'function_style_select',(event) => {
                    this.mapCoreV2.mapUIManagerService.removeActiveClass('available-styles')
                    this.mapCoreV2.mapUIManagerService.toggleDrawLineCloseIcon(false)
                    this.mapCoreV2.mapUIManagerService.toggleSelectedLayerStyleContainer(false)
                    this.mapCoreV2.mapHelperManagerService.setSelectedStyleId(Number(styleId))
                    this.mapCoreV2.mapUIManagerService.enableQuicklinkLayerStyle()
                    this.mapCoreV2.mapHelperManagerService.setDrawingType(mapItemTypes.POLYLINE)
                    this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints()
                })
            }
            //Select previous selected layer and style
            const selectedLayerStyleFunction = outerDiv.getElementsByClassName('drawing-selection-set-inner')[0];
            this.createGMEventDomListener('click', selectedLayerStyleFunction, 'drawing-selection-set-inner',(event) => {
                this.mapCoreV2.mapUIManagerService.removeActiveClass('available-layers')
                this.mapCoreV2.mapUIManagerService.removeActiveClass('available-styles')
                this.mapCoreV2.mapUIManagerService.toggleDrawLineCloseIcon(false)
                this.mapCoreV2.mapUIManagerService.toggleSelectedLayerStyleContainer(false)
                this.mapCoreV2.mapHelperManagerService.setDrawingType(mapItemTypes.POLYLINE)
                this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints()
            })

            //Close draw line options
            const closeDrawOptionsButton = outerDiv.getElementsByClassName('drawing-selection-container-closebtn')[0];
            this.createGMEventDomListener('click', closeDrawOptionsButton, 'drawing-selection-container-closebtn',(event) => {
                this.mapCoreV2.mapUIManagerService.removeActiveClass('available-layers')
                this.mapCoreV2.mapUIManagerService.removeActiveClass('available-styles')
            })
            //Option visible
            const optionVisibleElements = outerDiv.getElementsByClassName('option_visible');
            for (let i = 0; i < optionVisibleElements.length; i++) {
                const layerId = Number(optionVisibleElements[i].getAttribute('attr-value'));
                if (layerId !== null) {
                    this.createGMEventDomListener('click', optionVisibleElements[i], 'option_visible', (event) => {
                        this.mapCoreV2.mapUIManagerService.toggleActiveClass(optionVisibleElements[i]);
                        this.mapCoreV2.mapLayerManagerService.toggleMapItemsVisible(layerId, optionVisibleElements[i].classList.contains('active'));
                    })
                }
            }
        } else if (controlPanel === 'draw') {
            //Fixed line width
            const lineLength = outerDiv.getElementsByClassName('info-value-inner-newline')[0];
            this.createGMEventDomListener('keyup', lineLength, 'info-value-inner-newline',(event) => {
                if (!isNaN(Number(outerDiv.getElementsByClassName('info-value-inner-newline')[0].innerHTML))) {
                    this.mapCoreV2.polylineDrawingHeadFixedLength = Number(lineLength.innerHTML);
                    if (this.mapCoreV2.polylineDrawingHeadFixedLength > 0) {
                        outerDiv.getElementsByClassName('remove-fixed-length')[0].classList.add('visible')
                    } else {
                        outerDiv.getElementsByClassName('remove-fixed-length')[0].classList.remove('visible')
                    }
                } else {
                    this.mapCoreV2.mapUIManagerService.removeFixedLineLength()
                }
            })
            //Remove fixed line width
            const removeLineLength = outerDiv.getElementsByClassName('remove-fixed-length')[0];
            this.createGMEventDomListener('click', removeLineLength, 'remove-fixed-length',(event) => {
                this.mapCoreV2.mapUIManagerService.removeFixedLineLength()
            })
            //Parallel
            const parallelBtn = outerDiv.getElementsByClassName('function_parallel')[0];
            this.createGMEventDomListener('click', parallelBtn, 'function_parallel',(event) => {
                if(this.mapCoreV2.mapLayerManagerService.isParallelActive()){
                    this.mapCoreV2.mapUIManagerService.removeActiveClass('function_parallel');
                } else {
                    this.mapCoreV2.mapUIManagerService.addActiveClass('function_parallel');
                }
                this.mapCoreV2.mapLayerManagerService.toggleOptionsForAllLayers('parallel')
                this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints()
            })
            //Snappable
            const snapBtn = outerDiv.getElementsByClassName('function_snappable')[0];
            this.createGMEventDomListener('click', snapBtn, 'function_snappable',(event) => {
                if(this.mapCoreV2.mapLayerManagerService.isSnappingActive()){
                    this.mapCoreV2.mapUIManagerService.removeActiveClass('function_snappable');
                } else {
                    this.mapCoreV2.mapUIManagerService.addActiveClass('function_snappable');
                }
                this.mapCoreV2.mapLayerManagerService.toggleOptionsForAllLayers('snappable')
                this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints()
            })
            //Save drawing
            const saveBtn = outerDiv.getElementsByClassName('function_save')[0];
            this.createGMEventDomListener('click', saveBtn, 'function_save',(event) => {
                this.mapCoreV2.mapDrawingManagerService.closeDrawingMode(true)
            })
            //Close drawing mode
            const closeBtn = outerDiv.getElementsByClassName('function_cancel')[0];
            this.createGMEventDomListener('click', closeBtn, 'function_cancel',(event) => {
                this.mapCoreV2.mapDrawingManagerService.closeDrawingMode(false)
            })
        } else if (controlPanel === 'edit') {
            //Fixed line width
            const lineLength = outerDiv.getElementsByClassName('info-value-inner-editline')[0];
            this.createGMEventDomListener('keyup', lineLength, 'info-value-inner-editline',(event) => {
                if (!isNaN(Number(outerDiv.getElementsByClassName('info-value-inner-editline')[0].innerHTML))) {
                    this.mapCoreV2.polylineDrawingHeadFixedLength = Number(lineLength.innerHTML);
                    if (this.mapCoreV2.polylineDrawingHeadFixedLength > 0) {
                        outerDiv.getElementsByClassName('remove-fixed-length')[0].classList.add('visible')
                    } else {
                        outerDiv.getElementsByClassName('remove-fixed-length')[0].classList.remove('visible')
                    }
                } else {
                    this.mapCoreV2.mapUIManagerService.removeFixedLineLength()
                }
            })
            //Remove fixed line width
            const removeLineLength = outerDiv.getElementsByClassName('remove-fixed-length')[0];
            this.createGMEventDomListener('click', removeLineLength, 'remove-fixed-length',(event) => {
                this.mapCoreV2.mapUIManagerService.removeFixedLineLength()
            })
            //Add points
            const addPointsBtn = outerDiv.getElementsByClassName('function_addpoint')[0];
            this.createGMEventDomListener('click', addPointsBtn, 'function_addpoint',(event) => {
                this.mapCoreV2.mapUIManagerService.clickAddPointButtonUi(this.mapCoreV2.mapItemEditPath !== 'add' ? true : false)
                this.mapCoreV2.mapItemEditPath = this.mapCoreV2.mapItemEditPath !== 'add' ? 'add' : null
                this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints();
            })
            //Remove points
            const removePointsBtn = outerDiv.getElementsByClassName('function_removepoint')[0];
            this.createGMEventDomListener('click', removePointsBtn, 'function_removepoint',(event) => {
                this.mapCoreV2.mapUIManagerService.clickRemovePointButtonUi(this.mapCoreV2.mapItemEditPath !== 'remove' ? true : false)
                this.mapCoreV2.mapItemEditPath = this.mapCoreV2.mapItemEditPath !== 'remove' ? 'remove' : null
            })
            //Parallel
            const parallelBtn = outerDiv.getElementsByClassName('function_parallel')[0];
            this.createGMEventDomListener('click', parallelBtn, 'function_parallel',(event) => {
                if(this.mapCoreV2.mapLayerManagerService.isParallelActive()){
                    this.mapCoreV2.mapUIManagerService.removeActiveClass('function_parallel');
                } else {
                    this.mapCoreV2.mapUIManagerService.addActiveClass('function_parallel');
                }
                this.mapCoreV2.mapLayerManagerService.toggleOptionsForAllLayers('parallel')
            })
            //Snappable
            const snapBtn = outerDiv.getElementsByClassName('function_snappable')[0];
            this.createGMEventDomListener('click', snapBtn, 'function_snappable',(event) => {
                if(this.mapCoreV2.mapLayerManagerService.isSnappingActive()){
                    this.mapCoreV2.mapUIManagerService.removeActiveClass('function_snappable');
                } else {
                    this.mapCoreV2.mapUIManagerService.addActiveClass('function_snappable');
                }
                this.mapCoreV2.mapLayerManagerService.toggleOptionsForAllLayers('snappable')
            })
            //Save edit
            const saveBtn = outerDiv.getElementsByClassName('function_save')[0];
            this.createGMEventDomListener('click', saveBtn, 'function_save',(event) => {
                this.mapCoreV2.mapDrawingManagerService.handleFinishEditMapItem(true);
            })
            //Close edit mode
            const closeBtn = outerDiv.getElementsByClassName('function_cancel')[0];
            this.createGMEventDomListener('click', closeBtn, 'function_cancel',(event) => {
                this.mapCoreV2.mapDrawingManagerService.closeEditMode()
            })
        }  else if (controlPanel === 'zoom') {
            //Zoom prefix control
            const zoomPrefixElements = outerDiv.getElementsByClassName('function_zoom_prefix');
            for (let i = 0; i < zoomPrefixElements.length; i++) {
                const zoomLevel = Number(zoomPrefixElements[i].getAttribute('attr-value'));
                if (zoomLevel !== null) {
                    this.createGMEventDomListener('click', zoomPrefixElements[i], 'function_zoom_prefix',(event) => {
                        this.mapCoreV2.mapHelperManagerService.zoomToLevel(zoomLevel, false)
                    })
                }
            }
        }
    }

    public createGMListener(eventAction: string, element: any, callbackSuccess: any, registerEvent:boolean = true) {
        //Create listener and register event so it can be destroyed
        if(typeof element !== 'undefined') {
            // this.logger.log('[Grid][MapEventManagerService] ' + 'Create listener for element: ' + element + ' with action: '+eventAction)
            const listener = google.maps.event.addListener(element, eventAction, (event) => {
                this.callbackSuccess = callbackSuccess(event);
            });
            if(registerEvent){
                this.registeredEvents.push(listener);
            }
        } else {
            this.logger.log('[Grid][MapEventManagerService] ' + 'ERROR creating listener for element. Polyline not found! Action: '+eventAction)
        }
    }

    public createGMEventDomListener(eventAction: 'click' | 'keyup' | 'keydown' | 'mouseover' | 'mouseout' | 'mouseenter' | 'mouseleave' | 'mousemove', element:any, elementName:string, callbackSuccess: any, keyCodes: number[] = [], registerEvent:boolean = true) {
        //Create dom listener and optionally register event so it can be destroyed or not since not all events need to be destroyed when grid closes
        if(typeof element !== 'undefined'){
            // this.logger.log('[Grid][MapEventManagerService] ' + 'Create dom listener for element: '+element+' with action: '+eventAction+' triggered by keycodes: '+keyCodes+'. Event is registered and can therefore be destroyed: '+registerEvent)
            const eventListener = google.maps.event.addDomListener(element, eventAction, (event) => {
                let doCallback: boolean = false;
                if (keyCodes.length > 0) {
                    let code = (event.keyCode ? event.keyCode : event.which);
                    if (keyCodes.filter(_key => {
                        return _key == code;
                    }).length > 0) {
                        doCallback = true;
                    }
                } else {
                    doCallback = true;
                }
                if (doCallback) {
                    this.callbackSuccess = callbackSuccess(event);
                }
            });
            if(registerEvent){
                this.registeredEvents.push(eventListener)
            }
        } else {
            this.logger.log('[Grid][MapEventManagerService] ' + 'ERROR creating listener for element. Element not found! ElementName: '+elementName+', action: '+eventAction+', keycodes: '+keyCodes)
        }

    }

    public destroyAllListeners():void{
        //Destroy all registered events when grid is closed
        this.logger.log('[Grid][MapEventManagerService] ' + 'Destroy all registered listeners')
        this.registeredEvents.map(_listener => {
            google.maps.event.removeListener(_listener)
        })
        this.registeredEvents = []
    }

    public showPopupWarning(action: 'cancelChangesMapItemEdit' | 'deleteMapItemEdit' | 'closeDrawingMode' | 'changeDrawingType' | string, title: string, label: string, cancelBtnLabel: string, confirmBtnLabel: string, drawingType?:mapItemTypes): void {
        //Show popup
        this.logger.log('[Grid][MapEventManagerService] ' + 'Popup warning for action: '+action)
        this.mapCoreV2.globalAlertService.addPopup(title, label,
            [{
                label: cancelBtnLabel,
                code: ButtonCode.ANNULEREN,
                isPrimary: true,
            }, {
                label: confirmBtnLabel,
                code: ButtonCode.OK,
                callback: () => {
                    if (action === 'cancelChangesMapItemEdit') {
                        this.mapCoreV2.mapDrawingManagerService.handleFinishEditMapItem(false);
                    } else if (action === 'deleteMapItemEdit') {
                        this.mapCoreV2.mapDrawingManagerService.deleteEditMapItem();
                    } else if (action === 'closeDrawingMode') {
                        this.mapCoreV2.mapDrawingManagerService.closeDrawingMode(false, true);
                    } else if(action === 'changeDrawingType') {
                        this.mapCoreV2.mapDrawingManagerService.closeDrawingMode(false, true);
                        this.mapCoreV2.mapHelperManagerService.setDrawingType(drawingType)
                        this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints()
                    }
                },
                isPrimary: false,
            }], () => {
        });
    }
}
