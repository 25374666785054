/**
 * Created by Christiaan on 05/05/2017.
 */
export interface BaseObjectInterface {
    lat?: number
    lng?: number
    label: string
    icon?: string
    id: string
    selected?: boolean
    hidden?: boolean
    actionUrl?: string
}

export interface ICluster {
    h3:{
        cell_id:string,
        center: {
            lat: number,
            lng: number
        }
    },
    geometry:{
        type:'Polygon',
        coordinates:{lat:number,lng:number}[]
    },
    data:any,
    total:number
    offline?:number
    fault?:number
    attention?:number
}

export interface MapItemInterface {
    lat: number
    lng: number
    label: string
    icon: string
    id: string
    selected?: boolean
    hidden?: boolean
    baseObjects: BaseObjectInterface[]
    char?: string
    iconChanged?: boolean
    hasLabel?: boolean
    actionUrl?: string
    baseObjectId?: number
    oldSelectedState?: boolean
    dataLayerLabels?: [{name:any, value:string}]
    dataLayer?: boolean
    objectType?: 'cable'|'wire'|'conduit'|'joint'|'annotation'|'cluster'
    styleId?:number
    layerId?:number
    geometry?:{
        type:'LineString'|'Polygon';
        coordinates:number[]|{lat:number,lng:number}[];
    }
    data?:any,
    total?:number,
    maxClusterSize?:number
    isCluster?:boolean

}

export class MapItem implements MapItemInterface {
    //TODO: add reference to werkorder/mast/oid
    lat: number;
    lng: number;
    label: string;
    //fillColor:string
    icon: string;
    //type:string
    id: string;
    selected?: boolean;
    hidden?: boolean;
    //actionUrl:string

    baseObjects: BaseObjectInterface[];
    char?: string = '';
    baseObjectId?: number;
    objectType?: 'cable'|'wire'|'conduit'|'joint'|'annotation'|'cluster';
    styleId?:number;
    layerId?:number;
    geometry?:{
        type:'LineString'|'Polygon';
        coordinates:number[]|{lat:number,lng:number}[];
    }
    data?:any;
    total?:number;
    offline?:number;
    attention?:number;
    fault?:number;
    maxClusterSize?:number;
    isCluster?:boolean = false;
}
