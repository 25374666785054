/**
 * Created by Christiaan on 13/04/2017.
 */
import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { RouterModule } from '@angular/router';
import {GlobalPopupBasicComponent} from './global-popup-basic.component';
import {GlobalPopupBasicInputComponent} from './global-popup-basic-input.component';
import {GlobalPopupMoveItemComponent} from './global-popup-move-item.component';
import {LumiFormModule} from '../../../shared/components/form/lumi-form.module';
import {GlobalPopupCreateDropdownComponent} from './global-popup-create-dropdown.component';
import {GlobalPopupEditDropdownComponent} from './global-popup-edit-dropdown.component';
import {GlobalPopupCreateBaseObjectComponent, } from './global-popup-create-baseobject.component';
import {GlobalPopupCreateMapItemComponent} from './global-popup-create-mapitem.component';
import {GlobalPopupCreateDrawingItemComponent} from './global-popup-create-drawingitem.component';
import {GlobalPopupDeleteBaseObjectComponent} from './global-popup-delete-baseobject.component';
import {CommonPipesModule} from '../../../shared/pipes/common-pipes.module';
import {GlobalPopupCopyBaseobjectComponent} from './global-popup-copy-baseobject.component';
import {GlobalPopupCreateExceptionComponent} from './global-popup-create-exception.component';
import {GlobalPopupEditSchemeComponent} from './global-popup-edit-scheme.component';
import {GlobalPopupSetOverrideComponent} from './global-popup-set-override.component';
import {GlobalPopupTableOptionsComponent} from './global-popup-table-options/global-popup-table-options.component';
import {GlobalPopupAddTableFilterComponent} from './global-popup-add-table-filter.component';
import {GlobalPopupViewAttachmentComponent} from './global-popup-view-attachment.component';
import {GlobalPopupAboutUsComponent} from './global-popup-about-us.component';
import {GlobalPopupImagesCarouselComponent} from './global-popup-images-carousel.component';
import {GlobalPopupCreateWorkPreparationComponent} from './global-popup-create-work-preparation.component';
import {GlobalPopupEditWorkPreparationComponent} from './global-popup-edit-work-preparation.component';
import {GlobalPopupCreateWorkSpecificationComponent} from './global-popup-create-work-specification.component';
import {GlobalPopupCreateWeekprijsComponent} from './global-popup-create-weekprijs.component';
import {GlobalPopupCreateWorkActivityComponent} from './global-popup-create-work-activity.component';
import {GlobalPopupCreateCheckActivityComponent} from './global-popup-create-check-activity/global-popup-create-check-activity.component';
import {GlobalPopupMergeDropdownComponent} from './global-popup-merge-dropdown.component';
import {CommonDirectivesModule} from '../../../shared/directives/common/common-directives.module';
import {GlobalPopupScannerComponent} from './global-popup-scanner.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {CommonUIModule} from '../../../shared/components/commonUI/common-ui.module';
import {DefaultPopupWrapperComponent} from './default-popup-wrapper/default-popup-wrapper.component';
import {GlobalPopupMapLegendComponent} from './global-popup-map-legend/global-popup-map-legend.component';
import {GlobalPopupMapOptionComponent} from './global-popup-map-option.component';
import {GlobalPopupCreatePublicReportComponent} from './global-popup-create-public-report.component';
import {GlobalPopupBrowserWarningComponent} from './global-popup-browser-warning.component';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {GlobalPopupCreateGenericReportComponent} from './global-popup-create-generic-report.component';
import {GlobalPopupAddAttachmentToGenericReportComponent} from './global-popup-add-attachment-to-generic-report.component';
import {GlobalPopupEditTimeRegistrationComponent} from './global-popup-edit-time-registration.component';
import {GlobalPopupCreateUserComponent} from './global-popup-create-user.component';
import {GlobalPopupInviteUserComponent} from './global-popup-invite-user.component';
import {GlobalPopUpCreatePvStoringComponent} from './global-pop-up-create-pv-storing.component';
import {GlobalPopupTimerComponent} from './global-popup-timer.component';
import {GlobalPopupCreateMasterdataComponent} from './global-popup-create-masterdata.component';
import {GlobalPopupRequestRefreshComponent} from './global-popup-request-refresh/global-popup-request-refresh.component';
import {TableColumnSelectorModule} from '../../../shared/components/table/tableColumnSelector/tableColumnSelector.module';
import {GlobalPopupCreateMapItemsComponent} from './global-popup-create-map-items/global-popup-create-map-items.component';
import {MapModule} from '../../../shared/components/map/map.module';
import {GlobalPopupFirstLoginComponent} from './first-login/global-popup-first-login.component';
import { GlobalPopupBasicFormComponent } from './global-popup-basic-form/global-popup-basic-form.component';
import {GlobalPopupCreateMapserverComponent} from './global-popup-create-mapserver/global-popup-create-mapserver.component';
import {FileModule} from '../../../shared/components/file/file.module';
import {IconLegendComponent} from './global-popup-map-legend/icon-legend/icon-legend.component';
import {GlobalPopupCreateAreaalComponent} from './global-popup-create-areaal/global-popup-create-areaal.component';
import {GlobalPopUpCreateMsbMeldingComponent} from './global-pop-up-create-msb-melding.component';
import {GlobalPopUpCreateWorkorderFromExternalMalfunctionReportComponent} from './global-pop-up-create-workorder-from-external-malfunction-report.component';
import {GlobalPopUpExternalMalfunctionReportChangeStateComponent} from './global-pop-up-external-malfunction-report-change-state.component';
import {GlobalPopUpMsbMeldingChangeStateComponent} from './global-pop-up-msb-melding-change-state.component';
import {GlobalPopupViewStedinOrderComponent} from './global-popup-view-stedin-order.component';
import {GlobalPopupFormPreviewComponent} from './global-popup-form-preview.component';
import {GlobalPopUpAddMsbMeldingToWorkorderComponent} from './global-pop-up-add-msb-melding-to-workorder.component';
import {GlobalPopUpWerkbonSuggestionCreateNewWorkorderComponent} from './global-pop-up-werkbon-suggestion-create-new-workorder.component';
import {GlobalPopUpCreateAdditionalMsbMeldingComponent} from './global-pop-up-create-additional-msb-melding.component';
import {GlobalPopUpAddExternalMalfunctionReportToWorkorderComponent} from './global-pop-up-add-external-malfunction-report-to-workorder.component';
import {GlobalPopupLocalizationComponent} from "./global-popup-localization.component";
import {GlobalPopupDeviceMappingComponent} from './global-popup-device-mapping/global-popup-device-mapping.component';
import {MapDeviceMappingComponent} from './global-popup-device-mapping/map-device-mapping.component';
import {MapDeviceMappingService} from './global-popup-device-mapping/map-device-mapping.service';
import {GlobalPopupControlCalendarComponent} from './global-popup-control-calendar/global-popup-control-calendar.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {GlobalPopupControlProgramEditComponent} from "./global-popup-control-program-edit/global-popup-control-program-edit.component";
import {GlobalPopupControlProgramEditService} from "./global-popup-control-program-edit/global-popup-control-program-edit.service";
import {GlobalPopupControlExceptionEditComponent} from "./global-popup-control-exception-edit/global-popup-control-exception-edit.component";
import {GlobalPopupControlExceptionEditService} from "./global-popup-control-exception-edit/global-popup-control-exception-edit.service";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxColorsModule} from "ngx-colors";
import {GlobalPopupEditUserDefinedWidgetComponent} from "./global-popup-edit-user-defined-widget.component";
import {MatRadioModule} from "@angular/material/radio";
import {MatSelectModule} from "@angular/material/select";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        LumiFormModule,
        CommonPipesModule,
        CommonDirectivesModule,
        ZXingScannerModule,
        CommonUIModule,
        TableColumnSelectorModule,
        MapModule,
        FileModule,
        MatTooltipModule,
        NgxColorsModule,
        MatRadioModule,
        MatSelectModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    exports: [
        AbstractBasePopupComponent,
        GlobalPopupBasicComponent,
        GlobalPopupCreateMapserverComponent,
        GlobalPopupCreateAreaalComponent,
        GlobalPopupBasicInputComponent,
        GlobalPopupMoveItemComponent,
        GlobalPopUpCreatePvStoringComponent,
        GlobalPopUpMsbMeldingChangeStateComponent,
        GlobalPopUpCreateMsbMeldingComponent,
        GlobalPopUpCreateWorkorderFromExternalMalfunctionReportComponent,
        GlobalPopUpExternalMalfunctionReportChangeStateComponent,
        GlobalPopUpAddMsbMeldingToWorkorderComponent,
        GlobalPopUpWerkbonSuggestionCreateNewWorkorderComponent,
        GlobalPopUpAddExternalMalfunctionReportToWorkorderComponent,
        GlobalPopUpCreateAdditionalMsbMeldingComponent,
        GlobalPopupCreateDropdownComponent,
        GlobalPopupEditDropdownComponent,
        GlobalPopupMergeDropdownComponent,
        GlobalPopupCreateBaseObjectComponent,
        GlobalPopupCreateMapItemComponent,
        GlobalPopupCreateDrawingItemComponent,
        GlobalPopupDeleteBaseObjectComponent,
        GlobalPopupBrowserWarningComponent,
        IconLegendComponent,
        GlobalPopupCopyBaseobjectComponent,
        GlobalPopupCreateExceptionComponent,
        GlobalPopupEditSchemeComponent,
        GlobalPopupSetOverrideComponent,
        GlobalPopupTableOptionsComponent,
        GlobalPopupAboutUsComponent,
        GlobalPopupAddTableFilterComponent,
        GlobalPopupViewAttachmentComponent,
        GlobalPopupImagesCarouselComponent,
        GlobalPopupCreateWorkPreparationComponent,
        GlobalPopupEditWorkPreparationComponent,
        GlobalPopupEditUserDefinedWidgetComponent,
        GlobalPopupCreateWorkActivityComponent,
        GlobalPopupCreateCheckActivityComponent,
        GlobalPopupCreateWorkSpecificationComponent,
        GlobalPopupCreateWeekprijsComponent,
        GlobalPopupScannerComponent,
        GlobalPopupMapLegendComponent,
        GlobalPopupMapOptionComponent,
        GlobalPopupCreatePublicReportComponent,
        GlobalPopupCreateGenericReportComponent,
        GlobalPopupViewStedinOrderComponent,
        GlobalPopupFormPreviewComponent,
        GlobalPopupAddAttachmentToGenericReportComponent,
        GlobalPopupEditTimeRegistrationComponent,
        GlobalPopupCreateUserComponent,
        GlobalPopupInviteUserComponent,
        GlobalPopupTimerComponent,
        GlobalPopupCreateMasterdataComponent,
        GlobalPopupRequestRefreshComponent,
        GlobalPopupCreateMapItemsComponent,
        GlobalPopupFirstLoginComponent,
        GlobalPopupBasicFormComponent,
        GlobalPopupLocalizationComponent,
        GlobalPopupDeviceMappingComponent,
        MapDeviceMappingComponent,
        GlobalPopupControlCalendarComponent,
        GlobalPopupControlProgramEditComponent,
        GlobalPopupControlExceptionEditComponent
    ],
    declarations: [
        AbstractBasePopupComponent,
        GlobalPopupBasicComponent,
        GlobalPopupCreateMapserverComponent,
        GlobalPopupCreateAreaalComponent,
        GlobalPopupAboutUsComponent,
        GlobalPopupBasicInputComponent,
        GlobalPopupMoveItemComponent,
        GlobalPopUpCreatePvStoringComponent,
        GlobalPopUpMsbMeldingChangeStateComponent,
        GlobalPopUpAddExternalMalfunctionReportToWorkorderComponent,
        GlobalPopUpCreateMsbMeldingComponent,
        GlobalPopUpCreateWorkorderFromExternalMalfunctionReportComponent,
        GlobalPopUpExternalMalfunctionReportChangeStateComponent,
        GlobalPopUpAddMsbMeldingToWorkorderComponent,
        GlobalPopUpWerkbonSuggestionCreateNewWorkorderComponent,
        GlobalPopUpCreateAdditionalMsbMeldingComponent,
        GlobalPopupCreateDropdownComponent,
        GlobalPopupEditDropdownComponent,
        GlobalPopupMergeDropdownComponent,
        GlobalPopupCreateBaseObjectComponent,
        GlobalPopupCreateMapItemComponent,
        GlobalPopupCreateDrawingItemComponent,
        GlobalPopupDeleteBaseObjectComponent,
        GlobalPopupBrowserWarningComponent,
        IconLegendComponent,
        GlobalPopupCopyBaseobjectComponent,
        GlobalPopupCreateExceptionComponent,
        GlobalPopupEditSchemeComponent,
        GlobalPopupSetOverrideComponent,
        GlobalPopupTableOptionsComponent,
        GlobalPopupAddTableFilterComponent,
        GlobalPopupViewAttachmentComponent,
        GlobalPopupImagesCarouselComponent,
        GlobalPopupCreateWorkPreparationComponent,
        GlobalPopupEditUserDefinedWidgetComponent,
        GlobalPopupEditWorkPreparationComponent,
        GlobalPopupCreateWorkActivityComponent,
        GlobalPopupCreateCheckActivityComponent,
        GlobalPopupCreateWorkSpecificationComponent,
        GlobalPopupCreateWeekprijsComponent,
        GlobalPopupScannerComponent,
        DefaultPopupWrapperComponent,
        GlobalPopupMapLegendComponent,
        GlobalPopupMapOptionComponent,
        GlobalPopupCreatePublicReportComponent,
        GlobalPopupCreateGenericReportComponent,
        GlobalPopupViewStedinOrderComponent,
        GlobalPopupFormPreviewComponent,
        GlobalPopupAddAttachmentToGenericReportComponent,
        GlobalPopupEditTimeRegistrationComponent,
        GlobalPopupCreateUserComponent,
        GlobalPopupInviteUserComponent,
        GlobalPopupTimerComponent,
        GlobalPopupCreateMasterdataComponent,
        GlobalPopupRequestRefreshComponent,
        GlobalPopupCreateMapItemsComponent,
        GlobalPopupFirstLoginComponent,
        GlobalPopupBasicFormComponent,
        GlobalPopupLocalizationComponent,
        GlobalPopupDeviceMappingComponent,
        MapDeviceMappingComponent,
        GlobalPopupControlCalendarComponent,
        GlobalPopupControlProgramEditComponent,
        GlobalPopupControlExceptionEditComponent
    ],
    providers: [
        MapDeviceMappingService,
        GlobalPopupControlProgramEditService,
        GlobalPopupControlExceptionEditService
    ]
})

export class AlertsModule {
    constructor() {
    }
}
