import {IWidget, IWidgetData} from "./user-defined-dashboard.interface";
import {ButtonCode} from "../../../wrapper/global-alert/global-popup";
import {take} from "rxjs/operators";
import {UserDefinedDashboardComponent} from "./user-defined-dashboard.component";

declare var google:any;

export class UserDefinedWidget {
    public isLoadingChartData:boolean = true;
    public isInitialized:boolean = false;
    public widgetChartData:IWidgetData = null;
    private googleChart:any = null;
    private chartEditor:any = null;
    private defaultChartWidth:number = 760;

    constructor(public widgetData: IWidget, private userDefinedDashboardComponent: UserDefinedDashboardComponent) {
        this.widgetData = widgetData;
    }


    public initWidget():void{
        if(!this.isInitialized){
            this.initGoogleChart();
            this.getWidgetChartData();
            this.isInitialized = true;
        }
    }

    public initGoogleChart():void {
        google.charts.load('current', {'packages': ['corechart', 'charteditor']});
        if (google?.visualization) {
            this.chartEditor = new google.visualization.ChartEditor();
        }
    }

    private loadGoogleChartData():void{
        this.googleChart = new google.visualization.ChartWrapper({
            chartType: this.widgetData.chartOptions? this.widgetData.chartOptions.type : 'ColumnChart',
            dataTable:this.widgetChartData.datatable,
            containerId: 'widgetChartContainer-' + this.getId()
        });

        if(this.widgetData.chartOptions && this.widgetData.chartOptions.options){
            this.googleChart.setOptions(
                this.widgetData.chartOptions.options
            )
        }
        this.googleChart.setOption('width',this.defaultChartWidth)
        this.googleChart.setOption('height',300)
        this.googleChart.setOption('chartArea',{
            left:50,
            right:50,
        })

        this.drawChart();
        this.isLoadingChartData = false;
    }

    public openChartEditor(){
        this.chartEditor.openDialog(this.googleChart);
        const chartEditorBackground = document.createElement('div')
        chartEditorBackground.classList.add('google-visualization-charteditor-background')
        document.body.appendChild(chartEditorBackground)

        google.visualization.events.addListener(this.chartEditor, 'ok', () =>{this.saveChartEdit()} );
        google.visualization.events.addListener(this.chartEditor, 'cancel', () =>{this.closeChartEditor()} );
        chartEditorBackground.addEventListener('click', () =>{this.closeChartEditor()})
    }

    private closeChartEditor(){
        this.chartEditor.closeDialog(this.googleChart, {});
        this.removeEditorBackground()
    }

    private saveChartEdit(){
        this.googleChart = this.chartEditor.getChartWrapper();
        this.redrawChart();
        this.removeEditorBackground()
    }

    private redrawChart() {
        const width = Math.min(document.documentElement.clientWidth, window.innerWidth || 0) + 'px';
        const height = Math.min(document.documentElement.clientHeight, window.innerHeight || 0) + 'px';
        this.googleChart.setOption('width', width);
        this.googleChart.setOption('height', height);
        this.drawChart();
        this.updateWidgetChartOptions({options: this.googleChart.getOptions(), type: this.googleChart.getChartType()})
    }

    public drawChart(){
        if(this.googleChart){
            const elementId:string = 'widgetChartContainer-' + this.getId();
            let containerWidth:number = document.getElementById(elementId)?.closest('.widget-container').getBoundingClientRect().width - 50;
            if(containerWidth < this.defaultChartWidth){
                this.googleChart.setOption('width', containerWidth);
            } else {
                this.googleChart.setOption('width', this.defaultChartWidth);
            }
            this.googleChart.draw(elementId)
        }
    }

    private removeEditorBackground(){
        const backgroundElement = document.getElementsByClassName('google-visualization-charteditor-background')[0]
        backgroundElement?.parentNode.removeChild(backgroundElement)
    }

    public getId():number{
        return this.widgetData.id;
    }

    public getRow():number{
        return this.widgetData.row;
    }

    public getColumn():number{
        return this.widgetData.column;
    }

    public getTitle():string{
        return this.widgetData.title;
    }

    public getDescription():string{
        return this.widgetData.description ? this.widgetData.description : '';
    }

    public isEmptyWidget():boolean{
        if(this.widgetChartData){
            return Object.keys(this.widgetChartData.datatable).length === 0;
        } else {
            return false;
        }
    }

    private clearChartContainer():void{
        document.getElementById('widgetChartContainer-' + this.getId()).innerHTML = '';
    }

    public updateWidgetChartOptions(chartOptions:any):void{
        this.userDefinedDashboardComponent.subscriptions.push(this.userDefinedDashboardComponent.userDashboardService.updateWidgetChartOptions(this.widgetData.updateChartOptionsUrl, chartOptions).pipe(take(1)).subscribe((data) => {}));
        this.widgetData.chartOptions = {options:{'chart-options':chartOptions.options},type:chartOptions.type}
    }

    private getWidgetChartData(){
        this.userDefinedDashboardComponent.subscriptions.push(this.userDefinedDashboardComponent.userDashboardService.getWidgetData(this.widgetData.dataUrl).pipe(take(1)).subscribe((data) => {
            this.widgetChartData = data;
            this.loadGoogleChartData();
        }));
    }

    public editWidget():void{
        this.userDefinedDashboardComponent.globalAlertService.editPopupUserDefinedWidget(
            'Edit widget',
            '',
            this.widgetData.getEditFormUrl,
            this.widgetData.saveEditFormUrl,
            (buttonCode, response) => {
                this.widgetData = response;
                this.clearChartContainer();
                this.isLoadingChartData = true;
                this.getWidgetChartData();
            }, () => {}
        );
    }

    public changeWidgetPosition(row:number,column:number):void{
        this.userDefinedDashboardComponent.subscriptions.push(this.userDefinedDashboardComponent.userDashboardService.updateWidgetPosition(this.getId(),row,column).pipe(take(1)).subscribe((data) => {}));
        this.widgetData.row = row;
        this.widgetData.column = column;
    }

    public removeWidget(){
        this.userDefinedDashboardComponent.globalAlertService.addPopup(this.userDefinedDashboardComponent.ts.translate("userdefined-dashboard.remove-widget-title"), this.userDefinedDashboardComponent.ts.translate("userdefined-dashboard.remove-widget-label"),
            [{label:this.userDefinedDashboardComponent.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                {label:this.userDefinedDashboardComponent.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                    callback:() => {
                        this.userDefinedDashboardComponent.subscriptions.push(this.userDefinedDashboardComponent.userDashboardService.removeWidget(this.widgetData.deleteWidgetUrl).pipe(take(1)).subscribe((data) => {}));
                        this.isInitialized = false;
                        this.userDefinedDashboardComponent.userWidgetList.splice(this.userDefinedDashboardComponent.userWidgetList.findIndex(_x => _x == this), 1)
                    },
                    isPrimary:false}
            ], () => {})
    }
}
