import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild
} from '@angular/core';
import {GlobalAlertService} from "../../global-alert.service";
import {ButtonCode, GlobalPopup} from '../../global-popup';
import {FormDataService} from "../../../../shared/components/form/services/form-data.service";
import {TranslateService} from "../../../../shared/services/translate/translate.service";
import {DefaultPopupWrapperComponent} from "../default-popup-wrapper/default-popup-wrapper.component";
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {TooltipService} from "../../../../shared/services/tooltip/tooltip.service";
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import {MapDeviceMappingService} from './map-device-mapping.service';
import {finalize, take} from 'rxjs/operators';

@Component ({
    selector: 'global-popup-device-mapping-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component #wrapper [componentRef]="this" [disableButtonsOverride]="disableButtonsOverride" [globalPopup]="globalPopup" [showLoader]="isLoading" [title]="this.ts.translate('asset-mapping.title')" [description]="this.ts.translate('asset-mapping.description')" (onClose)="closePopup($event)">
            <div class="d-flex lightgrey-background mt-1">
                <div class="card w-100 z-depth-0">
                    <map-device-mapping
                            [popupData]="this.globalPopup.data"
                            (onChangeLoadingState)="setIsLoading($event)"
                            (onSelectBaseObjectId)="setSelectedBaseObjectId($event)">
                    </map-device-mapping>
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupDeviceMappingComponent extends AbstractBasePopupComponent implements AfterViewInit {
    @ViewChild('wrapper', {static: false}) wrapper: DefaultPopupWrapperComponent;
    public selectedBaseObjectId:number = 0;
    public disableButtonsOverride:any = (button:any) => {return (this.config && this.config.disabled && (button.code != ButtonCode.ANNULEREN || button.code != ButtonCode.UNMAP_DEVICE)) || (this.selectedBaseObjectId === 0 && (button.code != ButtonCode.ANNULEREN || button.code != ButtonCode.UNMAP_DEVICE))};

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected elementRef:ElementRef, protected ts:TranslateService, protected tooltipService:TooltipService, protected logger:LoggerService, private mapDeviceMappingService: MapDeviceMappingService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit(){
        this.submitButton = this.getSubmitButton();
    }

    public setSelectedBaseObjectId(baseObjectId:number):void{
        this.selectedBaseObjectId = baseObjectId
        if(baseObjectId === 0){
            this.disableButtonsOverride = (button:any) => {return (button.code != ButtonCode.ANNULEREN && button.code != ButtonCode.UNMAP_DEVICE)};
        } else {
            this.disableButtonsOverride = () => {return false};
        }
    }

    public setIsLoading(loading:boolean):void{
        this.isLoading = loading
        this.cd.detectChanges()
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        if (button == this.submitButton && this.selectedBaseObjectId !== 0 && this.globalPopup && this.globalPopup.data && this.globalPopup.data.baseObjectId){
            this.mapDeviceMappingService.saveSelectedDevice(this.globalPopup.data.baseObjectId, this.selectedBaseObjectId).pipe(
                take(1),
                finalize(() => {
                    this.onPopupAction.emit({event:event, alert:alert, button:button});
                })
            ).subscribe();
        }else if(button.code == ButtonCode.UNMAP_DEVICE && this.globalPopup && this.globalPopup.data && this.globalPopup.data.baseObjectId){
            this.mapDeviceMappingService.unmapDevice(this.globalPopup.data.baseObjectId).pipe(
                take(1),
                finalize(() => {
                    this.onPopupAction.emit({event:event, alert:alert, button:button});
                })
            ).subscribe();
        } else{
            //Hide alert and perform callback of button
            this.onPopupAction.emit({event:event, alert:alert, button:button});
        }
    }

    public closePopup(event:any):void {
        this.doDefaultCloseHandling(event, this.config && !this.config.disabled);
    }
}
