import {MapCoreV2Component} from "../map-core-V2.component";
import {
    IMapItemStyles,
    IParallelHeading,
    ISnappablePoint,
    IMapItem,
    IMapItemOriginalValue, ISelectedBaseObjectIds, IAnnotation, mapItemTypes
} from "./map-manager.interface";
import {GlobalModel} from "../../../services/state/global.model";


export class MapItem {
    public googleMarker: google.maps.Marker = null;
    public googlePolyline: google.maps.Polyline = null;
    public googlePolygon: google.maps.Polygon = null;
    public googlePolygonPreSelectOptions = {};
    public googlePolygonInfoWindow: google.maps.InfoWindow = null;
    public googlePolylineSelected: google.maps.Polyline = null;
    public googlePolylineOriginalValue:IMapItemOriginalValue = null;
    public googlePolylineInfoWindow: google.maps.InfoWindow = null;
    public annotation: IAnnotation = {
        infoWindow: null,
        polyline: null
    };
    public isHighlighted:boolean = false;
    public isSelected:boolean = false;

    constructor(private mapCoreV2: MapCoreV2Component, public mapItemData: IMapItem, private model:GlobalModel) {
        this.mapItemData = mapItemData;
    }

    public initGoogleObject(): void {
        //Initialize the map item
        if (typeof this.mapItemData.markerV1 !== 'undefined' && this.googleMarker === null) {
            //It is a V1 marker that has not yet been initialized. Set V1 marker to V2 and place on map.
            this.googleMarker = this.mapItemData.markerV1
            this.googleMarker.setVisible(true)
            if(this.mapItemData.icon == 'jnt-blue' || this.mapItemData.icon == 'jnt-blue-sel'){
                this.googleMarker.setOptions({
                    icon:{
                        url: this.mapItemData.markerV1.getIcon().toString(),
                        anchor: new google.maps.Point(this.mapCoreV2.mapSettings.JOINT_ICON_CENTER, this.mapCoreV2.mapSettings.JOINT_ICON_CENTER),
                    }
                })
            }
            this.googleMarker.setMap(this.mapCoreV2.map)
        } else if (this.mapItemData.objectType !== 'annotation' && this.mapItemData.geometry && this.mapItemData.geometry.type === 'LineString' && this.googlePolyline === null) {
            //It is a linestring and has not yet been initialized.
            //Find style by styleId
            const style: IMapItemStyles = this.getStyle();

            //Generate array of google coordinates
            const path = [];
            this.mapItemData.geometry.coordinates.map(_path => {
                path.push(new google.maps.LatLng(_path[0], _path[1]))
            })

            //Make new polyline and add to map
            this.googlePolyline = new google.maps.Polyline({
                path: path,
                strokeWeight: style.strokeWeight,
                strokeColor: style.strokeColor,
                map: this.mapCoreV2.map,
                editable: false,
                clickable: true,
                visible: true,
                zIndex: style.zIndex,
            });


            //Save original value so it can be reverted without a server call
            this.googlePolylineOriginalValue = {
                path: path
            }

            //If the stroke style is dotted, make the line dotted
            if (style.strokeStyle.toLowerCase() === 'dotted') {
                this.makeLineDotted();
            } else if (style.strokeStyle.toLowerCase() === 'diamond') {
                this.makeLineDiamond();
            }

            //Create the events for the map item and do not register so they will not be destroyed when grid is deactivated
            this.mapCoreV2.mapEventManagerService.createGMEventDomListener('click', this.googlePolyline, 'polyline',(event) => {
                this.mapCoreV2.mapHelperManagerService.onMapitemClick(this, event)
            }, [], false)
            this.mapCoreV2.mapEventManagerService.createGMEventDomListener('mouseover', this.googlePolyline, 'polyline',(event) => {
                //Trigger mouseover to V1 so it can handle whether or not the table row should be highlighted.
                this.mapCoreV2.handleMapItemMouseOver(this)
                if(this.mapCoreV2.showInfoWindows){
                    this.showInfoWindow(event)
                }
            }, [], false)
            this.mapCoreV2.mapEventManagerService.createGMEventDomListener('mouseout', this.googlePolyline, 'polyline',(event) => {
                this.hideInfoWindow()
            }, [], false)

            //Create the polyline InfoWindow
            this.createPolylineInfoWindow()
        } else if(this.mapItemData.objectType === 'annotation' && this.annotation.polyline === null && this.annotation.infoWindow === null) {
            //It is an annotation

            //Generate array of google coordinates
            const path = [];
            this.mapItemData.geometry.coordinates.map(_path => {
                path.push(new google.maps.LatLng(_path[0], _path[1]))
            })
            this.annotation.polyline = new google.maps.Polyline({
                path: path,
                strokeWeight: this.mapCoreV2.mapSettings.ANNOTATION_STROKE_WEIGHT,
                strokeColor: this.mapCoreV2.mapSettings.ANNOTATION_STROKE_COLOR,
                map: this.mapCoreV2.map,
                editable: false,
                clickable: true,
                visible: true,
                zIndex: this.mapCoreV2.mapSettings.ANNOTATION_ZINDEX
            });
            const randomId = this.mapCoreV2.mapHelperManagerService.randomId();
            this.annotation.infoWindow = new google.maps.InfoWindow({
                content: `<div class="mapitem-annotation-container mapitem-annotation-id-${randomId}">${this.convertLabelToAnnotationHtml()}</div>`,
                position: new google.maps.LatLng(this.mapItemData.geometry.coordinates.at(-1)[0], this.mapItemData.geometry.coordinates.at(-1)[1]),
                ariaLabel: 'mapitem-annotation',
                disableAutoPan: true,
            })
            this.showAnnotation()

            //Create click listener for the infoWindow cable items
            this.mapCoreV2.mapEventManagerService.createGMListener('domready', this.annotation.infoWindow, ()=> {
                const annotationItem = document.querySelectorAll('.mapitem-annotation-id-'+randomId)[0];
                const annotationCableItems = annotationItem.getElementsByClassName('annotation-cable-item');
                for (let i = 0; i < annotationCableItems.length; i++) {
                    const baseObjectId = annotationCableItems[i].getAttribute('attr-baseobjectid');
                    this.mapCoreV2.mapEventManagerService.createGMEventDomListener('click', annotationCableItems[i], 'annotation-cable-item', (event) => {
                        this.mapCoreV2.mapHelperManagerService.onCableItemClick(Number(baseObjectId))
                    })
                }
            }, false)
            //Create click listener for the polyline
            this.mapCoreV2.mapEventManagerService.createGMEventDomListener('click', this.annotation.polyline, 'annotation polyline',(event) => {
                this.mapCoreV2.mapHelperManagerService.onAnnotationItemClick(this)
            }, [], false)
        } else if (this.mapItemData.objectType === 'cluster' && this.mapItemData.geometry && this.mapItemData.geometry.type === 'Polygon' && this.googlePolygon === null) {
            //It is a polygon
            const colorToUseNumber = Math.min(Math.trunc(this.mapItemData.maxClusterSize / this.mapItemData.total), 10) - 1
            const colorToUse = this.model.clusterColorList[colorToUseNumber]

            let strokeColor = '#FFFFFF';
            let strokeOpacity = 1;
            let strokeWeight= .2;


            if (this.mapItemData.offline > 0) {
                strokeColor = '#FF0000';
                strokeOpacity = 0.8;
                strokeWeight = 1;
            }

            this.googlePolygon = new google.maps.Polygon({
                paths: this.mapItemData.geometry.coordinates,
                strokeColor: strokeColor,
                strokeOpacity: strokeOpacity,
                strokeWeight: strokeWeight,
                fillColor: colorToUse,
                fillOpacity:.75,
                zIndex:colorToUseNumber,
                editable: false,
                clickable: true,
                visible: true,
            });
            this.googlePolygon.setMap(this.mapCoreV2.map)

            //Create the events for the map item and do not register, so they will not be destroyed when grid is deactivated
            this.mapCoreV2.mapEventManagerService.createGMEventDomListener('click', this.googlePolygon, 'polygon',(event) => {
                this.mapCoreV2.mapHelperManagerService.onMapitemClick(this, event)
            }, [], false)
            this.mapCoreV2.mapEventManagerService.createGMEventDomListener('mouseover', this.googlePolygon, 'polygon',(event) => {
                //Trigger mouseover to V1, so it can handle whether the table row should be highlighted.
                this.mapCoreV2.handleMapItemMouseOver(this)
                if(this.mapCoreV2.showInfoWindows && this.mapCoreV2.allowInfoWindowPopup()){
                    this.createPolygonInfoWindow();
                }
            }, [], false)
            this.mapCoreV2.mapEventManagerService.createGMEventDomListener('mouseout', this.googlePolygon, 'polygon',(event) => {
                this.removePolygonInfoWindow();
            }, [], false)

            //Set mouse move event because the mouse move event of the map does not trigger when hovering over a map item. This goes for all map items, but it is not noticable because a marker has only 1 pixel. A cluster is big so only add the event here.
            this.mapCoreV2.mapEventManagerService.createGMEventDomListener('mousemove', this.googlePolygon, 'polygon',(event) => {
                this.mapCoreV2.handleMapMouseMove(event);
            }, [], false)
        }

        if(typeof this.mapItemData.markerV1 === 'undefined' && this.mapItemData.objectType !== 'annotation' && this.mapItemData.geometry && (this.mapItemData.geometry.type === 'Polygon' || this.mapItemData.geometry.type === 'LineString') && (this.googlePolyline === null || this.googlePolygon === null)){
            //Subscribe to the selected items and see if the current item is selected, then highlight.
            this.mapCoreV2.subSelectedItems = this.model.assetsSelectedItems.subscribe((selectedItems) => {
                const isBaseObjectIdInSelectedArray = selectedItems.map(_selectedItem => _selectedItem.baseObjectId).indexOf(this.getBaseObjectId().toString());
                if(isBaseObjectIdInSelectedArray !== -1){
                    //Current baseObjectId is in selected array
                    this.highlight(true)

                    //If a single item has been selected, set as selected map item
                    if(selectedItems.length === 1){
                        this.mapCoreV2.selectedMapItem = this;
                    }
                } else {
                    //Current baseObjectId is not in selected array
                    this.highlight(false)
                }
            });
        }
    }

    private convertLabelToAnnotationHtml():string{
        let htmlOutput: HTMLDivElement = document.createElement('div');
        let totalItemCount:number = 0;

        //Label layout: LABELCODE ; BASEOBJECTID | LABELCODE ; BASEOBJECTID
        if(this.mapItemData.label != ''){
            this.mapItemData.label.split('|').map(_cableRow => {
                const cableData = _cableRow.split(';');
                if(cableData[1]){
                    totalItemCount++
                    const div = document.createElement('div')
                    div.className = 'annotation-cable-item'
                    div.setAttribute('attr-baseobjectid',cableData[1])
                    div.innerHTML = cableData[0]
                    htmlOutput.appendChild(div);
                }
            })
        }
        if(totalItemCount == 0) {
            htmlOutput.innerHTML = this.mapCoreV2.ts.translate('annotation.nodata')
        }
        return htmlOutput.outerHTML;
    }

    private handleHighlightMapItemInAnnotation(highlight:boolean):void{
        let annotationMapItems = document.getElementsByClassName('annotation-cable-item');

        for (let i = 0; i < annotationMapItems.length; i++) {
            const baseObjectId = Number(annotationMapItems[i].getAttribute('attr-baseobjectid'));
            if (baseObjectId !== null) {
                if(baseObjectId === this.getBaseObjectId()){
                    if(highlight){
                        annotationMapItems[i].classList.add('selected')
                    } else {
                        annotationMapItems[i].classList.remove('selected')
                    }
                }
            }
        }
    }

    public getLayerId(): number {
        return this.mapItemData.layerId;
    }

    public getBaseObjectId(): number {
        return this.mapItemData.baseObjects[0].id;
    }

    public getLabel():string {
        return this.mapItemData.label;
    }

    public getId(): number {
        return this.mapItemData.id;
    }

    public getPath():google.maps.LatLng[]{
        if(this.googlePolyline !== null){
            return this.googlePolyline.getPath().getArray()
        } else if(this.googlePolygon !== null){
            return this.googlePolygon.getPath().getArray()
        }
    }

    public setClickable(isClickable: boolean): void {
        if (this.googleMarker !== null) {
            this.googleMarker.setClickable(isClickable);
        } else if (this.googlePolyline !== null) {
            this.googlePolyline.setOptions({clickable: isClickable});
        }
    }

    public setSelected(selected:boolean):void{
        this.isSelected = selected
    }

    public showInfoWindow(event:any):void{
        if (this.googlePolyline !== null) {
            this.googlePolylineInfoWindow.setPosition(new google.maps.LatLng(event.latLng))
            this.googlePolylineInfoWindow.open({map: this.mapCoreV2.map})
        }
    }

    public showAnnotation():void{
        if(this.annotation.polyline !== null){
            this.annotation.polyline.setVisible(true)
        }
        if(this.annotation.infoWindow !== null){
            this.annotation.infoWindow.open({map:this.mapCoreV2.map})
        }
        if(this.isSelected){
            this.highlight(true)
        }
    }

    public hideAnnotation():void{
        if(this.annotation.polyline !== null){
            this.annotation.polyline.setVisible(false)
        }
        if(this.annotation.infoWindow !== null){
            this.annotation.infoWindow.close()
        }
        if(this.isHighlighted){
            this.highlight(false)
        }
    }

    public showInfoWindowOnEdge(showOnEnd:boolean = false):void{
        if (this.googlePolyline !== null) {
            //Get last position in array
            let infoWindowPosition = this.googlePolyline.getPath().getArray().at(-1)
            if(showOnEnd){
                //Get first position in array
                infoWindowPosition = this.googlePolyline.getPath().getArray().at(0)
            }
            this.googlePolylineInfoWindow.setPosition(infoWindowPosition)
            this.googlePolylineInfoWindow.open({map: this.mapCoreV2.map})
        }
    }

    public hideInfoWindow():void{
        if (this.googlePolylineInfoWindow !== null) {
            this.googlePolylineInfoWindow.close()
        }
    }

    public setEditable(isEditable: boolean): void {
        if (this.googleMarker !== null) {
            //Marker cannot be edited
        } else if (this.googlePolyline !== null) {
            this.googlePolyline.setOptions({editable: isEditable});
        }
    }

    public getObjectTypeTranslation(objectType:string):string{
        let translation:string = objectType
        if(objectType == 'cable'){
            translation = this.mapCoreV2.ts.translate('objecttype.cable')
        } else if(objectType == 'wire'){
            translation = this.mapCoreV2.ts.translate('objecttype.wire')
        } else if(objectType == 'conduit'){
            translation = this.mapCoreV2.ts.translate('objecttype.conduit')
        } else if(objectType == 'joint'){
            translation = this.mapCoreV2.ts.translate('objecttype.joint')
        } else if(objectType == 'annotation'){
            translation = this.mapCoreV2.ts.translate('objecttype.annotation')
        }
        return translation
    }

    public getStyle(): IMapItemStyles {
        //Find style by styleId
        let style: IMapItemStyles = this.mapCoreV2.model.mapItemStyles.value.filter(_style => {
            return _style.id == this.mapItemData.styleId
        })[0];

        if (typeof style === 'undefined') {
            style = this.mapCoreV2.model.mapItemStyles.value[0]
        }
        return style
    }

    private createPolylineInfoWindow():void{
        //Currently hardcoded InfoWindow
        let outerDiv: HTMLDivElement = document.createElement('div');
        outerDiv.innerHTML = `
            <div class="infowindow-container">
                <div class="d-flex justify-content-between  align-items-center">
                    <span><strong>${this.mapCoreV2.ts.translate('grid.infoWindow.type')}:</strong> ${this.getObjectTypeTranslation(this.mapItemData.objectType)}</span>
                    <div class="d-flex justify-content-end align-items-center pl-3">
                        <img src="/assets/img/lumicon/16x16/cbl-blue.png" class="pr-1" />
                        <span class="form-link-anchor">${this.mapItemData.baseObjects[0].label}</span>
                    </div>
                </div>
                <div class="flex-column map-infowindow-table d-flex">
                    <span><strong>${this.mapCoreV2.ts.translate('grid.infoWindow.code')}:</strong> ${this.mapItemData.baseObjects[0].label}</span>
                    <span><strong>${this.mapCoreV2.ts.translate('grid.label.linestyle')}:</strong> ${this.mapCoreV2.mapHelperManagerService.getStyleName(this.mapItemData.styleId)}</span>
                    <span><strong>${this.mapCoreV2.ts.translate('grid.label.linelayer')}:</strong> ${this.mapCoreV2.mapHelperManagerService.getLayerName(this.mapItemData.layerId)}</span>
                </div>
            </div>
        `;

        //Info window link click
        const mapItemSpanItem = outerDiv.getElementsByClassName('form-link-anchor')[0];
        this.mapCoreV2.mapEventManagerService.createGMEventDomListener('click', mapItemSpanItem, 'infoWindowMapItemLink',(event) => {
            this.mapCoreV2.mapHelperManagerService.onMapitemClick(this, event, true)
        }, [], false)


        this.googlePolylineInfoWindow = new google.maps.InfoWindow({
            content: outerDiv,
            position:new google.maps.LatLng(this.mapItemData.geometry.coordinates[0][0], this.mapItemData.geometry.coordinates[0][1]),
            ariaLabel: "drawing-manager-infowindow",
            disableAutoPan:true
        });
    }

    private createPolygonInfoWindow():void{
        if(this.googlePolygonInfoWindow === null) {
            //Currently hardcoded InfoWindow
            let innerHtml = '';
            const outerDiv: HTMLDivElement = document.createElement('div');
            innerHtml = `
                <div class="infowindow-container">
                    <div class="d-flex justify-content-between  align-items-center">
                        <span><strong>Aantal:</strong> ${this.mapItemData.total}</span>
                    </div>
                    <div class="flex-column map-infowindow-table d-flex">
                    `;
            if(this.mapItemData.offline){
                innerHtml += `
                        <span><strong>Aantal offline:</strong> ${this.mapItemData.offline}</span>
                       `;
            }

            if(this.mapItemData.fault){
                innerHtml += `
                        <span><strong>Aantal fout:</strong> ${this.mapItemData.fault}</span>
                       `;
            }

            if(this.mapItemData.attention){
                innerHtml += `
                        <span><strong>Aantal attentie:</strong> ${this.mapItemData.attention}</span>
                       `;
            }

            innerHtml += `
                    </div>
                </div>
            `;

            outerDiv.innerHTML = innerHtml;

            this.googlePolygonInfoWindow = new google.maps.InfoWindow({
                content: outerDiv,
                position: new google.maps.LatLng({lat: this.mapItemData.lat, lng: this.mapItemData.lng}),
                ariaLabel: "drawing-manager-infowindow",
                disableAutoPan: true,
                pixelOffset: new google.maps.Size(0, -10)
            });
            this.googlePolygonInfoWindow.setPosition(new google.maps.LatLng({
                lat: this.mapItemData.lat,
                lng: this.mapItemData.lng
            }))
            this.googlePolygonInfoWindow.open({map: this.mapCoreV2.map})
        }
    }

    private removePolygonInfoWindow():void{
        if(this.googlePolygonInfoWindow !== null){
            this.googlePolygonInfoWindow.close()
            this.googlePolygonInfoWindow = null;
        }
    }


    public revertEditChanges():void{
        //Only change that can be reverted is the path.
        this.googlePolyline.setOptions({
            path: this.googlePolylineOriginalValue.path
        })
    }

    public hasEditItemChanged():boolean{
        //Only chnage that can be checked is the path
        if(this.googlePolyline.getPath().getArray().toString() != this.googlePolylineOriginalValue.path){
            return true
        } else {
            return false
        }
    }

    public getSnappablePoints(bound: google.maps.LatLngBounds): ISnappablePoint[] {
        //Get snappable points within bounds
        const snappableList: ISnappablePoint[] = []
        if (this.googleMarker !== null && this.isInBounds(bound)) {
            snappableList.push({
                mapItem: this,
                latLng: this.googleMarker.getPosition()
            })
        } else if (this.googlePolyline !== null) {
            this.googlePolyline.getPath().getArray().map(_position => {
                if (bound.contains(_position)) {
                    snappableList.push({
                        mapItem: this,
                        latLng: _position
                    })
                }
            })
        }
        return snappableList;
    }

    public isInBounds(bound): boolean {
        //Check if the bound is within the bounds
        let isInBound = false;
        if (this.googleMarker !== null) {
            isInBound = bound.contains(this.googleMarker.getPosition());
        } else if (this.googlePolyline !== null) {
            this.googlePolyline.getPath().getArray().map(_position => {
                if (bound.contains(_position) === true) {
                    isInBound = true;
                }
            })
        } else if (this.googlePolygon !== null) {
            this.googlePolygon.getPath().getArray().map(_position => {
                if (bound.contains(_position) === true) {
                    isInBound = true;
                }
            })
        }
        return isInBound;
    }

    public getParallelHeadings(bound: google.maps.LatLngBounds): IParallelHeading[] {
        const headings: IParallelHeading[] = []
        if (this.googleMarker !== null) {
            //Markers have no heading
            return []
        } else if (this.googlePolyline !== null) {
            const posistionArray = this.googlePolyline.getPath().getArray();
            for (let i = 0; i < posistionArray.length - 1; i++) {
                const start = posistionArray[i];
                const end = posistionArray[i + 1];

                //Create bounds from start and end position
                let lineBounds = new google.maps.LatLngBounds()
                lineBounds.extend(start)
                lineBounds.extend(end)

                //Check if the given bound intersects with the line bounds
                if (bound.intersects(lineBounds)) {
                    let addNewHeading: boolean = true;

                    if(headings.length > 0){
                        const distToLastParallelHeading = google.maps.geometry.spherical.computeDistanceBetween(headings.at(-1).startLatLng, start);
                        if(distToLastParallelHeading >= (this.mapCoreV2.mapSettings.DISTANCE_TO_DRAW_PARALLEL / 2)){ //Only add heading if the distance to the last heading is greater than the given distance to prevent the creating of too much lines
                            addNewHeading = true
                        } else {
                            addNewHeading = false
                        }
                    }

                    if(addNewHeading){
                        const heading = google.maps.geometry.spherical.computeHeading(start, end)
                        const reverseHeading = (heading < 0) ? (heading + 180) : (heading - 180)
                        const lineSegmentPoly = new google.maps.Polyline({
                            path: [start, end]
                        })

                        //Add line segment shifted by 10 cm to one side
                        let tempHeading = heading - 90;
                        const headingOffsetA = (tempHeading < 0) ? (tempHeading + 180) : (tempHeading - 180);
                        tempHeading = heading + 90;
                        const headingOffsetB = (tempHeading < 0) ? (tempHeading + 180) : (tempHeading - 180);

                        const startOffsetLonger = google.maps.geometry.spherical.computeOffset(start, 0.2, reverseHeading)
                        const startOffsetA = google.maps.geometry.spherical.computeOffset(startOffsetLonger, 0.1, headingOffsetA)
                        const startOffsetB = google.maps.geometry.spherical.computeOffset(startOffsetLonger, 0.1, headingOffsetB)

                        const endOffsetLonger = google.maps.geometry.spherical.computeOffset(end, 0.2, heading)
                        const endOffsetA = google.maps.geometry.spherical.computeOffset(endOffsetLonger, 0.1, headingOffsetA)
                        const endOffsetB = google.maps.geometry.spherical.computeOffset(endOffsetLonger, 0.1, headingOffsetB)

                        const lineSegmentOffsetA = new google.maps.Polyline({
                            path: [startOffsetA, endOffsetA],
                            clickable:false,
                            strokeWeight:1,
                            strokeColor:'#c9c9c9',
                            map: this.mapCoreV2.map,
                            strokeOpacity: 0,
                            icons: [{
                                icon: {
                                    path: "M 0,-1 0,1",
                                    strokeOpacity: 1,
                                    scale: 1,
                                },
                                offset: "0",
                                repeat: 4 + "px",
                            }]
                        })

                        const lineSegmentOffsetB = new google.maps.Polyline({
                            path: [startOffsetB, endOffsetB],
                            clickable:false,
                            strokeWeight:1,
                            strokeColor:'#c9c9c9',
                            map: this.mapCoreV2.map,
                            strokeOpacity: 0,
                            icons: [{
                                icon: {
                                    path: "M 0,-1 0,1",
                                    strokeOpacity: 1,
                                    scale: 1,
                                },
                                offset: "0",
                                repeat: 4 + "px",
                            }]
                        })

                        //Add line segment shifted by 10 cm to other side
                        headings.push({
                            mapItem: this,
                            heading: heading,
                            startLatLng: startOffsetA,
                            endLatLng: endOffsetA,
                            lineSegmentPoly: lineSegmentOffsetA
                        })
                        headings.push({
                            mapItem: this,
                            heading: heading,
                            startLatLng: startOffsetB,
                            endLatLng: endOffsetB,
                            lineSegmentPoly: lineSegmentOffsetB
                        })

                        //Add heading
                        headings.push({
                            mapItem: this,
                            heading: heading,
                            startLatLng: start,
                            endLatLng: end,
                            lineSegmentPoly: lineSegmentPoly
                        })
                    }
                }
            }
        }
        this.mapCoreV2.mapDrawingManagerService.snappedPolylines.push(...headings)
        return headings;
    }

    public removeFromMap(): void {
        if (this.googlePolylineSelected){
            this.highlight(false)
        }
        if (this.googleMarker !== null) {
            this.googleMarker.setVisible(false)
            this.googleMarker.setMap(null);
            this.googleMarker = null;
        }
        if (this.googlePolyline !== null) {
            this.googlePolyline.setMap(null);
            this.googlePolyline = null;
        }
        if (this.googlePolygon !== null) {
            this.googlePolygon.setMap(null);
            this.googlePolygon = null;
        }
        if (this.annotation.polyline !== null){
            this.annotation.polyline.setMap(null);
            this.annotation.polyline = null;
        }
        if (this.annotation.infoWindow !== null){
            this.annotation.infoWindow.close();
            this.annotation.infoWindow = null;
        }
    }

    public highlight(highlight: boolean) {
        if (this.googleMarker !== null) {
            //Markers are highlighted in V1
        } else if (this.googlePolyline !== null) {
            if (highlight && !this.isHighlighted) {
                //Highlight and not yet highlighted
                this.googlePolylineSelected = new google.maps.Polyline({
                    path: this.googlePolyline.getPath().getArray(),
                    strokeWeight: Number(this.googlePolyline.get('strokeWeight')) + 2,
                    strokeColor: '#000000',
                    map: this.mapCoreV2.map,
                    editable: false,
                    clickable: false,
                    visible: true,
                    zIndex: this.googlePolyline.get('zIndex') - 1,
                });
                this.isHighlighted = true;
                this.mapCoreV2.mapHelperManagerService.mutateTotalSelectedItems(1)
            } else if(!highlight && this.isHighlighted) {
                //Do not highlight and is highlighted
                this.googlePolylineSelected.setMap(null);
                this.googlePolylineSelected.setVisible(false);
                this.isHighlighted = false;
                this.mapCoreV2.mapHelperManagerService.mutateTotalSelectedItems(-1)
            }
        } else if (this.annotation.polyline !== null) {
            if (highlight && !this.isHighlighted) {
                //Highlight and not yet highlighted
                this.googlePolylineSelected = new google.maps.Polyline({
                    path: this.annotation.polyline.getPath().getArray(),
                    strokeWeight: Number(this.annotation.polyline.get('strokeWeight')) + 2,
                    strokeColor: '#000000',
                    map: this.mapCoreV2.map,
                    editable: false,
                    clickable: false,
                    visible: true,
                    zIndex: this.annotation.polyline.get('zIndex') - 1,
                });
                this.isHighlighted = true;
                this.mapCoreV2.mapHelperManagerService.mutateTotalSelectedItems(1)
            } else if(!highlight && this.isHighlighted) {
                //Do not highlight and is highlighted
                this.googlePolylineSelected.setMap(null);
                this.googlePolylineSelected.setVisible(false);
                this.isHighlighted = false;
                this.mapCoreV2.mapHelperManagerService.mutateTotalSelectedItems(-1)
            }
        } else if(this.googlePolygon !== null){
            if (highlight && !this.isHighlighted) {
                this.googlePolygonPreSelectOptions = {
                    strokeColor: this.googlePolygon.get('strokeColor'),
                    strokeOpacity: this.googlePolygon.get('strokeOpacity'),
                    strokeWeight: this.googlePolygon.get('strokeWeight')
                }

                this.googlePolygon.setOptions({
                    strokeColor: '#000000',
                    strokeOpacity: 1,
                    strokeWeight: this.googlePolygon.get('strokeWeight') + 2,
                })
                this.isHighlighted = true;
                this.mapCoreV2.mapHelperManagerService.mutateTotalSelectedItems(1)
            } else if(!highlight && this.isHighlighted){
                this.googlePolygon.setOptions(this.googlePolygonPreSelectOptions)
                this.isHighlighted = false;
                this.mapCoreV2.mapHelperManagerService.mutateTotalSelectedItems(-1)
            }
        }
        this.handleHighlightMapItemInAnnotation(highlight)
    }

    public makeLineDotted(): void {
        if (this.googlePolyline !== null) {
            this.googlePolyline.setOptions({
                strokeOpacity: 0,
                icons: [{
                    icon: {
                        path: "M 0,-1 0,1",
                        strokeOpacity: 1,
                        scale: this.googlePolyline.get('strokeWeight'),
                    },
                    offset: "0",
                    repeat: (this.googlePolyline.get('strokeWeight') * 4) + "px",
                }]
            })
        }
    }

    public makeLineDiamond(): void {
        if (this.googlePolyline !== null) {
            this.googlePolyline.setOptions({
                strokeOpacity: 0,
                icons: [{
                    icon: {
                        path: "M -2,0 0,-2 2,0 0,2 z",
                        strokeColor: this.googlePolyline.get('strokeColor'),
                        strokeOpacity: 1,
                        fillColor: this.googlePolyline.get('strokeColor'),
                        fillOpacity: 1,
                        scale: this.googlePolyline.get('strokeWeight'),
                    },
                    offset: "0",
                    repeat: (this.googlePolyline.get('strokeWeight') * 6) + "px",
                }]
            })
        }
    }

    public setVisible():void{
        if (this.googlePolyline !== null) {
            this.googlePolyline.setVisible(true);
        } else if(this.googleMarker !== null) {
            this.googleMarker.setVisible(true);
        } else if(this.googlePolygon !== null) {
            this.googlePolygon.setVisible(true);
        }
    }
    public setHidden():void{
        if (this.googlePolyline !== null) {
            this.googlePolyline.setVisible(false);
        } else if(this.googleMarker !== null) {
            this.googleMarker.setVisible(false);
        } else if(this.googlePolygon !== null) {
            this.googlePolygon.setVisible(false);
        }
    }
}
