import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import {DefaultBaseFormComponent} from "./default-base-form-component";
import {FormSectionComponent} from "./section/form-section.component";
import {FormEvent} from '../containers/form/form.interface';
import {LoggerService} from "../../../services/logger/logger.service";

@Component({
    selector: 'default-form-component',
    template: `
        <div class="full-height-wrapper">
            <div class="w-100 full-height no-mobile-padding justify-content-center d-flex">
                <div *ngIf="showSections" class="{{!showLoader() ? '':'d-none'}} p-lg-3 col-lg-3 col-xxl-2 full-height user-profile-list-group-column">
                    <form-section-component #sectionComponent [formData]="_formData" [title]="title">
                        <default-base-form-component #fakeFormComponent [title]="title" [dataObservable]="dataObservable" [uriFragment]="uriFragment" [areaSpecific]="areaSpecific" [formData]="_formData" [buttonsOnly]="true"></default-base-form-component>
                    </form-section-component>
                </div>
                <div id="formTop" class="p-0 p-lg-3 {{'col-lg-9' + (largeForm?' col-xxxl-10':' col-xl-7 col-xxl-5 col-xxxl-4')}} full-height settings-container no-mobile-padding">
                    <div #formComponentContainer (scrollOutsideAngular)="onScroll($event)" class="white-bordered-z-depth-1 full-height pb-2 form-component-container">

                        <div class="loading-filler {{showLoader() ? 'd-block':'d-none'}}"></div>
                        <div class="{{showLoader() ? 'd-flex':'d-none'}} justify-content-center align-items-center">
                            <div class="loader"></div>
                        </div>
                        
                        <default-base-form-component #formComponent [title]="title" [description]="description" [dataObservable]="dataObservable" [uriFragment]="uriFragment" [areaSpecific]="areaSpecific" [formData]="_formData" (onComponentEvent)="handleComponentEvent($event)"></default-base-form-component>
                        <form-spacer-component *ngIf="showSections" [refreshTrigger]="_formData" [hasButtonBar]="false"></form-spacer-component>
                    </div>
                </div>
            </div>
        </div>
    `
})

export class DefaultFormComponent implements OnDestroy, OnInit{

    @ViewChild('formComponentContainer', {static: false}) formComponentContainer:ElementRef;
    @ViewChild('sectionComponent', {static: false}) sectionComponent:FormSectionComponent;
    @ViewChild('formComponent', {static: false}) formComponent: DefaultBaseFormComponent;
    @ViewChild('fakeFormComponent', {static: false}) fakeFormComponent:DefaultBaseFormComponent;

    @Input() title:string = "";
    @Input() description:string = "";
    @Input() loadText:string = "";
    @Input() uriFragment:string = "";
    @Input() dataObservable:BehaviorSubject<any> = null;
    @Input() areaSpecific:boolean = false;
    @Input() showSections:boolean = false;
    @Input() largeForm:boolean = false;

    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter<any>();

    public _formData:any = null;
    private _didInit:boolean = false;
    private subFormDataObservable:Subscription;

    constructor(private cd:ChangeDetectorRef, protected logger:LoggerService) {}

    public ngOnInit()
    {
        this.subFormDataObservable = this.dataObservable.subscribe((value:any) => {

            //TODO: leeghalen misschien niet nodig als je altijd ook het areaal refreshed (en formulier weer vervangt). Hoewel misschien nog wel voor error meldingen (mits die de form structuur aanpassen?)
            //Refresh form data for the cases when the formdata returned from a call is different from the original structure
            this._formData = null;
            setTimeout( () => {
                this._formData = value;

                //Formdata received, this component was loaded at least once
                if (value){
                    this._didInit = true;

                    //When a fake form is used in the sections, connect the html-form of the orginial to the fake one
                    if (this.fakeFormComponent){
                        let nrOfTries = 1;
                        const setFakeFormForm = () => {
                            const fakeFormForm = this.formComponent.getForm();
                            if (fakeFormForm) {
                                this.fakeFormComponent.setForm(fakeFormForm);
                            }
                            else if (nrOfTries++ < 20) {
                                setTimeout(setFakeFormForm, 100)
                            }
                        }
                        setTimeout(setFakeFormForm, 100)
                    }
                }

                this.cd.markForCheck();
            });
            this.cd.markForCheck();

            //TODO: hoezo 100ms hier?
            if (this.showSections){
                setTimeout(() => {
                    this.sectionComponent.connectForm(this.formComponentContainer, FormSectionComponent.SECTION_LAYOUT_TEXT);
                }, 100);
            }
        });

        //Wait for the init of the child components
        setTimeout( ()=>{
            // the initial request for form data
            this.formComponent.requestFormData();
        });

        this.logger.log("[DefaultFormComponent] ", this._formData);
    }

    public handleComponentEvent(eventData:any):void
    {
        this.onComponentEvent.emit(eventData);
    }

    public showLoader():boolean{
        return (!this._formData && !this._didInit);
    }

    public onScroll(event:any):void
    {
        if (this.showSections){
            this.sectionComponent.handleFormScroll(event);
        }
    }

    ngOnDestroy()
    {
        if (this.subFormDataObservable){
            this.subFormDataObservable.unsubscribe();
        }
    }
}
