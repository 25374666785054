import {ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from "@angular/core";
import {AbstractBaseFormComponent} from "../containers/form/abstract-base-form.component";
import {GlobalAlertService} from "../../../../wrapper/global-alert/global-alert.service";
import {SettingsService} from "./settings-service";
import {GlobalModel} from "../../../services/state/global.model";
import {AreaalService} from "../../../services/areaal/areaal.service";
import {FormComponent} from "../containers/form/form.component";
import {RequestFailure} from "../../../services/http/request-failure";
import {HTTPError} from "../../../services/http/http-error";
import { BehaviorSubject } from 'rxjs';
import {TranslateService} from "../../../services/translate/translate.service";
import {UntypedFormGroup} from "@angular/forms";
import {TooltipService} from "../../../services/tooltip/tooltip.service";
import { FormEvent } from '../containers/form/form.interface';
import {LoggerService} from "../../../services/logger/logger.service";

@Component({
    selector: 'default-base-form-component',
    template: `
        <ng-container *ngIf="config && _formData">
            
            <div *ngIf="!buttonsOnly" class="form-group-label-container">
                <h3 style="width: auto" class="pr-0">{{title}}</h3>
                <ng-container *ngIf="hasDescription()">
                    <help-icon-component [tooltipText]="description" [tooltipPlacement]="PLACEMENT_BOTTOM"></help-icon-component>
                </ng-container>
            </div>

            <!--TODO: bewaar voor description onder de header ooit-->
            <!--<h3 *ngIf="!buttonsOnly" class="{{hasDescription()?'mb-0':''}}">{{title}}</h3>-->
            <!--<ng-container *ngIf="!buttonsOnly && hasDescription()">
                <label [innerHtml]="description" class="pb-2 form-padding"></label>
            </ng-container>-->
            
            <form-component #formComponent
                [config]="config"
                [name]="getFormName()"
                [validationConstraints]="validationConstraints"
                [invalidControlsErrors]="invalidControlsErrors"
                [readOnly]="readOnly"
                [buttonsOnly]="buttonsOnly"
                [formIsSubmitted]="formIsSubmitted"
                (onComponentEvent)="handleComponentEvent($event)">
            </form-component>

            <!-- Voor de scrollspy moet bij niet-buttononly toch ook altijd die margin aanwezig zijn-->
            <div class="{{(!buttonsOnly || hasFailedConstraints())?'mb-3':'mb-0'}}"></div>
            
            <!--<div class="form-note">
                <p>Velden met een <strong><span class="required"></span></strong> zijn verplichte velden.</p>
            </div>-->
            <validation-warning [showWarning]="hasFailedConstraints()"></validation-warning>
        </ng-container>
    `
})

export class DefaultBaseFormComponent extends AbstractBaseFormComponent
{
    @Input() title:string = "";
    @Input() description:string = "";
    @Input() uriFragment:string = "";
    @Input() dataObservable:BehaviorSubject<any> = null;
    @Input() areaSpecific:boolean = false;
    @Input() buttonsOnly:boolean = false;

    @ViewChild('formComponent', {static: false}) formComponent:FormComponent;

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService: GlobalAlertService, protected formDataService:SettingsService, protected model:GlobalModel, protected areaService:AreaalService, protected elementRef:ElementRef, protected ts:TranslateService, protected logger:LoggerService)
    {
        super(cd, globalAlertService, elementRef, ts, logger);
    }

    public setForm(form: UntypedFormGroup): void {
        this.formComponent.form = form;
    }

    public getForm() {
        return this.formComponent?.form;
    }

    public handleComponentEvent(eventData:any):void
    {
        if (eventData.event == FormEvent.SAVE){
            this.submitFormData(eventData.data.formData);
        } else {
            this.onComponentEvent.emit(eventData, );
        }
    }

    public hasDescription():boolean
    {
        return !!this.description;
    }

    private submitFormData(form: any):void
    {
        this.handleSubmitForm();

        this.logger.log('[DefaultFormFormComponent] submitFormData');

        this.formDataService.setFormDataForUriFragment(this.uriFragment, form, this.getFormName(), this.dataObservable,
            () => {
                this.logger.log('[DefaultFormFormComponent] setFormDataForUriFragment success');
                const area = this.model.currentAreaal.getValue();

                //Store form data to re-apply after data is cleared
                let storedFormData:any = this.dataObservable.value;

                this.areaService.loadAreaal(
                    area.id, false,
                    (_data) => {
                        this.dataObservable.next(storedFormData);
                    },
                    (_data) => {
                        this.dataObservable.next(storedFormData);
                    },
                    this.uriFragment
                );
                this.handleSubmitResponse();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },(error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            },
            this.areaSpecific
        );
    }

    public requestFormData():void
    {
        this.formDataService.getFormDataForUriFragment(
            this.uriFragment,
            this.dataObservable,
            () => {
                //Call success, model adjusted
            },
            () => {},
            (error:HTTPError) => {},
            this.areaSpecific
        );
    }

    public ngOnDestroy():void{
        if(this.dataObservable){
            this.dataObservable.next(null);
        }
        this._formData = null;
        this.config = null;
    }

    get PLACEMENT_BOTTOM(): string {
        return TooltipService.PLACEMENT_BOTTOM;
    }
}
