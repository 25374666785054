import {
    ChangeDetectorRef,
    Component, ElementRef, Input,
    OnDestroy,
} from '@angular/core';

import {GlobalAlertService} from "../../global-alert.service";
import {ButtonCode, GlobalPopup} from '../../global-popup';
import {TranslateService} from "../../../../shared/services/translate/translate.service";
import {Subscription} from "rxjs";
import {GlobalModel} from "../../../../shared/services/state/global.model";
import {AuthorizationService} from "../../../../shared/services/authorization/authorization.service";
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {TooltipService} from "../../../../shared/services/tooltip/tooltip.service";
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import {GlobalPopupControlProgramEditService} from "./global-popup-control-program-edit.service";
import {IControl, IControlProgram, IControlProgramList} from "./global-popup-control-program-edit.interface";
import {AppSettings} from "../../../../app.settings";
import * as dayjs from "dayjs";
import {take} from "rxjs/operators";

declare var google:any|google.maps.event;


@Component ({
    selector: 'global-popup-control-program-edit-component',
    templateUrl: 'global-popup-control-program-edit.component.html',
    styleUrls: ['global-popup-control-program-edit.component.scss']
})

export class GlobalPopupControlProgramEditComponent extends AbstractBasePopupComponent implements OnDestroy {
    private subscriptions: Subscription[] = [];
    @Input() currentActiveFilterQuery:string = '';
    private googleChart:any = null;
    private googleChartOptions:any;
    public showList:boolean = false;

    public _mobileMode:boolean = false;
    public _tabletMode:boolean = false;
    public _laptopMode:boolean = false;
    public availableColorPallet: string[] = AppSettings.ADVANCED_COLOR_PICKER_DEFAULT_OPTIONS;
    public disableAddProgramButton:boolean = false;
    public isAddingNewProgram:boolean = false;
    public isLoadingControlProgram:boolean = false;
    public activeControlProgram:IControlProgram = null;
    public controlProgramsList:IControlProgramList[] = [];
    public hasFormError:boolean = false;
    private googleChartData:any = null;
    private currentDragPointId:number = null;
    private isDragging:boolean = false;
    private hasChanged:boolean = false;
    private defaultChartWidth:number = 740;

    public constructor(protected cd:ChangeDetectorRef, protected elementRef:ElementRef, private controlProgramService:GlobalPopupControlProgramEditService, protected globalAlertService:GlobalAlertService, protected ts:TranslateService, private model:GlobalModel, public auth:AuthorizationService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngOnInit(){
        this.getControlProgramLists();

        google.charts.load('current', {'packages':['corechart', 'charteditor']});
        this.googleChartOptions = {
            vAxis: {title: 'Percentage', viewWindow: {min: 0, max: 100}},
            hAxis: {title: '', format: 'HH:mm'},
            legend: {position: 'none'},
            tooltip: {isHtml: true},
            width: this.defaultChartWidth,
            height: 390,
            chartArea: {
                right:20,
                width: '90%',
                height: '80%'
            }
        };
        this.subscriptions.push(this.model.mobileMode.subscribe((value: boolean) => {
            // Don't forget to unsubscribe
            this._mobileMode = value;
            this.checkToDisableNavigation()
        }));
        this.subscriptions.push(this.model.tabletMode.subscribe((value: boolean) => {
            // Don't forget to unsubscribe
            this._tabletMode = value;
            this.checkToDisableNavigation()
        }));
        this.subscriptions.push(this.model.laptopMode.subscribe((value: boolean) => {
            // Don't forget to unsubscribe
            this._laptopMode = value;
            this.checkToDisableNavigation()
        }));
        window.onresize = (e => {
            if(this.googleChart){
                this.drawChart();
            }
        })
    }

    private checkToDisableNavigation():void{
        if(!this._mobileMode && !this._tabletMode && !this._tabletMode){
            this.showList = false;
            this.cd.detectChanges()
        }
    }
    public toggleArticleNavigation(forceClose:boolean = false, preventDefault:boolean = false){
        if((this._mobileMode || this._tabletMode || this._laptopMode) && (!preventDefault)){
            if(!forceClose){
                this.showList = !this.showList;
            } else {
                this.showList = false;
            }
        }
    }

    public addProgram(){
        this.isAddingNewProgram = true;
        this.hasFormError = true;
        this.activeControlProgram = {
            id: null,
            name: '',
            description: '',
            color: '',
            defaultLightLevel:100,
            activePeriodAstroEnabled: false,
            activePeriodAstroSunsetOffset: '0',
            activePeriodAstroSunriseOffset: '0',
            adminOnly:false,
            timeControls:[],
            updatedAt: '',
            createdAt: ''
        }
        this.setSelected(null);
        this.cd.detectChanges();
        this.loadGoogleChartData();
    }

    public saveProgram(){
        if(this.activeControlProgram.name != ''){
            this.subscriptions.push(this.controlProgramService.saveControlProgram(this.activeControlProgram).subscribe((formPostResult) => {
                let controlProgram:IControlProgram = (formPostResult as any).controlList;
                if(this.activeControlProgram.id === null){
                    this.controlProgramsList.unshift({
                        id: controlProgram.id,
                        name: controlProgram.name,
                        description: controlProgram.description,
                        color: controlProgram .color,
                        avgDimLevel: null,
                    })
                    this.setSelected(controlProgram.id)
                    this.activeControlProgram = controlProgram;
                } else {
                    const currentListControlProgram = this.controlProgramsList.find(_x => {return _x.id == this.activeControlProgram.id;})
                    currentListControlProgram.name = controlProgram.name;
                    currentListControlProgram.description = controlProgram.description;
                    currentListControlProgram.color = controlProgram.color;
                }
                this.cd.detectChanges()
            }));
            this.hasChanged = true;
        }
    }

    public copyProgram():void{
        this.isAddingNewProgram = true;
        this.hasFormError = true;
        this.activeControlProgram.name = '';
        this.activeControlProgram.id = null;
        this.activeControlProgram.timeControls.map(_x => {
            _x.id = null;
        })
        this.setSelected(null);
        this.cd.detectChanges();
    }


    public deleteActiveControlProgram():void{
        this.globalAlertService.addPopup(this.ts.translate("control-program.remove-program-title"), this.ts.translate("control-program.remove-program-label"),
            [{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                {label:this.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                    callback:() => {
                        this.subscriptions.push(this.controlProgramService.deleteControlProgram(this.activeControlProgram.id).pipe(take(1)).subscribe((data) => {}));
                        this.controlProgramsList.splice(this.controlProgramsList.map(function(e) { return e.id; }).indexOf(this.activeControlProgram.id),1);
                        this.activeControlProgram = null;
                        this.googleChart = null;
                        this.cd.detectChanges();
                    },
                    isPrimary:false}
            ], () => {})
    }



    public checkForError(){
        this.hasFormError = this.activeControlProgram.name == '';
        this.cd.detectChanges()
    }

    public changeDefaultLightLevel():void{
        this.checkForError();
        this.loadGoogleChartData();
    }

    private getControlProgramLists():void{
        this.subscriptions.push(this.controlProgramService.getControlProgramsList().subscribe((formPostResult) => {
            this.controlProgramsList = (formPostResult as any).controlProgramList;
            this.cd.detectChanges()
        }));
    }

    public getControlProgram(controlProgramId:number):void{
        if(this.activeControlProgram == null || this.activeControlProgram.id != controlProgramId){
            this.setSelected(controlProgramId);
            this.subscriptions.push(this.controlProgramService.getControlProgram(controlProgramId).subscribe((formGetResult) => {
                this.activeControlProgram = (formGetResult as any).controlList;

                this.activeControlProgram.timeControls.forEach((timeControl) => {
                    let timestamp = dayjs('1970-01-01 '+timeControl.time).format('HH:mm:ss');
                    if (timestamp < '12:00:00') {
                        timeControl.timestamp = dayjs('1970-01-02 ' + timeControl.time, 'YYYY-MM-DD HH:mm:ss');
                    } else {
                        timeControl.timestamp = dayjs('1970-01-01 ' + timeControl.time, 'YYYY-MM-DD HH:mm:ss');
                    }
                });

                this.addFrontEndIdToControls();
                this.cd.detectChanges()
                this.loadGoogleChartData();
                this.checkForError();
            }));
        }
    }

    private addFrontEndIdToControls():void{
        this.activeControlProgram.timeControls.map((_x, index) => {
            _x.controlId = index+1;
        })
    }

    public addTimeControl(){
        const previousTimeControl = this.activeControlProgram.timeControls.length ? this.activeControlProgram.timeControls[this.activeControlProgram.timeControls.length-1] : null;

        let timeControlToAdd: IControl = {
            id:null,
            controlId:1,
            time: '12:00',
            timestamp: dayjs('1970-01-01 12:00:00', 'YYYY-MM-DD HH:mm:ss'),
            dimLevel: 100
        }
        if(previousTimeControl !== null){
            let timestampToUse = dayjs(previousTimeControl.timestamp).add(1, 'hour')
            if(timestampToUse.format('DHHmm') > '21200'){
                timestampToUse = previousTimeControl.timestamp;
            }
            timeControlToAdd = {
                id:null,
                controlId:previousTimeControl.controlId+1,
                time: timestampToUse.format('HH:mm'),
                timestamp: timestampToUse,
                dimLevel: 100
            }
        }
        this.activeControlProgram.timeControls.push(timeControlToAdd)
        this.loadGoogleChartData();
    }

    public changeTimeControl(){
        this.loadGoogleChartData();
    }

    public removeTimeControl(id:number){
        this.activeControlProgram.timeControls.splice(this.activeControlProgram.timeControls.findIndex(_x => {
            return _x.controlId === id;
        }), 1)
        this.cd.detectChanges();
        this.loadGoogleChartData();
    }

    public selectColor(type:'default'|'timeControl', id?:number):void {
        if(type === 'default'){
            this.activeControlProgram.color = this.activeControlProgram.color.toUpperCase()
        } else if(type === 'timeControl'){
            this.getTimeControl(id).colorHex = this.getTimeControl(id).colorHex.toUpperCase()
        }
    }

    public doSearch():void {
        this.controlProgramsList.map(_x => {
            if(this.currentActiveFilterQuery == ''){
                _x.isHidden = false;
            } else {
                _x.isHidden = !(_x.name.toLowerCase().includes(this.currentActiveFilterQuery.toLowerCase()) || _x.isSelected);
            }
        })
        this.cd.detectChanges();
    }

    public hasSearchQuery():boolean {
        return !(this.currentActiveFilterQuery == '' || this.currentActiveFilterQuery == null || typeof this.currentActiveFilterQuery == 'undefined');
    }

    public clearSearchQuery():void {
        this.currentActiveFilterQuery = ''
        this.doSearch()
    }

    public canEditControlProgram():boolean{
        const isAdmin = true;
        if(isAdmin){
            return true;
        } else { return !this.activeControlProgram.adminOnly; }
    }

    public changeAstroOffset(event:any){
        this.activeControlProgram.activePeriodAstroEnabled = (<HTMLInputElement>event.target).checked;
    }

    public changeTimeControlTime(event:any, id:number){
        if(event){
            event = dayjs(event, 'YYYY-MM-DD HH:mm:ss');
            event = event.set('year', 1970).set('month', 0).set('date', 1);
            if (event < dayjs('1970-01-01 12:00:00', 'YYYY-MM-DD HH:mm:ss')) {
                event = event.set('date', 2);
            }
            this.getTimeControl(id).time = dayjs(event).format('HH:mm:ss');
            this.getTimeControl(id).timestamp = dayjs(event, 'YYYY-MM-DD HH:mm:ss');
        }
        this.loadGoogleChartData();
    }

    private getTimeControl(id:number):IControl{
        return this.activeControlProgram.timeControls.find(_x => {
            return _x.controlId == id;
        })
    }

    private mouseMoveScript(event:any):void{
        if(this.currentDragPointId !== null && this.isDragging){
            let time = event.offsetX;
            let valTime = this.googleChart.getChartLayoutInterface().getHAxisValue(time);
            let percentage = event.offsetY;
            let valPercentage = this.googleChart.getChartLayoutInterface().getVAxisValue(percentage);

            let newValuePercentage:number = Math.ceil(valPercentage/5)*5;
            if(valPercentage < 0){
                newValuePercentage = 0;
            } else if(valPercentage > 100){
                newValuePercentage = 100;
            }

            let newValueTime:string = dayjs(valTime).format('HH:mm')
            const day = dayjs(valTime).get('date');
            let hour = dayjs(valTime).get('hour');
            let forcedTimeSet:boolean = false;

            if(day == 1){
                if(newValueTime < '12:00'){
                    hour = 12;
                    forcedTimeSet = true;
                }
            } else if(day == 2){
                if(newValueTime > '12:00'){
                    hour = 12;
                    forcedTimeSet = true;
                }
            }

            if(this.currentDragPointId == 0){
                this.activeControlProgram.defaultLightLevel = newValuePercentage;
            } else {
                const timeControlElement = this.activeControlProgram.timeControls.find(_x => {return _x.id == this.currentDragPointId});
                let minuteToUse = Math.ceil(dayjs(valTime).get('minute')/30)*30;
                if(forcedTimeSet){
                    minuteToUse = 0;
                }

                if (minuteToUse == 60) {
                    minuteToUse = 0;
                    hour++;
                }
                timeControlElement.dimLevel = newValuePercentage;
                timeControlElement.timestamp = dayjs('1970-01-0'+day+' '+hour+':'+minuteToUse+':00', 'YYYY-MM-DD HH:mm:ss');
                timeControlElement.time = hour.toString().padStart(2, '0')+':'+minuteToUse.toString().padStart(2, '0')+':00';
            }
            this.loadGoogleChartData();
        }
    }

    private addDragPoint(event){
        const pointId = this.googleChartData?.eg[event.row]?.pointId;
        if(pointId !== null){
            this.currentDragPointId = pointId;
        }
    }

    private removeDragPoint():void{
        this.currentDragPointId = null;
        this.cd.detectChanges();
    }

    private mouseDownScript(event:Event):void{
        this.isDragging = true;
    }

    private mouseUpScript(event:Event):void{
        this.isDragging = false;
        this.removeDragPoint();
    }


    private loadGoogleChartData(){
        if(this.googleChart === null){
            this.googleChart = new google.visualization.AreaChart(document.getElementById('graph'));

            //Events
            google.visualization.events.addListener(this.googleChart, 'onmouseover', (event) => {this.addDragPoint(event);});
            google.visualization.events.addListener(this.googleChart, 'onmouseout', () => {this.removeDragPoint();});
            document.getElementById('graph').addEventListener('mousemove', (e: Event) => {this.mouseMoveScript(e)});
            document.getElementById('graph').addEventListener('mousedown', (e: Event) => {this.mouseDownScript(e)});
            document.getElementById('graph').addEventListener('mouseup', (e: Event) => {this.mouseUpScript(e)});
        }
        //New data, clear chart and add data if applicable

        let chartData:any = [];
        let start: IControl = null;

        this.activeControlProgram.timeControls.forEach((timeControl) => {
            chartData.push(timeControl);
            if (timeControl.timestamp.get('hour') == 12 && timeControl.timestamp.get('minute') == 0) {
                start = timeControl;
            }
        });

        if (start === null) {
            chartData.push({
                id: 0,
                dimLevel: this.activeControlProgram.defaultLightLevel,
                time: "12:00:00",
                timestamp: dayjs('1970-01-01 12:00:00', 'YYYY-MM-DD HH:mm:ss')
            })
        }

        chartData.sort(function(a,b){
            return a.timestamp.toDate() - b.timestamp.toDate();
        });

        let _googleChartData:any = [];

        for (let i = 0; i < chartData.length; i++) {
            const point = chartData[i];
            _googleChartData.push({
                pointId: point.id,
                c:[
                    {v: point.timestamp.toDate(), f: point.timestamp.format('YYYY-MM-DD HH:mm:ss')},
                    {v: point.dimLevel, f: point.dimLevel},
                    {v: 'point { visible:true }'},
                    {v: '<b>'+point.timestamp.format('HH:mm')+'</b>: '+point.dimLevel+'%'},
                ]
            });

            let nextPoint = chartData[i + 1];
            if (typeof nextPoint !== 'undefined') {
                _googleChartData.push({
                    pointId: null,
                    c:[
                        {v: nextPoint.timestamp.toDate(), f: nextPoint.timestamp.format('YYYY-MM-DD HH:mm:ss')},
                        {v: point.dimLevel, f: point.dimLevel},
                        {v: 'point { visible:false }'},
                        {v: '<b>'+nextPoint.timestamp.format('HH:mm')+'</b>: '+point.dimLevel+'%'},
                    ]
                });
            }
        }

        let end = { ...start };
        end.timestamp =  dayjs('1970-01-02 12:00:00', 'YYYY-MM-DD HH:mm:ss');
        end.dimLevel = chartData[chartData.length - 1].dimLevel;
        end.id = chartData[chartData.length - 1].id;
        _googleChartData.push({
            pointId: null,
            c:[
                {v: end.timestamp.toDate(), f: end.timestamp.format('HH:mm')},
                {v: end.dimLevel, f: end.dimLevel},
                {v: 'point { visible:false }'},
                {v: '<b>'+end.timestamp.format('HH:mm')+'</b>: '+end.dimLevel+'%'},
            ]
        });

        this.googleChartData = new google.visualization.DataTable({
            cols: [
                {id: 'time', label: this.ts.translate('control-program.column-time'), type: 'date'},
                {id: 'level', label: this.ts.translate('control-program.column-percentage'), type: 'number'},
                {type: 'string', role: 'style'},
                {type: 'string', role: 'tooltip', 'p': {'html': true}}
            ],
            rows: _googleChartData
        });
        this.googleChart.clearChart();
        this.drawChart();
    }

    private drawChart(){
        let containerWidth:number = document.getElementById('graph')?.closest('.graph-parent').getBoundingClientRect().width - 50;
        if(this.googleChart !== null){
            if(containerWidth < this.defaultChartWidth){
                this.googleChartOptions.width = containerWidth;
            } else {
                this.googleChartOptions.width = this.defaultChartWidth;
            }
        }
        this.googleChart.draw(this.googleChartData, this.googleChartOptions)
    }

    private setSelected(controlProgramId:number):void{
        if(controlProgramId !== null){
            this.isAddingNewProgram = false;
        }
        //Set the selected item to false
        this.controlProgramsList.filter(_x => {return _x.isSelected}).map(_x => {_x.isSelected = false});
        //Set current item selected
        this.controlProgramsList.filter(_x => {return _x.id == controlProgramId}).map(_x => {_x.isSelected = true});
    }

    public convertStringDateToFormat(date:string|number,format:string):string{
        return dayjs(date).locale(this.ts.getLanguage()).format(format);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //Hide alert and perform callback of button
        this.onPopupAction.emit({event:event, alert:alert, button:button, data:{dataChanged:this.hasChanged}});
    }

    public closePopup(event:any):void
    {
        //When the moments have changed, show a are-your-sure-popup
        this.doDefaultCloseHandling(event,this.isAddingNewProgram);
    }

    ngOnDestroy(){
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        window.onresize = null;
    }
}
