import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TreeNodeLMX} from "./tree-node-lmx";
import {TranslateService} from "../../../services/translate/translate.service";

@Component ({
    selector: 'tree-search-component',
    templateUrl: 'tree-search.component.html',
    styleUrls: ['tree-search.component.scss']
})

export class TreeSearchComponent {
    private selectedNode:TreeNodeLMX = null;
    @Input() parentTreeNode:TreeNodeLMX = null;
    @Input() currentActiveFilterQuery:string;
    @Output() setIsTreeFiltering: EventEmitter<any> = new EventEmitter();
    @Output() setFilterQuery: EventEmitter<any> = new EventEmitter();

    constructor(public ts:TranslateService) {}

    //Do search
    public doSearch():void {
        const searchQuery = this.currentActiveFilterQuery;
        this.isTreeFiltering(true)
        this.setFilterQuery.emit(searchQuery);

        //Set timeout so angular has time to update the dom with the loading image
        setTimeout(()=> {
            this.filterCurrentTreeNodes(searchQuery)
        })

    }

    //Check if search query
    public hasSearchQuery():boolean {
        if(this.currentActiveFilterQuery == '' || this.currentActiveFilterQuery == null || typeof this.currentActiveFilterQuery == 'undefined') {
            return false
        }
        return true;
    }

    //Clear search query
    public clearSearchQuery():void {
        this.currentActiveFilterQuery = ''
        this.doSearch()
    }

    //Expand selected
    private expandSelected(childrenNodes:TreeNodeLMX[]):void{
        let selectedNode:TreeNodeLMX = null;
        childrenNodes.map(_childNode => {
            if (_childNode.selected) {
                this.showParentNode(_childNode.parent)
                this.selectedNode = _childNode
            }

            //Recursive run
            if (_childNode.children.length > 0) {
                this.expandSelected(_childNode.children)
            }
        })
    }

    //Tree filter
    private filterCurrentTreeNodes(fieldInput:string):void{
        //Show and expand the main parent node (areaal name)
        this.setTreeNodeItemVisibility(this.parentTreeNode, true)
        this.setTreeNodeItemExpanded(this.parentTreeNode, true)

        if(fieldInput == '' || typeof fieldInput === 'undefined'){
            this.removeFilterFromTree(this.parentTreeNode.children)
            this.expandSelected(this.parentTreeNode.children)
            this.isTreeFiltering(false)
            this.scrollToNode()
        } else {
            //Start the loop by going trough the parent nodes. This is a seperate loop so we can keep track of when it reaches the last parent node.
            this.parentTreeNode.children.map((_childNode, index) => {
                //Filter the parent node item.
                this.filterItem(fieldInput,_childNode)
                //Check if last parent node or not and call recursive method
                let isLastParentNode:boolean = false;
                if(index === this.parentTreeNode.children.length-1){
                    isLastParentNode = true;
                }
                this.checkNodesRecursive(fieldInput,_childNode.children, isLastParentNode);
            })
        }
    }

    //Check the items recursive
    private checkNodesRecursive(fieldInput:string, currentNodeChildren:TreeNodeLMX[] = null, isLastParentNode:boolean):void{
        if(fieldInput != ''){
            //Filter items
            currentNodeChildren.map(_childNode => {
                this.filterItem(fieldInput,_childNode)

                //Recursive run
                if(_childNode.children.length > 0){
                    this.checkNodesRecursive(fieldInput, _childNode.children, isLastParentNode)
                }
            })

            //Last item reached. Disable loading
            if(isLastParentNode){
                setTimeout(() => { //Timeout so angular has time to finish the filtering and update the dom
                    this.isTreeFiltering(false)
                    this.scrollToNode()
                })
            }
        }
    }

    //Filter the item
    private filterItem(fieldInput:string, node:TreeNodeLMX = null):void{
        if(node.label.toLowerCase().includes(fieldInput.toLowerCase()) || node.selected){
            this.setTreeNodeItemVisibility(node, true)

            //Display parent folder even if it does not match search input
            this.showParentNode(node.parent)
        } else {
            this.setTreeNodeItemVisibility(node, false)
        }

        //Expand folders
        if(node.isVisible){
            this.setTreeNodeItemExpanded(node, true)
        }
    }

    //Remove filter from tree
    private removeFilterFromTree(childrenNodes:TreeNodeLMX[]):void{
        if (typeof childrenNodes !== 'undefined' && childrenNodes.length > 0) {
            childrenNodes.map(_childNode => {
                this.setTreeNodeItemVisibility(_childNode, true)
                this.setTreeNodeItemExpanded(_childNode, false)

                //Recursive run
                if (_childNode.children.length > 0) {
                    this.removeFilterFromTree(_childNode.children)
                }
            })
        }
    }

    //Reset the tree nodes
    private resetTreeNodeValues(treeNode:TreeNodeLMX){
        this.setTreeNodeItemVisibility(treeNode, true)
        this.setTreeNodeItemExpanded(treeNode, false)
    }

    //Show all parent nodes
    private showParentNode(parentNode:TreeNodeLMX):void{
        if(typeof parentNode !== 'undefined'){
            this.setTreeNodeItemVisibility(parentNode, true)
            this.setTreeNodeItemExpanded(parentNode, true)

            //Recursive run
            if(typeof parentNode.parent !== 'undefined'){
                this.showParentNode(parentNode.parent)
            }
        }
    }

    //Set tree node visible
    private setTreeNodeItemVisibility(nodeItem:TreeNodeLMX, visible:boolean):void{
        if(typeof nodeItem !== 'undefined' && nodeItem.isVisible !== visible){
            nodeItem.isVisible = visible;
        }
    }

    //Set tree node highlighted
    private setTreeNodeItemHighighted(nodeItem:TreeNodeLMX, highlight:boolean):void{
        if(typeof nodeItem !== 'undefined' && nodeItem.isHighlighted !== highlight){
            nodeItem.isHighlighted = highlight;
        }
    }

    //Set tree node expanded
    private setTreeNodeItemExpanded(nodeItem:TreeNodeLMX, expanded:boolean):void{
        //Make sure to check if it is a parent item bij checking if it has children
        if(typeof nodeItem !== 'undefined' && nodeItem.expanded !== expanded && nodeItem.children.length > 0){
            nodeItem.expanded = expanded;
        }
    }

    //Scroll to node
    private scrollToNode() {
        if(this.selectedNode !== null){
            //Use timeout to give angular time to render the dom
            setTimeout(()=>{
                let elementInDom = document.getElementById(this.selectedNode.index.toString())
                elementInDom.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
                this.selectedNode = null;
            })
        }
    }

    //Is it filtering to show loading or not
    private isTreeFiltering(isFiltering:boolean):void{
        this.setIsTreeFiltering.emit(isFiltering);
    }
}
