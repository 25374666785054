import {MapItem} from '../map/map-item/map-item';
import {CellChild} from '../table/shared/baseTable/baseTable.interface';
import {FilterCommand} from '../table/filterableTable/filter.interface';

export interface TabInterface {
    code: string
    icon: string
    active: boolean
}

export interface TmpMapDataInterface {
    mapItems: MapItem[]
    newData: boolean
    mapHidden: boolean
    zoomOnMarkers: boolean
}

export interface RowAsObject {
    [key: string]: string | number
}

export interface FilterPopupData {
    deleteFilter: boolean
    filter: {
        command: FilterCommand
        field: string
        label: string
        type: string
        values: string[]
    }
}

export interface TableColumn {
    id?: string|number
    visible?: boolean
    label?: string
    code: string
    bold?: boolean
    sorting?: boolean
    sortDirection?: number
    visibleColumnIndex?: number
    type?: string
    isRemoved?: boolean
    advancedTableOption?: boolean
}

export interface TableCell {
    label: string
    truncate?: boolean
    truncatedLabel?: string
    children?: CellChild[]
}

export interface TableRow extends Array<TableCell> {
    id?: string
    hidden?: boolean
    baseObjectId?: number|string
    mapItemId?: string
    selected?: boolean
    searchString?: string
    highlight?: boolean
    clickAction?: string
}

export enum MapTableTab {
    'TABLE' = 'TABLE',
    'MAP' = 'MAP',
    'MIXED' = 'MIXED'
}

export interface IClusterInfo {
    counts: any[]
}
