import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {FormSectionComponent} from './section/form-section.component';
import {AssetsComponent} from '../../../../modules/assets/assets.component';
import {DeviceManagementComponent} from '../../../../modules/deviceManagement/device-management.component';
import {ControlComponent} from '../../../../modules/control/control.component';
import {ReportMalfunctionComponent} from '../../../../modules/reportMalfunction/report-malfunction.component';
import {DeviceMalfunctionComponent} from '../../../../modules/deviceMalfunction/device-malfunction.component';
import {WorkOrdersComponent} from '../../../../modules/workOrders/work-orders.component';
import {PublicPortalComponent} from '../../../../modules/publicPortal/public-portal.component';
import {ProjectsCreateComponent} from '../../../../modules/projectsCreate/projects-create.component';
import {ProjectsManageComponent} from '../../../../modules/projectsManage/projects-manage.component';
import {StedinOrdersComponent} from '../../../../modules/stedinOrders/stedin-orders.component';
import {UserManagementComponent} from '../../../../modules/userManagement/user-management.component';
import {MasterDataComponent} from '../../../../modules/masterData/master-data.component';
import {FormEvent, LumiFormEventData} from '../containers/form/form.interface';
import {AbstractBaseFormComponent} from '../containers/form/abstract-base-form.component';
import Utils from '../../../utils/utils';
import {MechanicComponent} from '../../../../modules/mechanic/mechanic/mechanic.component';
import {LoggerService} from "../../../services/logger/logger.service";

@Component({
    selector: 'section-form-component',
    template: `
        <div class="row full-height module-section-form-root">
            <div class="col-sm-12 full-height no-padding">
                <div class="row full-height no-gutters">
                    <div class="col-sm-12 full-height white-bordered-z-depth-1">
                        <div class="full-height form-section-component-containter-container">
                            <div class="full-height form-section-component-container">
                                <form-section-component #sectionComponent
                                                        (onToggleCollapse)="handleToggleCollapse($event)"
                                                        [formData]="formData"
                                                        [collapsed]="collapsed">
                                </form-section-component>
                            </div>
                            <div class="full-height {{collapsed?'collapsed-form':''}}">
                                <div #formComponentContainer (scrollOutsideAngular)="handleFormScroll($event)"
                                     [ngSwitch]="module"
                                     class="module-section-container pt-3 overflow-auto-y overflow-hidden-x full-height form-padding">

                                    <assets-form-component *ngSwitchCase="MODULE_PATH_ASSETS" #formComponent
                                                           [formData]="formData"
                                                           (onComponentEvent)="handleComponentEvent($event)"></assets-form-component>
                                    <device-management-form-component *ngSwitchCase="MODULE_PATH_DEVICE_MANAGEMENT" #formComponent
                                                              [formData]="formData"
                                                              (onComponentEvent)="handleComponentEvent($event)"></device-management-form-component>
                                    <control-form-component *ngSwitchCase="MODULE_PATH_CONTROL" #formComponent
                                                            [formData]="formData"
                                                            (onComponentEvent)="handleComponentEvent($event)"></control-form-component>
                                    <report-malfunction-form-component *ngSwitchCase="MODULE_PATH_REPORT_MALFUNCTION" #formComponent
                                                               [formData]="formData"
                                                               (onComponentEvent)="handleComponentEvent($event)"></report-malfunction-form-component>
                                    <device-malfunction-form-component *ngSwitchCase="MODULE_PATH_DEVICE_MALFUNCTION" #formComponent
                                                               [formData]="formData"
                                                               (onComponentEvent)="handleComponentEvent($event)"></device-malfunction-form-component>
                                    <work-orders-form-component *ngSwitchCase="MODULE_PATH_WORK_ORDERS" #formComponent
                                                                [formData]="formData"
                                                                (onComponentEvent)="handleComponentEvent($event)"></work-orders-form-component>
                                    <work-orders-form-component *ngSwitchCase="MODULE_PATH_MECHANIC" #formComponent
                                                                [formData]="formData"
                                                                (onComponentEvent)="handleComponentEvent($event)"></work-orders-form-component>
                                    <stedin-orders-form-component *ngSwitchCase="MODULE_PATH_STEDIN_ORDERS"
                                                                  #formComponent [formData]="formData"
                                                                  (onComponentEvent)="handleComponentEvent($event)"></stedin-orders-form-component>
                                    <public-portal-form-component *ngSwitchCase="MODULE_PATH_PUBLIC_PORTAL"
                                                                  #formComponent [formData]="formData"
                                                                  (onComponentEvent)="handleComponentEvent($event)"></public-portal-form-component>
                                    <projects-create-form-component *ngSwitchCase="MODULE_PATH_PROJECTS_CREATE"
                                                                    #formComponent [formData]="formData"
                                                                    (onComponentEvent)="handleComponentEvent($event)"></projects-create-form-component>
                                    <projects-manage-form-component *ngSwitchCase="MODULE_PATH_PROJECTS_MANAGE"
                                                                    #formComponent [formData]="formData"
                                                                    (onComponentEvent)="handleComponentEvent($event)"></projects-manage-form-component>
                                    <user-management-form-component *ngSwitchCase="MODULE_PATH_USER_MANAGEMENT"
                                                                    #formComponent [formData]="formData"
                                                                    (onComponentEvent)="handleComponentEvent($event)"></user-management-form-component>
                                    

                                    <master-data-form-component *ngSwitchCase="MODULE_PATH_MASTER_DATA"
                                                                #formComponent [formData]="formData"
                                                                (onComponentEvent)="handleComponentEvent($event)"></master-data-form-component>

                                    <div *ngSwitchDefault>Implementatiefout: Geen form component gekoppeld voor deze
                                        module in section-form-component
                                    </div>

                                    <form-spacer-component [refreshTrigger]="formData"
                                                           [hasButtonBar]="true"></form-spacer-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class SectionFormComponent implements AfterViewInit {
    @Input() module: string;
    @Input() formData: any;
    @Input() collapsed: boolean;

    @Output() onToggleCollapse: EventEmitter<any> = new EventEmitter();
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('formComponentContainer', {static: false}) formComponentContainer: ElementRef;
    @ViewChild('sectionComponent', {static: false}) sectionComponent: FormSectionComponent;
    @ViewChild('formComponent', {static: false}) formComponent: AbstractBaseFormComponent;

    constructor(protected logger:LoggerService){}

    
    @HostListener('window:keyup', ['$event'])
    handleGlobalKeyUp($event: KeyboardEvent): void {
        if ((<{ ignore?: boolean }>$event).ignore) {
            this.logger.log('[AbstractBaseFormComponent] ' + 'keyup event.ignore:' + (<{ ignore?: boolean }>$event).ignore);
            return;
        }
    
        if ((<HTMLInputElement>$event.target).nodeName === 'TEXTAREA') {
            this.logger.log('[AbstractBaseFormComponent] ' + 'keyup ignore; target was textarea:' + $event.target);
            return;
        }
    
        if (!Utils.isBody(<Element>$event.target) && Utils.hasFocus(<Element>$event.target)) {
            this.logger.log('[AbstractBaseFormComponent] ' + 'keyup ignore; event target has focus:' + $event.target);
            return;
        }
    
        if ($event.key === 'Enter') {
            if (this.formComponent?.baseFormGroupComponent?.form) {
                this.logger.log('[AbstractBaseFormComponent] ' + 'enter key pressed - saving form');
                this.formComponent.baseFormGroupComponent.form.updateValueAndValidity();
                this.formComponent.baseFormGroupComponent.handleComponentEvent({
                    event: FormEvent.SAVE,
                    data: {} as LumiFormEventData
                });
            }
        }
    }

    ngAfterViewInit(): void {
        this.sectionComponent.connectForm(this.formComponentContainer, FormSectionComponent.SECTION_LAYOUT_ICON_BAR);
    }

    handleComponentEvent(eventData: any): void {
        // Handle events from the forms
        if (eventData.event == FormEvent.SCROLL_TO_SECTION) {
            this.sectionComponent.scrollToElement(
                null,
                eventData.data.sectionHash,
                eventData.data.scrollSmooth,
                eventData.data.autoExpandSection
            );
        }

        // Emit them to the parent component
        this.onComponentEvent.emit(eventData);
    }

    handleFormScroll(event: any): void {
        this.sectionComponent.handleFormScroll(event);
    }

    handleToggleCollapse(event: any): void {
        this.onToggleCollapse.emit(event);
    }

    get MODULE_PATH_ASSETS(): String {
        return AssetsComponent.MODULE_PATH_ASSETS;
    }

    get MODULE_PATH_DEVICE_MANAGEMENT(): String {
        return DeviceManagementComponent.MODULE_PATH_DEVICE_MANAGEMENT;
    }

    get MODULE_PATH_CONTROL(): String {
        return ControlComponent.MODULE_PATH_CONTROL;
    }

    get MODULE_PATH_REPORT_MALFUNCTION(): String {
        return ReportMalfunctionComponent.MODULE_PATH_REPORT_MALFUNCTION;
    }

    get MODULE_PATH_DEVICE_MALFUNCTION(): String {
        return DeviceMalfunctionComponent.MODULE_PATH_DEVICE_MALFUNCTION;
    }

    get MODULE_PATH_WORK_ORDERS(): String {
        return WorkOrdersComponent.MODULE_PATH_WORK_ORDERS;
    }

    get MODULE_PATH_MECHANIC(): String {
        return MechanicComponent.MODULE_PATH_MECHANIC;
    }

    get MODULE_PATH_STEDIN_ORDERS(): String {
        return StedinOrdersComponent.MODULE_PATH_STEDIN_ORDERS;
    }

    get MODULE_PATH_PUBLIC_PORTAL(): String {
        return PublicPortalComponent.MODULE_PATH_PUBLIC_PORTAL;
    }

    get MODULE_PATH_PROJECTS_CREATE(): String {
        return ProjectsCreateComponent.MODULE_PATH_PROJECTS_CREATE;
    }

    get MODULE_PATH_PROJECTS_MANAGE(): String {
        return ProjectsManageComponent.MODULE_PATH_PROJECTS_MANAGE;
    }

    get MODULE_PATH_USER_MANAGEMENT(): String {
        return UserManagementComponent.MODULE_PATH_USER_MANAGEMENT;
    }

    get MODULE_PATH_MASTER_DATA(): string {
        return MasterDataComponent.MODULE_PATH_MASTER_DATA;
    }
}
