import {ChangeDetectorRef, Component, ElementRef, NgZone} from '@angular/core';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {HTTPError} from '../../../shared/services/http/http-error';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {AbstractObjectFormComponent} from '../../../shared/components/form/containers/form/abstract-object-form.component';
import {
    ProjectsManageFormConfig,
    ProjectsManageFormRowName,
    ProjectsManageFormWorkOrderRowConfig
} from './projects-manage-form.interface';
import {FormEvent} from '../../../shared/components/form/containers/form/form.interface';
import {ConfigType} from '../../../shared/components/form/components/field/fieldDirective.interface';
import {take} from 'rxjs/operators';
import {BasicMapItem} from '../../../shared/components/map/map-item/map-item-importer/map-item-importer.interface';
import {MapItemService} from '../../../shared/components/map/map-item/map-item.service';
import {
    BaseStatus,
    StatusConfig
} from '../../../shared/components/form/components/form-group/form-group-with-status/form-group-with-status.interface';
import {ButtonCode, ButtonInterface, PopupResult, PopupResultCode} from '../../../wrapper/global-alert/global-popup';
import {StatusService} from '../../../shared/components/status/status.service';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'projects-manage-form-component',
    template : `
    <ng-container *ngIf="formData && config">
        <form-component
                #baseFormGroup
                [config]="config"
                [name]="getFormName()"
                [validationConstraints]="validationConstraints"
                [invalidControlsErrors]="invalidControlsErrors"
                [readOnly]="readOnly"
                [formIsSubmitted]="formIsSubmitted"
                (onComponentEvent)="handleComponentEvent($event)">
        </form-component>
    </ng-container>
    `
})

export class ProjectsManageFormComponent extends AbstractObjectFormComponent {
    public config: ProjectsManageFormConfig;

    public constructor(
        protected cd: ChangeDetectorRef,
        protected globalAlertService: GlobalAlertService,
        protected formDataService: FormDataService,
        protected zone: NgZone,
        protected elementRef: ElementRef,
        public model: GlobalModel,
        protected ts: TranslateService,
        private mapItemService: MapItemService,
        private statusService: StatusService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, model, logger);
    }
    
    public handleComponentEvent(eventData: any): void {
        switch (eventData.event) {
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                    this.onComponentEvent.emit({event: FormEvent.SAVE_SUCCESS, data: {baseObjectId: eventData.data.referenceId}});
                });
                break;
            case FormEvent.PROJECT_SAVE:
                this.submitProjectFormData(eventData.data.formData, eventData.data.referenceId);
                break;
            case FormEvent.PROJECT_DELETE:
                this.globalAlertService.addPopup(this.ts.translate('project.delete'), this.ts.translate('project.deletewarning'),
                    [
                        {label: this.ts.translate('Annuleren'), code: ButtonCode.ANNULEREN, isPrimary: true},
                        {label: this.ts.translate('Verwijderen'), code: ButtonCode.DELETE,
                            callback: () => {
                                this.submitProjectDelete(eventData.data.url);
                            }, isPrimary: false}
                    ], () => {});
                break;
            case FormEvent.BATCH_SAVE:
                this.submitBatchUpdate(this.model.projectsManageSelectedItems.value, eventData.data.formData, eventData.data.url, () => {
                    this.handleBatchUpdateSuccess();
                });
                break;
            case FormEvent.BATCH_DELETE:
                this.globalAlertService.addPopup(
                    this.ts.translate('Items verwijderen'),
                    this.ts.translate('Wilt u de geselecteerde items verwijderen?'),
                    [{label: this.ts.translate('Annuleren'), code: ButtonCode.ANNULEREN, isPrimary: true},
                        /*{label:this.ts.translate("Archiveren"), code:ButtonCode.ARCHIVE,
                            callback:() => {
                                this.submitBatchDelete(this.model.workOrdersSelectedItems.value, eventData.data.url, true, () => {
                                    this.handleBatchDeleteSuccess();
                                });
                            },
                            classes:GlobalAlertService.DEFAULT_WHITE_BUTTON_CLASSES},*/
                        {label: this.ts.translate('Verwijderen'), code: ButtonCode.DELETE,
                            callback: () => {
                                this.submitBatchDelete(this.model.projectsManageSelectedItems.value, eventData.data.url, false, () => {
                                    this.handleBatchDeleteSuccess();
                                });
                            },
                            isPrimary: false}
                    ], () => {});
                break;
            case FormEvent.WORKORDER_DELETE:
                this.globalAlertService.addPopup(
                    this.ts.translate('workorder.deletetitle'),
                    this.ts.translate('workorder.delete'),
                    [
                        {
                            label: this.ts.translate('Annuleren'),
                            code: ButtonCode.ANNULEREN,
                            isPrimary: false
                        },
                        {
                            label: this.ts.translate('Verwijderen'),
                            code: ButtonCode.DELETE,
                            callback: () => {
                                this.formDataService.deleteWorkOrder(eventData.data.attr.deleteUrl,
                                    () => {
                                        this.onComponentEvent.emit({event: FormEvent.WORKORDER_DELETE_SUCCESS});
                                        this.globalAlertService.addAlertSuccess(
                                            this.ts.translate('workorder.isdeletedtitle'),
                                            this.ts.translate('workorder.isdeletedtext' ), ''
                                        );
                                    },
                                    () => {},
                                    () => {});
                            },
                            isPrimary: true
                        }],
                    () => {}
                );
                break;
            case FormEvent.WORK_ACTIVITY_CREATE:
                this.globalAlertService.addPopupCreateWorkActivity(
                    eventData.data.attr.baseObjectId,
                    'components/activity/new/',
                    'components/activity/create/',
                    eventData.data.attr.dropDownData,
                    () => {
                        this.onComponentEvent.emit({
                            event: FormEvent.WORK_ACTIVITY_CREATE_SUCCESS,
                            data: {
                                referenceId: eventData.data.attr.baseObjectId
                            }
                        });
                    },
                    () => {
                        // Move is canceled, or failed. Do nothing
                    }
                );
                break;
            case FormEvent.WORK_SPECIFICATION_CREATE:
                this.globalAlertService.addPopupCreateWorkSpecification(
                    eventData.data.attr.baseObjectId,
                    'components/specification/new/',
                    'components/specification/create/',
                    eventData.data.attr.dropDownData,
                    () => {
                        this.onComponentEvent.emit({
                            event: FormEvent.WORK_SPECIFICATION_CREATE_SUCCESS,
                            data: {referenceId: eventData.data.attr.baseObjectId}
                        });
                    }, () => {
                        // Move is canceled, or failed. Do nothing
                    });
                break;
            case FormEvent.CHECK_ACTIVITY_CREATE:
                this.globalAlertService.addPopupCreateCheckActivity(eventData.data.attr.baseObjectId,
                    'components/check-activity/new/',  'components/check-activity/create/',
                    eventData.data.attr.dropDownData, eventData.data.attr.batchUpdate,
                    () => {
                        if (eventData.data.attr.batchUpdate && eventData.data.attr.batchUpdate === true) {
                            // todo: what if batch update creates new activities on work orders with status READY ?
                            this.onComponentEvent.emit({event: FormEvent.BATCH_SAVE_SUCCESS,
                                data: { referenceId: eventData.data.attr.baseObjectId}});
                            this.handleBatchUpdateSuccess();
                        } else {
                            this.updateWorkOrderState(eventData, true);
                            this.onComponentEvent.emit({
                                event: FormEvent.ACTIVITY_CREATE_SUCCESS,
                                data: {referenceId: eventData.data.attr.baseObjectId}
                            });
                        }
                    }, () => {
                        // Move is canceled, or failed. Do nothing
                    }
                );
                break;
            case FormEvent.CREATE_MAP_ITEMS:
                this.globalAlertService.addPopupCreateMapItems(
                    (button: ButtonInterface, locations: BasicMapItem[]) => {
                        this.createMapItems(locations, eventData);
                    }, () => {}
                );
                break;
            case FormEvent.ACTIVITY_SAVE_SUCCESS:
                this.updateWorkOrderState(eventData);
                break;
        }

        this.onComponentEvent.emit(eventData);
    }

    private submitBatchUpdate(items: any[], form: any, url: string, successCallBack?: () => any): void {
        if (!items || items.length === 0) {
            this.logger.log('[ProjectsManageFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setBatchUpdateFormData(this.model.projectsManageFormData, url, form, this.getFormName(), items,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitFormData(form: any, itemId: number, successCallBack?: () => any): void {
        if (itemId == 0) {
            this.logger.log('[ProjectsManageFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setFormDataForId(
            FormDataService.FORM_URL_PROJECTS_MANAGE,
            this.model.projectsManageFormData,
            form,
            this.getFormName(),
            itemId,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitBatchDelete(items: any[], url: string, archive: boolean = true, successCallBack?: () => any): void {
        if (!items || items.length === 0) {
            this.logger.log('[ProjectsManageFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.batchDelete(url, items, archive,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitProjectFormData(form: any, itemId: number): void {
        if (itemId == 0) {
            this.logger.log('[ProjectsManageFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setProjectFormDataForId(
            FormDataService.FORM_URL_PROJECTS_MANAGE,
            this.model.projectsManageFormData,
            form,
            this.getFormName(),
            itemId,
            () => {
                this.onComponentEvent.emit({event: FormEvent.PROJECT_SAVE_SUCCESS});
                this.handleSubmitResponse();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitProjectDelete(url: string): void {
        this.handleSubmitForm();

        this.formDataService.deleteProject(url,
            () => {
                this.handleSubmitResponse();
                this.globalAlertService.addAlertSuccess(
                    this.ts.translate('project.deletetitle'),
                    this.ts.translate('project.deletetext' ),
                    ''
                );
                this.onComponentEvent.emit({event: FormEvent.PROJECT_DELETE_SUCCESS});
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }
    
    private updateWorkOrderState(eventData: any, addedNewActivity: boolean = false): void {
        this.logger.log('[ProjectsManageFormComponent]' + 'activities changed; updating work order status...');
        const workOrderConfig: ProjectsManageFormWorkOrderRowConfig = this.config.children.find(
            configChild => configChild.name === ProjectsManageFormRowName.Werkbon
        ) as ProjectsManageFormWorkOrderRowConfig;
        
        if (workOrderConfig) {
            const statusConfig: StatusConfig = workOrderConfig.children.find(
                configChild => configChild.type === ConfigType.status
            ) as StatusConfig;
            
            if (statusConfig) {
                const currentStatus = statusConfig.options.find(option => option?.attr?.isActive);
                
                if (currentStatus) {
                    let statusChangeUrl = '';
                    const currentBaseStatus: BaseStatus = currentStatus.attr.baseStatus;
                    const inProgressStatusItems = statusConfig.options.filter(option => option.attr.baseStatus === BaseStatus.IN_PROGRESS);
                    const doneStatusItems = statusConfig.options.filter(option => option.attr.baseStatus === BaseStatus.DONE);
                    
                    this.logger.log('[ProjectsManageFormComponent] ' + 'current base status: ', currentBaseStatus);
                    
                    if (
                        currentBaseStatus === BaseStatus.DONE &&
                        (addedNewActivity || !eventData.areAllActivitiesDone) &&
                        inProgressStatusItems && inProgressStatusItems.length > 0
                    ) {
                        this.logger.log('[ProjectsManageFormComponent] '
                            + `${addedNewActivity ? 'new activity added' : 'activity set to TODO'}`
                            + '; adjusting work order status from READY to last IN_PROGRESS status and saving form');
                        const lastInProgressStatus = inProgressStatusItems[inProgressStatusItems.length - 1];
        
                        statusChangeUrl = `${statusConfig.action}/${lastInProgressStatus.id}`;
                        
                        this.statusService.getStatusChangeRequests(statusChangeUrl).pipe(
                            take(1)
                        ).subscribe(result => {
                            if (result.schema) {
                                this.logger.log('[ProjectsManageFormComponent] ' + 'target status requires parameters; not updated automatically');
                            } else {
                                eventData.data.formData[ProjectsManageFormRowName.Werkbon]['status'] = lastInProgressStatus.id;
                                this.handleSubmitSuccess(eventData);
                            }
                        });
                        
                        return;
                    }
    
                    if (
                        currentBaseStatus !== BaseStatus.DONE &&
                        eventData.areAllActivitiesDone &&
                        doneStatusItems && doneStatusItems.length > 0
                    ) {
                        this.logger.log('[ProjectsManageFormComponent] '
                            + 'Last activity done; adjusting work order status to first READY status and saving form');
                        const firstDoneStatus = doneStatusItems[0];
    
                        statusChangeUrl = `${statusConfig.action}/${firstDoneStatus.id}`;
    
                        this.statusService.getStatusChangeRequests(statusChangeUrl).pipe(
                            take(1)
                        ).subscribe(result => {
                            if (result.schema) {
                                this.logger.log('[ProjectsManageFormComponent] ' + 'target status requires parameters; not updated automatically');
                            } else {
                                eventData.data.formData[ProjectsManageFormRowName.Werkbon]['status'] = firstDoneStatus.id;
                                this.handleSubmitSuccess(eventData);
                            }
                        });
                        return;
                    }
                    
                    if (
                        currentBaseStatus === BaseStatus.TODO &&
                        !addedNewActivity &&
                        inProgressStatusItems && inProgressStatusItems.length > 0
                    ) {
                        this.logger.log('[ProjectsManageFormComponent] '
                            + 'first action on an activity'
                            + '; adjusting work order status from TODO to first IN_PROGRESS status and saving form');
                        const firstInProgressStatus = inProgressStatusItems[0];
    
                        statusChangeUrl = `${statusConfig.action}/${firstInProgressStatus.id}`;
    
                        this.statusService.getStatusChangeRequests(statusChangeUrl).pipe(
                            take(1)
                        ).subscribe(result => {
                            if (result.schema) {
                                this.logger.log('[ProjectsManageFormComponent] ' + 'target status requires parameters; not updated automatically');
                            } else {
                                eventData.data.formData[ProjectsManageFormRowName.Werkbon]['status'] = firstInProgressStatus.id;
                                this.handleSubmitSuccess(eventData);
                            }
                        });
                        return;
                    }
                }
            }
        }
    
        this.logger.log('[ProjectsManageFormComponent] '
            + 'no need to adjust work order status');
    
        // trigger table refresh
        this.onComponentEvent.emit({event: FormEvent.SAVE_SUCCESS, data: {referenceId: eventData.data.referenceId}});
    }
    
    private handleSubmitSuccess(eventData: any): void {
        this.showStatusAdjustedNotificationPopup();
        this.onComponentEvent.emit({event: FormEvent.SAVE_SUCCESS, data: {referenceId: eventData.data.referenceId}});
    }
    
    private showStatusAdjustedNotificationPopup(): void {
        this.globalAlertService.addAlertStatusUpdate(this.ts.translate('project.workorder.statusUpdatedAlert'));
    }
    
    private createMapItems(locations: BasicMapItem[], eventData: any): void {
        if (locations.length < 0) {
            this.globalAlertService.addAlert(
                this.ts.translate('project.addMapItems.noItemsAlert.title'),
                this.ts.translate('project.addMapItems.noItemsAlert'),
                '',
                'add_location'
            );
            return;
        }
        this.mapItemService.createMapItem(this.config.base_object_id, locations)
            .pipe(
                take(1)
            ).subscribe(() => {
                this.onComponentEvent.emit({
                    event: FormEvent.ADD_SUCCESS,
                    data: {baseObjectId: eventData.data.referenceId}
                });
            });
    }
}
