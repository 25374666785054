import {MapCoreV2Component} from "../map-core-V2.component";
import {
    ILayerMapItem,
    IMapItemStyles,
    IDrawingLayer,
    IWorkingLayer,
    mapItemTypes,
    ISnappablePoint, IParallelHeading, IMapItem
} from "./map-manager.interface";
import {MapLayer} from "./map-layer";
import {MapItem} from "./map-item";
import {LoggerService} from "../../../services/logger/logger.service";


export default class MapLayerManagerService {
    public layers: MapLayer[] = [];
    public focusLayer: google.maps.Rectangle;
    public drawingLayer: IDrawingLayer = null;

    constructor(private mapCoreV2:MapCoreV2Component, protected logger:LoggerService){}

    public initCreateLayers():void{
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Initialize layers')

        //Init the layers
        this.mapCoreV2.model.mapItemLayers.value.map(_layer => {
            const newLayer = new MapLayer(this.mapCoreV2, _layer.id,  _layer.name);
            this.layers.push(newLayer);
        })
    }

    public getLayerById(layerId: number) {
        return this.layers.find(_layer => {
            return _layer.layerId === layerId;
        })
    }

    public appendMapItem(mapItem: MapItem){
        // this.logger.log('[Grid][MapLayerManagerService] ' + 'Append map item',mapItem)
        //Append map item to the specified layer
        this.layers.filter(_x => {
            return _x.layerId === mapItem.getLayerId()
        }).map(_layer =>{
            _layer.appendMapItem(mapItem);
        })
    }

    public removeMapItem(baseObjectId:number){
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Remove map item with baseObjectId: '+baseObjectId)
        //Remove map item from layer
        this.layers.map(_layer =>{
            _layer.removeMapItem(baseObjectId)
        })
    }

    public clearAllLayers(){
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Clear all layers')
        this.layers.map(_layer => {
            _layer.clear();
        })
    }

    public filterItemsLocal(hiddenItems: (number | string)[], visibleItems: (number | string)[]):void{
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Filters items. Total hidden: '+hiddenItems.length+'. Total visible: '+visibleItems.length)

        const start = performance.now();
        const tempMapping = {};
        this.layers.map(_layer => {
            _layer.getMapItems().map(_mapItem => {
                tempMapping[_mapItem.getBaseObjectId()] = _mapItem;
            })
        })

        hiddenItems.map(_baseObjectId => {
            const mapItem = tempMapping[_baseObjectId]
            if(mapItem) {
                mapItem.highlight(false)
                mapItem.setHidden()
            }
        })

        visibleItems.map(_baseObjectId => {
            const mapItem = tempMapping[_baseObjectId]
            if(mapItem){
                if (mapItem.isSelected) {
                    mapItem.highlight(true)
                }
                mapItem.setVisible()
            }
        });

        const end = performance.now();
        this.logger.log('[Grid][MapLayerManagerService] Filters items done in ' + (end - start) + ' ms')
    }

    private setMapItemVisibleByBaseObjectId(baseObjectId:number):void{
        //Search for baseobjectid in all layers and set the map item visible
        let continueLookup:boolean = true;
        this.layers.map(_layer => {
            if(_layer.getMapItems().length > 0 && continueLookup){
                _layer.getMapItems().map(_mapItem => {
                    if(_mapItem.getBaseObjectId() === baseObjectId){
                        if(_mapItem.isSelected){
                            _mapItem.highlight(true)
                        }
                        _mapItem.setVisible()
                        continueLookup = false
                    }
                })
            }
        })
    }

    private setMapItemHiddenByBaseObjectId(baseObjectId:number):void{
        //Search for baseobjectid in all layers and set the map item hidden
        let continueLookup:boolean = true;
        this.layers.map(_layer => {
            if(_layer.getMapItems().length > 0 && continueLookup){
                _layer.getMapItems().map(_mapItem => {
                    if(_mapItem.getBaseObjectId() === baseObjectId){
                        _mapItem.highlight(false)
                        _mapItem.setHidden()
                        continueLookup = false
                    }
                })
            }
        })
    }

    public toggleAnnotations(show:boolean):void{
        this.layers.find(_layer => _layer.layerId === this.mapCoreV2.mapSettings.ANNOTATION_LAYER_HARDCODED_ID).getMapItems().map(_mapItem => {
            if(show && this.mapCoreV2.showAnnotations){
                _mapItem.showAnnotation()
            } else {
                _mapItem.hideAnnotation()
            }
        })
    }

    public getMapItemById(mapItemId:number):MapItem{
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Get map item by mapItemId: '+mapItemId)
        //Search for the mapitem in all layers by id
        let mapItemToReturn:MapItem = null;
        this.layers.map(_layer => {
            if(_layer.getMapItems().length > 0 && mapItemToReturn === null){
                _layer.getMapItems().map(_mapItem => {
                    if(_mapItem.getId() === mapItemId){
                        mapItemToReturn = _mapItem;
                    }
                })
            }
        })
        return mapItemToReturn;
    }

    public closeInfoWindowForAllMapItems():void{
        this.layers.map(_layer => {
            if(_layer.getMapItems().length > 0){
                _layer.getMapItems().map(_mapItem => {
                   _mapItem.hideInfoWindow()
                })
            }
        })
    }

    public getAllClusterMapItems():MapItem[]{
        //Return all cluster map items
        const allMapItems:MapItem[] = [];
        this.layers.map(_layer => {
            if(_layer.layerId == this.mapCoreV2.mapSettings.CLUSTER_LAYER_HARDCODED_ID){
                _layer.getMapItems().map(_mapItem => {
                    allMapItems.push(_mapItem)
                })
            }
        })
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Get all cluster map items. Total items: '+allMapItems.length)
        return allMapItems;
    }

    public clearAllClusterMapItems():void{
        this.layers.map(_layer => {
            if(_layer.layerId == this.mapCoreV2.mapSettings.CLUSTER_LAYER_HARDCODED_ID){
                _layer.clear();
            }
        })
    }

    public getAllNonV1MapItems():MapItem[]{
        //Return all V2 map items
        const allMapItems:MapItem[] = [];
        this.layers.map(_layer => {
            if(_layer.layerId != this.mapCoreV2.mapSettings.V1_MARKER_LAYER_HARDCODED_ID && _layer.layerId != this.mapCoreV2.mapSettings.ANNOTATION_LAYER_HARDCODED_ID){ //Do not return V1 markers
                _layer.getMapItems().map(_mapItem => {
                    if(_mapItem.googlePolyline !== null){
                        allMapItems.push(_mapItem)
                    }
                })
            }
        })
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Get all non V1 map items. Total items: '+allMapItems.length)
        return allMapItems;
    }

    public getAllNonV1SelectedMapItems():MapItem[]{
        //Return all V2 map items that are selected
        const allMapItems:MapItem[] = [];
        this.layers.map(_layer => {
            if(_layer.layerId != this.mapCoreV2.mapSettings.V1_MARKER_LAYER_HARDCODED_ID && _layer.layerId != this.mapCoreV2.mapSettings.ANNOTATION_LAYER_HARDCODED_ID){ //Do not return V1 markers
                _layer.getMapItems().map(_mapItem => {
                    if(_mapItem.isHighlighted && _mapItem.googlePolyline !== null){
                        allMapItems.push(_mapItem)
                    }
                })
            }
        })
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Get all non V1 selected map items. Total items: '+allMapItems.length)
        return allMapItems;
    }

    public getSnappablePoints():ISnappablePoint[]{
        let snappablePoints:ISnappablePoint[] = [];
        this.layers.map(_layer => {
            if (_layer.isSnappable === true) {
                snappablePoints.push(..._layer.getSnappablePoints());
            }
        })
        return snappablePoints;
    }

    public getParallelHeadings():IParallelHeading[]{
        let headings:IParallelHeading[] = [];
        this.layers.map(_layer => {
            if (_layer.isParallel === true) {
                headings.push(..._layer.getParallelHeadings());
            }
        })
        return headings;
    }

    public setAllMapItemsClickable(isClickable:boolean):void{
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Set all map items clickable')
        //Map items click handle
        this.layers.map(_layer => {
            _layer.setClickable(isClickable)
        })
    }

    public toggleMapItemsVisible(layerId:number, isVisible:boolean){
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Set all map items for layerId: '+layerId+', visible: '+isVisible)
        let toggleLayers:boolean = true;
        if(layerId === this.mapCoreV2.mapSettings.ANNOTATION_LAYER_HARDCODED_ID){
            this.mapCoreV2.showAnnotations = isVisible
            if(this.mapCoreV2.map.getZoom() < this.mapCoreV2.mapSettings.MINIMUM_ZOOM_LEVEL_TO_TRIGGER_GRID_FUNCTIONS){
                toggleLayers = false
            }
        }

        if(toggleLayers){
            this.layers.filter(_x => {
                return _x.layerId === layerId
            }).map(_layer =>{
                if(isVisible){
                    _layer.show();
                } else {
                    _layer.hide();
                }
            })
        }
    }

    public toggleOptionsForAllLayers(option:'snappable'|'parallel'){
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Toggle '+option+' for all layers')
        this.layers.map(_layer => {
            if(option === 'snappable'){
                _layer.isSnappable = _layer.isSnappable ? false : true;
            } else if(option === 'parallel'){
                _layer.isParallel = _layer.isParallel ? false : true;
            }
        })
    }

    public isParallelActive():boolean{
        return this.layers.at(-1).isParallel
    }

    public isSnappingActive():boolean{
        return this.layers.at(-1).isSnappable
    }

    public createFocusLayer(){
        //Create the focus layer
        this.focusLayer = new google.maps.Rectangle({
            strokeWeight: 0,
            fillColor: this.mapCoreV2.mapSettings.FOCUSLAYER_BG_COLOR,
            fillOpacity: this.mapCoreV2.mapSettings.FOCUSLAYER_BG_OPACITY,
            map: this.mapCoreV2.map,
            visible: false,
            clickable: false,
            draggable: false,
            editable: false,
            zIndex: this.mapCoreV2.mapSettings.ZINDEX_FOCUSLAYER,
            bounds: {
                north: -90,
                south: 90,
                east: 180,
                west: -180,
            },
        });
    }

    public toggleFocusLayer(isVisible:boolean){
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Show focus layer: '+isVisible)
        this.focusLayer.setVisible(isVisible)
    }

    public clearDrawingLayer():void{
        this.logger.log('[Grid][MapLayerManagerService] ' + 'Clear the drawing layer')
        if(this.drawingLayer !== null){
            //Clear the drawing layer if it has been used
            this.drawingLayer.mapItem.setMap(null)
            this.drawingLayer = null
        }
        if(this.mapCoreV2.polylineDrawingTail !== null){
            //Clear the tail if it has been used
            this.mapCoreV2.polylineDrawingTail.setMap(null);
            this.mapCoreV2.polylineDrawingTail = null;
        }
        if(this.mapCoreV2.selectedMapItem !== null){
            //Clear the selected map item if it has been used
            this.mapCoreV2.selectedMapItem = null;
        }
        //Clear the fixed length
        this.mapCoreV2.polylineDrawingHeadFixedLength = 0;
    }
}
