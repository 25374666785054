
import {ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, Output} from "@angular/core";
import {AbstractBaseFormComponent} from "../../shared/components/form/containers/form/abstract-base-form.component";
import {RequestFailure} from "../../shared/services/http/request-failure";
import {HTTPError} from "../../shared/services/http/http-error";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";
import {FormComponent} from "../../shared/components/form/containers/form/form.component";
import {GlobalModel} from "../../shared/services/state/global.model";
import {FormDataService} from "../../shared/components/form/services/form-data.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {AbstractObjectFormComponent} from "../../shared/components/form/containers/form/abstract-object-form.component";
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {ButtonCode} from '../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'control-form-component',
    template : `        
    <ng-container *ngIf="formData && config"> 
        <form-component
                #baseFormGroup
                [config]="config"
                [name]="getFormName()"
                [validationConstraints]="validationConstraints"
                [invalidControlsErrors]="invalidControlsErrors"
                [readOnly]="readOnly"
                [formIsSubmitted]="formIsSubmitted"
                (onComponentEvent)="handleComponentEvent($event)">
        </form-component>
        <!--<validation-warning [showWarning]="hasFailedConstraints()"></validation-warning>-->
    </ng-container>
    `
})

export class ControlFormComponent extends AbstractObjectFormComponent {

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected zone:NgZone, protected elementRef:ElementRef, public model:GlobalModel, protected ts:TranslateService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, model, logger);
    }

    public handleComponentEvent(eventData:any):void{
        switch (eventData.event){
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                    this.onComponentEvent.emit({event:FormEvent.SAVE_SUCCESS, data:{baseObjectId:eventData.data.referenceId}});
                });
                break;
            case FormEvent.BATCH_SAVE:
                this.submitBatchUpdate(this.model.controlSelectedItems.value, eventData.data.formData, eventData.data.url, () => {
                    this.handleBatchUpdateSuccess();
                });
                break;
            case FormEvent.DIMGROUP_SAVE:
                this.submitDimGroupFormData(eventData.data.formData, eventData.data.referenceId);
                break;
            case FormEvent.DIMGROUP_DELETE:
                this.globalAlertService.addPopup(this.ts.translate("dimgroup.delete"), this.ts.translate("dimgroup.deletewarning"),
                    [
                        {label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                        {label:this.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                        callback:() => {
                            this.submitDimGroupDelete(eventData.data.url);
                        }, isPrimary:false}
                        ], () => {});
                break;
            case FormEvent.CONTROL_OVERRIDE_SAVE:
                this.submitOverrideFormData(eventData.data.formData, eventData.data.referenceId);
                break;
            case FormEvent.OVERRIDE_DELETE:
                this.globalAlertService.addPopup(this.ts.translate("override.delete"), this.ts.translate("override.deletewarning"),
                    [
                        {label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:false},
                        {label:this.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                            callback:() => {
                                this.submitOverrideDelete(eventData.data.url);
                            },
                            isPrimary:true}
                    ], () => {});
                break;
            case FormEvent.SYSTEMGROUP_SAVE:
                this.submitSystemGroupFormData(eventData.data.formData, eventData.data.referenceId);
                break;
            case FormEvent.SYSTEMGROUP_DELETE:
                this.globalAlertService.addPopup(this.ts.translate("systemgroup.delete"), this.ts.translate("systemgroup.deletewarning"),
                    [
                        {label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:false},
                        {label:this.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                        callback:() => {
                            this.submitSystemGroupDelete(eventData.data.url);
                        },
                          isPrimary:true}
                        ], () => {});
                break;
            case FormEvent.EXCEPTION_CREATE:
                this.globalAlertService.addPopupCreateException(eventData.data.attr.dimGroupId, null, () => {

                    //Exception created
                    this.onComponentEvent.emit({event:FormEvent.EXCEPTION_CREATE_SUCCESS, data:{referenceId:eventData.data.attr.dimGroupId}});

                }, () => {
                   // cancel/close/fail
                });
                break;
            case FormEvent.SCHEME_EDIT:
            case FormEvent.CLICK_GRAPH:

                //When selecting via a graph there is a schemeid present
                let schemeId:number = -1;
                if (eventData && eventData.data && eventData.data.dimmingSchemeId > 0){
                    schemeId = eventData.data.dimmingSchemeId;
                }

                let selectedChannel:number = -1;
                if (eventData && eventData.data && eventData.data.channel > -1){
                    selectedChannel = eventData.data.channel;
                }

                this.globalAlertService.addPopupEditScheme(schemeId, selectedChannel, () => {

                }, (buttonCode:string, restData:any) => {
                    //This popup can only be terminated with the close button

                    // cancel/close/fail

                    // Only refresh if the data is changed
                    if (restData.dataChanged){
                        this.onComponentEvent.emit({event:FormEvent.CLOSE_SCHEME_EDITOR, data:{referenceId:this.config.referenceId}});
                    }
                });
                break;

            case FormEvent.CONTROL_PROGRAM_EDIT:

                //When selecting via a graph there is a schemeid present
                let schemeIdControlProgram:number = -1;
                if (eventData && eventData.data && eventData.data.dimmingSchemeId > 0){
                    schemeIdControlProgram = eventData.data.dimmingSchemeId;
                }

                let selectedChannelControlProgram:number = -1;
                if (eventData && eventData.data && eventData.data.channel > -1){
                    selectedChannelControlProgram = eventData.data.channel;
                }

                this.globalAlertService.addPopupControlProgramEdit(schemeIdControlProgram, selectedChannelControlProgram, () => {

                }, (buttonCode:string, restData:any) => {
                    //This popup can only be terminated with the close button

                    // cancel/close/fail

                    // Only refresh if the data is changed
                    if (restData.dataChanged){
                        this.onComponentEvent.emit({event:FormEvent.CLOSE_CONTROL_PROGRAM_EDIT, data:{referenceId:this.config.referenceId}});
                    }
                });
                break;

            case FormEvent.CONTROL_EXCEPTION_EDIT:
                this.globalAlertService.addPopupControlExceptionEdit(eventData.data, () => {

                }, (buttonCode:string, restData:any) => {
                    //This popup can only be terminated with the close button

                    // cancel/close/fail

                    // Only refresh if the data is changed
                    if (restData.dataChanged){
                        this.onComponentEvent.emit({event:FormEvent.CLOSE_CONTROL_EXCEPTION_EDIT, data:{referenceId:this.config.referenceId}});
                    }
                });
                break;
        }

        this.onComponentEvent.emit(eventData);
    }

    private submitBatchUpdate(items:any[], form:any, url:string, successCallBack?:() => any):void
    {
        if (!items || items.length == 0){
            this.logger.log("[ControlFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setBatchUpdateFormData(this.model.controlFormData, url, form, this.getFormName(), items,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitFormData(form: any, itemId:number, successCallBack?:() => any):void
    {
        if (itemId == 0){
            this.logger.log("[ControlFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setFormDataForId(FormDataService.FORM_URL_CONTROL, this.model.controlFormData, form, this.getFormName(), itemId,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitDimGroupFormData(form: any, itemId:number):void
    {
        if (itemId == 0){
            this.logger.log("[ControlFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setDimGroupFormDataForId(FormDataService.FORM_URL_CONTROL, this.model.controlFormData, form, this.getFormName(), itemId,
            () => {
                this.onComponentEvent.emit({event:FormEvent.DIMGROUP_SAVE_SUCCESS});
                this.handleSubmitResponse();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitDimGroupDelete(url:string):void
    {
        this.handleSubmitForm();

        this.formDataService.deleteDimGroup(url,
            () => {
                this.handleSubmitResponse();
                this.globalAlertService.addAlertSuccess(this.ts.translate('dimgroup.deletetitle'), this.ts.translate('dimgroup.deletetext' ), '');
                this.onComponentEvent.emit({event:FormEvent.DIMGROUP_DELETE_SUCCESS});
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitSystemGroupFormData(form: any, itemId:number):void
    {
        if (itemId == 0){
            this.logger.log("[ControlFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setSystemGroupFormDataForId(FormDataService.FORM_URL_CONTROL, this.model.controlFormData, form, this.getFormName(), itemId,
            () => {
                this.onComponentEvent.emit({event:FormEvent.SYSTEMGROUP_SAVE_SUCCESS});
                this.handleSubmitResponse();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }
    private submitOverrideFormData(form: any, itemId:number):void
    {
        if (itemId == 0){
            this.logger.log("[ControlFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setOverrideFormDataForId(FormDataService.FORM_URL_CONTROL, this.model.controlFormData, form, this.getFormName(), itemId,
            () => {
                this.onComponentEvent.emit({event:FormEvent.CONTROL_OVERRIDE_SAVE_SUCCESS});
                this.handleSubmitResponse();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }
    private submitSystemGroupDelete(url:string):void
    {
        this.handleSubmitForm();

        this.formDataService.deleteSystemgroup(url,
            () => {
                this.handleSubmitResponse();

                this.onComponentEvent.emit({event:FormEvent.SYSTEMGROUP_DELETE_SUCCESS});
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitOverrideDelete(url:string):void
    {
        this.handleSubmitForm();

        this.formDataService.deleteOverride(url,
            () => {
                this.handleSubmitResponse();

                this.onComponentEvent.emit({event:FormEvent.OVERRIDE_DELETE_SUCCESS});
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitDimGroupOverride(url:string):void
    {
        this.handleSubmitForm();

        this.formDataService.deleteDimGroup(url,
            () => {
                this.handleSubmitResponse();

                this.onComponentEvent.emit({event:FormEvent.DIMGROUP_DELETE_SUCCESS});
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }
}
