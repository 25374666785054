/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    Output
} from '@angular/core';
import {TreeNodeLMX} from './tree-node-lmx';
import {TreeService} from './tree.service';
import {AppSettings} from '../../../../app.settings';
import {GlobalModel} from '../../../services/state/global.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import {TreeLMX} from './tree-lmx';
import {Router} from '@angular/router';
import {FormEvent} from '../../form/containers/form/form.interface';
import {GlobalAlertService} from '../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../services/translate/translate.service';
import {FormDataService} from '../../form/services/form-data.service';
import {CreateNodeObject} from './tree-node-interface';

@Component ({
    selector: 'tree-node-component',
    template: `
        <div class="tree-node-component{{node?.selected?'-selected':''}} d-flex" id="{{node?.index}}" *ngIf="node?.isVisible !== false || node?.selected === true"
             [ngStyle]="{ 'padding-left': getLeftPadding()}" (globalClickOutsideAngular)="closePopups()">
            <div class="tree-node-icon-container" *ngIf="node.children.length > 0" (click)="toggleExpand(node)">
                <i class="material-icons tree-node-icon">{{node.expanded ? 'expand_more' : 'keyboard_arrow_right'}}</i>
            </div>
            <div class="d-flex justify-content-between w-100"
                 (click)="handleSelectNode($event, node)"
                 (dblclick)="toggleExpand(node)">
                <div [attr.title]="('total-items' | translate)+' '+totalChildrenCount(node)">
                    <span class="pr-1">
                        <i *ngIf="node.children.length>0; else single_item_icon"
                           class="material-icons tree-node-icon">{{(node.expanded ? 'folder_open' : 'folder')}}</i>
                        <ng-template #single_item_icon>
                            <i class="material-icons tree-node-icon-single">{{childNodeIcon}}</i>
                        </ng-template>
                     </span>
                    <label [title]="isUnknownNode()?('tree.unknownnode' | translate):('total-items' | translate)+' '+totalChildrenCount(node)"
                           [ngClass]="{'tree-node-label-highlighted':node.isHighlighted}"
                           class="tree-node-label {{isUnknownNode()?'font-italic':''}}">{{node.label}}</label>
                </div>
                <div class="d-flex">
                    <div class="d-flex pl-2" *ngIf="node.counts && (!node.expanded || node.children.length == 0)"
                         style="align-items: center;">
                        <ng-container *ngFor="let count of node.counts; let countIndex = index">
                            <span *ngIf="count > 0" class="mr-2 badge badge-pill"
                                  [style.background]=getCountColor(countIndex)
                                  title="{{getCountDescription(count, countIndex)}}">{{count}}</span>
                        </ng-container>
                    </div>
                    <div class="d-flex pl-2" *ngIf="node.attr && node.attr.override" style="align-items: center;">
                        <span class="mr-2 d-flex" title="{{'override.active' | translate}}">
                            <i class="material-icons p-0">wb_incandescent</i>
                        </span>
                    </div>
                    <div class="d-flex pl-2" *ngIf="hasButtons(node)" style="align-items: center;">
                        <ng-container *ngFor="let button of node.buttons;">
                            <span class="mr-2 d-flex" title="{{button.title}}">
                                <div class="tree-node-button {{button.state}}"
                                     (click)="handleClickButton($event, button)">
                                    <i class="material-icons p-1">{{button.icon}}</i>
                                </div>
                            </span>
                        </ng-container>
                    </div>
                    <div class="d-flex pl-2" *ngIf="node.attr && node.attr.color && hideActionIcon"
                         style="align-items: center;">
                        <span class="mr-2 z-depth-1 tree-node-color" [style.background]=node.attr.color
                              title="{{'dimgroup.color' | translate}}"></span>
                    </div>
                    <div class="d-flex pr-2 pl-2" *ngIf="node.action != null && !hideActionIcon"
                         style="align-items: center;">
                        <i *ngIf="node.action != ''" class="material-icons tree-node-icon-action cursor-pointer"
                           (click)="handleClickIcon($event, node)"
                           title="{{nodeActionTitleKey | translate}}">{{nodeActionIcon}}</i>
                        <i *ngIf="node.action == ''" class="empty-icon"></i>
                    </div>
                    <div class="d-flex pr-2 pl-2 position-relative" *ngIf="this.hasMenuOptions()"
                         style="align-items: center;">
                        <i class="material-icons tree-node-icon-action cursor-pointer"
                           (click)="handleClickMenu($event)"
                           title="{{'tree.node.moreOptions' | translate}}">menu</i>
                        <div class="tn-menu-dropdown" *ngIf="showMenu && this.hasMenuOptions()">
                            <div class="tn-dropdown-option-container">
                                <div class="tn-dropdown-option" (click)="handleClickCreate($event)">
                                    <i>add</i>
                                    <span title="{{'tree.node.moreOptions.add' | translate}}">
                                        {{'tree.node.moreOptions.add' | translate}}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <ng-container *ngIf="node.expanded">
            <tree-node-component [tree]="tree" [node]="child" [childNodeIcon]="childNodeIcon"
                                 [hideActionIcon]="hideActionIcon"
                                 [nodeActionIcon]="nodeActionIcon"
                                 [nodeActionTitleKey]="nodeActionTitleKey"
                                 (onSelectionChange)="handleChildSelectionChange($event)"
                                 (onTreeNodeAction)="handleChildTreeNodeAction($event)"
                                 (onTreeNodeButton)="handleChildTreeNodeButton($event)"
                                 (onTreeNodeCreate)="handleChildTreeNodeCreate($event)"
                                 [unfoldFolderClickNode]="unfoldFolderClickNode"
                                 *ngFor="let child of node.children"></tree-node-component>
        </ng-container>
    `
})

export class TreeNodeComponent implements OnDestroy {

    private static readonly BUTTON_STATE_ENABLED: string = 'enabled';

    @Input('tree') public tree: BehaviorSubject<TreeLMX>;
    @Input('node') public node: TreeNodeLMX;
    @Input('unfoldFolderClickNode') unfoldFolderClickNode: boolean = false;
    @Input() childNodeIcon: string = 'place';
    @Input() nodeActionTitleKey: string = 'dimgroup.edit';
    @Input() nodeActionIcon: string = 'settings';
    @Input() hideActionIcon: boolean = false;

    @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();
    @Output() onTreeNodeAction: EventEmitter<any> = new EventEmitter();
    @Output() onTreeNodeButton: EventEmitter<any> = new EventEmitter();
    @Output() onTreeNodeCreate: EventEmitter<CreateNodeObject> = new EventEmitter();

    private mobileMode: boolean = false;
    private subMobileMode: Subscription;
    public showMenu: boolean = false;

    constructor(
        private treeService: TreeService,
        private router: Router,
        private model: GlobalModel,
        private cd: ChangeDetectorRef
    ) {

        this.subMobileMode = model.mobileMode.subscribe((value: boolean) => {
            // Don't forget to unsubscribe
            this.mobileMode = value;
            this.cd.markForCheck();
        });
    }

    public isUnknownNode(): boolean {
        return (this.node && this.node.attr && this.node.attr.unknown);
    }

    public ngOnDestroy(): void {
        if (this.subMobileMode) {
            this.subMobileMode.unsubscribe();
        }
    }

    public handleClickButton(event: MouseEvent, button: any): void {
        // Prevent clicking on the tree node itself
        event.preventDefault();
        event.stopImmediatePropagation();

        // When enabled, execute the button url
        if (button.state == TreeNodeComponent.BUTTON_STATE_ENABLED) {
            this.onTreeNodeButton.emit({button: button});
        }
    }

    public totalChildrenCount(node: TreeNodeLMX):number{
        if (node.children.length == 0) {
            return 1;
        }
        let total = 0;
        if(node.children.length > 0){
            node.children.map(child => {
                if(child.children.length > 0) {
                    total += child.children.length;
                } else {
                    total += 1;
                }
            })
        }
        return total;
    }


    public hasButtons(node: TreeNodeLMX): boolean {
        return node && node.buttons && node.buttons.length > 0;
    }

    public handleSelectNode(event: MouseEvent, node: TreeNodeLMX) {

        if (this.unfoldFolderClickNode && node.children.length > 0) {
            this.toggleExpand(node);
        } else {
            // Sample nodes
            /*node.counts = [
                {"label":"w", "description":"Werkorders", "count":Utils.getRandomNumber(0,5), "color":"#508ec9"},
                {"label":"s", "description":"Storingen", "count":Utils.getRandomNumber(0,5), "color":"#ff7b63"},
                {"label":"r", "description":"Remplace", "count":Utils.getRandomNumber(0,5), "color":"#939393"}
                ];*/


            // TODO: als dit veel items worden, dan moet je niet alles brute deselecten ect. maar werken met pools

            this.treeService.storeNodeClick(node);

            if (event.shiftKey) {
                this.treeService.handleShiftClick(this.tree);
            } else {
                // Handle ctrl click
                if (event.ctrlKey || event.metaKey) {
                    node.selected = !node.selected;
                } else {
                    this.treeService.deselectAllNodes(this.tree);
                    node.selected = true;
                }

                if (node.selected) {
                    if (AppSettings.AUTO_DESELECT_GRANDCHILDREN) {
                        this.treeService.deselectGrandChildren(node);
                    }
                    if (AppSettings.AUTO_DESELECT_GRANDPARENTS) {
                        this.treeService.deselectGrandParents(node);
                    }
                }
            }

            // Let the service (one instance) handle the multi-select-delay
            this.treeService.handleClickNode(() => {this.handleSelectionChanged(); });
        }

    }

    public handleClickIcon(event: MouseEvent, node: any) {
        // Prevent other clicks and default behaviour
        event.preventDefault();
        event.stopImmediatePropagation();

        if (node && node.action && node.action != '') {
            this.onTreeNodeAction.emit({action: node.action});
        }
    }

    public getCountDescription(count: number, countIndex: number): string {

        let result: string = '';

        // Make sure the matching count description isn't out of bounds
        if (this.tree.value && this.tree.value.countDescriptions && countIndex < this.tree.value.countDescriptions.length) {
            result = count + ' ' + this.tree.value.countDescriptions[countIndex].description;
        }

        return result;
    }

    public getCountColor(countIndex: number) {
        // Default to extreme color
        let result: any = '#00FF00';

        // Make sure the matching count description isn't out of bounds
        if (this.tree.value && this.tree.value.countDescriptions && countIndex < this.tree.value.countDescriptions.length) {
            switch (this.tree.value.countDescriptions[countIndex].type.toLowerCase()) {
                case 'werkorders':
                    result = AppSettings.COLOR_YELLOW;
                    break;
                case 'offline':
                    result = AppSettings.COLOR_GREY;
                    break;
                case 'defect':
                    result = AppSettings.COLOR_RED;
                    break;
                case 'uncommissioned':
                case 'attention':
                    // TODO: dit is de zelfde kleur paars als op de icoontjes op de kaart, maar nog geen systeemstandaard
                    result = 'b973ff'; // AppSettings.COLOR_PURPLE;
                    break;
            }
        }

        return '#' + result;
    }

    public handleChildSelectionChange(selectionData: any) {
        this.onSelectionChange.emit(selectionData);
    }

    public handleSelectionChanged() {
        // Collect all references for selected nodes
        let references: any = [];
        let nodes: any = [];
        this.treeService.getAllSelectedNodes(this.tree).forEach((node) => {

            // Root has no reference
            if (node.reference) {
                references.push(node.reference);
            }

            nodes.push(node);
        });

        this.onSelectionChange.emit({references: references, treeNodes: nodes});
    }

    public handleChildTreeNodeAction(data: any): void {
        this.onTreeNodeAction.emit(data);
    }

    public handleChildTreeNodeButton(data: any): void {
        this.onTreeNodeButton.emit(data);
    }

    public handleChildTreeNodeCreate(data: CreateNodeObject): void {
        this.onTreeNodeCreate.emit(data);
    }
    
    public getLeftPadding(): string {
        if (this.node) {
            return (this.node.children.length > 0 ? 0 : 16) + (this.node.level * 14) + 'px';
        } else {
            return '0px';
        }
    }

    public toggleExpand(node: TreeNodeLMX) {
        if (node && node.children.length > 0) {
            node.expanded = !node.expanded;
        }
        // event.stopPropagation();
    }
    
    public closePopups(): void {
        this.showMenu = false;
    }
    
    public handleClickMenu($event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();
    
        this.showMenu = !this.showMenu;
    }
    
    public handleClickCreate($event: MouseEvent): void {
        $event.stopPropagation();
        $event.preventDefault();
        this.showMenu = false;
        
        this.onTreeNodeCreate.emit({
            parentNode: this.node.label ? this.node.label : null,
            creationHref: <string>this.node.creationHref
        });
    }
    
    public hasMenuOptions() {
        return !!this.node.creationHref && this.node.creationHref !== '';
    }
}
