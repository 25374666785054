import {AfterViewInit, ChangeDetectorRef, Component, HostBinding, OnDestroy, Renderer2, ViewChild} from '@angular/core';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {GoogleChartComponent} from '../../../commonUI/googleChart/google-chart.component';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {FormDataService} from '../../services/form-data.service';
import {Subscription} from 'rxjs';
import {GlobalModel} from '../../../../services/state/global.model';
import {TranslateService} from '../../../../services/translate/translate.service';
import {AbstractFormWidget} from './abstract-form-widget';
import {GlobalEvent} from '../../../../interfaces/global-event';
import {AuthorizationService} from '../../../../services/authorization/authorization.service';
import {AppSettings} from '../../../../../app.settings';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-line-graph',
    template: `
        <ng-container [formGroup]="group">
            <div class="d-flex flex-column dimming-scheme-selector mt-3">
                <div *ngIf="config && config.attr && config.attr.graphs && config.attr.graphs.length > 1"
                     class="d-flex flex-row px-2 py-2">
                    <div *ngFor="let graph of config.attr.graphs" (click)="handleClickTab($event,graph)"
                         [title]="graph.label"
                         class="px-1 dimming-scheme-selector-day-block unselectable-content text-truncate tabs {{isSelectedGraph(graph)?'selected-graph':''}}">
                        {{graph.label}}
                    </div>
                </div>
                <label *ngIf="config.label && config.label != ''"
                       class="pl-2 form-sub-title d-flex {{!showDatePickers?'mb-0':''}} pt-3 pb-1"
                       for="{{config.name}}">{{ config.label }}
                    <ng-container *ngIf="infoText">
                        <help-icon-component [tooltipText]="infoText"
                                             [tooltipPlacement]="PLACEMENT_BOTTOM"></help-icon-component>
                    </ng-container>
                </label>
                <div class="d-flex justify-content-between align-items-end">
                    <div *ngIf="showDatePickers" class="line-graph-date-row px-2">
                        <div [title]="'Startdatum' | translate" class="col-6 date-row-left">
                            <date-input [inputType]="'date'"
                                        [allowClear]="false"
                                        [maxDate]="disableUntil"
                                        [initialValue]="startDate"
                                        (onDateChanged)="onStartDateChanged($event)">
                            </date-input>
                        </div>
                        <div [title]="'Einddatum' | translate" class="col-6 date-row-right no-padding">
                            <date-input [inputType]="'date'"
                                        [allowClear]="false"
                                        [maxDate]="disableUntil"
                                        [initialValue]="endDate"
                                        (onDateChanged)="onEndDateChanged($event)">
                            </date-input>
                        </div>

                    </div>
                    <div *ngIf="canEdit">
                        <lumi-button [title]="'chart-edit' | translate" [icon]="'edit'" (onClick)="openChartEditor()" [size]="'small'" [rank]="'tertiary'"></lumi-button>
                    </div>
                </div>
                
                <div #graphContainer [ngStyle]="{'min-height': DEFAULT_HEIGHT + 'px'}" *ngIf="chartData && chartData.cols && chartData.cols.length > 1">
                    <google-chart [hidden]="!chartData || !chartData.rows || chartData.rows.length <= 0"
                                  (globalResizeOutsideAngular)="onResize($event)" #googleChart="GoogleChartDirective"
                                  [chartId]="config.name" [chartOptions]="chartOptions"
                                  [chartType]="chartType" ></google-chart>
                    <div class="form-graph-no-data"
                         [hidden]="(chartData && chartData.rows && chartData.rows.length > 0)">
                        <div class="chart-widget-no-data">{{isLoading ? '' : 'Geen informatie beschikbaar' | translate}}</div>
                    </div>
                </div>
                <div id="chart_div"></div> <!-- Test chart for on click line hiding -->
            </div>
        </ng-container>
    `,
})
export class FormGraphComponent extends AbstractFormWidget implements AfterViewInit, OnDestroy {
    @HostBinding('class') hostClasses = 'd-flex w-100 flex-column';

    //private static readonly GRIDLINES_COUNT: number = 4; //TODO: hier moeten we wat mee als er datepickers komen. Wil je voor elke dag een gridline tot een bepaalde max?
    //private readonly WIDGET_RESIZE_REDRAW_TIME: number = 250; //Milliseconds
    readonly DEFAULT_HEIGHT: number = 300;
    private readonly DEFAULT_LABEL_COLOR: string = AppSettings.getColor('$color_grey-default-label');

    @ViewChild('googleChart', {static: false}) private chartElement: any;
    @ViewChild('graphContainer', {static: false}) private graphContainer: any;

    chartData: any = null;
    chartType: string = GoogleChartComponent.TYPE_AREACHART;
    chartOptions: ChartOptionsInterface = {
        height: this.DEFAULT_HEIGHT,
        backgroundColor: {fill: 'transparent'},
        fontName: 'Roboto',
        hAxis: {
            textStyle: {
                color: this.DEFAULT_LABEL_COLOR,
            },
            titleTextStyle: {
                color: this.DEFAULT_LABEL_COLOR,
            },
        },
        vAxis: {
            textStyle: {
                color: this.DEFAULT_LABEL_COLOR,
            },
            titleTextStyle: {
                color: this.DEFAULT_LABEL_COLOR,
            },
        },
        legend: {
            textStyle: {
                color: this.DEFAULT_LABEL_COLOR,
            },
            position: 'bottom',
            alignment: 'start',
        },
        animation: {
            duration: 0,
            easing: 'linear',
            startup: false,
        },
    };

    private subOnFormResize: Subscription;
    private subOnGlobalEvent: Subscription;
    public canEdit:boolean = true;

    constructor(public renderer: Renderer2, public auth: AuthorizationService, public validationConstraintService: ValidationConstraintService, public ts: TranslateService, public tooltipService: TooltipService, public globalAlertService: GlobalAlertService, public formDataService: FormDataService, public cd: ChangeDetectorRef, public model: GlobalModel, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, model, formDataService, globalAlertService, ts, cd, logger);

        this.subOnGlobalEvent = this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            if (event.type == GlobalEvent.EVENT_EXPAND_FORM_GROUP) {
                if (event.data.expanded == true) {
                    //The graph might have been resized while being collapsed. Redraw the graph to fit the form.
                    setTimeout(() => {
                        this.redrawChart();
                    });
                }
            }
        });
    }

    ngAfterViewInit() {
        this.handleAfterViewInit();

        // When a form is resized, redraw the chart
        this.subOnFormResize = this.model.onFormResize.subscribe((unused: string) => {
            this.redrawChart();
        });
    }

    ngOnInit() {
        this.setChartOptions();
        if (this.config.attr && this.config.attr.graphs && this.config.attr.graphs[0]) {
            if (!this.config.attr.graphs[this.model.lastSelectedGraphTab]) {
                this.model.lastSelectedGraphTab = 0;
            }
            this.initWidget(this.config.attr.graphs[this.model.lastSelectedGraphTab].url);
        }
        else {
            if (this.config.attr && this.config.attr.url) {
                this.initWidget('' + this.config.attr.url);
            }
            else {
                if (this.auth.allowShowDebug()) {
                    this.globalAlertService.addAlertDebugMessage('Geen URL', 'Geen URL gevonden', 'De grafiek verwacht een URL om data mee op te halen, maar die is niet meegegeven');
                }
            }
        }
    }

    public openChartEditor(){
        this.chartElement.openChartEditor()
    }

    // Check if a day is selected
    isSelectedGraph(graph: any): boolean {
        let selectedGraph = this.config.attr.graphs.indexOf(graph);
        return selectedGraph == this.model.lastSelectedGraphTab;
    }

    handleClickTab(event: any, graph: any) {
        this.logger.log('[FormGraphComponent] loading graph: ', graph);
        if (graph && graph.url) {
            this.initWidget(graph.url);
        }

        this.model.lastSelectedGraphTab = this.config.attr.graphs.indexOf(graph);

        //Update the view
        this.cd.detectChanges();
    }

    protected handleWidgetDataReceived(json: any): void {
        this.chartData = json.datatable;

        if (json.graphType && json.graphType != '') {
            this.chartType = json.graphType;
        }

        //Set the chartoptions to default values
        this.setChartOptions();

        //When the backend sends chart options, apply them over the default values. But only override if a value is given for a key
        this.applyChartSettings(json.chartSettings);

        this.cd.markForCheck();

        //Wait until view is up to date
        //TODO: dit kan vast mooier. Eerst moet chartdata in de view verwerkt zijn, en daarna wil percentage-height/width niet werken op google chart, dus is een nieuwe redraw nodig als de eerste chart staat
        this.logger.log('[FormGraphComponent] ' + 'redraw after graphdata');
        this.redrawChart();
        setTimeout(() => {
            this.redrawChart();
        }, 0);
    }

    //Replaces the keys of the chart options for every key in the giving settings object. You can set a specific child key without replacing the entire key.
    //To override a object property wit zero: vAxis:{}  dont use vAxis: null;
    private applyChartSettings(chartSettings: ChartOptionsInterface) {
        if (chartSettings) {
            for (let key in chartSettings) {

                // Check for child properties
                if (chartSettings[key] && Object.keys(chartSettings[key]).length > 0) {

                    //Loop over properties and apply only existing properties
                    for (let subKey in chartSettings[key]) {

                        // If these settings do not yet exist in the local options, create a new empty object
                        if (this.chartOptions[key] == undefined) {
                            this.chartOptions[key] = {};
                        }

                        this.chartOptions[key][subKey] = chartSettings[key][subKey];
                    }
                }
                else {
                    //No specific properties, apply value directly
                    this.chartOptions[key] = chartSettings[key];
                }
            }
        }
    }

    private setChartOptions(): void {
        //Apply google chart defaults
        GoogleChartComponent.setDefaultChartOptions(this.chartType, this.chartOptions, this.globalAlertService);

        //Set component specific chartOptions
        if (this.chartType == GoogleChartComponent.TYPE_AREACHART || this.chartType == GoogleChartComponent.TYPE_SCATTER) {
            //Override the defaults
            //Set component specific chartOptions
            this.chartOptions['chartArea'] = {left: '10%', top: '10%', width: '85%', height: '70%'};
            this.chartOptions['hAxis'] = {
                format: 'd-M',
                textStyle: {color: this.DEFAULT_LABEL_COLOR},
                titleTextStyle: {color: this.DEFAULT_LABEL_COLOR},
                //gridlines: {color: this.DEFAULT_LABEL_COLOR, count: FormGraphComponent.GRIDLINES_COUNT} //TODO: pas aan voor daterange straks. kijk wat handig is/mooi staat
                gridlines: {color: this.DEFAULT_LABEL_COLOR},
            };
            this.chartOptions['vAxis'] = {
                //viewWindow: {min: 0, max: 100},
                viewWindowMode: 'explicit',
                textStyle: {color: this.DEFAULT_LABEL_COLOR},
                titleTextStyle: {color: this.DEFAULT_LABEL_COLOR},
            };
        }
    }

    private redrawChart(): void {
        //Only draw if the element is present
        if (this.chartElement) {
            this.chartElement.drawGraph(this.chartData, this.graphContainer.nativeElement.offsetWidth);
        }
    }

    onResize(event: MouseEvent): void {
        this.logger.log('[FormGraphComponent] ' + 'Redraw graph');
        this.redrawChart();
    }

    ngOnDestroy() {

        super.ngOnDestroy();

        // nullify the chart element explicitly
        this.chartElement = null;

        if (this.subOnFormResize) {
            this.subOnFormResize.unsubscribe();
        }
    }
}

export interface ColoredStyleInterface {
    color: string
}

export interface ChartOptionsInterface {
    height: number
    backgroundColor: {fill: string}
    fontName: string
    hAxis: {
        textStyle: ColoredStyleInterface
        titleTextStyle: ColoredStyleInterface
        format?: string
        gridlines?: ColoredStyleInterface
    },
    vAxis: {
        textStyle: ColoredStyleInterface
        titleTextStyle: ColoredStyleInterface
        viewWindowMode?: string
    },
    legend: {
        textStyle: ColoredStyleInterface
        position: string
        alignment: string
    },
    animation: {
        duration: number
        easing: string
        startup: boolean
    },
}
