<div class="tree-shortcuts-container">
    <div class="btn-group">
        <i class="material-icons" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">tune</i>
        <div class="active-shortcut-notify" [ngClass]="{'has-active' : hasActiveKeyPress()}"></div>
        <div class="dropdown-menu dropdown-menu-right">
            <div class="dropdown-item d-flex align-items-center" *ngFor="let shortcut of shortcutList" (click)="forceKeyPress(shortcut.keycode, $event)">
                <div class="key" [ngClass]="{'active' : shortcut.isActive}">{{shortcut.keycode}}</div>
                <div>{{shortcut.title}}</div>
            </div>
        </div>
    </div>
</div>
