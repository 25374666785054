<tree-map-form #treeMapFormComponent
               (onComponentEvent)="handleComponentEvent($event)">
    <div *ngIf="!breakTopBar()" mapTableButtons class="d-flex align-items-center">
        <lumi-button (onClick)="handleClickMechanicFilter(MECHANIC_FILTER.CURRENT)"
                     [active]="activeMechanicFilter === MECHANIC_FILTER.CURRENT"
                     [icon]="'filter_alt'"
                     [label]="ts.translate('mechanic.buttonfilter.active')">
        </lumi-button>
        <lumi-button (onClick)="handleClickMechanicFilter(MECHANIC_FILTER.ALL)"
                     [active]="activeMechanicFilter === MECHANIC_FILTER.ALL"
                     [icon]="'filter_alt'"
                     [label]="ts.translate('mechanic.buttonfilter.all')">
        </lumi-button>
        <lumi-select *ngIf="mechanicSelectOptions?.length > 0"
                     style="width: 10rem; min-width: 6rem;"
                     [style.min-width]="'6rem'"
                     [options]="mechanicSelectOptions"
                     [selectedOptions]="getSelectedMechanicOptions()"
                     [showOptionFilter]="mechanicSelectOptions?.length > 10"
                     (onOptionsSelect)="setMechanic($event)">
        </lumi-select>
    </div>
    <div *ngIf="breakTopBar()" mapTableButtonsBrokenTopBar class="d-flex align-items-center mb-2">
        <lumi-button (onClick)="handleClickMechanicFilter(MECHANIC_FILTER.CURRENT)"
                     [active]="activeMechanicFilter === MECHANIC_FILTER.CURRENT"
                     [icon]="'filter_alt'"
                     [label]="ts.translate('mechanic.buttonfilter.today')">
        </lumi-button>
        <lumi-button (onClick)="handleClickMechanicFilter(MECHANIC_FILTER.ALL)"
                     [active]="activeMechanicFilter === MECHANIC_FILTER.ALL"
                     [icon]="'filter_alt'"
                     [label]="ts.translate('mechanic.buttonfilter.all')">
        </lumi-button>
        <lumi-select *ngIf="mechanicSelectOptions?.length > 0"
                     style="width: 10rem; min-width: 6rem;"
                     [options]="mechanicSelectOptions"
                     [selectedOptions]="getSelectedMechanicOptions()"
                     [showOptionFilter]="mechanicSelectOptions?.length > 10"
                     (onOptionsSelect)="setMechanic($event)">
        </lumi-select>
    </div>
</tree-map-form>
