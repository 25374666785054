import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import * as moment from 'moment/moment';
import Utils from '../../utils/utils';
import {MapLayerCoordinate} from './map-layer-coordinate';
import {BaseObjectInterface, MapItem, MapItemInterface} from './map-item/map-item';
import {GlobalModel} from '../../services/state/global.model';
import MapIconGenerator, {MapIconInterface} from '../../utils/map-icon-generator';
import {Subscription, timer} from 'rxjs';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {StreetviewCoreComponent} from './streetview-core.component';
import MapWMSLayerGenerator from '../../utils/map-wmslayer-generator';
import {ChangeableComponent} from '../changeable/changeable.component';
import {StorageService} from '../../services/storage/storage.service';
import {LatLng} from './lat-lng';
import {TranslateService} from '../../services/translate/translate.service';
import {AuthorizationService} from '../../services/authorization/authorization.service';
import {GlobalEvent} from '../../interfaces/global-event';
import {PolygonSelect} from './polygon-select';
import MarkerClusterer, {ClusterIconInfo} from '@googlemaps/markerclustererplus';
import {TableCell, TableColumn, TmpMapDataInterface} from '../map-table/map-table.interface';
import {PrintService} from '../../services/print/print.service';
import {Areaal, CyclomediaInterface, WmsDataInterface} from '../../interfaces/areaal';
import {HttpService2} from '../../services/http-service-2.0/http2.0.service';
import {ButtonCode} from '../../../wrapper/global-alert/global-popup';
import {AppSettings} from 'src/app/app.settings';
import {animate, style, transition, trigger} from '@angular/animations';
import {GlobalStateService} from "../../services/state/global-state.service";
import {
    BoundsChangedDuringAutoLoadEvent,
    ClickMarkerEvent,
    DragMarkerEvent,
    InfoWindowDataInterface,
    LongPressEvent,
    LuminizerLayerMarkerDataRef,
    LuminizerMapMarker,
    LuminizerMapMarkerV2Conversion,
    MouseOverMarkerEvent,
    NumMarkersChangedEvent,
    SelectMapTypeOptions,
    SelectMarkersEvent
} from "./map-core.interface";
import {MapCoreV2Component} from "./map-core-V2.component";
import {MapCoreV2Service} from "./map-core-V2.service";
import {Router} from "@angular/router";
import {MapItem as MapItemV2} from './managers/map-item'
import MapTypeStyle = google.maps.MapTypeStyle;
import TravelMode = google.maps.TravelMode;
import MVCObject = google.maps.MVCObject;
import Timer = NodeJS.Timer;
import MarkerOptions = google.maps.MarkerOptions;
import Polyline = google.maps.Polyline;
import InfoWindow = google.maps.InfoWindow;
import LatLngLiteral = google.maps.LatLngLiteral;
import {LoggerService} from "../../services/logger/logger.service";


@Component({
    selector: 'map-core-component',
    templateUrl: './map-core.component.html',
    animations: [
        trigger(
            'shortFade',
            [
                transition(
                    ':enter',
                    [
                        style({ opacity: 0 }),
                        animate('0.2s',
                            style({ opacity: 1 }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ opacity: 1 }),
                        animate('0.2s',
                            style({ opacity: 0 }))
                    ]
                ),
            ]
        )
    ]
})

export class MapCoreComponent extends ChangeableComponent implements OnInit, AfterViewInit, OnDestroy {
    public mapCoreV2Component: MapCoreV2Component;
    @Input('allowLocation') public allowLocation: boolean = false;
    @Input('allowSearch') public allowSearch: boolean = true;
    @Input('allowAutoLoadMarkers') public allowAutoLoadMarkers: boolean = false;
    @Input('allowSingleSelect') public allowSingleSelect: boolean = true;
    @Input('allowMultiSelect') public allowMultiSelect: boolean = false;
    @Input('allowMarkerPopup') public allowMarkerPopup: boolean = true;
    @Input('showNoItemsWarning') public showNoItemsWarning: boolean = true;
    @Input('allowMarkerDrag') public allowMarkerDrag: boolean = false;
    @Input('allowEasyMarkerDrag') public allowEasyMarkerDrag: boolean = false;
    @Input('allowCreateMarker') public allowCreateMarker: boolean = false;
    @Input('useCustomIconColors') public useCustomIconColors: boolean = false;
    @Input('iconSet') public iconSet: 'default'|'mechanic'|'smartdevices' = 'default';
    @Input('isBasicMode') public isBasicMode: boolean = false;
    @Input('showLoading') public showLoading: boolean = true;
    @Input('isMapInPopup') public isMapInPopup: boolean = false;
    @Input() public disableClusterer: boolean = false;
    public allowClusterer: boolean = false;
    @Input() private showSearchBar: boolean = false;
    @Input() private optionsBasicMode: boolean = false;
    @Input() private expandSearchOnInit: boolean = true;
    @Input() public heatmapValue: string = '';

    @Input() set isLoading(isLoading: boolean) {
        //Delay the loading screen, so that small loads don't even show a screen anymore

        if (isLoading) {
            //Create new timer
            this.loadDelayTimer = setTimeout(() => {
                this.loadDelayTimer = null;
                this._isLoading = true;
                this.cd.detectChanges();
            }, MapCoreComponent.LOAD_DELAY);
        }
        else {
            this._isLoading = false;

            //Reset pending timer
            if (this.loadDelayTimer) {
                clearTimeout(this.loadDelayTimer);
                this.loadDelayTimer = null;
            }
        }
    }

    @Output() onClickMarker: EventEmitter<ClickMarkerEvent> = new EventEmitter();
    @Output() onSelectMarkers: EventEmitter<SelectMarkersEvent> = new EventEmitter();
    @Output() onMouseOverMarker: EventEmitter<MouseOverMarkerEvent> = new EventEmitter();
    @Output() onMarkerPopupClicked: EventEmitter<MapItem> = new EventEmitter();
    @Output() onNumMarkersChanged: EventEmitter<NumMarkersChangedEvent> = new EventEmitter();
    @Output() onDragMarker: EventEmitter<DragMarkerEvent> = new EventEmitter();
    @Output() onLongPress: EventEmitter<LongPressEvent> = new EventEmitter();
    @Output() onBoundsChangedDuringAutoLoad: EventEmitter<BoundsChangedDuringAutoLoadEvent> = new EventEmitter();
    @Output() onInfoWindowDataRequest: EventEmitter<InfoWindowDataInterface> = new EventEmitter();
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('mapContainer', {static: false}) mapContainer: ElementRef;
    @ViewChild('mapSearchInput', {static: false}) searchInput: ElementRef;
    @ViewChild('streetviewComponent', {static: false}) streetviewComponent: StreetviewCoreComponent;
    @ViewChild('rightClickMenu', {static: false}) rightClickMenu: ElementRef;

    private subscriptions: Subscription[] = [];

    private subHandleBoundsChanged: Subscription = new Subscription();

    public AppSettings = AppSettings;
    // right click
    private static readonly MAP_EVENT_CONTEXT_MENU: string = 'contextmenu';
    private static readonly MAP_EVENT_CLICK: string = 'click';
    private static readonly MAP_EVENT_BOUNDS_CHANGED: string = 'bounds_changed';
    private static readonly MAP_EVENT_CENTER_CHANGED: string = 'center_changed';
    private static readonly MAP_EVENT_MOUSE_DOWN: string = 'mousedown';
    // private static readonly MAP_EVENT_MOUSE_UP: string = 'mouseup';
    private static readonly MAP_EVENT_MOUSE_MOVE: string = 'mousemove';
    // private static readonly MAP_EVENT_MOUSE_OUT: string = 'mouseout';
    private static readonly MAP_EVENT_DRAG: string = 'drag';
    private static readonly SEARCH_EVENT_PLACES_CHANGED: string = 'places_changed';
    // private static readonly MAP_EVENT_CLICK: string = 'click';

    private static readonly MAP_BACKGROUND_COLOR: string = '$color_super_light_grey';
    //private static readonly DEFAULT_ZOOM:number = 10;
    private static readonly MOUSE_HOLDDOWN_TIME: number = 1000;
    private static readonly MOUSE_OVER_POPUP_TIME: number = 250;
    private static readonly MOUSE_OUT_POPUP_TIME: number = 200; // Miliseconds to move you mouse over the popup before it closes again
    private static readonly FOCUS_MAX_ZOOM: number = 19; //The maximum zoom level for the focus button. So, has nothing to do with the map itself
    private static readonly DEFAULT_SHOW_LABEL_ZOOM: number = 18; //TODO: misschien nodig om dit dynamisch in te kunnen stellen per kaart
    public static readonly DISABLE_AUTO_PAN_INFOWINDOW: boolean = true; //Dit speelt vooral heel duidelijk bij de view waar je map en table gelijkertijd ziet
    private static readonly AUTO_LOAD_DELAY: number = 750;
    private static readonly AUTO_LOAD_FLOOD_DELAY: number = 5000; //Allow a call every x seconds
    private static readonly LOAD_DELAY: number = 2500;
    private static readonly DEFAULT_LOCATION_WATCH_ZOOMLEVEL: number = 16; //A bit on the high side, because the screen is much smaller on mobile and you'll have to see the road
    private static readonly PUBLIC_PORTAL_THRESHOLD_ZOOMLEVEL: number = 15; // Autoload for public portal is enabled beyond this zoom level

    public static readonly MAP_TYPE_OSM: string = 'OSM';
    public static readonly MAP_TYPE_ROADMAP: string = 'roadmap';
    public static readonly MAP_TYPE_MOON: string = 'moon';

    public allowMarkerDragByMouseDown: boolean = false;
    public preventAutoCloseInfoWindow: boolean = false;
    _isLoading: boolean = false;
    private enableAltKey = true; //Turn off alt key for now
    private showLabelZoom = MapCoreComponent.DEFAULT_SHOW_LABEL_ZOOM;
    private showRouteButton: boolean = true;

    // Calculate distance between 2 markers
    measurementMarkers: LuminizerMapMarker[] = []; //Reference to all markers on the map
    public measurementMarkerLatLng: LatLngLiteral;
    public measurementLine: Polyline;
    public measurementInfoWindow: InfoWindow;
    public readableLatLng: LatLng;
    public clipboardLatLng: LatLng;
    public showMapInfo: boolean = false;
    public showMapInfoText: string = '';
    public measureDistanceMode: boolean = false;

    public map: google.maps.Map;

    //Heatmap layer
    private heatmap: any;
    private heatmapMarkers = [];
    public heatmapOptionCode: string = "";

    markers: LuminizerMapMarker[] = []; //Reference to all markers on the map
    private markersWithLabel: LuminizerMapMarker[] = []; //Fast access to markers with an icon + label
    private wmsLayerGenerator: MapWMSLayerGenerator = new MapWMSLayerGenerator(this.ngZone, false, true, this.httpService, this.storage, this.logger);
    public wmsLayerVisible: boolean = true;
    public POIVisible: boolean = false;
    public allowMapPopup: boolean = true;
    public allowMarkerLabels: boolean = true;
    public originalAllowMarkerLabels: boolean = this.allowMarkerLabels;
    private clusterer: MarkerClusterer;
    public streetViewMap: string = 'google';
    public cyclomediaSettings: CyclomediaInterface;

    //TODO: set deze voor verschillende kaarten via component implementatie
    private autoZoomToFitMarkers: boolean = true;

    mapIconGenerator: MapIconGenerator;

    //For change bounds timer buffer
    private static readonly HANDLE_BOUNDS_CHANGED_DELAY: number = 500; //Perform changeboundsactions only after n milliseconds of inactivity
    private handleBoundsChangedTimer = timer(MapCoreComponent.HANDLE_BOUNDS_CHANGED_DELAY, 0);

    private draggedMarker: LuminizerMapMarker = null;
    private draggedMarkerMoved: boolean = false;
    public polygonSelect: PolygonSelect;
    private mouseDownMarker: LuminizerMapMarker = null;
    private mouseDownMarkerTimeout: any = null;
    private mouseDownMapTimeout: any  = null;
    private mouseOverMarkerTimeout: any = null;
    private mouseOutMarkerTimeout: any = null;

    public autoLoadMarkers: boolean = false;
    private preventAutoLoadMarkers: boolean = false;
    private autoLoadDelayTimer: any;
    private autoLoadFloodTimer: any;
    private autoLoadFlooded: boolean = false;
    private loadDelayTimer: any;
    lockOnLocation: boolean = false;
    private centerUpdatedByLocationWatch: boolean = false;
    private currentLocationMarker: LuminizerMapMarker = null;
    private currentLocationWatcher: number = -1;
    private numVisibleMarkers: number = 0;

    private currentCenter: google.maps.LatLng;
    private infoWindow: google.maps.InfoWindow;
    private lastInfoWindowMarker: LuminizerMapMarker = null;
    private cmdKeyPressed: boolean = false;
    private shiftKeyPressed: boolean = false;
    private altKeyPressed: boolean = false;
    private selectedMarkers: LuminizerMapMarker[] = [];
    private selectedBaseObjects: { baseObjectId?: number | string }[] = [];
    private queueZoomToFitMarkers: boolean = false;
    showStreetView: boolean = false;
    private stopMarkerClickEventPropagation: boolean = false; //Stupid way of stopping propagation. Default way doesn't work with google maps events
    //private isFocusButtonZooming:boolean = false;

    public mobileMode: boolean = null;
    private searchBox: google.maps.places.SearchBox;
    searchExpanded: boolean = false;
    private autoLoadDisabledDuringSearch: boolean = false; //When the autoload function is disabled during search, don't activate it again when picking a new search result
    private directionsService: google.maps.DirectionsService;
    private directionsDisplay: google.maps.DirectionsRenderer;
    private routeMarker: LuminizerMapMarker;

    isMobileUserAgent:boolean = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    private features: { name: string, features: google.maps.Data.Feature[] }[] = [];

    constructor(public model: GlobalModel, public globalAlertService: GlobalAlertService, public cd: ChangeDetectorRef, public ngZone: NgZone, protected elementRef: ElementRef, private storage: StorageService, public ts: TranslateService, public auth: AuthorizationService, private printService:PrintService, private httpService:HttpService2, private renderer: Renderer2, protected globalStateService: GlobalStateService, private mapCoreV2Service:MapCoreV2Service, private router: Router, protected logger:LoggerService) {
        super(elementRef);

        this.infoWindow = new google.maps.InfoWindow({disableAutoPan: MapCoreComponent.DISABLE_AUTO_PAN_INFOWINDOW});
        this.mapIconGenerator = new MapIconGenerator(ts, (iconName: string) => {
            this.handleMarkerIconLoaded(iconName);
        }, logger, this.model, this.globalStateService);
        this.polygonSelect = new PolygonSelect();

        this.mapCoreV2Component = new MapCoreV2Component(this, mapCoreV2Service, model, ts, auth, globalAlertService, logger);
    }

    //When an icon with label needs to be composed, but the icon isn't loaded yet, the icon with label needs to be composed again after the image succesfully loaded
    private handleMarkerIconLoaded(iconName: string): void {
        this.logger.log('[MapCoreComponent] ' + 'handle marker icon loaded: ', iconName);

        this.rebuildInvalidMarkers();
    }

    handleWindowFocus(): void {
        this.logger.log('[MapCoreComponent] ' + 'Window focused, release cmd key');
        this.cmdKeyPressed = false;
    }



    ngAfterViewInit(): void {
        if (this.showSearchBar == true) {
            this.handleClickSearch();
        }

        // Enable autoloading directly, when in public portal mode
        if (this.isBasicMode) {
            this.enableAutoLoadMarkers(false);
        }

        this.map = this.createMap();

        //Only initialize V2 when map is not displayed in popup
        if(!this.isMapInPopup){
            this.mapCoreV2Component.initializeMapCoreV2Component(this.map);
        }


        //The map isn't available until next 'tick'
        //setTimeout(()=>
        //{
        //Fix for incorrect display of the map (half of the component is empty)
        google.maps.event.trigger(this.map, 'resize');
        //Create layers and center areaal
        this.createMapLayers(this.map);

        //Init the searchbox
        this.initSearch();
        this.initDirections();

        this.subscriptions.push(this.model.currentAreaal.subscribe((value) => {
            this.logger.log('[MapCoreComponent] current areaal subscribe', value);
            //Add tiled WMS-layers
            if (this.wmsLayerVisible && value?.wmsData?.length > 0) {
                this.tryWMSSettings();
                this.addTiledWMSOverlay();
            }
        }));

        this.setPOIVisible(this.POIVisible);

        //When modeldata is reloaded, markers can be present at the creation of this component.
        if (this.markers.length <= 0) {
            this.centerMapOnAreaal();
        }
        else {
            //The replacemarkers function handles zooming but that might be too early in some cases, so re-zoom here
            if (this.autoZoomToFitMarkers) {
                this.zoomToFitAllMarkers();
            }
        }

        //Set stored map type
        this.trySetStoredMapType();

        //}, 0);

        //Meer mapevents nodig? Check hier: https://developers.google.com/maps/documentation/javascript/events
        this.map.addListener(MapCoreComponent.MAP_EVENT_BOUNDS_CHANGED, () => {
            this.handleMapBoundsChanged();
        });
        this.map.addListener(MapCoreComponent.MAP_EVENT_CENTER_CHANGED, () => {
            this.handleMapCenterChanged();
        });
        this.map.addListener(MapCoreComponent.MAP_EVENT_MOUSE_DOWN, (e) => {
            this.handleMapMouseDown(e);
        });
        this.map.addListener(MapCoreComponent.MAP_EVENT_MOUSE_MOVE, (e) => {
            this.handleMapMouseMove(e);
        });
        this.map.addListener(MapCoreComponent.MAP_EVENT_DRAG, (e) => {
            this.handleMapDrag(e);
        });
        this.map.addListener(MapCoreComponent.MAP_EVENT_CONTEXT_MENU, (e) => {
            this.handleMapRightClick(e);
        })
        this.map.addListener(MapCoreComponent.MAP_EVENT_CLICK, (e) => {
            this.handleMapClick(e);
        })

        this.storage.getObjectValue(StorageService.KEY_USER_MAP_LAYERS, (value: {geoJson: string, fileName: string}[]) => {
            if (value) {
                for (let i = 0; i < value.length; i++) {
                    this.handleLoadAdditionalLayer(value[i].geoJson, value[i].fileName);
                }
            }
        });
    }

    ngOnInit(): void {
        if (this.model.user.value != null && this.model.user.value.settings && this.model.user.value.settings.labelZoomLevel) {
            this.showLabelZoom = this.model.user.value.settings.labelZoomLevel;
        }
        else {
            this.showLabelZoom = MapCoreComponent.DEFAULT_SHOW_LABEL_ZOOM;
        }

        if (this.isBasicMode) {
            this.showRouteButton = false;
        }

        this.subscriptions.push(this.model.currentAreaal.subscribe((value:Areaal) => {
            // Check if cyclomedia is here, otherwise set streetview to google
            if(value.cyclomedia){
                this.cyclomediaSettings = {
                    username:value.cyclomedia['username'],
                    password:value.cyclomedia['password'],
                    apiKey:value.cyclomedia['apiKey'],
                    enabled:value.cyclomedia['enabled']
                };
                // Check if cyclomedia is enabled, otherwise set streetview to google
                if(value.cyclomedia['enabled']){
                    this.streetViewMap = 'cyclomedia';
                } else {
                    this.streetViewMap = 'google';
                }
            } else {
                this.cyclomediaSettings = null;
                this.streetViewMap = 'google';
            }
        }));

        this.subscriptions.push(this.model.mobileMode.subscribe((value: boolean) => {

            //Don't forget to unsubscribe
            this.mobileMode = value;
            this.allowMarkerDragByMouseDown = this.mobileMode;
        }));

        this.subscriptions.push(this.model.loadUserMapLayer.subscribe((value) => {
            if (value && value.geoJson) {
                this.handleLoadAdditionalLayer(value.geoJson, value.fileName);
            }
        }));

        this.subscriptions.push(this.model.deleteUserMapLayer.subscribe((value) => {
            if (value) {
                this.deleteAdditionalLayer(value);
            }
        }));

        this.subscriptions.push(this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            switch (event.type) {
                case GlobalEvent.EVENT_MAP_SELECT_MAP_TYPE:
                    if (event.data && event.data.selectOption) {
                        this.handleSelectMapType(event.data.selectOption);
                    }
                    break;

                case GlobalEvent.EVENT_MAP_TOGGLE_POI:
                    this.handleTogglePOI();
                    break;

                case GlobalEvent.EVENT_MAP_HEATMAP_OPTION_CODE:
                    if (event.data) {
                        this.handleToggleHeatmap(event.data.selectOption);
                    }
                    break;

                case GlobalEvent.EVENT_MAP_TOGGLE_MAP_POPUP:
                    this.handleToggleMapPopup();
                    break;

                case GlobalEvent.EVENT_MAP_TOGGLE_WMS_LAYER:
                    this.handleToggleWMSLayer();
                    break;

                case GlobalEvent.EVENT_MAP_TOGGLE_SINGLE_WMS_LAYER:
                    this.handleToggleSingleWMSLayer(event.data.wmsLayer);
                    break;

                case GlobalEvent.EVENT_MAP_TOGGLE_MARKER_LABELS:
                    this.handleToggleMarkerLabels();
                    break;

                case GlobalEvent.EVENT_MAP_TOGGLE_MAP_CLUSTERS:
                    this.handleToggleMapClusters();
                    break;
            }
        }));

        this.logger.log('[MapCoreComponent] ' + 'Applied label zoom level: ' + this.showLabelZoom);

        //this.ngZone.runOutsideAngular(() => {

        this.storage.getBooleanValue(StorageService.KEY_MAP_WMS_ENABLED, (value: boolean) => {
            this.wmsLayerVisible = value;
        });

        // Always disable WMS layer for public portal mode
        if (this.isBasicMode) {
            this.wmsLayerVisible = false;
        }

        this.storage.getBooleanValue(StorageService.KEY_MAP_POI_ENABLED, (value: boolean) => {
            this.POIVisible = value;
        });

        this.storage.getBooleanValue(StorageService.KEY_MAP_POPUP_ENABLED, (value: boolean) => {
            this.allowMapPopup = value;
        });

        this.storage.getBooleanValue(StorageService.KEY_MAP_MARKER_LABELS_ENABLED, (value: boolean) => {
            this.allowMarkerLabels = value;
            this.originalAllowMarkerLabels = value;
        });

        this.storage.getBooleanValue(StorageService.KEY_MAP_CLUSTERER, (value) => {
            this.allowClusterer = value;
        });
    }

    //Try to set the maptype to the stored value. If no value is stored, do nothing
    private trySetStoredMapType(): void {
        if (this.auth.enableOSMAsDefault()) {
            // You can set a default map here. It will be overwritten by the cookie data (if present)
            this.map.setMapTypeId(MapCoreComponent.MAP_TYPE_OSM);
        }

        //Set stored map type
        this.storage.getStringValue(StorageService.KEY_MAP_MAP_TYPE, (value: string) => {
            this.map.setMapTypeId(value);
        });
    }

    handleClickSearch(): void {
        this.searchExpanded = !this.searchExpanded;
        this.updateSearchField();
    }

    private updateSearchField(): void {
        //Reset automatic-autoload
        if (this.searchExpanded) {
            this.autoLoadDisabledDuringSearch = false;
            if (this.searchInput) {
                this.searchInput.nativeElement.focus();
            }
        }
    }

    private handleMapMouseDown(e: google.maps.MapMouseEvent): void {
        if (this.allowMultiSelect && this.shiftKeyPressed) {

            this.logger.log('[MapCoreComponent] ' + 'mousedown with shift');
            this.polygonSelect.startDragSelect(this.map, e.latLng);
        }
        else if (this.allowCreateMarker) {

            //Clear previous timers. (on mobile this event is somehow fired twice, which will create 2 timeouts)
            if (this.mouseDownMapTimeout) {
                this.cancelMouseDownMap();
            }

            this.mouseDownMapTimeout = setTimeout(() => {
                this.mouseDownMapTimeout = null;
                this.ngZone.run(() => {
                    this.logger.log('[MapCoreComponent] ' + 'Trigger create marker event ', e);
                    this.onLongPress.emit({position: e.latLng});
                });
            }, MapCoreComponent.MOUSE_HOLDDOWN_TIME);
        }
    }

    //Get baseobjectid on the first baseobject, or -1 if all hidden
    private static getFirstVisibleBaseObjectId(marker: LuminizerMapMarker): number {
        if (marker.dataRef && marker.dataRef.baseObjects) {
            for (let i = 0; i < marker.dataRef.baseObjects.length; i++) {
                if (!marker.dataRef.baseObjects[i].hidden) {
                    return parseInt(marker.dataRef.baseObjects[i].id);
                }
            }
        }

        return -1;
    }

    public handleMapMouseMove(e: google.maps.MapMouseEvent): void {
        if (this.draggedMarker) {
            this.draggedMarker.setPosition(e.latLng);
            this.draggedMarkerMoved = true;

        }
        else if (this.allowMultiSelect && this.polygonSelect.dragIsActive()) {
            this.polygonSelect.moveDragSelect(e.latLng);
        }
    }

    private handleMapDrag(e: google.maps.MapMouseEvent): void {
        //When you click and hold a marker and start shaking your mouse, mouse move is not triggered, but map drag is
        if (this.draggedMarker) {
            this.draggedMarker.setPosition(e.latLng);
            this.draggedMarkerMoved = true;
        }

        this.cancelMouseDownMap();
    }

    private cancelMouseDownMap(): void {
        if (this.mouseDownMapTimeout) {
            clearTimeout(this.mouseDownMapTimeout);
            this.mouseDownMapTimeout = null;
        }
    }

    private addTiledWMSOverlay(): void {
        let hasActiveLayer = false;
        this.model.currentAreaal.getValue().wmsData.forEach((wmsData, index) => {
            if (!wmsData.disabled && wmsData.active) {
                //Limit wms layer to 1 on mobile
                if(hasActiveLayer && this.isMobileUserAgent){
                    wmsData.active = false;
                }
                this.wmsLayerGenerator.addTiledWMSOverlay(this.map, wmsData, index);
                hasActiveLayer = true;
            }
        })
    }

    private addSingleTiledWMSOverlay(server): void {
        if(this.model.currentAreaal.getValue().wmsData !== null) {
            let wmsIndex = this.model.currentAreaal.getValue().wmsData.findIndex(_wmsData => _wmsData.id === server.id);
            this.wmsLayerGenerator.addTiledWMSOverlay(this.map, this.model.currentAreaal.getValue().wmsData[wmsIndex], wmsIndex);
        }
    }

    private removeTiledWMSOverlay(): void {
        this.wmsLayerGenerator.removeTiledWMSOverlay(this.map);
    }

    private removeSingleTiledWMSOverlay(id:number): void {
        this.wmsLayerGenerator.removeSingleTiledWMSOverlay(this.map, id);
    }

    // Show menu for right click
    public showRightClickMenu(event:any): void {
        this.renderer.setStyle(this.rightClickMenu.nativeElement, 'left', event.x+'px')
        this.renderer.setStyle(this.rightClickMenu.nativeElement, 'top', event.y+'px')
        this.renderer.setStyle(this.rightClickMenu.nativeElement, 'display', 'flex')
    }

    public removeMeasureDistance() {
        this.renderer.setStyle(this.rightClickMenu.nativeElement, 'display', 'none')
        this.measurementInfoWindow.close()
        this.measurementMarkers.forEach((marker) => {
            marker.setMap(null);
        })
        this.measurementMarkers = [];
        this.measurementLine.setMap(null);
    }

    private updateMeasurementMarkerPosition(marker): void {
        this.measurementMarkers.forEach((measurementMarker, index) => {
            if (measurementMarker === marker) {
                if(this.measurementMarkers[0] && this.measurementMarkers[1]){
                    const newPositions = [
                        { lat: this.measurementMarkers[0].getPosition().lat(), lng: this.measurementMarkers[0].getPosition().lng() },
                        { lat: this.measurementMarkers[1].getPosition().lat(), lng: this.measurementMarkers[1].getPosition().lng() },
                    ];
                    this.measurementLine.setPath(newPositions)
                    this.openMeasurementInfoWindow(false);
                }
            }
        })
    }

    private openMeasurementInfoWindow(create:boolean): void {
        let distance = google.maps.geometry.spherical.computeDistanceBetween(this.measurementMarkers[0].getPosition(), this.measurementMarkers[1].getPosition())
        const measurement = distance > 1000? 'km' : 'm';
        distance = Math.round(((distance > 1000? distance / 1000 : distance) + Number.EPSILON) * 100) / 100;
        if (create) {
            // Create and open infowindow
            this.measurementInfoWindow = new google.maps.InfoWindow({
                content: `${distance} ${measurement}`,
                position: google.maps.geometry.spherical.interpolate(this.measurementMarkers[0].getPosition(), this.measurementMarkers[1].getPosition(), 0.5)
            });

            this.measurementInfoWindow.open({
                map: this.map
            })
        } else {
            this.measurementInfoWindow.setPosition(google.maps.geometry.spherical.interpolate(this.measurementMarkers[0].getPosition(), this.measurementMarkers[1].getPosition(), 0.5))
            this.measurementInfoWindow.setContent(`${distance} ${measurement}`);
        }
    }

    // Measure distance between markers and show the information
    public measureDistance(): void {
        this.renderer.setStyle(this.rightClickMenu.nativeElement, 'display', 'none')

        // Create no more than 2 markers
        if (this.measurementMarkers.length < 2) {
            this.measureDistanceMode = true;
            this.showMapInfo = true;
            this.showMapInfoText = 'measuredistance.secondclick';

            // Create marker
            const marker: LuminizerMapMarker = new google.maps.Marker(
                <MarkerOptions>{
                    position: this.measurementMarkerLatLng,
                    map: this.map,
                    title: this.measurementMarkers.length === 0?this.ts.translate('measuredistance.firstpoint'):this.ts.translate('measuredistance.secondpoint'),
                    draggable: true,
                    icon: "assets/img/map-marker.svg"
                },
            );
            this.measurementMarkers.push(marker);

            google.maps.event.addListener(marker, 'dragend', () => {
                this.updateMeasurementMarkerPosition(marker)
            });

            // Only start calculating when theres 2 markers
            if (this.measurementMarkers.length > 1) {
                // Get distance and measurements
                this.openMeasurementInfoWindow(true);

                // Create line between 2 measurement markers
                const measurementLinePositions = [
                    { lat: this.measurementMarkers[0].getPosition().lat(), lng: this.measurementMarkers[0].getPosition().lng() },
                    { lat: this.measurementMarkers[1].getPosition().lat(), lng: this.measurementMarkers[1].getPosition().lng() },
                ];
                this.measurementLine = new google.maps.Polyline({
                    path: measurementLinePositions,
                    geodesic: true,
                    strokeColor: "#FF0000",
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                    map: this.map
                });

                this.measureDistanceMode = false;
            }
        }
    }

    // Get current lat long of right click press so u can create markers
    public handleMapRightClick(event:any): void {
        if (!this.measureDistanceMode) {
            this.measurementMarkerLatLng = event.latLng;
            this.makeLatLngReadable(event.latLng);
        }
    }

    // Get current lat long for second normal click
    public handleMapClick(event:any): void {
        if (this.measureDistanceMode) {
            this.measurementMarkerLatLng = event.latLng;
            this.makeLatLngReadable(event.latLng);
            this.measureDistance();
            this.showMapInfo = false;
            this.cd.detectChanges();
        }
    }

    public makeLatLngReadable(latlng) {
        this.clipboardLatLng = {lat: latlng.lat(), lng: latlng.lng()};
        const lat = Number.parseFloat(latlng.lat()).toFixed(5);
        const lng = Number.parseFloat(latlng.lng()).toFixed(5);
        this.readableLatLng = {lat: Number(lat), lng: Number(lng)};
    }

    public copyToClipboard() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = `${this.clipboardLatLng.lat}, ${this.clipboardLatLng.lng}`;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.renderer.setStyle(this.rightClickMenu.nativeElement, 'display', 'none')
        this.showMapInfo = true;
        this.showMapInfoText = 'clipboardcopy.success';
        setTimeout(() => {
            this.showMapInfo = false;
            this.cd.detectChanges();
        }, 3000)
    }

    public handleClickMap(event): void {
        // Close right click menu
        if (!this.rightClickMenu.nativeElement.contains(event.target)) {
            this.renderer.setStyle(this.rightClickMenu.nativeElement, 'display', 'none')
        }

        //Timeout to ensure this event comes after marker-click-event. Fix for mobile mode
        setTimeout(() => {
            this.ngZone.runOutsideAngular(() => {
                if (!this.stopMarkerClickEventPropagation) {
                    this.hideInfoWindow();
                }
                else {
                    //Propagation was canceled, reset
                    this.stopMarkerClickEventPropagation = false;
                }
            });
        });
    }

    onResize(event: Event): void {
        //TODO: het resize event zelf is nu al buiten angular, het zou dus goed kunnen dat deze regel overbodig is geworden:
        this.ngZone.runOutsideAngular(() => {
            //Fix for incorrect display of map center after resizing
            //The stored center is reapplied to keep the map center in center after resizing

            //When there is no real event, this event is manually triggered. Prevent the auto loading feature
            if (!event) {
                this.preventAutoLoadMarkers = true;
            }

            if (this.map) {

                //Make sure location following stays on when the map center is changed by resizing
                this.centerUpdatedByLocationWatch = true;

                google.maps.event.trigger(this.map, 'resize');
                this.map.setCenter(this.currentCenter);

                //Fix for content of infowindow being erased, leaving a messed-up popup
                this.hideInfoWindow();
            }
            else {
                this.logger.log('[MapCoreComponent] ' + 'Trying to resize map, but map isn\'t created yet');
                this.logger.log('[MapCoreComponent] ' + 'TODO: If you read this comment, is the map still scaled correctly?');
            }
        });
    }

    handleShowLegend(): void {
        this.globalAlertService.addPopupMapLegend(this.allowSingleSelect, this.allowMultiSelect, this.allowMarkerDrag, this.allowEasyMarkerDrag, this.allowCreateMarker, this.useCustomIconColors, this.iconSet, () => {
        });
    }

    handleShowOptions(): void {
        this.globalAlertService.addPopupMapOptions(this.map.getMapTypeId(), this.POIVisible, this.heatmapOptionCode, this.allowMapPopup, this.wmsLayerVisible, this.allowMarkerLabels, this.allowClusterer, this.optionsBasicMode, () => {
        });
    }

    //When a hidden map is made visible, e.g when opening a tab
    public showMap(): void {
        //Incorrect resize fix
        this.onResize(null);

        //Incorrect zoom after new items fix
        if (this.queueZoomToFitMarkers) {
            this.queueZoomToFitMarkers = false;
            this.zoomToFitAllMarkers();
        }

        //Same for streetview
        this.streetviewComponent.showMap();
    }

    //When the panning changes
    private handleMapCenterChanged(): void {
        //When the panning isnt done by the gps-center-tracker
        if (this.map && !this.centerUpdatedByLocationWatch) {
            //Disable location tracking
            this.disableLocationTracking();
            this.cd.detectChanges();
        }
        else {

            //The movement came from the gps tracker. Mark the next motion as 'not-by-gps'
            this.centerUpdatedByLocationWatch = false;
        }
    }

    private handleClickSearchResult(): void {
        const places = this.searchBox.getPlaces();

        if (places.length == 0) {
            this.logger.log('[MapCoreComponent] ' + 'No search results');
            return;
        }

        const bounds = new google.maps.LatLngBounds();
        const selectedPlace = places[0];

        if (selectedPlace.geometry.viewport) {
            bounds.union(selectedPlace.geometry.viewport);
        }
        else {
            bounds.extend(selectedPlace.geometry.location);
        }

        //When not just deactivated manually, activate auto-load
        /*if (!this.autoLoadDisabledDuringSearch){

            //Prevent getting markers automatically, the map-bounds will be changed soon, so wait for that to happen
            this.enableAutoLoadMarkers(false);
        }*/

        this.map.fitBounds(bounds);
    }

    private tryAutoLoad(): void {
        //Autoload feature
        if (this.autoLoadMarkers && !this.preventAutoLoadMarkers) {

            //Reset pending timer
            if (this.autoLoadDelayTimer) {
                clearTimeout(this.autoLoadDelayTimer);
                this.autoLoadDelayTimer = null;
            }

            //Create new timer
            this.autoLoadDelayTimer = setTimeout(() => {
                this.autoLoadDelayTimer = null;
                this.loadMarkersInCurrentBounds();

            }, MapCoreComponent.AUTO_LOAD_DELAY);
        }
        else {
            this.preventAutoLoadMarkers = false;
        }
    }

    //Decided to always explicitly remove/create label marks. The method is super fast now so the user won't notice,
    //and this will prevent the image from being out of sync with the (possibly updated) data
    private handleMapBoundsChanged(): void {
        //Update the current location for the searchbox to show local results
        if (this.searchBox) {
            this.searchBox.setBounds(this.map.getBounds());
        }

        setTimeout(() => {
            this.cancelMouseDownMap();
        });

        this.tryAutoLoad();

        this.currentCenter = this.map.getCenter();

        //Draw labels with timer buffer, so you don't redraw the labels too often

        //Stop running timers
        this.subHandleBoundsChanged.unsubscribe();

        //Start a new timer
        this.subHandleBoundsChanged = this.handleBoundsChangedTimer.subscribe(() => {
            this.logger.log('[MapCoreComponent] ' + 'Map bounds changed [DELAYED] - current zoom level: ' + this.map.getZoom());

            //Stop this timer
            this.subHandleBoundsChanged.unsubscribe();

            //Perform action

            //Load new WMS-Layer, also for panning
            if (this.wmsLayerVisible) {
                this.addFullWMSOverlay();
            }

            //When the right zoomlevel is reached add labels to markers or remove them
            if (this.showMarkerLabels()) {
                this.showLabelOnMarkersInViewport();
            }
            else {
                this.removeLabelFromMarkersInViewport(this.markersWithLabel);
            }

            this.rebuildInvalidMarkers();
        });
    }

    //Rebuild a given set, or all markers where iconChanged is true
    private rebuildInvalidMarkers(invalidMarkers: LuminizerMapMarker[] = null): void {
        let markers: LuminizerMapMarker[] = invalidMarkers;
        let alwaysRedraw: boolean = false;

        if (!markers) {
            markers = this.markers;
        } else {
            //A set of markers was supplied. Redraw the icons, even if not iconChanged
            alwaysRedraw = true;
        }

        this.logger.log('[MapCoreComponent] ' + 'Rebuild invalid markers');

        if (!markers || markers.length <= 0) {
            this.logger.log('[MapCoreComponent] ' + 'No invalid markers, skip rebuilding: ', markers);
            return;
        }

        this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Invalid markers rebuild in: ', () => {
            let markersRebuild: number = 0;
            //let showLabels: boolean = this.showMarkerLabels();

            markers.forEach((marker) => {
                const markerDataRef = marker.dataRef;

                if (alwaysRedraw || (markerDataRef && markerDataRef.iconChanged)) {
                    markersRebuild++;
                    markerDataRef.iconChanged = false;

                    // Check if the marker is supposed to have a label, than recreate it
                    if (markerDataRef.hasLabel) {
                        this.setMarkerIcon(marker, this.mapIconGenerator.getIconWithLabel(markerDataRef), markerDataRef.hasLabel);
                    } else {
                        this.setMarkerIcon(marker, this.mapIconGenerator.getIcon(markerDataRef), markerDataRef.hasLabel);
                    }
                }
            });

            this.logger.log('[MapCoreComponent] ' + 'Total markers rebuild: ' + markersRebuild);
        }));
    }

    private loadMarkersInCurrentBounds(): void {
        this.logger.log('Markers laden');
        if (this.autoLoadMarkers && this.map) {
            this.logger.log('Hebben we een autoLoadMarkers en een map ?');
            const bounds = this.map.getBounds();
            if (bounds != undefined) {
                this.logger.log('Niet undefined?');
                //When locked on location, only allow autoload every MapCoreComponent.AUTO_LOAD_FLOOD_DELAY seconds
                if (this.lockOnLocation) {
                    if (this.autoLoadFlooded) {
                        //Stop loading, do nothing
                        return;
                    }
                    else {

                        this.autoLoadFlooded = true;

                        this.autoLoadFloodTimer = setTimeout(() => {
                            this.autoLoadFloodTimer = null;
                            this.autoLoadFlooded = false;
                        }, MapCoreComponent.AUTO_LOAD_FLOOD_DELAY);
                    }
                }

                if (this.isBasicMode) {
                    if (this.map.getZoom() < MapCoreComponent.PUBLIC_PORTAL_THRESHOLD_ZOOMLEVEL) {
                        // When zoomed too far out, don't autoload in public portal
                        return;
                    }
                }

                // Perform actual auto load
                this.logger.log('// Perform actual auto load');
                this.onBoundsChangedDuringAutoLoad.emit({bounds: bounds});
                this.cd.detectChanges();
            }
        }
    }

    //Create and apply a lableicon to markers visible on screen
    private showLabelOnMarkersInViewport(): void {
        this.logger.log('[MapCoreComponent] ' + 'show label on markers in viewport');

        this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Labels SHOWN on markers in viewport in: ', () => {
            let debugCounter: number = 0;

            const mapBounds = this.map.getBounds();
            if (mapBounds) {
                for (let i: number = 0; i < this.markers.length; i++) {
                    const currentMarker = this.markers[i];
                    if (mapBounds.contains(currentMarker.getPosition())) {
                        if (this.markersWithLabel.indexOf(currentMarker) == -1) {
                            debugCounter++;

                            if(currentMarker.dataRef) {
                                currentMarker.dataRef.iconChanged = true;
                                currentMarker.dataRef.hasLabel = true;
                            }
                            // Mark the marker invalid + with label
                            //this.setMarkerIcon(currentMarker, this.mapIconGenerator.getIconWithLabel(currentMarker.dataRef));
                            this.markersWithLabel.push(currentMarker);
                        }
                    }
                }
            }

            this.logger.log('[MapCoreComponent] ' + 'marks with label created: ' + debugCounter);
        }));
    }

    //Reassign default icon to markers in viewport
    private removeLabelFromMarkersInViewport(markers: LuminizerMapMarker[]): void {
        this.logger.log('[MapCoreComponent] ' + 'remove label from marker in viewport');

        this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Labels REMOVED from markers in viewport in: ', () => {
            const mapBounds = this.map.getBounds();
            let debugCounter: number = 0;

            for (let i: number = markers.length - 1; i >= 0; i--) {
                const currentMarker = markers[i];

                if (mapBounds.contains(currentMarker.getPosition())) {
                    debugCounter++;

                    // Mark the marker as invalid + without label
                    if (currentMarker.dataRef) {
                        currentMarker.dataRef.iconChanged = true;
                        currentMarker.dataRef.hasLabel = false;
                    }
                    //this.setMarkerIcon(currentMarker, this.mapIconGenerator.getIcon(currentMarker.dataRef));

                    // NOTE: markers equals markersWithLabel most of the time. That array is updated here (so still reflects markers with labels only)
                    markers.splice(i, 1);
                }
            }

            this.logger.log('[MapCoreComponent] ' + 'num markers edited: ' + debugCounter);

        }));
    }

    private setMarkerIcon(marker: LuminizerMapMarker, newIcon: MapIconInterface, hasLabel: boolean): void {
        if (newIcon) {
            if (!hasLabel) {
                marker.setIcon(newIcon.url);
            } else {
                marker.setIcon(newIcon);
            }

            //Set the hitboxshape, null for default icons(no label) the image/icon can be used as hitbox
            marker.setShape(newIcon.shape);
        }
        else {
            this.logger.log('[MapCoreComponent] ' + 'WARNING: NEW MARKER ICON IS EMPTY. Set marker skipped');
            marker.iconChanged = true;
        }

        //Set the center of the icon for a joint
        if(marker.dataRef && marker.dataRef.icon && marker.dataRef.icon.startsWith("jnt-")){
            marker.setOptions({
                icon:{
                    url: marker.getIcon().toString(),
                    anchor: new google.maps.Point(11, 11),
                }
            })
        }
    }

    private closeAllWindows(): void {
        this.hideInfoWindow();
        this.cd.detectChanges();
    }

    //Googlemaps has no support for cmd-click, so DIY with keyevents
    handleKeyDown(event: KeyboardEvent): void {
        //On escape, close info window
        if (Utils.isEscapeKey(event)) {
            this.logger.log('[MapCoreComponent] ' + 'Press escape');
            this.closeAllWindows();
        }

        if (event.ctrlKey || event.metaKey || event.keyCode == 17 || event.keyCode == 224 || event.keyCode == 91 || event.keyCode == 93) {
            this.cmdKeyPressed = true;
        }

        if (event.shiftKey || event.keyCode == 16) {
            if (this.allowMultiSelect) {
                this.hideInfoWindow();
            }

            //Disable multiselect for apple devices because this happened unintentionally when exiting an input field and shift was pressed
            if(!this.isIOSDevice()){
                this.shiftKeyPressed = true;
            }
        }

        if (this.enableAltKey) {
            if (event.altKey || event.keyCode == 18) {
                this.altKeyPressed = true;
            }
        }

        if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'p') {
            this.printService.printMap();
            return;
        }
    }

    private isIOSDevice() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }


    handleKeyUp(event: KeyboardEvent): void {
        //this.logger.log("[MapCoreComponent] " + "Handle key up: " , event);
        //Somehow .ctrlKey or .metaKey doesn't work here
        if (event.keyCode == 17 || event.keyCode == 224 || event.keyCode == 91 || event.keyCode == 93) {
            this.logger.log('[MapCoreComponent] ' + 'cmd key released');
            this.cmdKeyPressed = false;
        }

        //shiftKey doesn't work here
        if (event.keyCode == 16) {
            this.shiftKeyPressed = false;
        }

        if (this.enableAltKey) {
            if (event.keyCode == 18) {
                this.altKeyPressed = false;
            }
        }
    }

    //Mouseup or touchend
    handleMouseUp(): void {
        this.logger.log('[MapCoreComponent] ' + 'MOUSE UP GLOBAL');

        //Handle shift select
        if (this.allowMultiSelect) {

            //Reset draggable of the map for multiselect
            this.map.setOptions({
                draggable: true,
            });

            //If the rect was visible (thus shift was used)
            if (this.polygonSelect.dragIsActive()) {

                this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Markers SHIFT-selected in: ', () => {
                    this.polygonSelect.stopDragSelect(
                        (rectBounds) => this.setMarkerSelection(rectBounds),
                    );
                }));
            }
        }

        //Handle a dragged marker
        if (this.draggedMarker) {

            this.ngZone.run(() => {

                if (this.draggedMarkerMoved) {
                    this.logger.log('[MapCoreComponent] ' + 'Map mouseup while dragging a marker, submit drag event');
                    this.onDragMarker.emit({
                        draggedMarker: this.draggedMarker,
                        posOld: {lat: this.draggedMarker.dataRef.lat, lng: this.draggedMarker.dataRef.lng},
                        posNew: this.draggedMarker.getPosition(),
                    });
                }

                this.draggedMarkerMoved = false;
                this.draggedMarker = null;

                this.map.setOptions({
                    draggable: true,
                });
            });
        }

        //Handle a clcik+hold on marker
        if (this.mouseDownMarkerTimeout) {
            this.mouseDownMarker.setAnimation(null);
            clearTimeout(this.mouseDownMarkerTimeout);
            this.mouseDownMarkerTimeout = null;
            this.mouseDownMarker = null;
        }

        //Cancel the mouse down on the map (for creating items for example)
        this.cancelMouseDownMap();
    }

    private setMarkerSelection(rectBounds?: google.maps.LatLngBounds): void {
        const selectedMarkers: LuminizerMapMarker[] = [];
        const selectContains = (pos: google.maps.LatLng) => {
            return (rectBounds)
                ? rectBounds.contains(pos)
                : this.polygonSelect.containsLocation(pos);
        };

        this.markers.map(_marker => {
            if (_marker.getVisible() && selectContains(_marker.getPosition())) {
                //Don't select just one baseObject: select all of them when dragging
                if (_marker.dataRef.baseObjects) {
                    _marker.dataRef.baseObjects.map((_baseObject, index) => {
                        if (!_baseObject.hidden) {
                            //This is just for performance. In case of 1 item, use the marker to store selected items
                            //In case of multiple, create a new object (which is slower)
                            if (index == 0) {
                                _marker.dataRef.baseObjectId = parseInt(_baseObject.id);
                                _marker.baseObjectId = parseInt(_baseObject.id);
                                selectedMarkers.push(_marker);
                            }
                            else {
                                selectedMarkers.push(<any>
                                    //Make a new selectionobject
                                    {
                                        dataRef: {baseObjectId: parseInt(_baseObject.id)},
                                        baseObjectId: parseInt(_baseObject.id),
                                    },
                                );
                            }
                        }
                    })
                }
            }
        })

        this.mapCoreV2Component.getAllNonV1MapItems().map(_v2MapItem => {
            if(_v2MapItem.googlePolyline.getVisible()){
                let mapItem:MapItemV2 = null;
                _v2MapItem.googlePolyline.getPath().getArray().map(_point => {
                    if(selectContains(_point)){
                        mapItem = _v2MapItem;
                    }
                })
                if(mapItem !== null){
                    //Add polyline to marker list as new object
                    selectedMarkers.push(<any>
                        //Make a new selectionobject
                        {
                            dataRef: {baseObjectId: mapItem.getBaseObjectId()},
                            baseObjectId: mapItem.getBaseObjectId(),
                        },
                    );

                }
            }
        })

        this.mapCoreV2Component.getAllClusterMapItems().map(_v2MapItem => {
            if(_v2MapItem.googlePolygon !== null && _v2MapItem.googlePolygon.getVisible()){
                let mapItem:MapItemV2 = null;
                _v2MapItem.googlePolygon.getPath().getArray().map(_point => {
                    if(selectContains(_point)){
                        mapItem = _v2MapItem;
                    }
                })
                if(mapItem !== null){
                    //Add polyline to marker list as new object
                    selectedMarkers.push(<any>
                        //Make a new selectionobject
                        {
                            dataRef: {baseObjectId: mapItem.getBaseObjectId()},
                            baseObjectId: mapItem.getBaseObjectId(),
                        },
                    );

                }
            }
        })

        this.onSelectMarkers.emit({markers: selectedMarkers, cmdKeyPressed: this.cmdKeyPressed});
    }

    public acceptDrag(marker: LuminizerMapMarker, newLat: number, newLng: number) {
        this.logger.log('[MapCoreComponent] ' + 'Acceptmarkerdrag');
        marker.dataRef.lat = newLat;
        marker.dataRef.lng = newLng;

        marker.setPosition({lat: newLat, lng: newLng});

        this.zoomToFitAllMarkers();
    }

    public rejectDrag(marker: LuminizerMapMarker, oldLat: number, oldLng: number) {
        this.logger.log('[MapCoreComponent] ' + 'reject, reset position');
        marker.setPosition({lat: oldLat, lng: oldLng});
    }

    private handleSelectMapType(selectOptions: SelectMapTypeOptions) {
        //Apply and store choice
        this.map.setMapTypeId(selectOptions.mapType.code);

        if (selectOptions.storeChoice) {
            this.storage.setValue(StorageService.KEY_MAP_MAP_TYPE, selectOptions.mapType.code);
        }
    }

    private handleTogglePOI(): void {
        this.POIVisible = !this.POIVisible;
        this.setPOIVisible(this.POIVisible);

        this.storage.setValue(StorageService.KEY_MAP_POI_ENABLED, this.POIVisible);
    }

    private handleToggleHeatmap(heatmapOptionCode:string): void {
        this.heatmapOptionCode = heatmapOptionCode;
        this.toggleHeatmapOnMap(this.heatmapOptionCode);
    }

    private handleToggleMapPopup(): void {
        this.allowMapPopup = !this.allowMapPopup;
        this.storage.setValue(StorageService.KEY_MAP_POPUP_ENABLED, this.allowMapPopup);
    }

    private handleToggleMarkerLabels(): void {
        this.allowMarkerLabels = !this.allowMarkerLabels;
        this.storage.setValue(StorageService.KEY_MAP_MARKER_LABELS_ENABLED, this.allowMarkerLabels);

        if (this.showMarkerLabels()) {
            this.showLabelOnMarkersInViewport();
        }
        else {
            this.removeLabelFromMarkersInViewport(this.markersWithLabel);
        }
        this.rebuildInvalidMarkers();
    }

    private handleToggleWMSLayer(): void {
        //Toggle all layers
        if (this.wmsLayerVisible) {
            this.model.currentAreaal.getValue().wmsData.forEach((wmsData:any, index) => {
                if (!wmsData.disabled) {
                    wmsData.active = false;
                }
            });
            this.removeTiledWMSOverlay();
            this.removeFullWMSOverlay();
        }
        else {
            this.model.currentAreaal.getValue().wmsData.forEach((wmsData:any, index) => {
                if (!wmsData.disabled) {
                    wmsData.active = true;
                }
            });
            this.addTiledWMSOverlay();
            this.addFullWMSOverlay();
            this.tryWMSSettings();
        }

        this.wmsLayerVisible = !this.wmsLayerVisible;

        this.storage.setValue(StorageService.KEY_MAP_WMS_ENABLED, this.wmsLayerVisible);
    }

    private handleToggleSingleWMSLayer(server:WmsDataInterface): void {
        if (!server.disabled) {
            server.active = !server.active;

            this.storage.setValue(`${StorageService.KEY_MAP_SINGLE_WMS_ENABLED}${server.id}`, server.active);

            if (!server.active) {
                this.removeSingleTiledWMSOverlay(server.id);
                this.removeFullWMSOverlay();
            }
            else {
                this.addSingleTiledWMSOverlay(server);
                this.addFullWMSOverlay();
                this.tryWMSSettings();
            }
        }
    }

    private handleLoadAdditionalLayer(geoJson: string, fileName: string): void {
        this.map.data.setStyle(function (feature) {
            switch (feature.getGeometry().getType()) {
                case 'Point':
                    return /** @type {!google.maps.Data.StyleOptions} */({
                        clickable: true,
                        icon: {
                            fillColor: 'gray', fillOpacity: 0.6, strokeColor: 'gray', strokeOpacity: 0.6,
                            scale: 3, path: google.maps.SymbolPath.CIRCLE,
                        },
                        cursor: 'pointer',
                    });
                case 'Polygon':
                    return /** @type {!google.maps.Data.StyleOptions} */({
                        clickable: true,
                        fillColor: 'gray',
                        fillOpacity: 0.5,
                    });
                case 'Line':
                    return /** @type {!google.maps.Data.StyleOptions} */({
                        clickable: true,
                        strokeColor: 'gray',
                        strokeOpacity: 0.5,
                    });
                default:
                    return /** @type {!google.maps.Data.StyleOptions} */({
                        clickable: true,
                    });
            }
        });

        const geojsonFeatures = this.map.data.addGeoJson(JSON.parse(geoJson));
        for (let i = 0; i < this.features.length; i++) {
            if (this.features[i].name == fileName) {
                for (let j = 0; j < this.features[i].features.length; j++) {
                    this.map.data.remove(this.features[i].features[j]);
                }
                this.features[i].features = geojsonFeatures;
                return;
            }
        }

        let userLayer = {name: fileName, features: geojsonFeatures};
        this.features.push(userLayer);

        this.map.data.addListener('mouseover', (event) => {

            const markerData:LuminizerLayerMarkerDataRef = {
                id: event.feature.getId(),
                lat: event.latLng.lat(),
                lng: event.latLng.lng(),
                hidden: false,
                dataLayer: true,
                icon: {fillColor: 'gray', fillOpacity: 0.6, strokeColor: 'gray', strokeOpacity: 0.6,
                    scale: 3, path: google.maps.SymbolPath.CIRCLE},
                dataLayerLabels: []
            };

            event.feature.forEachProperty( (value:any, name:string) => {
                markerData.dataLayerLabels.push({name:name, value:value});
            });

            this.handleMouseOverDataLayer(event, markerData);
        });

        this.map.data.addListener('mouseout', () => {
            this.handleMouseOutMarker();
        });
    }

    private deleteAdditionalLayer(layer: string): void {
        for (let i = 0; i < this.features.length; i++) {
            if (this.features[i].name == layer) {
                for (let j = 0; j < this.features[i].features.length; j++) {
                    this.map.data.remove(this.features[i].features[j]);
                }
            }
        }
        this.features = this.features.filter(function (obj) {
            return obj.name !== layer;
        });
    }

    private handleToggleMapClusters():void {
        this.allowClusterer = !this.allowClusterer;
        this.storage.setValue(StorageService.KEY_MAP_CLUSTERER, this.allowClusterer);

        // this.showItemsOnMap();
    }


    //TODO: dit worden er langzamerhand zo veel dat het mooier kan
    public tryWMSSettings(): void {
        this.model.currentAreaal.getValue().wmsData.forEach((wmsData) => {
            if (!wmsData.disabled) {
                this.wmsLayerGenerator.tryWMSSettings(wmsData, (isImplementationError: boolean, isLayerError: boolean) => {
                    if (this.isBasicMode) {
                        this.logger.log('[MapCoreComponent] ' + 'Error alert prevented for public portal mode');
                        return;
                    }

                    this.logger.log('[MapCoreComponent] try WMS settings for ' + wmsData.label + ' ' + isImplementationError);
                    this.logger.log('[MapCoreComponent] try WMS settings ' + wmsData.label + ' ' + isLayerError);

                    //Show implementation error first, then, handle other errors
                    if (isImplementationError) {
                        this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_WARNING, 'WMS implementatiefout for: ' + wmsData.label, 'CRS [' + MapWMSLayerGenerator.CURRENT_CRS + '] is niet ondersteund door de applicatie. Stel de WMS-generator anders in of voeg ondersteuning toe. BBOX waardes zijn nu ongeldig.');
                    }
                    else if (isLayerError) {
                        this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_WARNING, this.ts.translate('wms.errortitle') + ' ' + wmsData.label, this.ts.translate('wms.errorlayers'));
                    }
                    else {
                        this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_WARNING, this.ts.translate('wms.errortitle') + ' ' + wmsData.label, this.ts.translate('wms.errordefault'));
                    }
                });
            }
        })
    }

    public addFullWMSOverlay(): void {
        if (!this.model.currentAreaal.getValue().wmsData) {
            return;
        }
        this.model.currentAreaal.getValue().wmsData.forEach((wmsData, index) => {
            this.wmsLayerGenerator.addFullWMSOverlay(this.map, wmsData, index);
        })
    }

    public removeFullWMSOverlay(): void {
        this.model.currentAreaal.getValue().wmsData.forEach((wmsData, index) => {
            this.wmsLayerGenerator.removeFullWMSOVerlay(index);
        })
    }

    public disableLocationTracking(): void {
        this.lockOnLocation = false;

        //Enable to hide the marker when disabeling the location lock feature
        /*if (this.currentLocationMarker){
            this.currentLocationMarker.setMap(null);
            this.currentLocationMarker = null;
        }*/

        //If there was a location marker, make it inactive
        if (this.currentLocationMarker) {
            this.currentLocationMarker.setIcon(this.mapIconGenerator.getIcon({icon: MapIconGenerator.LOCATION_ICON_INACTIVE}));
        }

        //Clear the listener
        if (navigator.geolocation) {
            navigator.geolocation.clearWatch(this.currentLocationWatcher);
        }
        else {
            //browser support navigation niet jo
        }
    }

    private enableLocationTracking(): void {
        if (navigator.geolocation) {

            this.lockOnLocation = true;

            //Remove any current location markers
            if (this.currentLocationMarker) {
                this.currentLocationMarker.setMap(null);
                this.currentLocationMarker = null;
            }

            //Clear the subscription for when the location tracking is enabled twice in a row somehow
            navigator.geolocation.clearWatch(this.currentLocationWatcher);

            this.currentLocationWatcher = navigator.geolocation.watchPosition((position) => {
                this.logger.log('[MapCoreComponent] ' + 'NEW POSITION:', position);

                const currentPosition = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };

                //Convert to Google latlng and check distance
                const newPosition = new google.maps.LatLng(currentPosition.lat, currentPosition.lng);

                let oldPosition = this.map.getCenter();
                let deltaInMeters: number = oldPosition ? google.maps.geometry.spherical.computeDistanceBetween(newPosition, oldPosition) : Number.MAX_VALUE;

                //Prevent new bounds from disabeling location watch
                this.centerUpdatedByLocationWatch = true;

                //Check if there is a route marker, if there is, calculate a route
                if (this.routeMarker) {
                    this.calculateAndDisplayRoute(currentPosition, this.routeMarker.dataRef);
                    this.routeMarker = null;
                }
                //If this is the first position after enabeling the location watch, create a marker
                if (!this.currentLocationMarker) {

                    this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_GPS_POSITION_UPDATE, {currentPosition}));

                    //this.logger.log("[MapCoreComponent] " + "There is no marker, add a new one, regardless of new distance");

                    this.currentLocationMarker = new google.maps.Marker({
                        position: currentPosition,
                        map: this.map,
                        icon: this.mapIconGenerator.getIcon({icon: MapIconGenerator.LOCATION_ICON_ACTIVE}),
                        animation: google.maps.Animation.DROP,
                        draggable: false,
                    });

                    //Zoom only the first time to protect from loading the bbox of a high zoom level map
                    if (this.map.getZoom() < MapCoreComponent.DEFAULT_LOCATION_WATCH_ZOOMLEVEL) {
                        this.map.setZoom(MapCoreComponent.DEFAULT_LOCATION_WATCH_ZOOMLEVEL);
                    }

                }
                else if (deltaInMeters < 2) {

                    //this.logger.log("[MapCoreComponent] " + "Distance change too small, do nothing: " + deltaInMeters);

                    //The location watch was already running, and the delta is too small. Do nothing
                    this.centerUpdatedByLocationWatch = false;
                    return;
                }
                else {
                    //this.logger.log("[MapCoreComponent] " + "Marker exists, distance is large enough: update location");

                    this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_GPS_POSITION_UPDATE, {currentPosition}));
                    //The marker is present, update it's location
                    this.currentLocationMarker.setPosition(currentPosition);
                }

                //Update the map center with the new location
                this.map.setCenter(currentPosition);

            }, (error) => {
                // Error with GPS
                this.logger.log('[MapCoreComponent] ' + 'Navigator not allowed or other error', error);

                this.showNoGPSError(error);
                this.disableLocationTracking();
            }, {enableHighAccuracy: true});

        }
        else {
            //Geo shizzle doet het niet op deze browser
            this.logger.log('[MapCoreComponent] ' + 'No navigator support on this browser');
            this.disableLocationTracking();
            this.showNoGPSError();
        }
    }

    public enableAutoLoadMarkers(loadNow: boolean = true): void {
        this.autoLoadMarkers = true;

        if (loadNow) {
            this.loadMarkersInCurrentBounds();
        }
    }

    public disableAutoLoadMarkers(): void {
        this.autoLoadMarkers = false;
    }

    handleClickAutoLoadMarkers(): void {
        if (this.autoLoadMarkers) {
            this.disableAutoLoadMarkers();

            //Autoload deactivated manually
            if (this.searchExpanded) {
                this.autoLoadDisabledDuringSearch = true;
            }
        }
        else {
            this.enableAutoLoadMarkers();
        }
    }

    handleClickLocation(): void {
        if (this.lockOnLocation) {
            this.disableLocationTracking();
        }
        else {
            this.enableLocationTracking();
            //Location tracking will trigger an autoload, so skip loading for now
            //this.enableAutoLoadMarkers(false);
        }
    }

    private showNoGPSError(error: GeolocationPositionError = null): void {
        //Generate error message for service engineers
        let errorMessage: string = (this.auth.allowShowDebug() && error ? '<br><br>Navigation Error ' + error.code + ': ' + error.message : '');

        if (error && error.code == 1) {
            //Location prompt denied error
            this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_WARNING, this.ts.translate('navigate.errortitle'), this.ts.translate('navigate.errordenied') + errorMessage);
        }
        else {
            //Display no gps error
            this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_WARNING, this.ts.translate('navigate.errortitle'), this.ts.translate('navigate.error') + errorMessage);
        }
    }

    handleClickFocus(): void {
        //Zoom to selected markers, or else zoom to markers, or else zoom to areaalcenter
        if (this.selectedMarkers.length > 0) {
            //Add the selected V1 markers and get the non V1 selected polylines from V2 component
            this.zoomToFitMarkers(this.selectedMarkers, this.mapCoreV2Component.getAllNonV1SelectedMapItems(), this.mapCoreV2Component.getAllClusterMapItems());
        }
        else {
            this.zoomToFitAllMarkers();
        }
    }

    public filterItemsLocal(hiddenItems: (number | string)[], visibleItems: (number | string)[], mapHidden: boolean, zoomOnMarkers: boolean): void {
        this.mapCoreV2Component.filterItemsV2(hiddenItems, visibleItems)

        this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] map items FILTERED in: ', () => {
            this.logger.log('[MapCoreComponent] ' +
                'Number hidden items: ' + hiddenItems.length +
                ' visible items: ' + visibleItems.length +
                ' Total number of markers items: ' + this.markers.length
            );

            const invalidMarkers: LuminizerMapMarker[] = [];
            this.numVisibleMarkers = 0;

            // todo: check for performance improvements
            this.markers.forEach(marker => {
                if (hiddenItems.length < visibleItems.length) {
                    if (marker.dataRef) {
                        marker.dataRef.hidden = false;
                    }
                    marker.setVisible(true);
                    if (hiddenItems.some(baseObjectId => {
                        return marker.dataRef &&
                            marker.dataRef.baseObjects &&
                            marker.dataRef.baseObjects.some(baseObject => String(baseObject.id) === String(baseObjectId));
                    })) {
                        marker.setVisible(false);
                        if (marker.dataRef) {
                            marker.dataRef.hidden = true;
                            if (marker.dataRef.baseObjects) {
                                marker.dataRef.baseObjects.forEach(baseObject => {
                                    baseObject.hidden = hiddenItems.some(_baseObjectId => String(baseObject.id) === String(_baseObjectId));
                                });
                            }
                        }
                    }
                } else {
                    if (marker.dataRef) {
                        marker.dataRef.hidden = true;
                    }
                    marker.setVisible(false);
                    if (visibleItems.some(baseObjectId => {
                        return  marker.dataRef &&
                            marker.dataRef.baseObjects &&
                            marker.dataRef.baseObjects.some(baseObject => String(baseObject.id) === String(baseObjectId));
                    })) {
                        marker.setVisible(true);
                        if (marker.dataRef) {
                            marker.dataRef.hidden = false;
                            if (marker.dataRef.baseObjects) {
                                marker.dataRef.baseObjects.forEach(baseObject => {
                                    baseObject.hidden = !visibleItems.some(
                                        _baseObjectId => String(baseObject.id) === String(_baseObjectId)
                                    );
                                });
                            }
                        }
                    }
                }

                if (marker.dataRef && marker.dataRef.baseObjects && marker.dataRef.baseObjects.length > 1 ) {
                    invalidMarkers.push(marker);
                }
            });

            if (this.autoZoomToFitMarkers && zoomOnMarkers) {
                if (mapHidden) {
                    this.queueZoomToFitMarkers = true;
                } else {
                    this.zoomToFitAllMarkers();
                }
            }
            this.numVisibleMarkers = this.markers.filter(marker => marker.getVisible()).length;

            this.rebuildInvalidMarkers(invalidMarkers);
            // this.showItemsOnMap();
        }));
        //});

        this.logger.log('[MapCoreComponent] ' + 'Total visible markers: ' + this.numVisibleMarkers);
    }

    public updateSelectedItems(selectedBaseObjects: { baseObjectId?: number | string }[]): void {

        //Store the list with ID's for later reference. Copy the array, so the items can safely be removed in this function
        this.selectedBaseObjects = selectedBaseObjects;
        const baseObjectDict = {};
        selectedBaseObjects.map(_selectedObject => {
            baseObjectDict[_selectedObject.baseObjectId] = _selectedObject;
        })

        //Select new selected markers. selectedBaseObjects is a list of ID's so you'll need to map them to mapItems first
        const newSelectedMarkers: LuminizerMapMarker[] = [];

        this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Set markers to selected: ', () => {
            this.markers.filter(_marker => {
                return _marker.dataRef
            }).map(_marker => {
                const markerDataRef = _marker.dataRef;

                //Store old state. If selected has no value yet, default to false
                markerDataRef.oldSelectedState = (markerDataRef.selected || false);

                //Deselect all markers
                markerDataRef.selected = false;

                if (markerDataRef.baseObjects) { // && marker.dataRef.baseObjects.length) {
                    markerDataRef.baseObjects.forEach((baseObject) => {
                        if (baseObject.id in baseObjectDict) {
                            //Match: Select marker
                            newSelectedMarkers.push(_marker);
                            markerDataRef.selected = true;
                            markerDataRef.baseObjectId = parseInt(baseObject.id);
                        }
                    });
                }

                //If the selected state changed, invalidate the marker icon
                if (markerDataRef.oldSelectedState != markerDataRef.selected) {
                    markerDataRef.iconChanged = true;
                }
            })
        }));
        //Replace old with new
        this.selectedMarkers = newSelectedMarkers;


        //TODO: het zoomniveau van de kaart wordt hierna pas geupdate. De kaart staat standaard heel diep ingezoomed en er moeten dus eigenlijk labels worden getoond
        //TODO: daarna worden de bounds pas goed geset en kan de kaart pas echt zeggen of er labels moeten komen of niet.
        //TODO: voor nu opgelost met een 500ms timeout
        setTimeout(() => {
            //Recreate all modified marker icons. Not only for selected but also for the deselected items
            this.rebuildInvalidMarkers();

            //Update infowindows (possibly)
            if (this.selectedMarkers.length > 0) {

                //Markers are selected and info window was visible
                if ((<any>this.infoWindow).getMap() != null) {
                    //Redraw the info window, items might be selected

                    //Refresh the infowindow. Use lastInfoWindowMarker to skip mapping selected objects on markers
                    this.showInfoWindow(this.lastInfoWindowMarker);
                }

                if (this.showStreetView) {
                    this.streetviewComponent.handleSelectMapItem(this.selectedMarkers[this.selectedMarkers.length - 1].dataRef);
                }
            }
        }, 500);
    }

    private showMarkerLabels(): boolean {
        return this.allowMarkerLabels && this.map.getZoom() >= this.showLabelZoom;
    }

    private createMarker(markerData: MapItem, showLabel: boolean): google.maps.Marker {
        let icon = (showLabel ? this.mapIconGenerator.getIconWithLabel(markerData) : this.mapIconGenerator.getIcon(markerData).url);

        let zIndex:number = 1;
        if(markerData && markerData.icon){
            //Give werkorders/schakelkast/kabelbon higher zindex. Easiest reference here is to check for icon name.
            if(markerData.icon.startsWith("wrk-") || markerData.icon.startsWith("sch-") || markerData.icon.startsWith("ond-")){
                zIndex = 2;
            }

            //Check if baseobject has children.
            //If so, check to see if any of them has a red marker (error).
            //If so, set the main baseobject icon to the red icon so it is shown on the map.
            if(markerData.baseObjects && typeof markerData.baseObjects !== 'undefined'){
                markerData.baseObjects.map(_baseobject => {
                    if(_baseobject.icon && typeof _baseobject.icon !== 'undefined' && _baseobject.icon.includes("-red")){
                        markerData.icon = _baseobject.icon
                    }
                })
            }

        }

        const marker: LuminizerMapMarker = new google.maps.Marker(
            <MarkerOptions>{
                position: markerData,
                icon: icon,
                dataRef: markerData,
                draggable: false,
                visible: markerData.hidden,
                zIndex: zIndex
            },
        );

        //TODO: aanpassen alsje getIconWithLabel er uit haalt oooooit

        // iconChanged can be set to false here, the icon is just created, with the right appearance
        if (showLabel) {
            if (marker.dataRef) {
                marker.dataRef.iconChanged = false;
                marker.dataRef.hasLabel = true;
            }
            // Store label settings for later use
            this.markersWithLabel.push(marker);
        }
        else {
            if (marker.dataRef) {
                marker.dataRef.iconChanged = false;
                marker.dataRef.hasLabel = false;
            }
        }

        //When appending items you need to create mouseovers for hidden items too. They will be displayed later on.
        //if (!markerData.hidden) {
        //Pass marker to components.
        //Marker is the marker object just created, googleMapsMarker is the marker reference from the mouseEvent. The data differs for a bit
        marker.addListener('click', () => ((marker) => {
            //Execute in the zone, trigger binding if a var changes in this callstack
            //this.ngZone.run(() => {
            this.ngZone.runOutsideAngular(() => {
                this.stopMarkerClickEventPropagation = true;

                let firstVisibleBaseObjectId: number = MapCoreComponent.getFirstVisibleBaseObjectId(marker); //= -1;
                /*if (marker.dataRef.baseObjects) {
                    for(let i = 0; i <  marker.dataRef.baseObjects.length; i++) {
                        if (!marker.dataRef.baseObjects[i].hidden){
                            firstVisibleBaseObjectId = marker.dataRef.baseObjects[i].id;
                            break;
                        }
                    }
                }*/

                if (marker.dataRef.baseObjects || marker.dataRef.actionUrl) {
                    this.logger.log('[MapCoreComponent] marker has baseObjects or actionUrl');

                    if (this.allowMultiSelect) {
                        const selectedMarkers: LuminizerMapMarker[] = [];
                        marker.dataRef.baseObjects.forEach((baseObject) => {
                            if (!baseObject.hidden) {
                                marker.dataRef.baseObjectId = parseInt(baseObject.id);
                                marker.baseObjectId = parseInt(baseObject.id);
                                const selectedMarker = {
                                    baseObjectId: baseObject.id,
                                    dataRef: {id: marker.dataRef.id, baseObjectId: baseObject.id},
                                };
                                selectedMarkers.push(<any>selectedMarker);
                            }
                        });

                        this.handleClickMarker(selectedMarkers, false);
                    }
                    else {
                        // Note: this will show the inforwindow for the marker, not select te marker itself. So keep this on, even for !allowSingleSelect
                        marker.dataRef.baseObjectId = firstVisibleBaseObjectId;
                        marker.baseObjectId = firstVisibleBaseObjectId;
                        this.handleClickMarker([<any>marker], false);
                    }

                }
            });
        })(marker));

        marker.addListener('mouseover', () => ((marker) => {
            //Execute in the zone, trigger binding if a var changes in this callstack
            //this.ngZone.run(() => {
            this.ngZone.runOutsideAngular(() => {
                this.handleMouseOverMarker(marker);
            });
        })(marker));

        marker.addListener('mouseout', () => (() => {
            //Execute in the zone, trigger binding if a var changes in this callstack
            //this.ngZone.run(() => {
            this.ngZone.runOutsideAngular(() => {
                this.handleMouseOutMarker();
            });
        })());

        marker.addListener('mousedown', () => ((marker) => {
            this.logger.log('[MapCoreComponent] ' + 'mousedown start drag');

            if (this.allowMarkerDrag && this.allowMarkerDragByMouseDown) {

                this.mouseDownMarker = marker;

                this.mouseDownMarkerTimeout = setTimeout(() => {

                    if (this.mouseDownMarker && !this.draggedMarker) {

                        this.mouseDownMarkerTimeout = null;

                        this.startMarkerDrag(this.mouseDownMarker);

                        this.mouseDownMarker.setAnimation(google.maps.Animation.BOUNCE);
                        setTimeout(() => {
                            if (this.mouseDownMarker) {
                                this.mouseDownMarker.setAnimation(null);
                            }
                        }, 750);
                    }
                }, MapCoreComponent.MOUSE_HOLDDOWN_TIME);
            }

            if (this.altKeyPressed || this.allowEasyMarkerDrag) {
                if (this.allowMarkerDrag && !this.draggedMarker) {
                    this.startMarkerDrag(marker);
                }
            }
            else {
                this.draggedMarkerMoved = false;
                this.draggedMarker = null;
            }

        })(marker));
        //}

        this.markers.push(marker);
        return marker;
    }

    private startMarkerDrag(marker: LuminizerMapMarker): void {
        this.map.setOptions({
            draggable: false,
        });

        this.draggedMarker = marker;
    }

    private handleClickMarker(markers: LuminizerMapMarker[], byPopUp: boolean): void {
        //marker.dataRef.baseObjectId = baseObjectId;
        //marker.baseObjectId = baseObjectId;
        this.logger.log('[MapCoreComponent] ' + 'Marker clicked, passing event to component: cmdKeyPressed: ' + this.cmdKeyPressed + ' byPopUp: ' + byPopUp + ' markers: ', markers);// + marker.dataRef.baseObjectId + " marker.baseObjectId: " + marker.baseObjectId, marker);
        this.onClickMarker.emit({markers: markers, cmdKeyPressed: this.cmdKeyPressed, byPopUp: byPopUp});
    }

    private handleMouseOutMarker(): void {
        this.clearMouseTimeouts();

        this.mouseOutMarkerTimeout = setTimeout(() => {
            if (!this.preventAutoCloseInfoWindow) {
                this.hideInfoWindow();
            }
            else {
                this.preventAutoCloseInfoWindow = false;
            }
        }, MapCoreComponent.MOUSE_OUT_POPUP_TIME);

    }

    private clearMouseOverMarkerTimeout(): void {
        if (this.mouseOverMarkerTimeout) {
            clearTimeout(this.mouseOverMarkerTimeout);
            this.mouseOverMarkerTimeout = null;
        }
    }

    private clearMouseOutMarkerTimeout(): void {
        if (this.mouseOutMarkerTimeout) {
            clearTimeout(this.mouseOutMarkerTimeout);
            this.mouseOutMarkerTimeout = null;
        }
    }

    public handleMouseOverMarker(marker: (LuminizerMapMarker | LuminizerMapMarkerV2Conversion)): void {
        this.clearMouseTimeouts();
        if (this.allowInfoWindowPopup) {
            this.mouseOverMarkerTimeout = setTimeout(() => {
                this.showInfoWindow(marker as LuminizerMapMarker);
            }, MapCoreComponent.MOUSE_OVER_POPUP_TIME);
        }
        this.onMouseOverMarker.emit({marker: marker as LuminizerMapMarker});
    }

    private handleMouseOverDataLayer(marker: MVCObject, markerData: LuminizerLayerMarkerDataRef): void {
        this.clearMouseTimeouts();

        if (this.allowInfoWindowPopup){
            this.mouseOverMarkerTimeout = setTimeout(() => {
                this.showInfoWindowDataLayer(marker, markerData);
            }, MapCoreComponent.MOUSE_OVER_POPUP_TIME);
        }
    }

    private clearMouseTimeouts(): void{
        //Stop any pending mouse over/out timeouts
        this.clearMouseOverMarkerTimeout();
        this.clearMouseOutMarkerTimeout();
    }

    public allowInfoWindowPopup(): boolean {
        return this.allowMarkerPopup && (!this.allowMultiSelect || !this.shiftKeyPressed)
    }

    private hideInfoWindow(): void {
        this.infoWindow.close();
    }

    private showInfoWindowDataLayer(marker, markerData:LuminizerLayerMarkerDataRef): void {
        // Disabling of the pop-up isn't possible on mobile (you need it to navigate), so only check it on a not-mobile environment
        if (!this.mobileMode && !this.allowMapPopup) {
            return;
        }

        this.ngZone.runOutsideAngular(() => {
            //Create div's manually, the click event won't trigger as plain text
            let containerDiv: HTMLElement = document.createElement('div');
            containerDiv.className = 'map-infowindow-container';
            containerDiv.style.display = 'inline-block';


            if (marker && markerData) {

                for(let i = 0; i < markerData.dataLayerLabels.length; i++){
                    let codeDiv: HTMLElement = document.createElement('div');
                    codeDiv.innerHTML = '<div style="color:black" data-value=' + markerData.id + ' class=\'map-infowindow-code-button ' + '\' title=\'' + this.ts.translate('Selecteer item:') + ' ' + markerData.dataLayerLabels[i].name + '\'>' + markerData.dataLayerLabels[i].name + ': ' + markerData.dataLayerLabels[i].value + '</div>';

                    containerDiv.appendChild(codeDiv);
                }

                let wrapperDiv: HTMLElement = document.createElement('div');
                wrapperDiv.appendChild(containerDiv);

                this.infoWindow.setContent(wrapperDiv);

                this.infoWindow.setPosition(marker.latLng);

                this.infoWindow.set('pixelOffset', new google.maps.Size(0, -5));

                this.infoWindow.open(this.map);

                // Wait for the info window to render, then add listeners
                setTimeout(() => {
                    // Get the content of the window
                    const infoWindowContent = <Element>this.infoWindow.getContent();
                    // Try to find the root
                    if (infoWindowContent && infoWindowContent.parentElement && infoWindowContent.parentElement.parentElement) {
                        const infoWindowDiv = infoWindowContent.parentElement.parentElement;
                        infoWindowDiv.onmouseenter =
                            () => {
                                this.preventAutoCloseInfoWindow = true;
                            };
                        infoWindowDiv.onmouseleave =
                            () => {
                                // Directly hide the info window
                                this.hideInfoWindow();
                            };
                    }
                });
            }
        });
    }

    private showInfoWindow(marker: LuminizerMapMarker): void {
        // Disabling of the pop-up isn't possible on mobile (you need it to navigate), so only check it on a not-mobile environment
        if (!this.mobileMode && !this.allowMapPopup) {
            return;
        }

        this.lastInfoWindowMarker = marker;

        this.ngZone.runOutsideAngular(() => {
            //Create div's manually, the click event won't trigger as plain text
            let containerDiv: HTMLElement = document.createElement('div');
            containerDiv.className = 'map-infowindow-container';

            containerDiv.onclick =
                () => {
                    this.handleClickMarkerPopup(marker.dataRef);
                };

            let streetviewLinkDiv: HTMLElement = document.createElement('div');
            let streetviewTitle: string = this.ts.translate('streetview.view');
            streetviewLinkDiv.innerHTML = '<div class=\'map-infowindow-streetview-button\' title=\'' + streetviewTitle + '\'><i class=\'material-icons map-streetview-icon\'>visibility</i></div>';
            streetviewLinkDiv.onclick =
                (e) => {
                    e.stopPropagation(); //Stop passing of event, so the container does not get a click event anymore

                    this.handleOpenStreetview(marker.dataRef);

                    //The event is fired from inside google maps, somehow angular has problems detecting changes that way. So do it manually
                    this.cd.detectChanges();
                };

            containerDiv.appendChild(streetviewLinkDiv);

            if (this.showRouteButton) {
                let routeLinkDiv: HTMLElement = document.createElement('div');
                let routeTitle: string = this.ts.translate('route.view');
                routeLinkDiv.innerHTML = '<div class=\'map-infowindow-streetview-button\' title=\'' + routeTitle + '\'><i class=\'material-icons map-streetview-icon\'>directions_car</i></div>';
                routeLinkDiv.onclick =
                    (e) => {
                        e.stopPropagation(); //Stop passing of event, so the container does not get a click event anymore

                        this.handleClickStartRoute(marker);

                        //The event is fired from inside google maps, somehow angular has problems detecting changes that way. So do it manually
                        this.cd.detectChanges();
                    };

                containerDiv.appendChild(routeLinkDiv);
            }

            // Create container for base object list
            let baseObjectContainerDiv: HTMLElement = document.createElement('div');
            baseObjectContainerDiv.className = 'map-infowindow-baseobject-container';

            let tempIconUrl: string;


            //Fix for old items without baseobjects
            //TODO: in de praktijk is dit waarschijnlijk alleen voor WO op de kaart. Als daar ook gebruik gemaakt gaat worden van mapitems/baseobject kan deze fallback er uit
            if (!marker.dataRef.baseObjects) {
                marker.dataRef.baseObjects = [{
                    id: marker.dataRef.id,
                    label: marker.dataRef.label,
                    icon: marker.dataRef.icon,
                    actionUrl: marker.dataRef.actionUrl,
                }];
            }


            if (marker.dataRef.baseObjects) {

                const baseObjectIds: string[] = [];

                // Loop to all base objects for this map item and construct the popup divs
                for (let i = 0; i < marker.dataRef.baseObjects.length; i++) {

                    // Variable needs to be created in loop, not outside, for reference in click function
                    let tempBaseObject = marker.dataRef.baseObjects[i];


                    if (!tempBaseObject.hidden) {

                        baseObjectIds.push(tempBaseObject.id);

                        tempIconUrl = this.mapIconGenerator.getIcon(tempBaseObject).url;

                        // Look for selected items
                        let selectedClass: string = '';
                        if (marker.dataRef.selected) {
                            this.selectedBaseObjects.forEach((selectedBaseObject) => {
                                if (parseInt(tempBaseObject.id) == selectedBaseObject.baseObjectId) {
                                    selectedClass = 'map-infowindow-code-button-selected';
                                }
                            });
                        }

                        let codeDiv: HTMLElement = document.createElement('div');
                        let singleSelect: string = this.allowSingleSelect ? '' : 'no-select';
                        codeDiv.innerHTML = '<div data-value=' + tempBaseObject.id + ' class=\'map-infowindow-code-button ' + singleSelect + ' ' + selectedClass + '\' title=\'' + this.ts.translate('Selecteer item:') + ' ' + tempBaseObject.label + '\'><img alt="" data-value=' + tempBaseObject.id + ' src=' + tempIconUrl + '>' + tempBaseObject.label + '</div>';

                        codeDiv.onclick =
                            (e) => {
                                //User clicked on a baseobject from the infowindow

                                //Stop passing of event, so the container does not get a click event anymore
                                e.stopPropagation();

                                //Get the clicked ID via the element
                                let clickedBaseObjectID = (<HTMLElement>e.target).getAttribute('data-value');
                                this.logger.log('[MapCoreComponent] ' + 'clicked baseobject: ', clickedBaseObjectID);

                                //Create a fake marker. You didn't click a marker, but only a baseobject of that marker. So create a fake marker consisting only of one baseobject
                                let selectedBaseObject: BaseObjectInterface | object = {};
                                marker.dataRef.baseObjects.forEach((baseObject) => {
                                    if (baseObject.id == clickedBaseObjectID) {
                                        selectedBaseObject = baseObject;
                                    }
                                });

                                const fakeMarker = {
                                    dataRef: {
                                        baseObjectId: clickedBaseObjectID,
                                        baseObjects: [selectedBaseObject],
                                    }, baseObjectId: clickedBaseObjectID,
                                };

                                //TODO: is deze regel nog nodig, zou kunnen dat nu niks meer de directe referentie naar baseobject op de marker gebruikt.
                                marker.dataRef.baseObjectId = parseInt(clickedBaseObjectID);

                                if (this.allowSingleSelect) {
                                    //Handle the selection of the marker
                                    this.handleClickMarker([<any>fakeMarker], true);
                                }

                                //Show new infowindow, with updated selected-state for baseobjects
                                this.showInfoWindow(marker);

                                //The event is fired from inside google maps, somehow angular has problems detecting changes that way. So do it manually
                                this.cd.detectChanges();
                            };
                        baseObjectContainerDiv.appendChild(codeDiv);
                    }

                }

                containerDiv.appendChild(baseObjectContainerDiv);

                let wrapperDiv: HTMLElement = document.createElement('div');
                wrapperDiv.appendChild(containerDiv);

                this.onInfoWindowDataRequest.emit({baseObjects: baseObjectIds.map(parseInt), content: wrapperDiv});

                this.infoWindow.setContent(wrapperDiv);

                //Adjust infowindow position for heightoffset of canvas-drawn-marker when showing marker-labels
                if (this.showMarkerLabels()) {
                    this.infoWindow.set('pixelOffset', new google.maps.Size(0, MapIconGenerator.CANVAS_MARKER_HEIGHT_OFFSET));
                }
                else {
                    this.infoWindow.set('pixelOffset', new google.maps.Size(0, 0));
                }

                this.infoWindow.setPosition(marker.position);
                this.infoWindow.open(this.map, marker);


                // Wait for the info window to render, then add listeners
                setTimeout(() => {
                    // Get the content of the window
                    const infoWindowContent = <Element>this.infoWindow.getContent();

                    // Try to find the root
                    if (infoWindowContent && infoWindowContent.parentElement && infoWindowContent.parentElement.parentElement) {
                        const infoWindowDiv = infoWindowContent.parentElement.parentElement;
                        infoWindowDiv.onmouseenter =
                            () => {
                                this.preventAutoCloseInfoWindow = true;
                            };
                        infoWindowDiv.onmouseleave =
                            () => {
                                // Directly hide the info window
                                this.hideInfoWindow();
                            };
                    }
                });


            }
            else if (marker.dataRef.actionUrl) {
                this.logger.log('[MapCoreComponent] hovering over map item with URL. Marker:', marker);
            }
        });
    }

    private handleClickStartRoute(marker: LuminizerMapMarker): void {
        let arrayMapButtons = [
            {
                label: this.ts.translate('Annuleren'),
                code: ButtonCode.ANNULEREN,
                isPrimary: false,
            },
            {
                label: this.ts.translate('route.googlemaps'), code: ButtonCode.NAVIGATE_GOOGLE_MAPS,
                callback: () => {

                    //Open a google maps link
                    if (this.selectedMarkers.length > 1) {
                        const waypoints = this.selectedMarkers.map(
                            marker => {
                                return `${marker.position.lat()},${marker.position.lng()}`;
                            },
                        );
                        const navUrl = 'https://www.google.com/maps/dir/?api=1&waypoints=' + waypoints.join('|');
                        window.open(navUrl, '_blank');
                    }
                    else if (marker && marker.position) {
                        const lat = marker.position.lat();
                        const lng = marker.position.lng();
                        const navUrl = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng;
                        window.open(navUrl, '_blank');
                    }

                    this.cd.detectChanges();
                },
                isPrimary: false,
            },
            {
                label: this.ts.translate('route.applemaps'), code: ButtonCode.NAVIGATE_APPLE_MAPS,
                callback: () => {

                    //Open a google maps link
                    if (marker && marker.position) {
                        const lat = marker.position.lat();
                        const lng = marker.position.lng();
                        const navUrl = 'http://maps.apple.com/?daddr=' + lat + ',' + lng;
                        window.open(navUrl, '_self');
                    }

                    this.cd.detectChanges();
                },
                isPrimary: false,
            },
            {
                label: this.ts.translate('route.btnlocal'), code: ButtonCode.NAVIGATE_LUMINIZER,
                callback: () => {

                    this.routeMarker = marker;
                    this.enableLocationTracking();

                    this.cd.detectChanges();
                },
                isPrimary: true,
            }];

        if (!Utils.isIDevice() && !Utils.isMacintosh()) {
            for (let i = 0; i < arrayMapButtons.length; i++) {
                if (arrayMapButtons[i].code == 'NAVIGATE_APPLE_MAPS') {
                    arrayMapButtons.splice(i, 1);
                }
            }
        }

        // TODO: Maak van de buttons een array en haal conditioneel 1 item eruit
        this.globalAlertService.addPopup(this.ts.translate('route.title'),
            this.ts.translate('route.description'),
            arrayMapButtons, () => {
            });
    }

    private handleClickMarkerPopup(marker: MapItem): void {
        this.onMarkerPopupClicked.emit(marker);
    }

    private handleOpenStreetview(marker: MapItem): void {
        this.logger.log('[MapCoreComponent] ' + 'Open streetview');

        this.logger.log('[MapCoreComponent] ', marker);

        //First time to show streetview? Fix the center of the map
        if (!this.showStreetView) {
            this.showStreetView = true;
            setTimeout(() => {
                this.showMap();
            }, 100);
        }
        this.streetviewComponent.setTarget(marker);
    }

    public setInfoWindowData(mouseOverData: (TableColumn | TableCell)[][], content: HTMLElement): void {
        //Create HTML
        let rowString: string = '';
        let tableDiv: HTMLDivElement = document.createElement('div');
        tableDiv.classList.add('flex-column');
        tableDiv.classList.add('map-infowindow-table');

        if (mouseOverData[0].length > 0) {
            tableDiv.classList.add('d-flex');
            for (let i = 0; i < mouseOverData[0].length; i++) {
                const rowDiv: HTMLElement = document.createElement('span');

                rowString = '';
                for (let j = 1; j < mouseOverData.length; j++) {
                    if (mouseOverData[j][i]) {
                        rowString += (j > 1 ? ',' : '') + ' ' + (mouseOverData[j][i].label != '' ? mouseOverData[j][i].label : '-');
                    } else {
                        this.logger.log('[MapCoreComponent] [setInfoWindowData] No mouseoverdata found', );
                    }
                }

                const rowDivB = document.createElement('b');
                rowDivB.textContent = mouseOverData[0][i].label + ':';
                rowDiv.append(rowDivB, rowString);
                tableDiv.appendChild(rowDiv);
            }
        }
        else {
            tableDiv.classList.add('d-none');
        }

        //Add to window
        content.appendChild(tableDiv);
        this.infoWindow.setContent(content);
    }

    public setPOIVisible(visible: boolean): void {
        const mapStyles = [{
            elementType: 'labels',
            featureType: 'poi',
            stylers: [{
                visibility: visible ? 'on' : 'off',
            }],
        }];

        this.map.setOptions({styles: <MapTypeStyle[]>mapStyles});
    }

    private toggleHeatmapOnMap(heatmapOptionCode: string): void {
        if(heatmapOptionCode == ''){
            //Heatmap must be disabled.
            this.clearHeatmap(true);
        } else {
            this.model.isHeatmapLoading.next(true);
            setTimeout(() => { //Timeout so loading can be rendered
                const getTreeFormState = this.globalStateService.getSetTreeFormState('get', this.model.heatmapTreeCode, null);
                let mapIdFieldIndex: number = null;
                let heatmapFieldIndex: number = null;
                //Reset heatmap
                this.clearHeatmap(false);

                // Get index of mapid field and selected field from formState
                mapIdFieldIndex = getTreeFormState.tableItems[0].findIndex(_x => _x.code == "mapItemId")
                heatmapFieldIndex = getTreeFormState.tableItems[0].findIndex(_x => _x.code == heatmapOptionCode)

                //Loop trough markers in rounds to prevent browser crash for lots of markers
                const maxPerRound = 4000;
                let currentRound = 0;
                const activeMarkers = this.markers.map(marker => marker).filter(marker => !marker.dataRef.hidden);
                let totalArrayLength = activeMarkers.length;

                markerLoop:
                for (let i = (currentRound * maxPerRound), len = ((currentRound * maxPerRound) + maxPerRound) > totalArrayLength ? totalArrayLength : ((currentRound * maxPerRound) + maxPerRound); i < len; i++) {
                    const _x = activeMarkers[i];
                    const weightFieldValue: string | number = getTreeFormState.tableItems.find(_y => _y[mapIdFieldIndex].label == _x.dataRef.id)[heatmapFieldIndex].label;
                    let weightValueToUse = "";
                    if (weightFieldValue != '') { // Ignore if empty
                        if (!isNaN(Number(weightFieldValue))) { //Number
                            weightValueToUse = String(weightFieldValue).replace('-', '');
                        } else { //Datetime
                            weightValueToUse = String(moment().diff(moment(weightFieldValue, ['L', 'l', 'L LTS'])));
                        }
                        if (weightValueToUse != "") {
                            this.heatmapMarkers.push({
                                location: new google.maps.LatLng(_x.dataRef.lat, _x.dataRef.lng),
                                weight: Number(weightValueToUse)
                            });
                        }
                    }
                    _x.setVisible(false) //Hide current active Marker
                    if (i === (len - 1)) {
                        currentRound++;
                        i = (currentRound * maxPerRound) - 1;
                        if (i < totalArrayLength) {
                            len = (currentRound * maxPerRound) + maxPerRound;
                            len = len > totalArrayLength ? totalArrayLength : len;
                            continue markerLoop;
                        } else {
                            this.model.isHeatmapLoading.next(false);
                            this.logger.log('[MapCoreComponent] ' + 'Heatmap - marker loop finished');
                        }
                    }
                }

                if (this.heatmapMarkers.length > 0) {
                    this.heatmap = new google.maps.visualization.HeatmapLayer({
                        data: this.heatmapMarkers
                    });
                    this.heatmap.set("opacity", 1);
                    this.heatmap.setMap(this.map);
                    this.logger.log('[MapCoreComponent] ' + 'Heatmap - Enabled for tablefield ' + heatmapOptionCode + ' (' + this.heatmapMarkers.length + ' items)');
                }
            }, 100);
        }
    }

    private clearHeatmap(enableMarkers:boolean, clearSelectedHeatmapOption?:boolean):void{
        if(this.heatmap){
            this.heatmapMarkers = [];
            this.heatmap.setMap(null);
            this.logger.log('[MapCoreComponent] ' + 'Heatmap - Disabled');
            if(enableMarkers){
                this.markers.forEach(_x => {
                    if(!_x.dataRef.hidden){
                        _x.setVisible(true)
                    }
                });
                this.logger.log('[MapCoreComponent] ' + 'Heatmap - Disbaled. Markers enabled');
            }
        }
        if(clearSelectedHeatmapOption){
            this.heatmapOptionCode = "";
            this.logger.log('[MapCoreComponent] ' + 'Heatmap - Cleared selected heatmap option');
        }
    }



    private createMap(): google.maps.Map {
        return new google.maps.Map(this.mapContainer.nativeElement, {
            scaleControl: true, //Scale at the bottom of the map
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            mapTypeId: MapCoreComponent.MAP_TYPE_ROADMAP,
            backgroundColor: MapCoreComponent.MAP_BACKGROUND_COLOR,
            styles: [],
        });
    }

    // Get all visible baseobjects wrapped with id, label and coordinates from their mapitem
    public getBaseObjectsWithCoordinates(tmpData:TmpMapDataInterface): BaseObjectInterface[] {
        const baseObjects: BaseObjectInterface[] = [];

        // Loop over all markers and baseobjects
        if (this.markers?.length) {
            for (let i: number = 0; i < this.markers.length; i++) {
                const currentMarker = this.markers[i];
                if (currentMarker.dataRef && currentMarker.dataRef.baseObjects) {
                    for (let i = 0; i < currentMarker.dataRef.baseObjects.length; i++) {
                        const baseObject = currentMarker.dataRef.baseObjects[i];
                        // When the baseobject is visible
                        if (!baseObject.hidden) {
                            baseObjects.push({
                                id: baseObject.id,
                                label: baseObject.label,
                                lat: currentMarker.dataRef.lat,
                                lng: currentMarker.dataRef.lng,
                            });
                        }
                    }
                }
            }
        } else {
            for (let i: number = 0; i < tmpData.mapItems.length; i++) {
                const currentMarker = tmpData.mapItems[i];
                if (currentMarker && currentMarker.baseObjects) {
                    for (let i = 0; i < currentMarker.baseObjects.length; i++) {
                        const baseObject = currentMarker.baseObjects[i];
                        // When the baseobject is visible
                        if (!baseObject.hidden) {
                            baseObjects.push({
                                id: baseObject.id,
                                label: baseObject.label,
                                lat: currentMarker.lat,
                                lng: currentMarker.lng,
                            });
                        }
                    }
                }
            }
        }

        return baseObjects;
    }

    public getMarkerByBaseObjectId(baseObjectId: number|string): LuminizerMapMarker {
        let foundMarker: LuminizerMapMarker;

        for (let i = 0; i < this.markers.length; i++) {
            if (this.markers[i].dataRef?.baseObjects) {
                const baseObjects = this.markers[i].dataRef.baseObjects;
                for (let j = 0; j < baseObjects.length; j++) {
                    if (!baseObjects[j].hidden && baseObjectId == parseInt(baseObjects[j].id)) {
                        foundMarker = this.markers[i];
                        break;
                    }
                }
            }
        }

        return foundMarker;
    }

    public highlightMarkerByBaseObjectId(baseObjectId: number|string): void {
        this.highlightMarker(this.getMarkerByBaseObjectId(baseObjectId));
    }

    private highlightMarker(marker: LuminizerMapMarker | null): void {
        if (marker) {
            this.showInfoWindow(marker);
        }
        else {
            //This item has no coordinates, close infowindow
            this.hideInfoWindow();
        }
    }

    public highlightMarkerByMapItemId(mapItemId: string): void {
        //Deze functionaliteit heeft een tijd uitgestaan, maar performt nu goed:
        let foundMarker: LuminizerMapMarker | null = null;
        for (let i = 0; i < this.markers.length; i++) {
            if (this.markers[i].dataRef.id == mapItemId) {
                foundMarker = this.markers[i];
                break;
            }
        }

        if(foundMarker !== null){
            //Found in V1 items. Make sure all V2 infoWindows are closed before opening V1 infoWindow.
            this.mapCoreV2Component.closeInfoWindowForAllMapItems()
            this.highlightMarker(foundMarker);
        } else {
            //Not found in V1. Make sure all V1 infoWindows are closed, close all V2 infoWindows, find map item and show infoWindow
            this.hideInfoWindow();
            this.mapCoreV2Component.closeInfoWindowForAllMapItems()
            this.mapCoreV2Component.toggleMapItemInfoWindowById(Number(mapItemId),true)
        }
    }

    public removeMarkersByBaseObjectId(baseObjectIds: (number | string)[]): void {
        if (!this.markers || this.markers.length <= 0 || !baseObjectIds || baseObjectIds.length <= 0) {
            return;
        }

        let t0 = performance.now();
        this.logger.log('[MapCoreComponent] ' + `removing ${baseObjectIds.length} Markers By BaseObjectId`);

        if (baseObjectIds.length >= this.markers.length * 0.5) {
            baseObjectIds.forEach((idToRemove) => {
                let markerIndex;
                this.markers.some((marker, index) => {
                    if (marker?.dataRef?.baseObjects?.some(baseObject => String(idToRemove) === String(baseObject.id))) {
                        markerIndex = index;
                        marker.setVisible(false);
                        return true;
                    }
                    return false;
                });
                if (markerIndex) {
                    this.markers.splice(markerIndex, 1);
                }
                this.logger.log(this.markers.length);
            });
        } else {
            this.markers.some((marker, markerIndex) => {
                let baseObjectIdIndex;
                baseObjectIds.some((idToRemove, index) => {
                    if (marker?.dataRef?.baseObjects?.some(baseObject => String(idToRemove) === String(baseObject.id))) {
                        marker.setVisible(false);
                        this.markers.splice(markerIndex, 1);
                        baseObjectIdIndex = index;
                        return true;
                    }
                    return false;
                });
                if (baseObjectIdIndex) {
                    baseObjectIds.splice(baseObjectIdIndex, 1);
                }
                this.logger.log(baseObjectIdIndex, baseObjectIds.length);
                return baseObjectIds.length === 0;
            });
        }

        let t1 = performance.now();
        this.logger.log('[MapCoreComponent] ' + ' remove Markers By BaseObjectId took: ' + (t1 - t0) + ' milliseconds');

    }

    public appendMarkers(newMarkerData: MapItem[]): void {
        this.ngZone.runOutsideAngular(() => {
            this.logger.log('[MapCoreComponent] ' + 'Appending markers. Total new possible markers: ' + (newMarkerData ? newMarkerData.length : 0));

            this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Markers CLEANED in: ', () => {
                let mapItemId: string = '';
                for (let i = 0; i < this.markers.length; i++) {
                    mapItemId = this.markers[i].dataRef?this.markers[i].dataRef.id:null;
                    for (let j = 0; j < newMarkerData.length; j++) {
                        if (newMarkerData[j].id == mapItemId) {

                            const tempDataRef = this.markers[i].dataRef;

                            if (tempDataRef) {
                                // Update the position on the map (just setting the position in the dataRef isn't enough)
                                if (tempDataRef.lat != newMarkerData[j].lat || tempDataRef.lng != newMarkerData[j].lng) {
                                    this.markers[i].setPosition(new google.maps.LatLng(newMarkerData[j].lat, newMarkerData[j].lng));
                                }

                                // Update existing marker to prevent flickering
                                tempDataRef.icon = newMarkerData[j].icon;
                                tempDataRef.baseObjects = newMarkerData[j].baseObjects;
                                tempDataRef.label = newMarkerData[j].label;
                                tempDataRef.lat = newMarkerData[j].lat;
                                tempDataRef.lng = newMarkerData[j].lng;
                                tempDataRef.hidden = true;
                                tempDataRef.iconChanged = true;
                            }

                            // Marker exists: remove the marker from the new markers
                            newMarkerData.splice(j, 1);

                            // Remove the old marker from map and model
                            //this.markers[i].setMap(null);
                            //this.markers.splice(i, 1);
                            //i--;
                            break;
                        }
                    }
                }

                // this.mapCoreV2Component.clearAllClusterMapItems();

            }));

            // Clear references to markers with labels
            //this.markersWithLabel = [];

            //let visibleMarkerCount:number = 0;
            const functionStartTime = new Date();

            this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Markers APPENDED in: ', () => {
                    if (newMarkerData) {

                        let showLabels: boolean = this.showMarkerLabels();
                        this.logger.log('[MapCoreComponent] ' + 'append markers, showlabels: ' + showLabels);

                        let tempObject: MapItem;

                        for (let i = 0; i < newMarkerData.length; i++) {
                            tempObject = newMarkerData[i];
                            if(typeof tempObject.objectType !== 'undefined') { //Polyline or Polygon
                                this.mapCoreV2Component.appendMapItemV2(tempObject);
                            } else if (tempObject.lat) { //Marker
                                //marker.hidden || visibleMarkerCount++;

                                //Hide the marker by default. After appending, the items will be filtered. This will apply the filter over the map and make the items visible again.
                                //This is done to prevent flickering of items when appending. We cant filter the items directly here, we need the table to filter. So filtering of mapitems can only be done when the tableitems are received.
                                //This takes some time. In the mean time the mapitems were visible. With this fix they stay hidden.
                                //numvisiblemarkers isnt updated correctly right now. that is also done after filtering.
                                tempObject.hidden = true;
                                let v1Marker = this.createMarker(tempObject, showLabels);
                                this.mapCoreV2Component.appendMapItemV1(v1Marker);
                            } else {
                                //Way too much logging. Browser crash when console is open.
                                //Fix for empty array marker (instead of an object) that sometimes is found in the mapitems array
                                // this.logger.log('[MapCoreComponent] ' + '----------------------');
                                // this.logger.log('[MapCoreComponent] ' + 'REMOVED INVALID ITEM FROM MAPITEMS ARRAY: ', tempObject);
                                // this.logger.log('[MapCoreComponent] ' + '----------------------');

                            }
                        }
                        if(newMarkerData.length > 0){
                            this.showNoItemsWarning = false;
                        }
                    }
                },
            ));

            // this.showItemsOnMap();

            this.logger.log('[MapCoreComponent] ' + 'Total append marker time: ' + ((new Date() as any) - <any>functionStartTime) + ' ms');
        });
    }

    private showItemsOnMap() {
        if (!this.map) {
            return;
        }
        this.logger.log('[MapCoreClusterer] - showing items on map');

        if (this.allowClusterer && !this.disableClusterer) {
            this.logger.log('[MapCoreClusterer] - clusterer is allowed');
            this.markers.forEach(marker => marker.setMap(null));

            if (this.clusterer) {
                this.logger.log('[MapCoreClusterer] - clusterer already initialized, clearing and re-adding markers');

                // todo: update clusterer when this.markers has (possibly) changed
                this.clusterer.clearMarkers();
                this.clusterer.addMarkers(this.markers.filter(marker => marker.getVisible()));
                this.clusterer.setMap(this.map);
            } else {
                this.logger.log('[MapCoreClusterer] - initializing new clusterer');
                this.clusterer = new MarkerClusterer(this.map, this.markers, {
                    imagePath: "assets/img/cluster/_cluster",
                    clusterClass: "cluster-icon",
                    ignoreHidden: true,
                    zoomOnClick: true,
                    averageCenter: true,
                    calculator: this.clusterCalculator,
                    batchSize: 350000,  // Batchsize for processing markers, default: 2000
                    gridSize: 120,  // default: 60
                    maxZoom: 16,  // default: null, was 14
                    minimumClusterSize: 30,  // default: 2, was 30
                });
            }
        } else {
            this.logger.log('[MapCoreClusterer] - clusterer not allowed');
            if (this.clusterer) {
                this.logger.log('[MapCoreClusterer] - clusterer not allowed, setting map to null');
                this.clusterer.setMap(null);
            }
            this.logger.log('[MapCoreClusterer] - clusterer not allowed, setting map on markers');
            this.markers.forEach(marker => marker.setMap(this.map));
        }
        this.logger.log('[MapCoreClusterer] - done showing items on map');
    }

    private clusterCalculator(markers: LuminizerMapMarker[], numStyles: number): ClusterIconInfo {
        let index = 0;
        let count: number = 0;
        markers.forEach(marker => {
            count = count + marker.dataRef.baseObjects?.filter(baseObject => !baseObject.hidden).length;
        });

        let dv = count;
        while (dv !== 0) {
            dv = Math.floor(dv / 10);
            index++;
        }

        index = Math.min(index, numStyles);
        return {
            text: count.toString(),
            index: index,
            title: "",
        };
    }

    private clearRoute(): void {
        if (this.directionsDisplay) {
            this.directionsDisplay.setDirections({routes: [], request:null, geocoded_waypoints: []});
        }
    }

    //Replace the current markers with new ones. If the map is hidden, queue some actions until made visible later on
    public replaceMarkers(newObjectData: MapItem[], mapHidden: boolean = false, skipFiltering: boolean = false, zoomOnMarkers: boolean = true): void {
        this.ngZone.runOutsideAngular(() => {
            this.logger.log('[MapCoreComponent] ' + 'Replacing markers. Total new markers: ' + (newObjectData ? newObjectData.length : 0));

            this.clearRoute();

            // Clear references to markers with labels
            this.markersWithLabel = [];

            let visibleMarkerCount: number = 0;
            const functionStartTime = new Date();

            this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Markers CLEARED in: ', () => {
                    while (this.markers.length) {
                        this.markers.pop().setVisible(false);
                    }
                    this.mapCoreV2Component.clearLayers();
                },
            ));

            this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Markers CREATED in: ', () => {
                    if (newObjectData) {

                        // When zooming is off, take the label visibility of the current zoomlevel (it is not going to change)
                        // In all other cases: The next zoom level is not yet determined, the bounds of the new items are not known. Wait for the zoomToFitMarkers to zoom to the correct level and trigger a display of labels (or not)
                        let showLabels: boolean = false;
                        if (!zoomOnMarkers) {
                            showLabels = this.showMarkerLabels();
                        }

                        for (let i = 0; i < newObjectData.length; i++) {
                            const tempObject = newObjectData[i];

                            // This check (and duplicate 'addObjectToMapCoreV2') is to speed things up. 'createMarker' does filter out non marker types
                            // but it slows the process down so better to have a little duplicate code than to slow things down
                            if(typeof tempObject.objectType !== 'undefined'){ //Polyline or Polygon
                                this.mapCoreV2Component.appendMapItemV2(tempObject);
                            } else if (tempObject.lat) { //Marker
                                if (skipFiltering) {
                                    tempObject.hidden || visibleMarkerCount++;
                                }
                                else {
                                    tempObject.hidden = true;
                                }
                                let v1Marker = this.createMarker(tempObject, showLabels);
                                this.mapCoreV2Component.appendMapItemV1(v1Marker);
                            } else {
                                //Fix for empty array marker (instead of an object) that sometimes is found in the mapitems array
                                this.logger.log('[MapCoreComponent] ' + '----------------------');
                                this.logger.log('[MapCoreComponent] ' + 'REMOVED INVALID ITEM FROM MAPITEMS ARRAY: ', tempObject);
                                this.logger.log('[MapCoreComponent] ' + '----------------------');
                            }
                        }
                        if(newObjectData.length > 0){
                            this.showNoItemsWarning = false;
                        }
                    }
                },
            ));

            this.logger.log(Utils.measureFunctionTime('[MapCoreComponent] ' + 'Markers ZOOMED in: ', () => {
                if (zoomOnMarkers && this.autoZoomToFitMarkers) {
                    if (mapHidden) {
                        this.queueZoomToFitMarkers = true;
                    }
                    else {
                        this.zoomToFitAllMarkers();
                    }
                }
                else {
                    // The map bound have not changed, so trigger the check for auto load manually
                    this.tryAutoLoad();
                }
            }));

            this.numVisibleMarkers = visibleMarkerCount;

            this.logger.log('[MapCoreComponent] ' + 'Total replace marker time: ' + ((new Date() as any) - <any>functionStartTime) + ' ms');

            this.onNumMarkersChanged.emit({numVisibleMarkers: this.numVisibleMarkers});
            // this.showItemsOnMap();
        });
    }

    public zoomToFitAllMarkers(): void {
        //Zoom to fit markers on screen, or zoom to areaal center
        //Add the V1 markers and get the non V1 polylines from V2 component
        this.zoomToFitMarkers(this.markers, this.mapCoreV2Component.getAllNonV1MapItems(), this.mapCoreV2Component.getAllClusterMapItems());
    }

    //Auto zoom/pan to fit the markers on the map
    private zoomToFitMarkers(markers: LuminizerMapMarker[], v2MapItems: MapItemV2[], clusterMapItems: MapItemV2[]): void {
        setTimeout(() => {
            if (this.isMoonArea()) {
                if (this.map.getMapTypeId() == MapCoreComponent.MAP_TYPE_MOON) {
                    this.logger.log('[MapCoreComponent] ' + 'Turn moonmap off');

                    //Revert to default map and try to get map from storage
                    this.map.setMapTypeId(MapCoreComponent.MAP_TYPE_ROADMAP);
                    this.trySetStoredMapType();
                }
            }
        }, 100);

        if (markers.length > 0 || v2MapItems.length > 0 || clusterMapItems.length > 0) {
            let markersInBounds: boolean = false;
            const bounds = new google.maps.LatLngBounds();
            const numMarker: number = markers.length;

            let customCenter = null;
            for (let i = 0; i < numMarker; i++) {
                if (markers[i].getVisible() && !markers[i].dataRef.hidden) {
                    bounds.extend(markers[i].getPosition());
                    markersInBounds = true;
                    if (markers[i].dataRef.label === 'User klik'){
                        customCenter = markers[i].getPosition();
                    }
                }
            }
            //Add V2 items to bounds is visible
            v2MapItems.map(_v2MapItem => {
                if(_v2MapItem.googlePolyline.getVisible()){
                    _v2MapItem.googlePolyline.getPath().getArray().map(_point => {
                        bounds.extend(_point.toJSON());
                        markersInBounds = true;
                    })
                }
            })
            //Add cluster items to bounds is visible
            clusterMapItems.map(_clusterMapItem => {
                if(_clusterMapItem.googlePolygon && _clusterMapItem.googlePolygon.getVisible()){
                    _clusterMapItem.googlePolygon.getPath().getArray().map(_point => {
                        bounds.extend(_point.toJSON());
                        markersInBounds = true;
                    })
                }
            })

            if (markersInBounds) {

                // TODO: Hier lijkt een minibugje in te zitten (misschien zelfs in de googlemaps api) .
                // TODO: Het setten van options op de map is zelfs na een frame wachten nog niet altijd uitgevoerd. Snel klikken kan er dus voor zorgen dat er geen rekening wordt gehouden met de maxzoom van de kaart
                this.map.setOptions({maxZoom: MapCoreComponent.FOCUS_MAX_ZOOM});

                setTimeout(() => {
                    //Zoom to selected markers, or else zoom to markers, or else zoom to areaalcenter
                    this.map.fitBounds(bounds);

                    //Fix for not setting center correctly when reentering a module with a map. Resize event is triggered before boundschanged is triggered. Get the center from the bounds, not from the map. The map might not have processed it.
                    this.currentCenter = bounds.getCenter();

                    if (customCenter !== null) {
                        this.map.setCenter(customCenter);
                        this.map.setZoom(18)
                    }

                    //A delay is needed
                    setTimeout(() => {
                        this.map.setOptions({maxZoom: null}); //TODO: mocht ooit de maxZoom worden ingesteld dan moet die hier weer worden teruggezet. Voor nu revert null de waarde naar default
                    }, 50);
                });

                return;
            }
        }

        //No markers present, revert to default areaal-zoom/position
        this.centerMapOnAreaal();
    }

    private static isValidLatLng(latLng: LatLng): boolean {
        return !((latLng.lat < -90 || latLng.lat > 90) || (latLng.lng < -180 || latLng.lng > 180));

    }

    private centerMapOnAreaal(): void {
        this.logger.log('[MapCoreComponent] ' + 'Zoom to areaal center for ' + this.model.currentAreaal.getValue().label);

        if (this.map) {

            let center: LatLng = this.model.currentAreaal.getValue().center;

            if (center.lat != undefined && center.lng != undefined) {

                let tempCenter: LatLng = this.model.currentAreaal.getValue().center;

                //Check if within valid range. If not, make valid.
                if (this.isMoonArea()) {
                    this.logger.log('[MapCoreComponent] ' + 'Turn moonmap on.');
                    tempCenter = {lat: 0.3, lng: 1.4}; //Create new object, dont change the original.
                    setTimeout(() => {
                        this.map.setMapTypeId(MapCoreComponent.MAP_TYPE_MOON);
                    });
                }

                this.map.setCenter(tempCenter);
            }
            else {
                this.logger.log('[MapCoreComponent] ' + 'INVALIED AREAAL CENTER > SHOW WARNING');
                this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_WARNING, 'Ongeldig areaal-middelpunt', 'De coördinaten die zijn opgegeven als areaal-middelpunt zijn ongeldig. Ga naar <i>Areaalinstellingen</i> om de instellingen te wijzigen of vraag uw areaal administrator om dit te doen.');
            }

            this.map.setZoom(this.model.currentAreaal.getValue().zoomLevel);
        }
        else {
            this.logger.log('[MapCoreComponent] ' + 'Warning: no map present');
        }
    }

    public setZoom(zoomLevel: number): void {
        if (this.map) {
            this.map.setZoom(zoomLevel);
        }
    }

    ngOnDestroy(): void {
        this.subHandleBoundsChanged.unsubscribe();

        if (navigator.geolocation) {
            navigator.geolocation.clearWatch(this.currentLocationWatcher);
        }

        this.subscriptions.forEach(subscription => subscription.unsubscribe())

        this.mapCoreV2Component.ngOnDestroy()
    }

    handleCloseStreetview(): void {
        this.showStreetView = false;

        //Restore center after binding has triggered
        setTimeout(() => {
            this.ngZone.runOutsideAngular(() => {
                this.showMap();
            });
        }, 100);
    }

    private initSearch(): void {
        if (this.searchInput) {
            this.searchBox = new google.maps.places.SearchBox(this.searchInput.nativeElement);
            this.searchBox.addListener(MapCoreComponent.SEARCH_EVENT_PLACES_CHANGED, () => {
                this.handleClickSearchResult();
            });

            if (this.isBasicMode && this.expandSearchOnInit) {
                setTimeout(() => {
                    // Pretend to open the search
                    this.searchExpanded = true;
                    this.updateSearchField();
                }, 100);
            }
        }
    }

    private initDirections(): void {
        if (!this.showRouteButton) {
            return;
        }

        this.directionsService = new google.maps.DirectionsService;
        const options = {
            suppressMarkers: true,
            preserveViewport: true,
        };
        this.directionsDisplay = new google.maps.DirectionsRenderer(options);
        this.directionsDisplay.setMap(this.map);
    }

    private calculateAndDisplayRoute(origin: { lat: number, lng: number }, destination: MapItemInterface): void {

        if (!this.showRouteButton) {
            return;
        }

        let routeRequest: google.maps.DirectionsRequest;
        if (this.selectedMarkers.length > 1) {
            routeRequest = {
                origin: origin,
                destination: origin,
                travelMode: TravelMode.DRIVING,
                waypoints: this.selectedMarkers.map(marker => {
                    return {location: marker.position, stopover: true};
                }),
                optimizeWaypoints: true,
            };
        }
        else {
            routeRequest = {
                origin: origin,
                destination: destination,
                travelMode: TravelMode.DRIVING,
            };
        }

        this.directionsService.route(routeRequest, (response, status) => {
            if (status === 'OK') {
                this.directionsDisplay.setDirections(response);
            }
            else {
                let errorMessage: string = (this.auth.allowShowDebug() ? '<br><br>Destination Error: ' + status : '');
                this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_WARNING, this.ts.translate('route.errortitle'), this.ts.translate('route.error') + errorMessage);

                //Somehow, it needs quite some time
                setTimeout(() => {
                    this.cd.detectChanges();
                }, 500);
            }
        });
    }


    private createMapLayers(map: google.maps.Map): void {
        const osmMapType = new google.maps.ImageMapType(<google.maps.ImageMapTypeOptions>{
            getTileUrl: function (coord: MapLayerCoordinate, zoom: number) {
                return 'https://tile.openstreetmap.org/' +
                    zoom + '/' + coord.x + '/' + coord.y + '.png';
            },
            tileSize: new google.maps.Size(256, 256),
            isPng: true,
            alt: 'OpenStreetMap',
            name: 'OSM',
            maxZoom: 22
        });

        map.mapTypes.set('OSM', osmMapType);


        if (this.isMoonArea()) {
            let moonMapType = new google.maps.ImageMapType(<google.maps.ImageMapTypeOptions>{
                getTileUrl: (coord, zoom) => {
                    const normalizedCoord = MapCoreComponent.getNormalizedMoonCoord(coord, zoom);
                    if (!normalizedCoord) {
                        return null;
                    }
                    const bound = Math.pow(2, zoom);
                    return '//mw1.google.com/mw-planetary/lunar/lunarmaps_v1/clem_bw' +
                        '/' + zoom + '/' + normalizedCoord.x + '/' +
                        (bound - normalizedCoord.y - 1) + '.jpg';
                },
                tileSize: new google.maps.Size(256, 256),
                maxZoom: 9,
                minZoom: 0,
                radius: 1738000,
                name: MapCoreComponent.MAP_TYPE_MOON,
            });

            map.mapTypes.set(MapCoreComponent.MAP_TYPE_MOON, moonMapType);
            //map.setMapTypeId(MapCoreComponent.MAP_TYPE_MOON);
        }
    }

    private isMoonArea(): boolean {
        let areaCenter: LatLng = this.model.currentAreaal.value.center;

        return !MapCoreComponent.isValidLatLng(areaCenter);
    }

    // Normalizes the coords that tiles repeat across the x axis (horizontally)
    // like the standard Google map tiles.
    private static getNormalizedMoonCoord(coord: google.maps.Point, zoom: number): { x: number, y: number } {
        let y = coord.y;
        let x = coord.x;

        // tile range in one direction range is dependent on zoom level
        // 0 = 1 tile, 1 = 2 tiles, 2 = 4 tiles, 3 = 8 tiles, etc
        let tileRange = 1 << zoom;

        // don't repeat across y-axis (vertically)
        if (y < 0 || y >= tileRange) {
            return null;
        }

        // repeat across x-axis
        if (x < 0 || x >= tileRange) {
            x = (x % tileRange + tileRange) % tileRange;
        }

        return {x: x, y: y};
    }
}

