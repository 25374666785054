/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy,
    Output,
} from '@angular/core';
import {GlobalModel} from '../../../services/state/global.model';
import {TreeService} from './tree.service';
import {AppSettings} from '../../../../app.settings';
import {HTTPService} from '../../../services/http/http.service';
import {ChangeableComponent} from '../../changeable/changeable.component';
import {Subscription, BehaviorSubject} from 'rxjs';
import {TreeLMX} from './tree-lmx';
import {StorageService} from '../../../services/storage/storage.service';
import {AuthorizationService} from '../../../services/authorization/authorization.service';
import {TreeNodeLMX} from './tree-node-lmx';
import {ActivatedRoute} from '@angular/router';
import {CreateNodeObject} from './tree-node-interface';
import {LoggerService} from "../../../services/logger/logger.service";

@Component({
    selector: 'tree-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div class="card tree-component">
            <tab-bar-component class="{{collapsed?'tab-bar-collapsed':''}}" [availableModuleObjectTypes]="model.availableModuleObjectTypes" [tabData]="_currentTrees"
                               [selectedTab]="_currentTree" (onSwitchTab)="handleSwitchTab($event)"
                               (click)="handleClickTabBar()"></tab-bar-component>
            <div class="tree-node-component-wrapper {{collapsed?'hidden':''}}">
                <div class="d-flex" *ngIf="(_currentTree && _currentTree.treeNodes && !httpService.isPendingCallPath([GET_TREE_PATH])) || appending > 0">
                    <tree-search-component
                            (setIsTreeFiltering)="setIsTreeFiltering($event)"
                            (setFilterQuery)="setFilterQuery($event)"
                            [parentTreeNode]="_currentTree.treeNodes"
                            [currentActiveFilterQuery]="_currentTree.currentActiveFilterQuery"
                            style="flex:1">
                    </tree-search-component>
                    <tree-shortcuts-component></tree-shortcuts-component>
                </div>
                <ng-container
                        *ngIf="(_currentTree && _currentTree.treeNodes && !isFiltering && !httpService.isPendingCallPath([GET_TREE_PATH])) || appending > 0; else loader">
                    <ng-container *ngIf="SHOW_ROOT_COMPONENT; else hide_root">
                        <tree-node-component class="tree-component-root px-2 py-3" [tree]="tree"
                                             [node]=_currentTree.treeNodes
                                             [childNodeIcon]="childNodeIcon"
                                             [nodeActionIcon]="nodeActionIcon"
                                             [nodeActionTitleKey]="nodeActionTitleKey"
                                             [hideActionIcon]="hideActionIcon"
                                             (onSelectionChange)="handleSelectionChange($event)"
                                             (onTreeNodeAction)="handleTreeNodeAction($event)"
                                             (onTreeNodeButton)="handleTreeNodeButton($event)"
                                             (onTreeNodeCreate)="handleTreeNodeCreate($event)"
                                             [unfoldFolderClickNode]="unfoldFolderClick">
                        </tree-node-component>
                    </ng-container>
                    <ng-template #hide_root>
                        <tree-node-component class="tree-component-root px-2 py-3"
                                             *ngFor="let node of _currentTree.treeNodes.children;" [tree]="tree"
                                             [node]=node (onSelectionChange)="handleSelectionChange($event)"
                                             [childNodeIcon]="childNodeIcon"
                                             [hideActionIcon]="hideActionIcon"
                                             [nodeActionIcon]="nodeActionIcon"
                                             [nodeActionTitleKey]="nodeActionTitleKey"
                                             (onTreeNodeAction)="handleTreeNodeAction($event)"
                                             (onTreeNodeButton)="handleTreeNodeButton($event)"
                                             (onTreeNodeCreate)="handleTreeNodeCreate($event)"
                                             [unfoldFolderClickNode]="unfoldFolderClick">
                        </tree-node-component>
                    </ng-template>
                </ng-container>
                <ng-template #loader>
                    <div *ngIf="!noTreesImplemented" class="h-100 d-flex justify-content-center align-items-center">
                        <div class="loader"></div>
                    </div>
                </ng-template>
                <div class="p-4" style="white-space: normal" *ngIf="noTreesImplemented">
                    <div><b>{{"Geen trees beschikbaar" | translate}}</b></div>
                    <div>{{"Voor dit areaal zijn geen trees beschikbaar. Zonder trees werkt deze module niet naar behoren." | translate}}</div>
                </div>
            </div>
            <div *ngIf="!mobileMode" class="tree-collapse-button-container" (click)="handleClickCollapse()"
                 title="{{'In- en uitklappen' | translate}}">
                <i class="material-icons tree-collapse-button">{{collapsed ? 'keyboard_arrow_right' : 'keyboard_arrow_left'}}</i>
            </div>
        </div>
    `,
})
export class TreeComponent extends ChangeableComponent implements OnDestroy, AfterViewInit {

    public static readonly TREE_CODE_SYSTEM: string = 'SYSTEM';
    public static readonly TREE_CODE_CONTROL: string = 'CONTROL';
    public static readonly TREE_CODE_CONTROL_GATEWAYS: string = 'CONTROL_GATEWAYS';
    public static readonly TREE_CODE_CONTROL_FOLDERS: string = 'CONTROL_FOLDERS';
    public static readonly TREE_CODE_CONTROL_DIMGROEP_FOLDERS = 'DIMGROEP_FOLDERS';
    public static readonly TREE_CODE_RWS: string = 'RWS';
    public static readonly TREE_CODE_PERFECTVIEW: string = 'PERFECT_VIEW';
    public static readonly TREE_CODE_MSB_MELDING: string = 'MSB_MELDING';
    public static readonly TREE_CODE_PROJECTS_SWITCHBOX: string = 'PROJECTS_SWITCHBOX';
    public static readonly TREE_CODE_EXTERNAL_MALFUNCTION_REPORT: string = 'EXTERNAL_MALFUNCTION_REPORT';
    public static readonly DEVICE_MALFUNCTION: string = 'DEVICE_MALFUNCTION';
    public static readonly TREE_CODE_OVERRIDE_FOLDERS: string = 'OVERRIDE_FOLDERS';

    @Input() collapsed: boolean;
    @Input() module: string = '';
    @Input() counts: string[] = [];
    @Input() tree: BehaviorSubject<TreeLMX>;
    @Input() hideTree: boolean = false;
    @Input() unfoldFolderClick: boolean = false;
    @Input() childNodeIcon: string = 'place';
    @Input() nodeActionTitleKey: string = 'dimgroup.edit';
    @Input() nodeActionIcon: string = 'settings';
    @Input() hideActionIcon: boolean = false;

    @Output() onSelectionChange: EventEmitter<any> = new EventEmitter();
    @Output() onTreeNodeAction: EventEmitter<any> = new EventEmitter();
    @Output() onToggleCollapse: EventEmitter<any> = new EventEmitter();
    @Output() onSwitchTree: EventEmitter<any> = new EventEmitter();
    @Output() onTreeNodeCreate: EventEmitter<CreateNodeObject> = new EventEmitter();
    public allowSelectChildNodesOnHiddenTree: boolean = false;
    
    public isTreeLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private readonly subMobileMode: Subscription;
    private readonly subPendingCallPaths: Subscription;
    private subCurrentTrees: Subscription;
    private subCurrentTree: Subscription;
    _currentTrees: TreeLMX[] = [];
    _currentTree: TreeLMX = null;
    mobileMode: boolean = false;
    noTreesImplemented: boolean = false;
    private didExpandTreeRequest: boolean = false;
    appending: number = 0; // > 0 means is appending, <= 0 means not appending
    private selectRootNode:boolean = false;

    public isFiltering:boolean = false;

    constructor(public model: GlobalModel, private treeService: TreeService, public httpService: HTTPService, protected elementRef: ElementRef, public cd: ChangeDetectorRef, private storageService: StorageService, private auth: AuthorizationService, private route: ActivatedRoute, protected logger:LoggerService){
        super(elementRef);

        this.subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {
            this.mobileMode = value;
            this.cd.markForCheck();
        });

        this.subPendingCallPaths = this.httpService.pendingCallPaths.subscribe(() => {
            //Don't forget to unsubscribe

            //Refresh the loading state by checking the pending calls
            this.cd.markForCheck();
        });

        this.route.queryParams.subscribe(params => {
            if(params.selectRootNode){
                this.selectRootNode = params.selectRootNode;
            }
        });
    }

    //Wait until params are passed through component
    ngAfterViewInit(): void {
        this.subCurrentTrees = this.model.currentTrees.subscribe((value: TreeLMX[]) => {
            if (value) {
                //Create a copy
                this._currentTrees = value.slice();

                //Filter the copy of trees for this module
                this.treeService.filterTreesForModule(this.module, this._currentTrees);
                this.cd.markForCheck();
            }
            else {
                this.logger.log('[TreeComponent] ' + 'WARNING: no current trees');
            }
        });

        this.subCurrentTree = this.tree.subscribe((value: TreeLMX) => {

            if (value) {
                let moduleTree = {
                    module: this.module,
                    treeCode: value.code
                };
                this.storageService.setValue(StorageService.KEY_SELECTED_TREE, value.id);
                if (this.module === 'report-malfunction') {
                    this.storageService.setValue(StorageService.KEY_SELECTED_MODULE_TREE, JSON.stringify(moduleTree));
                }
            }

            this._currentTree = value;
            this.cd.markForCheck();
        });

        //When this element is constructed, get tree nodes if they don't exist
        this.treeService.loadInitialTreeNodes(this.tree, this.module, this.counts,
            () => {
                this.logger.log('[TreeComponent] Tree loaded');
                this.isTreeLoaded.next(true);
                // The tree is loaded, check if the root needs to be selected
                if (!this.didExpandTreeRequest || this.selectRootNode) {
                    this.autoSelectRootNode();
                }
                else {
                    this.logger.log('[TreeComponent] ' + 'Block auto load root item, other tree nodes will be selected through find reference');
                }

                this.cd.markForCheck();
            },
            () => {
                this.noTreesImplemented = true;
                //this.globalAlertService.addAlert(this.ts.translate("Error"), this.ts.translate("Geen trees beschikbaar"), this.ts.translate("Voor dit areaal zijn geen trees beschikbaar. Zonder trees werkt deze module niet naar behoren."));
            },
        );
    }

    // Switch to a tree. If tree already selected > wait for it to be fully loaded if not already loaded > trigger callback, else > load the tree > trigger callback.
    // So also works when current tree not loaded yet
    public switchToTree(newTreeCode: string, currentTreeId: number, switchDoneCallBack?: () => void): void {
        //Tree might not be loaded yet here, so there is no code available. Get the code yourself
        const trees = this.model.currentTrees.value;
        let matchingTreeId: number = -1;

        trees.forEach((tree: TreeLMX) => {
            if (tree.code == newTreeCode) {
                matchingTreeId = tree.id;
            }
        });

        //The desired tree is loading or available
        if (matchingTreeId == currentTreeId) {
            //When no trees are loaded, only a tree id is available. So wait for the nodes to get loaded.
            //For other instances treenodes are already available.
            let tempSubscription = this.tree.subscribe((tree: TreeLMX) => {

                //A tree is only fully loaded when nodes are available
                if (tree && tree.treeNodes) {

                    //The tree is ready, unsubscribe and trigger the callback
                    if (tempSubscription) {
                        tempSubscription.unsubscribe();
                    }

                    //If the callback triggers a change in the tree, you get a infinite loop. So put a small pause in here to prevent that
                    setTimeout(() => {
                        switchDoneCallBack();
                    });
                }
            });
        }
        else {
            //Current tree or loading tree is not the desired tree
            //Load the desired tree
            if (matchingTreeId != -1) {
                this.loadTree(matchingTreeId, false, false, false, () => {
                    //Switching is done, tree is loaded
                    switchDoneCallBack();
                });
            }
        }
    }

    // Search for the position of a baseobject in the tree, then expand the tree
    public expandTreeForBaseObject(baseObjectId: number): void {
        // Don't bother expanding if there is no tree
        if (!this._currentTree || this.hideTree || this.selectRootNode) {
            this.logger.log('[TreeComponent] ' + 'No trees > skip expanding tree');
            return;
        }

        this.didExpandTreeRequest = true;

        this.treeService.getBaseObjectPositionInTree(baseObjectId, (results) => {

            // If there is a tree, apply the result. Else, wait for the tree to fully load
            if (this.tree.value && this.tree.value.treeNodes) {
                this.logger.log('[TreeComponent] ' + 'There is a tree, apply the find reference results');
                this.applyFindReferenceResults(results);
            }
            else {
                this.logger.log('[TreeComponent] ' + 'There is no tree, wait to apply find reference results...');
                let tempSubscription = this.tree.subscribe((tree: TreeLMX) => {

                    //A tree is only fully loaded when nodes are available
                    if (tree && tree.treeNodes) {

                        //The tree is ready, unsubscribe and trigger the callback
                        if (tempSubscription) {
                            tempSubscription.unsubscribe();
                        }

                        this.logger.log('[TreeComponent] ' + 'Apply find reference results on tree');
                        this.applyFindReferenceResults(results);
                    }
                });
            }
        }, () => {
        });
    }

    public applyFindReferenceResults(results: object[]): void {
        //deselect all selected nodes
        this.treeService.deselectAllNodes(this.tree);

        const references = this.treeService.expandTreeForReferences(this._currentTree, results, true, true, true, true);

        if (references) {
            //A reference is found, act as if the tree selection changed
            this.emitSelectionChange(references, false, null, false);
        }
    }

    //Expand the tree for one or multiple treenode references. The references are supposed to come from the current tree, so no tree code checking is done.
    public expandTreeForReferences(references: TreeNodeLMX[]): void {
        // Don't bother expanding if there is no tree
        if (!this._currentTree || this.hideTree) {
            this.logger.log('[TreeComponent] ' + 'No trees > skip expanding tree');
            return;
        }

        //deselect all selected nodes
        this.treeService.deselectAllNodes(this.tree);

        this.treeService.expandTreeForReferences(this._currentTree, references, true, true, true, false);

        if (references) {
            //A reference is found, act as if the tree selection changed
            this.emitSelectionChange(references, false, null, false);
        }
    }

    handleSelectionChange(selectionData: {references: TreeNodeLMX[], treeNodes: TreeNodeLMX[]}): void {
        this.emitSelectionChange(selectionData.references, true, selectionData.treeNodes, false);
    }

    private emitSelectionChange(references: TreeNodeLMX[], selectedInTree: boolean, treeNodes: TreeNodeLMX[], appendItems: boolean): void {
        //this.lastUsedReferences = references;
        this.onSelectionChange.emit({
            references: references,
            treeNodes: treeNodes,
            selectedInTree: selectedInTree,
            treeCode: this._currentTree.code,
            appendItems: appendItems,
        });
    }

    // Act as if the selected tree nodes are selected again. Thus triggering a refresh of the list calls in most cases
    public reapplyTreeSelection(appendItems: boolean): void {
        /*if (this.lastUsedReferences){
            this.logger.log("[TreeComponent] " + "reapplyTreeSelection with lastusedreferences");
            this.emitSelectionChange(this.lastUsedReferences, false);
        }else{*/
        //No items have been selected. This might be the case when just entering a module

        //Try to get the selected items from the tree
        if (this.tree && this.tree.value && this.tree.value.treeNodes) {
            let references: TreeNodeLMX[] = [];
            let nodes: TreeNodeLMX[] = [];

            // When running in no-tree mode, pretend the root item is selected
            // (Doesn't need to be triggered when autoSelectRoot is active, the user might have changed the selected tree item to not-root)
            if (this.hideTree && !this.allowSelectChildNodesOnHiddenTree) {
                this.treeService.deselectAllNodes(this.tree);
                this.tree.value.treeNodes.selected = true;
            }

            this.treeService.getAllSelectedNodes(this.tree).forEach((node) => {
                if (node.reference != undefined) { //This is a fix for selected root items. They will leave the reference array empty instead of putting 'undefined' in the array as first item
                    references.push(node.reference);
                }
                nodes.push(node);
            });

            this.emitSelectionChange(references, false, <any>nodes, appendItems);
        }

        //}
    }

    public getExpandedNodes(): {references?: object[], nodes?: object[]} {
        let result = {};

        if (this.tree && this.tree.value && this.tree.value.treeNodes) {
            let references: TreeNodeLMX[] = [];
            let nodes: TreeNodeLMX[] = [];
            this.treeService.getAllExpandedNodes(this.tree).forEach((node) => {
                references.push(node.reference);
                nodes.push(node);
            });

            result = {references: references, nodes: nodes};
        }

        return result;
    }

    //Get the currently selected nodes
    public getSelectedNodes(): {references?: TreeNodeLMX[], nodes?: TreeNodeLMX[]} {
        let result = {};

        if (this.tree && this.tree.value && this.tree.value.treeNodes) {
            let references = [];
            let nodes = [];
            this.treeService.getAllSelectedNodes(this.tree).forEach((node) => {
                references.push(node.reference);
                nodes.push(node);
            });

            result = {references: references, nodes: nodes};
        }

        return result;
    }

    //Select by references. This won't check for matching code with the references, but tries to apply it to the current tree directly.
    // Works well with getSelectedNodes()
    // Doesn't reload the mapitems for the to be selected nodes, only applies the selection in the tree
    public selectNodesForReferences(references: object[]): void {
        this.treeService.expandTreeForReferences(this._currentTree, references, true, false, false, false);
    }

    public expandNodesForReferences(references: object[]): void {
        this.treeService.expandTreeForReferences(this._currentTree, references, false, true, false, false);
    }

    // Trigger a reload of the tree from outside this component
    // It will be a silent refresh (no loader, no scroll, nothing)
    public reload(invalidateCache: boolean = false, reloadCallBack?: () => void): void {
        // Check for empty tree. Can't re-load if no tree is present
        if (!this._currentTree) {
            return;
        }

        // Disabled the loader (this function can be run multiple times, so a simple boolean doesn't cut it)
        this.appending++;
        this.cd.detectChanges();

        this.loadTree(this._currentTree.id, true, true, invalidateCache, () => {

            // Enable the loader
            this.appending--;
            this.cd.markForCheck();

            if (reloadCallBack) {
                reloadCallBack();
            }
        });
    }

    private loadTree(treeId: number, preserveSelected: boolean, preserveExpanded: boolean, invalidateCache: boolean = false, successCallBack?: () => void): void {
        this.treeService.getTreeNodes(treeId, this.module, this.counts, invalidateCache, (tree: TreeLMX) => {

            // Store selected and expanded nodes
            const selectedNodes = preserveSelected ? this.getSelectedNodes() : {};
            const expandedNodes = preserveExpanded ? this.getExpandedNodes() : {};

            this.tree.next(tree);

            // Re-apply node state
            this.selectNodesForReferences(selectedNodes.references);
            this.expandNodesForReferences(expandedNodes.references);

            this.cd.markForCheck();
            if (successCallBack) {
                successCallBack();
            }
        });
    }

    private autoSelectRootNode(): void {
        // When auto select root node is active, select the root node of the tree
        // NOTE: When hiding the tree, map/table items are already getting loaded, so skip this
        if (!this.hideTree && this.auth.enableAutoSelectTreeRoot() && this.tree.value && this.tree.value.treeNodes) {

            //Select the root
            this.tree.value.treeNodes.selected = true;

            //Emit a selection change
            this.emitSelectionChange(<any>this.tree.value.treeNodes.reference, false, <any>this.tree.value.treeNodes, false);
        }
    }

    handleSwitchTab(tab: {id: number}): void {
        this.loadTree(tab.id, false, false, false,() => {

            // Just switched tab, selected the first node if necessary
            this.autoSelectRootNode();

            //Tree switched and loaded
            this.onSwitchTree.emit();

        });
    }

    handleClickCollapse(): void {
        this.onToggleCollapse.emit({collapsed: !this.collapsed});
    }

    handleClickTabBar(): void {
        this.onToggleCollapse.emit({collapsed: false});
    }

    handleTreeNodeAction(data: {action: string}): void {
        this.onTreeNodeAction.emit(data);
    }

    handleTreeNodeButton(data: {button: {url: string}}): void {
        this.treeService.executeTreeNodeURL(data.button.url, () => {
            //URL execution success
            this.reload();
        });
    }

    handleTreeNodeCreate(data: CreateNodeObject): void {
        this.onTreeNodeCreate.emit(data);
    }
    
    public deselectAllNodes(): void {
        this.treeService.deselectAllNodes(this.tree);
    }

    //You can't reach static/global vars from template, only local vars, so use this workaround...
    get SHOW_ROOT_COMPONENT(): boolean {
        return AppSettings.SHOW_ROOT_COMPONENT;
    }

    get GET_TREE_PATH(): string {
        return TreeService.GET_TREE_PATH;
    }

    public setIsTreeFiltering(isFiltering:boolean):void{
        this.isFiltering = isFiltering;
    }

    public setFilterQuery(filterQuery:string):void{
        this._currentTree.currentActiveFilterQuery = filterQuery
    }

    ngOnDestroy(): void {
        if (this.subMobileMode) {
            this.subMobileMode.unsubscribe();
        }
        if (this.subPendingCallPaths) {
            this.subPendingCallPaths.unsubscribe();
        }
        if (this.subCurrentTrees) {
            this.subCurrentTrees.unsubscribe();
        }
        if (this.subCurrentTree) {
            this.subCurrentTree.unsubscribe();
        }
    }
}
