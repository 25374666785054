import {ChangeDetectorRef, Component, ElementRef, Input} from "@angular/core";
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-spacer-component',
    template: `
        <div class="spacer" [style.height]="getSpacerHeight()" [style.display]="isFormEmpty?'none':'inline-block'"></div>
    `
})

export class FormSpacerComponent {

    constructor(private elementRef:ElementRef, private cd:ChangeDetectorRef, protected logger:LoggerService){}

    @Input() hasButtonBar:boolean = true;

    public isFormEmpty:boolean = true;

    @Input() set refreshTrigger(value:any)
    {
        //Hide the spacer when no form data
        this.isFormEmpty = (value == undefined || value == null);

        setTimeout(() => {
            //do nothing, except to recalculate the size
            try{
                this.cd.detectChanges();
            }catch(e){
                this.logger.log("[FormSpacerComponent] " + "Warning: trying to trigger change detection on a destroyed view");
            }
        })
    }

    //Calculate the height of the spacer that is needed to be able to scroll exactly until the top of the last formgroup on the form
    public getSpacerHeight():string
    {
        //Set default height to 100%
        let result:string = "100%";

        //Get the form
        let parentElement:any = this.elementRef.nativeElement.parentElement;
        if (parentElement) {

            //Get all formgroups
            let nodeList:NodeList = parentElement.querySelectorAll("form-group-component");
            let lastFormGroupHeight:number = 0;

            //When formgroups are found
            if (nodeList && nodeList.length > 0)
            {
                //Get the last formgroup
                let node:any = nodeList[nodeList.length - 1];

                //this.logger.log("[FormSpacerComponent] " + "node: " , node);
                //this.logger.log("[FormSpacerComponent] " + "firstElementChild: " , node.firstElementChild);
                //this.logger.log("[FormSpacerComponent] " + "height: " , node.firstElementChild.offsetHeight);

                //TODO: deze offset komt van, node.paddingbottom + node.marginbottom + offset-voor-de-button-bar + bovenliggende-node.marginBottom
                //TODO: dit wordt heel ingewikkeld (alle juiste nodes benaderen en px en rem omrekenen naar pixels ect
                //TODO: voor nu deze niet zo mooie oplossing...
                //TODO: handvaatjes voor ooit: //getComputedStyle(node) //getComputedStyle(node.firstElementChild).marginBottom

                //TODO: niet helemaal duidelijk waarom 16 minder hier

                //Use firstElementChild as Chrome fix (Chrome doesn't get the offsetHeight)
                let marginBottom:number = parseInt(window.getComputedStyle(node.firstElementChild).marginBottom, 10);
                //lastFormGroupHeight = node.firstElementChild.offsetHeight + 10 + marginBottom + 64 + (this.hasButtonBar?16:0);

                let hasSaveButton:boolean = !!document.querySelector('.form-group .default-button-container button');

                let buttonbarHeight:number = $('.form-group.form-group--mutationButtons.button-bar-fixed').outerHeight() || (10 + (hasSaveButton?69:9));
                lastFormGroupHeight = node.firstElementChild.offsetHeight + marginBottom + (this.hasButtonBar?16:0) + buttonbarHeight;

                //this.logger.log("[FormSpacerComponent] " + "buttonbar height: " , buttonbarHeight);
                //this.logger.log("[FormSpacerComponent] " + "lastFormGroupHeight: ", lastFormGroupHeight);
            }

            //this.logger.log("[FormSpacerComponent] " + "parentElement.offsetHeight: " , parentElement.offsetHeight);
            //this.logger.log("[FormSpacerComponent] " + "parentElement: " , parentElement);

            //this.logger.log("[FormSpacerComponent] " + "totaal: " + (parentElement.offsetHeight - lastFormGroupHeight));

            let spacerHeight:number = Math.max(parentElement.offsetHeight - lastFormGroupHeight, 0);

            //this.logger.log("[FormSpacerComponent] " + "spacerHeight: ", spacerHeight);

            //Set height in pixels
            result = spacerHeight + "px";
        }

        return result;
    }
}
