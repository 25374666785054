import {Component, Input} from '@angular/core';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {GlobalEvent} from '../../../../interfaces/global-event';
import {LoggerService} from "../../../../services/logger/logger.service";
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';

@Component({
    selector: 'create-device-mapping',
    templateUrl: './create-device-mapping.component.html'
})
export class CreateDeviceMappingComponent {
    @Input('config') config: any;
    public label: string = '';

    constructor(
        private ts: TranslateService,
        private model: GlobalModel,
        protected globalAlertService: GlobalAlertService,
        protected logger:LoggerService
    ) {}

    public handleClickCreateDeviceMapping() {
        this.globalAlertService.addPopupDeviceMapping(
            this.config.attr.baseObjectId,
            this.config.attr.icon,
            this.config.attr.latitude,
            this.config.attr.longitude,
            this.config.attr.showUnMapButton,
            (buttonCode: any, response: any) => {
                // Show success alert
                if(buttonCode == ButtonCode.UNMAP_DEVICE){
                    this.globalAlertService.addAlertSuccess(
                        this.ts.translate('asset-mapping.unmappingsuccesstitle'),
                        this.ts.translate('asset-mapping.unmappingsuccesssubtitle'),
                        this.ts.translate('asset-mapping.unmappingsuccesslabel',[this.config.attr.baseObjectId])
                    );
                } else {
                    this.globalAlertService.addAlertSuccess(
                        this.ts.translate('asset-mapping.mappingsuccesstitle'),
                        this.ts.translate('asset-mapping.mappingsuccesssubtitle'),
                        this.ts.translate('asset-mapping.mappingsuccesslabel',[this.config.attr.baseObjectId])
                    );
                }

                // Refresh the form and module
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MODULE_REFRESH, {invalidateCache: true}));
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REFRESH_FORM,{referenceId: this.config.attr.baseObjectId}));
            }, () => {}
        );
    }
}
