import {Component} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";

@Component({
    selector: 'form-image',
    template: `
    <div class="dynamic-field form-image" [formGroup]="group">
        <img *ngIf="hasImage()" src="{{getImage()}}" class="img-fluid">
    </div>
    `
})

export class FormImageComponent
{
    config:any;
    group:UntypedFormGroup;

    constructor() {}

    hasImage() {
        return (this.config.attr && this.config.attr.image && this.config.attr.image != "");
    }

    getImage() {
        return this.config.attr.image;
    }
}
