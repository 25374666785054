/**
 * Created by Christiaan on 13/03/2017.
 */
import { NgModule } from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import { LoginComponent } from './wrapper/login/login.component';
import { PasswordForgetComponent } from './wrapper/login/password/password-forget.component';
import { PasswordNewComponent } from './wrapper/login/password/password-new.component';
import { UserInviteComponent } from './wrapper/user-invite/user-invite-component';
import { ForbiddenComponent } from './wrapper/forbidden/forbidden.component';
import { EmptyComponent } from './shared/components/empty/empty.component';
import { PageNotFoundComponent } from './wrapper/page-not-found/page-not-found.component';
import { LoadAreaalComponent } from './wrapper/load-areaal/load-areaal.component';
import {RouteGuard} from './shared/guards/route.guard';
import {AppSettings} from './app.settings';
import {TwoFactorAuthComponent} from './wrapper/login/two-factor-auth/two-factor-auth.component';
import {CalamityControlComponent} from './modules/calamity-control/calamity-control.component';
import {LuminizerRoutes} from './shared/interfaces/routes';

// Always navigate with the routeguard to have a central route-switching-choke-point
// Add unblockable=true for routes that can always be accessed


const routes: Routes = [

    // Routing to basic wrapper components
    {path: '', pathMatch: 'full', redirectTo: LuminizerRoutes.LOGIN_PAGE},
    {path: LuminizerRoutes.LOGIN_PAGE, component: LoginComponent, canActivate: [RouteGuard], data: { unblockable: true}},
    {path: LuminizerRoutes.PASSWORD_FORGET_PAGE, component: PasswordForgetComponent, canActivate: [RouteGuard], data: { unblockable: true}},
    {path: LuminizerRoutes.PASSWORD_NEW_PAGE, component: PasswordNewComponent, canActivate: [RouteGuard], data: { unblockable: true}},
    {path: LuminizerRoutes.USER_INVITE_PAGE, component: UserInviteComponent, canActivate: [RouteGuard], data: { unblockable: true}},
    {path: LuminizerRoutes.TWO_FACTOR_AUTH_PAGE, component: TwoFactorAuthComponent, canActivate: [RouteGuard], data: { unblockable: true}},
    {path: LuminizerRoutes.FORBIDDEN_PAGE, component: ForbiddenComponent, canActivate: [RouteGuard], data: { unblockable: true}},
    {path: LuminizerRoutes.LOAD_AREAAL_PAGE, component: LoadAreaalComponent, canActivate: [RouteGuard], data: { unblockable: true}},
    // Routing to modules. Add configModule for access control per user role

    {
        path: LuminizerRoutes.ASSETS_PAGE,
        loadChildren: () => import('./modules/assets/assets.module').then(m => m.AssetsModule),
        canActivate: [RouteGuard],
        data: { configModule: LuminizerRoutes.ASSETS_PAGE}
    }, {
        path: LuminizerRoutes.GRID,
        pathMatch: 'full',
        redirectTo: '/assets/view/0?grid=1'
    }, {
    path: LuminizerRoutes.USER_MANAGEMENT_PAGE,
        loadChildren: () => import('./modules/userManagement/user-management.module').then(m => m.UserManagementModule),
        canActivate: [RouteGuard],
        data: { configModule: LuminizerRoutes.USER_MANAGEMENT_PAGE}
    }, {
        path: LuminizerRoutes.MASTER_DATA_PAGE,
        loadChildren: () => import('./modules/masterData/master-data.module').then(m => m.MasterDataModule),
        canActivate: [RouteGuard],
        data: { configModule: LuminizerRoutes.MASTER_DATA_PAGE}
    }, {
        path: LuminizerRoutes.CONTROL_PAGE,
        loadChildren: () => import('./modules/control/control.module').then(m => m.ControlModule),
        canActivate: [RouteGuard],
        data: { configModule: LuminizerRoutes.CONTROL_PAGE}
    }, {
        path: LuminizerRoutes.DEVICE_MANAGEMENT_PAGE,
        loadChildren: () => import('./modules/deviceManagement/device-management.module').then(m => m.DeviceManagementModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.DEVICE_MANAGEMENT_PAGE}
    }, {
        path: LuminizerRoutes.REPORT_MALFUNCTION_PAGE,
        loadChildren: () => import('./modules/reportMalfunction/report-malfunction.module').then(m => m.ReportMalfunctionModule),
        canActivate: [RouteGuard], data: {configModule: LuminizerRoutes.REPORT_MALFUNCTION_PAGE}
    }, {
        path: LuminizerRoutes.DEVICE_MALFUNCTION_PAGE,
        loadChildren: () => import('./modules/deviceMalfunction/device-malfunction.module').then(m => m.DeviceMalfunctionModule),
        canActivate: [RouteGuard], data: {configModule: LuminizerRoutes.DEVICE_MALFUNCTION_PAGE}
    }, {
        path: LuminizerRoutes.PROJECTS_CREATE_PAGE,
        loadChildren: () => import('./modules/projectsCreate/projects-create.module').then(m => m.ProjectsCreateModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.PROJECTS_CREATE_PAGE}
    }, {
        path: LuminizerRoutes.PROJECTS_MANAGE_PAGE,
        loadChildren: () => import('./modules/projectsManage/projects-manage.module').then(m => m.ProjectsManageModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.PROJECTS_MANAGE_PAGE}
    }, {
        path: LuminizerRoutes.PUBLIC_PORTAL_PAGE,
        loadChildren: () => import('./modules/publicPortal/public-portal.module').then(m => m.PublicPortalModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.PUBLIC_PORTAL_PAGE}
    }, {
        path: LuminizerRoutes.WORK_ORDERS_PAGE,
        loadChildren: () => import('./modules/workOrders/work-orders.module').then(m => m.WorkOrdersModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.WORK_ORDERS_PAGE}
    }, {
        path: LuminizerRoutes.STEDIN_ORDERS_PAGE,
        loadChildren: () => import('./modules/stedinOrders/stedin-orders.module').then(m => m.StedinOrdersModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.STEDIN_ORDERS_PAGE}
    }, {
        path: LuminizerRoutes.REPORT_GENERIC_PAGE,
        loadChildren: () => import('./modules/reportGeneric/report-generic.module').then(m => m.ReportGenericModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.REPORT_GENERIC_PAGE}
    }, {
        path: LuminizerRoutes.SETTINGS_PAGE,
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule)
    }, {
        path: LuminizerRoutes.IMPORT_PAGE,
        loadChildren: () => import('./modules/import/import.module').then(m => m.ImportModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.IMPORT_PAGE}
    }, {
        path: LuminizerRoutes.EXPORT_PAGE,
        loadChildren: () => import('./modules/export/export.module').then(m => m.ExportModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.EXPORT_PAGE}
    }, {
        path: LuminizerRoutes.DASHBOARD_PAGE,
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.DASHBOARD_PAGE}
    }, {
        path: LuminizerRoutes.INFORMATION_PAGE,
        loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.INFORMATION_PAGE}
    }, {
        path: LuminizerRoutes.CALAMITY_CONTROL_PAGE,
        loadChildren: () => import('./modules/calamity-control/calamity-control.module').then(m => m.CalamityControlModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.CALAMITY_CONTROL_PAGE}
    }, {
        path: LuminizerRoutes.REPORTING_PAGE,
        pathMatch: 'full',
        redirectTo: LuminizerRoutes.REPORTING_PAGE_ALIAS
    }, {
        path: LuminizerRoutes.REPORTING_PAGE_ALIAS,
        loadChildren: () => import('./modules/analysisReport/reporting.module').then(m => m.ReportingModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.REPORTING_PAGE}
    }, {
        path: LuminizerRoutes.MECHANIC,
        loadChildren: () => import('./modules/mechanic/mechanic.module').then(m => m.MechanicModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.MECHANIC}
    }, {
        path: LuminizerRoutes.NOTIFICATIONS,
        loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [RouteGuard],
        data: {configModule: LuminizerRoutes.NOTIFICATIONS}
    },

    // For every other route, existing or not. Always accessible
    { path: '**', component: PageNotFoundComponent, canActivate: [RouteGuard], data: { unblockable: true}},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {}
