<tree-map-form #treeMapFormComponent
               (onComponentEvent)="handleComponentEvent($event)">
    <div mapTableButtons class="d-flex align-items-center">
        <lumi-button *ngIf="auth.allowCreateUser()"
                     (onClick)="handleClickAdd()"
                     [label]="'usermanagement.add' | translate"
                     [title]="'usermanagement.add' | translate"
                     [icon]="'add'">
        </lumi-button>
        <lumi-button *ngIf="true || auth.allowInviteUser()"
                     (onClick)="handleClickInvite()"
                     [label]="'usermanagement.invite' | translate"
                     [title]="'usermanagement.invite' | translate"
                     [icon]="'add'">
        </lumi-button>
    </div>
</tree-map-form>
