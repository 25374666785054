export interface IControlExceptionList {
    id:number,
    name:string,
    controlProgramName:string,
    isHidden:boolean,
    isSelected:boolean,
}

export interface IControlException {
    id: number,
    name:string,
    controlProgramName?: string;
    controlProgramId:number,
    priority:number,
    type: ESelectedType,
    startDate:string,
    endDate:string,
    recurrenceNr:number,
    weekday:number,
    weekdayRelative:number,
    month:number,
    day:number
}

export enum ESelectedType{
    'one_time' = 'one_time',
    'weekly' = 'weekly',
    'monthly_date' = 'monthly_date',
    'monthly_weekday' = 'monthly_weekday',
    'yearly_date' = 'yearly_date',
    'yearly_weekday' = 'yearly_weekday',
}
