import {ChangeDetectorRef, Component, ElementRef} from '@angular/core';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {AbstractBaseFormComponent} from '../../shared/components/form/containers/form/abstract-base-form.component';
import {FormComponent} from '../../shared/components/form/containers/form/form.component';
import {FormDataService} from '../../shared/components/form/services/form-data.service';
import {RequestFailure} from '../../shared/services/http/request-failure';
import {GlobalModel} from '../../shared/services/state/global.model';
import {HTTPService} from '../../shared/services/http/http.service';
import {PopupMergeOptionInterface} from '../../wrapper/global-alert/alerts/global-popup-merge-dropdown.component';
import {GlobalEvent} from '../../shared/interfaces/global-event';
import {FormEvent} from '../../shared/components/form/containers/form/form.interface';
import {ButtonCode} from '../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'master-data-form-component',
    template: `
        <ng-container *ngIf="formData && config">
            <form-component
                    #baseFormGroup
                    [config]="config"
                    [name]="getFormName()"
                    [validationConstraints]="validationConstraints"
                    [invalidControlsErrors]="invalidControlsErrors"
                    [readOnly]="readOnly"
                    [formIsSubmitted]="formIsSubmitted"
                    (onComponentEvent)="handleComponentEvent($event)">
            </form-component>
            <!--<validation-warning [showWarning]="hasFailedConstraints()"></validation-warning>-->
        </ng-container>
    `,
})
export class MasterDataFormComponent extends AbstractBaseFormComponent {
    constructor(
        public cd: ChangeDetectorRef, public globalAlertService: GlobalAlertService, public elementRef: ElementRef,
        public ts: TranslateService, public formDataService: FormDataService, public model: GlobalModel,
        public httpService: HTTPService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, logger);
    }

    handleComponentEvent(eventData: { event: string, data: { attr: any, formData: any, referenceId: number | string } }): void {
        switch (eventData.event) {
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                    this.onComponentEvent.emit({
                        event: FormEvent.SAVE_SUCCESS,
                        data: {baseObjectId: eventData.data.referenceId},
                    });
                });
                break;

            case FormEvent.MERGE:
                this.logger.log(eventData.data);
                this.httpService.doGetRequest(`master-data/merge-list/${eventData.data.referenceId}`, (mergeList: PopupMergeOptionInterface[]) => {
                    const refId = <string>eventData.data.referenceId;
                    const itemType = refId.split('-')[0];
                    const itemId = parseInt(refId.split('-')[1]);
                    const currentItem = mergeList.find(o => o.id === itemId);
                    this.globalAlertService.addPopupMergeDropdown(
                        this.ts.translate('masterdata.mergetitle'),
                        this.ts.translate('masterdata.mergelabel'),
                        `settings/master-data/${itemType}/merge`,
                        mergeList, currentItem,
                        () => {
                            // Object is successfully moved
                            this.onComponentEvent.emit({
                                event: FormEvent.MERGE_SUCCESS,
                                data: {baseObjectId: eventData.data.formData.id},
                            });
                        }, () => {
                            // Move is canceled, or failed. Do nothing
                        });
                });
                break;
            case FormEvent.WEEKPRIJS_CREATE:
                this.globalAlertService.addPopupCreateWeekprijs(eventData.data.attr.termijnstaatId,
                    'components/weekprijs/new/',
                    'components/weekprijs/create/',
                    eventData.data.attr.dropDownData,
                    () => {
                        this.onComponentEvent.emit({
                            event: FormEvent.WEEKPRIJS_CREATE_SUCCESS,
                            data: {termijnstaatId: eventData.data.attr.termijnstaatId}
                        });
                    }, () => {
                    });
            break;
            case FormEvent.DELETE_MASTERDATA:
                this.globalAlertService.addPopup(this.ts.translate('masterdata.removetitle'), this.ts.translate('masterdata.removelabel'),
                    [{
                        label: this.ts.translate('Annuleren'),
                        code: ButtonCode.ANNULEREN,
                        isPrimary: true,
                    }, {
                        label: this.ts.translate('Verwijderen'),
                        code: ButtonCode.DELETE,
                        isPrimary: false,
                        callback: () => {
                            this.formDataService.deleteDropDownItem(`master-data/delete`, eventData.data.referenceId, () => {
                                    this.globalAlertService.addAlertSuccess(this.ts.translate('masterdata.deletetitlesuccess'), this.ts.translate('masterdata.deletesubmitsuccess'), '');
                                    this.onComponentEvent.emit({
                                        event: FormEvent.DELETE_MASTERDATA_SUCCESS,
                                        data: {baseObjectId: eventData.data.referenceId}
                                    })
                                },
                                () => {
                                },
                                () => {
                                },
                            );
                        },
                    },
                    ], () => {
                    });
        }

        this.onComponentEvent.emit(eventData);
    }

    private submitFormData(form: any, itemId: number | string, successCallBack?: () => any): void {
        if (itemId == 0) {
            this.logger.log('[MasterDataFormComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setFormDataForId(FormDataService.FORM_URL_MASTER_DATA, this.model.masterDataFormData, form, this.getFormName(), itemId,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            () => {
                this.handleSubmitResponse(null, true);
            },
        );
    }
}
