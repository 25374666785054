import {Component, OnInit, Input, ViewChild, ChangeDetectorRef, NgZone} from '@angular/core';
import {Widget} from "./widget";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {WidgetService} from "./widget.service";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {MenuService} from "../../../dynamic-menu/menu/menu.service";
import {MapTableComponent} from "../../../shared/components/map-table/map-table.component";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'map-table-widget-component',
    template: `
        <div class="card widget-panel">
            <div class="card small-widget-title-container {{widget.iconClass}}">
                <i class="material-icons md-light md-24 widget-icon">{{widget.iconName}}</i>
            </div>
            <div class="map-widget-title">
                {{widget.title}}
            </div>
            <!--<map-table-component #mapComponent [isLoading]="isLoading" class="widget-map-container" (onClickMarker)="clickMarkerHandler($event)" [style.padding-bottom]="widget.description?'0rem':'1rem'"></map-table-component>-->
            <map-table-component #mapTableComponent [allowCreateMarker]="false" [allowMultiSelect]="false" [allowMixedView]="false" [allowMarkerDrag]="false" (onSelectionChange)="handleTableMapSelectionChange($event)"></map-table-component>

            <div *ngIf="widget.description" class="map-widget-subtext-container">
                <i class="material-icons md-18 widget-subtext-icon">info_outline</i>
                <div class="widget-subtext">
                    {{widget.description}}
                </div>
            </div>
        </div>
    `
})

export class MapTableWidgetComponent implements OnInit {

    @Input('widget') public widget:Widget;
    @ViewChild('mapTableComponent', {static: false}) mapTableComponent:MapTableComponent;

    private isLoading:boolean;
    private clickMarkerHandler:Function;

    constructor(private widgetService:WidgetService, private globalAlertService:GlobalAlertService, private menuService:MenuService, private cd:ChangeDetectorRef, private zone:NgZone, protected logger:LoggerService) {}

    ngOnInit()
    {
        this.isLoading = true;
        this.widgetService.getWidgetData(this.widget.id, "",
            (json:any) => {
                this.isLoading = false;
                this.widget.title = json.title?json.title:this.widget.title;

                this.mapTableComponent.updateMapItems(json.mapItems, true);
                this.widget.description = json.description;
                this.cd.markForCheck();
            },
            (failure:RequestFailure) => {}
        );

        this.clickMarkerHandler =
            (clickData:any)=> {

                this.logger.log("[MapWidgetComponent] " + "Click marker on widget: ", clickData);

                let mapItemRef:any = clickData.markers[0].dataRef;
                let baseObjectId:number = mapItemRef.baseObjectId;
                let action:string = "";

                if (mapItemRef.actionUrl) {
                    action = mapItemRef.actionUrl;
                } else if(mapItemRef.baseObjects) {
                    mapItemRef.baseObjects.forEach((baseObject:any) => {
                        if (baseObject.id == baseObjectId){
                            if (baseObject.actionUrl && baseObject.actionUrl != ""){
                                action = baseObject.actionUrl;
                            }
                        }
                    });
                } else {
                    this.logger.log('[MapWidgetComponent] no action url found in map item and/or base object');
                }

                if (action !== ""){

                    //Run in zone, so the following actions trigger change detection
                    this.zone.run(() => {
                        this.widgetService.handleClickItem(action);
                    });
                }
            };

        //Handle invalid data
        if (!this.widget.iconClass || !this.widget.iconName || this.widget.iconName == "" || this.widget.iconClass == ""){
            this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_ERROR, "Unknown widget icon", "Voor small-widget-type [" + this.widget.code + "] is geen icon informatie gedefinieerd");
        }

        this.cd.markForCheck();
    }

    public handleTableMapSelectionChange(event:Event){

    }
}
