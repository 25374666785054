import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {TreeMapFormComponent} from '../../../shared/components/tree-map-form/tree-map-form.component';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {GlobalEvent} from '../../../shared/interfaces/global-event';
import {FormEvent} from '../../../shared/components/form/containers/form/form.interface';
import {LumiSelectOption} from '../../../shared/components/commonUI/select/lumi-select/lumi-select.interface';
import {Filter, FilterCommand} from '../../../shared/components/table/filterableTable/filter.interface';
import {MechanicFilter} from './mechanic.interface';
import {Subscription} from 'rxjs';
import {UserRoles} from '../../../shared/interfaces/user.interface';
import {StorageService} from '../../../shared/services/storage/storage.service';
import {TreeMapFormEvent} from '../../../shared/components/tree-map-form/tree-map-form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";
import {TranslateService} from '../../../shared/services/translate/translate.service';

@Component({
    selector: 'mechanic',
    templateUrl: './mechanic.component.html'
})
export class MechanicComponent implements OnInit, AfterViewInit, OnDestroy {
    public static readonly MODULE_PATH_MECHANIC: string = 'mechanic';
    public readonly FORM_URL: string = '/mechanic/view';
    public readonly BATCHUPDATE_URL: string = 'mechanic/batch-update/get';
    
    @ViewChild('treeMapFormComponent', {static: false}) treeMapForm: TreeMapFormComponent;
    
    public mechanicSelectOptions: LumiSelectOption[];
    public activeMechanicFilter: MechanicFilter = MechanicFilter.CURRENT;
    public MECHANIC_FILTER = MechanicFilter;
    private subscriptions: Subscription[] = [];
    private selectedMechanicId: number;
    private initialized: boolean = false;
    private readonly MECHANIC_OPTION_SHOW_ALL_ID = -2;
    
    constructor(
        public model: GlobalModel,
        private storageService: StorageService,
        protected logger:LoggerService,
        public ts:TranslateService
    ) {
    }
    
    ngOnInit(): void {
        if (this.storageService.keyExists(StorageService.KEY_MECHANIC_FILTER)) {
            this.storageService.getStringValue(StorageService.KEY_MECHANIC_FILTER, value => {
                this.activeMechanicFilter = value as MechanicFilter;
            });
        }
    }
    
    ngAfterViewInit(): void {
        this.treeMapForm.autoloadTreeRootOnHiddenTree = false;
        this.treeMapForm.setTreeMapFormSettings(
            'mechanic_1',
            'mechanic_2',
            ['mechanic'],
            MechanicComponent.MODULE_PATH_MECHANIC,
            ['mechanic'],
            this.FORM_URL,
            this.BATCHUPDATE_URL,
            FormDataService.FORM_URL_MECHANIC,
            this.model.mechanicSelectedItems,
            this.model.mechanicFormData,
            this.model.mechanicFormCollapsed,
            this.model.mechanicTreeCollapsed,
            this.model.mechanicMapItems,
            this.model.mechanicAutoLoadedMapItems,
            this.model.mechanicAutoLoadedTableItems,
            this.model.mechanicTableItems,
            this.model.mechanicFilterString,
            this.model.mechanicTree,
            {
                iconSet: 'mechanic'
            }
        );
        this.treeMapForm.viewInit();
        
        this.addMechanicOptions();
        this.applyMechanicSelection();
    }
    
    private addMechanicOptions(): void {
        this.subscriptions.push(this.model.currentTree.subscribe(tree => {
            this.logger.log('[Mechanic] current tree', tree);
            if (tree?.code === 'MECHANIC' && tree?.treeNodes?.children?.length > 0) {
                this.mechanicSelectOptions = tree.treeNodes.children.map(treeNode => {
                    return {
                        name: treeNode.label,
                        id: treeNode.reference.mechanic,
                        italic: treeNode.reference.mechanic < 0
                    };
                });
                this.mechanicSelectOptions.unshift({
                    name: '...',
                    id: this.MECHANIC_OPTION_SHOW_ALL_ID,
                    italic: true
                });
                this.setInitialSelectedMechanic();
            }
        }));
    }
    
    private setInitialSelectedMechanic(): void {
        if (this.initialized) {
            return;
        }
        this.initialized = true;
        this.logger.log('[MechanicComponent] selecting initial mechanic');
        if (this.storageService.keyExists(StorageService.KEY_SELECTED_MECHANIC)) {
            this.storageService.getNumberValue(StorageService.KEY_SELECTED_MECHANIC, result => {
                this.logger.log(`[MechanicComponent] mechanic id ${result} found in storage`);
                if (this.mechanicSelectOptions?.some(option => option.id === result)) {
                    this.logger.log(`[MechanicComponent] mechanic id ${result} is an available option`);
                    this.setSelectedMechanic(result);
                } else {
                    this.logger.log(`[MechanicComponent] mechanic id ${result} is not an available option`);
                    this.setDefaultMechanic();
                }
            });
        } else {
            this.logger.log(`[MechanicComponent] no mechanic id found in storage`);
            this.setDefaultMechanic();
        }
    }
    
    private setDefaultMechanic(): void {
        this.logger.log(`[MechanicComponent] setting default mechanic`);
        if (this.hasUserMechanicRole()) {
            this.logger.log(`[MechanicComponent] user is Mechanic, setting default to user`);
            this.setSelectedMechanic(this.model.user.getValue().userId);
        } else {
            this.logger.log(`[MechanicComponent] user is not Mechanic, setting default to show all`);
            this.setSelectedMechanic(this.MECHANIC_OPTION_SHOW_ALL_ID);
        }
    }
    
    private setSelectedMechanic(id: number): void {
        this.selectedMechanicId = id;
        this.storageService.setValue(StorageService.KEY_SELECTED_MECHANIC, this.selectedMechanicId);
        this.selectTreeNodeForSelectedMechanicId();
    }
    
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    
    public handleComponentEvent(eventData: any): void {
        switch (eventData.event) {
            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                const polygonSelect = this.treeMapForm.mapTableComponent.mapComponent.polygonSelect;
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect();
                }
                this.treeMapForm.clearSelectedItems();
                break;
            case FormEvent.WORKORDER_DELETE_SUCCESS:
            case FormEvent.BATCH_DELETE_SUCCESS:
                this.treeMapForm.reloadTree();
                this.treeMapForm.refreshMapTableItems(this.treeMapForm.getSelectedItemIds());
                this.treeMapForm.clearSelectedItems();
                break;
            case FormEvent.WORK_PREPARATION_CREATE_SUCCESS:
            case FormEvent.WORK_PREPARATION_DELETE_SUCCESS:
            case FormEvent.WORK_PREPARATION_UPDATE_SUCCESS:
            case FormEvent.WORK_ACTIVITY_CREATE_SUCCESS:
            case FormEvent.WORK_ACTIVITY_DELETE_SUCCESS:
            case FormEvent.WORK_SPECIFICATION_CREATE_SUCCESS:
            case FormEvent.WORK_SPECIFICATION_DELETE_SUCCESS:
            case FormEvent.ACTIVITY_CREATE_SUCCESS:
            case FormEvent.ACTIVITY_DELETE_SUCCESS:
                this.treeMapForm.refreshMapTableItems(this.treeMapForm.getSelectedItemIds());
                this.treeMapForm.getForm(eventData.data.referenceId);
                break;
            case FormEvent.COMMENT_ADD_SUCCESS:
            case FormEvent.COMMENT_DELETE_SUCCESS:
            case FormEvent.ATTACHMENT_ADD_SUCCESS:
            case FormEvent.ATTACHMENT_DELETE_SUCCESS:
                this.treeMapForm.refreshMapTableItems(this.treeMapForm.getSelectedItemIds());
                break;
            case FormEvent.SAVE_SUCCESS:
            case FormEvent.BATCH_SAVE_SUCCESS:
                if (eventData?.data?.referenceId > 0) {
                    this.treeMapForm.getForm(eventData.data.referenceId);
                }
                this.treeMapForm.reloadTree();
                this.treeMapForm.refreshMapTableItems(this.treeMapForm.getSelectedItemIds());
                break;
            case TreeMapFormEvent.TREE_NODE_SELECTED: {
                if (eventData?.data?.selectionData?.references?.length > 0 && eventData.data.selectionData.references[0].mechanic) {
                    this.selectedMechanicId = eventData.data.selectionData.references[0].mechanic;
                } else {
                    this.selectedMechanicId = -2;
                }
                this.storageService.setValue(StorageService.KEY_SELECTED_MECHANIC, this.selectedMechanicId);
                break;
            }
            case TreeMapFormEvent.FILTER_RESET: {
                this.activeMechanicFilter = MechanicFilter.ALL;
                this.storageService.setValue(StorageService.KEY_MECHANIC_FILTER, this.activeMechanicFilter);
                break;
            }
        }
    }
    
    public getSelectedMechanicOptions(): LumiSelectOption[] {
        return this.mechanicSelectOptions.filter(option => option.id === this.selectedMechanicId);
    }
    
    public setMechanic($event: LumiSelectOption[]): void {
        this.setSelectedMechanic(Number($event[0].id));
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_MODULE_REFRESH, {}));
    }
    
    public handleClickMechanicFilter(mechanicFilter: MechanicFilter) {
        this.activeMechanicFilter = mechanicFilter;
        this.applyMechanicSelection();
        this.storageService.setValue(StorageService.KEY_MECHANIC_FILTER, this.activeMechanicFilter);
    }
    
    protected handleGlobalEvent(event: GlobalEvent): void {
        if (event.type === GlobalEvent.EVENT_LOAD_FORM_REQUEST) {
            if (event.data.url) {
                // When switching entities on the batchupdate form
                this.treeMapForm.getBatchUpdateForm(event.data.url);
            }
        }
    }
    
    private applyMechanicSelection() {
        const filters: Filter[] = [];
        switch (this.activeMechanicFilter) {
            case MechanicFilter.ALL:
                this.treeMapForm.clearMapTableFilters();
                return;
            default :
                filters.push({
                    tableColumnCode: 'datumPlanningMonteur',
                    filterCommand: FilterCommand.TODAY_OR_PAST_DAYS,
                    filterValues: []
                });
                filters.push({
                    tableColumnCode: 'subStatus',
                    filterCommand: FilterCommand.NOT_CONTAINS,
                    filterValues: ['attentie']
                });
        }
        this.treeMapForm.applyMapTableFilters(filters);
    }
    
    private hasUserMechanicRole(): boolean {
        return this.model.getUserRoleCodes().some(role => role === UserRoles.MONTEUR);
    }
    
    private selectTreeNodeForSelectedMechanicId(): void {
        this.logger.log('[MechanicComponent] selecting tree node for mechanic id ', this.selectedMechanicId);
        if (this.model.currentTree?.getValue()?.treeNodes?.children?.length > 0) {
            this.treeMapForm.treeComponent.allowSelectChildNodesOnHiddenTree = true;
            this.treeMapForm.treeComponent.deselectAllNodes();
            if (this.selectedMechanicId === -2 ) {
                this.treeMapForm.treeComponent.tree.value.treeNodes.selected = true;
            } else {
                this.treeMapForm.treeComponent.selectNodesForReferences([{mechanic: this.selectedMechanicId}]);
            }
            this.treeMapForm.treeComponent.reapplyTreeSelection(false);
            this.treeMapForm.treeComponent.cd.detectChanges();
        }
    }
    
    public breakTopBar(): boolean {
        return this.treeMapForm?.mapTableComponent?.breakTopBar();
    }
}
