
import {ChangeDetectorRef, Component, ElementRef, EventEmitter, NgZone, Output} from "@angular/core";
import {AbstractBaseFormComponent} from "../../shared/components/form/containers/form/abstract-base-form.component";
import {RequestFailure} from "../../shared/services/http/request-failure";
import {HTTPError} from "../../shared/services/http/http-error";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";
import {FormComponent} from "../../shared/components/form/containers/form/form.component";
import {GlobalModel} from "../../shared/services/state/global.model";
import {FormDataService} from "../../shared/components/form/services/form-data.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {AbstractObjectFormComponent} from "../../shared/components/form/containers/form/abstract-object-form.component";
import {GlobalEvent} from "../../shared/interfaces/global-event";
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {ButtonCode} from '../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'user-management-form-component',
    template : `        
    <ng-container *ngIf="formData && config"> 
        <form-component
                #baseFormGroup
                [config]="config"
                [name]="getFormName()"
                [validationConstraints]="validationConstraints"
                [invalidControlsErrors]="invalidControlsErrors"
                [readOnly]="readOnly"
                [formIsSubmitted]="formIsSubmitted"
                (onComponentEvent)="handleComponentEvent($event)">
        </form-component>
        <!--<validation-warning [showWarning]="hasFailedConstraints()"></validation-warning>-->
    </ng-container>
    `
})

export class UserManagementFormComponent extends AbstractObjectFormComponent {

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected zone:NgZone, protected elementRef:ElementRef, public model:GlobalModel, protected ts:TranslateService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, model, logger);
    }
    
    public handleComponentEvent(eventData:any):void{

        switch (eventData.event){
            case FormEvent.DELETE_USER:


                    // TODO: vertalingen
                    this.globalAlertService.addPopup(this.ts.translate("usermanagement.delete"), this.ts.translate("usermanagement.deletewarning"),
                        [
                            {
                                label: this.ts.translate("Annuleren"),
                                code: ButtonCode.ANNULEREN,
                                isPrimary: true
                            },
                            {
                                label: this.ts.translate("Verwijderen"), code: ButtonCode.DELETE,
                                callback: () => {
                                    this.submitUserDelete(eventData.data.url);
                                }, isPrimary: false
                            }
                        ], () => {
                        });

                break;
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                    this.onComponentEvent.emit({event:FormEvent.SAVE_SUCCESS, data:{baseObjectId:eventData.data.referenceId}});
                });
                break;
            case FormEvent.DELETE_BASEOBJECT:
                //Click from delete button on baseobject-form (not the tree)
                this.logger.log("[UserManagementFormComponent] " + "Delete baseobject via form");
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_BASEOBJECT, {}));
                break;
            case FormEvent.BATCH_DELETE:
                this.globalAlertService.addPopup(this.ts.translate("Items verwijderen"), this.ts.translate("Wilt u de geselecteerde items verwijderen of archiveren?"),
                    [
                        {label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                        {label:this.ts.translate("Archiveren"), code:ButtonCode.ARCHIVE,
                            callback:() => {
                                this.submitBatchDelete(this.model.userManagementSelectedItems.value, eventData.data.url, true, () => {
                                    this.handleBatchDeleteSuccess();
                                });
                            },
                            isPrimary:false},
                        {label:this.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                            callback:() => {
                                this.submitBatchDelete(this.model.userManagementSelectedItems.value, eventData.data.url, false, () => {
                                    this.handleBatchDeleteSuccess();
                                });
                            },
                            isPrimary:false},
                        ], () => {});
                break;

            case FormEvent.BATCH_SAVE:
                this.submitBatchUpdate(this.model.userManagementSelectedItems.value, eventData.data.formData, eventData.data.url, () => {
                    this.handleBatchUpdateSuccess();
                });
                break;
            /*case FormEvent.MOVE_MAPITEM:
                this.globalAlertService.addPopupMoveItem(eventData.data.mapItem,
                    () => {
                        //Object is successfully moved
                        this.onComponentEvent.emit({event:FormEvent.MOVE_MAPITEM_SUCCESS, data:{mapItemId:eventData.data.mapItem}});
                    }, () => {
                        //Move is canceled, or failed. Do nothing
                    });
                break;*/
            // case FormEvent.COPY_BASEOBJECT:
            //     this.globalAlertService.addPopupCopyBaseObject(eventData.data.mapItem,
            //         (button:any, data:any) => {
            //             //Object is successfully copied
            //             this.onComponentEvent.emit({event:FormEvent.COPY_BASEOBJECT_SUCCESS, data:{baseObjectId:data.baseObjectId}});
            //         }, () => {
            //             //Copy is canceled, or failed. Do nothing
            //         });
            //     break;
        }

        this.onComponentEvent.emit(eventData);
    }

    private submitBatchUpdate(items:any[], form:any, url:string, successCallBack?:() => any):void
    {
        if (!items || items.length == 0){
            this.logger.log("[UserManagementFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setBatchUpdateFormData(this.model.userManagementFormData, url, form, this.getFormName(), items,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitBatchDelete(items:any[], url:string, archive:boolean = true, successCallBack?:() => any):void
    {
        if (!items || items.length == 0){
            this.logger.log("[UserManagementFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.batchDelete(url, items, archive,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitFormData(form: any, itemId:number, successCallBack?:() => any):void
    {
        if (itemId == 0){
            this.logger.log("[UserManagementFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setFormDataForId(FormDataService.FORM_URL_USER_MANAGEMENT, this.model.userManagementFormData, form, this.getFormName(), itemId,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitUserDelete(url:string):void
    {
        this.handleSubmitForm();

        this.formDataService.deleteUser(url,
            () => {
                this.handleSubmitResponse();
                // TODO: vertalingen
                this.globalAlertService.addAlertSuccess(this.ts.translate('usermanagement.deletesuccesstitle'), this.ts.translate('usermanagement.deletesuccesstext' ), '');
                this.onComponentEvent.emit({event:FormEvent.DELETE_USER_SUCCESS});
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }
}
