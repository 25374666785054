/**
 * Created by Christiaan on 15/03/2017.
 */

//TODO: naarmate er meer functionaliteit beschikbaar komt kan deze class worden opgeslitst in meerdere logische models

import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {User} from '../../../wrapper/login/user';
import {Dashboard} from '../../../modules/dashboard/dashboard';
import {Areaal} from '../../interfaces/areaal';
import {GlobalAlert} from '../../../wrapper/global-alert/global-alert';
import {MenuItem} from '../../../dynamic-menu/menu/menu-item/menu-item';
import {ISearchGroups} from '../../../dynamic-menu/menu/menu-search/menu-search.interface';
import {TreeLMX} from '../../components/commonUI/tree/tree-lmx';
import {MapItem} from '../../components/map/map-item/map-item';
import MapWMSLayerGenerator from '../../utils/map-wmslayer-generator';
import {GlobalPopup} from '../../../wrapper/global-alert/global-popup';
import {GlobalEvent} from '../../interfaces/global-event';
import {IMyDate} from 'angular-mydatepicker';
import {INotifications} from "../../../modules/notifications/notifications.interface";
import {IHistoryList} from "../../../dynamic-menu/menu/menu-history/history.interface";
import {TableOptionsSet} from "../../components/table/tableColumnSelector/table-options";
import {IMarkerLabelOverride, ITreeTableFormState} from "./global-state.interface";
import {IMapItemLayers, IMapItemStyles} from "../../components/map/managers/map-manager.interface";
import {ILocalizationElement} from "../../components/localization/localization.interface";
import {ITreeShortcut} from "../../components/commonUI/tree/tree-shortcuts.interface";

@Injectable()
export class GlobalModel {

    //Areaal
    public user: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    public currentDashboard: BehaviorSubject<Dashboard> = new BehaviorSubject<Dashboard>(new Dashboard());
    public dashboardItems: MenuItem[] = []; //Does not influence the view, no observable needed
    public dashboardAutoRefresh: boolean = true; //Does not influence the view, no observable needed
    public arealen: BehaviorSubject<Areaal[]> = new BehaviorSubject<Areaal[]>([]);
    public recentArealen: BehaviorSubject<Areaal[]> = new BehaviorSubject<Areaal[]>([]);
    public currentAreaal: BehaviorSubject<Areaal> = new BehaviorSubject<Areaal>(null);
    public userManualPath: string; //Does not influence the view, no observable needed
    public mainMenuItems: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
    public searchGroups: BehaviorSubject<ISearchGroups[]> = new BehaviorSubject<ISearchGroups[]>([]);
    public rightSideMenuItems: BehaviorSubject<MenuItem[]> = new BehaviorSubject<MenuItem[]>([]);
    public authorisations: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public mapItemLayers: BehaviorSubject<IMapItemLayers[]> = new BehaviorSubject<IMapItemLayers[]>([]);
    public mapItemStyles: BehaviorSubject<IMapItemStyles[]> = new BehaviorSubject<IMapItemStyles[]>([]);
    public locale: BehaviorSubject<string> = new BehaviorSubject<string>('nl-NL');
    //public newMenuStyle:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    //Forms
    public storedStartDates: IMyDate[] = [];
    public storedEndDates: IMyDate[] = [];
    public formLocationComponentPosition: any = null;
    public formBushItemTreeExpanded: boolean = true;
    public lastSelectedDimmingSchemeDays: number[] = [0]; // Remember the last selected day for the dimming scheme forms
    public lastSelectedGraphTab: number = 0; // Remember the last selected tab for graphs
    public sliderPositions: any = {}; //Storage van slider posities. Hoeft niet als event behandeld te worden
    public currentSelectedItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public currentSelectedClusterItems: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public currentCustomSort: string = 'Code';
    public currentCustomSortDirection: number = 1;

    //TODO: veel herhaling hier
    //Device management
    public deviceManagementFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public deviceManagementSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public deviceManagementFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public deviceManagementFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public deviceManagementTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public deviceManagementTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public deviceManagementMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public deviceManagementTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);
    public deviceManagementAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public deviceManagementAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();

    //Assets
    public assetsFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public assetsSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public assetsFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public assetsFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public assetsTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public assetsTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public assetsMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public assetsAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public assetsAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public assetsTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);
    public mapMarkerLabelOverride:IMarkerLabelOverride = null;

    //User management
    public userManagementFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public userManagementSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public userManagementFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public userManagementFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public userManagementTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public userManagementTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public userManagementMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public userManagementAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public userManagementAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public userManagementTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);

    //Control
    public controlFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public controlSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public controlFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public controlFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public controlTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public controlTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public controlMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public controlTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);
    public controlAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public controlAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();

    //Report malfunction
    public reportMalfunctionFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public reportMalfunctionSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public reportMalfunctionFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public reportMalfunctionFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public reportMalfunctionTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public reportMalfunctionTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public reportMalfunctionMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public reportMalfunctionAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public reportMalfunctionAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public reportMalfunctionTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);

    //Device malfunction
    public deviceMalfunctionFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public deviceMalfunctionSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public deviceMalfunctionFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public deviceMalfunctionFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public deviceMalfunctionTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public deviceMalfunctionTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public deviceMalfunctionMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public deviceMalfunctionAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public deviceMalfunctionAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public deviceMalfunctionTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);

    //Report Generic
    public reportGenericFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public reportGenericSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public reportGenericFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public reportGenericFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public reportGenericTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public reportGenericTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public reportGenericMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public reportGenericAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public reportGenericAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public reportGenericTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);

    //Projects create
    public projectsCreateFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public projectsCreateSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public projectsCreateFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public projectsCreateFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public projectsCreateTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public projectsCreateTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public projectsCreateMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public projectsCreateAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public projectsCreateAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public projectsCreateTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);

    //Projects create
    public projectsManageFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public projectsManageSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public projectsManageFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public projectsManageFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public projectsManageTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public projectsManageTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public projectsManageMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public projectsManageAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public projectsManageAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public projectsManageTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);

    //Public Portal
    public publicPortalFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public publicPortalSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public publicPortalFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public publicPortalFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public publicPortalTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public publicPortalTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public publicPortalMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public publicPortalAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public publicPortalAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public publicPortalTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);
    public publicPortalWelcomeText: string;

    //Work orders
    public workOrdersFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public workOrdersSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public workOrdersFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public workOrdersFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public workOrdersTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public workOrdersTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public workOrdersMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public workOrdersAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public workOrdersAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public workOrdersTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);

    //Mechanic
    public mechanicFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public mechanicSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public mechanicFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public mechanicFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public mechanicTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public mechanicTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public mechanicMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public mechanicAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public mechanicAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public mechanicTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);

    //Stedin orders
    public stedinOrdersFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public stedinOrdersSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public stedinOrdersFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public stedinOrdersFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public stedinOrdersTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public stedinOrdersTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public stedinOrdersMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public stedinOrdersAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public stedinOrdersAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public stedinOrdersTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);

    // Master data
    public masterDataTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public masterDataTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public masterDataFormCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public masterDataMapItems: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public masterDataAutoLoadedMapItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public masterDataAutoLoadedTableItems: Subject<MapItem[]> = new Subject<MapItem[]>();
    public masterDataTableItems: BehaviorSubject<MapItem[]> = new BehaviorSubject<any>(null);
    public masterDataFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public masterDataSelectedItems: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
    public masterDataFilterString: BehaviorSubject<string> = new BehaviorSubject<string>('');

    //Settings & user profile
    public userProfileFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public importFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public exportFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public areaSettingsFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public userSettingsFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public authSettingsFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public reportingTilesFormData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    //Notifications
    public notificationList: INotifications[] = [];
    public notificationModalList: INotifications[] = [];
    public hasNewNotifications: boolean = false;
    public hasModalNotifications: boolean = false;

    //Global search
    public activeSearchGroup:ISearchGroups = null;

    //History
    public historyList:IHistoryList[] = [];

    //Grid draw mode
    public gridDrawModeActive:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    //Tree / Map
    public clusterColorList:string[] = [];
    public treeShortcuts:BehaviorSubject<ITreeShortcut[]> = new BehaviorSubject<ITreeShortcut[]>([])
    public tableColumnOptions: TableOptionsSet[] = [];


    //Translate
    // public localizationElements:ILocalizationElement[] = [];
    public localizationElements: BehaviorSubject<ILocalizationElement[]> = new BehaviorSubject<ILocalizationElement[]>([]);

    //Heatmap
    public heatmapValueOptions: TableOptionsSet[] = [];
    public heatmapTreeCode:string = "";
    public heatmapTableDataBackup: ITreeTableFormState; //For when state is disabled for certain modules.
    public isHeatmapLoading:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    //Other
    public currentTrees: BehaviorSubject<TreeLMX[]> = new BehaviorSubject<TreeLMX[]>([]);
    public globalAlerts: BehaviorSubject<GlobalAlert[]> = new BehaviorSubject<GlobalAlert[]>(null);
    public globalPopups: BehaviorSubject<GlobalPopup[]> = new BehaviorSubject<GlobalPopup[]>(null);
    public mobileMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public tabletMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public laptopMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public publicPortalMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public publicPortalArea: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public showMobileStateBackButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); //true if back-button is visible to navigate
    public mobileStateBack: BehaviorSubject<number> = new BehaviorSubject<number>(null); //Trigger/event to go back in states. Values is amount of steps to go back. Also does something with the initial value, so no Subject
    public currentModule: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public currentTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public changeLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(''); //Trigger/event after language change. Also does something with the initial value, so no Subject
    public tableOptionsSets: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public idleLogout: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public changedSmartSelection: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loadUserMapLayer: BehaviorSubject<{geoJson: string, fileName: string}> = new BehaviorSubject<{geoJson: string, fileName: string}>(null);
    public deleteUserMapLayer: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public additionalLayerList: string[] = [];
    public streetSmartWarningShown: boolean = false;
    public availableModuleObjectTypes:string[] = [];
    public currentCallIsTableBBox: boolean = false;


    // Reporting
    public reportingTree: BehaviorSubject<TreeLMX> = new BehaviorSubject<TreeLMX>(null);
    public reportingTreeCollapsed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    //Triggers/events
    //TODO: de specifieke event kunnen ook naar global event worden omgeschreven
    public onFormResize: Subject<string> = new Subject<string>(); //Event trigger

    // Use this event structure only for 'small' updates, i.e. don't dispatch to hundreds of components. Every emit will be registered by every listener, so keep the amount of listeners low.
    public onGlobalEvent: Subject<GlobalEvent> = new Subject<GlobalEvent>(); //Event trigger > send global events that can be subscribed to anywhere

    constructor() {
    }

    //NOTE: clear all data specific to an area, like selected items or drop-downs
    public clearAreaData() {
        this.assetsTree.next(null);
        this.controlTree.next(null);
        this.deviceManagementTree.next(null);
        this.reportMalfunctionTree.next(null);
        this.reportGenericTree.next(null);
        this.projectsCreateTree.next(null);
        this.projectsManageTree.next(null);
        this.workOrdersTree.next(null);
        this.stedinOrdersTree.next(null);
        this.mechanicTree.next(null);
        this.currentTrees.next(null);
        this.currentTree.next(null);
        this.assetsMapItems.next(null);
        this.controlMapItems.next(null);
        this.assetsTableItems.next(null);
        this.controlTableItems.next(null);
        this.deviceManagementMapItems.next(null);
        this.deviceManagementTableItems.next(null);
        this.deviceManagementFormData.next(null);
        this.reportMalfunctionMapItems.next(null);
        this.reportMalfunctionTableItems.next(null);
        this.reportMalfunctionFormData.next(null);
        this.reportGenericMapItems.next(null);
        this.reportGenericTableItems.next(null);
        this.reportGenericFormData.next(null);
        this.mechanicTableItems.next(null);
        this.mechanicMapItems.next(null);
        this.mechanicFormData.next(null);
        this.projectsCreateMapItems.next(null);
        this.projectsCreateTableItems.next(null);
        this.projectsCreateFormData.next(null);
        this.projectsManageMapItems.next(null);
        this.projectsManageTableItems.next(null);
        this.projectsManageFormData.next(null);
        this.workOrdersMapItems.next(null);
        this.workOrdersTableItems.next(null);
        this.workOrdersFormData.next(null);
        this.stedinOrdersMapItems.next(null);
        this.stedinOrdersTableItems.next(null);
        this.stedinOrdersFormData.next(null);
        this.assetsFormData.next(null);
        this.controlFormData.next(null);
        this.deviceManagementFilterString.next('');
        this.assetsFilterString.next('');
        this.controlFilterString.next('');
        this.reportMalfunctionFilterString.next('');
        this.reportGenericFilterString.next('');
        this.projectsCreateFilterString.next('');
        this.projectsManageFilterString.next('');
        this.workOrdersFilterString.next('');
        this.stedinOrdersFilterString.next('');
        this.mechanicFilterString.next('');
        this.deviceManagementSelectedItems.next([]);
        this.assetsSelectedItems.next([]);
        this.controlSelectedItems.next([]);
        this.reportMalfunctionSelectedItems.next([]);
        this.reportGenericSelectedItems.next([]);
        this.projectsCreateSelectedItems.next([]);
        this.projectsManageSelectedItems.next([]);
        this.workOrdersSelectedItems.next([]);
        this.stedinOrdersSelectedItems.next([]);
        this.mechanicSelectedItems.next([]);
        this.areaSettingsFormData.next(null);
        this.userProfileFormData.next(null);
        this.userSettingsFormData.next(null);
        this.authorisations.next([]);
        this.currentSelectedClusterItems.next(0);
        this.mapMarkerLabelOverride = null;
        this.currentCallIsTableBBox = false;

        this.masterDataFormData.next(null);
        this.dashboardAutoRefresh = true;

        this.storedStartDates = [];
        this.storedEndDates = [];

        this.heatmapValueOptions = [];
        this.heatmapTreeCode = "";

        this.treeShortcuts.next([]);

        this.historyList = [];
        this.localizationElements.next([]);

        //Allow the wms generator to show a warning again in case of an error
        MapWMSLayerGenerator.SHOWN_WMS_ERROR_WARNING = false;
    }

    public getUserRoleLabels(): string[] {
        let result: string[] = [];
        if (this.user && this.user.value) {
            for (let userRole of this.user.value.roles) {
                result.push(userRole.name);
            }
        }
        return result;
    }

    public getUserRoleCodes(): string[] {
        let result: string[] = [];

        if (this.user && this.user.value) {
            for (let userRole of this.user.value.roles) {
                result.push(userRole.role);
            }
        }
        return result;
    }

    public isKeycodeActive(keycode:string):boolean{
        let isActive = false;
        this.treeShortcuts.value.map(_x => {
            if(_x.keycode == keycode && _x.isActive){
                isActive = true;
            }
        });
        return isActive;
    }

    public isXmasMode():boolean{
        const enableXmasMode:boolean = true;
        const currentDate = new Date();
        if(
            enableXmasMode &&
            ((currentDate.getMonth() == 11 && currentDate.getDate() >= 18) ||
            (currentDate.getMonth() == 0 && currentDate.getDate() <= 5))
        ){
            return true
        } else {
            return false
        }
    }
}
