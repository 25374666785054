import {
    AdditionalStatusItemInfo,
    StatusItem
} from '../../../../status/status-progress/status-progress.interface';
import {BaseFormConfig, ConfigType, FormGroupConfig, FormRowConfig} from '../../field/fieldDirective.interface';
import {FormLinkConfig} from '../../form-link/form-link.interface';
import {SelectConfig} from '../../form-select/form-select.interface';
import {DateTextConfig, SimpleInputConfig} from '../../form-input/form-input.interface';
import {CheckBoxConfig} from '../../form-checkbox/form-checkbox.interface';
import {BasicHttpGetResult, HttpResult} from '../../../../../services/http-service-2.0/http.interface';
import {LumiForm} from '../../../containers/form/form.interface';

export interface FormGroupWithStatusConfig extends FormRowConfig {
    children: (
        StatusConfig|
        FormLinkConfig|
        SelectConfig|
        DateTextConfig|
        SimpleInputConfig|
        CheckBoxConfig
    )[];
    initialValue: {
        betaalbaarBoolean: boolean[];
        datumBezochtmonteur: string;
        datumPlanningtmonteur: string;
        monteur: string;
        projectBonType: string;
        reparatieStatus: string;
        status: {};
        toelichting: string;
    };
}

export interface StatusConfig extends BaseFormConfig {
    type: ConfigType.status;
    attr: StatusConfigAttr;
    base_object_id: number;
    initialvalue: string
    options: StatusConfigOptions[];
    action: string;
}

export interface StatusConfigAttr {
    isWorkorderStatus: true;
    actions?: StatusConfigAction[];
}

export interface StatusConfigAction {
    id: number;
    label: string;
    action: string; // url
    icon: string;
}

export interface StatusConfigOptions {
    id: number;
    name: string;
    attr: {
        isActive: boolean;
        isFinished: true;
        additionalStatusInfo: AdditionalStatusItemInfo[];
        baseStatus: BaseStatus;
        disabled: boolean;
    }
}

export enum BaseStatus {
    'TODO' = 'TODO',
    'IN_PROGRESS' = 'IN_PROGRESS',
    'DONE' = 'DONE'
}

export interface FormGroupWithStatus {
    statusItems: FormGroupStatus[];
    // isArchived: boolean;
}

export interface FormGroupStatus extends StatusItem {
    baseStatus: BaseStatus;
    disabled: boolean;
}

export interface StatusChangeRequestsHttpResult extends BasicHttpGetResult {
    schema: BaseFormConfig
}
