/**
 * Managed by Christiaan on 13/03/2017.
 */
import {AfterViewInit, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild} from '@angular/core';
import {GlobalModel} from '../../shared/services/state/global.model';
import {MapTableComponent} from '../../shared/components/map-table/map-table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {MapTableService} from '../../shared/components/map-table/map-table.service';
import {HTTPService} from '../../shared/services/http/http.service';
import {FormDataService} from '../../shared/components/form/services/form-data.service';
import {AuthorizationService} from '../../shared/services/authorization/authorization.service';
import {TreeMapFormComponent} from '../../shared/components/tree-map-form/tree-map-form.component';
import {SectionFormComponent} from '../../shared/components/form/components/section-form.component';
import {StorageService} from '../../shared/services/storage/storage.service';
import {GlobalEvent} from '../../shared/interfaces/global-event';
import {TableOptionsService} from '../../shared/components/table/table-options.service';
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {CreateNodeObject} from '../../shared/components/commonUI/tree/tree-node-interface';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {take} from 'rxjs/operators';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {TreeComponent} from '../../shared/components/commonUI/tree/tree.component';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {RequestFailure} from '../../shared/services/http/request-failure';
import {LuminizerRoutes} from '../../shared/interfaces/routes';
import {GlobalStateService} from "../../shared/services/state/global-state.service";
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component ({
    selector: 'projects-manage-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../../shared/components/tree-map-form/tree-map-form.component.html'
})
export class ProjectsManageComponent extends TreeMapFormComponent implements AfterViewInit, OnInit {

    public static readonly FORM_URL: string = '/projects-manage/view';
    public static readonly MODULE_PATH_PROJECTS_MANAGE: string = 'projects-manage';
    public static readonly BATCHUPDATE_URL: string = 'projects-manage/batch-update/get/mast';
    public readonly CHILD_NODE_ICON: string = 'pending_actions';
    public readonly CHILD_NODE_ACTION_ICON: string = 'info';
    public readonly CHILD_NODE_ACTION_TITLE: string = 'project.show';

    @ViewChild('mapTableComponent', {static: false}) mapTableComponent: MapTableComponent;
    @ViewChild('formComponent', {static: false}) formComponent: SectionFormComponent;
    @ViewChild('dragContainer', {static: false}) dragContainer: any;

    constructor(
        public model: GlobalModel,
        protected mapTableService: MapTableService,
        protected elementRef: ElementRef,
        protected cd: ChangeDetectorRef,
        protected ngZone: NgZone,
        protected activatedRoute: ActivatedRoute,
        private router: Router,
        protected httpService: HTTPService,
        protected formDataService: FormDataService,
        public auth: AuthorizationService,
        protected storage: StorageService,
        protected tableOptionsService: TableOptionsService,
        protected globalAlertService: GlobalAlertService,
        private translateService: TranslateService,
        protected areaalService: AreaalService,
        protected globalStateService: GlobalStateService,
        protected logger:LoggerService
    ) {
        super(cd, activatedRoute, httpService, router, model, mapTableService, storage, tableOptionsService, auth, formDataService, ngZone, activatedRoute, globalAlertService, areaalService, globalStateService, translateService, logger);
        
        this.setTreeMapFormSettings(
            'projectsManage_1',
            'projectsManage_2',
            ['projectbon'],
            ProjectsManageComponent.MODULE_PATH_PROJECTS_MANAGE,
            [],
            ProjectsManageComponent.FORM_URL,
            ProjectsManageComponent.BATCHUPDATE_URL,
            FormDataService.FORM_URL_PROJECTS_MANAGE,
            this.model.projectsManageSelectedItems,
            this.model.projectsManageFormData,
            this.model.projectsManageFormCollapsed,
            this.model.projectsManageTreeCollapsed,
            this.model.projectsManageMapItems,
            this.model.projectsManageAutoLoadedMapItems,
            this.model.projectsManageAutoLoadedTableItems,
            this.model.projectsManageTableItems,
            this.model.projectsManageFilterString,
            this.model.projectsManageTree,
            {
                allowMultiLineInRow: true,
                allowMarkerDrag: false
            },
            {
                nodeActionIcon: this.CHILD_NODE_ACTION_ICON,
                childNodeIcon: this.CHILD_NODE_ICON,
                nodeActionTitleKey: this.CHILD_NODE_ACTION_TITLE,
            }
        );
        this.expectTreeNodeActions = true;
    }

    ngOnInit():void {
        // This module relies heavily upon the creation of new projects, so always reload the tree + table/map upon entering when no id is given
        this.activatedRoute.params.subscribe(params => {
            if(params.ids === 0){
                this.autoRefreshOnModuleEnter = true;
            }
        });
    }

    ngAfterViewInit(): void {
        this.viewInit();
    }

    protected handleGlobalEvent(event: GlobalEvent): void {
        if (event.type == GlobalEvent.EVENT_LOAD_FORM_REQUEST) {
            if (event.data.url) {
                // When switching entities on the batchupdate form
                this.getBatchUpdateForm(event.data.url);
            }
        }
    }

    // Overwrite super class
    protected handleFormDataChanged(json: any): void {
        super.handleFormDataChanged(json, ['projects_manage_process']);
    }

    // Overwrite super class
    protected handleRouteChanged(params: any): void {
        this.timesParamsReceived++;

        // When no ID is used
        if (params && params.projectid && params.projectid == 0 && params.ids && params.ids == 0) {

            // When this route-change is just to fool the router so you can execute the same link twice, do nothing now
            if (this.httpService.getQueryParam('origin') == FormDataService.ORIGIN_ROUTER_REFRESH) {
                return;
            }

            // Klap het formulier in bij 0/0 url
            this.logger.log('[ProjectsManageComponent] ' + 'No id\'s, collapse form');
            if (this.timesParamsReceived > 1) {
                this.formCollapsedObservable.next(true);
            }
        }

        // When there is an id given with the url, load that form
        if (params && params.projectid) {// } && params.id != 0) {

            // Convert string to array of objects
            let ids: any[] = params.projectid.split(',');
            let idsAsObject: any[] = [];
            ids.forEach((id: any) => {
                idsAsObject.push({projectProces: parseInt(id)});
            });

            if (idsAsObject.length === 1 && idsAsObject[0].projectProces == 0) {
                // Skip loading id 0
                // return;
            } else {
                // One or more objects. Request the form for the first selected treenode and select all treenodes and do list calls

                // Form doesnt corresponds with selected items any more, so clear selected items
                this.selectedItemsObservable.next([]);
                this.getProjectForm(idsAsObject[0].projectProces);
                if (this.model.currentTree.value.code !== TreeComponent.TREE_CODE_PROJECTS_SWITCHBOX) {
                    if (this.timesParamsReceived == 1 || this.httpService.getQueryParam('origin') != FormDataService.ORIGIN_TREE) {
                        // Expand the tree
                        this.logger.log('[ProjectsManageComponent] ' + 'EXPAND AND LOAD TREE');

                        // Only set the state to form when a link is clicked that links directly to the dimgroepform
                        this.setActiveState(this.STATE_FORM);

                        // When using the direct link in the tree, don't load the map/table items
                        if (this.httpService.getQueryParam('origin') != FormDataService.ORIGIN_TREE_NODE_ACTION) {
                            this.treeComponent.isTreeLoaded.pipe(take(2)).subscribe(value => {
                                if (value) {
                                    this.logger.log('[ProjectsManageComponent] ' + 'EXPANDING NODES ' + idsAsObject)
                                    this.treeComponent.expandTreeForReferences(idsAsObject);
                                }
                            });
                        }
                    } else {
                        this.logger.log('[ProjectsManageComponent] ' + 'DONT EXPAND AND LOAD TREE');
                    }
                }
            }
        }

        this.handleRouteDefaultIds(params);
    }

    private getProjectForm(id: number) {
        this.formDataService.getProjectFormDataForId(this.MODULE_FORM_DATA_URL, this.formDataObservable, id, () => {}, (value) => {
            this.logger.log('[TreeMapFormComponent] could not find baseobject in area, trigger area switch: ', value);
            this.logger.log('[TreeMapFormComponent] called url: ', this._router.url);
            const calledUrl = this._router.url;
            if (value[0].areaId) {
                this.areaalService.loadAreaal(value[0].areaId, true,
                    (json: any) => {
                        this.logger.log('[TreeMapFormComponent] success area switch (deep link)', json);
                        setTimeout(() => {
                            this._router.navigateByUrl(calledUrl);
                        }, 100)
                    },
                    (failure: RequestFailure) => {
                        this.logger.log('[TreeMapFormComponent] failed area switch (deep link)', failure);
                        this.globalAlertService.addAlertFailure(failure);
                    },
                    LuminizerRoutes.INITIAL_PAGE,
                );
            }
        }, () => {});
    }

    // Overwrite super class
    protected gotoActionUrl(url: string): void {
        // Go to tree node action url
        this.router.navigate([this.MODULE_FORM_URL, '0', '0'] , {queryParams: {origin: FormDataService.ORIGIN_ROUTER_REFRESH}});
        setTimeout(() => {
            this.router.navigateByUrl(url + '?origin=' + null);
        });
    }

    handleTableMapSelectionChange(selectedItems: any) {
        super.handleTableMapSelectionChange(selectedItems, (params: any) => {
            this._router.navigate([this.MODULE_FORM_URL, params, '0'], {queryParamsHandling: 'merge'});
        });
    }

    // Overwrites super class
    public clearSelectedItems(): void {
        super.clearSelectedItems([this.MODULE_FORM_URL, 0, 0], true);
    }

    // Overwrites super class
    protected clearVisibleItems(): void {
        super.clearVisibleItems([this.MODULE_FORM_URL, 0, 0]);
    }

    public handleComponentEvent(eventData: any): void {
        switch (eventData.event) {
            case FormEvent.COMMENT_ADD_SUCCESS:
            case FormEvent.COMMENT_DELETE_SUCCESS:
            case FormEvent.ATTACHMENT_ADD_SUCCESS:
            case FormEvent.ATTACHMENT_DELETE_SUCCESS:
            case FormEvent.SAVE_SUCCESS:
                if (eventData?.data?.referenceId) {
                    this.getForm(eventData.data.referenceId);
                }
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;
            case FormEvent.BATCH_SAVE_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;
            case FormEvent.WORKORDER_DELETE_SUCCESS:
            case FormEvent.BATCH_DELETE_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                this.clearSelectedItems();
                break;
            case FormEvent.ACTIVITY_CREATE_SUCCESS:
            case FormEvent.ACTIVITY_DELETE_SUCCESS:
            case FormEvent.WORK_ACTIVITY_CREATE_SUCCESS:
            case FormEvent.WORK_ACTIVITY_DELETE_SUCCESS:
            case FormEvent.WORK_SPECIFICATION_CREATE_SUCCESS:
            case FormEvent.WORK_SPECIFICATION_DELETE_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                this.getForm(eventData.data.referenceId);
                break;
            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                const polygonSelect = this.mapTableComponent.mapComponent.polygonSelect;
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect();
                }
                this.clearSelectedItems();
                break;
            case FormEvent.ADD_SUCCESS:
            case FormEvent.PROJECT_SAVE_SUCCESS:
                this.reloadTree(true);
                break;
            case FormEvent.PROJECT_DELETE_SUCCESS:
                this.reloadTree(false, false, false,() => {
                    this.clearVisibleItems();
                });
                break;
            case FormEvent.NAVIGATE:
                this.router.navigate([eventData.data.url], { queryParams: { state: 1, selectRootNode: true}});
                break;
        }
    }
    
    public handleTreeNodeCreate(data: CreateNodeObject): void {
        this.globalAlertService.addPopupCreateDropdown(
            this.translateService.translate('project.add.title', [data.parentNode]),
            '',
            data.creationHref,
            (buttonCode: any, response: any) => {
                this.reloadTree(false, false, false,() => {
                    this.router.navigate([`${this.MODULE_FORM_URL}/0/${response.id}`], { queryParams: { state: 2, origin: null}});
                });
            },
            () => {
            }
        );
    }
}
