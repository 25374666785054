import {
    Component, ViewChild, AfterViewInit, NgZone, ChangeDetectionStrategy, ElementRef,
    ChangeDetectorRef
} from '@angular/core';
import {GlobalModel} from "../../shared/services/state/global.model";
import {MapTableComponent} from "../../shared/components/map-table/map-table.component";
import {MapTableService} from "../../shared/components/map-table/map-table.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HTTPService} from "../../shared/services/http/http.service";
import {FormDataService} from "../../shared/components/form/services/form-data.service";
import {AuthorizationService} from "../../shared/services/authorization/authorization.service";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {TreeMapFormComponent} from "../../shared/components/tree-map-form/tree-map-form.component";
import {SectionFormComponent} from "../../shared/components/form/components/section-form.component";
import {StorageService} from "../../shared/services/storage/storage.service";
import {GlobalEvent} from "../../shared/interfaces/global-event";
import {TableOptionsService} from "../../shared/components/table/table-options.service";
import {TreeComponent} from "../../shared/components/commonUI/tree/tree.component";
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {GlobalStateService} from "../../shared/services/state/global-state.service";
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'device-management-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../../shared/components/tree-map-form/tree-map-form.component.html'
})
export class DeviceManagementComponent extends TreeMapFormComponent implements AfterViewInit {

    public static readonly FORM_URL: string = '/device-management/view';
    public static readonly MODULE_PATH_DEVICE_MANAGEMENT: string = 'device-management';
    public static readonly BATCHUPDATE_URL: string = 'device-management-form/batch-update/get/general';

    @ViewChild('mapTableComponent', {static: false}) mapTableComponent: MapTableComponent;
    @ViewChild('formComponent', {static: false}) formComponent: SectionFormComponent;
    @ViewChild('dragContainer', {static: false}) dragContainer: any;

    constructor(
        public model:GlobalModel,
        protected mapTableService: MapTableService,
        protected elementRef:ElementRef,
        protected cd:ChangeDetectorRef,
        protected ngZone:NgZone,
        protected formDataService:FormDataService,
        protected activatedRoute:ActivatedRoute,
        private router:Router,
        protected globalAlertService:GlobalAlertService,
        protected httpService:HTTPService,
        public auth:AuthorizationService,
        public ts:TranslateService,
        protected storage:StorageService,
        protected tableOptionsService:TableOptionsService,
        protected areaalService: AreaalService,
        protected globalStateService: GlobalStateService,
        protected logger:LoggerService
    ) {
        super(cd, activatedRoute, httpService, router, model, mapTableService, storage, tableOptionsService, auth, formDataService, ngZone, activatedRoute, globalAlertService, areaalService, globalStateService, ts, logger);

        this.setTreeMapFormSettings(
            'dm_1',
            'dm_2',
            ['segment', 'olc'],
            DeviceManagementComponent.MODULE_PATH_DEVICE_MANAGEMENT,
            ['offline', 'defect'],
            DeviceManagementComponent.FORM_URL,
            DeviceManagementComponent.BATCHUPDATE_URL,
            FormDataService.FORM_URL_DEVICES,
            this.model.deviceManagementSelectedItems,
            this.model.deviceManagementFormData,
            this.model.deviceManagementFormCollapsed,
            this.model.deviceManagementTreeCollapsed,
            this.model.deviceManagementMapItems,
            this.model.deviceManagementAutoLoadedMapItems,
            this.model.deviceManagementAutoLoadedTableItems,
            this.model.deviceManagementTableItems,
            this.model.deviceManagementFilterString,
            this.model.deviceManagementTree,
            {
                allowCreateMarker: this.auth.allowCreateMapItem(),
                allowMarkerDrag: this.auth.allowMoveMapItem(),
                iconSet: 'smartdevices'
            }
        );
        // TODO: de counts meesturen met de module-settings ipv los configureren met module actions
        if (this.auth.allowShowUncommissionedCount()) {
            this.MODULE_COUNTS.push('uncommissioned');
            this.MODULE_COUNTS.push('attention');
        }
        if (this.auth.allowHideOfflineCount()) {
            this.MODULE_COUNTS = this.MODULE_COUNTS.filter(function(e) { return e != 'offline' })
        }
    }

    ngAfterViewInit():void
    {
        this.viewInit();
    }

    handleMapLongPress(e: any)
    {
        let dropDownOptions: any = [
            { value: 'segment', label: this.ts.translate("Segment") },
        ];
        if (this.auth.allowCreateGenericDevice()) {
            dropDownOptions.push({ value: 'genericDevice', label: this.ts.translate('genericDevice') })
        }
        if (this.auth.allowCreateTPPLDevice()){
            dropDownOptions.unshift({value:'tppl', label:this.ts.translate("device.tppl")})
        }
        if (this.auth.allowCreateSammodeDevice()){
            dropDownOptions.unshift({value:'sammode', label:this.ts.translate("device.sammode")})
        }

        this.globalAlertService.addPopupCreateMapItem(this.ts.translate("Item aanmaken"), "", "mapitem/form/new/",  "mapitem/form/create/", dropDownOptions, (buttonCode:any, response:any) =>
        {
            //User pressed OK and call succeeded
            this.router.navigateByUrl(this.MODULE_FORM_URL + "/" + response.baseObjectId + "?origin=" + (response.schakelkastId && response.schakelkastId != ""?FormDataService.ORIGIN_NEW_SCHAKELKAST:FormDataService.ORIGIN_RELOAD));
            //this.setActiveState(this.STATE_FORM);

        }, () => {}, e.position.lat(), e.position.lng());
    }

    protected handleGlobalEvent(event:GlobalEvent):void
    {
        switch (event.type) {
            case GlobalEvent.EVENT_LOAD_FORM_REQUEST:
                if (event.data.url){
                    //When switching entities on the batchupdate form
                    this.getBatchUpdateForm(event.data.url);
                }
                break;

            case GlobalEvent.EVENT_REMOVE_BASEOBJECT_SUCCESS:
                // This is handling for both deleting from the form and from the mapitemtree

                // Refresh the map and table
                this.refreshMapTableItems(this.getSelectedItemIds());

                // Check if selected items need to be deselected
                if (event.data.baseObjectId) {
                    if (this.getSelectedItemIds().length > 0) {
                        if (this.getSelectedItemIds()[0] == event.data.baseObjectId) {
                            // When the currently selected item equals the deleted mapitem-treenode: deselect all items and don't reload the form
                            this.clearSelectedItems();
                        }
                        else {
                            // Mapitem tree item does not equal the currently selected item, reload the form to refresh the mapitemtree
                            this.getForm(this.getSelectedItemIds()[0], () => {
                                //TODO: hier hoeft nu niks te gebeuren tenzij je een lamp geselecteerd had en de bijbehorende mast aan het verwijderen bent, dan krijg je nu een melding dat je het formulier niet kan ophalen omdat deze gearchiveerd is, en selected items blijft op 1 staan. dus die wordne hier leeggehaald, maar dat moet eigenlijk mooier
                                this.logger.log('[DeviceManagementComponent] ' + 'Error while getting form, deselect all items');
                                this.clearSelectedItems();
                            });

                        }
                    }
                }
                break;

            case GlobalEvent.EVENT_REFRESH_FORM:
                this.refreshMapTableItems(this.getSelectedItemIds());
                this.getForm(event.data.referenceId);
                break;
        }
    }

    handleComponentEvent(eventData: any):void
    {
        switch (eventData.event) {
            case FormEvent.SAVE_SUCCESS:
            case FormEvent.BATCH_SAVE_SUCCESS:
            case FormEvent.MOVE_MAPITEM_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;
            case FormEvent.BATCH_DELETE_SUCCESS:
                // NOTE: for now not used, but has a big chance of getting implemented sometimes
                this.refreshMapTableItems(this.getSelectedItemIds());
                this.clearSelectedItems();
                break;
            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                const polygonSelect = this.mapTableComponent.mapComponent.polygonSelect
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect()
                }
                this.clearSelectedItems();
                break;
            case FormEvent.SEGMENT_DELETE_SUCCESS:
                this.clearSelectedItems();

                this.setActiveState(this.startingState);

                this.model.deviceManagementMapItems.next(null);
                this.model.deviceManagementTableItems.next(null);

                this.reloadTree();
                break;
        }
    }

    // Overwrite super class
    protected setModuleObjectType():void
    {
      let objectTypes:string[] = ['segment'];

      if (this.model.deviceManagementTree.value.code != TreeComponent.TREE_CODE_RWS) {
          objectTypes.push('olc');
      }

      this.MODULE_OBJECT_TYPES = objectTypes;
    }
}
