
import {ChangeDetectorRef, Component, ElementRef, NgZone} from "@angular/core";
import {RequestFailure} from "../../shared/services/http/request-failure";
import {HTTPError} from "../../shared/services/http/http-error";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";
import {GlobalModel} from "../../shared/services/state/global.model";
import {FormDataService} from "../../shared/components/form/services/form-data.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {AbstractObjectFormComponent} from "../../shared/components/form/containers/form/abstract-object-form.component";
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {ButtonCode} from '../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'stedin-orders-form-component',
    template : `        
    <ng-container *ngIf="formData && config"> 
        <form-component
                #baseFormGroup
                [config]="config"
                [name]="getFormName()"
                [validationConstraints]="validationConstraints"
                [invalidControlsErrors]="invalidControlsErrors"
                [readOnly]="readOnly"
                [formIsSubmitted]="formIsSubmitted"
                (onComponentEvent)="handleComponentEvent($event)">
        </form-component>
        <!--<validation-warning [showWarning]="hasFailedConstraints()"></validation-warning>-->
    </ng-container>
    `
})

export class StedinOrdersFormComponent extends AbstractObjectFormComponent {

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected zone:NgZone, protected elementRef:ElementRef, public model:GlobalModel, protected ts:TranslateService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, model, logger);
    }

    public handleComponentEvent(eventData:any):void{

        switch (eventData.event){
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData, eventData.data.referenceId, () => {
                    this.onComponentEvent.emit({event:FormEvent.SAVE_SUCCESS, data:{baseObjectId:eventData.data.referenceId}});
                });
                break;
            case FormEvent.WORKORDER_DELETE:
                this.globalAlertService.addPopup(this.ts.translate("workorder.deletetitle"), this.ts.translate("workorder.delete"), [{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:false},
                    {label:this.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                        callback:() => {

                            this.formDataService.deleteWorkOrder(eventData.data.attr.deleteUrl,
                                () => {
                                    this.onComponentEvent.emit({event:FormEvent.WORKORDER_DELETE_SUCCESS});
                                    this.globalAlertService.addAlertSuccess(this.ts.translate('workorder.isdeletedtitle'), this.ts.translate('workorder.isdeletedtext' ), '');
                                }, () => {}, () => {})
                        },
                      isPrimary:true}, ], () => {});

                break;
            case FormEvent.BATCH_DELETE:
                this.globalAlertService.addPopup(this.ts.translate("Items verwijderen"), this.ts.translate("Wilt u de geselecteerde items verwijderen?"),
                    [{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                    /*{label:this.ts.translate("Archiveren"), code:ButtonCode.ARCHIVE,
                        callback:() => {
                            this.submitBatchDelete(this.model.stedinOrdersSelectedItems.value, eventData.data.url, true, () => {
                                this.handleBatchDeleteSuccess();
                            });
                        },
                        classes:GlobalAlertService.DEFAULT_WHITE_BUTTON_CLASSES},*/
                    {label:this.ts.translate("Verwijderen"), code:ButtonCode.DELETE,
                        callback:() => {
                            this.submitBatchDelete(this.model.stedinOrdersSelectedItems.value, eventData.data.url, false, () => {
                                this.handleBatchDeleteSuccess();
                            });
                        },
                        isPrimary:false}
                        ], () => {})
                break;
            case FormEvent.BATCH_SAVE:
                this.submitBatchUpdate(this.model.stedinOrdersSelectedItems.value, eventData.data.formData, eventData.data.url, () => {
                    this.handleBatchUpdateSuccess();
                });
                break;
            case FormEvent.WORK_PREPARATION_CREATE:
                this.globalAlertService.addPopupCreateWorkPreparation(eventData.data.attr.baseObjectId,
                    () => {
                        this.onComponentEvent.emit({event:FormEvent.WORK_PREPARATION_CREATE_SUCCESS, data:{referenceId: eventData.data.attr.baseObjectId}});
                    }, () => {
                        //Move is canceled, or failed. Do nothing
                    });
                break;
            case FormEvent.WORK_ACTIVITY_CREATE:
                this.globalAlertService.addPopupCreateWorkActivity(eventData.data.attr.baseObjectId, "components/activity/new/",  "components/activity/create/", eventData.data.attr.dropDownData,
                    () => {
                        this.onComponentEvent.emit({event:FormEvent.WORK_ACTIVITY_CREATE_SUCCESS, data:{referenceId: eventData.data.attr.baseObjectId}});
                    }, () => {
                        //Move is canceled, or failed. Do nothing
                    });
                break;
        }

        this.onComponentEvent.emit(eventData);
    }

    private submitBatchUpdate(items:any[], form:any, url:string, successCallBack?:() => any):void
    {
        if (!items || items.length == 0){
            this.logger.log("[StedinOrdersFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setBatchUpdateFormData(this.model.stedinOrdersFormData, url, form, this.getFormName(), items,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitFormData(form: any, itemId:number, successCallBack?:() => any):void
    {
        if (itemId == 0){
            this.logger.log("[StedinOrdersFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setFormDataForId(FormDataService.FORM_URL_STEDIN_ORDERS, this.model.stedinOrdersFormData, form, this.getFormName(), itemId,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private submitBatchDelete(items:any[], url:string, archive:boolean = true, successCallBack?:() => any):void
    {
        if (!items || items.length == 0){
            this.logger.log("[StedinOrdersFormComponent] " + "ERROR: trying to submit the form, but it is not linked to an item");
            return;
        }

        this.handleSubmitForm();

        this.formDataService.batchDelete(url, items, archive,
            () => {
                this.handleSubmitResponse();
                successCallBack();
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }
}
