<div #dropdownToggler
     tabindex=0
     class="d-flex {{singleLineDropdown? 'multi-select-drop-down-container-single-line':'multi-select-drop-down-container'}} {{readonly?'read-only':disabled?'disabled':''}}"
     (keyup)="onKeyUpToggler($event)"
     (globalClickOutsideAngular)="closeDropdown()"
     (click)="toggleDropDown($event)">
    <div *ngIf="multi" class="d-flex flex-wrap mb-1 dropdown-items-container">
        <ng-container *ngFor="let option of selectedOptions;">
            <div class="{{disabled?'px-1':'pl-1'}} {{option.italic? 'font-italic': ''}} mx-1 mt-1 d-flex align-items-center unselectable-content multi-select-option-block"
                 title="{{option.title ? option.title : option.name}}">
                <div class="my-auto d-flex flex-wrap">
                    <div style="display: grid; display: -ms-grid; max-width: 100%;">
                        <div style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                             title="{{option.title ? option.title : option.name}}">{{option.title ? option.title : option.name}}</div>
                    </div>
                </div>
                <div *ngIf="!disabled && !readonly && !option.disabled"
                     class="cursor-pointer"
                     title="{{'Item deselecteren' | translate}}">
                    <i class="material-icons p-0 text-color-darkgrey"
                       style="font-size: 14px; padding: 0.1rem; line-height: unset;"
                       (click)="removeChoice($event, option)">
                        clear
                    </i>
                </div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="!multi" class="px-2 py-1 my-auto d-flex flex-wrap">
        <ng-container *ngIf="selectedOptions[0]">
            <div style="display: grid; display: -ms-grid; max-width: 100%;">
                <div class="{{selectedOptions[0].italic? 'font-italic': ''}}" style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                     title="{{selectedOptions[0].title ? selectedOptions[0].title : selectedOptions[0].name}}">{{selectedOptions[0].title ? selectedOptions[0].title : selectedOptions[0].name}}</div>
            </div>
        </ng-container>
    </div>
    <div *ngIf="!disabled && !readonly" style="margin-left:auto;">
        <div class="cursor-pointer h-100 d-flex align-items-center form-select-arrow-container unselectable-content"
             [style.width]="'fit-content'">
            <i style="left:-1px; top:8px; font-size:18px"
               class="p-0  material-icons">arrow_drop_down</i>
        </div>
    </div>
</div>
<div style="position: relative"
     (globalKeydownOutsideAngular)="handleKeyDownDropDown($event)">
    <div #dropdown
         *ngIf="showDropdown || isBeingToggled"
         [style.bottom]="getDropDownBottom()"
         [style.left]="getDropDownLeft()"
         class="{{isBeingToggled? 'invisible':'d-flex'}} {{getDropDownDirection()}} w-100 {{dropdownSizeLarge?'multi-select-option-list-large':'multi-select-option-list'}}">
        <input #optionFilter
               class="mx-2 {{showOptionFilter?'':'d-none'}}"
               title="{{'multiselect.filter' | translate}}"
               placeholder="{{'multiselect.filter' | translate}}"
               style="width:auto; font-size:0.8rem;height: 2rem;"
               (click)="Utils.preventDefault($event)"
               (input)="handleFilterInput($event)"
               type="text">
        <ng-container *ngFor="let option of filteredOptions;">
            <ng-container *ngIf="multi">
                <label class="{{highlightedOption === option?'highlighted':''}} {{option.disabled? 'option-disabled':''}} {{option.italic? 'font-italic': ''}} custom-control m-0 custom-checkbox d-flex unselectable-content align-items-center"
                       style="padding-left:0.5rem; margin-bottom:0.2rem !important;"
                       title="{{option.title ? option.title : option.name}}"
                       (click)="handleClickOption($event, option)">
                    <lumi-checkbox [label]="option.title ? option.title : option.name"
                                   [isDisabled]="option.disabled"
                                   [small]="true"
                                   [value]="isSelected(option)">
                    </lumi-checkbox>
                </label>
            </ng-container>
            <ng-container *ngIf="!multi">
                <label class="px-2 {{highlightedOption === option?'highlighted':''}} {{isSelected(option) ?'highlight-selected':''}} {{option.disabled? 'option-disabled':''}} {{option.italic? 'font-italic': ''}} custom-control m-0 d-flex unselectable-content align-items-center"
                       (click)="handleClickOption($event,option)"
                       style="padding-top:0.35rem; padding-bottom:0.35rem; white-space: nowrap"
                       title="{{option.title ? option.title : option.name}}">
                    {{option.title ? option.title : option.name}}
                </label>
            </ng-container>
        </ng-container>
    </div>
</div>
