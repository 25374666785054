/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter, ElementRef} from '@angular/core';
import {LoggerService} from "../../services/logger/logger.service";

@Directive({
    selector: '[clickOutsideAngular]'
})
export class ClickOutsideAngularDirective
{
    private static readonly EVENT_CLICK:string = "click";

    @Output('clickOutsideAngular') clickHandler: EventEmitter<any> = new EventEmitter();

    constructor(private elementRef: ElementRef, private ngZone:NgZone, protected logger:LoggerService)
    {
        this.ngZone.runOutsideAngular(() => {
            this.elementRef.nativeElement.addEventListener(ClickOutsideAngularDirective.EVENT_CLICK, (event:any)=> {
                this.logger.log("[ClickOutsideAngularDirective] " + "CLICK EVENT");
                this.clickHandler.emit(event);
            })
        });
    }
}
