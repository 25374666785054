import {Component, OnInit, OnDestroy, Input} from '@angular/core';
import {Router, Event, NavigationEnd} from "@angular/router";
import {Subscription} from "rxjs";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {IHistoryList} from "./history.interface";
import {MenuComponent} from "../menu.component";
import {StorageService} from "../../../shared/services/storage/storage.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {HistoryService} from './history.service';
import * as moment from 'moment/moment';

@Component ({
    selector: 'history-component',
    templateUrl: 'history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {
    private totalHistoryItems:number = 10;
    /*
        If a new page is added to Luminizer, it has to be defined in the array below in order to be included in the history list.
        It does a url check so add the unique url part to search for
        ['url part to search for', 'title to display if found']
    */
    private pagesToTrack:string[][] = [
        ['assets',this.ts.translate('history.url.assets')],
        ['device-management',this.ts.translate('history.url.device-management')],
        ['control',this.ts.translate('history.url.control')],
        ['control',this.ts.translate('history.url.control')],
        ['report-malfunction',this.ts.translate('history.url.report-malfunction')],
        ['work-orders',this.ts.translate('history.url.work-orders')],
        ['stedin-orders',this.ts.translate('history.url.work-orders')],
        ['mechanic',this.ts.translate('history.url.mechanic')],
        ['projects-create',this.ts.translate('history.url.projects-create')],
        ['projects-manage',this.ts.translate('history.url.projects-manage')]
    ];

    @Input('isMobile') isMobile: boolean = false;
    private subRouteChange: Subscription;
    private subBaseObjectIdName:Subscription;
    private lastUrlCalled:string = '';


    constructor(private router:Router, public model:GlobalModel, private menuComponent:MenuComponent, private storageService:StorageService, private historyService:HistoryService, private ts:TranslateService) {
        this.subRouteChange = this.router.events.subscribe((event:Event) => {
            if(event instanceof NavigationEnd) {
                this.processRouteChangeValue(event.urlAfterRedirects);
            }
        });
    }

    ngOnInit() {
        if(this.storageService.keyExists(StorageService.KEY_HISTORY_LIST)) {
            this.storageService.getObjectValue(StorageService.KEY_HISTORY_LIST, (value: IHistoryList[]) => {
                if (value.length > 0) {
                    this.model.historyList = [];
                    for (let i = 0; i < value.length; i++) {
                        this.model.historyList.push(value[i]);
                    }
                }
            });
        }
    }

    private replaceUrlParam(url, paramName, paramValue){
        if (paramValue == null) {
            paramValue = '';
        }
        let pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
        if (url.search(pattern)>=0) {
            return url.replace(pattern,'$1' + paramValue + '$2');
        }
        url = url.replace(/[?#]$/,'');
        return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
    }

    private processRouteChangeValue(urlFull:string){
        let pageTitle = '';
        let urlKey = '';
        let baseObjectId:string = '';
        let url = urlFull.split('?')[0];

        if(url != this.lastUrlCalled){
            this.pagesToTrack.every(_x =>{
                if(url.includes('/'+_x[0]) && this.getIdString(url) && this.getIdString(url) != '0'){
                    pageTitle = _x[1];
                    urlKey = _x[0];
                    baseObjectId = this.getIdString(url);
                    urlFull = this.replaceUrlParam(urlFull, 'state','2');
                    urlFull = this.replaceUrlParam(urlFull, 'origin','history');
                    return false;
                } else {
                    return true;
                }
            })
            if(pageTitle != ''){
                if(baseObjectId != '' && baseObjectId != '0'){
                    this.addToArrayWithBaseObjectName(baseObjectId,pageTitle,url,urlFull,urlKey)
                } else {
                    this.addToArray(pageTitle,url,urlFull);
                }
            }
        }
        this.lastUrlCalled = url;
    }

    public clickHistoryItem(historyItem:IHistoryList){
        this.menuComponent.handleMenuAction();
        this.model.mobileStateBack.next(null); //Reset mobile state to null otherwise last state is used and this might not be the form (state 2).
        this.router.navigateByUrl(historyItem.urlFull);
    }

    private addToArrayWithBaseObjectName(baseObjectId:string, pageTitle:string, url:string, urlFull:string, urlKey:string){
        this.subBaseObjectIdName = this.historyService.getBaseObjectName(baseObjectId).subscribe((baseObjectName) => {
            pageTitle = (baseObjectName.label && baseObjectName.label != '' && urlKey == 'assets') ? pageTitle+' - '+baseObjectName.code + ' ('+baseObjectName.label+')' : pageTitle+' - '+baseObjectName.code;
            this.addToArray(pageTitle,url,urlFull);
        });
    }

    private addToArray(title:string,url:string,fullUrl:string):void{
        let isValueInHistoryList = false;
        for(let i=0,len=this.model.historyList.length;i<len;i++){
            if(this.model.historyList[i].url === url){
                isValueInHistoryList = true;
                break;
            }
        }
        if(!isValueInHistoryList){
            if(this.model.historyList.length === this.totalHistoryItems){
                //Remove oldest item
                this.model.historyList.pop();
            }
            let item:IHistoryList = {
                title:title,
                url: url,
                urlFull:fullUrl,
                dateTime:moment().toString()
            }
            this.model.historyList.unshift(item);
            this.storageService.setObjectValue(StorageService.KEY_HISTORY_LIST, this.model.historyList);
        }
    }

    private getIdString(url:string):string{
        return url.match(/\d+/g).filter(_m => _m != '0')[0];
    }

    ngOnDestroy(){
        if(this.subRouteChange){
            this.subRouteChange.unsubscribe();
        }
        if(this.subBaseObjectIdName){
            this.subBaseObjectIdName.unsubscribe();
        }
    }
}
