import {Component, ElementRef, Input, Output, ViewChild, EventEmitter, AfterViewInit} from '@angular/core';
import {MapItem} from './map-device-mapping-mapitem.component';
import {Subscription} from "rxjs";
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import {IMapItem, IMarker} from './map-device-mapping.interface';
import {MapDeviceMappingService} from './map-device-mapping.service';
import {TranslateService} from '../../../../shared/services/translate/translate.service';

@Component({
    selector: 'map-device-mapping',
    template: `
        <div #mapContainer id="mapContainer" class="w-100" style="height:500px;"></div>
    `
})
export class MapDeviceMappingComponent implements AfterViewInit {
    @Input() popupData: IMarker = null;
    @Output() onSelectBaseObjectId = new EventEmitter<number>();
    @Output() onChangeLoadingState = new EventEmitter<boolean>();
    @ViewChild('mapContainer', {static: false}) mapContainer: ElementRef;

    private subscriptions: Subscription[] = [];
    public map: google.maps.Map;
    public assetMarker: MapItem = null;
    public bBoxItems: MapItem[] = [];
    private eventDragend:google.maps.MapsEventListener = null;
    private eventZoomChanged:google.maps.MapsEventListener = null;
    private selectedBaseObjectId:number = null;

    constructor(protected logger:LoggerService, private mapDeviceMappingService:MapDeviceMappingService, public ts: TranslateService){}

    ngAfterViewInit() {
        this.logger.log('[MapDeviceMapping] ' + 'Initialize map')
        this.map = new google.maps.Map(this.mapContainer.nativeElement, {
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: true,
            mapTypeId: 'roadmap',
            backgroundColor: '#f9f9f9',
            styles: [{featureType:"poi", elementType:"labels", stylers:[{visibility:"off"}]},{featureType:"transit",stylers:[{visibility:"off"}]}],
            zoom:21,
        });

        //Add event for drag
        this.eventDragend = google.maps.event.addListener(this.map, 'dragend', () => {
            this.getBbox()
        })
        //Add event for zoom
        this.eventZoomChanged = google.maps.event.addListener(this.map, 'zoom_changed', () => {
            this.getBbox()
        })

        if(this.popupData && this.popupData?.baseObjectId){
            this.initSourceMarker();
            //Use timeout to prevent the bounds from returning undefined
            setTimeout(()=>{
                this.getBbox();
            })
        }

        this.changeLoadingState(false)
    }

    private initSourceMarker(){
        this.logger.log('[MapDeviceMapping] ' + 'Initialize source marker')
        this.assetMarker = new MapItem(this)
        this.assetMarker.initGoogleObject(this.popupData.baseObjectId,this.popupData.icon+".gif", this.popupData.lat, this.popupData.lng, null,false, true, false)
        this.map.setCenter(new google.maps.LatLng(this.popupData.lat, this.popupData.lng))
    }

    private getBbox(){
        this.logger.log('[MapDeviceMapping] ' + 'Get target markers from boundingbox')
        const currentMapBounds = this.map.getBounds()
        if (currentMapBounds) {
            this.subscriptions.push(this.mapDeviceMappingService.getBBoxItems(currentMapBounds).subscribe((formPostResult) => {
                const formPostResultConv = formPostResult as any;
                const _localSelectedBaseObjectId:number = this.selectedBaseObjectId
                this.clearBoundBoxItems();
                formPostResultConv.mapItems.map((_marker: IMarker) => {
                    const mapItem = new MapItem(this)
                    mapItem.initGoogleObject(_marker.id, _marker.icon+'.png', _marker.lat, _marker.lng, _marker.label, true, false, _localSelectedBaseObjectId === _marker.id);
                    this.bBoxItems.push(mapItem);
                })
            }));
        }
    }

    private clearBoundBoxItems():void{
        this.logger.log('[MapDeviceMapping] ' + 'Clear bounding box items')
        this.bBoxItems.map(_targetItem => {
            _targetItem.removeFromMap()
        });
        this.selectedBaseObjectId = null;
        this.onSelectBaseObjectId.emit(0);
        this.bBoxItems = []
    }

    public clearSelectedMarkers():void{
        this.bBoxItems.map(_mapItem => {
            _mapItem.isSelected = false;
            _mapItem.mapItem.marker.setIcon(_mapItem.mapItem.icon.replace("-sel", ''))
        })
    }

    public selectMarker(mapItem: IMapItem): void {
        mapItem.marker.setIcon(mapItem.icon.replace(".png", '-sel.png'))
        this.selectedBaseObjectId = mapItem.baseObjectId;
        this.onSelectBaseObjectId.emit(mapItem.baseObjectId);
    }

    private changeLoadingState(loading:boolean):void{
        this.onChangeLoadingState.emit(loading)
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe())
        google.maps.event.removeListener(this.eventDragend)
        google.maps.event.removeListener(this.eventZoomChanged)
    }
}


