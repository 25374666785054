<div class="timeline-bar" *ngIf="bar"></div>
<div class="timeline">
    <div class="d-flex align-items-center">
        <div class="timeline-date">
            <span>{{config.created | momentDate:'lll'}}</span>
        </div>
        <div class="timeline-icon-container">
            <div class="timeline-icon"></div>
        </div>
        <div class="timeline-status">
         <span>{{config.code + ', ' + ('timeline.creator' | translate) + config.firstName + ' ' + config.surname}}</span>
        </div>
    </div>
<div class="timeline-timediff">{{timeDifference | duration}}</div>
</div>
