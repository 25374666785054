/**
 * Created by Christiaan on 01/03/2017.
 */
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import htmlString = JQuery.htmlString;
import {TranslateService} from "../../../services/translate/translate.service";

@Component ({
    selector: 'tab-bar-component',
    template: `

        <ul class="nav nav-tabs tab-bar d-flex" role="tablist">
            <ng-container *ngFor="let tab of tabData">
                <li class="nav-item {{isActiveTab(tab)?'active':''}}" title="{{tab.label}}">
                    <a class="nav-link tab-bar-tab {{isActiveTab(tab)?'active':''}} waves-effect waves-light p-2" (click)="handleClickTab(tab)">{{tab.label}}</a> <!--for default behaviour add: data-toggle="tab" role="tab" -->
                </li>
            </ng-container>
            
            <!-- Add a fake tab when tabdata only contains one tab -->
            <ng-container *ngIf="tabData.length == 1">
                <li class="nav-item">
                    <a class="nav-link tab-bar-empty-tab waves-effect waves-light"></a> <!--for default behaviour add: data-toggle="tab" role="tab" -->
                </li>
            </ng-container>
<!--            <li class="nav-item text-right" *ngIf="availableModuleObjectTypes.length > 0">-->
<!--                <i class="material-icons cursor-pointer" [title]="returnAvaiableModuleObjectTypesString()">info</i>-->
<!--            </li>-->
        </ul>
`
})

export class TabBarComponent {
    @Input('availableModuleObjectTypes') public availableModuleObjectTypes:string[] = [];
    @Input('tabData') public tabData:any;
    @Input('selectedTab') public selectedTab:any;
    @Output() onSwitchTab: EventEmitter<any> = new EventEmitter();

    constructor(private ts:TranslateService) {}

    /*private getTabsClass(tabData:any):string
    {
        let numTabs:number = 2;

        if (tabData) {
            numTabs = tabData.length;
            if (numTabs < 2){
                numTabs = 2;
            }
        }

        return "tabs-" + numTabs;
    }*/

    public isActiveTab(tab:any):boolean
    {
        return (tab && this.selectedTab && this.selectedTab.id == tab.id)
    }

    public returnAvaiableModuleObjectTypesString():string{
        let output:string = '';
        this.availableModuleObjectTypes.map((_objectType,index) =>{
            output += this.ts.translate('objecttype.'+_objectType);
            if(index < (this.availableModuleObjectTypes.length-1)){
                output += '<br>';
            }
        })
        return output.replace(/<br>/g,'\n');
    }

    public handleClickTab(tab:any)
    {
        //Trigger tab switched event. Only when a new tab is selected
        if (!this.selectedTab || this.selectedTab.id != tab.id){
            this.onSwitchTab.emit(tab);
        }
    }
}
