/**
 * Created by Christiaan on 13/03/2017.
 */
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild} from '@angular/core';
import {GlobalModel} from '../../shared/services/state/global.model';
import {MapTableComponent} from '../../shared/components/map-table/map-table.component';
import {ActivatedRoute, Router} from '@angular/router';
import {MapTableService} from '../../shared/components/map-table/map-table.service';
import {HTTPService} from '../../shared/services/http/http.service';
import {FormDataService} from '../../shared/components/form/services/form-data.service';
import {AuthorizationService} from '../../shared/services/authorization/authorization.service';
import {TreeMapFormComponent} from '../../shared/components/tree-map-form/tree-map-form.component';
import {SectionFormComponent} from '../../shared/components/form/components/section-form.component';
import {StorageService} from '../../shared/services/storage/storage.service';
import {GlobalEvent} from '../../shared/interfaces/global-event';
import {TableOptionsService} from '../../shared/components/table/table-options.service';
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {GlobalStateService} from "../../shared/services/state/global-state.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component ({
    selector: 'projects-create-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../../shared/components/tree-map-form/tree-map-form.component.html'
})
export class ProjectsCreateComponent extends TreeMapFormComponent implements AfterViewInit {

    public static readonly FORM_URL: string = '/projects-create/view';
    public static readonly MODULE_PATH_PROJECTS_CREATE: string = 'projects-create';
    public static readonly BATCHUPDATE_URL: string = 'projects-create/batch-update/get/mast';

    @ViewChild('mapTableComponent', {static: false}) mapTableComponent: MapTableComponent;
    @ViewChild('formComponent', {static: false}) formComponent: SectionFormComponent;
    @ViewChild('dragContainer', {static: false}) dragContainer: any;

    constructor(
        public model: GlobalModel,
        protected mapTableService: MapTableService,
        protected elementRef: ElementRef,
        protected cd: ChangeDetectorRef,
        protected ngZone: NgZone,
        protected activatedRoute: ActivatedRoute,
        private router: Router,
        protected httpService: HTTPService,
        protected formDataService: FormDataService,
        public auth: AuthorizationService,
        protected storage: StorageService,
        protected tableOptionsService: TableOptionsService,
        protected globalAlertService: GlobalAlertService,
        protected areaalService: AreaalService,
        protected globalStateService: GlobalStateService,
        public ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(cd, activatedRoute, httpService, router, model, mapTableService, storage, tableOptionsService, auth, formDataService, ngZone, activatedRoute, globalAlertService, areaalService, globalStateService, ts, logger);

        this.setTreeMapFormSettings(
            'projectsCreate_1',
            'projectsCreate_2',
            ['schakelkast', 'mast'],
            ProjectsCreateComponent.MODULE_PATH_PROJECTS_CREATE,
            [],
            ProjectsCreateComponent.FORM_URL,
            ProjectsCreateComponent.BATCHUPDATE_URL,
            FormDataService.FORM_URL_PROJECTS_CREATE,
            this.model.projectsCreateSelectedItems,
            this.model.projectsCreateFormData,
            this.model.projectsCreateFormCollapsed,
            this.model.projectsCreateTreeCollapsed,
            this.model.projectsCreateMapItems,
            this.model.projectsCreateAutoLoadedMapItems,
            this.model.projectsCreateAutoLoadedTableItems,
            this.model.projectsCreateTableItems,
            this.model.projectsCreateFilterString,
            this.model.projectsCreateTree,
            {
                allowMarkerDrag: false
            }
        );
    }

    ngAfterViewInit(): void {
        this.viewInit();
    }

    protected handleGlobalEvent(event: GlobalEvent): void {
        if (event.type === GlobalEvent.EVENT_LOAD_FORM_REQUEST) {
            if (event.data.url) {
                // When switching entities on the batchupdate form
                this.getBatchUpdateForm(event.data.url);
            }
        }
    }

    handleComponentEvent(eventData: any):void
    {
        switch (eventData.event) {
            case FormEvent.SAVE_SUCCESS:
            case FormEvent.BATCH_SAVE_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;
            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                this.clearSelectedItems();
                break;
        }
    }
}
