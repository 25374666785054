<div (click)="handleClickNode($event)"
     class="{{(selectedNodeId === node.nodeId)?'tree-node-component-selected':'tree-node-component'}} simple-tree-node d-flex align-items-center {{!node.children?'no-children':''}} ">
    <div class="tree-node-icon-container pl-1" *ngIf="node.children?.length > 0">
        <i class="material-icons tree-node-icon">{{expanded ? 'expand_more' : 'keyboard_arrow_right'}}</i>
    </div>
    <i *ngIf="node.children?.length > 0 else single_item_icon"
       class="material-icons tree-node-icon pr-1">{{(expanded ? 'folder_open' : 'folder')}}</i>
    <ng-template #single_item_icon>
        <i class="material-icons tree-node-icon-single pr-1">{{nodeIcon}}</i>
    </ng-template>
    <label class="tree-node-label">{{node.name}}</label>
</div>
<div *ngIf="node.children?.length > 0 && expanded" style="padding-left: 0.75rem;">
    <ng-container *ngFor="let childNode of node.children">
        <simple-tree-node
                [selectedNodeId]="selectedNodeId"
                (onNodeClick)="handleChildNodeClick($event)"
                [nodeIcon]="nodeIcon"
                [node]="childNode">
        </simple-tree-node>
    </ng-container>
</div>
