/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild
} from '@angular/core';

import {GlobalPopup} from "../global-popup";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {GlobalAlertService} from '../global-alert.service';
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {FormComponent} from '../../../shared/components/form/containers/form/form.component';
import {HTTPError} from '../../../shared/services/http/http-error';
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-create-dropdown-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [disableButtons]="formDisabled" [globalPopup]="globalPopup" [title]="globalPopup.title" [description]="'Nieuwe waarde:' | translate" (onClose)="closePopup($event)">
            <input initialFocus #dropDownText class="" title="{{globalPopup.label}}" type="text">
            <ng-container *ngIf="formData && config">
                <form-component #form
                                [config]="config"
                                [name]="getFormName()"
                                [validationConstraints]="validationConstraints"
                                [invalidControlsErrors]="invalidControlsErrors"
                                [readOnly]="readOnly"
                                [formIsSubmitted]="formIsSubmitted"
                                (onComponentEvent)="handleComponentEvent($event)"></form-component>
            </ng-container>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupCreateDropdownComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    @ViewChild("dropDownText", {static: false}) dropDownText:any = "";

    @Input() submitUrl:string = "";
    @Input() requestUrl:string = "";

    public formDisabled:boolean = false;
    private entity:string = '';

    constructor(private formDataService:FormDataService, protected cd:ChangeDetectorRef, protected tooltipService:TooltipService, private renderer:Renderer2, protected ts:TranslateService, protected globalAlertService:GlobalAlertService, protected elementRef:ElementRef, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit()
    {


        this.submitButton = this.getSubmitButton();

        // Get data type from tree
        this.entity = 'mastType';

        // When the add popup needs to be pre-filled
        if(this.globalPopup.data && this.globalPopup.data.autoFill){
            this.dropDownText.nativeElement.value = this.globalPopup.data.autoFill;
        }
    }
    
    public handleComponentEvent(eventData:any):void{
        if (eventData.event == FormEvent.SAVE){
            this.submitFormData(eventData.data.formData);
        }
    }

    private submitFormData(form: any):void
    {
        this.handleSubmitForm();

        this.formDataService.setFormData(form, this.getFormName(), this.submitUrl + this.getEntity(),
            (formData:any) => {
                //this.formData = formData;
                this.handleSubmitResponse();

                //Hide alert and perform success callback via submit button
                this.onPopupAction.emit({event:null, alert:this.globalPopup, button:this.submitButton, data:formData}); //, data:{lat:form.location.latitude, lng:form.location.longitude}});
            },
            (failure: RequestFailure) => {
                this.logger.log("[GlobalPopupCreateBaseObjectComponent] " + "Handle failure response: ", failure);
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private getEntity():string{

        //Safety for element not loaded
        let entity:string = "mastType";

        //Get the value of the dropdown
        if (this.entity){
            entity = this.entity;
        }

        return entity;
    }

    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, false);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        this.tooltipService.destroyToolTip(this.dropDownText);

        //callback for submit button comes from this form, not from outside this component
        if (button == this.submitButton){
            this.submitValue();
        }else{
            //Hide alert and perform callback of button
            this.onPopupAction.emit({event:event, alert:alert, button:button});
        }

        //this.onPopupAction.emit({event:event, alert:alert, button:button, data:{input:this.dropDownText.nativeElement.value}});
    }

    private submitValue()
    {
        if (!this.formDisabled) {

            let input: string = this.dropDownText.nativeElement.value;
            this.logger.log("[GlobalPopupCreateDropdownComponent] " + "Try to submit value |" + input + "|");

            if (input == "") {
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.dropDownText,
                    this.ts.translate("popupinput.validation"), //JSON.stringify(this.validationConstraints),
                    TooltipService.PLACEMENT_TOP, TooltipService.TOOLTIP_MODE_MANUAL, "", true
                );
            } else {

                this.formDisabled = true;

                //Execute call
                this.formDataService.createNewDropDown(this.globalPopup.data.path, this.dropDownText.nativeElement.value, (response: any) => {
                    this.formDisabled = false;

                    //Hide alert and perform success callback via submit button
                    this.onPopupAction.emit({
                        event: null,
                        alert: this.globalPopup,
                        button: this.submitButton,
                        data: response
                    });
                }, (failure: RequestFailure) => {

                    this.tooltipService.createAndShowTooltip(
                        this.renderer,
                        this.dropDownText,
                        this.ts.translate("Geef een geldige en unieke waarde op"), //JSON.stringify(this.validationConstraints),
                        TooltipService.PLACEMENT_TOP, TooltipService.TOOLTIP_MODE_MANUAL, "", true
                    );

                    this.formDisabled = false;
                    this.cd.detectChanges()
                }, () => {
                    this.tooltipService.createAndShowTooltip(
                        this.renderer,
                        this.dropDownText,
                        this.ts.translate("Er ging iets mis met het aanmaken. Item is niet aangemaakt."), //JSON.stringify(this.validationConstraints),
                        TooltipService.PLACEMENT_TOP, TooltipService.TOOLTIP_MODE_MANUAL, "", true
                    );

                    this.formDisabled = false;
                    this.cd.detectChanges()
                });
            }
        }
    }

    public requestFormData():void
    {
        this.isLoading = true;

        this.formDataService.getFormData(this.requestUrl + this.getEntity(),
            (formData:any) => {
            this.logger.log("[GlobalPopupCreateDropdownComponent] " + "");
                //setTimeout(() => {
                this.formData = formData;
                this.isLoading = false;
                this.cd.detectChanges();
                //});
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }
}
