import {Component, Input, OnInit} from '@angular/core';
import {Timeline} from './device-timeline.interface';
import {IDeviceStateHistory} from '../../../../gui-form/components/device-state/device-state.interface';
import {LoggerService} from "../../../../../services/logger/logger.service";

@Component({
  selector: 'device-timeline',
  templateUrl: './device-timeline.component.html',
  styleUrls: ['./device-timeline.component.scss']
})

export class DeviceTimelineComponent implements OnInit {
    @Input() public config: IDeviceStateHistory;
    @Input() public bar: boolean = true;

    public timeDifference:number;

    constructor(protected logger:LoggerService) { }

    ngOnInit(): void {
        const dateReported:Date =  new Date(this.config.date_reported)
        const dateResolve:Date =  new Date(this.config.date_resolve)
        this.logger.log('[DeviceTimelineComponent] ', dateReported);
        this.logger.log('[DeviceTimelineComponent] ', dateResolve);
        this.timeDifference = Math.abs(<any>dateReported - <any>dateResolve);
        this.logger.log('[DeviceTimelineComponent] ', this.timeDifference);
    }

}
