<div class="control-program-week mt-5">
    <div class="d-flex justify-content-between align-items-center">
        <h5>{{'control-calendar.current-week' | translate}} ({{currentWeekNumber}})</h5>
        <i class="material-icons yearview p-0 mb-2" (click)="handleClickOpenControlCalendar()" [title]="'control-program.yearview-title' | translate">open_in_new</i>
    </div>
    <div *ngIf="isLoading" class="py-3 d-flex justify-content-center">
        <div class="loader"></div>
    </div>
    <ng-container *ngIf="!isLoading">
        <div class="status-card program mb-1" *ngFor="let dayOfWeek of currentWeekData">
            <div class="statusbar" *ngIf="dayOfWeek.date == currentDay"></div>
            <div class="inner d-flex align-items-center">
                <div class="color" [ngStyle]="{'background-color': dayOfWeek.control_program[0].color}"></div>
                <div class="info ml-2">
                    <div class="date">{{formatDateString(dayOfWeek.date,'dddd DD MMMM YYYY')}}</div>
                    <div class="name">{{dayOfWeek.control_program[0].name}}</div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
