/**
 * Created by Christiaan on 13/03/2017.
 */
import {
    AfterViewInit,
    Component,
    ViewChild,
} from '@angular/core';
import {GlobalModel} from '../../shared/services/state/global.model';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {FormDataService} from '../../shared/components/form/services/form-data.service';
import {AuthorizationService} from '../../shared/services/authorization/authorization.service';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {TreeMapFormComponent} from '../../shared/components/tree-map-form/tree-map-form.component';
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';

@Component({
    selector: 'user-management-component',
    templateUrl: 'user-management.component.html'
})
export class UserManagementComponent implements AfterViewInit {

    public static readonly FORM_URL: string = '/user-management/view';
    public static readonly MODULE_PATH_USER_MANAGEMENT: string = 'user-management';
    public static readonly BATCHUPDATE_URL: string = 'user-management-form/batch-update/get/mast';
    
    @ViewChild('treeMapFormComponent', {static: false}) treeMapForm: TreeMapFormComponent;

    constructor(
        public model: GlobalModel,
        protected globalAlertService: GlobalAlertService,
        public auth: AuthorizationService,
        private ts: TranslateService
    ) {
    }

    ngAfterViewInit(): void {
        this.treeMapForm.setTreeMapFormSettings(
            'user_management_1',
            'user_management_2',
            ['user'],
            UserManagementComponent.MODULE_PATH_USER_MANAGEMENT,
            [],
            UserManagementComponent.FORM_URL,
            UserManagementComponent.BATCHUPDATE_URL,
            FormDataService.FORM_URL_USER_MANAGEMENT,
            this.model.userManagementSelectedItems,
            this.model.userManagementFormData,
            this.model.userManagementFormCollapsed,
            this.model.userManagementTreeCollapsed,
            this.model.userManagementMapItems,
            this.model.userManagementAutoLoadedMapItems,
            this.model.userManagementAutoLoadedTableItems,
            this.model.userManagementTableItems,
            this.model.userManagementFilterString,
            this.model.userManagementTree,
            {
                allowCreateMarker: this.auth.allowCreateMapItem(),
                allowMarkerDrag: this.auth.allowMoveMapItem(),
                hideMap: true
            }
        );
    
        this.treeMapForm.viewInit();
    }

    handleClickAdd() {

        this.globalAlertService.addPopupCreateUser('user-management/new-user', 'user-management/create-user', (buttonCode: any, response: any) => {
            this.treeMapForm.refreshMapTableItems(response.baseObjectId);

            this.treeMapForm.mapTableComponent.setSelectedItems([{baseObjectId: response.baseObjectId}], true);

            // Show success alert
            this.globalAlertService.addAlertSuccess(this.ts.translate('usermanagement.createsuccesstitle'), this.ts.translate('usermanagement.createsuccesssubtitle'), this.ts.translate('usermanagement.createsuccesslabel'));

        }, () => {
        });
    }

    handleClickInvite() {
        this.globalAlertService.addPopupInviteUser('user-management/invite-user', 'user-management/invite-user', (buttonCode: any, response: any) => {
            this.treeMapForm.refreshMapTableItems(response.baseObjectId);

            this.treeMapForm.mapTableComponent.setSelectedItems([{baseObjectId: response.baseObjectId}], true);

            // Show success alert
            this.globalAlertService.addAlertSuccess(this.ts.translate('usermanagement.createinvitesuccesstitle'), this.ts.translate('usermanagement.createinvitesuccesssubtitle'), this.ts.translate('usermanagement.createinvitesuccesslabel'));

        }, () => {
        });
    }

    handleComponentEvent(eventData: any): void {
        switch (eventData.event) {
            case FormEvent.SAVE_SUCCESS:
            case FormEvent.BATCH_SAVE_SUCCESS:
                this.treeMapForm.refreshMapTableItems(this.treeMapForm.getSelectedItemIds());
                break;
            case FormEvent.BATCH_DELETE_SUCCESS:
            case FormEvent.DELETE_USER_SUCCESS:
                this.treeMapForm.refreshMapTableItems(this.treeMapForm.getSelectedItemIds());
                this.treeMapForm.clearSelectedItems();
                break;
            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                const polygonSelect = this.treeMapForm.mapTableComponent.mapComponent.polygonSelect;
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect();
                }
                this.treeMapForm.clearSelectedItems();
                break;
        }
    }

    ngOnDestroy() {
        this.treeMapForm.formDataObservable.next(null);
    }
}
